var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Color from "color";
import { Html } from "react-konva-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { useHover } from "../../hooks";
var STROKE_COLOR = Color("#cbdbe7");
var STROKE_COLOR_HEX = STROKE_COLOR.hex();
var CHECKED_COLOR_BASE = Color("#4299e1");
var DARKENED_STROKE_COLOR_HEX = STROKE_COLOR.darken(0.25).hex();
var CHECKED_COLOR = CHECKED_COLOR_BASE.hex();
var CHECKED_HOVERED_COLOR = CHECKED_COLOR_BASE.darken(0.25).hex();
var GanntListItemCheckbox = function (_a) {
    var checked = _a.checked, height = _a.height, width = _a.width, left = _a.x, top = _a.y, onToggle = _a.onToggle;
    var _b = useHover(), ref = _b[0], isHovered = _b[1];
    var fill = checked
        ? isHovered
            ? CHECKED_HOVERED_COLOR
            : CHECKED_COLOR
        : STROKE_COLOR_HEX;
    var hoveredColor = isHovered ? DARKENED_STROKE_COLOR_HEX : STROKE_COLOR_HEX;
    var color = checked ? fill : hoveredColor;
    var icon = checked ? faCheckSquare : faSquare;
    return (_jsx(Html, { children: _jsx("button", __assign({ ref: ref, className: "absolute flex items-center justify-center", style: {
                width: width,
                height: height,
                top: top,
                left: left,
            }, onClick: onToggle }, { children: _jsx(FontAwesomeIcon, { icon: icon, size: "lg", color: color }, void 0) }), void 0) }, void 0));
};
/**
 *  <Rect
      ref={ref}
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      stroke={stoke}
      strokeWidth={1}
      cornerRadius={1}
    />
 */
export default GanntListItemCheckbox;
