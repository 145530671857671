import { camelizeKeys } from "humps"
import { atom, useRecoilState } from "recoil"

export interface GroupDataStructure {
  uuid: string
  id: number
  name: string
  position: number
  subdomain?: string
  groupUuid?: string
}

export const groups = atom({
  key: "groups",
  default: {} as { [uuid: string]: GroupDataStructure },
})

export const useResetGroups = () => {
  const [, setGroups] = useRecoilState(groups)
  return () => setGroups({})
}

export const useAddGroups = () => {
  const [existingGroups, setGroups] = useRecoilState(groups)
  return (
    newGroups: GroupDataStructure[],
    options: { useCache: boolean } | undefined = { useCache: true }
  ) => {
    setGroups({
      ...(options.useCache ? existingGroups : {}),
      ...(newGroups ?? []).reduce(
        (p, c) => ({ ...p, [c.uuid]: camelizeKeys(c) }),
        {}
      ),
    })
  }
}

export const useExistingGroups = () => {
  const [existingGroups] = useRecoilState(groups)
  return existingGroups
}

export const useGroupsAccessibleFor = (uuid: string) => {
  const groups = Object.values(useExistingGroups())
  const descendantOfTargetUuid = ({
    groupUuid,
    uuid: currentUuid,
  }: GroupDataStructure): boolean => {
    return (
      groupUuid === uuid ||
      currentUuid === uuid ||
      groupUuid === currentUuid || // Protect against corrupt data...
      (groupUuid
        ? descendantOfTargetUuid(
            groups.find((g) => g.uuid === groupUuid) as GroupDataStructure
          )
        : false)
    )
  }

  return [
    { name: "Top Level", uuid: "ROOT" },
    ...(groups.filter((g) => !descendantOfTargetUuid(g)) ?? []).map((g) => ({
      name: g.name,
      uuid: g.uuid,
    })),
  ]
}
