var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { DateTime, Interval } from "luxon";
import { Icon } from "./Icon";
import { faChevronLeft, faChevronRight, } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
export var MiniCal = function (_a) {
    var _b, _c;
    var initialDate = _a.initialDate, selectedDates = _a.selectedDates, handleSelectedDates = _a.onDateSelected, _d = _a.monthsToShow, monthsToShow = _d === void 0 ? 1 : _d, className = _a.className, range = _a.range, _e = _a.rangeDirection, rangeDirection = _e === void 0 ? "both" : _e, darkDays = _a.darkDays, disableWeekends = _a.disableWeekends;
    var _f = useState(null), hoverDate = _f[0], setHoverDate = _f[1];
    var _g = useState([]), trackedDates = _g[0], setTrackedDates = _g[1];
    var _h = useState(0), increment = _h[0], setIncrement = _h[1];
    var handleIncrementClick = function (newIncrement) { return function (e) {
        e.preventDefault();
        setIncrement(increment + newIncrement);
    }; };
    var initializedDate = useRef({
        selectedDates: selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.join(),
        initialDate: initialDate,
    });
    useEffect(function () {
        if (initializedDate.current.selectedDates === (selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.join()) &&
            initializedDate.current.initialDate === initialDate) {
            return;
        }
        setIncrement(0);
    }, [selectedDates, initialDate, setIncrement]);
    var originDate = (_c = (_b = selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[0]) !== null && _b !== void 0 ? _b : initialDate) !== null && _c !== void 0 ? _c : DateTime.utc();
    var monthStart = (originDate.isValid ? originDate : DateTime.utc())
        .startOf("month")
        .plus({ months: increment });
    var handleDateClick = function (date) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        setTrackedDates(range && trackedDates.length < 2 ? __spreadArray(__spreadArray([], trackedDates, true), [date], false) : [date]);
    }; };
    useEffect(function () {
        var _a;
        if (trackedDates.length === 2 || (!range && trackedDates.length === 1)) {
            var diff = trackedDates[0]
                .diff((_a = trackedDates[1]) !== null && _a !== void 0 ? _a : trackedDates[0])
                .as("days");
            handleSelectedDates === null || handleSelectedDates === void 0 ? void 0 : handleSelectedDates(trackedDates, diff < 1 ? "forward" : "backward");
        }
    }, [trackedDates, handleSelectedDates, range]);
    var gridClassNames = monthsToShow > 2
        ? "grid grid-cols-3 gap-4"
        : monthsToShow > 1
            ? "grid grid-cols-2 gap-4"
            : null;
    return (_jsx("div", __assign({ className: "p-2 px-4 h-full" }, { children: _jsxs("div", __assign({ className: "flex flex-col relative" }, { children: [_jsxs("nav", __assign({ className: "flex rounded p-2 absolute top-0 inset-x-0" }, { children: [_jsx("button", __assign({ onClick: handleIncrementClick(-1), className: "p-2 absolute left-0 top-0" }, { children: _jsx(Icon, { icon: faChevronLeft, mode: "secondary" }, void 0) }), void 0), _jsx("button", __assign({ onClick: handleIncrementClick(1), className: "p-2 absolute top-0 right-0" }, { children: _jsx(Icon, { icon: faChevronRight, mode: "secondary" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: clsx(gridClassNames, "flex-grow") }, { children: __spreadArray([], Array(monthsToShow), true).map(function (_, months) { return (_jsxs("div", __assign({ className: clsx("flex flex-col", className) }, { children: [_jsx("h3", __assign({ className: "text-sm font-semibold flex-grow text-center mx-auto p-2" }, { children: monthStart.plus({ months: months }).toFormat("MMM, yyyy") }), void 0), new Array(6).fill("").map(function (_, weeks) {
                                return (_jsx("div", __assign({ className: "grid grid-cols-7" }, { children: new Array(7).fill("").map(function (_, days) {
                                        var _a, _b, _c, _d;
                                        var currentMonth = monthStart
                                            .plus({ months: months })
                                            .startOf("month");
                                        var day = currentMonth
                                            .plus({ weeks: weeks })
                                            .startOf("week")
                                            .plus({ days: days - 1 });
                                        var inMonth = currentMonth.month === day.month;
                                        var isIncompleteRange = range && trackedDates.length === 1;
                                        var isBefore = ((_a = trackedDates[0]) === null || _a === void 0 ? void 0 : _a.diff(day).as("days")) > 0;
                                        var isDark = darkDays === null || darkDays === void 0 ? void 0 : darkDays.includes(day.toISODate());
                                        var isWeekend = day.weekday >= 6;
                                        var disabled = (isWeekend && disableWeekends) ||
                                            isDark ||
                                            !inMonth ||
                                            (isIncompleteRange &&
                                                !((_b = trackedDates[0]) === null || _b === void 0 ? void 0 : _b.equals(day)) &&
                                                ((rangeDirection === "forward" && isBefore) ||
                                                    (rangeDirection === "backward" && !isBefore)));
                                        var isSelectedDate = (_c = trackedDates[0]) === null || _c === void 0 ? void 0 : _c.equals(day);
                                        var start = (_d = trackedDates[0]) !== null && _d !== void 0 ? _d : selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[0];
                                        var end = (selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.length) === 2 && trackedDates.length < 1
                                            ? selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[1]
                                            : hoverDate;
                                        var rangeValues = [start, end].sort();
                                        var isInRange = range &&
                                            rangeValues[0] &&
                                            rangeValues[1] &&
                                            Interval.fromDateTimes(rangeValues[0], rangeValues[1].plus({ day: 1 })).contains(day);
                                        return (_jsx("li", __assign({ className: clsx("border-2 flex transition duration-100 ease-out text-xs", disabled
                                                ? "text-gray-400 bg-gray-100 border-transparent pointer-events-none cursor-not-allowed"
                                                : (start === null || start === void 0 ? void 0 : start.equals(day))
                                                    ? "border-sorbus-dark bg-white text-sorbus-dark"
                                                    : (end === null || end === void 0 ? void 0 : end.equals(day))
                                                        ? "border-lochivar-dark bg-white text-lochivar-dark"
                                                        : isSelectedDate || isInRange
                                                            ? "bg-lochivar-default text-white hover:bg-lochivar-dark"
                                                            : "text-gray-800 bg-gray-100 hover:bg-gray-300"), onMouseOver: function () { return setHoverDate(day); } }, { children: _jsx("button", __assign({ className: "flex flex-grow p-1", onClick: handleDateClick(day.toUTC()) }, { children: _jsx("span", __assign({ className: "m-auto" }, { children: day.toFormat("d") }), void 0) }), void 0) }), "date-" + months + "-" + weeks + "-" + days));
                                    }) }), "minical-week-" + weeks));
                            })] }), "month-" + months)); }) }), void 0)] }), void 0) }), void 0));
};
