import { OPTIMISTIC_ENTITY_UPDATE } from "../actionTypes"

/**
 * Generates a faux API result for a map of entities of a given
 * type. This is intended to be used to perform a technique called
 * optimisitic updates in which the UI updates before a subsequent
 * API request has completed thus giving the user a sense of
 * instanteous feedback without having to deal with network latency
 * in the UI.
 * @param  {String} entityType  The key in the entities cache for this entity type. (i.e. 'categories' for category objects.)
 * @param  {Integer} id         The id of the entity being updated.
 * @param  {Object} entity      The actual json representation of the new entity.
 * @return {Object}             A redux action which can be dispatched to update the API cache.
 */
export const optimisticEntityUpdate = (entityType, id, entity) => ({
  type: OPTIMISTIC_ENTITY_UPDATE,
  payload: {
    entities: {
      [entityType]: {
        [id]: entity,
      },
    },
  },
})

/**
 * Generates a deleted copy of a passed in entity and then passes
 * it on to the optimisiticEntityUpdate action creator.
 *
 * @param  {String} entityType  The key in the entities cache for this entity type. (i.e. 'categories' for category objects.)
 * @param  {Integer} id         The id of the entity being updated.
 * @param  {Object} entity      The actual json representation of the new entity.
 * @return {Object}             A redux action which can be dispatched to update the API cache.
 */
export const optimisticEntityDelete = (entityType, id, entity) => {
  return optimisticEntityUpdate(entityType, id, { ...entity, isDeleted: true })
}
