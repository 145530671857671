import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  GanntChart,
  GanntContextMenuHandler,
  CalendarGroup,
  DateMap,
  GanntTreeState,
  GanntRenderingMode,
} from "ui"
import { DateTime } from "luxon"
import {
  holidaySelector,
  iconSelector,
  itemSelector,
  eventSelector,
  lengthenItem,
  renameItem,
  calendarSelector,
} from "../../api"
import { ContextTypes } from "../constants"
import { requestContext, shiftEventPreview } from "../actions"
import { flatten } from "lodash"
import { GanntChartProps } from "ui"

export type ZoomLevel = "Day" | "Week" | "Month" | "Quarter" | "Year"

export const ZOOM_LEVELS: ZoomLevel[] = [
  "Day",
  "Week",
  "Month",
  "Quarter",
  "Year",
]

/**
 * Prefix for the the cookie storing the last known expanded state of the GanntView by the current user.
 */
export const EXPANDED_COOKIE = "ganntExpanded"

/**
 * Prefix for the the cookie storing the last known disabled state of the GanntView by the current user.
 */
export const DISABLED_COOKIE = "ganntDisabled"

export interface GanntChartViewProps {
  onDateUpdate: (date: string) => void
  currentIndex?: number
  anchorDate?: string
  selectedDate?: string
  onSelectDate?: (date: string) => void
  onSelectEvent?: (eventId?: string, calendarId?: string) => void
  onItemDrop?: (id: string, date: string, type: string) => void
  onItemDropHover?: (
    itemType: string,
    id: string,
    start: boolean,
    date: string
  ) => void
  calendarIds: number[]
  reportId?: number
  onEventMouseDown: (selected: boolean) => void
  onEditEvent: (id: string) => void
  readOnly?: boolean
  groups: CalendarGroup[]
  lastLayoutChange?: number
  inactiveCellBackgroundColor?: string
  weekendCellBackgroundColor?: string
  selectedEventIds?: string[] | undefined
  originDate: string
  darkDays?: DateMap
  expanded?: GanntTreeState
  enabled?: GanntTreeState
}

/**
 * Generate a unique hash key representing the current view contents.
 * @param calendarIds An array of calendarIds rendered by the current gannt chart.
 * @param reportId The report id for the current gannt chart if present.
 */
const makeViewId = (calendarIds: number[] = [], reportId: number = 0) =>
  flatten([calendarIds.sort(), reportId]).join("_")

export const GanntChartView: React.FC<GanntChartViewProps> = ({
  onDateUpdate: handleDateUpdate,
  onSelectDate: handleSelectDate,
  selectedDate,
  calendarIds,
  reportId,
  groups: externalGroups,
  expanded: externalExpandedState,
  enabled: externalEnabledState,
  onSelectEvent: handleSelectEvent,
  darkDays,
  inactiveCellBackgroundColor,
  weekendCellBackgroundColor,
  selectedEventIds,
  originDate,
}) => {
  const viewId = makeViewId(calendarIds, reportId)

  const handleExpand = (state: GanntTreeState) => {
    console.log("Update the expanded state:")
    console.log(state)
  }

  const handleToggle = (state: GanntTreeState) => {
    console.log("Update the visible state:")
    console.log(state)
  }

  const [initialState, setInitialState] = useState<{
    expanded?: GanntTreeState
    disabled?: GanntTreeState
  }>({})

  // Store the report and associated calendar ids as a hash string to determine
  // if we need to reload any stored state from the network.
  const [currentViewId, setCurrentViewId] = useState(viewId)
  useEffect(() => {
    if (viewId !== currentViewId) {
      setInitialState({
        expanded: externalExpandedState,
        disabled: externalEnabledState,
      })
      setCurrentViewId(viewId)
    }
  }, [
    setInitialState,
    setCurrentViewId,
    viewId,
    currentViewId,
    externalExpandedState,
    externalEnabledState,
  ])

  // Store external groups in local state
  const [groups, setGroups] = useState(externalGroups)
  // Update stored groups if new groups have been passed to the component.
  useEffect(() => {
    if (JSON.stringify(groups) !== JSON.stringify(externalGroups)) {
      setGroups(externalGroups)
    }
  }, [externalGroups, groups])

  const dispatch = useDispatch()
  const findHolidaysForCalendars = useSelector(holidaySelector.forCalendar)
  const findIconsForCalendars = useSelector(iconSelector.forCalendar)
  const findItem = useSelector(itemSelector.find)
  const findEvent = useSelector(eventSelector.find)
  const findCalendar = useSelector(calendarSelector.find)

  // Respond to requests for contextual navigation.
  const handleContextMenu: GanntContextMenuHandler = ({
    x,
    y,
    date,
    eventId,
  }) => {
    const iconsForDate = findIconsForCalendars(calendarIds).filter(
      ({ startDate }: any) => {
        const anchor = DateTime.fromISO(date, { zone: "utc" })
        return (
          Math.floor(
            DateTime.fromISO(startDate, { zone: "utc" }).diff(anchor, "days")
              .days
          ) <= 0
        )
      }
    )
    if (eventId) {
      dispatch(
        requestContext({
          entityType: ContextTypes.EVENT,
          uuids: [eventId],
          targetUuid: eventId,
          x,
          y,
        })
      )
      if (!selectedEventIds?.includes(eventId) && handleSelectEvent) {
        handleSelectEvent(eventId)
      }
    } else {
      const holidaysForDate = findHolidaysForCalendars(calendarIds).filter(
        (d: any) => {
          const anchor = DateTime.fromISO(date, { zone: "utc" })
          return (
            DateTime.fromISO(d.startsAt, { zone: "utc" }).diff(anchor, "days")
              .days <= 0 &&
            DateTime.fromISO(d.endsAt, { zone: "utc" }).diff(anchor, "days")
              .days >= 0
          )
        }
      )
      dispatch(
        requestContext({
          entityType: ContextTypes.DAY,
          holidays: holidaysForDate,
          icons: iconsForDate,
          referenceDate: date,
          x,
          y,
        })
      )
      if (handleSelectDate) {
        handleSelectDate(date)
      }
    }
  }

  const handleAdjustEvent: GanntChartProps["onAdjustEvent"] = ({
    eventId,
    startsAt,
  }) => {
    const event = findEvent(eventId, calendarIds[0])
    const item = findItem(event?.itemUuid, calendarIds[0])
    if (item && event) {
      dispatch(
        shiftEventPreview(
          eventId,
          item.calendarId,
          startsAt,
          event.position,
          event.partition,
          false
        )
      )
    }
  }

  const handleValueChange: GanntChartProps["onValueChange"] = (
    eventId,
    field,
    value
  ) => {
    const event = findEvent(eventId, calendarIds[0])
    const item = findItem(event?.itemUuid, calendarIds[0])

    if (item && field === "name") {
      const calendar = findCalendar(calendarIds[0])
      dispatch(
        renameItem(calendar.organization, item.calendarId, item.uuid, value)
      )
    }

    if (item && event && field === "startsAt") {
      dispatch(
        shiftEventPreview(
          eventId,
          item.calendarId,
          value,
          event.position,
          event.partition,
          false
        )
      )
    }
    if (item && event && field === "length") {
      dispatch(
        lengthenItem(
          event.itemUuid,
          item.calendarId,
          event.startsAt,
          Number(value)
        )
      )
    }
  }

  const [currentZoom, setZoom] = useState("Month" as ZoomLevel)
  const zoomIndex = ZOOM_LEVELS.indexOf(currentZoom)
  const canZoomIn = zoomIndex > 0
  const canZoomOut = zoomIndex < ZOOM_LEVELS.length - 1
  const handleGanntZoom = (zoom: boolean) => {
    if (zoom && canZoomIn) {
      setZoom(ZOOM_LEVELS[zoomIndex - 1])
    }
    if (!zoom && canZoomOut) {
      setZoom(ZOOM_LEVELS[zoomIndex + 1])
    }
  }

  let rowHeight: number
  let cellWidth: number
  let renderingMode: GanntRenderingMode
  let divideRows = false
  let showHandles = false
  switch (currentZoom) {
    case "Day":
      divideRows = true
      rowHeight = 40
      cellWidth = 64
      renderingMode = "days"
      showHandles = true
      break
    case "Week":
      rowHeight = 36
      cellWidth = 40
      showHandles = true
      renderingMode = "days"
      break
    case "Month":
      rowHeight = 24
      cellWidth = 20
      renderingMode = "days"
      break
    case "Quarter":
      rowHeight = 24
      cellWidth = 10
      renderingMode = "weeks"
      break
    default:
      rowHeight = 24
      cellWidth = 6
      renderingMode = "weeks"
  }

  return (
    <GanntChart
      divideRows={divideRows}
      originDate={originDate}
      dayCellSize={cellWidth}
      rowHeight={rowHeight}
      renderingMode={renderingMode}
      groups={groups}
      onExpand={handleExpand}
      onToggle={handleToggle}
      onSelectDate={handleSelectDate}
      onSelectEvent={handleSelectEvent}
      onContextMenu={handleContextMenu}
      selectedDate={selectedDate}
      selectedEventIds={selectedEventIds}
      darkDays={darkDays}
      weekendCellBackgroundColor={weekendCellBackgroundColor}
      inactiveCellBackgroundColor={inactiveCellBackgroundColor}
      expanded={initialState.expanded ?? {}}
      disabled={initialState.disabled ?? {}}
      viewId={viewId}
      onZoom={handleGanntZoom}
      canZoomIn={canZoomIn}
      canZoomOut={canZoomOut}
      showHandles={showHandles}
      onAdjustEvent={handleAdjustEvent}
      onValueChange={handleValueChange}
    />
  )
}
