import { useCurrentUser } from "./useCurrentUser"

export const useHasCreatorPrivilege = (subdomain: string) => {
  const currentUser = useCurrentUser()
  return (
    currentUser.superAdmin ||
    currentUser.admin(subdomain) ||
    currentUser.creator(subdomain)
  )
}
