import React, { useState } from "react"
import {
  Modal,
  ModalProps,
  NoteForm,
  NoteFormValues,
  AsyncSubmitHandler,
} from "ui"
import { DateTime } from "luxon"
import toast from "react-hot-toast"
import { createItem, updateItem, useItem, useNotesCategory } from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"

export interface NoteModalProps {
  itemUuid?: string
  defaultDate?: string
}

export const NoteModal: React.FC<NoteModalProps & ModalProps> = ({
  onClose: handleDismiss,
  itemUuid,
  defaultDate,
  onFocus: handleFocus,
  zIndex,
}) => {
  const [loading, setLoading] = useState(false)
  const params = useParams<{ subdomain: string; calendarId: string }>()

  const {
    loading: loadingItem,
    item,
    event,
  } = useItem(params.subdomain, params.calendarId, itemUuid)

  const { loading: loadingCategories, notesCategory } = useNotesCategory(
    params.subdomain,
    params.calendarId
  )

  const dispatch = useDispatch()
  const onSubmit: AsyncSubmitHandler<NoteFormValues> = async (data) => {
    const { startsAt, notes, ...itemData } = data
    setLoading(true)
    await wait(1)
    const values = {
      ...itemData,
      categoryUuid: notesCategory?.uuid ?? "",
      interruptedByHolidays: false,
      interruptedByWeekends: false,
      event: { startsAt, length: 1, notes },
      occursOnCalendar: true,
      locked: false,
      active: true,
    }
    try {
      const response = await dispatch(
        item
          ? updateItem(params.subdomain, params.calendarId, {
              ...values,
              uuid: itemUuid,
            })
          : createItem(params.subdomain, params.calendarId, values)
      )
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(
        `Successfully ${item ? "updated" : "created"} note "${data.name}".`
      )
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return
  }

  const startsAt = event?.startsAt ?? defaultDate

  const defaultValues = {
    name: item?.name ?? "",
    length: event?.length ?? 1,
    startsAt: startsAt
      ? DateTime.fromISO(startsAt, { zone: "utc" }).toISODate()
      : DateTime.local().toISODate(),
    partition: item?.partition ?? 0,
    categoryUuid: notesCategory?.uuid ?? "",
    notes: "",
    styleUuid: item?.styleUuid,
  }

  return (
    <Modal
      title={item ? "Edit Note" : "New Note"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <NoteForm
        loading={loading || loadingItem || loadingCategories}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Modal>
  )
}
