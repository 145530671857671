import React, { useState, useEffect } from "react"
import { PropTypes } from "prop-types"
import { Field } from "redux-form"
import { Link, useLocation, useHistory } from "react-router-dom"
import queryString from "query-string"
import { Modal, Form, Indicator, Flex, Background } from "../../shared"
import { hasExpired, decode } from "../../utils/tokenStore"
import Helmet from "react-helmet"
import { Button } from "ui"

/**
 * Displays a modal form view that allows the user to create a new
 * password provided the authorization token included in the query
 * string is still valid.
 */
const ResetPasswordFormView = ({
  submitForm,
  autoLogin,
  submitValidate,
  submitting,
  handleSubmit,
  error,
  change,
}) => {
  const [validToken, setValidToken] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const token = (queryString.parse(location.search) || {}).token
  useEffect(() => {
    try {
      setValidToken(!hasExpired(token))
      change("email", decode(token).email)
    } catch (err) {
      setValidToken(false)
    }
    change("token", token)
  }, [token, change, setValidToken])

  const handleSubmitForm = (values) => {
    return submitForm(values.password, values.token)
      .then(submitValidate)
      .then(() => {
        return autoLogin({
          username: values.email,
          password: values.password,
        })
      })
      .then(() => {
        history.push("/login")
      })
  }

  return (
    <Background.Fun>
      <Helmet title="Reset Your Password" />
      <Modal.Dialog extended={true}>
        <form onSubmit={handleSubmit((values) => handleSubmitForm(values))}>
          <Modal.Header>
            <Modal.Title title="Reset Your Password" />
          </Modal.Header>
          {validToken && (
            <Modal.Body>
              <Field
                name="password"
                component={Form.ModalFieldInput}
                type="password"
                label="Password"
                placeholder="Enter your new password"
                disabled={submitting}
              />
              <Field
                name="passwordConfirmation"
                component={Form.ModalFieldInput}
                type="password"
                label="Confirm Password"
                placeholder="Retype your new password"
                disabled={submitting}
              />
            </Modal.Body>
          )}
          <Modal.Footer>
            <div>
              {!validToken && (
                <Form.Message error={true}>
                  You can no longer reset your password with this link.
                </Form.Message>
              )}
              {error && <Form.Message error={true}>{error}</Form.Message>}
            </div>
            {validToken && (
              <Button type="submit" align="stretch" disabled={submitting}>
                <Flex.Container>
                  {submitting && <Indicator.Ring theme={"button"} />} Update
                  Password
                </Flex.Container>
              </Button>
            )}
            <Modal.Prompt>
              {!validToken && (
                <Link to="/forgot-password">Request a New Token</Link>
              )}
            </Modal.Prompt>
          </Modal.Footer>
        </form>
      </Modal.Dialog>
    </Background.Fun>
  )
}

ResetPasswordFormView.propTypes = {
  /**
   * An event handler passed in from redux form.
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * Indicates whether or not the form is currently performing
   * an asynchronous action.
   */
  submitting: PropTypes.bool.isRequired,

  /**
   * A redux action creator that should already be mapped to
   * dispatch that will allow us to login the user after successfully
   * resetting their password.
   */
  autoLogin: PropTypes.func.isRequired,

  /**
   * A redux form action creator that allows us to populate
   * form values in the redux store.
   */
  change: PropTypes.func.isRequired,

  /**
   * A redux acrtion creator that should already be mapped to
   * dispatch which will let us send the form contents to the
   * api.
   */
  submitForm: PropTypes.func.isRequired,

  /**
   * An error message to display at the bottom of the form.
   */
  error: PropTypes.string,

  /**
   * A redux form validator that is executed upon form submission.
   */
  submitValidate: PropTypes.func.isRequired,
}

export default ResetPasswordFormView
