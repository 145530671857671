var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFile } from "@fortawesome/pro-light-svg-icons/faFile";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons/faFilePdf";
import { faFilePowerpoint } from "@fortawesome/pro-light-svg-icons/faFilePowerpoint";
import { faFileExcel } from "@fortawesome/pro-light-svg-icons/faFileExcel";
import { faFileWord } from "@fortawesome/pro-light-svg-icons/faFileWord";
import { faFileCsv } from "@fortawesome/pro-light-svg-icons/faFileCsv";
import { faFileImage } from "@fortawesome/pro-light-svg-icons/faFileImage";
import { Sortable } from "../../Behavior";
import { ItemTypes } from "./ItemTypes";
import { Sendable } from "./Sendable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var ATTACHMENT_STYLES = "flex flex-grow m-1 relative bg-gray-300 bg-opacity-25";
var PREVIEW_WRAPPER_STYLES = "flex box-border m-0 w-full p-2";
var ICON_STYLES = "box-border m-0";
var TEXT_CONTENT_STYLES = "box-border m-0 w-full pl-4 my-auto";
var Icon = function (_a) {
    var fileId = _a.fileId;
    var extension = fileId.split(".").reverse()[0];
    var icon = faFile;
    switch (extension) {
        case "pdf":
            icon = faFilePdf;
            break;
        case "xls":
            icon = faFileExcel;
            break;
        case "xlsx":
            icon = faFileExcel;
            break;
        case "ppt":
            icon = faFilePowerpoint;
            break;
        case "doc":
            icon = faFileWord;
            break;
        case "docx":
            icon = faFileWord;
            break;
        case "csv":
            icon = faFileCsv;
            break;
        case "jpg":
            icon = faFileImage;
            break;
        case "jpeg":
            icon = faFileImage;
            break;
        case "png":
            icon = faFileImage;
            break;
        case "gif":
            icon = faFileImage;
            break;
    }
    return _jsx(FontAwesomeIcon, { icon: icon, size: "2x" }, void 0);
};
export var AttachmentPreview = function (_a) {
    var id = _a.id, canDrop = _a.canDrop, preview = _a.preview, progress = _a.progress, onSort = _a.onSort, onFinishSort = _a.onFinishSort, onDelete = _a.onDelete, index = _a.index;
    return (_jsx(Sortable, __assign({ id: id, index: index, type: ItemTypes.File, onSort: onSort, onFinishSort: onFinishSort, sortDirection: "vertical" }, { children: _jsx("div", __assign({ className: ATTACHMENT_STYLES }, { children: _jsx(Sendable, __assign({ onDelete: onDelete, deletable: canDrop, progress: progress, preview: preview, listMode: true }, { children: preview ? (_jsxs("div", __assign({ className: PREVIEW_WRAPPER_STYLES }, { children: [_jsx("div", __assign({ className: ICON_STYLES }, { children: _jsx(Icon, { fileId: preview.id }, void 0) }), void 0), _jsx("div", __assign({ className: TEXT_CONTENT_STYLES }, { children: _jsx("p", { children: preview.name }, void 0) }), void 0)] }), void 0)) : null }), void 0) }), void 0) }), void 0));
};
