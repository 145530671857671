import { GroupDataStructure } from "../atoms"
import { useApiRequest, RequestParams } from "./useApiRequest"
export interface UpdateGroupBody {
  name: string
}

export interface UpdateGroupParams extends RequestParams {
  uuid: string
}

export const useUpdateGroup = (subdomain: string) => {
  const updateGroup = useApiRequest<
    GroupDataStructure,
    UpdateGroupBody,
    UpdateGroupParams
  >({
    method: "PUT",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/:uuid`,
  })
  return updateGroup
}
