import { useMemo } from "react";
import { debounce, throttle } from "lodash";
import { useEventListener } from "./useEventListener";
export var useResize = function (callback, wait, method) {
    if (wait === void 0) { wait = 250; }
    var methodFn = method === "debounce" ? debounce : throttle;
    var handleResize = useMemo(function () {
        return wait !== 0
            ? methodFn(function (event) { return callback(event); }, wait, {
                leading: false,
                trailing: true,
            })
            : function (event) { return callback(event); };
    }, [wait, callback, methodFn]);
    useEventListener({
        type: "resize",
        listener: handleResize,
    });
};
