import React, { useState } from "react"
import {
  Modal,
  PushPullForm,
  ModalProps,
  PushPullFormValues,
  AsyncSubmitHandler,
} from "ui"
import toast from "react-hot-toast"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useCategories, useItems, useTags, pushCalendar } from "../api"
import { faFolder, faTag } from "@fortawesome/pro-regular-svg-icons"
import { wait } from "utils"
import { DateTime } from "luxon"
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons"

export interface PushPullModalProps {
  anchorDate?: string
  itemsToMove?: string
  action?: "Push" | "Pull"
}

export const PushPullModal: React.FC<PushPullModalProps & ModalProps> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  zIndex,
  anchorDate,
  itemsToMove = "",
  action = "Push",
}) => {
  const [loading, setLoading] = useState(false)
  const { calendarId, subdomain } = useParams<{
    subdomain: string
    calendarId: string
  }>()
  const dispatch = useDispatch()

  const { loading: loadingCategories, categories } = useCategories(
    subdomain,
    calendarId
  )
  const { loading: loadingItems, items } = useItems(subdomain, calendarId)
  const { loading: loadingTags, tags } = useTags(subdomain, calendarId)

  const eventStartDates =
    itemsToMove
      .split(" ")
      .map((i) => i.split("#"))
      .filter((i) => i[0] === "item")
      .map(
        (i) =>
          items.find((item: any) => item.uuid === i[1])?.getEvents()?.[0]
            ?.startsAt
      )
      .filter((i) => i)
      .sort() ?? []

  const loadingContent =
    loading || loadingCategories || loadingItems || loadingTags

  const handleSearch = (_: string) => {}

  const searchResults = [
    ...categories.map((c: any) => ({
      label: c.name,
      secondaryLabel: "Category",
      id: `category#${c.uuid}`,
      value: `category#${c.uuid}`,
      icon: faFolder,
    })),
    ...items.map((i: any) => ({
      label: i.name,
      secondaryLabel: "Item",
      id: `item#${i.uuid}`,
      value: `item#${i.uuid}`,
      icon: faFileAlt,
    })),
    ...tags
      .filter((tag?: string) => tag?.length)
      .map((tag: string) => ({
        label: tag,
        secondaryLabel: "Tag",
        id: `tag##${tag}#`,
        value: `tag##${tag}#`,
        icon: faTag,
      })),
  ]

  const onSubmit: AsyncSubmitHandler<PushPullFormValues> = async (data) => {
    const { anchorDate, pushBy, itemsToMove: itemsFromData } = data
    setLoading(true)
    await wait(1)
    const filters = {
      categories: itemsFromData
        .split(" ")
        .filter((i) => i.includes("category"))
        .map((i) => i.split("#")[1]),
      items: itemsFromData
        .split(" ")
        .filter((i) => i.includes("item"))
        .map((i) => i.split("#")[1]),
      tags: itemsFromData
        .split(" ")
        .filter((i) => i.includes("tag"))
        .map((i) => i.split("tag##")[1].replaceAll("#", "")),
    }
    try {
      const response = (await dispatch(
        pushCalendar(subdomain, calendarId, pushBy, anchorDate, filters)
      )) as any
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(`Successfully pushed calendar".`)
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  const defaultDate = anchorDate
    ? DateTime.fromISO(anchorDate, { zone: "utc" })
    : DateTime.fromISO(eventStartDates?.[0], { zone: "utc" }) ??
      DateTime.now().toUTC()

  const pushBy = action === "Pull" ? -1 : 1

  return (
    <Modal
      title={`${action} Calendar`}
      onClose={handleDismiss}
      onFocus={handleFocus}
      zIndex={zIndex}
      open
      draggable
    >
      <PushPullForm
        loading={loadingContent}
        onSearch={handleSearch}
        onSubmit={onSubmit}
        defaultValues={{
          anchorDate: defaultDate.toISODate(),
          pushTo: defaultDate.plus({ days: pushBy }).toISODate(),
          pushBy,
          itemsToMove,
        }}
        externalAnchorDate={defaultDate.toISODate()}
        searchResults={searchResults}
        action={action}
      />
    </Modal>
  )
}
