import React from "react"
import PropTypes from "prop-types"
import styles from "./Heading.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = ({ name, icon, sub }) => (
  <header className={styles.heading}>
    <h5 className={styles.formHeading}>
      {icon ? <FontAwesomeIcon icon={icon} className={styles.icon} /> : null}
      {name}
    </h5>
    {sub ? <h6 className={styles.formSubHeading}>{sub}</h6> : null}
  </header>
)

Header.propTypes = {
  name: PropTypes.string.isRequired,
  sub: PropTypes.string,
  icon: PropTypes.string,
}

const Heading = ({ name, sub, onClick, icon }) => (
  <div style={{ display: "flex" }}>
    {onClick ? (
      <button
        className={styles.formHeadingButton}
        onClick={onClick}
        type="button"
      >
        <Header name={name} icon={icon} sub={sub} />
      </button>
    ) : (
      <Header name={name} icon={icon} sub={sub} />
    )}
  </div>
)

Heading.propTypes = {
  name: PropTypes.string.isRequired,
  sub: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

export default Heading
