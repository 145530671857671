import { useState, useCallback } from "react";
import { useResize } from "./useResize";
export var useWindowSize = function (wait, method) {
    if (wait === void 0) { wait = 250; }
    if (method === void 0) { method = "throttle"; }
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), windowSize = _a[0], setWindowSize = _a[1];
    var getWindowSize = useCallback(function () { return ({
        width: window.innerWidth,
        height: window.innerHeight,
    }); }, []);
    useResize(function () {
        setWindowSize(getWindowSize);
    }, wait, method);
    return windowSize;
};
