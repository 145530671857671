import React from "react"
import PropTypes from "prop-types"
import styles from "./Message.module.css"

const styleForError = (error) => {
  return error ? styles.errorMessage : styles.helperMessage
}

const Message = ({ error, children }) => (
  <p className={styleForError(error)}>{children}</p>
)

Message.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
}

export default Message
