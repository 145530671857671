import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarIcon.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ToolBarIcon = ({ icon, required, dark }) => (
  <FontAwesomeIcon
    icon={icon}
    className={`${styles.toolbarIcon} ${dark && styles.dark} ${
      required && styles.required
    }`}
  />
)

ToolBarIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  required: PropTypes.bool,
  dark: PropTypes.bool,
}

export default ToolBarIcon
