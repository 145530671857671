var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
var getInitials = function (name) {
    return ("" + (name !== null && name !== void 0 ? name : ""))
        .split(" ")
        .reduce(function (a, b) { return a + b.charAt(0); }, "")
        .substring(0, 2);
};
var AVATAR_STYLES = "flex rounded-full hover:shadow-md transition-shadow duration-500 ease-out";
var RESPONSIVE_STYLES = "h-6 w-6 text-xs md:h-8 md:w-8 md:text-sm lg:h-10 lg:w-10 lg:text-md";
var EXTRA_SMALL_STLYES = "h-6 w-6 text-xs";
var SMALL_STLYES = "h-8 w-8 text-sm";
var MEDIUM_STYLES = "h-10 w-10 text-md";
var LARGE_STYLES = "h-12 w-12 text-lg";
var styleForSize = function (size) {
    switch (size) {
        case "lg":
            return LARGE_STYLES;
        case "md":
            return MEDIUM_STYLES;
        case "sm":
            return SMALL_STLYES;
        case "xs":
            return EXTRA_SMALL_STLYES;
        case "responsive":
            return RESPONSIVE_STYLES;
    }
};
export var Avatar = function (_a) {
    var name = _a.name, color = _a.color, _b = _a.size, size = _b === void 0 ? "md" : _b, imageUrl = _a.imageUrl, className = _a.className;
    return (_jsx("div", __assign({ className: clsx(AVATAR_STYLES, className, styleForSize(size)), style: { background: color !== null && color !== void 0 ? color : "" } }, { children: imageUrl ? (_jsx("span", { className: "w-full h-full flex rounded-full bg-center bg-cover", style: { backgroundImage: "url(" + imageUrl + ")" } }, void 0)) : (_jsx("span", __assign({ className: "flex m-auto font-body font-semibold text-white" }, { children: getInitials(name) }), void 0)) }), void 0));
};
