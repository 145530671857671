import React, { useState } from "react"
import moment from "moment"
import { useActiveUsers } from "./hooks"
import { Button } from "ui"
import { Panel } from "./Panel"
import { Title } from "./Title"
import { Results } from "./Resullts"
import { StylableProps } from "./Styleable"

interface UsersPanelProps {
  maxResults?: number
  days?: number
}
export const ActiveUsersPanel: React.FC<StylableProps & UsersPanelProps> = ({
  maxResults = 10,
  days = 30,
  ...props
}) => {
  const { loading, activeUsers } = useActiveUsers(days)
  const [showAll, setShowAll] = useState(false)
  const handleShowAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setShowAll(!showAll)
  }
  return (
    <Panel {...props} loading={loading}>
      <Title>
        {(activeUsers ?? []).length} Active Users (Last {days} days)
      </Title>
      <Results>
        <>
          <p className="px-2 w-1/3 text-sm font-bold">User Name</p>
          <p className="px-2 w-1/3 text-sm font-bold">Last Active</p>
          <p className="px-2 w-1/3 text-sm font-bold">Organization</p>
        </>
        {(activeUsers ?? [])
          .filter((_: any, i: any) =>
            showAll ? true : maxResults ? i <= maxResults : true
          )
          .map((metric: any, i: number) => (
            <React.Fragment key={metric.id}>
              <p className="px-2 w-1/3 text-sm flex-grow-0 truncate">
                {metric.meta1}
              </p>
              <p className="px-2 w-1/3 text-sm flex-grow-0 truncate">
                {moment(metric.value).format("MMM DD h:mm A")}
              </p>
              <p className="px-2 w-1/3 text-sm flex-grow-0 truncate">
                {metric.meta2}
              </p>
            </React.Fragment>
          ))}
        {activeUsers.length <= maxResults ? (
          <p className="px-2 w-full text-sm flex-grow-0 truncate font-italic text-gray-500">
            {activeUsers.length
              ? `Displaying all ${activeUsers.length} results.`
              : "No results found."}
          </p>
        ) : (
          <Button onClick={handleShowAll} appearance="secondary">
            {showAll ? "Show Less" : "Show All"}
          </Button>
        )}
      </Results>
    </Panel>
  )
}
