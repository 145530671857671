import { useApiRequest, RequestParams } from "./useApiRequest"
import { GroupDataStructure } from "../atoms"

export interface DeleteGroupParams extends RequestParams {
  uuid: string
}

export const useDeleteGroup = (subdomain: string) => {
  const deleteRequest = useApiRequest<
    GroupDataStructure,
    GroupDataStructure,
    DeleteGroupParams
  >({
    method: "DELETE",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/:uuid`,
  })

  return deleteRequest
}
