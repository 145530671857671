var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useRef } from "react";
// utils
import debounce from "lodash.debounce";
import { concatStyles, remCalc, wait } from "utils";
// ui
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Indicator from "../Indicators";
import { TextField } from "./TextField";
import { Overlay } from "../Overlay";
var CONTAINER_STYLES = "flex flex-grow flex-col w-full relative font-body";
var RESULT_STYLES = "bg-white flex flex-col border-2 border-t-0 shadow rounded-b overflow-y-auto w-full ";
var RESULT_ITEM_STYLES = "border-b border-contentAreaBorder text-left text-sm";
var getPositionForMethod = function (push) {
    return push ? "relative" : "absolute mt-0 top-100 z-50";
};
var FIELD_STYLES = "flex flex-grow bg-white w-auto h-auto mb-auto relative w-full p-2 rounded focus:outline-none overflow-hidden";
var ACTIVE_FIELD_STYLES = "border border-actionable shadow";
var INACTIVE_FIELD_STYLES = "border border-contentAreaBorder";
var ERROR_FIELD_STYLES = "border border-error";
var getFieldAppearance = function (active, error) {
    return active
        ? ACTIVE_FIELD_STYLES
        : error
            ? ERROR_FIELD_STYLES
            : INACTIVE_FIELD_STYLES;
};
var SEARCH_FIELD_WRAPPER_STYLES = "sticky top-0 z-50 text-body min-w-0 max-w-full p-2";
var LABEL_STYLES = "flex my-auto mx-0 truncate";
var getLabelOpacityStyle = function (active) { return (active ? "" : "opacity-25"); };
var OVERLAY_STYLES = "fixed z-40 h-screen w-screen top-0 left-0";
var ICON_WRAP_STYLES = "absolute m-0 p-3 flex flex-grow text-lg text-white font-body inset-y-0 right-0";
var ACTIVE_ICON_WRAP_STYLES = "border-sorbus-default";
var INACTIVE_ICON_WRAP_STYLES = "bg-lochivar-default";
var ERROR_ICON_WRAP_STYLES = "bg-error";
var ICON_STYLES = "m-auto";
var getIconWrapAppearance = function (active, error) {
    return active
        ? ACTIVE_ICON_WRAP_STYLES
        : error
            ? ERROR_ICON_WRAP_STYLES
            : INACTIVE_ICON_WRAP_STYLES;
};
export var Dropdown = React.forwardRef(function (_a, forwardRef) {
    var _b, _c;
    var className = _a.className, name = _a.name, pushContent = _a.pushContent, hideSearch = _a.hideSearch, value = _a.value, children = _a.children, placeholder = _a.placeholder, onDismiss = _a.onDismiss, onSearch = _a.onSearch, loading = _a.loading, style = _a.style, error = _a.error, externalActive = _a.active;
    var _d = useState(false), active = _d[0], setActive = _d[1];
    var _e = useState(""), searchValue = _e[0], setSearchValue = _e[1];
    var buttonRef = useRef(null);
    var updateSearch = debounce(function (updatedVal) {
        setSearchValue(updatedVal);
        if (onSearch) {
            onSearch(updatedVal);
        }
    }, 300, { leading: false, trailing: true });
    var onClick = function (e) {
        e.preventDefault();
        setActive(!active);
    };
    var onChange = function (e) {
        return updateSearch(e.target.value);
    };
    var dismiss = function () {
        setActive(false);
        setSearchValue("");
        if (onDismiss) {
            onDismiss();
        }
    };
    var delayedDismiss = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, wait(0.2)];
                case 1:
                    _a.sent();
                    dismiss();
                    return [2 /*return*/];
            }
        });
    }); };
    var hasValue = typeof value === "string" && value.length > 0;
    var isActive = externalActive !== null && externalActive !== void 0 ? externalActive : active;
    var _f = (_c = (_b = buttonRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) !== null && _c !== void 0 ? _c : {}, x = _f.x, y = _f.y, height = _f.height, width = _f.width;
    var resultListStyles = pushContent
        ? {}
        : {
            top: (y !== null && y !== void 0 ? y : 0) + (height !== null && height !== void 0 ? height : 0),
            left: x,
            width: width,
        };
    var renderItems = function () { return (_jsxs("ul", __assign({ className: concatStyles([
            RESULT_STYLES,
            getPositionForMethod(pushContent),
        ]), style: __assign({ maxHeight: remCalc(256) }, resultListStyles) }, { children: [hideSearch ? null : (_jsxs("li", __assign({ className: SEARCH_FIELD_WRAPPER_STYLES }, { children: [_jsx(TextField, { name: name + ".search", icon: faSearch, type: "text", onChange: onChange }, void 0), loading ? _jsx(Indicator.Spinner, { className: "mr-2" }, void 0) : null] }), void 0)), children(searchValue, dismiss).map(function (child, index) { return (_jsx("li", __assign({ className: RESULT_ITEM_STYLES, onClick: delayedDismiss }, { children: child }), "dropdown" + name + "Option" + index)); })] }), void 0)); };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: concatStyles([CONTAINER_STYLES, className]), style: style }, { children: [_jsxs("button", __assign({ onClick: onClick, type: "button", ref: buttonRef, className: concatStyles([
                        FIELD_STYLES,
                        getFieldAppearance(isActive, error),
                    ]) }, { children: [_jsx("label", __assign({ className: concatStyles([
                                LABEL_STYLES,
                                getLabelOpacityStyle(hasValue),
                            ]) }, { children: hasValue ? value : placeholder }), void 0), _jsx("p", __assign({ className: concatStyles([
                                ICON_WRAP_STYLES,
                                getIconWrapAppearance(isActive, error),
                            ]) }, { children: _jsx(FontAwesomeIcon, { className: ICON_STYLES, icon: faChevronDown }, void 0) }), void 0)] }), void 0), _jsx("input", { type: "hidden", name: name, ref: forwardRef }, void 0), isActive ? (pushContent ? (renderItems()) : (_jsxs(Overlay, { children: [renderItems(), _jsx("div", { className: OVERLAY_STYLES, onClick: dismiss }, void 0)] }, void 0))) : null] }), void 0) }, void 0));
});
Dropdown.displayName = "Form.Dropdown";
