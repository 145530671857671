import React from "react"
import PropTypes from "prop-types"
import styles from "./ShortListSearchField.module.css"

const ShortListSearchField = ({ onChange, placeholder }) => (
  <input
    type="text"
    name="search"
    className={styles.searchField}
    onChange={onChange}
    placeholder={placeholder}
    autoComplete="off"
  />
)

ShortListSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default ShortListSearchField
