var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concatStyles } from 'utils';
var CONTAINER_STYLES = 'flex flex-col text-center h-full font-bold text-primary font-body p-2 w-full';
var ICON_WRAPPER_STYLES = 'text-xl rounded-full h-10 w-10 text-center mx-auto mt-auto flex';
var ICON_STYLES = 'm-auto';
var ACTIVE_STYLES = 'bg-white text-actionable border-2 border-actionable';
var INACTIVE_STYLES = 'bg-actionable text-white border-2 border-transparent';
var LABEL_STYLES = 'mx-auto mt-0 mb-auto';
var ACTIVE_LABEL_STYLES = 'text-actionable';
export var DropTarget = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "Drag 'n' drop some files here, or click to select files" : _b, active = _a.active;
    return (_jsxs("div", __assign({ className: concatStyles([CONTAINER_STYLES, active ? ACTIVE_STYLES : '']) }, { children: [_jsx("p", __assign({ className: concatStyles([
                    ICON_WRAPPER_STYLES,
                    active ? ACTIVE_STYLES : INACTIVE_STYLES,
                ]) }, { children: _jsx(FontAwesomeIcon, { icon: faArrowUp, className: ICON_STYLES }, void 0) }), void 0), _jsx("p", __assign({ className: concatStyles([
                    LABEL_STYLES,
                    active ? ACTIVE_LABEL_STYLES : '',
                ]) }, { children: label }), void 0)] }), void 0));
};
