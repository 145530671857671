import React, { useState } from "react"
import Helmet from "react-helmet"
import { AsyncSubmitHandler, SignupForm, SignupFormValues, Modal } from "ui"
import toast from "react-hot-toast"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { wait } from "utils"
import { registerAndLoginUser, invitationSelector } from "../api"
import { Avatar } from "ui"

export interface SignupModalProps {}

export const SignupModal: React.FC<SignupModalProps> = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const pendingInvitation = useSelector(
    invitationSelector.pendingInvitationEntity
  )
  const token = useSelector(invitationSelector.pendingInvitationToken)

  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<SignupFormValues> = async (data) => {
    const { name, email, password, confirm } = data
    setLoading(true)
    await wait(1)

    console.log("DATA", data)

    try {
      const { response } = (await dispatch(
        registerAndLoginUser({
          name,
          username: email,
          password,
          confirm,
          token,
        })
      )) as any
      if ([200, 201].includes(response.status)) {
        toast.success("Your account has been created. Welcome to PROCal!")
        history.push(`/org/${pendingInvitation.organization}`)
      } else {
        toast.error("There was a problem connecting to the server.")
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  return (
    <Modal title="Create Your Account" open>
      <Helmet title="Create Your Account" />
      {pendingInvitation?.organization && (
        <header className="flex mt-2 mb-4 pb-4 border-b border-gray-200">
          <Avatar
            name={(pendingInvitation?.organization ?? "")
              .split("-")
              .map((s: string) => s.toUpperCase())
              .join(" ")}
            color="#4e83bc"
          />

          <div className="flex flex-col my-auto ml-3">
            <h2 className="text-sm">You have been invited to</h2>
            <h3 className="font-bold">{pendingInvitation?.organization}</h3>
          </div>
        </header>
      )}
      {pendingInvitation?.organization ? (
        <SignupForm
          onSubmit={onSubmit}
          loading={loading}
          defaultValues={{
            email: pendingInvitation?.email ?? "",
            name: pendingInvitation?.name ?? "",
            password: "",
            confirm: "",
          }}
        />
      ) : (
        <p className="p-4 rounded mb-4 bg-gray-200">
          Your invitation could not be found. Please request another invitation
          from the organization who invited you to join PROCal.
        </p>
      )}
      <p className="text-center mx-auto pb-3">
        Existing user?{" "}
        <Link to="/login" className="text-lochivar-default font-bold">
          Click here to login.
        </Link>
      </p>
      <p className="border-t border-gray-400 my-3 pt-3 text-center">
        <strong>Have questions or suggestions?</strong>
        <br />
        Please email <a href="mailto:support@revolutiones.com">support</a> or
        call 818.303.3306
      </p>
      <p className="text-xs text-center">
        Powered by{" "}
        <a href="https://www.revolutiones.com" className="text-sorbus-default">
          Revolution Entertainment Services
        </a>
      </p>
      <p className="text-xs text-center">
        <a
          href="http://www.revolutiones.com/#/termsofuse"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Terms of Service
        </a>{" "}
        |{" "}
        <a
          href="http://www.revolutiones.com/#/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Privacy Policy
        </a>
      </p>
    </Modal>
  )
}
