import { atom, useRecoilState } from "recoil"
import { DrawerContent } from "ui"
import { useCallback } from "react"

export const positionSort = (a: DrawerContent, b: DrawerContent) =>
  a.position > b.position ? 1 : a.position < b.position ? -1 : 0

export const calendarListItems = (subdomain: string) =>
  atom({
    key: `${subdomain}.calendarListItems`,
    default: [] as DrawerContent[],
  })

export const deletedListItems = atom({
  key: "deletedListItems",
  default: [] as string[],
})

export const openListItems = atom({
  key: "openListItems",
  default: [] as string[],
})

export const useCalendarListItems = (subdomain: string) => {
  const [existingItems, setListItems] = useRecoilState(
    calendarListItems(subdomain)
  )
  const [deletedItems] = useRecoilState(deletedListItems)
  const [openItems] = useRecoilState(openListItems)
  const listItems = existingItems
    .filter((i) => !deletedItems.includes(i.id))
    .map((i) => ({ ...i, open: openItems.includes(i.id) }))
    .sort(positionSort) as DrawerContent[]
  return { listItems, deletedItems, setListItems }
}

export const useToggleListItem = () => {
  const [items, setItems] = useRecoilState(openListItems)
  return useCallback(
    (id: string, open: boolean) =>
      setItems(open ? [...items, id] : [...items.filter((i) => i !== id)]),
    [items, setItems]
  )
}

export const useRemoveCalendarListItem = () => {
  const [items, setItems] = useRecoilState(deletedListItems)
  return useCallback(
    (id: string) => setItems([...items, id]),
    [items, setItems]
  )
}
