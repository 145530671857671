import React, { useState } from "react"
import { Modal, StyleList, ModalProps } from "ui"
import toast from "react-hot-toast"
import { deleteStyle, useStyles } from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"
import { ConnectedStyleForm } from "./ConnectedStyleForm"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"

export interface ThemeModalProps {
  onEditStyle: (uuid: string) => void
  onDuplicateStyle: (uuid: string) => void
  onCreateStyle?: () => void
}

export const ThemeModal: React.FC<ThemeModalProps & ModalProps> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  zIndex,
}) => {
  const [deletedCache, setDeletedCache] = useState([] as string[])
  const [loading, setLoading] = useState(false)
  const params = useParams<{ subdomain: string; calendarId: string }>()
  const [styleUuid, setStyleUuid] = useState(null as string | null)
  const { styles } = useStyles(params.subdomain, params.calendarId)
  const handleEditStyle = (uuid: string) => {
    setStyleUuid(uuid)
  }
  const dispatch = useDispatch()
  const handleCreateStyleClick = () => {
    setStyleUuid("")
  }

  const handleDeleteStyle = async (uuid: string) => {
    const style = styles.filter((s: any) => s.uuid === uuid)
    setLoading(true)
    await wait(1)
    await dispatch(deleteStyle(params.subdomain, params.calendarId, uuid))
    toast.success(`Successfully deleted style "${style.name}".`)
    setDeletedCache([...deletedCache, uuid])
    setLoading(false)
  }
  return (
    <Modal
      title={
        styleUuid === ""
          ? "Create Style"
          : styleUuid
          ? "Edit Style"
          : "Manage Theme"
      }
      onClose={handleDismiss}
      onFocus={handleFocus}
      zIndex={zIndex}
      open
      draggable
    >
      {styleUuid === "" || styleUuid ? (
        <>
          <button
            className="flex text-sm font-bold text-sorbus-default px-2"
            onClick={() => {
              setStyleUuid(null)
            }}
          >
            <FontAwesomeIcon className="my-auto" icon={faArrowLeft} />{" "}
            <span className="underline ml-2 my-auto">Manage Theme</span>
          </button>
          <ConnectedStyleForm
            styleUuid={styleUuid}
            onSave={() => setStyleUuid(null)}
          />
        </>
      ) : (
        <div
          className={clsx(
            loading ? "opacity-40" : "opacity-100",
            "flex flex-grow overflow-y-auto flex-col"
          )}
          style={{ height: 360 }}
        >
          <StyleList
            styles={styles
              .filter((s: any) => deletedCache.indexOf(s.uuid) < 0)
              .map((s: any) =>
                s.name ? s : { ...s, name: `Unnamed ${s.id}` }
              )}
            onEditStyle={handleEditStyle}
            onDeleteStyle={handleDeleteStyle}
            onAddStyle={handleCreateStyleClick}
          />
        </div>
      )}
    </Modal>
  )
}
