import React from "react"
import PropTypes from "prop-types"
import { Form } from "../../shared"
import styles from "./autoCompleteField.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

class AutoCompleteField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      filterText: "",
    }
  }

  render() {
    const { placeholder, label, filterResults, keyPrefix, onSelectResult } =
      this.props
    const { filterText, visible } = this.state
    const handleChange = (e) => {
      this.setState({ filterText: e.currentTarget.value })
    }
    const toggleAutocomplete = (visible) => () => {
      this.setState({ visible, filterText: "" })
    }
    const reducedResults = filterResults(filterText)
    return (
      <Form.Group>
        {label ? <label htmlFor="filter">{label}</label> : <span />}
        <div className={styles.autoCompleteInputGroup}>
          <Form.Input
            name="filter"
            placeholder={placeholder}
            type="text"
            value={filterText}
            autoComplete="off"
            onChange={handleChange}
            onFocus={toggleAutocomplete(true)}
            ref={(textInput) => {
              this.filterInput = textInput
            }}
          />
          {visible && <span className={styles.autoCompleteWrap} />}
          {visible && (
            <ul className={styles.autoCompleteResults}>
              <li
                className={styles.autoCompleteItem}
                onClick={toggleAutocomplete(false)}
              >
                <FontAwesomeIcon icon={faTimes} className={styles.inlineIcon} />
                Done
              </li>
              {reducedResults.map((result) => (
                <li
                  className={styles.autoCompleteItem}
                  key={`result${keyPrefix}_${result.id}`}
                  onClick={onSelectResult(result)}
                >
                  <FontAwesomeIcon
                    icon={result.icon}
                    className={styles.inlineIcon}
                  />
                  {result.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Form.Group>
    )
  }
}

AutoCompleteField.propTypes = {
  /**
   * The label text that will appear above the search field.
   */
  label: PropTypes.string,

  /**
   * An optional prefix to ensure there are no collisions if multiple autocomplete fields are present on the same form.
   */
  keyPrefix: PropTypes.string,

  /**
   * The placeholder text to appear in the text field.
   */
  placeholder: PropTypes.string,

  /**
   * A lambda that should perform a reduction of the results. It accepts the current search string as a param. Should return items as {id: string, name: string, icon: string}
   */
  filterResults: PropTypes.func.isRequired,

  /**
   * A lambda that will be called when an item is selected from the results list. It accepts the chosen item as a param. The item is structured as follows: {id: string, name: string, icon: string}
   */
  onSelectResult: PropTypes.func.isRequired,
}

export default AutoCompleteField
