import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"
import moment from "moment"

/**
 * Ensures any supplied time is formatted as UTC time.
 *
 * @param  {Object} timeString  A string representing a time.
 * @return {Object}             A string of the date transformed to UTC.
 */
const applyUTC = (timeString) => moment.utc(timeString).utc().format()

/**
 * Ensures any times in the request body are transformed
 * to UTC time in order to have consistent communication
 * between the API and the client.
 *
 * @param  {Object} holiday An object representing the holiday data.
 * @return {Object}       A cloned object with the dates transformed to UTC.
 */
const applyUTCForHoliday = (holiday) => {
  const { startsAt, ...rest } = holiday
  return {
    startsAt: applyUTC(startsAt),
    ...rest,
  }
}

//
// GET / Find all OR by :calendar
//

/**
 * Action creator that generates an API call to fetch all holiday schedules
 * or a specific holiday schedule by ID.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Integer} id           The (optional) id of the holiday to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getHolidaySchedule = (subdomain, calendarId, id) => ({
  type: types.FETCH_HOLIDAY_SCHEDULES,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.HOLIDAY_SCHEDULE : Schemas.HOLIDAY_SCHEDULES,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/holiday_schedules/${
      id || ""
    }`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_HOLIDAY_SCHEDULES_REQUEST,
      types.FETCH_HOLIDAY_SCHEDULES_SUCCESS,
      types.FETCH_HOLIDAY_SCHEDULES_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetch all holidays
 * or a specific holiday by ID. This is a convenience method that wraps
 * the getHoliday() creator in a thunk.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Integer} id           The (optional) id of the holiday to fetch.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const requestHolidaySchedule =
  (subdomain, calendarId, id) => (dispatch) => {
    return dispatch(getHolidaySchedule(subdomain, calendarId, id))
  }

/**
 * Action creator that generates an API call to fetch all holidays
 * or a specific holiday by ID.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Integer} id           The (optional) id of the holiday to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getHoliday = (subdomain, calendarId, id) => ({
  type: types.FETCH_HOLIDAYS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.HOLIDAY : Schemas.HOLIDAYS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/observed_holidays/${
      id || ""
    }`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_HOLIDAYS_REQUEST,
      types.FETCH_HOLIDAYS_SUCCESS,
      types.FETCH_HOLIDAYS_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetch all holidays
 * or a specific holiday by ID. This is a convenience method that wraps
 * the getHoliday() creator in a thunk.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Integer} id           The (optional) id of the holiday to fetch.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const requestHoliday = (subdomain, calendarId, id) => (dispatch) => {
  return dispatch(getHoliday(subdomain, calendarId, id))
}

//
// POST / New Holiday
//

/**
 * Action creator that generates an API call to create a new holiday
 * for a specific calendar.
 *
 * @param  {Integer} subdomain    The subdomain of the organization of which the calendar for the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Object}  holiday      Holiday data or body for the API call.
 * @return {Object}               An object representing the redux action.
 */
export const postHoliday = (subdomain, calendarId, holiday) => ({
  type: types.CREATE_HOLIDAY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.HOLIDAY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/observed_holidays`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(applyUTCForHoliday(holiday))),
    types: [
      types.CREATE_HOLIDAY_REQUEST,
      types.CREATE_HOLIDAY_SUCCESS,
      types.CREATE_HOLIDAY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to create a new holiday
 * for a specific calendar. This is a convenience method that wraps
 * the postHoliday() creator in a thunk.
 *
 * @param  {Integer} subdomain    The id of the calendar the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Object}  holiday      Holiday data or body for the API call.
 * @return {Promise}            A promise representing the dispatched redux action creator.
 */
export const createHoliday = (subdomain, calendarId, holiday) => (dispatch) => {
  return dispatch(postHoliday(subdomain, calendarId, holiday))
}

//
// PUT / Update Holiday
//

/**
 * Action creator that generates an API call to update an existing holiday
 * against the API.
 *
 * @param  {Integer} subdomain    The id of the calendar the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Object}  holiday      Holiday data or body for the API call.
 * @return {Object}           An object representing the redux action.
 */
export const putHoliday = (subdomain, calendarId, holiday) => ({
  type: types.UPDATE_HOLIDAY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.HOLIDAY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/observed_holidays/${holiday.uuid}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(applyUTCForHoliday(holiday))),
    types: [
      types.UPDATE_HOLIDAY_REQUEST,
      types.UPDATE_HOLIDAY_SUCCESS,
      types.UPDATE_HOLIDAY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * holiday against the API. This is a convenience method that wraps
 * the putHoliday() creator in a thunk.
 *
 * @param  {Integer} subdomain    The id of the calendar the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Object}  holiday    Holiday data or body for the API call.
 * @return {Promise}            A promise representing the dispatched redux action creator.
 */
export const updateHoliday = (subdomain, calendarId, holiday) => (dispatch) => {
  return dispatch(putHoliday(subdomain, calendarId, holiday))
}

//
// DELETE / Delete existing Holiday via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * holiday against the API.
 *
 * @param  {Integer} subdomain    The id of the calendar the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {Integer} holidayUuid    The id of the holiday to delete.
 * @return {Promise}          An object representing the redux action.
 */
export const deleteHoliday = (subdomain, calendarId, holidayUuid) => ({
  type: types.DELETE_HOLIDAY,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.HOLIDAY,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/observed_holidays/${holidayUuid}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_HOLIDAY_REQUEST,
      types.DELETE_HOLIDAY_SUCCESS,
      types.DELETE_HOLIDAY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * holiday against the API. This is a convenience method that wraps
 * the deleteHoliday() creator in a thunk.
 *
 * @param  {Integer} subdomain    The id of the calendar the holiday belongs to.
 * @param  {Integer} calendarId   The id of the calendar the holiday belongs to.
 * @param  {String}  holidayUuid  The id of the holiday to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const removeHoliday =
  (subdomain, calendarId, holidayUuid) => (dispatch) => {
    return dispatch(deleteHoliday(subdomain, calendarId, holidayUuid))
  }
