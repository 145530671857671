var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { PreviewAction } from "./PreviewAction";
import { concatStyles } from "utils";
import { Progress } from "./Progress";
var CONTAINER_STYLES = "flex relative w-full flex-grow";
export var Sendable = function (_a) {
    var deletable = _a.deletable, preview = _a.preview, onDelete = _a.onDelete, progress = _a.progress, children = _a.children, listMode = _a.listMode;
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var _c = useState(null), hoverTimeout = _c[0], setHoverTimeout = _c[1];
    var deletePreview = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (onDelete) {
            onDelete(preview);
        }
    };
    var toggleHover = function (enabled) { return function () {
        if (hoverTimeout && enabled) {
            clearTimeout(hoverTimeout);
        }
        if (!enabled) {
            setHoverTimeout(setTimeout(function () { return setHovering(enabled); }, 25));
        }
        else {
            setHovering(enabled);
        }
    }; };
    var deleteVisible = !deletable && hovering ? true : false;
    var loading = progress && progress < 100;
    return (_jsxs("div", __assign({ className: CONTAINER_STYLES, onMouseOver: toggleHover(true), onMouseOut: toggleHover(false), onMouseDown: toggleHover(false) }, { children: [onDelete ? (_jsx(PreviewAction, __assign({ onClick: deletePreview, onMouseOver: toggleHover(true), visible: deleteVisible, verticallyCentered: listMode }, { children: _jsx(FontAwesomeIcon, { icon: faTrash }, void 0) }), void 0)) : (_jsx("span", {}, void 0)), _jsx("div", __assign({ className: concatStyles([
                    CONTAINER_STYLES,
                    loading ? "opacity-50" : "",
                ]) }, { children: children }), void 0), loading ? _jsx(Progress, { progress: progress }, void 0) : null] }), void 0));
};
