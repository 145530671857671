import { useState, useCallback, } from "react";
export var useScrollableCanvas = function () {
    var _a = useState(null), dragging = _a[0], setDragging = _a[1];
    var _b = useState(0), scroll = _b[0], setScroll = _b[1];
    var _c = useState(false), scrolling = _c[0], setScrolling = _c[1];
    var onScroll = function (e) {
        e.stopPropagation();
        var dy = e.deltaY;
        var y = scroll - dy;
        setScrolling(true);
        setScroll(y);
    };
    var handleDragStart = useCallback(function (e) {
        setDragging(e.evt.clientY);
    }, [setDragging]);
    var handleDragEnd = useCallback(function (e) {
        setDragging(null);
    }, [setDragging]);
    var handleDragMove = useCallback(function (e) {
        if (dragging) {
            setScroll(scroll - (dragging - e.evt.clientY));
            setDragging(e.evt.clientY);
        }
    }, [setDragging, setScroll, dragging, scroll]);
    var handleTouchStart = useCallback(function (e) {
        setDragging(e.evt.touches[0].clientY);
    }, [setDragging]);
    var handleTouchEnd = useCallback(function (e) {
        setDragging(null);
    }, [setDragging]);
    var handleTouchMove = useCallback(function (e) {
        if (dragging) {
            setScroll(scroll - (dragging - e.evt.touches[0].clientY));
            setDragging(e.evt.touches[0].clientY);
        }
    }, [setDragging, setScroll, dragging, scroll]);
    var htmlProps = {
        onWheel: onScroll,
    };
    var stageProps = {
        onMouseLeave: handleDragEnd,
    };
    var layerProps = {
        onMouseDown: handleDragStart,
        onMouseUp: handleDragEnd,
        onMouseMove: handleDragMove,
        onTouchStart: handleTouchStart,
        onTouchEnd: handleTouchEnd,
        onTouchMove: handleTouchMove,
    };
    return {
        scroll: scroll,
        setScroll: setScroll,
        scrolling: scrolling,
        setScrolling: setScrolling,
        htmlProps: htmlProps,
        stageProps: stageProps,
        layerProps: layerProps,
    };
};
