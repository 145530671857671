import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { ConnectionResponse } from "./types"
import { camelizeKeys } from "humps"

export interface ConnectionFilterParams extends RequestParams {
  name?: string
  calendarId?: string
}

export const useConnections = () => {
  const updateEntity = useUpdateEntity()

  const requestConnection = useApiRequest<
    ConnectionResponse[],
    Object,
    ConnectionFilterParams
  >({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/connections`,
    onResponse: (response) => {
      response.forEach((connection) => {
        if (connection.id) {
          updateEntity("connections", connection.id, {
            ...camelizeKeys(connection),
          })
        }
      })
    },
  })

  return requestConnection
}
