import React from "react"
import PropTypes from "prop-types"
import Indicator from "../Indicator"
import styles from "./ShortListItem.module.css"

const ShortListItem = ({
  children,
  selected,
  handleClick,
  secondaryAction,
  isFetching,
}) => (
  <li className={`${styles.listItem} ${selected && styles.selected}`}>
    <button
      onClick={(e) => handleClick && handleClick(e)}
      className={styles.button}
    >
      {children}
    </button>
    {isFetching && (
      <div className={styles.loadingContainer}>
        <Indicator.Ring />
      </div>
    )}
    {!isFetching && secondaryAction && secondaryAction}
  </li>
)

ShortListItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  secondaryAction: PropTypes.node,
  isFetching: PropTypes.bool,
}

export default ShortListItem
