import { SubmissionError } from "redux-form"
import { camelizeKeys } from "humps"

export const validate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  const { password, passwordConfirmation } = formProps
  if (password && password.length < 6) {
    errors.password = "Must be at least 6 characters long."
  }
  if (passwordConfirmation && passwordConfirmation !== password) {
    errors.passwordConfirmation = "Must match password."
  }
  return errors
}

export const submitValidate = (result) => {
  if (result.error) {
    const errors = camelizeKeys(result.payload.errors)
    throw new SubmissionError(
      Object.assign({}, errors, {
        _error: "There was a problem resetting your password.",
      })
    )
  }
  return result
}
