import flatten from "lodash/flatten"
import moment from "moment"
import { holidaySelector } from "../../api"

/**
 * Calculates all dates that occur on the weekend between a supplied
 * range of dates.
 * @param  {String} startsAt  A date string representing the start of the range.
 * @param  {String} endsAt    A date string representing the ends of the range.
 * @return {Array}            An array of dates that occur on the weekend.
 */
export const calculateWeekends = (startsAt, endsAt) => {
  const endDay = moment.utc(endsAt).day()
  const length = moment.utc(endsAt).diff(moment.utc(startsAt), "d")
  const calculatedLength = length + (endDay === 6 ? 2 : 1)
  if (calculatedLength < 0) {
    return []
  }
  return Array(calculatedLength)
    .fill()
    .map((_, i) => moment.utc(startsAt).add(i, "d"))
    .filter((date) => [0, 6].includes(date.day()))
    .map((date) => date.toISOString())
}

/**
 * Detects a collision between the dates of a holiday and a supplied range.
 * @param  {Object} holiday   The holiday to test.
 * @param  {String} startsAt  A date string representing the start of the range.
 * @param  {String} endsAt    A date string representing the ends of the range.
 * @return {Bool}             True if there is a date collision.
 */
const holidayOccursBetweenDates = (holiday, startsAt, endsAt) =>
  moment.utc(holiday.startsAt).isBetween(startsAt, endsAt, null, "[]") ||
  moment.utc(holiday.endsAt).isBetween(startsAt, endsAt, null, "[]")

/**
 * Maps the days of a holiday into an array of individual momentJS
 * date object.
 * @param  {Object} holiday The holiday to map.
 * @return {Array}          An array of the dates the holiday spans as momentJS object.
 */
const datesForHoliday = (holiday) =>
  Array(holiday.length)
    .fill()
    .map((_, i) => moment.utc(holiday.startsAt).add(i, "d"))

/**
 * Matches any applicable holidays and extracts their dates in the form of
 * momentJS objects.
 * @param  {String} startsAt              A date string representing the start of the search range.
 * @param  {String} endsAt                A date string representing the end of the search range.
 * @param  {Integer} calendarId           The ID of the calendar used to search for holidays.
 * @param  {Object} currentState          The current state of the redux application.
 * @return {Array}                        An array of all applicable dates that occur on a holiday.
 */
export const calculateHolidays = (startsAt, endsAt, calendarId, currentState) =>
  holidaySelector
    .forCalendar(currentState)(calendarId)
    .filter((holiday) => holidayOccursBetweenDates(holiday, startsAt, endsAt))
    .map((holiday) => datesForHoliday(holiday))
    .map((dates) => dates.map((date) => date.toISOString()))

/**
 * Returns an array of dates representing all interruptions that will
 * affect a supplied date rang.
 * @param  {Boolean} interruptedByWeekends  If true include any weekends.
 * @param  {Boolean} interruptedByHolidays  If true include any holidays.
 * @param  {String} startsAt              A date string representing the start of the search range.
 * @param  {String} endsAt                A date string representing the end of the search range.
 * @param  {Integer} calendarId           The ID of the calendar used to search for holidays.
 * @param  {Object} currentState          The current state of the redux application.
 * @return {Array}                        An array of dates representing interruptions for an event.
 */
export const calculateInterruptions = (
  interruptedByWeekends,
  interruptedByHolidays,
  startsAt,
  endsAt,
  calendarId,
  currentState
) => [
  ...new Set(
    flatten(
      []
        .concat(
          interruptedByWeekends ? calculateWeekends(startsAt, endsAt) : []
        )
        .concat(
          interruptedByHolidays
            ? calculateHolidays(startsAt, endsAt, calendarId, currentState)
            : []
        )
    )
  ),
]
