import React, { useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import { Placeholder } from "ui"
import { useReports } from "../api"
import { VisibleOnlyAdmin, VisibleOnlyNonAdmin } from "../login"
import { faTasksAlt } from "@fortawesome/pro-duotone-svg-icons"

/**
 * Renders a nice onboarding view prompting the user to create
 * a new report if they have the required permissions. Otherwise,
 * we inform the user they currently do not have access to any
 * reports at this time.
 */
export const ReportBlankSlateView: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const { subdomain } = useParams<{
    reportId: string
    subdomain: string
  }>()

  const { reports, loading } = useReports(subdomain)

  const handleNewClick = () => history.push(`/org/${subdomain}/reports/new`)

  useEffect(() => {
    if (reports.length > 0) {
      history.push(
        `${pathname}/${reports[0].id}/start/${DateTime.local().toFormat(
          "yyyyMMdd"
        )}/view/1M`.replace("//", "/")
      )
    }
  }, [reports, history, pathname])

  return (
    <>
      <VisibleOnlyAdmin subdomain={subdomain}>
        <Placeholder
          loading={loading}
          title="Create Your First Gannt Chart"
          subtitle="There are currently no gannt chart reports for your Company. Create a gannt chart to get started."
          action="Create Gannt Chart"
          icon={faTasksAlt}
          onClick={handleNewClick}
        />
      </VisibleOnlyAdmin>
      <VisibleOnlyNonAdmin subdomain={subdomain}>
        <Placeholder
          loading={loading}
          title="No Gannt Charts Available"
          subtitle="There are currently no Gannt Charts for your company."
          icon={faTasksAlt}
        />
      </VisibleOnlyNonAdmin>
    </>
  )
}
