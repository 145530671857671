import React from "react"
import { StylableProps } from "./Styleable"
import clsx from "clsx"

interface PanelProps {
  loading?: boolean
}

export const Panel: React.FC<StylableProps & PanelProps> = ({
  children,
  className,
  style,
  loading,
}) => (
  <div className={clsx(className, "flex p-2")}>
    <div
      className={clsx(
        "flex flex-col flex-grow w-full rounded shadow border border-gray-300 p-4 bg-white",
        loading ? "opacity-50" : ""
      )}
      style={style}
    >
      {children}
    </div>
  </div>
)
