var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDefaultValueListener } from "../../hooks";
import { camelCase } from "lodash";
import clsx from "clsx";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
// Assumes we have some existing UI implementation for forms in our library. Replace as needed.
import { Fields, ErrorList, Button, MultiSelectField, StyleField, } from "../Form";
import { AbsoluteOverlay } from "../Indicators";
var makeTagId = function (tag) { return "#" + tag + "#"; };
var splitDefaultValue = function (value) { return value.split(", "); };
var schema = yup.object({});
export var BatchForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, _b = _a.defaultValues, defaultValues = _b === void 0 ? { appendTags: "true" } : _b, submitTitle = _a.submitTitle, itemUuids = _a.itemUuids, _c = _a.styles, styles = _c === void 0 ? [] : _c, _d = _a.availableTags, availableTags = _d === void 0 ? [] : _d, _e = _a.categories, categories = _e === void 0 ? [] : _e, handleCreateCategory = _a.onCreateCategory;
    var _f = useState(__spreadArray([], availableTags, true)), localTags = _f[0], setLocalTags = _f[1];
    var _g = useState(null), newCategory = _g[0], setNewCategory = _g[1];
    var _h = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    }), handleSubmit = _h.handleSubmit, register = _h.register, formErrors = _h.formState.errors, setError = _h.setError, reset = _h.reset, watch = _h.watch, setValue = _h.setValue;
    var _j = watch([
        "categoryUuid",
        "tags",
        "startIndex",
        "endIndex",
    ]), categoryUuid = _j[0], tags = _j[1], startIndex = _j[2], endIndex = _j[3];
    useDefaultValueListener(defaultValues, reset, function (values) {
        var _a, _b, _c, _d;
        if (!values)
            return values;
        setLocalTags(__spreadArray(__spreadArray([], splitDefaultValue((_a = values.tags) !== null && _a !== void 0 ? _a : ""), true), (availableTags || []), true).filter(function (t) { return t === null || t === void 0 ? void 0 : t.length; })
            .filter(function (t, i, a) { return a.indexOf(t) === i; }));
        return values
            ? __assign(__assign({}, values), { appendTags: (_b = values.appendTags) !== null && _b !== void 0 ? _b : "true", tags: (_d = (_c = values.tags) === null || _c === void 0 ? void 0 : _c.split(", ").filter(function (t) { return t.length; }).map(makeTagId).join(" ")) !== null && _d !== void 0 ? _d : "" }) : values;
    });
    var categoryStyles = categories.map(function (c) {
        return (__assign(__assign({}, styles.find(function (_a) {
            var uuid = _a.uuid;
            return uuid === c.styleUuid;
        })), { uuid: c.uuid, name: c.name }));
    });
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var onCreateTag = function (newVal) {
        setLocalTags(__spreadArray(__spreadArray([], localTags, true), [newVal !== null && newVal !== void 0 ? newVal : ""], false));
        setValue("tags", __spreadArray(__spreadArray([], (tags !== null && tags !== void 0 ? tags : "").split(" "), true), ["#" + newVal + "#"], false).join(" "), {
            shouldValidate: true,
        });
    };
    var handleTagSelect = function (newVal) {
        setValue("tags", newVal !== null && newVal !== void 0 ? newVal : "", {
            shouldValidate: true,
        });
    };
    var onCreateCategory = function (newVal) {
        setNewCategory(newVal);
    };
    var handleCategorySelect = function (newVal) {
        setValue("categoryUuid", newVal !== null && newVal !== void 0 ? newVal : "", { shouldValidate: true });
    };
    var handleDateChange = function (newVal) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ((startIndex && endIndex) || !startIndex) {
            setValue("startIndex", newVal !== null && newVal !== void 0 ? newVal : undefined, { shouldValidate: true });
            setValue("endIndex", undefined, { shouldValidate: true });
        }
        else {
            setValue("endIndex", newVal !== null && newVal !== void 0 ? newVal : undefined, { shouldValidate: true });
        }
    }; };
    var handleClearDates = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setValue("startIndex", undefined, { shouldValidate: true });
        setValue("endIndex", undefined, { shouldValidate: true });
    };
    return (_jsxs("form", __assign({ onSubmit: handleFormSubmit, className: "relative", role: "form" }, { children: [_jsxs(Fields, __assign({ register: register, errors: formErrors, grow: true, className: "pb-2" }, { children: [_jsx(StyleField, { name: "categoryUuid", options: categoryStyles, value: categoryUuid, label: "Reassign to category", id: "categoryUuid", placeholder: "Select category", className: clsx(newCategory ? "hidden" : "flex-grow flex-shrink-0 w-full"), onSelect: handleCategorySelect, onCreate: onCreateCategory, creatable: typeof handleCreateCategory === "function", clearable: true, selfControllable: true }, void 0), _jsx(MultiSelectField, { name: "tags", label: "Apply tags", value: tags, options: localTags.sort().map(function (t) { return ({
                            label: t,
                            value: t,
                            id: "#" + t + "#",
                            icon: faTag,
                        }); }), onSelect: handleTagSelect, onCreateOption: onCreateTag, placeholder: "Add any tags i.e. 'ep101'", selfControllable: true, grow: true, labelClassName: "font-normal", isMulti: true, creatable: true, id: "tags" }, void 0), _jsxs("div", __assign({ className: clsx(!(tags === null || tags === void 0 ? void 0 : tags.length) && "hidden", "-mt-2 flex flex-col") }, { children: [_jsxs("label", __assign({ className: "flex items-center", htmlFor: "appendTags" }, { children: [_jsx("input", __assign({ type: "checkbox", value: "true", className: "mr-3" }, register === null || register === void 0 ? void 0 : register("appendTags")), void 0), " ", _jsx("span", __assign({ className: "font-bold" }, { children: "Append the selected tags" }), void 0)] }), void 0), _jsx("span", __assign({ className: "text-sm ml-6 text-stone-600 rounded" }, { children: "Uncheck this if you want to replace any existing tags applied to the selected events" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex flex-col flex-grow w-full " }, { children: [_jsx("h3", { children: "Standardize Duration" }, void 0), _jsx("div", __assign({ className: "grid grid-cols-7 divide-x divide-stone-200 border-stone-200 border rounded" }, { children: new Array(7).fill("").map(function (_, i) { return (_jsx("button", __assign({ className: clsx("p-2  uppercase text-xs font-bold items-center", startIndex === i.toString() ||
                                        endIndex === i.toString() ||
                                        (parseInt(startIndex !== null && startIndex !== void 0 ? startIndex : "") < i &&
                                            i < parseInt(endIndex !== null && endIndex !== void 0 ? endIndex : ""))
                                        ? "bg-lochivar-lighter text-white"
                                        : "text-stone-600 hover:bg-lochivar-lighter/20", parseInt(startIndex !== null && startIndex !== void 0 ? startIndex : "") > i &&
                                        !endIndex &&
                                        "disabled opacity-50"), disabled: parseInt(startIndex !== null && startIndex !== void 0 ? startIndex : "") > i && !endIndex, onClick: handleDateChange(i.toString()) }, { children: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][i] }), "WeekDay" + i)); }) }), void 0), (!startIndex || !endIndex) && (_jsx("span", __assign({ className: "mt-1 p-1 text-xs text-stone-600 italic" }, { children: startIndex
                                    ? "Choose an end date"
                                    : "Define an optional start date to apply to the selected events" }), void 0)), startIndex && endIndex && (_jsx("button", __assign({ className: "rounded bg-burgundy-default p-1 px-4 text-xs mt-1 mr-auto text-white font-bold", onClick: handleClearDates }, { children: "Clear Duration" }), void 0))] }), void 0), _jsx(ErrorList, { errors: formErrors }, void 0), _jsx(Button, __assign({ type: "submit", className: "mt-2 w-full", align: "stretch" }, { children: submitTitle !== null && submitTitle !== void 0 ? submitTitle : "Update " + itemUuids.length + " events" }), void 0), _jsx("input", { type: "hidden", name: "startIndex" }, void 0), _jsx("input", { type: "hidden", name: "endIndex" }, void 0)] }), void 0), loading ? _jsx(AbsoluteOverlay, {}, void 0) : null] }), void 0));
};
BatchForm.displayName = "Forms.BatchForm";
