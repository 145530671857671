import { useSelector } from "react-redux"
import { eventSelector } from "../selectors"
import { DateTime } from "luxon"

export interface EventsMappedToRowsParams {
  calendarIds: string[]
  startDate: string
  endDate: string
}

export interface CalendarEvent {
  startsAt: string
  endsAt: string
  position: number
  name: string
  partition: number
}

const collision = (a: CalendarEvent, b: CalendarEvent) => {
  const occursBefore = a.startsAt < b.startsAt && a.endsAt < b.startsAt
  const occursAfter = a.startsAt > b.endsAt && a.endsAt > b.endsAt
  return !(occursBefore || occursAfter)
}

export const mapEventsToRows = ({
  events,
  calendarIds,
  startDate,
  endDate,
}: EventsMappedToRowsParams & { events: CalendarEvent[] }) => {
  const start = DateTime.fromISO(startDate)
  const end = DateTime.fromISO(endDate).endOf("week")
  const { weeks } = end.endOf("week").diff(start.startOf("week"), "weeks")
  const ranges = new Array(Math.round(weeks)).fill("").map((_, i) => {
    const s = start.plus({ weeks: i })
    const e = s
      .plus({ days: s.weekday === 7 ? 1 : 0 })
      .endOf("week")
      .minus({ days: 1 })
    const startIso = s.toISODate()
    const endIso = e.toISODate()
    return {
      s: startIso,
      e: endIso,
      events: events
        .filter((e: CalendarEvent) => {
          const startsAt = e.startsAt.slice(0, 10)
          const endsAt = e.endsAt.slice(0, 10)
          const withinRange = startsAt >= startIso && endsAt <= endIso
          const finishesInRange = endsAt >= startIso && endsAt <= endIso
          const startsInRange = startsAt >= startIso && startsAt <= endIso
          const overlapsRange = startsAt <= startIso && endsAt >= endIso
          const matchesRange =
            withinRange || finishesInRange || startsInRange || overlapsRange
          return matchesRange
        })
        .sort((a: CalendarEvent, b: CalendarEvent) => {
          const positionSort = a.position - b.position
          if (positionSort !== 0) return positionSort
          const startsAtSort = DateTime.fromISO(b.startsAt).diff(
            DateTime.fromISO(a.startsAt),
            "days"
          ).days
          if (startsAtSort !== 0) {
            console.log(startsAtSort)
            return startsAtSort
          }
          const lengthSort =
            DateTime.fromISO(a.endsAt).diff(DateTime.fromISO(a.endsAt), "days")
              .days -
            DateTime.fromISO(b.endsAt).diff(DateTime.fromISO(b.endsAt), "days")
              .days
          return lengthSort
        })
        .reduce(
          (partitions: CalendarEvent[][], e: CalendarEvent) => {
            partitions[e.partition].push(e)
            return partitions
          },
          [[], [], []] as CalendarEvent[][]
        )
        .map((partition: CalendarEvent[]) =>
          partition
            .reduce((rows: CalendarEvent[][], e: CalendarEvent) => {
              if (rows.length === 0) return [[e]]
              const availableRows = rows.filter(
                (r) =>
                  r.filter((rowEvent) => !collision(rowEvent, e)).length > 0
              )
              if (availableRows.length < 1) {
                rows.push([e])
                return rows
              }

              const conflictingRows = availableRows.filter(
                (r) =>
                  r.filter(
                    (rowEvent) =>
                      collision(rowEvent, e) && rowEvent.position < e.position
                  ).length > 0
              )
              if (conflictingRows.length < 1) {
                availableRows[0].push(e)
                return rows
              }

              const conflictingIndixes = conflictingRows.map((row) =>
                rows.indexOf(row)
              )
              const lowestPossibleIndex =
                conflictingIndixes[conflictingIndixes.length - 1]
              const bestAvailableRows = availableRows.filter(
                (row) => rows.indexOf(row) > lowestPossibleIndex
              )
              if (bestAvailableRows.length < 1) {
                rows.push([e])
                return rows
              }
              bestAvailableRows[0].push(e)

              return rows
            }, [] as CalendarEvent[][])
            .map((row: CalendarEvent[]) => {
              return row.sort((a, b) => (a.startsAt > b.startsAt ? 1 : -1))
            })
        ),
    }
  })
  return ranges as { s: string; e: string; events: CalendarEvent[][][] }[]
}

export const useEventsMappedToRows = ({
  calendarIds,
  startDate,
  endDate,
}: EventsMappedToRowsParams) => {
  const events = useSelector(eventSelector.forCalendar)(calendarIds)
  return mapEventsToRows({ events, calendarIds, startDate, endDate })
}
