var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { stroke } from "./stroke";
import { makePx } from "./px";
/**
 * Render a rounded rect path on an HTML5 2d canvas.
 * @param ctx The current canvas context to draw on.
 * @param props The properties defining the appearance of the rectangle.
 */
export var roundedRect = function (ctx, _a) {
    var ox = _a.x, oy = _a.y, oh = _a.h, ow = _a.w, radius = _a.radius, fillStyle = _a.fillStyle, scale = _a.scale, strokeProps = __rest(_a, ["x", "y", "h", "w", "radius", "fillStyle", "scale"]);
    var px = makePx(scale);
    var halfRadians = Math.PI;
    var quarterRadians = Math.PI / 2;
    // scale original dimensions
    var r = px(radius);
    var x = px(ox);
    var y = px(oy);
    var h = px(oh);
    var w = px(ow);
    ctx.beginPath();
    // top left
    ctx.arc(r + x, r + y, r, -quarterRadians, halfRadians, true);
    // bottom left
    ctx.lineTo(x, y + h - r);
    ctx.arc(r + x, h - r + y, r, halfRadians, quarterRadians, true);
    // bottom right
    ctx.lineTo(x + w - r, y + h);
    ctx.arc(x + w - r, y + h - r, r, quarterRadians, 0, true);
    // top right
    ctx.lineTo(x + w, y + r);
    ctx.arc(x + w - r, y + r, r, 0, -quarterRadians, true);
    // finish
    ctx.lineTo(x + r, y);
    // fill
    if (fillStyle) {
        ctx.fillStyle = fillStyle;
        ctx.fill();
    }
    // stroke
    stroke(ctx, strokeProps);
};
