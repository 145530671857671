var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from "./Icon";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
var OVERLAY_CLASS = "absolute flex inset-0 bg-lochivar-default bg-opacity-25 z-10";
var INDICATOR_CLASS = "m-auto py-1 px-3 bg-white bg-opacity-75 text-lochivar-default shadow rounded-full";
var SPIN_CLASS = "animate-spin m-auto p-0";
export var Loader = function () {
    return (_jsx("div", __assign({ className: OVERLAY_CLASS }, { children: _jsx("div", __assign({ className: INDICATOR_CLASS }, { children: _jsx("p", __assign({ className: SPIN_CLASS, style: { fontSize: "2rem" } }, { children: _jsx(Icon, { icon: faSpinnerThird, mode: "default" }, void 0) }), void 0) }), void 0) }), void 0));
};
