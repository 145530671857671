var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContextItem } from "./ContextItem";
import { faEdit, faFolderPlus, faArrowsAlt, } from "@fortawesome/pro-regular-svg-icons";
/**
 * A set of context options for a selected event.
 */
export var ContextMenuCalendar = function (_a) {
    var onSelect = _a.onSelect;
    var handleSelection = function (selection, params) { return function () {
        if (onSelect) {
            onSelect(selection, params);
        }
    }; };
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            flexDirection: "column",
        } }, { children: [_jsx(ContextItem, { name: "New Group", icon: faFolderPlus, onClick: handleSelection("createGroup") }, void 0), _jsx(ContextItem, { name: "Move to Group", icon: faArrowsAlt, onClick: handleSelection("sendToGroup") }, void 0), _jsx(ContextItem, { name: "Edit Calendar", icon: faEdit, onClick: handleSelection("editCalendar") }, void 0)] }), void 0));
};
