export const isJson = (res) => {
  const contentType = res.headers.get("Content-Type")
  return contentType && ~contentType.indexOf("json")
}

export const isFalse = (prop) => typeof prop !== "undefined" && prop === false

export const isDefined = (prop) =>
  typeof prop !== "undefined" && prop !== "undefined"

export const isNotDefined = (prop) => !isDefined(prop)

export const isRealProp = (prop, state) => (isDefined(prop) ? prop : state)

export const isNotBlank = (prop) =>
  isDefined(prop) && typeof prop === "string" && prop !== ""

export const isBlank = (prop) => !isNotBlank(prop)

export const asSubDomain = (text) =>
  text && text.replace(/\W+/g, "-").toLowerCase()

export const trimmedSubDomain = (text) =>
  asSubDomain(text) && asSubDomain(text).replace(/^[^a-z\d]*|[^a-z\d]*$/gi, "")
