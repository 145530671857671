import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./RadioField.module.css"

const RadioField = ({ label, icon, description, input, type, disabled }) => (
  <li className={`list-group-item ${styles.radioGroupItem}`}>
    <div
      className={`${styles.radioContainer} ${input.checked && styles.selected}`}
    >
      <input
        type="button"
        className={styles.invisibleButton}
        disabled={disabled}
        onClick={() => input.onChange(input.value)}
      />
      <FontAwesomeIcon icon={icon} className={styles.icon} size="2x" />
      <div className={styles.radioContent}>
        <input
          type={type}
          {...input}
          className={styles.invisibleRadioButton}
          onChange={() => input.onChange(input.value)}
        />
        <label className={styles.label}>{label}</label>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  </li>
)

RadioField.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default RadioField
