import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { CompanyResponse } from "./types"
import { camelizeKeys } from "humps"

export interface UpdateTrialBody {
  active: boolean
  trialEndsAt?: string
}

export interface UpdateTrialParams extends RequestParams {
  id: string
}

export const useUpdateTrial = () => {
  const updateEntity = useUpdateEntity()
  const updateTrial = useApiRequest<
    CompanyResponse,
    UpdateTrialBody,
    UpdateTrialParams
  >({
    method: "PATCH",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/:id/trial`,
    onResponse: (response) => {
      updateEntity("organizations", response.id, {
        ...camelizeKeys(response),
      })
    },
  })
  return updateTrial
}
