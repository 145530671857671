import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { requestActiveCalendars, analyticsSelector } from "../../api"

export const useActiveCalendars = (days: number) => {
  const activeCalendars = useSelector(analyticsSelector.activeCalendars)(days)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [daysLoaded, setDaysLoaded] = useState(0)
  if (days && daysLoaded !== days && !loading) {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(requestActiveCalendars(days))
      setLoading(false)
      setDaysLoaded(days)
    }
    fetchData()
  }
  return {
    loading,
    activeCalendars,
  }
}
