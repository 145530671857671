import { useState, useEffect } from "react";
import { useSharedRef } from "utils";
export var useInitializedField = function (forwardedRef, _a) {
    var initiallyFocused = _a.initiallyFocused;
    var _b = useState(false), initialized = _b[0], setInitialized = _b[1];
    var inputRef = useSharedRef(null, [forwardedRef]);
    useEffect(function () {
        var _a;
        if (initialized)
            return;
        if (initiallyFocused) {
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        setInitialized(true);
    }, [initiallyFocused, initialized, setInitialized]);
    return inputRef;
};
