import { getCookie, setCookie } from "react-simple-cookie-store"

const RECENT_CALENDARS = "recent_calendars"
const VIEW_MODE = "calendar_view_mode"

export const getRecentCalendarIds = () =>
  getCookie(RECENT_CALENDARS)?.split(",") ?? []

export const appendRecentCalendarId = (id: string) => {
  const calendarIds = getRecentCalendarIds()
  if (calendarIds[0] !== `${id}`) {
    const updatedList = [id, ...calendarIds]
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((v) => v.length)
      .filter((_, i) => i < 7)
    setCookie(RECENT_CALENDARS, updatedList.join(","), 30)
  }
}

export const clearRecentCalendarIds = () => setCookie(RECENT_CALENDARS, "", 30)

export const setViewMode = (viewMode: string) =>
  setCookie(VIEW_MODE, viewMode, 30)

export const getRecentViewMode = () => getCookie(VIEW_MODE)
