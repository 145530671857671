import React from "react"
import { Modal, ModalProps } from "ui"
import { useSelector } from "react-redux"
import { authSelector } from "../api"
import { faInfoCircle, faVideo } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export interface HelpModalProps {
  position?: number
  subdomain?: string
}

export const HelpModal: React.FC<
  HelpModalProps & Omit<ModalProps, "title" | "subdomain" | "open">
> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  zIndex,
  subdomain,
  position,
  ...props
}) => {
  const currentUser = useSelector<any, any>(authSelector.currentUser)
  const subject = encodeURI("[ProCal] Customer Support Request")
  const emailBody = encodeURI(`



[IMPORTANT]
Please include the diagnostic content below to 
better help our team address your issue:
-----------------------------------------------

User Agent:
${navigator.userAgent}

URL Location: 
${window.location.href}

Organization ID: 
${subdomain}

User ID:
${currentUser.id}

Access Token:
${currentUser.accessToken}

Refresh Identifier:
${currentUser.refreshToken}
  `)

  return (
    <Modal
      {...props}
      title="Have questions or suggestions?"
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
    >
      <div className="flex py-2 mb-2 border-b border-gray-200">
        <FontAwesomeIcon icon={faVideo} mode="default" />
        <p className="text-sm ml-4 flex-grow">
          <a
            href="https://www.youtube.com/channel/UCBquqODKLzAif_g3rTmzSBw"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lochivar-default"
          >
            Video tutorials are now available on Youtube.
          </a>
        </p>
      </div>
      <div className="flex py-2">
        <FontAwesomeIcon icon={faInfoCircle} mode="default" />
        <p className="text-sm ml-4 flex-grow">
          Please{" "}
          <a
            href={`mailto:support@revolutiones.com?subject=${subject}&body=${emailBody}`}
            className="text-lochivar-default"
          >
            email support
          </a>{" "}
          for assistance. <br />
          You may also call us at{" "}
          <a href="tel:818.303.3306" className="text-lochivar-default">
            818.303.3306
          </a>
        </p>
      </div>
    </Modal>
  )
}
