// rootReducer.js
import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { connectRouter } from "connected-react-router"

import api from "./api"
import appNavigator from "./appNavigator"
import organizationDetailView from "./organizationDetailView"
import calendarDetailView from "./calendarDetailView"
import invitationDetailView from "./invitationDetailView"
import calendarView from "./calendarView"
import * as selectable from "./selectable"

// App modules follow a convention. Simply add the module
// to the array to have it's reducer properly mapped to
// an isolated namespace.

const moduleReducers = [
  api,
  appNavigator,
  organizationDetailView,
  calendarDetailView,
  invitationDetailView,
  calendarView,
  selectable,
]
  .map((mod) => ({ [mod.constants.NAME]: mod.reducer }))
  .reduce((prev, current) => Object.assign({}, current, prev))

// Append any additional reducers manually.

const RootReducer = (history) =>
  combineReducers(
    Object.assign({}, moduleReducers, {
      form: formReducer,
      router: connectRouter(history),
    })
  )

export default RootReducer
