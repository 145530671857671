import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { checkCalendarPrintStatus } from "../actions/calendars"
import { useInterval } from "./useInterval"

export const usePrintJobStatus = ({
  subdomain,
  calendarId,
  reportId,
  jobId,
}: {
  subdomain: string
  calendarId?: number
  reportId?: number
  jobId: string
}) => {
  const [results, setResults] = useState<{
    url: string | null
    loading: boolean
  }>({
    url: null,
    loading: true,
  })

  const dispatch = useDispatch()
  const callback = useCallback(async () => {
    const { url }: any = await dispatch(
      checkCalendarPrintStatus(subdomain, calendarId || reportId, jobId)
    )
    setResults({
      loading: (url?.length ?? 0) === 0,
      url,
    })
  }, [dispatch, setResults, subdomain, calendarId, reportId, jobId])

  useInterval(callback, 2500)

  return results
}
