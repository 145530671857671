import { PROTECTED } from "redux-jwt-protected-middleware"
import { decamelizeKeys } from "humps"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"

//
// GET / Find all OR by :subdomain
//

export const getSnapshot = (subdomain, calendar_id, id) => ({
  type: types.FETCH_SNAPSHOTS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.SNAPSHOT : Schemas.SNAPSHOTS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendar_id}/snapshots/${
      id || ""
    }`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_SNAPSHOTS_REQUEST,
      types.FETCH_SNAPSHOTS_SUCCESS,
      types.FETCH_SNAPSHOTS_FAILURE,
    ],
  },
})

export const requestSnapshot = (subdomain, calendar_id, id) => (dispatch) => {
  return dispatch(getSnapshot(subdomain, calendar_id, id))
}

//
// POST / new snapshot
//

export const postSnapshot = (subdomain, calendar_id, snapshot) => ({
  type: types.CREATE_SNAPSHOT,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.SNAPSHOT,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendar_id}/snapshots`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(snapshot)),
    types: [
      types.CREATE_SNAPSHOT_REQUEST,
      types.CREATE_SNAPSHOT_SUCCESS,
      types.CREATE_SNAPSHOT_FAILURE,
    ],
  },
})

export const createSnapshot =
  (subdomain, calendar_id, snapshot) => (dispatch) => {
    return dispatch(postSnapshot(subdomain, calendar_id, snapshot))
  }

//
// PUT / new snapshot
//

export const putSnapshot = (subdomain, calendar_id, snapshot) => ({
  type: types.CREATE_SNAPSHOT,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.SNAPSHOT,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendar_id}/snapshots/${snapshot.id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(snapshot)),
    types: [
      types.UPDATE_SNAPSHOT_REQUEST,
      types.UPDATE_SNAPSHOT_SUCCESS,
      types.UPDATE_SNAPSHOT_FAILURE,
    ],
  },
})

export const updateSnapshot =
  (subdomain, calendar_id, snapshot) => (dispatch) => {
    return dispatch(putSnapshot(subdomain, calendar_id, snapshot))
  }

//
// DELETE / Delete existing Snapshot via ID.
//

export const deleteSnapshot = (subdomain, calendar_id, id) => ({
  type: types.DELETE_SNAPSHOT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.SNAPSHOT,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendar_id}/snapshots/${id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_SNAPSHOT_REQUEST,
      types.DELETE_SNAPSHOT_SUCCESS,
      types.DELETE_SNAPSHOT_FAILURE,
    ],
  },
})

export const removeSnapshot = (subdomain, calendar_id, id) => (dispatch) => {
  return dispatch(deleteSnapshot(subdomain, calendar_id, id))
}
