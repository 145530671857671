import React, { Component } from "react"
import PropTypes from "prop-types"
import { DropTarget } from "react-dnd"
import { Selectable } from "../../selectable"
import styles from "./ItemExplorer.module.css"
import CategoryView from "./CategoryView.react"
import ItemView from "./ItemView.react"
import EventView from "./EventView.react"
import { forRoot, forCategory, forItem, matchingExactText } from "../filters"
import { ItemTypes } from "../constants"
import SelectableItem from "./SelectableItem.react"
import InlineSetting from "./InlineSetting.react"
import moment from "moment"
import { Button } from "ui"
import {
  faFolder,
  faCalendar,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons"
import {
  faCalendarAlt,
  faStickyNote,
  faSearch,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons"
import { SearchForm } from "ui"

class ItemExplorerView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoveringNotes: false,
      currentCalendarId: null,
      searchValue: null,
      replaceValue: null,
    }
  }

  itemsForCategory(
    categoryUuid,
    parentLocked,
    parentInactive,
    indentation,
    calendarId
  ) {
    const { items } = this.props
    const filtered = categoryUuid
      ? forCategory(items, categoryUuid, calendarId)
      : forRoot(items, calendarId)
    return this.renderItems(
      filtered,
      parentLocked,
      parentInactive,
      indentation,
      calendarId
    )
  }

  hasRootItems(calendarId) {
    const { items } = this.props
    return forRoot(items, calendarId).length > 0
  }

  eventsForItem(itemUuid, calendarId) {
    const { events } = this.props
    return forItem(events, itemUuid, calendarId)
  }

  renderCategory(
    categoryUuid,
    parentLocked,
    parentInactive,
    indentation,
    calendarId
  ) {
    const { categories, selectedCategories, selectItem, ...props } = this.props
    const filtered = categoryUuid
      ? forCategory(categories, categoryUuid, calendarId)
      : forRoot(categories, calendarId)
    const handleSelectedCategory = (id) =>
      selectItem(Selectable.CATEGORY, id, calendarId)
    return (
      <div>
        {filtered.map((category) => (
          <div key={category.hashId}>
            <CategoryView
              name={category.name}
              handleEditCategory={props.handleEditCategory}
              handleDeleteCategory={props.handleDeleteCategory}
              handleSelectedCategory={handleSelectedCategory}
              hideCategory={props.hideCategory}
              showCategory={props.showCategory}
              openCategory={props.openCategory}
              lockCategory={props.lockCategory}
              unlockCategory={props.unlockCategory}
              active={category.active}
              locked={category.locked}
              open={category.open}
              category={category}
              selected={selectedCategories.includes(category.hashId)}
              updateItemCategory={props.updateItemCategory}
              updateCategoryCategory={props.updateCategoryCategory}
              indentation={indentation}
              parentLocked={parentLocked}
              parentInactive={parentInactive}
              selectMode={props.multiSelectMode}
              readOnly={props.readOnly}
            />
            {category.open &&
              this.renderCategory(
                category.uuid,
                parentLocked || category.locked,
                parentInactive || !category.active,
                indentation + 1,
                calendarId
              )}
          </div>
        ))}
        {categoryUuid
          ? this.itemsForCategory(
              categoryUuid,
              parentLocked,
              parentInactive,
              indentation,
              calendarId
            )
          : null}
      </div>
    )
  }

  replaceTextForItem(item) {
    const { renameItem, subdomain } = this.props
    const { searchValue, replaceValue, caseSensitive } = this.state
    const expression = new RegExp(searchValue, caseSensitive ? "gi" : "g")
    const name = item.name.replace(expression, replaceValue)
    renameItem(subdomain, item.calendarId, item.uuid, name)
  }

  renderItems(items, parentLocked, parentInactive, indentation, calendarId) {
    const { selectedItems, selectedEvents, selectItem, ...props } = this.props
    const { searchValue, replaceValue, caseSensitive } = this.state
    const keywords = searchValue ? [searchValue] : []
    return items.map((item) => {
      const selected = selectedItems.includes(item.uuid)
      const events = this.eventsForItem(item.uuid, calendarId) || []
      const eventSelected =
        events.filter((event) => selectedEvents.includes(event.uuid)).length > 0
      const handleSelectedItem = (id) =>
        selectItem(Selectable.ITEM, id, calendarId)
      const handleReplaceText = () => this.replaceTextForItem(item)
      return (
        <div key={item.hashId}>
          <ItemView
            item={item}
            name={item.name}
            active={item.active}
            locked={item.locked}
            custom={item.useCustomStyles}
            selected={selected}
            handleEditItem={props.handleEditItem}
            lockItem={props.lockItem}
            unlockItem={props.unlockItem}
            hideItem={props.hideItem}
            showItem={props.showItem}
            indentation={indentation}
            parentLocked={parentLocked}
            parentInactive={parentInactive}
            handleSelectedItem={handleSelectedItem}
            handleDeleteItem={props.handleDeleteItem}
            updateItemDraggingStatus={props.updateItemDraggingStatus}
            keywords={keywords}
            replace={replaceValue}
            caseSensitive={caseSensitive}
            findStyle={props.findStyle}
            selectMode={props.multiSelectMode}
            readOnly={props.readOnly}
            handleReplaceText={handleReplaceText}
          />
          {(selected || eventSelected) &&
            this.renderEvents(events, indentation + 1, calendarId)}
        </div>
      )
    })
  }

  renderItemsAsNotes(items, indentation, calendarId) {
    const { selectedItems, selectedEvents, selectItem, ...props } = this.props
    const { searchValue, replaceValue, caseSensitive } = this.state
    const keywords = searchValue ? [searchValue] : []
    return items
      .filter((item) =>
        calendarId
          ? parseInt(item.calendarId, 0) === parseInt(calendarId, 0)
          : true
      )
      .map((item) => {
        const selected = selectedItems.includes(item.uuid)
        const events = this.eventsForItem(item.uuid)
        const eventSelected =
          events.filter((event) => selectedEvents.includes(event.uuid)).length >
          0
        const handleSelectedItem = (id) =>
          selectItem(Selectable.ITEM, id, calendarId)
        return (
          <div key={item.hashId}>
            <ItemView
              item={item}
              name={item.name}
              active={item.active}
              locked={item.locked}
              custom={item.useCustomStyles}
              selected={selected}
              handleEditItem={props.handleEditItem}
              lockItem={props.lockItem}
              unlockItem={props.unlockItem}
              hideItem={props.hideItem}
              showItem={props.showItem}
              indentation={indentation}
              handleSelectedItem={handleSelectedItem}
              handleDeleteItem={props.handleDeleteItem}
              handleReplaceText={props.handleReplaceText}
              updateItemDraggingStatus={props.updateItemDraggingStatus}
              keywords={keywords}
              replace={replaceValue}
              caseSensitive={caseSensitive}
              findStyle={props.findStyle}
              selectMode={props.multiSelectMode}
              readOnly={props.readOnly}
            />
            {(selected || eventSelected) &&
              this.renderEvents(events, indentation + 1, calendarId)}
          </div>
        )
      })
  }

  renderEvents(events, indentation, calendarId) {
    const { selectedEvents, handleEditEvent, readOnly, selectItem } = this.props
    const handleSelectedEvent = (id) =>
      selectItem(Selectable.EVENT, id, calendarId)
    return events.map((event) => (
      <EventView
        key={event.hashId}
        uuid={event.uuid}
        event={event}
        name={moment.utc(event.startsAt).format("MMM Do, YYYY")}
        selected={selectedEvents.includes(event.uuid)}
        handleEditEvent={handleEditEvent}
        handleSelectedEvent={handleSelectedEvent}
        custom={event.useCustomStyles}
        indentation={indentation}
        readOnly={readOnly}
      />
    ))
  }

  handleClick() {
    this.props.selectItem(Selectable.ITEM, null)
  }

  renderItemTree(calendarId) {
    const { connectDropTarget, isOver, notes, notesCategory } = this.props
    const { hoveringNotes } = this.state
    return (
      <div
        className={`${styles.itemExplorer} ${isOver && styles.hover}`}
        onClick={() => this.handleClick()}
      >
        <div className={styles.topLevelContainer}>
          {/* Purely here to push layout */}
          <InlineSetting
            active={false}
            visible={false}
            handleClick={() => null}
            activeIcon={faEyeSlash}
            defaultIcon={faEye}
          />
          <div className={styles.topLevel}>
            <FontAwesomeIcon icon={faFolder} className={styles.topLevelIcon} />
            <span className={styles.topLevelName}>Categories</span>
          </div>
        </div>
        {this.renderCategory(null, false, false, 0, calendarId)}
        <div className={styles.topLevelContainer}>
          {/* Purely here to push layout */}
          <InlineSetting
            active={false}
            visible={false}
            handleClick={() => null}
            activeIcon={faEyeSlash}
            defaultIcon={faEye}
          />
          {connectDropTarget(
            <div className={styles.topLevel}>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={styles.topLevelIcon}
              />
              <span className={styles.topLevelName}>Events</span>
            </div>
          )}
        </div>
        {this.itemsForCategory(null, false, false, 0, calendarId)}
        <div
          className={styles.topLevelContainer}
          onMouseOver={() => this.setState({ hoveringNotes: true })}
          onMouseOut={() => this.setState({ hoveringNotes: false })}
        >
          {/* Purely here to push layout */}
          <InlineSetting
            active={notesCategory && !notesCategory.active}
            visible={hoveringNotes}
            handleClick={() => {
              return notesCategory && !notesCategory.active
                ? this.props.showCategory(
                    notesCategory.uuid,
                    notesCategory.calendarId
                  )
                : this.props.hideCategory(
                    notesCategory.uuid,
                    notesCategory.calendarId
                  )
            }}
            activeIcon={faEyeSlash}
            defaultIcon={faEye}
          />
          <div className={styles.topLevel}>
            <FontAwesomeIcon
              icon={faStickyNote}
              className={styles.topLevelIcon}
            />
            <span className={styles.topLevelName}>Notes</span>
          </div>
        </div>
        {this.renderItemsAsNotes(notes, 0, calendarId)}
      </div>
    )
  }

  renderCalendarTree() {
    const {
      availableCalendars,
      reportCalendarIds,
      notes,
      handleManageReportClick,
    } = this.props
    const { currentCalendarId } = this.state
    const currentCalendar = availableCalendars.filter(
      (c) => c.id === currentCalendarId
    )[0]
    const calendarNotes = notes.filter(
      (n) => n.calendarId === currentCalendarId
    )
    const activeCalendars = availableCalendars.filter((c) =>
      reportCalendarIds.includes(c.id)
    )
    return (
      <div
        className={`${styles.itemExplorer}`}
        onClick={() => this.handleClick()}
      >
        {currentCalendar ? (
          <div>
            <SelectableItem
              key={`calendarOption${currentCalendarId}`}
              handleClick={() => {
                this.setState({ currentCalendarId: null })
              }}
              handleDoubleClick={(e) => e}
              selected={false}
            >
              <div className={styles.topLevel}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className={styles.topLevelIcon}
                />
                <span className={styles.topLevelName}>
                  {currentCalendar.name}
                </span>
              </div>
            </SelectableItem>
            <div className={styles.topLevel} style={{ paddingLeft: "2rem" }}>
              <FontAwesomeIcon
                icon={faFolder}
                className={styles.topLevelIcon}
              />
              <span className={styles.topLevelName}>Categories</span>
            </div>
            {this.renderCategory(null, false, false, 0, currentCalendar.id)}
            {this.hasRootItems(currentCalendar.id) ? (
              <div className={styles.topLevel} style={{ paddingLeft: "2rem" }}>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className={styles.topLevelIcon}
                />
                <span className={styles.topLevelName}>Events</span>
              </div>
            ) : null}
            {this.itemsForCategory(null, false, false, 0, currentCalendar.id)}
            {calendarNotes.length > 0 ? (
              <div className={styles.topLevel} style={{ paddingLeft: "2rem" }}>
                <FontAwesomeIcon
                  icon={faStickyNote}
                  className={styles.topLevelIcon}
                />
                <span className={styles.topLevelName}>Notes</span>
              </div>
            ) : null}
            {calendarNotes.length > 0
              ? this.renderItemsAsNotes(calendarNotes, 0)
              : null}
          </div>
        ) : (
          <div style={{ padding: "0 0.5rem" }}>
            <div className={styles.topLevel}>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className={styles.topLevelIcon}
              />
              <span className={styles.topLevelName}>Calendars</span>
            </div>
            <div style={{ paddingLeft: "0.5rem" }}>
              {activeCalendars.map((calendar) => {
                return (
                  <SelectableItem
                    key={`calendarOption${calendar.id}`}
                    handleClick={() => {
                      this.setState({ currentCalendarId: calendar.id })
                    }}
                    handleDoubleClick={(e) => e}
                    selected={false}
                  >
                    <div className={styles.topLevel}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className={styles.topLevelIcon}
                      />
                      <span className={styles.topLevelName}>
                        {calendar.name}
                      </span>
                    </div>
                  </SelectableItem>
                )
              })}
              <div style={{ marginTop: "2rem" }}>
                <Button
                  align="stretch"
                  onClick={(e) => {
                    e.preventDefault()
                    handleManageReportClick()
                  }}
                >
                  Add / Manage Calendars
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderSearchResults() {
    const { items, events, calendarId, handleChangeAll, handleDeleteItem } =
      this.props
    const { searchValue, caseSensitive } = this.state
    const filteredItems = matchingExactText(items, searchValue, caseSensitive)
    const filteredItemsOnCalendar = filteredItems.filter(
      ({ uuid }) => events.filter((e) => e.itemUuid === uuid).length > 0
    )
    const handleChangeAllClick = (e) => {
      e.preventDefault()
      handleChangeAll(filteredItems.map((i) => i.uuid))
    }

    const handleDeleteAll = (e) => {
      e.preventDefault()
      handleDeleteItem(filteredItems.map((i) => i.uuid))
    }
    return (
      <>
        <div
          className={[
            styles.topLevel,
            styles.searchHeading,
            "flex-grow-0 flex-shrink-0",
          ].join(" ")}
        >
          <span className="w-8 p-2 text-center">
            <FontAwesomeIcon icon={faSearch} className={styles.topLevelIcon} />
          </span>
          <span className={styles.topLevelName}>
            {filteredItems.length} Events Match '{searchValue}'{" "}
            {filteredItems.length !== filteredItemsOnCalendar.length ? (
              <>
                <br />
                <span style={{ fontWeight: "200" }}>
                  ({filteredItemsOnCalendar.length} matches are on calendar)
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
        <div
          className={`${styles.itemExplorer}`}
          onClick={() => this.handleClick()}
        >
          {this.renderItems(filteredItems, 0, calendarId)}
        </div>
        <div className="mx-4 mt-4 grid grid-cols-2 gap-2 mb-12">
          <Button onClick={handleChangeAllClick}>Change All</Button>
          <Button appearance="destructive" onClick={handleDeleteAll}>
            Delete All
          </Button>
        </div>
      </>
    )
  }

  render() {
    const {
      subdomain,
      calendarId,
      availableCalendars,
      findAndReplaceItems,
      readOnly,
    } = this.props
    const { searchValue, replaceValue, caseSensitive } = this.state
    const handleSearchUpdate = (val) => this.setState({ searchValue: val })
    const handleReplaceUpdate = (val) => this.setState({ replaceValue: val })
    const handleCaseUpdate = (val) => this.setState({ caseSensitive: val })
    const handleReplaceAll = () => {
      findAndReplaceItems(
        subdomain,
        calendarId,
        searchValue,
        replaceValue,
        caseSensitive
      )
    }
    return (
      <>
        <SearchForm
          searchValue={searchValue}
          replaceValue={replaceValue}
          caseSensitive={caseSensitive}
          onSearchChange={handleSearchUpdate}
          onReplaceChange={handleReplaceUpdate}
          onToggleCase={handleCaseUpdate}
          onReplaceAll={handleReplaceAll}
          replaceAllowed={!readOnly}
        />
        {searchValue && searchValue.length > 0
          ? this.renderSearchResults()
          : availableCalendars && availableCalendars.length > 0
          ? this.renderCalendarTree()
          : this.renderItemTree(calendarId)}
      </>
    )
  }
}

ItemExplorerView.propTypes = {
  /**
   * The category entities to render.
   */
  categories: PropTypes.arrayOf(PropTypes.object),

  /**
   * An array of calendar objects if presenting a report view.
   */
  availableCalendars: PropTypes.arrayOf(PropTypes.object),

  /**
   * An array of report calendar ids if presenting a report view.
   */
  reportCalendarIds: PropTypes.arrayOf(PropTypes.number),

  /**
   * The item entities to render.
   */
  items: PropTypes.arrayOf(PropTypes.object),

  /**
   * The item entities to render as notes.
   */
  notes: PropTypes.arrayOf(PropTypes.object),

  /**
   * The category entity representing notes.
   */
  notesCategory: PropTypes.object,

  /**
   * The event entities to render.
   */
  events: PropTypes.arrayOf(PropTypes.object),

  /**
   * Called on an interaction with an item to request
   * the editing modal.
   */
  handleEditItem: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an event to request
   * the editing modal.
   */
  handleEditEvent: PropTypes.func,

  /**
   * Called on an interaction with an event to request
   * the batch action modal.
   */
  handleChangeAll: PropTypes.func,

  /**
   * Called on an interaction with an item to request
   * the deletion modal.
   */
  handleDeleteItem: PropTypes.func.isRequired,

  /**
   * Called when the user requests a find and replace
   * operation on a specific event.
   */
  renameItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar item.
   */
  lockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar item.
   */
  unlockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar category.
   */
  lockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar category.
   */
  unlockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar item.
   */
  showItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideItem: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an category to request
   * the editing modal.
   */
  handleEditCategory: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an category to request
   * the deletion modal.
   */
  handleDeleteCategory: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an event to request
   * the deletion modal.
   */
  handleDeleteEvent: PropTypes.func,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar category.
   */
  showCategory: PropTypes.func.isRequired,

  /**
   * An optional event handler that will be called when the user clicks or
   * taps the Add / Manage Calendars button on the report sidebar.
   */
  handleManageReportClick: PropTypes.func,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will open or close a calendar category.
   */
  openCategory: PropTypes.func.isRequired,

  /**
   * Adds a calendar to a specified report.
   */
  addReportCalendar: PropTypes.func.isRequired,

  /**
   * Removes a calendar from a specified report.
   */
  removeReportCalendar: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar item's parent category.
   */
  updateItemCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar categorys's parent
   * category.
   */
  updateCategoryCategory: PropTypes.func.isRequired,

  /**
   * Dispatches an API patch request to update the name
   * of all items in calendar.
   */
  findAndReplaceItems: PropTypes.func.isRequired,

  /**
   * A boolean flag indicating whether or not an item that
   * may be dropped onto this category is currently hovering
   * above it.
   */
  isOver: PropTypes.bool.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDropTarget: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update the status of whether or not a
   * calendar item (not an existing event) is being dragged.
   */
  updateItemDraggingStatus: PropTypes.func.isRequired,

  /**
   * An array of item IDs that have been marked as selected
   * by the user.
   */
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * An array of event IDs that have been marked as selected
   * by the user.
   */
  selectedEvents: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * An array of category IDs that have been marked as selected
   * by the user.
   */
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Disables certain interactions becuase the user is selecting
   * items as opposed to navigating the event tree.
   */
  multiSelectMode: PropTypes.bool.isRequired,

  /**
   * Specifies whether the view is in read only mode and should not
   * allow for editing or item creation.
   */
  readOnly: PropTypes.bool.isRequired,

  /**
   * Indicates whether or not multiple items can be selected.
   */
  multiselectEnabled: PropTypes.bool.isRequired,

  /**
   * The subdomain of the current organization which has calendar(s)
   * currently being rendered.
   */
  subdomain: PropTypes.string.isRequired,

  /**
   * The id of the current calendar being rendered.
   */
  calendarId: PropTypes.string,
}

/**
 * A react DND specification for the DropTarget component representing
 * the EventTargetView
 * @type {Object}
 */
const itemViewTarget = {
  canDrop(props, monitor) {
    return !props.readOnly && monitor.isOver({ shallow: false })
  },
  drop(props, monitor) {
    const { updateItemCategory, updateCategoryCategory, readOnly } = props
    const item = monitor.getItem()
    const updateAction =
      item.itemType === ItemTypes.CATEGORY_VIEW
        ? updateCategoryCategory
        : updateItemCategory
    !readOnly && updateAction(item.uuid, null)
  },
}

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  }
}

export default DropTarget(
  [ItemTypes.ITEM_VIEW, ItemTypes.CATEGORY_VIEW],
  itemViewTarget,
  dropCollect
)(ItemExplorerView)
