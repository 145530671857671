// @flow
import { middleware as protectedMiddleware } from "redux-jwt-protected-middleware"
import { camelizeKeys } from "humps"
import { API_ROOT } from "../selectors"
import { auth } from "../actions"
import {
  getRefreshToken,
  getAccessToken,
  setRefreshToken,
  setAccessToken,
} from "../tokens"

/**
 * A default error to pass if the access token could not be refreshed.
 */
const REFRESH_ERROR = { error: "[API] Auth token could not refresh" }

/**
 * Sends a request to obtina a new pair of JWTs from the oauth provider.
 * @param {String} refreshToken The current valid refresh token.
 * @param {String} apiRoot The current base domain for the API.
 * @returns {Promise} A promise resolving the JSON
 */
const refreshTokens = (refreshToken, apiRoot) =>
  fetch(`${apiRoot}/oauth/token`, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `refresh_token=${refreshToken}&grant_type=refresh_token`,
  })
    .then((res) => {
      if (!res.ok) {
        throw REFRESH_ERROR
      }
      return res.json()
    })
    .then((json) => camelizeKeys(json))

const config = {
  debug: false,
  currentAccessToken: () => getAccessToken() || "",
  currentRefreshToken: () => getRefreshToken() || "",
  handleRefreshAccessToken: (refreshToken, store) =>
    new Promise(async (resolve, reject) => {
      const apiRoot = API_ROOT(store.getState())
      try {
        const json = await refreshTokens(refreshToken, apiRoot)
        if (json.success === false) {
          reject(Error("Could not refresh authentication token."))
        } else {
          setRefreshToken(json.refreshToken)
          setAccessToken(json.accessToken)
          resolve(json.accessToken)
        }
      } catch (e) {
        setRefreshToken("")
        setAccessToken("")
        resolve("")
        store.dispatch(auth.tokenRefreshFailure())
      }
    }),
  handleAuthenticationError: (error) => console.error(error),
}

export default protectedMiddleware(config)
