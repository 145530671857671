import React from "react"
import { DateTime } from "luxon"
import clsx from "clsx"

export interface WeekHeadingProps {
  simplified?: boolean
  limited?: boolean
  highContrast?: boolean
  style?: React.CSSProperties
}

const HEADER_STYLES =
  "flex py-2 flex-row w-full bg-white text-rose-darkest border-b border-rose"
const LIST_STYLES = "flex flex-row flex-grow my-auto"
const LIST_ITEM_STYLES = "flex flex-1"
const HEADING_STYLES = "font-bold my-auto truncate uppercase pl-4 text-xs"

export const WeekHeading: React.FC<WeekHeadingProps> = ({
  simplified,
  limited,
  style,
  highContrast,
}) => {
  const weekDays = new Array(7).fill("").map((_, i) =>
    DateTime.utc()
      .set({ weekday: i })
      .toFormat(simplified ? "EEE" : "EEEE")
  )
  const fontSize = limited ? "0.5rem" : simplified ? "0.675rem" : "0.875rem"

  return (
    <header
      className={clsx(HEADER_STYLES)}
      style={{
        fontSize,
        ...style,
        color: highContrast ? "black" : style?.color,
      }}
    >
      <ul className={LIST_STYLES}>
        {weekDays.map((dayOfWeek, i) => (
          <li key={`heading-${dayOfWeek}-${i}`} className={LIST_ITEM_STYLES}>
            <h3 className={HEADING_STYLES}>{dayOfWeek}</h3>
          </li>
        ))}
      </ul>
    </header>
  )
}
