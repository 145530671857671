import React, { useState } from "react"
import { View } from "../../shared/"
import { faCalendarPlus } from "@fortawesome/pro-regular-svg-icons"
import {
  CalendarImportForm,
  CalendarImportFormValues,
} from "./CalendarImportForm"
import { useImportCalendar, useGroups, useCreateGroup } from "../../api/hooks"
import { GroupDataStructure, useAddGroups } from "../../api/atoms"
import { useHistory, useParams } from "react-router"
import toast from "react-hot-toast"
import { Button } from "ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft } from "@fortawesome/pro-solid-svg-icons"
import clsx from "clsx"

const {
  ViewContainer,
  ToolBar,
  ViewContent,
  ToolBarSection,
  ToolBarIcon,
  ToolBarHeading,
} = View

interface CalendarImportViewProps {}

export const CalendarImportView: React.FC<CalendarImportViewProps> = () => {
  const [timedOut, setTimedOut] = useState(false)
  const { subdomain } = useParams<{ subdomain: string }>()
  const history = useHistory()
  const [importCalendar, { loading }] = useImportCalendar()
  const handleSave = async (values: CalendarImportFormValues) => {
    const result = await importCalendar({ body: { ...values, subdomain } })
    const calendarId = result?.data?.calendar?.id
    if (calendarId) {
      toast.success(
        `Successfully imported "${values.file}" into calendar "${values.name}".`
      )
      history.push(`/org/${subdomain}/cal/${calendarId}`)
    }
    if ((result as any)?.message === "Endpoint request timed out") {
      toast.error(
        `The importer timed out on your file before it could complete. Your calendar was still created. Look in the calendar drawer to review your calendar.`
      )
      setTimedOut(true)
    }
  }

  const handleStandardFormClick = () =>
    history.replace(`/org/${subdomain}/cal/new`)

  const { groups, loading: loadingGroups } = useGroups(subdomain)
  const [createGroup] = useCreateGroup(subdomain)
  const addGroups = useAddGroups()

  const [creatingGroup, setCreatingGroup] = useState(false)
  const handleCreateGroup = async (name: string) => {
    setCreatingGroup(true)
    const group = await createGroup({ body: { name } })
    setCreatingGroup(false)
    if (group) {
      toast.success("Group created")
      if (group) {
        addGroups([group as GroupDataStructure])
      }
      return group.uuid
    }
    return
  }

  return (
    <ViewContainer>
      <ToolBar>
        <ToolBarSection required={true} ignoresGrid={true}>
          <ToolBarIcon icon={faCalendarPlus} />
          <ToolBarHeading primaryText="Import Calendar" secondaryText="BETA" />
        </ToolBarSection>
      </ToolBar>
      <ViewContent>
        <div className="rounded bg-rose-lighter p-4 w-1/3 border-2 border-rose-default mb-4">
          <h3 className="font-bold text-lochivar-dark">Import External Data</h3>
          <p className="text-sm my-2">
            A custom importer to process schedules in excel format is available.
            Please note this function is in private beta for select
            organizations.
          </p>
          <p className="text-sm my-2">
            You should use our standard form if you wish to create a calendar
            via the standard form or just need to duplicate an existing calendar
            already in PROCal.
          </p>
          <Button onClick={handleStandardFormClick} appearance="link">
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className="my-auto mr-2"
            />
            Return to the standard form.
          </Button>
        </div>
        <div
          className={clsx(
            "border-t-2 border-rose-lighter pt-4 w-1/3",
            timedOut && "opacity-25 pointer-events-none"
          )}
        >
          <CalendarImportForm
            onSave={handleSave}
            loading={loading || loadingGroups || creatingGroup}
            groups={groups.map((g) => ({ uuid: g.uuid, name: g.name }))}
            onCreateGroup={handleCreateGroup}
          />
        </div>
      </ViewContent>
    </ViewContainer>
  )
}
