import queryString from "query-string"
import { decamelizeKeys } from "humps"
import { CALL_API, Schemas } from "../middlewares"
import { PROTECTED } from "redux-jwt-protected-middleware"
import * as types from "../actionTypes"
import { applyUTC } from "../helpers"
import { optimisticDateRangeUpdate } from "./events"

//
// TOUCH / Track a page view on a specific calendar
//

export const touchCalendar = (subdomain, id) => ({
  type: types.TOUCH_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.CALENDAR,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${id || ""}/touch`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.TOUCH_CALENDAR_REQUEST,
      types.TOUCH_CALENDAR_SUCCESS,
      types.TOUCH_CALENDAR_FAILURE,
    ],
  },
})

export const requestTouchCalendar = (subdomain, id) => (dispatch) => {
  console.log("requestTouchCalendar", subdomain, id)
  return dispatch(touchCalendar(subdomain, id))
}

//
// GET / Find all OR by :subdomain
//

export const getCalendar = (subdomain, id) => ({
  type: types.FETCH_CALENDARS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.CALENDAR : Schemas.CALENDARS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${id || ""}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_CALENDARS_REQUEST,
      types.FETCH_CALENDARS_SUCCESS,
      types.FETCH_CALENDARS_FAILURE,
    ],
  },
})

export const requestCalendar = (subdomain, id) => (dispatch) => {
  if (id && id !== "undefined") {
    dispatch(touchCalendar(subdomain, id))
  }
  if (id === "undefined") {
    return
  }
  return dispatch(getCalendar(subdomain, id))
}

//
// GET / download key
//

export const getDownloadCalendarKey = (subdomain, id) => ({
  type: types.DOWNLOAD_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${id}/download`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DOWNLOAD_CALENDAR_REQUEST,
      types.DOWNLOAD_CALENDAR_SUCCESS,
      types.DOWNLOAD_CALENDAR_FAILURE,
    ],
  },
})

export const downloadCalendar = (subdomain, id) => (dispatch) => {
  return dispatch(getDownloadCalendarKey(subdomain, id))
}

//
// POST / new print request token
//

export const postCalendarPrintToken = (subdomain, id, clientUrl) => ({
  type: types.PRINT_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/calendars/${id}/print_request`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys({ clientUrl })),
    types: [
      types.PRINT_CALENDAR_REQUEST,
      types.PRINT_CALENDAR_SUCCESS,
      types.PRINT_CALENDAR_FAILURE,
    ],
  },
})

export const createCalendarPrintToken =
  (subdomain, id, clientUrl) => (dispatch) =>
    dispatch(postCalendarPrintToken(subdomain, id, clientUrl)).then(
      ({ payload }) => payload
    )

//
// GET / calendar job print status
//

export const getCalendarPrintStatus = (subdomain, id, jobId) => ({
  type: types.PRINT_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${id}/print_status?${queryString.stringify(
      decamelizeKeys({ jobId })
    )}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.PRINT_CALENDAR_REQUEST,
      types.PRINT_CALENDAR_SUCCESS,
      types.PRINT_CALENDAR_FAILURE,
    ],
  },
})

export const checkCalendarPrintStatus = (subdomain, id, jobId) => (dispatch) =>
  dispatch(getCalendarPrintStatus(subdomain, id, jobId)).then(
    ({ payload }) => payload
  )

//
// POST / new calendar
//

export const postCalendar = (subdomain, calendar) => ({
  type: types.CREATE_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.CALENDAR,
    endpoint: `/api/organizations/${subdomain}/calendars/`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        ...calendar,
        endDate: calendar.endDate ? applyUTC(calendar.endDate) : null,
        startDate: calendar.startDate ? applyUTC(calendar.startDate) : null,
      })
    ),
    types: [
      types.CREATE_CALENDAR_REQUEST,
      types.CREATE_CALENDAR_SUCCESS,
      types.CREATE_CALENDAR_FAILURE,
    ],
  },
})

export const createCalendar = (subdomain, calendar) => (dispatch) => {
  return dispatch(postCalendar(subdomain, calendar))
}

//
// PUT / update calendar
//

export const putCalendar = (subdomain, calendar) => ({
  type: types.UPDATE_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.CALENDAR,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendar.id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        ...calendar,
      })
    ),
    types: [
      types.UPDATE_CALENDAR_REQUEST,
      types.UPDATE_CALENDAR_SUCCESS,
      types.UPDATE_CALENDAR_FAILURE,
    ],
  },
})

export const updateCalendar = (subdomain, calendar) => (dispatch) => {
  return dispatch(putCalendar(subdomain, calendar))
}

//
// PATCH / push calendar
//

export const patchPushCalendar = (
  subdomain,
  calendarId,
  offset,
  date,
  filters
) => ({
  type: types.UPDATE_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.EVENTS_ARRAY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/push`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        offset,
        date,
        ...filters,
        fillUnusedDays: false,
      })
    ),
    types: [
      types.PUSH_CALENDAR_REQUEST,
      types.PUSH_CALENDAR_SUCCESS,
      types.PUSH_CALENDAR_FAILURE,
    ],
  },
})

export const pushCalendar =
  (subdomain, calendarId, offset, originDate, filters) => async (dispatch) => {
    const response = await dispatch(
      patchPushCalendar(subdomain, calendarId, offset, originDate, filters)
    )
    await dispatch(optimisticDateRangeUpdate(calendarId))
    return response
  }

//
// WEEK NUMBERS / Change week number status.
//

export const patchWeekNumbers = (subdomain, calendarId, showWeekNumbers) => ({
  type: types.CALENDAR_FIND_AND_REPLACE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CALENDAR,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/show-week-numbers`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        showWeekNumbers,
      })
    ),
    types: [
      types.CALENDAR_WEEK_NUMBERS_REQUEST,
      types.CALENDAR_WEEK_NUMBERS_SUCCESS,
      types.CALENDAR_WEEK_NUMBERS_FAILURE,
    ],
  },
})

export const requestPatchWeekNumbers =
  (subdomain, id, showWeekNumbers) => (dispatch) => {
    return dispatch(patchWeekNumbers(subdomain, id, showWeekNumbers))
  }

//
// RESTORE / Revert a calendar to a prior snapshot.
//

export const patchRestoreCalendar = (subdomain, calendarId, snapshotId) => ({
  type: types.CALENDAR_RESTORE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CALENDAR,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/restore`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        snapshotId,
      })
    ),
    types: [
      types.CALENDAR_RESTORE_REQUEST,
      types.CALENDAR_RESTORE_SUCCESS,
      types.CALENDAR_RESTORE_FAILURE,
    ],
  },
})

export const restoreCalendar = (subdomain, id, snapshotId) => (dispatch) => {
  return dispatch(patchRestoreCalendar(subdomain, id, snapshotId))
}

//
// PATCH / find and replace names on a calendar
//

export const patchFindAndReplaceItems = (
  subdomain,
  calendarId,
  matchValue,
  replaceValue,
  caseSensitive
) => ({
  type: types.CALENDAR_FIND_AND_REPLACE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEMS_ARRAY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/find-and-replace`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        matchValue,
        replaceValue,
        caseSensitive,
      })
    ),
    types: [
      types.CALENDAR_FIND_AND_REPLACE_REQUEST,
      types.CALENDAR_FIND_AND_REPLACE_SUCCESS,
      types.CALENDAR_FIND_AND_REPLACE_FAILURE,
    ],
  },
})

export const findAndReplaceItems =
  (subdomain, calendarId, matchValue, replaceValue, caseSensitive) =>
  (dispatch) => {
    return dispatch(
      patchFindAndReplaceItems(
        subdomain,
        calendarId,
        matchValue,
        replaceValue,
        caseSensitive
      )
    )
  }

//
// GET / validation errors
//

export const getCalendarValidation = (subdomain, values) => ({
  type: types.VALIDATE_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/validations?${queryString.stringify(
      values
    )}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.VALIDATE_CALENDAR_REQUEST,
      types.VALIDATE_CALENDAR_SUCCESS,
      types.VALIDATE_CALENDAR_FAILURE,
    ],
  },
})

export const requestCalendarValidation = (subdomain, values) => (dispatch) => {
  return dispatch(getCalendarValidation(subdomain, values))
}

//
// DELETE / Delete existing Calendar via ID.
//

export const deleteCalendar = (subdomain, id) => ({
  type: types.DELETE_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.CALENDAR,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_CALENDAR_REQUEST,
      types.DELETE_CALENDAR_SUCCESS,
      types.DELETE_CALENDAR_FAILURE,
    ],
  },
})

export const removeCalendar = (subdomain, id) => (dispatch) => {
  return dispatch(deleteCalendar(subdomain, id))
}
