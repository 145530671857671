var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PROGRESS_BAR_STYLES = "absolute top-0 left-0 flex p-1 bg-gray-400 bg-opacity-25 w-full rounded";
var METER_STYLES = "flex bg-lochivar-default rounded";
export var Progress = function (_a) {
    var _b = _a.progress, progress = _b === void 0 ? 0 : _b;
    return (_jsxs("div", __assign({ className: PROGRESS_BAR_STYLES }, { children: [_jsx("span", { className: METER_STYLES, style: { width: (progress !== null && progress !== void 0 ? progress : 0) + "%", height: "4px" } }, void 0), _jsx("progress", { max: "100", value: progress, className: "absolute opacity-0" }, void 0)] }), void 0));
};
