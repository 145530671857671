import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { categories as actions } from "../actions"
import { categorySelector } from "../selectors"

export const useNotesCategory = (subdomain: string, calendarId: string) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestCategory(subdomain, calendarId, null))
  const notesCategory = useSelector(categorySelector.forCalendar)(
    calendarId
  ).find((c: any) => c.name === "Notes")
  const { loading, refetch } = useRequest(request)
  return { loading, notesCategory, refetch }
}
