import React from "react"
import * as V from "victory"
import { StylableProps } from "./Styleable"
import { Title } from "./Title"
import { Panel } from "./Panel"
import { DateTime } from "luxon"

const { VictoryLine, VictoryTooltip } = V

export interface LineChartPanelProps {
  colorScale?:
    | string[]
    | "heatmap"
    | "qualitative"
    | "grayscale"
    | "warm"
    | "cool"
    | "red"
    | "green"
    | "blue"
    | undefined
  data?: any[]
  title?: string
  loading?: boolean
}

export const LineChartPanel: React.FC<LineChartPanelProps & StylableProps> = ({
  title,
  colorScale,
  data,
  loading,
  className,
  style,
}) => {
  return (
    <Panel loading={loading} style={style} className={className}>
      {title ? <Title responsive>{title}</Title> : null}
      <V.VictoryChart width={1200} height={300} theme={V.VictoryTheme.material}>
        <VictoryLine
          labelComponent={<VictoryTooltip />}
          colorScale={loading ? "grayscale" : colorScale ?? "qualitative"}
          data={
            loading || (data?.length ?? 0) < 1
              ? [{ x: "", y: 100 }]
              : data?.map((d) => ({ ...d, label: d.y }))
          }
        />
        <V.VictoryAxis
          tickCount={Math.max(Math.min(data?.length ?? 1, 12), 1)}
          tickFormat={(t) => DateTime.fromISO(t).toFormat("MMM d")}
        />
        <V.VictoryAxis dependentAxis />
      </V.VictoryChart>
    </Panel>
  )
}
