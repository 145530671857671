var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, TextField, Fields, DateField, DropdownField, DropDownItem, } from "../Form";
import { DateTime } from "luxon";
import { AbsoluteOverlay } from "../Indicators";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDefaultValueListener } from "../../hooks";
import { camelCase } from "lodash";
import { ErrorList } from "../Form/ErrorList";
import { TextAreaField } from "../Form";
var partitions = [
    {
        name: "Early",
        value: 0,
    },
    {
        name: "Mid-Day",
        value: 1,
    },
    {
        name: "Late",
        value: 2,
    },
];
var schema = yup.object({
    name: yup.string().required("note name cannot be blank"),
    length: yup
        .number()
        .typeError("of event must be at least 1 day")
        .min(1)
        .required()
        .nullable(),
    partition: yup.string().required("day part cannot be blank"),
    notes: yup.string(),
});
var compareAsString = function (a, b) {
    return "" + a === "" + b;
};
export var NoteForm = function (_a) {
    var _b, _c, _d;
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle;
    var _e = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    }), handleSubmit = _e.handleSubmit, register = _e.register, formErrors = _e.formState.errors, watch = _e.watch, setError = _e.setError, setValue = _e.setValue, reset = _e.reset;
    useDefaultValueListener(defaultValues, reset);
    var _f = watch([
        "startsAt",
        "partition",
        "length",
    ]), startsAt = _f[0], partition = _f[1], length = _f[2];
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var partitionValue = (_c = (_b = partitions.find(function (p) { return compareAsString(p.value, partition); })) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "";
    var handleDateChange = function (d, r) {
        var _a, _b;
        setValue("startsAt", d[0].toISODate(), { shouldValidate: true });
        setValue("length", ((_a = d[1]) === null || _a === void 0 ? void 0 : _a.diff((_b = d[0]) !== null && _b !== void 0 ? _b : d[1], "days").days) + 1, {
            shouldValidate: true,
        });
    };
    var start = startsAt
        ? DateTime.fromISO(startsAt, { zone: "utc" })
        : undefined;
    var end = start === null || start === void 0 ? void 0 : start.plus({ days: length - 1 });
    return (_jsxs("form", __assign({ onSubmit: handleFormSubmit, className: "relative" }, { children: [_jsxs(Fields, __assign({ register: register, errors: formErrors, grow: true, className: "pb-2" }, { children: [_jsx(TextField, { name: "name", label: "Name", placeholder: "Name of Note", className: "w-full", onChange: function (e) {
                            console.log(e);
                        }, required: true, initiallyFocused: true }, void 0), _jsx(DateField, { name: "occursOn", label: "Duration", value: startsAt, dates: [start, end].filter(function (i) { return i; }), onDateChange: handleDateChange, className: "flex-grow", error: (_d = formErrors.length) === null || _d === void 0 ? void 0 : _d.message, range: true, rangeDirection: "forward", required: true }, void 0), _jsx(DropdownField, __assign({ name: "partition", label: "Default Day Part", placeholder: "Select a Day part", value: partitionValue, grow: true, hideSearch: true }, { children: function (search) {
                            return partitions
                                .filter(function (o) {
                                return search.length === 0 ||
                                    o.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                            })
                                .map(function (o) { return (_jsx(DropDownItem, __assign({ onClick: function (e) {
                                    e.preventDefault();
                                    setValue("partition", o.value);
                                }, selected: "" + partition === "" + o.value, item: { label: o.name, id: "" + o.value } }, { children: o.name }), o.value)); });
                        } }), void 0), _jsx(TextAreaField, { name: "notes", label: "Notes", placeholder: "Add notes...", className: "flex-grow" }, void 0), _jsx(ErrorList, { errors: formErrors }, void 0), _jsx("input", { type: "hidden", name: "startsAt", value: startsAt }, void 0), _jsx("input", { type: "hidden", name: "length", value: length }, void 0), _jsx(Button, __assign({ type: "submit", className: "mt-2 w-full" }, { children: submitTitle !== null && submitTitle !== void 0 ? submitTitle : "Create Note" }), void 0)] }), void 0), loading ? _jsx(AbsoluteOverlay, {}, void 0) : null] }), void 0));
};
