var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Indicators from "../Indicators";
import { Button } from "../Form";
var CONTAINER_STYLES = "flex h-screen w-full p-3 items-center content-center";
var MODAL_STYLES = "m-auto border-2 border-rose-dark rounded-lg p-5 flex";
var CONTENT_STYLES = "relative text-center flex flex-grow flex-col align-center items-center text-rose-darkest";
var TITLE_STYLES = "text-rose-darkest text-center font-bold text-md mt-2";
var SUBTITLE_STYLES = "text-rose-darkest font-body text-sm";
var BUTTON_STYLES = "mt-3";
export var Placeholder = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, icon = _a.icon, action = _a.action, onClick = _a.onClick, loading = _a.loading;
    return (_jsx("div", __assign({ className: CONTAINER_STYLES }, { children: _jsx("div", __assign({ className: MODAL_STYLES, style: { minWidth: 144, minHeight: 144, maxWidth: 480 } }, { children: _jsxs("article", __assign({ className: CONTENT_STYLES }, { children: [icon ? _jsx(FontAwesomeIcon, { icon: icon, size: "4x" }, void 0) : null, title ? _jsx("h1", __assign({ className: TITLE_STYLES }, { children: title }), void 0) : null, subtitle ? _jsx("p", __assign({ className: SUBTITLE_STYLES }, { children: subtitle }), void 0) : null, onClick ? (_jsx(Button, __assign({ onClick: onClick, appearance: "secondary", className: BUTTON_STYLES }, { children: action !== null && action !== void 0 ? action : "Continue" }), void 0)) : null, loading ? _jsx(Indicators.AbsoluteOverlay, {}, void 0) : null] }), void 0) }), void 0) }), void 0));
};
