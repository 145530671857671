var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Field, getErrorMessage } from "./Field";
import { StyleSelect } from "./StyleSelect";
export var StyleField = function (_a) {
    var id = _a.id, label = _a.label, name = _a.name, options = _a.options, onChange = _a.onChange, onCreate = _a.onCreate, onSelect = _a.onSelect, placeholder = _a.placeholder, style = _a.style, className = _a.className, disabled = _a.disabled, errorMessage = _a.errorMessage, errors = _a.errors, value = _a.value, control = _a.control, clearable = _a.clearable, creatable = _a.creatable, fieldProps = __rest(_a, ["id", "label", "name", "options", "onChange", "onCreate", "onSelect", "placeholder", "style", "className", "disabled", "errorMessage", "errors", "value", "control", "clearable", "creatable"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    return (_jsx(Field, __assign({ name: name, label: label, className: className, style: style, errorMessage: determinedErrorMessage }, fieldProps, { children: _jsx(StyleSelect, { control: control, id: id, name: name, value: value, options: options, onChange: onChange, onSelect: onSelect, onCreate: onCreate, placeholder: placeholder, disabled: disabled, clearable: clearable, creatable: creatable }, void 0) }), void 0));
};
