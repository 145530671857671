import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Indicator, Flex, View } from "../../shared"
import { Button } from "ui"
import { invitationSelector } from "../../api"
import toast from "react-hot-toast"

const { checkResend, checkExpired } = invitationSelector

class ResendFormView extends Component {
  submitForm() {
    const { resendInvitation, subdomain, invitationId } = this.props
    return resendInvitation(subdomain, invitationId).then(() => {
      toast.success("Invitation Successfully Resent")
    })
  }

  render() {
    const { submitting, handleSubmit, invitationId, lastSentDate } = this.props
    const { Card, CardTitle } = View
    const invitation = { id: invitationId, updatedAt: lastSentDate }

    return (
      <Card>
        <CardTitle>
          Invitation {checkExpired(invitation) ? "has Expired" : "is Pending"}
        </CardTitle>
        {checkExpired(invitation) ? (
          <p>
            This user did not accept this invitation within 24 hours. You can
            resend a new invitation to allow this user to create their account.
          </p>
        ) : (
          <p>
            This user is still pending as they have not accepted their
            invitation yet.
          </p>
        )}
        <form onSubmit={handleSubmit((values) => this.submitForm(values))}>
          <Button
            type="submit"
            align="stretch"
            disabled={submitting || !checkResend(invitation)}
          >
            <Flex.Container>
              {submitting && <Indicator.Ring theme={"button"} />}
              {checkResend(invitation)
                ? "Resend Invitation"
                : "Invitation Sent"}
            </Flex.Container>
          </Button>
          <p>
            <small>
              Invite last sent: {moment.utc(invitation.updatedAt).fromNow()}
            </small>
          </p>
        </form>
      </Card>
    )
  }
}

ResendFormView.propTypes = {
  /**
   * Injected by redux form. Indicates whether or not the form
   * is currently submitting.
   */
  submitting: PropTypes.bool,

  /**
   * Injected by redux form. Indicates whether or not the form
   * is currently submitting.
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will
   * post the contents of the form to the APIs invitation#update
   * method.
   */
  resendInvitation: PropTypes.func.isRequired,

  /**
   * The id of the invitation to update.
   */
  invitationId: PropTypes.number.isRequired,

  /**
   * The subdomain identifying the organization the invitation belongs to.
   */
  subdomain: PropTypes.string.isRequired,

  /**
   * The last time the invitation could have possibly been sent.
   */
  lastSentDate: PropTypes.string.isRequired,
}

export default ResendFormView
