const nameSort = (a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0)

/**
 * Sorts entities in alhpa numeric order by name.
 * @param  {Array} entities An array of item entities to sort.
 * @param  {Integer} calendarId An ID for a specific calendar.
 * @return {Array}          A copy of the entities array sorted by name.
 */
export const sortedByName = (entities) => entities.sort(nameSort)

const belongsToCalendar = (entityCalendarId, calendarId) => {
  const matched = parseInt(entityCalendarId, 0) === parseInt(calendarId, 0)
  return !calendarId || matched
}

/**
 * Filters out any entities that have no parent entities present.
 * @param  {Array} entities An array of item objects.
 * @param  {Integer} calendarId An ID for a specific calendar.
 * @return {Array}       An array of entities without a category ID.
 */
export const forRoot = (entities, calendarId) => {
  return entities
    .filter((entity) => entity.name !== "Notes")
    .filter((entity) => {
      return (
        !entity.categoryUuid &&
        belongsToCalendar(entity.snapshotId || entity.calendarId, calendarId)
      )
    })
    .sort(nameSort)
}

/**
 * Filters a set of entities that belong to a specific category id.
 * @param  {Array} entities     An array of entities to filter.
 * @param  {Integer} categoryUuid An ID for a specific category.
 * @param  {Integer} calendarId An ID for a specific calendar.
 * @return {Array}              A filtered set of entities matching the category.
 */
export const forCategory = (entities, categoryUuid, calendarId) =>
  entities
    .filter((entity) => entity.name !== "Notes")
    .filter(
      (entity) =>
        entity.categoryUuid === categoryUuid &&
        (!calendarId ||
          parseInt(entity.snapshotId || entity.calendarId, 0) ===
            parseInt(calendarId, 0))
    )
    .sort(nameSort)

/**
 * Filters a set of entities that belong to a specific item id.
 * @param  {Array} entities     An array of entities to filter.
 * @param  {Integer} itemUuid     An ID for a specific item.
 * @return {Array}              A filtered set of entities matching the item.
 */
export const forItem = (entities, itemUuid, calendarId) =>
  entities
    .filter(
      (entity) =>
        entity.itemUuid === itemUuid &&
        (!calendarId ||
          parseInt(entity.snapshotId || entity.calendarId, 0) ===
            parseInt(calendarId, 0))
    )
    .sort(nameSort)

/**
 * Filters any items that match the specified text via
 * fuzzy search. Multiple words will be inclusive in
 * filtering.
 * @param  {Array} entities   The entities to filter.
 * @param  {String} text      The text to filter by.
 * @return {Array}            A filtered set of entities matching the search text.
 */
export const matchingText = (entities, text) =>
  entities
    .filter(
      (entity) =>
        !text
          .split(" ")
          .map((word) => entity.name.toLowerCase().includes(word.toLowerCase()))
          .includes(false)
    )
    .sort(nameSort)

/**
 * Filters any items that match the specified text via
 * exact search. Case sensitivity can also be specified.
 * @param  {Array}    entities        The entities to filter.
 * @param  {String}   text            The text to filter by.
 * @param  {Boolean}  caseSensitive   Indicates whether or not to take case into consideration.
 * @return {Array}                    A filtered set of entities matching the search text.
 */
export const matchingExactText = (entities, text, caseSensitive) =>
  entities
    .filter((entity) =>
      (caseSensitive ? entity.name : entity.name.toLowerCase()).includes(
        caseSensitive ? text : text.toLowerCase()
      )
    )
    .sort(nameSort)
