import { PROTECTED } from "redux-jwt-protected-middleware"
import { decamelizeKeys } from "humps"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"

//
// GET / Find all OR by :subdomain
//

export const getReport = (subdomain, id) => ({
  type: types.FETCH_REPORTS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.REPORT : Schemas.REPORTS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/reports/${id || ""}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_REPORTS_REQUEST,
      types.FETCH_REPORTS_SUCCESS,
      types.FETCH_REPORTS_FAILURE,
    ],
  },
})

export const requestReport = (subdomain, id) => (dispatch) => {
  return dispatch(getReport(subdomain, id))
}

//
// GET / Find detailed calendar info for a report.
//

export const getFullReportCalendars = (subdomain, id) => ({
  type: types.FETCH_FULL_REPORT_CALENDARS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.CALENDARS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/reports/${id}/calendars`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_FULL_REPORT_CALENDARS_REQUEST,
      types.FETCH_FULL_REPORT_CALENDARS_SUCCESS,
      types.FETCH_FULL_REPORT_CALENDARS_FAILURE,
    ],
  },
})

export const requestFullReportCalendars = (subdomain, id) => (dispatch) => {
  return dispatch(getFullReportCalendars(subdomain, id))
}

//
// POST / new report
//

export const postReport = (subdomain, report) => ({
  type: types.CREATE_REPORT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.REPORT,
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/reports`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(report)),
    types: [
      types.CREATE_REPORT_REQUEST,
      types.CREATE_REPORT_SUCCESS,
      types.CREATE_REPORT_FAILURE,
    ],
  },
})

export const createReport = (subdomain, report) => (dispatch) => {
  return dispatch(postReport(subdomain, report))
}

//
// PUT / update report
//

export const putReport = (subdomain, report) => ({
  type: types.UPDATE_REPORT,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.REPORT,
    endpoint: `/api/organizations/${subdomain}/reports/${report.id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(report)),
    types: [
      types.UPDATE_REPORT_REQUEST,
      types.UPDATE_REPORT_SUCCESS,
      types.UPDATE_REPORT_FAILURE,
    ],
  },
})

export const updateReport = (subdomain, report) => (dispatch) => {
  return dispatch(putReport(subdomain, report))
}

//
// DELETE / Delete existing Calendar via ID.
//

export const deleteReport = (subdomain, id) => ({
  type: types.DELETE_REPORT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.REPORT,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/reports/${id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_REPORT_REQUEST,
      types.DELETE_REPORT_SUCCESS,
      types.DELETE_REPORT_FAILURE,
    ],
  },
})

export const removeReport = (subdomain, id) => (dispatch) => {
  return dispatch(deleteReport(subdomain, id))
}

//
// POST / Add Calendar to report via ID.
//

export const postReportCalendar = (subdomain, id, calendarId) => ({
  type: types.ADD_REPORT_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.REPORT_CALENDAR,
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/reports/${id}/add_calendar/${calendarId}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.ADD_REPORT_CALENDAR_REQUEST,
      types.ADD_REPORT_CALENDAR_SUCCESS,
      types.ADD_REPORT_CALENDAR_FAILURE,
    ],
  },
})

export const addReportCalendar = (subdomain, id, calendarId) => (dispatch) => {
  return dispatch(postReportCalendar(subdomain, id, calendarId))
}

//
// PUT / Update Calendar for report via ID.
//

export const putReportCalendar = (
  subdomain,
  id,
  calendarId,
  reportCalendar
) => ({
  type: types.ADD_REPORT_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.REPORT_CALENDAR,
    method: "PUT",
    endpoint: `/api/organizations/${subdomain}/reports/${id}/update_calendar/${calendarId}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(reportCalendar)),
    types: [
      types.UPDATE_REPORT_CALENDAR_REQUEST,
      types.UPDATE_REPORT_CALENDAR_SUCCESS,
      types.UPDATE_REPORT_CALENDAR_FAILURE,
    ],
  },
})

export const updateReportCalendar =
  (subdomain, id, calendarId, reportCalendar) => (dispatch) => {
    return dispatch(
      putReportCalendar(subdomain, id, calendarId, reportCalendar)
    )
  }

//
// DELETE / Delete existing Calendar from report via ID.
//

export const deleteReportCalendar = (subdomain, id, calendarId) => ({
  type: types.REMOVE_REPORT_CALENDAR,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.REPORT_CALENDAR,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/reports/${id}/remove_calendar/${calendarId}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.REMOVE_REPORT_CALENDAR_REQUEST,
      types.REMOVE_REPORT_CALENDAR_SUCCESS,
      types.REMOVE_REPORT_CALENDAR_FAILURE,
    ],
  },
})

export const removeReportCalendar =
  (subdomain, id, calendarId) => (dispatch) => {
    return dispatch(deleteReportCalendar(subdomain, id, calendarId))
  }
