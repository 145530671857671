import React from "react"
import PropTypes from "prop-types"
import styles from "./Region.module.css"

const Region = ({ children }) => <div className={styles.region}>{children}</div>

Region.propTypes = {
  children: PropTypes.node,
}

export default Region
