import React, { Component } from "react"
import { Toaster } from "react-hot-toast"
import { PropTypes } from "prop-types"
import { ItemDragLayerContainer } from "../itemExplorer"
import Modal from "../shared/Modal"
import Helmet from "react-helmet"
import { withRouter } from "react-router"

class App extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      nextProps.location.state.modal
    ) {
      this.previousChildren = this.props.children
    }
  }

  render() {
    const { location, children } = this.props
    const isModal =
      location.state && location.state.modal && this.previousChildren
    return (
      <>
        <div>
          <Helmet titleTemplate="%s | ProCal" defaultTitle="Viewing Calendar" />
          {isModal ? this.previousChildren : children}
          {isModal && (
            <Modal.Modal
              returnTo={location.state.returnTo}
              draggable={true}
              title={location.state.title}
            >
              {children}
            </Modal.Modal>
          )}
          <ItemDragLayerContainer />
        </div>
        <Toaster position="top-right" />
      </>
    )
  }
}

App.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default withRouter(App)
