import React from "react"
import PropTypes from "prop-types"
import styles from "./FinePrint.module.css"

const FinePrint = ({ children }) => (
  <p className={styles.finePrint}>{children}</p>
)

FinePrint.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FinePrint
