import Helmet from "react-helmet"
import React from "react"
import { Placeholder } from "../../shared"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"

const NotFound = () => (
  <Placeholder.Container theme={"modal"}>
    <div>
      <Helmet title="404: Page Not Found" />
      <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
      <h1>Error 404</h1>
      <p>The page you were looking for cannot be found.</p>
    </div>
  </Placeholder.Container>
)

export default NotFound
