import { useApiRequest } from "./useApiRequest"

export interface Group {
  id?: number
  uuid: string
  name: string
  organization_id: string
  position: number
}

export interface SortGroupsBody {
  uuids: string[]
  positions: number[]
}

export const useSortGroups = (subdomain: string) => {
  const getUpload = useApiRequest<Group[], SortGroupsBody>({
    method: "PATCH",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/sort`,
  })
  return getUpload
}
