import { getAccessToken, getRefreshToken } from "../tokens"
import { NAME } from "../constants"

/**
 * Retreives the authentication status from the redux store.
 *
 * @param  {Object} state   The current state of the redux store.
 * @return {Boolean}        True if the user is currently authenticated.
 */
export const isAuthenticated = (state) => state[NAME].isAuthenticated

/**
 * Retreives a global flag indicating the user should try to sign in
 * to manually restore their session
 *
 * @param  {Object} state   The current state of the redux store.
 * @return {Boolean}        True if the user should sign in.
 */
export const needsPassword = (state) => state[NAME].needsPassword

/**
 * Retreives the super admin permission status from the redux store.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Boolean}       True if the current user has super admin privileges.
 */
export const superAdmin = (state) => state[NAME].superAdmin

/**
 * Retreives the admin permission status from the redux store.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {String}       True if the current user has admin privileges.
 */
export const admin = (state) => (subdomain) =>
  superAdmin(state) ||
  (state[NAME].admin && state[NAME].admin.includes(subdomain))

/**
 * Retreives the creator permission status from the redux store.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {String}       True if the current user has creator privileges.
 */
export const creator = (state) => (subdomain) => {
  return (
    superAdmin(state) ||
    (state[NAME].admin && state[NAME].admin.includes(subdomain)) ||
    (state[NAME].creator && state[NAME].creator.includes(subdomain))
  )
}

export const canRead = (state) => (subdomain, calendarId) =>
  admin(state)(subdomain) ||
  Object.keys(state[NAME].userPermissions || {})
    .map((key) => state[NAME].userPermissions[key])
    .filter((perm) => parseInt(perm.calendarId, 0) === parseInt(calendarId, 0))
    .filter((perm) => perm.read || perm.write).length > 0

export const canWrite = (state) => (subdomain, calendarId) =>
  admin(state)(subdomain) ||
  Object.keys(state[NAME].userPermissions || {})
    .map((key) => state[NAME].userPermissions[key])
    .filter((perm) => parseInt(perm.calendarId, 0) === parseInt(calendarId, 0))
    .filter((perm) => perm.manage || perm.write).length > 0

export const canManage = (state) => (subdomain, calendarId) =>
  admin(state)(subdomain) ||
  Object.keys(state[NAME].userPermissions || {})
    .map((key) => state[NAME].userPermissions[key])
    .filter((perm) => parseInt(perm.calendarId, 0) === parseInt(calendarId, 0))
    .filter((perm) => perm.manage === true).length > 0

/**
 * Retreives an object representation of the current authenticated
 * user from the redux store..
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       The oauth creds and permissions for the current user.
 */
export const currentUser = (state) => ({
  id: state[NAME].currentUserId,
  isAuthenticated: isAuthenticated(state),
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
  admin: admin(state),
  creator: creator(state),
  superAdmin: superAdmin(state),
  canManage: canManage(state),
})
