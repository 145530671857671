import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  calendarSelector,
  updateCalendar,
  useCreateGroup,
  useGroups,
} from "../../api"
import {
  CalendarSettingsForm,
  CalendarSettingsFormValues,
} from "./CalendarSettingsForm"
import { wait } from "utils"
import { GroupDataStructure, useAddGroups } from "../../api/atoms"
import toast from "react-hot-toast"
import { Button, Indicators } from "ui"

interface CalendarSettingsWidgetTypes {
  /**
   * The identifier for the org
   */
  subdomain: string
  /**
   * The id of the calendar to present permissions for.
   */
  calendarId: string
  /**
   * A callback that is triggered when the user requests to edit the theme.
   */
  onEditTheme?: () => void
}

export const CalendarSettingsWidget: React.FC<CalendarSettingsWidgetTypes> = ({
  subdomain,
  calendarId,
  onEditTheme: handleEditTheme,
}) => {
  const { groups, loading: loadingGroups } = useGroups(subdomain)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const calendar = useSelector(calendarSelector.find)(calendarId)
  const [createGroup] = useCreateGroup(subdomain)
  const addGroups = useAddGroups()

  const handleCreateGroup = async (name: string) => {
    setLoading(true)
    const group = await createGroup({ body: { name } })
    setLoading(false)
    if (group) {
      toast.success("Group created")
      if (group) {
        addGroups([group as GroupDataStructure])
      }
      return group.uuid
    }
    return
  }

  const handleCalendarSave = async (values: CalendarSettingsFormValues) => {
    setLoading(true)
    await dispatch(updateCalendar(subdomain, { id: calendar.id, ...values }))
    await wait(1)
    setLoading(false)
    toast.success("Calendar settings updated!")
  }

  return (
    <div className="flex flex-col relative">
      {(loading || loadingGroups) && <Indicators.AbsoluteOverlay />}
      <CalendarSettingsForm
        onSave={handleCalendarSave}
        defaultValues={{
          groupUuid: calendar.groupUuid ?? "",
          name: calendar.name,
          showWeekNumbers: calendar.showWeekNumbers,
        }}
        groups={groups.map((g) => ({ uuid: g.uuid, name: g.name }))}
        onCreateGroup={handleCreateGroup}
      />
      {handleEditTheme && (
        <Button
          className="mt-2 mx-2"
          appearance="secondary"
          onClick={(e) => {
            e.preventDefault()
            handleEditTheme?.()
          }}
        >
          Manage Theme...
        </Button>
      )}
      <Link
        to={`/org/${subdomain}/cal/${calendarId}/settings`}
        className="text-sorbus-default text-sm font-semibold underline mt-4 mx-2"
      >
        Additional Settings...
      </Link>
    </div>
  )
}
