import Title from "./Title.react"
import ToolBar from "./ToolBar.react"
import ToolBarAction from "./ToolBarAction.react"
import ToolBarButtonGroup from "./ToolBarButtonGroup.react"
import ToolBarHeading from "./ToolBarHeading.react"
import ToolBarIcon from "./ToolBarIcon.react"
import ToolBarToolTip from "./ToolBarToolTip.react"
import ToolBarSearch from "./ToolBarSearch.react"
import ToolBarSection from "./ToolBarSection.react"
import ToolBarSpacer from "./ToolBarSpacer.react"
import ViewContainer from "./ViewContainer.react"
import ViewContent from "./ViewContent.react"
import Card from "./Card.react"
import CardTitle from "./CardTitle.react"

const content = {
  Title,
  ToolBar,
  ToolBarAction,
  ToolBarButtonGroup,
  ToolBarHeading,
  ToolBarIcon,
  ToolBarToolTip,
  ToolBarSection,
  ToolBarSpacer,
  ToolBarSearch,
  ViewContainer,
  ViewContent,
  Card,
  CardTitle,
}
export default content
