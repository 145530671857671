var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useCallback, useState, useRef } from "react";
import { useDrop } from "react-dnd";
import { DateTime } from "luxon";
import clsx from "clsx";
var CONTAINER_STYLES = "overflow-hidden absolute inset-0";
var RECT_STYLES = "absolute inset-y-0 h-full bg-lochivar-dark bg-opacity-20 border-2 border-lochivar-default";
export var WeekViewDnD = function (_a) {
    var _b, _c;
    var startDate = _a.startDate, accept = _a.accept, onDrop = _a.onDrop, onDropHover = _a.onDropHover;
    var actualStart = DateTime.fromISO(startDate, { zone: "utc" }).startOf("day");
    var elRef = useRef(null);
    var _d = useState(null), dropDate = _d[0], setDropDate = _d[1];
    var handleDrop = useCallback(function (itemType, item) {
        if (dropDate) {
            onDrop === null || onDrop === void 0 ? void 0 : onDrop(itemType, item, dropDate);
        }
    }, [onDrop, dropDate]);
    var handleDropHover = useCallback(function (itemType, item) {
        if (dropDate) {
            onDropHover === null || onDropHover === void 0 ? void 0 : onDropHover(itemType, item, dropDate);
        }
    }, [onDropHover, dropDate]);
    var rectWidth = ((_c = (_b = elRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) !== null && _c !== void 0 ? _c : 100) / 7;
    var _e = useDrop(function () { return ({
        accept: accept,
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            itemType: monitor.getItemType(),
        }); },
        drop: function (item, monitor) {
            handleDrop(monitor.getItemType(), item);
        },
        hover: function (item, monitor) {
            var _a, _b, _c, _d;
            var _e = item, uuid = _e.uuid, type = _e.type, calendarId = _e.calendarId, start = _e.start, end = _e.end;
            if (monitor.canDrop()) {
                var x = ((_b = (_a = monitor.getClientOffset()) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : 0) -
                    ((_d = (_c = elRef.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().x) !== null && _d !== void 0 ? _d : 0);
                var index = Math.floor(x / rectWidth);
                setDropDate(actualStart.plus({ days: index }).toISO());
                handleDropHover === null || handleDropHover === void 0 ? void 0 : handleDropHover(monitor.getItemType(), {
                    uuid: uuid,
                    calendarId: calendarId,
                    type: type,
                    start: start,
                    end: end,
                });
            }
        },
    }); }, [handleDrop, handleDropHover, setDropDate, dropDate, elRef, actualStart]), _f = _e[0], isOver = _f.isOver, itemType = _f.itemType, drop = _e[1];
    useEffect(function () {
        if (!isOver && dropDate) {
            setDropDate(null);
        }
    }, [dropDate, setDropDate, isOver]);
    drop(elRef);
    var diffDays = Math.floor(dropDate
        ? DateTime.fromISO(dropDate, { zone: "utc" }).diff(actualStart, "days")
            .days
        : 0);
    return (_jsx("div", __assign({ className: clsx(CONTAINER_STYLES, itemType ? "pointer-events-auto" : "pointer-events-none"), ref: elRef }, { children: dropDate && isOver ? (_jsx("div", { className: RECT_STYLES, style: {
                left: rectWidth * diffDays,
                width: rectWidth,
            } }, void 0)) : null }), void 0));
};
