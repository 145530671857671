var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
var BAR_HEADER_STYLE = "flex flex-col mx-1 my-auto";
var PRIMARY_HEADER_STYLE = "font-bold text-lochivar-default text-sm";
var SECONDARY_HEADER_STYLE = "font-body text-rose-darkest text-xs -mt-1";
export var BarHeader = function (_a) {
    var primary = _a.primary, secondary = _a.secondary, className = _a.className;
    return (_jsxs("header", __assign({ className: clsx(BAR_HEADER_STYLE, className) }, { children: [_jsx("h1", __assign({ className: PRIMARY_HEADER_STYLE }, { children: primary }), void 0), _jsx("h2", __assign({ className: SECONDARY_HEADER_STYLE }, { children: secondary }), void 0)] }), void 0));
};
