import React from "react"
import PropTypes from "prop-types"
import styles from "./GridRow.module.css"
import gridStyles from "../../utils/gridStyles"

const GridRow = ({ children }) => (
  <div className={`${gridStyles("row")} ${styles.rowContainer}`}>
    {children}
  </div>
)

GridRow.propTypes = {
  children: PropTypes.node,
}

export default GridRow
