import { camelizeKeys } from "humps"
import toast from "react-hot-toast"
import { SubmissionError } from "redux-form"
import { requestCalendarValidation } from "../api"

export const asyncValidate = (values, dispatch) => {
  const { organization, ...valsToPost } = values
  return dispatch(requestCalendarValidation(organization, valsToPost)).then(
    (action) => {
      if (action.payload && action.payload.errors && !action.payload.valid) {
        const error = camelizeKeys(action.payload.errors)
        throw error
      }
    }
  )
}

export const validate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  if (!formProps.name) {
    errors.name = "Please enter a name for this calendar"
  }
  return errors
}

export const submitValidate = (result) => {
  if (result.error) {
    const errors = camelizeKeys(result.payload.errors || {}).map
    toast.error("Calendar could not be saved!")
    throw new SubmissionError(
      Object.assign({}, errors, { _error: "Calendar could not be saved!" })
    )
  }
  return result
}
