import { camelizeKeys } from "humps"
import { SubmissionError } from "redux-form"
import { requestOrganizationValidation } from "../api"

export const asyncValidate = (values, dispatch) => {
  return dispatch(requestOrganizationValidation(values)).then((action) => {
    if (action.payload && action.payload.errors && !action.payload.valid) {
      const error = camelizeKeys(action.payload.errors)
      throw error
    }
  })
}

export const validate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  if (!formProps.subdomain) {
    errors.subdomain = "Please enter a subdomain for this organization"
  }
  if (!formProps.name) {
    errors.name = "Please enter a name for this organization"
  }
  return errors
}

export const submitValidate = (result) => {
  if (result.error) {
    const errors = camelizeKeys(result.payload.errors)
    throw new SubmissionError(
      Object.assign({}, errors, { _error: "Company could not be saved!" })
    )
  }
  return result
}

export const deleteValidate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  if (formProps.name !== formProps.confirmationValue) {
    errors.name = `Please type '${formProps.confirmationValue}' to confirm deletion.`
  }
  return errors
}
