import React, { useState } from "react"
import {
  Modal,
  ModalProps,
  CollaboratorForm,
  CollaboratorFormValues,
  AsyncSubmitHandler,
} from "ui"
import {
  useUpdatePermission,
  useCreateInvitation,
  useCreatePermission,
  useInvitations,
} from "../api"
import toast from "react-hot-toast"

import { wait } from "utils"

export interface CollaboratorModalProps {
  permissionId?: number
  invitationId?: number
  subdomain: string
  calendarId: number
  initialValues?: CollaboratorFormValues
}

export const CollaboratorModal: React.FC<
  CollaboratorModalProps & Omit<ModalProps, "title">
> = ({
  onClose: handleDismiss,
  permissionId,
  subdomain,
  onFocus: handleFocus,
  zIndex,
  initialValues,
  invitationId,
  calendarId,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [updatePermission] = useUpdatePermission(subdomain)
  const [createPermission] = useCreatePermission(subdomain)
  const [createInvitation] = useCreateInvitation(subdomain)
  const { invitations, refetch: reloadInvitations } = useInvitations(subdomain)

  const onSubmit: AsyncSubmitHandler<CollaboratorFormValues> = async ({
    permission,
    ...data
  }: CollaboratorFormValues) => {
    setLoading(true)
    await wait(1)
    const manage = permission === "manage"
    const write = permission === "write" || manage
    const read = permission === "read" || write

    let newInvitation: any | undefined
    if (!invitationId) {
      const existingInvitation = invitations.find(
        (i: any) => i.email.toLowerCase() === data.email.toLowerCase()
      )
      newInvitation =
        existingInvitation ??
        (await createInvitation({
          body: {
            ...data,
            calendarId,
            accessLevel: "user",
          },
        }))
    }
    if (permissionId && invitationId) {
      await updatePermission({
        body: {
          manage,
          read,
          write,
        },
        params: {
          permissionId: permissionId.toString(),
          invitationId: invitationId.toString(),
        },
      })
    } else if (invitationId ?? newInvitation?.id) {
      await createPermission({
        body: {
          manage,
          read,
          write,
          calendarId,
        },
        params: {
          invitationId: (invitationId ?? newInvitation?.id).toString(),
        },
      })
    }
    try {
      toast.success(
        `Successfully ${permissionId ? "updated" : "created"} collaborator "${
          data.name
        }".`
      )
      reloadInvitations()
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
    return undefined
  }

  return (
    <Modal
      {...props}
      title={permissionId ? "Edit Collaborator" : "Add Collaborator"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
    >
      <CollaboratorForm
        loading={loading}
        onSubmit={onSubmit}
        defaultValues={initialValues}
        invitationId={invitationId}
      />
    </Modal>
  )
}
