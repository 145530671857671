import { roundedRect, label } from "../../canvas";
import Color from "color";
export var renderEvent = function (ctx, _a) {
    var y = _a.y, x = _a.x, w = _a.w, h = _a.h, fillStyle = _a.fillStyle, strokeStyle = _a.strokeStyle, selectedColor = _a.selectedColor, hover = _a.hover, selected = _a.selected, dragging = _a.dragging, scale = _a.scale, name = _a.name, labelSize = _a.labelSize, radius = _a.radius, _b = _a.showHandles, showHandles = _b === void 0 ? false : _b;
    var handleWidth = Math.min(w / 4, 8);
    var handleColor = Color(fillStyle).darken(0.1).toString();
    var mouseOver = hover.x > x && hover.x < x + w && hover.y > y && hover.y < y + h;
    radius = radius / 6;
    if (mouseOver) {
        roundedRect(ctx, {
            x: x,
            y: y,
            radius: radius,
            lineWidth: 8,
            strokeStyle: "rgba(0,0,0,0.1)",
            h: h,
            w: w,
            scale: scale,
        });
    }
    roundedRect(ctx, {
        x: x,
        y: y,
        fillStyle: fillStyle,
        radius: radius,
        lineWidth: 1,
        strokeStyle: strokeStyle,
        h: h,
        w: w,
        scale: scale,
    });
    if (showHandles) {
        roundedRect(ctx, {
            x: x + 1,
            y: y + 1,
            fillStyle: handleColor,
            radius: radius,
            h: h - 2,
            w: handleWidth,
            scale: scale,
        });
        roundedRect(ctx, {
            x: x + w - handleWidth - 1,
            y: y + 1,
            fillStyle: handleColor,
            radius: radius,
            h: h - 2,
            w: handleWidth,
            scale: scale,
        });
    }
    // Render selection indicator
    if (dragging) {
        roundedRect(ctx, {
            x: x - 1,
            y: y - 1,
            radius: radius,
            lineWidth: 3,
            strokeStyle: selectedColor,
            h: h + 2,
            w: w + 2,
            scale: scale,
        });
    }
    else if (selected) {
        roundedRect(ctx, {
            x: x - 1,
            y: y - 1,
            radius: radius,
            lineWidth: 3,
            strokeStyle: selectedColor,
            lineDash: [7, 7],
            h: h + 2,
            w: w + 2,
            scale: scale,
        });
    }
    label(ctx, {
        x: x + w + 4,
        y: y + h / 2,
        text: name,
        labelSize: labelSize,
        baseline: "middle",
        scale: scale,
    });
    return { mouseOver: mouseOver };
};
