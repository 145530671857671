var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { concatStyles } from "utils";
import { ToolTip } from "../Navigation";
import { useControlGroup } from "./useControlGroup";
import { useIsSelectedControl } from "./useIsSelectedControl";
var CONTAINER_STYLES = "relative first:border first:border-r-0 last:border border-t border-l border-b border-gray-400 first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md flex items-center justify-center w-12 h-8 p-5 transition-colors duration-200 ease-linear";
var CONTAINER_SELECTED_STYLES = "bg-gray-400 hover:bg-gray-400 shadow-inner";
var CONTAINER_ENABLED_STYLES = "bg-white hover:bg-gray-200";
var INPUT_STYLES = "absolute bottom-0 h-full left-0 opacity-0 right-0 top-0 w-full";
var INPUT_DISABLED_STYLES = "cursor-not-allowed";
var DISABLED_STYLES = INPUT_DISABLED_STYLES + " opacity-50";
var ENABLED_STYLES = "cursor-pointer";
export var Control = function (_a) {
    var id = _a.id, value = _a.value, className = _a.className, disabled = _a.disabled, _b = _a.type, type = _b === void 0 ? "radio" : _b, children = _a.children, tooltip = _a.tooltip;
    var _c = useState(false), hover = _c[0], setHover = _c[1];
    var _d = useControlGroup(), register = _d.register, name = _d.name;
    var isSelected = useIsSelectedControl(value);
    return (_jsxs("div", __assign({ className: concatStyles(CONTAINER_STYLES, className, disabled && DISABLED_STYLES, !disabled && ENABLED_STYLES && CONTAINER_ENABLED_STYLES, isSelected && CONTAINER_SELECTED_STYLES), onMouseOver: function () { return setHover(true); }, onMouseOut: function () { return setHover(false); } }, { children: [tooltip ? _jsx(ToolTip, { active: hover, label: tooltip }, void 0) : null, _jsx("input", __assign({}, register === null || register === void 0 ? void 0 : register(name), { id: id !== null && id !== void 0 ? id : name, value: value, type: type, disabled: disabled, className: concatStyles(INPUT_STYLES, disabled && INPUT_DISABLED_STYLES, !disabled && ENABLED_STYLES) }), void 0), children] }), void 0));
};
