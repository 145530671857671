import React from "react"
import { SideBar, BarItem, BarSpacer } from "ui"
import {
  faSignOut,
  faQuestionCircle,
  faTachometerAltFast,
} from "@fortawesome/pro-light-svg-icons"
import styles from "./AppNavigator.module.css"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { analyticsSelector } from "../../api"
import { useSelector } from "react-redux"

export type AdminBarOption = "back" | "dashboard" | "help" | "logout"

export interface AdminBarProps {
  onSelect?: (selection: AdminBarOption) => void
}

export const AdminBar: React.FC<AdminBarProps> = ({ onSelect }) => {
  const handleSelection = (selection: AdminBarOption) => () =>
    onSelect ? onSelect(selection) : null
  const canViewAnalytics = useSelector(analyticsSelector.canViewAnalytics)

  return (
    <SideBar
      alignment="left"
      mode="compact"
      appearance="navigator"
      renderHeading={() => <div className={styles.logo} />}
    >
      <BarItem
        icon={faArrowLeft}
        onClick={handleSelection("back")}
        toolTip="Return to App"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
      <BarSpacer />
      {canViewAnalytics ? (
        <BarItem
          icon={faTachometerAltFast}
          onClick={handleSelection("dashboard")}
          toolTip="Site Analytics"
          toolTipDirection="right"
          mode="negative"
          indicated={true}
          indicatorMode="secondary"
        />
      ) : null}
      <BarItem
        icon={faQuestionCircle}
        onClick={handleSelection("help")}
        toolTip="Help"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
      <BarItem
        icon={faSignOut}
        onClick={handleSelection("logout")}
        toolTip="Logout"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
    </SideBar>
  )
}
