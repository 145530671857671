import { useDeleteEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { ConnectionResponse } from "./types"

export interface DeleteConnectionParams extends RequestParams {
  connectionId: string
}

export const useDeleteConnection = () => {
  const deleteEntity = useDeleteEntity()
  const deleteConnection = useApiRequest<
    ConnectionResponse,
    { empty?: null },
    DeleteConnectionParams
  >({
    method: "DELETE",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/connections/:connectionId`,
    onResponse: (_response, config) => {
      if (config?.params?.connectionId)
        deleteEntity("connections", parseInt(config.params.connectionId))
    },
  })
  return deleteConnection
}
