/**
 * Swallows a mouse event to prevent it from firing before executing the supplied block.
 * @param block A block to execute after the mosue event is swallowed.
 * @returns
 */
export var swallow = function (block) {
    return function (event) {
        event.stopPropagation();
        event.preventDefault();
        block();
    };
};
