import React from "react"
import clsx from "clsx"
import { StylableProps } from "./Styleable"

export interface SplitProps {
  responsive?: boolean
  wrap?: boolean
}
export const Split: React.FC<StylableProps & SplitProps> = ({
  children,
  className,
  style,
  responsive = true,
  wrap,
}) => (
  <div
    className={clsx(
      "flex",
      responsive ? "flex-col md:flex-row" : "flex-row",
      wrap ? "flex-wrap" : "",
      className
    )}
    style={style}
  >
    {children}
  </div>
)
