import React, { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import moment from "moment"
import CalendarForm from "../containers/CalendarForm.react"
import { View, deleteFormWithName } from "../../shared"
import { DELETE_FORM_NAME } from "../constants"
import {
  faCalendarAlt,
  faCalendarPlus,
} from "@fortawesome/pro-regular-svg-icons"
import { Button } from "ui"
import { useGroups } from "../../api"

const {
  ViewContainer,
  ToolBar,
  ToolBarIcon,
  ToolBarHeading,
  ToolBarSection,
  ViewContent,
} = View

const CalendarDeleteForm = deleteFormWithName(DELETE_FORM_NAME)

interface CalendarDetailViewProps {
  currentUser: any
  currentGroupName: string
  initialValues: any
  loadCalendar: (subdomain: string, calendarId?: string) => any
  loadOrganization: (subdomain: string) => any
  requestCurrentUser: () => any
  loadSnapshots: (subdomain: string, calendarId: string) => any
  loadHolidaySchedules: (subdomain: string, calendarId: string) => any
  loadInvitations: (subdomain: string, calendarId?: string) => any
  removeCalendar: (subdomain: string, calendarId: string) => any
  findCalendar: (subdomain: string) => any
  findOrganization: (subdomain: string) => any
  findCalendarsForOrg: (subdomain: string) => any
  findInvitationsForOrg: (subdomain: string) => any
  findInvitation: (subdomain: string) => any
  findSnapshotsForCalendar: (subdomain: string) => any
  templateCalendarId: string
  findPermissionsForCalendar: () => any
  holidaySchedules: object[]
  createInvitationPermission: any
  createInvitation: any
  updateInvitationPermission: any
  permissions: object[]
}

/**
 * Renders a toolbar and form to edit or create a calendar for
 * an organization.
 */
const CalendarDetailView: React.FC<CalendarDetailViewProps> = ({
  requestCurrentUser,
  templateCalendarId,
  loadHolidaySchedules,
  loadSnapshots,
  loadInvitations,
  currentUser,
  findCalendar,
  findOrganization,
  removeCalendar,
  findCalendarsForOrg,
  findSnapshotsForCalendar,
  updateInvitationPermission,
  initialValues,
  loadOrganization,
  loadCalendar,
  createInvitation,
  createInvitationPermission,
  currentGroupName,
  ...props
}) => {
  const { subdomain, calendarId } =
    useParams<{ subdomain: string; calendarId: string }>()
  const { loading: loadingGroups, groups } = useGroups(subdomain)
  const history = useHistory()
  const [currentCalendar, setCurrentCalendar] = useState<string | null>(null)

  const populateSnapshots = useCallback(() => {
    if (templateCalendarId) {
      loadSnapshots(subdomain, templateCalendarId).then((action: any) => {
        if (action.error) {
          history.replace("/404")
        }
      })
    }
  }, [loadSnapshots, subdomain, templateCalendarId, history])

  const populateCalendar = useCallback(
    (subdomain: string, calendarId: string) => {
      loadCalendar(subdomain, calendarId).then((action: any) => {
        if (action.error) {
          history.replace("/404")
        }
      })
    },
    [history, loadCalendar]
  )

  const populateOrganization = useCallback(
    (subdomain: string) => {
      loadOrganization(subdomain).then((action: any) => {
        if (action.error) {
          history.replace("/404")
        } else {
          loadCalendar(subdomain)
        }
      })
    },
    [loadCalendar, loadOrganization, history]
  )

  const generateGroupSuggestions = () => {
    return loadingGroups
      ? []
      : groups.sort().map((g) => ({ name: g.name, value: g.uuid }))
  }

  useEffect(() => {
    if (templateCalendarId) {
      populateSnapshots()
    }
    if (calendarId !== currentCalendar) {
      requestCurrentUser()
      loadInvitations(subdomain)
      loadHolidaySchedules(subdomain, calendarId || "new")
      if (calendarId) {
        populateCalendar(subdomain, calendarId)
      } else {
        populateOrganization(subdomain)
      }
      setCurrentCalendar(calendarId)
    }
  }, [
    calendarId,
    currentCalendar,
    setCurrentCalendar,
    templateCalendarId,
    loadHolidaySchedules,
    loadInvitations,
    populateCalendar,
    populateOrganization,
    populateSnapshots,
    requestCurrentUser,
    subdomain,
  ])

  const applicableCalendar = findCalendar(calendarId) || initialValues
  const calendar = {
    ...applicableCalendar,
    color: applicableCalendar.color || initialValues.color,
    holidaySchedules: applicableCalendar.appliedHolidaySchedules,
    generateSnapshot: true,
    startDate: moment.utc(applicableCalendar.startDate).format("YYYY-MM-DD"),
    endDate: moment.utc(applicableCalendar.endDate).format("YYYY-MM-DD"),
  }
  const organization = findOrganization(subdomain)
  const canUseImport = "renegade-83".split(" ").includes(subdomain)
  const titleText = calendar.name ? `${calendar.name}` : "Create New Calendar"
  const canDeleteCalendar =
    parseInt(calendar.id, 0) === parseInt(calendarId, 0) &&
    currentUser.admin(subdomain)
  const handleImportClick = () => {
    history.push(`/org/${subdomain}/cal/import`)
  }

  return (
    <ViewContainer>
      <ToolBar>
        <ToolBarSection required={true} ignoresGrid={true}>
          <ToolBarIcon icon={calendarId ? faCalendarAlt : faCalendarPlus} />
          <ToolBarHeading
            primaryText={titleText}
            secondaryText={organization && organization.name}
          />
        </ToolBarSection>
      </ToolBar>
      <ViewContent>
        {canUseImport ? (
          <div className="rounded bg-rose-lighter p-4 w-1/3">
            <h3 className="font-bold text-lochivar-dark">
              Do you need to import existing data from outside of ProCal?
            </h3>
            <p className="text-sm my-2">
              A custom importer to process schedules in excel format is
              available. Please note this function is in private beta for select
              organizations.
            </p>
            <Button onClick={handleImportClick}>
              Import Calendar From Excel
            </Button>
          </div>
        ) : null}
        <CalendarForm
          {...props}
          suggestedGroupNames={() => generateGroupSuggestions()}
          initialValues={calendar}
          subdomain={subdomain}
          isNew={!calendar.id}
          calendars={findCalendarsForOrg(subdomain)}
          snapshots={findSnapshotsForCalendar(templateCalendarId)}
          templateCalendarId={templateCalendarId}
          usesGranularPermissions={
            organization && organization.usesGranularPermissions
          }
          holidaySchedules={props.holidaySchedules}
        />
        {canDeleteCalendar && (
          <CalendarDeleteForm
            entityName="Calendar"
            confirmationName={calendar.name}
            deleteAction={() => removeCalendar(subdomain, calendar.id)}
            redirectAction={() => history.replace(`/org/${subdomain}/cal/`)}
          />
        )}
      </ViewContent>
    </ViewContainer>
  )
}

export default CalendarDetailView
