import React, { useState } from "react"
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import { Drawer, DRAWER_ITEM_TYPE, DrawerContent } from "ui"

import { useReports } from "../../api"
import { useHistory, useRouteMatch } from "react-router"
import { useHasCreatorPrivilege } from "../../api"

export interface ReportDrawerProps {
  onDismiss: () => void
}

export const ReportDrawer: React.FC<ReportDrawerProps> = ({
  onDismiss: handleDismiss,
}) => {
  const [search, setSearch] = useState("")

  const history = useHistory()
  const match = useRouteMatch<{ subdomain: string }>("/org/:subdomain")
  const subdomain = match?.params.subdomain ?? ""

  const { loading: loadingReports, reports } = useReports(subdomain)

  const handleNewClick = () => {
    history.push(`/org/${subdomain}/reports/new`)
    handleDismiss()
  }

  const isCreator = useHasCreatorPrivilege(subdomain)

  const handleSearch = (searchText?: string) => {
    setSearch(searchText ?? "")
  }

  const finalItems = reports
    ?.map(
      (i: any, index: number): DrawerContent => ({
        id: i.id,
        label: i.name,
        icon: faCalendarAlt,
        type: DRAWER_ITEM_TYPE,
        position: index,
        onSelect: () => {
          history.push(`/org/${subdomain}/reports/${i.id}`)
          handleDismiss()
        },
        onSecondaryClick: () => {
          history.push(`/org/${subdomain}/reports/${i.id}/settings`)
          handleDismiss()
        },
      })
    )
    .filter((i: DrawerContent) =>
      search ? i.label.toLowerCase().indexOf(search.toLowerCase()) > -1 : true
    )
    .filter((i: DrawerContent) => (search ? i.type === DRAWER_ITEM_TYPE : true))
    .map((i: DrawerContent) => (search ? { ...i, groupId: undefined } : i))

  const loading = loadingReports

  return (
    <div className="absolute inset-0">
      <Drawer
        title="Reports"
        newItemLabel="New Report"
        onSearch={handleSearch}
        items={finalItems}
        onDismiss={handleDismiss}
        onNewClick={isCreator ? handleNewClick : undefined}
        offset={48}
        loading={loading}
      />
    </div>
  )
}
