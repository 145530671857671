import React from "react"
import PropTypes from "prop-types"
import styles from "./Split.module.css"

const Split = ({ children }) => <div className={styles.split}>{children}</div>

Split.propTypes = {
  children: PropTypes.node,
}

export default Split
