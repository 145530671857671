var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useEffect } from "react";
import { ContextMenuEvent, } from "./ContextMenuEvent";
import { ContextMenuDay } from "./ContextMenuDay";
import { ContextMenuContainer } from "./ContextMenuContainer";
import { ContextMenuGroup } from "./ContextMenuGroup";
import { ContextMenuCalendar } from "./ContextMenuCalendar";
var EDGE_PADDING = 20;
var LEFT_OFFSET = 60;
var CONTAINER_STYLES = "flex flex-col shadow absolute w-64 bg-white rounded py-2";
/**
 * A context menu with overlay to dismiss itself when a user clicks outside of
 * the menu. Similar to a modal view but purely for menu navigation.
 */
export var ContextMenu = function (_a) {
    var x = _a.x, y = _a.y, onDismiss = _a.onDismiss, entityType = _a.entityType, clipboardMode = _a.clipboardMode, clipboardCount = _a.clipboardCount, hasHolidays = _a.hasHolidays, hasTravel = _a.hasTravel, onSelect = _a.onSelect, recurringEvent = _a.recurringEvent, weekNumberMode = _a.weekNumberMode, styles = _a.styles, styleUuid = _a.styleUuid, handleSelectStyle = _a.onSelectStyle, handleSelectManageStyles = _a.onSelectManageStyles, handleSelectNewStyle = _a.onSelectNewStyle;
    var _b = useState({ top: 0, left: 0 }), _c = _b[0], top = _c.top, left = _c.left, setMargins = _b[1];
    var dismissLayer = useRef(null);
    var menuBody = useRef(null);
    var body = menuBody === null || menuBody === void 0 ? void 0 : menuBody.current;
    useEffect(function () {
        if (body) {
            var top_1 = Math.min(0, window.innerHeight - y - body.offsetHeight - EDGE_PADDING);
            var left_1 = Math.min(0, window.innerWidth - x - LEFT_OFFSET - body.offsetWidth - EDGE_PADDING);
            setMargins({ top: top_1, left: left_1 });
        }
    }, [body, x, y, setMargins]);
    var handleDismiss = function (e) {
        e.preventDefault();
        onDismiss();
    };
    useEffect(function () {
        var layer = dismissLayer.current;
        if (layer) {
            layer === null || layer === void 0 ? void 0 : layer.addEventListener("contextmenu", handleDismiss);
        }
        return function () { return layer === null || layer === void 0 ? void 0 : layer.removeEventListener("contextmenu", handleDismiss); };
    });
    var handleMenuClick = function (e) {
        e.preventDefault();
    };
    var handleSelection = function (selection, params) {
        onDismiss();
        if (onSelect) {
            onSelect(selection, params);
        }
    };
    var renderContextForEntity = function (type) {
        switch (type) {
            case "calendar":
                return _jsx(ContextMenuCalendar, { onSelect: handleSelection }, void 0);
            case "group":
                return _jsx(ContextMenuGroup, { onSelect: handleSelection }, void 0);
            case "event":
                return (_jsx(ContextMenuEvent, { onSelect: handleSelection, recurringEvent: recurringEvent, styleUuid: styleUuid, styles: styles, onSelectStyle: handleSelectStyle, onSelectManageStyles: handleSelectManageStyles, onSelectNewStyle: handleSelectNewStyle }, void 0));
            case "day":
                return (_jsx(ContextMenuDay, { onSelect: handleSelection, clipboardMode: clipboardMode, clipboardCount: clipboardCount, hasHolidays: hasHolidays, hasTravel: hasTravel, weekNumberMode: weekNumberMode }, void 0));
            default:
                return;
        }
    };
    return (_jsx("div", __assign({ style: {
            position: "fixed",
            zIndex: 100,
            height: "100vh",
            width: "100vw",
            top: 0,
            left: 0,
        }, onClick: handleDismiss, ref: dismissLayer }, { children: _jsx(ContextMenuContainer, __assign({ onClick: handleMenuClick, className: CONTAINER_STYLES, style: {
                maxHeight: "70vh",
                marginTop: top,
                marginLeft: left,
                left: x - 50,
                top: y + 10,
            }, ref: menuBody }, { children: renderContextForEntity(entityType) }), void 0) }), void 0));
};
