var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { TextField } from "./TextField";
import { DropDownList } from "./DropDownList";
export var AutoCompleteField = React.forwardRef(function (_a, forwardedRef) {
    var items = _a.items, onSelectItem = _a.onSelectItem, onValueChanged = _a.onValueChanged, initialValue = _a.initialValue, autoClear = _a.autoClear, multiSelect = _a.multiSelect, maxResults = _a.maxResults, blankResultItem = _a.blankResultItem, additionalItem = _a.additionalItem, children = _a.children, className = _a.className, props = __rest(_a, ["items", "onSelectItem", "onValueChanged", "initialValue", "autoClear", "multiSelect", "maxResults", "blankResultItem", "additionalItem", "children", "className"]);
    var _b = useState(initialValue !== null && initialValue !== void 0 ? initialValue : ""), currentValue = _b[0], setCurrentValue = _b[1];
    var _c = useState(false), focused = _c[0], setFocused = _c[1];
    useEffect(function () {
        if (initialValue && !autoClear) {
            setCurrentValue(initialValue);
        }
    }, [initialValue]);
    var handleFocus = function (focused, delay) { return function () {
        setTimeout(function () {
            var remainInFocus = focused || multiSelect === true;
            setFocused(remainInFocus);
            if (!remainInFocus) {
                var element = document === null || document === void 0 ? void 0 : document.activeElement;
                element === null || element === void 0 ? void 0 : element.blur();
                if (autoClear) {
                    setCurrentValue("");
                }
            }
        }, delay !== null && delay !== void 0 ? delay : 0);
    }; };
    var handleChange = function (e) {
        var _a;
        onValueChanged === null || onValueChanged === void 0 ? void 0 : onValueChanged((_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : "");
        setCurrentValue(e.currentTarget.value);
    };
    var handleSelection = function (item) {
        onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item);
        handleFocus(false, 200)();
        if (autoClear) {
            setTimeout(function () { return setCurrentValue(""); }, 200);
        }
    };
    var handleDismiss = multiSelect
        ? function () { return setTimeout(function () { return setFocused(false); }, 200); }
        : undefined;
    return (_jsx(TextField, __assign({}, props, { onFocus: handleFocus(true), onBlur: handleFocus(false, 200), onChange: handleChange, value: currentValue, className: className, ref: forwardedRef }, { children: focused ? (_jsx(DropDownList, { items: items, onSelectItem: handleSelection, blankResultItem: blankResultItem, additionalItem: additionalItem, maxResults: maxResults, onDismiss: handleDismiss }, void 0)) : null }), void 0));
});
