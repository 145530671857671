import React, { Component } from "react"
import PropTypes from "prop-types"
import { getEmptyImage } from "react-dnd-html5-backend"
import { DragSource, DropTarget } from "react-dnd"
import styles from "./CategoryView.module.css"
import { ItemTypes } from "../constants"
import SelectableItem from "./SelectableItem.react"
import InlineSetting from "./InlineSetting.react"
import FolderIcon from "./FolderIcon.react"
import {
  faEye,
  faEyeSlash,
  faLock,
  faUnlockAlt,
  faPencil,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons"

class CategoryView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovering: false,
    }
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    })
  }

  handleDoubleClick() {
    const { handleEditCategory, category, selectMode } = this.props
    !selectMode && handleEditCategory(category.uuid)
  }

  handleClick(e) {
    e.stopPropagation()
    const {
      openCategory,
      handleSelectedCategory,
      open,
      category,
      selectMode,
      readOnly,
    } = this.props
    handleSelectedCategory(category.uuid)
    !selectMode &&
      openCategory(
        category.uuid,
        category.snapshotId || category.calendarId,
        !open,
        readOnly
      )
  }

  handleLockSetting() {
    const { lockCategory, unlockCategory, locked, category, readOnly } =
      this.props
    if (readOnly) {
      return // No need to toggle this if the calendar is read only.
    }
    locked
      ? unlockCategory(
          category.uuid,
          category.snapshotId || category.calendarId,
          readOnly
        )
      : lockCategory(
          category.uuid,
          category.snapshotId || category.calendarId,
          readOnly
        )
  }

  handleMouseOver() {
    this.setState({ hovering: true })
  }

  handleMouseLeave() {
    this.setState({ hovering: false })
  }

  handleActiveSetting() {
    const {
      showCategory,
      hideCategory,
      active,
      category,
      selectMode,
      readOnly,
    } = this.props
    !selectMode &&
      (active
        ? hideCategory(
            category.uuid,
            category.snapshotId || category.calendarId,
            readOnly
          )
        : showCategory(
            category.uuid,
            category.snapshotId || category.calendarId,
            readOnly
          ))
  }

  render() {
    const {
      connectDropTarget,
      connectDragSource,
      name,
      active,
      parentInactive,
      locked,
      parentLocked,
      category,
      readOnly,
      ...props
    } = this.props
    const { hovering } = this.state
    return connectDropTarget(
      <div
        onMouseOver={() => this.handleMouseOver()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <SelectableItem
          handleClick={(e) => this.handleClick(e)}
          handleDoubleClick={() => this.handleDoubleClick()}
          selected={props.isOver || props.isDragging || props.selected}
        >
          <InlineSetting
            active={parentInactive || !active}
            visible={hovering}
            disabled={parentInactive}
            handleClick={() => this.handleActiveSetting()}
            activeIcon={faEyeSlash}
            defaultIcon={faEye}
          />
          <InlineSetting
            active={parentLocked || locked}
            visible={hovering}
            disabled={parentLocked}
            handleClick={(e) => this.handleLockSetting(e)}
            activeIcon={faLock}
            defaultIcon={faUnlockAlt}
          />
          {connectDragSource(
            <div
              className={`${styles.categoryView} ${
                props.isOver && styles.selected
              }`}
              style={{ marginLeft: `${props.indentation * 1.2}rem` }}
            >
              <FolderIcon open={props.open} />
              <span className={styles.name}>{name}</span>
            </div>
          )}
          {!readOnly && (
            <InlineSetting
              active={false}
              visible={hovering}
              handleClick={() => props.handleEditCategory(category.uuid)}
              activeIcon={faPencil}
              defaultIcon={faPencil}
              useAdditionalSpacing={false}
            />
          )}
          {!readOnly && (
            <InlineSetting
              active={false}
              visible={hovering}
              handleClick={() => props.handleDeleteCategory(category.uuid)}
              activeIcon={faTrash}
              defaultIcon={faTrash}
            />
          )}
        </SelectableItem>
      </div>
    )
  }
}

CategoryView.propTypes = {
  /**
   * The category entity being represented by this view.
   */
  category: PropTypes.object.isRequired,

  /**
   * The name of the category to display.
   */
  name: PropTypes.string.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this category should be enabled.
   */
  active: PropTypes.bool.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be locked from date
   * shifts.
   */
  parentInactive: PropTypes.bool,

  /**
   * If true - it should indicate that this category
   * should be rendered in an expanded mode by the UI.
   */
  open: PropTypes.bool.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this category should be locked from date
   * shifts.
   */
  locked: PropTypes.bool.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be locked from date
   * shifts.
   */
  parentLocked: PropTypes.bool,

  /**
   * The level of indentation to be applied to this view.
   * Indententation is used to simulate nesting in the
   * directory structure.
   */
  indentation: PropTypes.number.isRequired,

  /**
   * A boolean flag indicating whether or not an item that
   * may be dropped onto this category is currently hovering
   * above it.
   */
  isOver: PropTypes.bool.isRequired,

  /**
   * A boolean flag indicating whether or not an item that
   * may be dropped onto this category is currently being
   * dragged.
   */
  isDragging: PropTypes.bool.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDropTarget: PropTypes.func.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDragSource: PropTypes.func.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDragPreview: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an category to request
   * the editing modal.
   */
  handleEditCategory: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an category to request
   * the editing modal.
   */
  handleDeleteCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar category.
   */
  showCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will open or close a calendar category.
   */
  openCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar Category.
   */
  lockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar Category.
   */
  unlockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar item's parent category.
   */
  updateItemCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar category's parent category.
   */
  updateCategoryCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar category's selected
   * property.
   */
  handleSelectedCategory: PropTypes.func.isRequired,

  /**
   * If set to true the only external callbacks that will
   * be called are related to the selection of this item.
   */
  selectMode: PropTypes.bool.isRequired,

  /**
   * Indicates whether or not this item should appear
   * as if it were selected by the user.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * Ensures this view will not allow any edit interactions if set to true.
   */
  readOnly: PropTypes.bool.isRequired,
}

/**
 * A react DND specification for the DragSource component
 * representing the CategoryView
 */
const itemViewSource = {
  beginDrag(props) {
    const { category } = props
    return { itemType: ItemTypes.CATEGORY_VIEW, ...category }
  },
  isDragging(props, monitor) {
    return props.name === monitor.getItem().name
  },
}

/**
 * A react DND specification for the DropTarget component representing
 * the EventTargetView
 * @type {Object}
 */
const itemViewTarget = {
  canDrop(props, monitor) {
    const { readOnly, category } = props
    const item = monitor.getItem()
    if (item.itemType !== ItemTypes.CATEGORY_VIEW) {
      return true
    }
    return (
      !readOnly &&
      item.uuid !== category.uuid &&
      item.uuid !== category.categoryUuid
    )
  },
  drop(props, monitor) {
    const { category, updateItemCategory, updateCategoryCategory, readOnly } =
      props
    const item = monitor.getItem()
    const updateAction =
      item.itemType === ItemTypes.CATEGORY_VIEW
        ? updateCategoryCategory
        : updateItemCategory
    !readOnly && updateAction(item.uuid, category.uuid, item.calendarId)
  },
}

function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

export default DropTarget(
  [ItemTypes.ITEM_VIEW, ItemTypes.CATEGORY_VIEW],
  itemViewTarget,
  dropCollect
)(
  DragSource(ItemTypes.CATEGORY_VIEW, itemViewSource, dragCollect)(CategoryView)
)
