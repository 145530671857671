var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useBreakpoint } from "../../hooks";
export var SizeContext = React.createContext({
    viewports: [],
    size: { height: 0, width: 0 },
});
export var WindowSize = function (_a) {
    var children = _a.children, _b = _a.method, method = _b === void 0 ? "throttle" : _b, _c = _a.wait, wait = _c === void 0 ? 500 : _c;
    var sizeContext = useBreakpoint(wait, method);
    return (_jsx(SizeContext.Provider, __assign({ value: sizeContext }, { children: children }), void 0));
};
