export const NAME = "calendar"
export const DATE_FORMAT = "YYYY-MM-DD"

export const ContextTypes = {
  EVENT: "event",
  DAY: "day",
}

export const ItemTypes = {
  EVENT_VIEW: `${NAME}/EVENT_VIEW`,
  EVENT_EXPAND_VIEW: `${NAME}/EVENT_EXPAND_VIEW`,
}

export const VIEW_MODE_NAMES = {
  ONE_WEEK: "1W",
  ONE_MONTH: "1M",
  TWO_MONTH: "2M",
  FOUR_MONTH: "4M",
  SIX_MONTH: "6M",
  ONE_YEAR: "1Y",
  PROJECT: "GN",
}

export const VIEW_MODES = [
  { name: VIEW_MODE_NAMES.ONE_WEEK, label: "1-Week", quantity: 1, units: "w" },
  {
    name: VIEW_MODE_NAMES.ONE_MONTH,
    label: "1-Month",
    quantity: 1,
    units: "M",
  },
  {
    name: VIEW_MODE_NAMES.FOUR_MONTH,
    label: "4-Months",
    quantity: 4,
    units: "M",
  },
  {
    name: VIEW_MODE_NAMES.SIX_MONTH,
    label: "6-Months",
    quantity: 6,
    units: "M",
  },
  { name: VIEW_MODE_NAMES.ONE_YEAR, label: "1-Year", quantity: 1, units: "y" },
  {
    name: VIEW_MODE_NAMES.PROJECT,
    label: "Timeline",
    quantity: 1,
    units: "timeline",
  },
]

export const PRINT_FORM_NAME = `${NAME}/PRINT_FORM`

export const ClipboardMode = {
  NONE: "",
  COPY: "copy",
  CUT: "cut",
}
