import React from "react"
import clsx from "clsx"
import { StylableProps } from "./Styleable"

export interface ResultProps {
  last?: boolean
}

export const Result: React.FC<StylableProps & ResultProps> = ({
  children,
  className,
  style,
  last,
}) => (
  <li
    className={clsx(
      "flex border-gray-200 p-2 py-1 mb-1",
      last ? "border-b-0" : "border-b",
      className
    )}
    style={style}
  >
    {children}
  </li>
)

export const Results: React.FC<StylableProps> = ({
  children,
  className,
  style,
}) => {
  const lastIndex = React.Children.count(children) - 1
  return (
    <ul className={clsx("flex flex-col", className)} style={style}>
      {React.Children.map(children, (child, index) => (
        <Result last={index === lastIndex}>{child}</Result>
      ))}
    </ul>
  )
}
