import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { PermissionResponse } from "./types"
import { camelizeKeys } from "humps"
export interface CreatePermissionBody {
  manage: boolean
  read: boolean
  write: boolean
  calendarId: number
}

export interface CreatePermissionParams extends RequestParams {
  invitationId: string
}

export const useCreatePermission = (subdomain: string) => {
  const updateEntity = useUpdateEntity()
  const createPermission = useApiRequest<
    PermissionResponse,
    CreatePermissionBody,
    CreatePermissionParams
  >({
    method: "POST",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/invitations/:invitationId/granular_permissions`,
    onResponse: (response) => {
      updateEntity("permissions", response.id, {
        ...camelizeKeys(response),
        organization: subdomain,
      })
    },
  })
  return createPermission
}
