import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import InvitationForm from "../containers/InvitationForm.react"
import ResendForm from "../containers/ResendForm.react"
import { View, deleteFormWithName } from "../../shared"
import { isDefined } from "../../utils/helpers"
import { DELETE_FORM_NAME } from "../constants"
import { invitationSelector } from "../../api"

import { faUser, faPaperPlane } from "@fortawesome/pro-regular-svg-icons"

const { checkPending } = invitationSelector

/**
 * Generates a delete form that can be embedded in the current view.
 * @type {ReactClass} An instance of an invitation delete form.
 */
const InvitationDeleteForm = deleteFormWithName(DELETE_FORM_NAME)

/**
 * A wrapper class that presents a toolbar and a form to edit or
 * create an invitation. Invitations are effectively how an admin
 * user manages users within their organization.
 */
class InvitationDetailView extends Component {
  componentWillMount() {
    this.populateView()
  }

  componentDidUpdate(prevProps) {
    const { invitationId } = this.props.match.params
    if (prevProps.match.params.invitationId !== invitationId) {
      this.populateView()
    }
  }

  populateView() {
    const { subdomain, invitationId } = this.props.match.params
    if (invitationId) {
      this.populateInvitation(subdomain, invitationId)
    } else {
      this.populateOrganization(subdomain)
    }
  }

  /**
   * Fetches the current invitation from the API in order to populate
   * the view.
   *
   * @param {String}  subdomain      The id of the organization the invitation belongs to.
   * @param {Integer} invitationId   The id of the invitation to fetch
   */
  populateInvitation(subdomain, invitationId) {
    this.props.loadInvitation(subdomain, invitationId).then((action) => {
      if (action.error || action.payload.result.length < 1) {
        this.props.history.replace("/404")
      } else {
        this.props
          .requestInvitationPermissions(subdomain, invitationId)
          .then(() => this.populateCalendars(subdomain))
      }
    })
  }

  /**
   * Fetches the current organization from the API in order to populate
   * the view.
   *
   * @param {String}  subdomain      The id of the organization the invitation belongs to.
   */
  populateOrganization(subdomain) {
    this.props.loadOrganization(subdomain).then((action) => {
      if (action.error || action.payload.result.length < 1) {
        this.props.history.replace("/404")
      } else {
        this.populateCalendars(subdomain)
      }
    })
  }

  /**
   * Fetches the current calendars from the API in order to populate
   * the view.
   *
   * @param {String}  subdomain      The id of the organization the invitation belongs to.
   */
  populateCalendars(subdomain) {
    this.props.loadCalendar(subdomain).then((action) => {
      if (action.error) {
        this.props.history.replace("/404")
      }
    })
  }

  getInitialFormValues() {
    const { initialValues, findInvitation } = this.props
    const { subdomain, invitationId } = this.props.match.params
    const formValues = findInvitation(invitationId) || initialValues
    return Object.assign({}, { subdomain }, formValues)
  }

  render() {
    const {
      ViewContainer,
      ToolBar,
      ToolBarIcon,
      ToolBarSection,
      ToolBarHeading,
      ViewContent,
    } = View
    const { removeInvitation, resendInvitation, calendarsForOrg } = this.props
    const { subdomain, invitationId } = this.props.match.params
    const values = this.getInitialFormValues()
    const calendars = calendarsForOrg(subdomain)
    const name = isDefined(values.id) ? values.name : "New User"
    const actionType = isDefined(values.id) ? "Settings" : "Create Invitation"
    return (
      <ViewContainer>
        <Helmet title={`${actionType} for ${name}`} />
        <ToolBar>
          <ToolBarSection required={true} extended={true}>
            <ToolBarIcon
              icon={(isDefined(values.id) && faUser) || faPaperPlane}
            />
            <ToolBarHeading primaryText={name} secondaryText={actionType} />
          </ToolBarSection>
        </ToolBar>
        <ViewContent>
          {checkPending(values) && (
            <ResendForm
              invitationId={values.id}
              lastSentDate={values.updatedAt}
              subdomain={values.organization}
              resendInvitation={resendInvitation}
            />
          )}
          <InvitationForm
            {...this.props}
            initialValues={values}
            calendars={calendars}
            subdomain={subdomain}
            invitationId={invitationId}
          />
          {values.id && (
            <InvitationDeleteForm
              entityName="User"
              confirmationName={values.email}
              deleteAction={() => removeInvitation(subdomain, values.id)}
              redirectAction={() =>
                this.props.history.replace(`/org/${subdomain}/cal/`)
              }
            />
          )}
        </ViewContent>
      </ViewContainer>
    )
  }
}

InvitationDetailView.propTypes = {
  /**
   * These are the expected params that should be
   * passed in via redux router.
   */
  match: PropTypes.object,

  /**
   * An object with values that can be mapped to
   * the InvitationForm as it's default values.
   */
  initialValues: PropTypes.object,

  /**
   * A thunk mapped to dispatch which will fetch
   * the latest information for a given invitation
   * to the cache.
   */
  loadInvitation: PropTypes.func.isRequired,

  /**
   * A thunk mapped to dispatch which will fetch
   * the latest information for a given organization
   * to the cache.
   */
  loadOrganization: PropTypes.func.isRequired,

  /**
   * A thunk mapped to dispatch which will delete
   * a specified invitation via the API.
   */
  removeInvitation: PropTypes.func.isRequired,

  /**
   * React router redux action mapped to dispatch that
   * will replace the current url instead of push an
   * updated url to the user's history.
   */
  history: PropTypes.func.isRequired,

  /**
   * Used to fetch an invitation from the cache and
   * populate the invitation form's initial values.
   */
  findInvitation: PropTypes.func.isRequired,

  /**
   * Passed down to the form view in order to fetch a cached
   * instance of a newly created invitation. This allows us
   * to redirect the user to the edit path for the newly
   * created invitation.
   */
  findInvitationByEmail: PropTypes.func.isRequired,

  /**
   * Passed down to the form view in order to fetch a cached
   * instance of a newly created invitation. This allows us
   * to redirect the user to the edit path for the newly
   * created invitation.
   */
  currentEmailAddress: PropTypes.string,

  /**
   * A redux axction creator mapped to dispatch which takes
   * an invitation and posts it to the resend action asynchronously
   * via the API.
   */
  resendInvitation: PropTypes.func.isRequired,

  /**
   * Returns all calendars scoped to an organization.
   */
  calendarsForOrg: PropTypes.func.isRequired,

  /**
   * Disatches a rpomise that fetches calendar data from
   * the API.
   */
  loadCalendar: PropTypes.func.isRequired,

  /**
   * Requests all avilable permissions for a specific invitation.
   */
  requestInvitationPermissions: PropTypes.func.isRequired,
}

export default withRouter(InvitationDetailView)
