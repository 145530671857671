import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"

//
// GET / Find all OR by :style
//

/**
 * Action creator that generates an API call to fetch all styles
 * or a specific style by ID.
 *
 * @param  {String} subdomain     The id of the organization which the style of the style belongs to.
 * @param  {Integer} calendarId   The id of the style the style belongs to.
 * @param  {Integer} styleUuid   The (optional) id of the style to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getStyle = (subdomain, calendarId, styleUuid) => ({
  type: types.FETCH_STYLES,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: styleUuid ? Schemas.STYLE : Schemas.STYLES_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/styles/${
      styleUuid || ""
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.FETCH_STYLES_REQUEST,
      types.FETCH_STYLES_SUCCESS,
      types.FETCH_STYLES_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetch all styles
 * or a specific style by ID. This is a convenience method that wraps
 * the getStyle() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the style belongs to.
 * @param  {Integer}  styleUuid    The (optional) id of the style to fetch.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const requestStyle =
  (subdomain, calendarId, styleUuid) => (dispatch) => {
    return dispatch(getStyle(subdomain, calendarId, styleUuid))
  }

//
// POST / New style
//

/**
 * Action creator that generates an API call to create a new style
 * for a specific calendar.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the style belongs to.
 * @param  {Integer}  style      The style that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const postStyle = (subdomain, calendarId, style) => ({
  type: types.CREATE_STYLE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.STYLE,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/styles`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys(style)),
    types: [
      types.CREATE_STYLE_REQUEST,
      types.CREATE_STYLE_SUCCESS,
      types.CREATE_STYLE_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to create a new style
 * for a specific style. This is a convenience method that wraps
 * the postStyle() creator in a thunk.
 *
 * @param  {String}  subdomain    The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the style belongs to.
 * @param  {Object}  style     Style data or body for the API call.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const createStyle = (subdomain, calendarId, style) => (dispatch) => {
  return dispatch(postStyle(subdomain, calendarId, style))
}

//
// PUT / Update Style
//

/**
 * Action creator that generates an API call to update an existing style
 * against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the style belongs to.
 * @param  {Object}   style      The style that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const putStyle = (subdomain, calendarId, style) => ({
  type: types.UPDATE_STYLE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.STYLE,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/styles/${style.uuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys(style)),
    types: [
      types.UPDATE_STYLE_REQUEST,
      types.UPDATE_STYLE_SUCCESS,
      types.UPDATE_STYLE_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * style against the API. This is a convenience method that wraps
 * the putStyle() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the style belongs to.
 * @param  {Object}   style      The style that will be updated.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const updateStyle = (subdomain, calendarId, style) => (dispatch) => {
  return dispatch(putStyle(subdomain, calendarId, style))
}

//
// DELETE / Delete existing Style via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * style against the API.
 *
 * @param  {String} subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the style belongs to.
 * @param  {Integer} styleUuid   The id of the style that will be deleted.
 * @return {Object}               An object representing the redux action.
 */
export const deleteStyle = (subdomain, calendarId, styleUuid) => ({
  type: types.DELETE_STYLE,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.STYLE,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/styles/${styleUuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.DELETE_STYLE_REQUEST,
      types.DELETE_STYLE_SUCCESS,
      types.DELETE_STYLE_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * style against the API. This is a convenience method that wraps
 * the deleteStyle() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the style belongs to.
 * @param  {Integer}  styleUuid    The id of the style that will be deleted.
 * @return {Promise}                An object representing the redux action.
 */
export const removeStyle = (subdomain, calendarId, styleUuid) => (dispatch) => {
  return dispatch(deleteStyle(subdomain, calendarId, styleUuid))
}
