import { connect } from "react-redux"
import {
  organizationSelector,
  requestOrganization,
  isFetching,
} from "../../api"
import OrganizationListView from "../components/OrganizationListView.react"

export default connect(
  (state) => ({
    organizations: organizationSelector.all(state),
    fetching: isFetching(state),
  }),
  {
    refreshList: requestOrganization,
  }
)(OrganizationListView)
