import React from "react"
import { Button, Indicators, Modal, ModalProps } from "ui"
import { usePrintJobStatus } from "../api"

export interface PrintPollingModalProps {
  subdomain: string
  calendarId?: number
  reportId?: number
  jobId: string
  sentTo: string
}

export const PrintPollingModal: React.FC<
  PrintPollingModalProps & ModalProps
> = ({
  onClose: handleDismiss,
  subdomain,
  calendarId,
  reportId,
  jobId,
  onFocus: handleFocus,
  zIndex,
  sentTo,
}) => {
  const { url, loading } = usePrintJobStatus({
    subdomain,
    calendarId,
    reportId,
    jobId,
  })

  return (
    <Modal
      title="Download PDF"
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <div className="flex flex-col">
        {loading ? (
          <p className="py-2">
            A PDF of your production calendar will be printed momentarily. If
            you do not wish to wait, you can close this window. An email
            confirmation along with the PDF attached will be sent to:
          </p>
        ) : (
          <p className="py-2">
            Your production calendar is ready to download. A copy has been
            emailed to:
          </p>
        )}
        {sentTo ? (
          <p className="font-bold">
            <strong>{sentTo}</strong>
          </p>
        ) : null}
        <p className="py-2 mb-2">
          If you experience any problems with the printed calendar, please{" "}
          <a
            href="mailto:support@revolutiones.com"
            className="text-sorbus-default font-bold"
          >
            contact support
          </a>
          .
        </p>
        {loading ? (
          <Indicators.Spinner
            className="mx-auto py-6 text-rose-dark"
            size="4x"
          />
        ) : (
          <div className="flex">
            <Button
              onClick={() => (window.location.href = url as string)}
              className="flex-grow w-full"
            >
              Download PDF
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}
