var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDrop } from "react-dnd";
import { DRAWER_TYPE } from "./types";
export var DrawerBackground = function (_a) {
    var children = _a.children, externalClickHandler = _a.onClick, handleSizeChange = _a.onDragOver;
    var _b = useDrop(function () { return ({
        accept: DRAWER_TYPE,
        hover: function (_, monitor) {
            var delta = monitor.getClientOffset();
            handleSizeChange(delta.x);
            return undefined;
        },
    }); }, [handleSizeChange]), drop = _b[1];
    var handleClick = function (e) {
        e.preventDefault();
        externalClickHandler === null || externalClickHandler === void 0 ? void 0 : externalClickHandler();
    };
    return (_jsx("div", __assign({ ref: drop, onClick: handleClick, className: "h-screen w-screen inset-0 fixed z-10" }, { children: children }), void 0));
};
