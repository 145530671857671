import { CalendarToolBar, CalendarToolBarProps } from "ui"
import { getRecentCalendarIds } from "./session"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  calendarSelector,
  useCalendars,
  useHasCreatorPrivilege,
} from "../../api"
import { useItemSelectHandler } from "./useItemSelectHandler"

interface ConnectedToolBarProps {
  subdomain: string
  currentCalendarId: string
  startDate: string
  viewId: string
  disabled?: boolean
}

export const ConnectedToolBar: React.FC<
  CalendarToolBarProps & ConnectedToolBarProps
> = ({
  currentCalendarId,
  subdomain,
  startDate,
  viewId,
  disabled,
  ...props
}) => {
  const canCreate = useHasCreatorPrivilege(subdomain) && !disabled

  const history = useHistory()
  const { loading: loadingCalendars } = useCalendars(subdomain)
  const findCalendar = useSelector(calendarSelector.find)
  const handleItemSelect = useItemSelectHandler(props.onItemSelect)

  const handleCalendarDropdown = () => {
    if (loadingCalendars) {
      return [{ label: "Loading Recent...", informational: true }]
    }
    const recents = getRecentCalendarIds()
      .filter((id: string) => id !== currentCalendarId)
      .map((id: string) => findCalendar(id))
      .filter((c: any) => c)
      .map((c: any) => ({
        id: c?.id,
        label: c?.name ?? `Calendar ID: ${c?.id ?? "..."}`,
        secondaryLabel: c?.organization,
      }))
    return recents.length > 0
      ? recents
      : [
          {
            label: "No recent calendars",
            secondaryLabel: "Browse other calendars to populate",
            informational: true,
          },
        ]
  }

  const handleCalendarSelect = (id: string) => {
    history.push(`/org/${subdomain}/cal/${id}`)
  }

  return (
    <CalendarToolBar
      {...props}
      onCalendarDropdown={handleCalendarDropdown}
      onCalendarSelect={handleCalendarSelect}
      onItemSelect={handleItemSelect}
      canSaveCalendar={canCreate}
      disabled={disabled}
    />
  )
}
