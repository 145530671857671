var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var SPINNER_CLASS = "flex flex-grow-0 flex-shrink";
export var Spinner = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? "1x" : _b;
    return (_jsx("p", __assign({ className: [className !== null && className !== void 0 ? className : "", SPINNER_CLASS].join(" ") }, { children: _jsx("span", __assign({ className: "animate-spin" }, { children: _jsx(FontAwesomeIcon, { icon: faSpinnerThird, size: size }, void 0) }), void 0) }), void 0));
};
