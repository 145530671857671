import { SnapshotToolBar, SnapshotToolBarItem, SnapshotToolBarProps } from "ui"
import { useCalendar, useSnapshots, useSnapshot } from "../../api"
import { useItemSelectHandler } from "./useItemSelectHandler"

interface ConnectedSnapshotToolBarProps {
  subdomain: string
  currentCalendarId: string
  snapshotId: string
  onItemSelect: (item: SnapshotToolBarItem) => void
}
export const ConnectedSnapshotToolBar: React.FC<
  SnapshotToolBarProps & ConnectedSnapshotToolBarProps
> = ({ currentCalendarId, subdomain, snapshotId, ...props }) => {
  useSnapshots(subdomain, currentCalendarId)
  const { snapshot } = useSnapshot(subdomain, currentCalendarId, snapshotId)
  const { calendar } = useCalendar(subdomain, currentCalendarId)
  const handleItemSelect = useItemSelectHandler(props.onItemSelect)

  return (
    <SnapshotToolBar
      {...props}
      calendarName={snapshot?.name ?? "Loading..."}
      calendarDescription={`Version of: ${calendar?.name ?? "..."}`}
      onItemSelect={handleItemSelect}
    />
  )
}
