import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DRAWER_GROUP_TYPE, DRAWER_ITEM_TYPE } from "./types";
export var useSort = function (_a) {
    var id = _a.id, parentId = _a.parentId, label = _a.label, position = _a.position, supportedBehaviors = _a.supportedBehaviors, onComplete = _a.onComplete;
    var _b = useState(null), behavior = _b[0], setBehavior = _b[1];
    var ref = useRef(null);
    var _c = useDrop(function () { return ({
        accept: [DRAWER_GROUP_TYPE, DRAWER_ITEM_TYPE],
        collect: function (monitor) { return ({ isOver: monitor.isOver() }); },
        drop: function (item, monitor) {
            if (item.id === id) {
                return {};
            }
            return {
                dragId: item.id,
                dropId: id,
                originPosition: item.index,
                destinationPosition: position,
                behavior: monitor.getItem().behavior,
                parentId: parentId,
            };
        },
        hover: function (item, monitor) {
            var node = ref.current;
            if (!node) {
                return null;
            }
            var dragIndex = item.position;
            var hoverIndex = position;
            var behavior = null;
            // Item's cannot be dropped onto their existing position.
            if (dragIndex === hoverIndex) {
                setBehavior(behavior);
                monitor.getItem().behavior = behavior;
                return;
            }
            // Determine rectangle on screen
            var hoverBoundingRect = node.getBoundingClientRect();
            // Get upper bound
            var boundary = hoverBoundingRect.height / supportedBehaviors.length;
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            var clientY = clientOffset.y - hoverBoundingRect.top;
            var hoverPosition = Math.min(Math.floor(clientY / boundary), supportedBehaviors.length);
            if (supportedBehaviors.length === 1) {
                behavior = supportedBehaviors[0];
            }
            else if (supportedBehaviors.includes("insert") &&
                hoverPosition === 1) {
                behavior = "insert";
            }
            else if (hoverPosition === 0 && hoverIndex < dragIndex) {
                behavior = "before";
            }
            else if (hoverPosition >= 1 && hoverIndex > dragIndex) {
                behavior = supportedBehaviors.includes("after")
                    ? "after"
                    : supportedBehaviors.includes("insert")
                        ? "insert"
                        : null;
            }
            setBehavior(behavior);
            monitor.getItem().behavior = behavior;
        },
    }); }, [position, id, parentId, supportedBehaviors]), isOver = _c[0].isOver, drop = _c[1];
    var _d = useDrag(function () { return ({
        type: DRAWER_GROUP_TYPE,
        item: {
            type: DRAWER_GROUP_TYPE,
            label: label,
            id: id,
            position: position,
            behavior: null,
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
        end: function (item, monitor) {
            var result = monitor.getDropResult();
            onComplete && result && onComplete(result);
        },
    }); }, [onComplete, id, position]), isDragging = _d[0].isDragging, drag = _d[1];
    drag(drop(ref));
    return { ref: ref, isDragging: isDragging, isOver: isOver, behavior: behavior };
};
