import { createBrowserHistory } from "history"
import { createStore, applyMiddleware, compose } from "redux"
import { routerMiddleware } from "connected-react-router"
import createDebounce from "redux-debounced"
import thunkMiddleware from "redux-thunk"
import LogRocket from "logrocket"
import { apiMiddleware, authMiddleware, getAccessToken } from "../api"
import { isDefined, isBlank, isNotBlank } from "../utils/helpers"
import createRootReducer from "../createRootReducer"

export const history = createBrowserHistory()

const getPreloadedState = () => {
  return typeof window === "undefined" ? {} : window.__INITIAL_STATE__ || {}
}

const bindFromTokenStore = (state) => {
  const accessToken = getAccessToken()
  if (!isDefined(accessToken) || isBlank(accessToken)) {
    return state
  }
  const { api } = state
  const authEmbeddedState = Object.assign(state, {
    api: {
      isAuthenticated: isNotBlank(accessToken),
      ...api,
    },
  })
  return authEmbeddedState
}

const configureStore = () => {
  const windowExists = typeof window !== "undefined"
  const persistedState = bindFromTokenStore(getPreloadedState())
  const composeEnhancers =
    (windowExists && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const enhancer = composeEnhancers(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      createDebounce({ simple: 300, network: 10000 }),
      authMiddleware,
      apiMiddleware,
      thunkMiddleware,
      LogRocket.reduxMiddleware()
    )
  )
  const store = createStore(
    createRootReducer(history),
    persistedState,
    enhancer
  )
  return store
}

export default configureStore
