import { createSelector } from "reselect"
import { entities } from "./baseSelector"
import * as organizationSelector from "./organizationSelector"

/**
 * Returns all report entities in the cache.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the report entities from state.
 */
const allReports = (state) => entities(state).reports

/**
 * Returns all report calendar entities in the cache that have not been deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the report entities from state.
 */
const allReportCalendars = (state) =>
  Object.keys(entities(state).reportCalendars)
    .map((key) => entities(state).reportCalendars[key])
    .filter((entity) => !entity.isDeleted)

/**
 * Returns a report calendar for a specific calendar and report.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the report entities from state.
 */
export const findReportCalendars =
  (state) =>
  ({ reportId }) =>
    allReportCalendars(state).filter(
      (entity) => parseInt(`${entity.reportId}`) === parseInt(`${reportId}`)
    )

/**
 * Returns a report calendar for a specific calendar and report.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the report entities from state.
 */
export const findReportCalendar =
  (state) =>
  ({ reportId, calendarId }) =>
    findReportCalendars(state)({ reportId }).filter(
      (entity) => parseInt(`${entity.calendarId}`) === parseInt(`${calendarId}`)
    )[0]

/**
 * Returns all reports entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} reports The current state of the redux store.
 * @return {Array}        A function that returns an array of report entities.
 */
const availableReports = (reports) =>
  Object.keys(reports)
    .map((key) => reports[key])
    .sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
    .filter((entity) => !entity.isDeleted)

/**
 * Returns all reports entities in the cache that have not been
 * deleted. (memoized)
 */
export const all = createSelector(allReports, availableReports)

/**
 * Returns a query function that fetches all reports for a single organization.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of reports that match a supplied calendar ID.
 */
export const forOrganization = (state) => (organizationId) =>
  all(state).filter(
    (report) =>
      parseInt(report.organizationId, 0) === parseInt(organizationId, 0)
  )

/**
 * Returns a query function that fetches all report calendars for a single organization and report.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of reports that match a supplied calendar ID.
 */
export const calendarsForReport = (state) => (reportId) => {
  return allReportCalendars(state).filter(
    (calendar) => parseInt(calendar.reportId, 0) === parseInt(reportId, 0)
  )
}

/**
 * Returns a query function that fetches all report calendar Ids for a single organization and report.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of reports that match a supplied calendar ID.
 */
export const calendarIdsForReport = (state) => (reportId) => {
  return calendarsForReport(state)(reportId).map((c) => c.calendarId)
}

/**
 * Returns a query function that fetches all report calendar Ids for a single organization and report that do not have a special rendering mode.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of reports that match a supplied calendar ID.
 */
export const renderableCalendarIdsForReport = (state) => (reportId) => {
  return calendarsForReport(state)(reportId)
    .filter((r) => !r.showInOut)
    .map((c) => c.calendarId)
}

/**
 * Retrieves reports for the current organization in props.
 * @param  {Object} state The current state of the application in the redux store.
 * @param  {Object} props The current props for the component.
 * @return {Array}       An array of reports.
 */
export const forCurrentOrganization = (state, props) => {
  const organization = organizationSelector.find(state)(props.subdomain)
  return forOrganization(state)(organization && organization.id)
}

/**
 * Retrieves a specific report object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching snapshot if one exists.
 */
export const find = (state) => (reportId) => {
  const entity = entities(state).reports[reportId]
  return entity && !entity.isDeleted && entity
}
