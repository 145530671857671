import * as types from "./actionTypes"
import api from "../api"
import { getItem } from "../utils/tokenStore"

export const loadingOrgs = () => ({
  type: types.LOADING_ORGS,
})

export const receiveOrgs = () => ({
  type: types.RECEIVED_ORGS,
})

export const loadOrgForSubdomain = (subdomain) => (dispatch) => {
  dispatch(loadingOrgs())
  return dispatch(api.actions.orgs.requestOrganization(subdomain)).then(
    (action) => {
      dispatch(receiveOrgs())
      const orgs =
        action &&
        action.payload &&
        action.payload.entities &&
        action.payload.entities.organizations
      if (orgs) {
        const rememberedOrg = orgs[getItem("subdomain")]
        const firstOrg = orgs[Object.keys(orgs)[0]]
        return rememberedOrg || firstOrg
      }
      return undefined
    }
  )
}
