import { useCallback } from "react";
import { useEventListener } from "./useEventListener";
var MOUSE_DOWN = "mousedown";
var TOUCH_START = "touchstart";
export function useClickAway(ref, handler, controllerRef) {
    var listener = useCallback(function (event) {
        if (isContained(ref, event.target) &&
            isContained(controllerRef, event.target)) {
            handler === null || handler === void 0 ? void 0 : handler(event);
        }
    }, [handler, ref, controllerRef]);
    useEventListener({
        type: MOUSE_DOWN,
        listener: listener,
    });
    useEventListener({
        type: TOUCH_START,
        listener: listener,
    });
}
var isContained = function (ref, target) { var _a; return ref && ref.current && !((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(target)); };
