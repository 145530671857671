import React from "react"
import PropTypes from "prop-types"
import styles from "./AssistantText.module.css"

const styleForState = (active, error) =>
  (error && styles.error) || (active && styles.active) || ""

const AssistantText = ({ children, active, error }) => (
  <div className={styles.textWrapper}>
    <p className={`${styles.assistantText} ${styleForState(active, error)}`}>
      {children}
    </p>
  </div>
)

AssistantText.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  error: PropTypes.bool,
}

export default AssistantText
