var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faChevronDown, faChevronRight, faGripLines, } from "@fortawesome/pro-regular-svg-icons";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { useSort } from "./useSort";
import { truncate } from "utils";
var preventDefault = function (e) { return e.preventDefault(); };
var ITEM_CLASSES = "flex flex-row justify-center items-center max-w-full transition-colors duration-150 ease-in cursor-pointer p-2 relative";
var ICON_WRAPPER_CLASSES = "flex flex-row justify-center items-center w-10 mr-1 text-md";
var GRIP_LINES_WRAPPED_CLASSES = "flex flex-row justify-center items-center w-8 ml-auto text-xl";
var SECONDARY_ICON_WRAPPER = "flex flex-row justify-center items-center w-8 ml-auto text-base h-full";
var GRIP_ICON_COLOR = "text-gray-600 transition-colors duration-150 ease-in";
var ICON_COLOR = "text-gray-600 transition-colors duration-150 ease-in";
var LABEL_DESC_WRAPPER_CLASSES = "flex flex-col justify-center mr-auto";
var LABEL_CLASSES = "text-sm font-bold transition-colors duration-150 ease-in";
var DISABLED_STATE_COLOR = "text-gray-500";
var DRAG_CONTROL = "absolute inset-0 hover:bg-lochivar-default hover:bg-opacity-5";
export var DrawerGroup = function (_a) {
    var label = _a.label, onSelect = _a.onSelect, secondaryIcon = _a.secondaryIcon, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.sortable, sortable = _c === void 0 ? false : _c, onSecondaryClick = _a.onSecondaryClick, open = _a.open, _d = _a.indent, indent = _d === void 0 ? 0 : _d, id = _a.id, position = _a.position, groupId = _a.groupId, _e = _a.truncationLength, truncationLength = _e === void 0 ? 40 : _e, onSort = _a.onSort, onContextMenu = _a.onContextMenu;
    var _f = useState(false), isHovered = _f[0], setIsHovered = _f[1];
    var handleOnMouseEnter = function () { return setIsHovered(function () { return true; }); };
    var handleOnMouseLeave = function () { return setIsHovered(function () { return false; }); };
    var handleOnSelect = function (event) {
        if (disabled) {
            event.preventDefault();
            return;
        }
        event.stopPropagation();
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(event);
    };
    var handleOnSecondaryClick = function (event) {
        if (disabled) {
            event.preventDefault();
            return;
        }
        event.stopPropagation();
        onSecondaryClick === null || onSecondaryClick === void 0 ? void 0 : onSecondaryClick(event);
    };
    var hover = isHovered && !disabled && !sortable;
    var iconHoverStyles = hover && "text-blue-200";
    var _g = useSort({
        position: position,
        id: id,
        label: label,
        supportedBehaviors: open
            ? ["before", "insert"]
            : ["before", "after", "insert"],
        parentId: groupId,
        onComplete: onSort,
    }), ref = _g.ref, isDragging = _g.isDragging, isOver = _g.isOver, behavior = _g.behavior;
    var handleContextMenu = function (e) {
        e.preventDefault();
        e.stopPropagation();
        onContextMenu === null || onContextMenu === void 0 ? void 0 : onContextMenu({
            groupId: groupId,
            id: id,
            position: position,
            x: e.clientX,
            y: e.clientY,
            type: "DRAWER_GROUP",
            label: label,
        });
    };
    return (_jsxs("li", __assign({ onContextMenu: handleContextMenu, onMouseDown: sortable ? undefined : preventDefault, onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave, className: clsx(ITEM_CLASSES, isDragging && "opacity-50", disabled && "bg-gray-100 cursor-not-allowed", !disabled && !sortable && "hover:bg-blue-100"), onClick: handleOnSelect, style: {
            paddingLeft: indent * 30,
        } }, { children: [_jsx("p", __assign({ className: ICON_WRAPPER_CLASSES }, { children: _jsx(FontAwesomeIcon, { icon: open ? faChevronDown : faChevronRight, className: clsx(iconHoverStyles, disabled ? DISABLED_STATE_COLOR : ICON_COLOR) }, void 0) }), void 0), _jsx("p", __assign({ className: LABEL_DESC_WRAPPER_CLASSES }, { children: _jsx("span", __assign({ className: clsx(LABEL_CLASSES, hover && "text-blue-500", disabled ? DISABLED_STATE_COLOR : "text-gray-800") }, { children: truncate(label, truncationLength, "...", 4) }), void 0) }), void 0), sortable ? (_jsx("p", __assign({ className: GRIP_LINES_WRAPPED_CLASSES }, { children: _jsx(FontAwesomeIcon, { icon: faGripLines, className: clsx(iconHoverStyles, GRIP_ICON_COLOR) }, void 0) }), void 0)) : null, hover && handleOnSecondaryClick ? (_jsx("button", __assign({ className: clsx(disabled && "cursor-not-allowed focus:outline-none", SECONDARY_ICON_WRAPPER), onClick: handleOnSecondaryClick }, { children: _jsx(FontAwesomeIcon, { icon: secondaryIcon !== null && secondaryIcon !== void 0 ? secondaryIcon : faCog, className: clsx(hover && "text-blue-400", hover && iconHoverStyles, disabled ? DISABLED_STATE_COLOR : ICON_COLOR) }, void 0) }), void 0)) : null, _jsx("div", { ref: ref, className: clsx(DRAG_CONTROL, sortable ? "block" : "hidden", isOver && behavior === "insert" && "border-lochivar-default border-2", isOver &&
                    behavior === "before" &&
                    "border-lochivar-default border-t-2", isOver && behavior === "after" && "border-lochivar-default border-b-2") }, void 0)] }), void 0));
};
