import React from "react"
import PropTypes from "prop-types"
import { Form } from "../../shared"
import { colors } from "../../utils"

/**
 * Renders a list of available schedules as a simple checkbox group or
 * advanced grid.
 */
class ScheduleListGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expandSchedules: false,
      expandList: false,
    }
  }

  renderSchedules() {
    const { expandList } = this.state
    const { input, holidaySchedules } = this.props
    const allHolidays = holidaySchedules.map((s) => s.holidayNames).flat()
    /* eslint-disable no-confusing-arrow */
    const uniqHolidays = expandList
      ? allHolidays
          .filter((h, i) => allHolidays.indexOf(h) === i)
          .sort((a, b) =>
            parseInt(a.split(" - ")[0]) > parseInt(b.split(" - ")[0])
              ? 1
              : parseInt(a.split(" - ")[0]) < parseInt(b.split(" - ")[0])
              ? -1
              : 0
          )
      : []
    /* eslint-enable no-confusing-arrow */
    const selectedHolidays = holidaySchedules
      .filter((s) => input.value.includes(s.key))
      .map((s) => s.holidayNames)
      .flat()
    return (
      <table
        style={{
          marginTop: expandList ? "6rem" : "1rem",
          marginBottom: "1rem",
        }}
      >
        <tr>
          <th
            style={{
              background: "#ccc",
              width: expandList ? "16rem" : "auto",
              textAlign: expandList ? "right" : "left",
              padding: "0 0.5rem",
            }}
            colSpan={2}
          >
            Holiday Schedules
          </th>
          {uniqHolidays.map((h) => (
            <th
              key={`Heading${h}`}
              style={{
                position: "relative",
                width: "2rem",
                textAlign: "right",
              }}
            >
              <label
                style={{
                  color: selectedHolidays.includes(h)
                    ? colors.lochivar
                    : colors.rose,
                  transform: "rotate(90deg)",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  transformOrigin: "center right",
                  margin: 0,
                  display: "block",
                  fontSize: "0.75rem",
                  whiteSpace: "nowrap",
                }}
              >
                {h.split(" - ")[1].replace("Martin Luther King, Jr.", "MLK")}
              </label>
            </th>
          ))}
        </tr>
        {holidaySchedules
          .filter((_, i) => expandList || i <= 5)
          .map((s, index) => (
            <tr
              key={`holidayRow${s.key}`}
              style={{
                borderBottom: `1px solid ${colors.roseLight}`,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault()
                const newValue = [...input.value]
                if (!input.value.includes(s.key)) {
                  newValue.push(s.key)
                } else {
                  newValue.splice(newValue.indexOf(s.key), 1)
                }
                return input.onChange(newValue)
              }}
            >
              <td
                style={{
                  background: input.value.includes(s.key)
                    ? colors.roseLight
                    : colors.roseLightest,
                  fontSize: "0.75rem",
                  textAlign: expandList ? "right" : "left",
                  padding: "0 0.5rem",
                }}
              >
                <input
                  type="checkbox"
                  name={`${input.name}[${index}]`}
                  value={s.key}
                  checked={input.value.indexOf(s.key) !== -1}
                  style={{ pointerEvents: "none" }}
                />
              </td>
              <td
                style={{
                  background: input.value.includes(s.key)
                    ? colors.roseLight
                    : colors.roseLightest,
                  width: expandList ? "16rem" : "auto",
                  fontSize: "0.75rem",
                  textAlign: expandList ? "right" : "left",
                  padding: expandList ? "0 0.5rem" : "0.25rem 0.5rem",
                }}
              >
                {s.name}
              </td>
              {uniqHolidays.map((h) => (
                <td
                  key={`${h}In${s.key}`}
                  style={{
                    width: "2rem",
                    textAlign: "center",
                    color: selectedHolidays.includes(h)
                      ? colors.lochivar
                      : colors.rose,
                    background: input.value.includes(s.key)
                      ? colors.roseLightest
                      : "#fff",
                  }}
                >
                  {s.holidayNames.indexOf(h) > -1 ? (
                    <span
                      style={{
                        display: "block",
                        fontSize: "0.75rem",
                        background: selectedHolidays.includes(h)
                          ? colors.lochivarLight
                          : "",
                      }}
                    >
                      X
                    </span>
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
      </table>
    )
  }

  render() {
    const { expandList } = this.state
    return (
      <div>
        <Form.Label>
          Do you want to generate any optional holiday schedule(s)?
        </Form.Label>
        {!expandList ? (
          <Form.GridRow>
            <Form.FieldCol>{this.renderSchedules()}</Form.FieldCol>
            <Form.AssistantCol>
              <Form.AssistantText active={true}>
                Select a holiday schedule that best fits your production.
                Multiple schedules can be selected. Any holiday that falls on a
                weekend, may be observed on the day before or after the weekend,
                in accordance to your company policy or union rules.
              </Form.AssistantText>
            </Form.AssistantCol>
          </Form.GridRow>
        ) : (
          this.renderSchedules()
        )}
        <Form.Message>
          <button
            onClick={(e) => {
              e.preventDefault()
              this.setState({ expandList: !expandList })
            }}
          >
            Show {expandList ? "Basic Schedules" : `Advanced Schedule Selector`}
          </button>
        </Form.Message>
      </div>
    )
  }
}

ScheduleListGroup.propTypes = {
  /**
   * A list of all available holiday schedules.
   */
  holidaySchedules: PropTypes.arrayOf(PropTypes.object),

  /**
   * Passed in from redux form's HOC.
   */
  input: PropTypes.object,
}

export default ScheduleListGroup
