import { useSelector } from "react-redux"

export type EntitySchema =
  | "metrics"
  | "connections"
  | "organizations"
  | "snapshots"
  | "calendars"
  | "groups"
  | "invitations"
  | "icons"
  | "events"
  | "items"
  | "categories"
  | "styles"
  | "permissions"
  | "holidays"
  | "reports"
  | "reportCalendars"

type MappedObject = { [key: string]: unknown }
interface EntityStore {
  entities: { [key in EntitySchema]: object }
}
interface ApiStore {
  api: EntityStore
}

export const useEntity = <T extends object>({
  entity,
  id,
  filter,
}: {
  entity: EntitySchema
  id?: string | number
  filter?: T
}) => {
  const entities = useSelector<ApiStore>(
    (state) => state.api.entities[entity]
  ) as { [key: string | number]: T }
  if (id) {
    return entities[id]
  }
  if (!filter) {
    return Object.values(entities)
  }
  return Object.keys(filter).reduce((acc, key) => {
    const filterValue = (filter as MappedObject)[key]
    if (!filterValue) {
      return acc
    }
    return acc.filter((e: unknown) => (e as MappedObject)[key] === filterValue)
  }, Object.values(entities))
}
