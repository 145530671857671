import React from "react"
import PropTypes from "prop-types"
import styles from "./Body.module.css"

const Body = ({ children, disableScrolling }) => (
  <div
    className={`modal-body ${styles.body} ${
      disableScrolling && styles.noScrolling
    }`}
  >
    {children}
  </div>
)

Body.propTypes = {
  children: PropTypes.node.isRequired,
  disableScrolling: PropTypes.bool,
}

export default Body
