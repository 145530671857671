import React, { useState } from "react"
import {
  RetainableModal,
  useRetainedModalToggle,
  CategoryForm,
  CategoryFormValues,
  AsyncSubmitHandler,
  ModalProps,
} from "ui"
import toast from "react-hot-toast"
import {
  createCategory,
  updateCategory,
  useCategories,
  useStyles,
} from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"

const MODAL_NAME = "CATEGORY_MODAL"

export interface CategoryModalProps {
  categoryUuid?: string
  onEditTheme?: () => void
  onEditStyle?: (uuid?: string) => void
}

export const CategoryModal: React.FC<CategoryModalProps & ModalProps> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  categoryUuid,
  onEditTheme: handleEditTheme,
  onEditStyle: handleEditStyle,
  zIndex,
}) => {
  const [loading, setLoading] = useState(false)
  const params = useParams<{ subdomain: string; calendarId: string }>()

  const { loading: loadingCategories, categories } = useCategories(
    params.subdomain,
    params.calendarId
  )
  const category = categories?.find((c: any) => c.uuid === categoryUuid)

  const { styles, loading: loadingStyles } = useStyles(
    params.subdomain,
    params.calendarId,
    [category?.styleUuid]
  )
  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<CategoryFormValues> = async (
    data,
    action
  ) => {
    const { ...categoryData } = data
    setLoading(true)
    await wait(1)
    const values = {
      ...categoryData,
      interruptedByHolidays: `${categoryData.interruptedByHolidays}` === "1",
      interruptedByWeekends: `${categoryData.interruptedByWeekends}` === "1",
    }
    try {
      const response = await dispatch(
        category
          ? updateCategory(params.subdomain, params.calendarId, {
              ...values,
              active: category?.active ?? true,
              hidden: category?.hidden ?? false,
              uuid: categoryUuid,
            })
          : createCategory(params.subdomain, params.calendarId, values)
      )
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(
        `Successfully ${category ? "updated" : "created"} Category "${
          data.name
        }".`
      )
      if (!keepOpen) {
        handleDismiss?.()
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return
  }

  const { keepOpen, toggleKeepOpen } = useRetainedModalToggle(MODAL_NAME)

  const defaultValues = {
    name: category?.name ?? "",
    partition: category?.partition ?? 0,
    categoryUuid: category?.categoryUuid ?? "",
    interruptedByWeekends: `${category?.interruptedByWeekends ? "1" : "0"}`,
    interruptedByHolidays: `${category?.interruptedByHolidays ? "1" : "0"}`,
    styleUuid: category?.styleUuid,
  }

  return (
    <RetainableModal
      name="category"
      title={category ? "Edit Category" : "New Category"}
      onClose={handleDismiss}
      toggleKeepOpen={toggleKeepOpen}
      keepOpen={keepOpen}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <CategoryForm
        loading={loading || loadingCategories || loadingStyles}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        categories={categories}
        styles={styles}
        onEditTheme={handleEditTheme}
        onEditStyle={handleEditStyle}
      />
    </RetainableModal>
  )
}
