import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { cals } from "../actions"
import { calendarSelector } from "../selectors"

export const useCalendars = (subdomain: string) => {
  const dispatch = useDispatch()
  const request = async () => await dispatch(cals.requestCalendar(subdomain))
  const calendars = useSelector(calendarSelector.forOrganization)(subdomain)
  const { loading, refetch } = useRequest(request)
  return { loading, calendars, refetch }
}
