import { combineReducers } from "redux"
import mergeWith from "lodash/mergeWith"
import * as t from "./actionTypes"
import { isFalse } from "../utils/helpers"

function baseDomain(state = "", action) {
  switch (action.type) {
    case t.API_BASE_CHANGED:
      return action.baseDomain
    default:
      return state
  }
}

function protocol(state = "//", action) {
  switch (action.type) {
    case t.API_PROTOCOL_CHANGED:
      return action.protocol || state
    default:
      return state
  }
}

const defaultEntities = {
  metrics: {},
  connections: {},
  organizations: {},
  snapshots: {},
  calendars: {},
  groups: {},
  invitations: {},
  icons: {},
  events: {},
  items: {},
  categories: {},
  styles: {},
  permissions: {},
  holidays: {},
  reports: {},
  reportCalendars: {},
  setKeeperProjects: {},
}

/**
 * Maintain the from event but overwrite the interruptions.
 * @param  {Object} fromEvent An event in the entities slice of the current state.
 * @param  {Object} toEvent   A new event to use as the source to overwrite interruptions from.
 * @return {Object}           A copy of the existing event with the exception of updated interruptions.
 */
const overwriteInterruptionsForEvent = (fromEvent, toEvent) => ({
  ...fromEvent,
  interruptions: (toEvent && toEvent.interruptions) || fromEvent.interruptions,
})

/**
 * merge() is non destructive so we actually want to use object
 * assign when we get updated events. This is becuase the interruptions
 * are stored as an array of cached dates and we have no way to
 * flag them as isDeleted. The cache of interruptions simply would
 * persist the new dates if we did a merge. This method takes the
 * freshly merged state but then maps the events back to the state
 * using object.assign.
 * @param  {Object} newEntities An object containing updated event ewntities.
 * @param  {Object} state       The freshly merged redux state.
 * @return {Object}             A manipulated copy of the current redux state.
 */
const overwriteDestructiveEventAttributesForState = (newEntities, state) => {
  if (newEntities.events) {
    const newEvents = Object.keys(state.events)
      .map((key) =>
        overwriteInterruptionsForEvent(
          state.events[key],
          newEntities.events[key]
        )
      )
      .reduce(
        (acc, event) => Object.assign({}, acc, { [event.uuid]: event }),
        {}
      )
    return Object.assign({}, state, { events: newEvents })
  } else {
    return state
  }
}

/**
 * A custom merge implementation that simply overwrites any existing keys in the cache.
 * @param {Object} obj The existing redux cache.
 * @param {Object} src The new updates to merge to the existing cache
 * @returns {Object} The result of the two objects merged into one.
 */
const mergeCustomization = (obj, src) => {
  // To return to the default behavior you can revert to lodash.merge
  // return merge({}, obj, src)
  return { ...obj, ...src }
}

/**
 * A blanket reducer that merges all API results from the middleware into a
 * single object. It is expected that the updated state is a normalized entity
 * map so that a merge can non-destructively accumulate or update the local
 * cache.
 * @param  {Object} state The current state from the reducer.
 * @param  {Object} action An FSA action updating the state.
 * @return {Object} An updated cache of entities.
 */
const entities = (state = defaultEntities, action) => {
  if (action.type === t.LOGOUT_SUCCESS) {
    return defaultEntities
  } // Clear everything if the user logs out.
  if (action.payload && action.payload.entities) {
    const newEntities = action.payload.entities
    const result = mergeWith({}, state, newEntities, mergeCustomization)
    switch (action.type) {
      case t.DELETE_HOLIDAY_SUCCESS:
        return overwriteDestructiveEventAttributesForState(newEntities, result)
      default:
        return result
    }
  }
  return state
}

const extractAPIMeta = (action) => action.meta && action.meta.api

const fetching = (state = [], action) => {
  const api = extractAPIMeta(action)
  if (api && api.fetching) {
    return [...state, api.reqID]
  } else if (api && isFalse(api.fetching)) {
    return state.filter((req) => req !== api.reqID) || []
  }
  return state
}

const pendingInvitation = (state = {}, action) => {
  switch (action.type) {
    case t.LOADED_PENDING_INVITATION:
      return action.pendingInvitation || state
    case t.CREATE_USER_SUCCESS:
    case t.CLEARED_PENDING_INVITATION:
      return {}
    default:
      return state
  }
}

function needsPassword(state = false, action) {
  switch (action.type) {
    case t.FETCH_AUTH_TOKEN_EXPIRED:
      return action.payload.needsPassword
    case t.FETCH_AUTH_TOKEN_SUCCESS:
    case t.LOGOUT_SUCCESS:
      return false
    default:
      return state
  }
}

/* eslint-disable complexity */
function isAuthenticated(state = false, action) {
  switch (action.type) {
    case t.UPDATE_AUTH_STATUS:
      return action.payload.isAuthenticated
    case t.LOGOUT_FAILURE:
    case t.FETCH_AUTH_TOKEN_FAILURE:
    case t.LOGOUT_SUCCESS:
      return false
    default:
      return state
  }
}
/* eslint-enable complexity */

function superAdmin(state = false, action) {
  const payload = action.payload || action

  switch (action.type) {
    case t.AUTH_ROLE_UPDATE:
      return payload.superAdmin || null
    default:
      return state
  }
}

function admin(state = [], action) {
  switch (action.type) {
    case t.FETCH_CURRENT_USER_SUCCESS:
      return (
        Object.values(action.payload.entities.invitations)
          .filter((i) => i.accessLevel === "admin")
          .map((i) => i.subdomain) || []
      )
    default:
      return state
  }
}

function creator(state = [], action) {
  switch (action.type) {
    case t.FETCH_CURRENT_USER_SUCCESS:
      return (
        Object.values(action.payload.entities.invitations)
          .filter((i) => i.accessLevel === "creator")
          .map((i) => i.subdomain) || []
      )
    default:
      return state
  }
}

function currentUserId(state = null, action) {
  switch (action.type) {
    case t.FETCH_CURRENT_USER_SUCCESS:
      return action.payload.result
    case t.LOGOUT_SUCCESS:
      return null
    default:
      return state
  }
}

function userPermissions(state = {}, action) {
  switch (action.type) {
    case t.FETCH_CURRENT_USER_SUCCESS:
      return action.payload.entities.permissions || {}
    case t.LOGOUT_SUCCESS:
      return {}
    default:
      return state
  }
}

function downloadToken(state = "", action) {
  switch (action.type) {
    case t.DOWNLOAD_CALENDAR_SUCCESS:
      return action.payload.downloadToken || state
    case t.DOWNLOAD_CALENDAR_REQUEST:
      return ""
    default:
      return state
  }
}

function downloadUrl(state = "", action) {
  switch (action.type) {
    case t.DOWNLOAD_CALENDAR_SUCCESS:
      return action.payload.downloadUrl || state
    case t.DOWNLOAD_CALENDAR_REQUEST:
      return ""
    default:
      return state
  }
}

export default combineReducers({
  isAuthenticated,
  needsPassword,
  baseDomain,
  currentUserId,
  entities,
  protocol,
  fetching,
  pendingInvitation,
  admin,
  creator,
  superAdmin,
  downloadUrl,
  downloadToken,
  userPermissions,
})
