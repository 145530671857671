import { makePx } from "./px";
export var FONT = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif";
/**
 * Render text on a new path on an HTML 5 drawing context.
 * @param ctx The HTML canvas context to perform the operation on.
 * @param param1 Attributes of the label operation.
 */
export var label = function (ctx, _a) {
    var scale = _a.scale, x = _a.x, y = _a.y, _b = _a.labelSize, labelSize = _b === void 0 ? 12 : _b, _c = _a.textAlign, textAlign = _c === void 0 ? "left" : _c, _d = _a.fillStyle, fillStyle = _d === void 0 ? "#000000" : _d, _e = _a.font, font = _e === void 0 ? FONT : _e, text = _a.text, _f = _a.baseline, baseline = _f === void 0 ? "alphabetic" : _f;
    var px = makePx(scale);
    ctx.textBaseline = baseline;
    ctx.beginPath();
    ctx.font = px(labelSize) + "px " + font;
    ctx.fillStyle = fillStyle;
    ctx.textAlign = textAlign;
    ctx.fillText(text, px(x), px(y));
};
