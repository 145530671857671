import { useRequest, RequestConfig } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { items } from "../actions"
import { itemSelector, eventSelector } from "../selectors"

export const useItems = (
  subdomain: string,
  calendarId: string,
  config?: RequestConfig
) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(items.requestItem(subdomain, calendarId, null))
  const events = useSelector(eventSelector.forItem)
  const results = useSelector(itemSelector.forCalendar)(calendarId).map(
    (item: any) => ({
      ...item,
      getEvents: () => events(item.uuid, calendarId),
    })
  )
  const { loading, refetch } = useRequest(request, config)
  return { loading, items: results, refetch }
}
