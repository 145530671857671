import { combineReducers } from "redux"
import * as t from "./actionTypes"
import { actionTypes as f } from "redux-form"

function isFetching(state = false, action) {
  switch (action.type) {
    case t.LOADING_ORGS:
      return true
    case t.RECEIVED_ORGS:
      return false
    default:
      return state
  }
}

function activeField(state = "", action) {
  switch (action.type) {
    case f.FOCUS:
      return action.meta.field
    case f.BLUR:
      return ""
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  activeField,
})
