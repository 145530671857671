import { NAME } from "./constants"

export const s = (state) => state[NAME]
export const showDragUI = (state) => s(state).showDragUI
export const dragPositionMap = (state) => s(state).dragPositionMap
export const itemExplorerVisible = (state) => s(state).itemExplorerVisible
export const searchValue = (state) => s(state).searchValue
export const itemPreviewType = (state) => s(state).itemPreviewType
export const ignoreEvents = (state) => s(state).ignoreEvents
export const clipboardEvents = (state) => s(state).clipboardEvents
export const clipboardOrigin = (state) => s(state).clipboardOrigin
export const clipboardMode = (state) => s(state).clipboardMode
export const dateForSettingsMenu = (state) => s(state).dateForSettingsMenu
export const contextMenu = (state) => s(state).contextMenu
export const dragEventDateOffset = (state) => s(state).dragEventDateOffset
