import { useMemo } from 'react';
import throttle from 'lodash/throttle';
import { useEventListener } from './useEventListener';
export var useResize = function (callback, wait) {
    if (wait === void 0) { wait = 250; }
    var handleResize = useMemo(function () {
        return wait !== 0
            ? throttle(function (event) { return callback(event); }, wait)
            : function (event) { return callback(event); };
    }, [wait, callback]);
    useEventListener({
        type: 'resize',
        listener: handleResize,
    });
};
