import React from "react"
import PropTypes from "prop-types"
import styles from "./SelectGroup.module.css"

const View = ({ children }) => (
  <div className={styles.selectGroupContainer}>
    <ul className={styles.selectGroup}>{children}</ul>
  </div>
)

View.propTypes = {
  children: PropTypes.node,
}

const Button = ({ active, children, onClick }) => (
  <li className={active && styles.selectGroupActive}>
    <button className={styles.selectButton} type="button" onClick={onClick}>
      {children}
    </button>
  </li>
)

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export { View, Button }
