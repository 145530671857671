var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { DateTime } from "luxon";
import { Layer, Line, Rect, Text } from "react-konva";
import { range } from "lodash";
import { getBeginningOfMonth, getEndOfMonth, getModeStyles, isBeginningOfWeek, isDateSelected, isWeekend, } from "./utils";
var SCALE = window.devicePixelRatio;
var FONT = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif";
var HEADER_HEIGHT = 30;
var OFFSET = 15;
var Y_AXIS_OFFSET = 3;
var STROKE_WIDTH = 1;
var HEIGHT_OFFSET = Y_AXIS_OFFSET + 2;
var DASH_SIZE = [4, 4];
export var GanntMonth = function (_a) {
    var _b = _a.borderColor, borderColor = _b === void 0 ? "rgba(0, 0, 0, 0.1)" : _b, _c = _a.cellBackgroundColor, cellBackgroundColor = _c === void 0 ? "#fff" : _c, _d = _a.inactiveCellBackgroundColor, inactiveCellBackgroundColor = _d === void 0 ? "rgba(168, 156, 168, 0.17)" : _d, labelColor = _a.labelColor, secondaryLabelColor = _a.secondaryLabelColor, _e = _a.hoverColor, hoverColor = _e === void 0 ? "#76b4f719" : _e, _f = _a.selectedColor, selectedColor = _f === void 0 ? "#4e83bc" : _f, startDate = _a.startDate, currentDate = _a.currentDate, selectedDate = _a.selectedDate, handleSelectDate = _a.onSelect, handleContextMenu = _a.onContextMenu, _g = _a.width, width = _g === void 0 ? 600 : _g, _h = _a.height, height = _h === void 0 ? 100 : _h, _j = _a.x, x = _j === void 0 ? 0 : _j, _k = _a.y, y = _k === void 0 ? 0 : _k, _l = _a.darkDays, darkDays = _l === void 0 ? {} : _l, _m = _a.scale, scale = _m === void 0 ? SCALE : _m, _o = _a.renderHeader, renderHeader = _o === void 0 ? false : _o, _p = _a.mode, mode = _p === void 0 ? "months" : _p;
    var start = getBeginningOfMonth(startDate);
    var end = getEndOfMonth(startDate);
    var days = end.diff(start, "days").days + 1;
    var cellWidth = width / days;
    var handleOnDayClick = function (date) { return function () {
        if (handleSelectDate) {
            handleSelectDate === null || handleSelectDate === void 0 ? void 0 : handleSelectDate(date);
        }
    }; };
    var handleOnMouseOver = function (evt) {
        var _a;
        ;
        (_a = evt.currentTarget) === null || _a === void 0 ? void 0 : _a.fill(hoverColor);
    };
    var handleOnMouseOut = function (fill) { return function (evt) {
        var _a;
        ;
        (_a = evt.currentTarget) === null || _a === void 0 ? void 0 : _a.fill(fill);
    }; };
    var handleOnContextMenu = function (date) { return function (_a) {
        var evt = _a.evt;
        evt.stopPropagation();
        evt.preventDefault();
        handleContextMenu === null || handleContextMenu === void 0 ? void 0 : handleContextMenu({ x: evt.clientX, y: evt.clientY, date: date });
    }; };
    return (_jsxs(Layer, __assign({ x: x, y: y, width: width, height: height }, { children: [range(days).map(function (_, i) {
                var _a;
                var d = start.plus({ days: i });
                var day = d.weekday;
                var date = d.toISO();
                var _b = getModeStyles(d, mode), top = _b.top, bottom = _b.bottom;
                var labelX = i * cellWidth;
                var labelY = renderHeader ? HEADER_HEIGHT + OFFSET : OFFSET;
                var isSelected = isDateSelected(d, selectedDate);
                var dark = darkDays[d.toUTC().toFormat("yyyy-MM-dd")];
                var weekend = isWeekend(day);
                var x = i * cellWidth;
                var inactive = weekend || ((_a = dark === null || dark === void 0 ? void 0 : dark.length) !== null && _a !== void 0 ? _a : -1) > 0;
                var fill = inactive
                    ? inactiveCellBackgroundColor
                    : cellBackgroundColor;
                var rectX = isSelected ? x + STROKE_WIDTH : x;
                var rectY = isSelected && renderHeader ? HEADER_HEIGHT + Y_AXIS_OFFSET : 0;
                var rectWidth = isSelected
                    ? cellWidth - STROKE_WIDTH * 2
                    : cellWidth;
                var rectHeight = isSelected && renderHeader
                    ? height - HEADER_HEIGHT - HEIGHT_OFFSET
                    : height;
                var strokeWidth = isSelected ? STROKE_WIDTH * scale : 1;
                var stroke = isSelected ? selectedColor : borderColor;
                var dash = isSelected ? DASH_SIZE : [];
                return (_jsxs(Fragment, { children: [_jsx(Rect, { fill: fill, x: rectX, y: rectY, width: rectWidth, height: rectHeight, stroke: stroke, strokeWidth: strokeWidth, dash: dash, onClick: handleOnDayClick(date), onMouseOver: handleOnMouseOver, onMouseOut: handleOnMouseOut(fill), onContextMenu: handleOnContextMenu(date) }, void 0), _jsx(Text, { width: cellWidth, fontSize: top.fontSize, fontStyle: top.fontStyle, fontFamily: FONT, fill: labelColor, text: top.date, x: labelX, y: labelY, align: "center" }, void 0), _jsx(Text, { width: cellWidth, fontSize: bottom.fontSize, fontStyle: bottom.fontStyle, fontFamily: FONT, fill: secondaryLabelColor, text: bottom.date, x: labelX, y: labelY + OFFSET, align: "center" }, void 0), mode === "weeks" && isBeginningOfWeek(day) ? (_jsxs(_Fragment, { children: [_jsx(Line, { x: rectX, y: 0, points: [0, 0, 0, 0, 0, height], stroke: "#777" }, void 0), _jsx(Text, { text: String(d.weekNumber), x: rectX + 5, y: labelY - 5 }, void 0)] }, void 0)) : null] }, rectX + stroke));
            }), renderHeader ? (_jsxs(Fragment, { children: [_jsx(Rect, { fill: "#fff", width: width, height: HEADER_HEIGHT, shadowBlur: 1, shadowColor: "#333" }, void 0), _jsx(Text, { y: 10, width: width, align: "center", fontFamily: FONT, text: DateTime.fromISO(startDate, { zone: "utc" })
                            .toFormat("MMMM yyyy")
                            .toUpperCase() }, void 0)] }, void 0)) : null] }), void 0));
};
