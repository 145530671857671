var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
var LIST = "flex flex-col py-2";
var LIST_ITEM = "flex flex-row py-1 mb-1 border-b border-gray-200 text-gray-800 text-sm";
var LIST_ITEM_LABEL = "flex truncate my-auto";
var ICON = "flex text-md text-blue-600 my-auto mr-2";
export var List = function (_a) {
    var items = _a.items, className = _a.className;
    return (_jsx("ul", __assign({ className: clsx(LIST, className) }, { children: items.map(function (item) { return (_jsxs("li", __assign({ className: LIST_ITEM }, { children: [item.icon ? (_jsx(FontAwesomeIcon, { icon: item.icon, className: ICON }, void 0)) : null, _jsx("span", __assign({ className: LIST_ITEM_LABEL }, { children: item.label }), void 0), item.accessory ? (_jsx("div", __assign({ className: "flex ml-auto" }, { children: item.accessory() }), void 0)) : null] }), item.id)); }) }), void 0));
};
