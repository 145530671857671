import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { PropTypes } from "prop-types"
import { getItem } from "../../utils/tokenStore"
import { getCalendar, getOrganization } from "../../api"
import { VisibleOnlyCreator, VisibleOnlyNonCreator } from "../../login"
import { Placeholder } from "../../shared"
import { Button } from "ui"
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * Renders a nice onboarding view prompting the user to create
 * a new calendar if they have the required permissions. Otherwise,
 * we inform the user they currently do not have access to any
 * calendars at this time.
 */
class CalendarBlankSlateView extends Component {
  componentWillMount() {
    this.handleCalendarForRoute()
  }

  getSubdomain() {
    return this.props.match.params.subdomain
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.subdomain !== prevProps.match.params.subdomain
    ) {
      this.handleCalendarForRoute()
    }
  }

  handleCalendarForRoute() {
    this.props.loadCalendars(this.getSubdomain(), "").then((action) => {
      const result = action.payload && action.payload.result
      if (result && result.length > 0) {
        const inferredCalendarId = parseInt(
          getItem(`${this.getSubdomain()}CalendarId`),
          0
        )
        const calendarId =
          (result.includes(inferredCalendarId) && inferredCalendarId) ||
          result[0]
        this.props.history.replace(
          `/org/${this.getSubdomain()}/cal/${calendarId}`
        )
      } else {
        this.handleMissingCalendar()
      }
    })
    this.props.loadCurrentUser()
  }

  handleMissingCalendar() {
    this.props.loadOrganization(this.getSubdomain()).then((action) => {
      if (
        action.payload &&
        action.payload.result &&
        action.payload.result.error
      ) {
        this.props.history.replace("/404")
      }
    })
  }

  showLoadingIndicator() {
    const { fetching, match } = this.props
    return (
      fetching(getCalendar(match.params.subdomain)) ||
      fetching(getOrganization(match.params.subdomain))
    )
  }

  render() {
    if (this.showLoadingIndicator()) {
      return <Placeholder.Loading />
    }
    return this.renderBlankSlate(this.props.match.params.subdomain)
  }

  renderBlankSlate(subdomain) {
    const { Container } = Placeholder

    const AdminBlankSlate = () => (
      <VisibleOnlyCreator subdomain={subdomain}>
        <div>
          <FontAwesomeIcon icon={faCalendarAlt} size="4x" />
          <h1>Create Your First Calendar</h1>
          <p>
            There are currently no calendars for your Company. Create a calendar
            to get started.
          </p>
          <Button
            align="center"
            onClick={() => this.props.history.push(`/org/${subdomain}/cal/new`)}
          >
            Create Calendar
          </Button>
        </div>
      </VisibleOnlyCreator>
    )

    const UserBlankSlate = () => (
      <VisibleOnlyNonCreator subdomain={subdomain}>
        <div>
          <FontAwesomeIcon icon={faCalendarAlt} size="4x" />
          <h1>You Do Not Have Access to Any Calendars</h1>
          <p>
            No calendars are linked to your account. Please contact whomever
            invited you to provide you with access to a calendar.
          </p>
        </div>
      </VisibleOnlyNonCreator>
    )

    return (
      <Container theme="modal">
        <AdminBlankSlate />
        <UserBlankSlate />
      </Container>
    )
  }
}

CalendarBlankSlateView.propTypes = {
  /**
   * A redux action creator mapped to dispatch which is intended
   * to asynchronously load calendars from the API.
   */
  loadCalendars: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which is intended
   * to asynchronously load an organization from the API.
   */
  loadOrganization: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which is intended
   * to asynchronously load the current user from the API.
   */
  loadCurrentUser: PropTypes.func.isRequired,

  /**
   * A redux selector intended to determine whether or not a given
   * action is currently fetching asynchronously.
   */
  fetching: PropTypes.func.isRequired,

  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(CalendarBlankSlateView)
