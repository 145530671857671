import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBar.module.css"

const ToolBar = ({ children }) => (
  <div className={styles.toolBar}>{children}</div>
)

ToolBar.propTypes = {
  children: PropTypes.node,
}

export default ToolBar
