import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { CollaboratorWidget, Dialog } from "ui"
import { CollaboratorModal } from "../../modals"
import {
  calendarSelector,
  requestInvitation,
  invitationSelector,
  useDeletePermission,
} from "../../api"

interface CalendarAccessWidgetTypes {
  /**
   * The identifier for the org
   */
  subdomain: string
  /**
   * The id of the calendar to present permissions for.
   */
  calendarId: string
}

interface Permission {
  id: number
  read: boolean
  write: boolean
  manage: boolean
  invitationId: number
}

const noAccess = ({ read, write, manage }: Permission) =>
  !read && !write && !manage

export const CalendarAccessWidget: React.FC<CalendarAccessWidgetTypes> = ({
  subdomain,
  calendarId,
}) => {
  const [deletePermission] = useDeletePermission(subdomain)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(requestInvitation(subdomain))
  }, [dispatch, subdomain])

  const [permissionToDelete, setPermissionToDelete] = React.useState<
    number | null
  >(null)
  const [permissionToCreate, setPermissionToCreate] = React.useState<{
    invitationId?: number
    name?: string
    email?: string
  } | null>(null)
  const [permissionToEdit, setPermissionToEdit] = React.useState<number | null>(
    null
  )

  const clearModals = () => {
    setPermissionToDelete(null)
    setPermissionToCreate(null)
    setPermissionToEdit(null)
    setPermissionToDelete(null)
  }

  const invitations = useSelector(invitationSelector.forOrganization)(subdomain)
  const permissions = useSelector(calendarSelector.appliedPermissions)(
    calendarId
  )

  const handleCreateInvitation = async ({
    name,
    email,
  }: {
    name?: string
    email?: string
  }) => {
    clearModals()
    setPermissionToCreate({
      name,
      email,
    })
  }

  const handleCreatePermissionForInvitation = (invitationId: number | null) => {
    clearModals()
    const i = invitations.find((i: any) => i.id === invitationId)
    if (i) {
      setPermissionToCreate({
        name: i?.name,
        email: i?.email,
        invitationId: i?.id,
      })
    }
  }

  const handlePermissionToDelete = (permissionId: number) => {
    clearModals()
    setPermissionToDelete(permissionId)
  }

  const handleEditCollaborator = (permissionId: number) => {
    clearModals()
    setPermissionToEdit(permissionId)
  }

  const permissionName = (pId: number) => {
    const permission = permissions.find((p: Permission) => p.id === pId)
    const invitation = invitations.find(
      (i: any) => i.id === permission?.invitationId
    )
    if (invitation) {
      return invitation.name
    }
    return "Collaborator"
  }

  const activePermissions = permissions.filter((p: Permission) => !noAccess(p))
  const activeCollaborators = activePermissions.map((p: Permission) => ({
    name: invitations.find((i: any) => i.id === p.invitationId)?.name,
    permissionId: p.id,
    read: p.read,
    write: p.write,
    manage: p.manage,
  }))

  const hasAccess = (i: any) =>
    activePermissions.map((p: any) => p.invitationId).includes(i.id)

  const availableMatches = invitations
    .filter(
      (i: any) => !["admin", "super"].includes(i.accessLevel) && !hasAccess(i)
    )
    .map((i: any) => ({
      id: i.id,
      name: i.name,
      email: i.email,
    }))

  const editablePermissionDetails =
    permissionToEdit &&
    permissions.find((p: Permission) => p.id === permissionToEdit)
  const existingInvitation = editablePermissionDetails
    ? invitations.find(
        (i: any) => i.id === editablePermissionDetails.invitationId
      )
    : null

  const handleDeletePermission = async () => {
    if (permissionToDelete) {
      const permission = permissions.find(
        (p: any) => p.id === permissionToDelete
      )
      await deletePermission({
        params: {
          permissionId: permissionToDelete.toString(),
          invitationId: permission.invitationId,
        },
      })
    }
    clearModals()
  }

  return (
    <>
      <CollaboratorWidget
        invitations={availableMatches}
        collaborators={activeCollaborators}
        onCreateInvitation={handleCreateInvitation}
        onSelectExistingInvitation={handleCreatePermissionForInvitation}
        onDeleteCollaborator={handlePermissionToDelete}
        onEditCollaborator={handleEditCollaborator}
        className="m-1"
      />
      {permissionToCreate && (
        <CollaboratorModal
          onClose={clearModals}
          invitationId={permissionToCreate.invitationId}
          initialValues={{
            name: permissionToCreate.name ?? "",
            email: permissionToCreate.email ?? "",
            permission: "read",
          }}
          subdomain={subdomain}
          calendarId={parseInt(`${calendarId}`)}
          draggable
          open
        />
      )}
      {editablePermissionDetails && (
        <CollaboratorModal
          onClose={clearModals}
          invitationId={editablePermissionDetails.invitationId}
          permissionId={editablePermissionDetails.id}
          initialValues={{
            name: existingInvitation.name ?? "",
            email: existingInvitation.email ?? "",
            permission: editablePermissionDetails.manage
              ? "manage"
              : editablePermissionDetails.write
              ? "write"
              : "read",
          }}
          subdomain={subdomain}
          calendarId={parseInt(`${calendarId}`)}
          draggable
          open
        />
      )}
      {permissionToDelete && (
        <Dialog
          title={`Remove ${permissionName(permissionToDelete).split(" ")[0]}?`}
          message={`Are you sure you want to remove ${permissionName(
            permissionToDelete
          )} from this calendar?`}
          onClose={clearModals}
          onCancel={clearModals}
          onConfirm={handleDeletePermission}
          open
          draggable
        />
      )}
    </>
  )
}
