import { useState, useCallback } from 'react';
import { useResize } from './useResize';
export var useWindowSize = function (wait) {
    if (wait === void 0) { wait = 250; }
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), windowSize = _a[0], setWindowSize = _a[1];
    var getWindowSize = useCallback(function () { return ({
        width: window.innerWidth,
        height: window.innerHeight,
    }); }, []);
    useResize(function () {
        setWindowSize(getWindowSize);
    }, wait);
    return windowSize;
};
