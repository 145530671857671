import isEmail from "is-email"

export const validateLoginCredentials = (creds) => {
  const nameLength = creds.username.length
  const passLength = creds.password.length
  const hasInvalidUsername = !isEmail(creds.username) && nameLength > 0
  const hasInvalidPassword = passLength < 6 && passLength > 0
  const hasCredentials = nameLength > 0 && passLength > 0
  const hasValidCredentials =
    !hasInvalidUsername && !hasInvalidPassword && hasCredentials
  const usernameErrorMessage = hasInvalidUsername
    ? "Must be a valid email address."
    : ""
  const passwordErrorMessage = hasInvalidPassword
    ? "Password is too short (minimum is 6 characters)"
    : ""

  return {
    hasInvalidUsername,
    hasInvalidPassword,
    hasValidCredentials,
    hasCredentials,
    usernameErrorMessage,
    passwordErrorMessage,
  }
}

export const validateRegistrationCredentials = (creds) => {
  const loginCreds = validateLoginCredentials(creds)
  const hasConfirm = creds.confirm.length > 0
  const hasUnconfirmedPassword = hasConfirm && creds.confirm !== creds.password
  const hasValidCredentials =
    loginCreds.hasValidCredentials && hasConfirm && !hasUnconfirmedPassword
  const confirmationErrorMessage = hasUnconfirmedPassword
    ? "Confirmation does not match Password."
    : ""
  return {
    ...loginCreds,
    hasValidCredentials,
    hasUnconfirmedPassword,
    confirmationErrorMessage,
    hasCredentials: loginCreds.hasCredentials && hasConfirm,
  }
}

export const validatedCredentials = (creds) => {
  if (typeof creds.confirm === "undefined") {
    return validateLoginCredentials(creds)
  }
  return validateRegistrationCredentials(creds)
}

export const validateEmail = (email) => isEmail(email)
