var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner } from "./Spinner";
import { concatStyles } from "utils";
var ABSOLUTE_OVERLAY_STYLES = "flex h-full w-full absolute top-0 left-0 bg-opacity-75 z-30";
export var AbsoluteOverlay = function (_a) {
    var bgClassName = _a.bgClassName;
    return (_jsx("div", __assign({ className: concatStyles(ABSOLUTE_OVERLAY_STYLES, bgClassName !== null && bgClassName !== void 0 ? bgClassName : "bg-white") }, { children: _jsx(Spinner, { size: "4x", className: "m-auto" }, void 0) }), void 0));
};
