import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router"
import { useSetWeekCanvasState, useWeekCanvasState } from "ui"
import { DateTime } from "luxon"

interface CalendarDateListenerProps {
  subdomain: string
  currentCalendarId?: string
  currentReportId?: string
  currentSnapshotId?: string
  viewId: string
  initialDate?: string
  useTimeout?: boolean
}

export const CalendarDateListener: React.FC<CalendarDateListenerProps> = ({
  subdomain,
  currentCalendarId,
  initialDate,
  viewId,
  currentReportId,
  currentSnapshotId,
  useTimeout,
}) => {
  const { scrollCursorDate } = useWeekCanvasState()
  const setWeekCanvasState = useSetWeekCanvasState()
  const [lastAdjustedDate, setLastAdjustedDate] = useState<DateTime | null>(
    null
  )
  const [lastViewMode, setLastViewMode] = useState<string | null>(viewId)
  const history = useHistory()

  useEffect(() => {
    if (!lastAdjustedDate || lastViewMode !== viewId) {
      setTimeout(() => {
        const initializedDate = initialDate
          ? DateTime.fromISO(initialDate, { zone: "utc" })
          : DateTime.utc()
        setWeekCanvasState((state) => ({
          ...state,
          anchorDate: initializedDate,
        }))
        setLastAdjustedDate(initializedDate)
        setLastViewMode(viewId)
      }, 0)
    }
  }, [
    lastAdjustedDate,
    setWeekCanvasState,
    initialDate,
    setLastAdjustedDate,
    lastViewMode,
    viewId,
    setLastViewMode,
  ])

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(
      () => {
        if (
          scrollCursorDate &&
          lastAdjustedDate?.toISODate() !== scrollCursorDate?.toISODate()
        ) {
          if (currentSnapshotId && currentCalendarId) {
            history.push(
              `/org/${subdomain}/cal/${currentCalendarId}/snapshot/${currentSnapshotId}/start/${scrollCursorDate?.toISODate()}/view/${viewId}`
            )
          } else if (currentCalendarId) {
            history.push(
              `/org/${subdomain}/cal/${currentCalendarId}/start/${scrollCursorDate?.toISODate()}/view/${viewId}`
            )
          } else if (currentReportId) {
            history.push(
              `/org/${subdomain}/reports/${currentReportId}/start/${scrollCursorDate?.toISODate()}/view/${viewId}`
            )
          }
        }
      },
      useTimeout ? 1000 : 0
    )
  }, [
    scrollCursorDate,
    setLastAdjustedDate,
    lastAdjustedDate,
    currentCalendarId,
    currentReportId,
    currentSnapshotId,
    history,
    subdomain,
    useTimeout,
    viewId,
  ])

  return <></>
}
