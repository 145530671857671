var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartBroken } from "@fortawesome/pro-solid-svg-icons";
import { swallow } from "utils";
var styles = {
    headline: "text-sm font-bold text-gray-700",
    body: "text-sm text-gray-600",
    item: "border-gray-300 border rounded p-2 my-1 relative flex hover:bg-lochivar-lighter/25",
    itemHeading: "text-sm font-bold mb-0",
    itemDescription: "text-sm m-0",
    invisibleButton: "opacity-0 absolute z-10 inset-0",
    icon: "w-10 mr-2 h-auto block my-auto",
    list: "flex flex-col mb-4",
};
var AppDefinitionOption = function (_a) {
    var app = _a.app, handleSelect = _a.onSelect;
    return (_jsxs("article", __assign({ className: styles.item }, { children: [_jsx("img", { src: app.icon, className: styles.icon }, void 0), _jsxs("ul", { children: [_jsx("li", { children: _jsx("h3", __assign({ className: styles.itemHeading }, { children: app.name }), void 0) }, void 0), _jsx("li", { children: _jsx("p", __assign({ className: styles.itemDescription }, { children: app.description }), void 0) }, void 0)] }, void 0), _jsxs("button", __assign({ className: styles.invisibleButton, onClick: handleSelect }, { children: ["Connect to ", app.name] }), void 0)] }), void 0));
};
export var AppsWidget = function (_a) {
    var className = _a.className, style = _a.style, _b = _a.availableApps, availableApps = _b === void 0 ? [] : _b, _c = _a.connectedApps, connectedApps = _c === void 0 ? [] : _c, handleSelectApp = _a.onSelectApp;
    var handleSelectClick = function (app, action) {
        return swallow(function () {
            handleSelectApp === null || handleSelectApp === void 0 ? void 0 : handleSelectApp(app, action);
        });
    };
    return (_jsxs("section", __assign({ className: className, style: style }, { children: [connectedApps.length > 0 && (_jsxs(_Fragment, { children: [_jsx("h3", __assign({ className: styles.headline }, { children: "Connected Apps" }), void 0), _jsx("ul", __assign({ className: styles.list }, { children: connectedApps.map(function (app) { return (_jsx("li", __assign({ className: "flex-1" }, { children: _jsx(AppDefinitionOption, { app: app, onSelect: handleSelectClick(app, "modify") }, void 0) }), app.name)); }) }), void 0)] }, void 0)), availableApps.length > 0 && (_jsxs(_Fragment, { children: [_jsx("h3", __assign({ className: styles.headline }, { children: "Available Apps" }), void 0), _jsx("ul", __assign({ className: styles.list }, { children: availableApps.map(function (app) { return (_jsx("li", __assign({ className: "flex-1" }, { children: _jsx(AppDefinitionOption, { app: app, onSelect: handleSelectClick(app, "connect") }, void 0) }), app.name)); }) }), void 0)] }, void 0)), availableApps.length + connectedApps.length === 0 && (_jsxs("article", __assign({ className: "text-lochivar-dark text-center" }, { children: [_jsx(FontAwesomeIcon, { icon: faHeartBroken, size: "4x" }, void 0), _jsx("h3", __assign({ className: styles.itemHeading }, { children: "No Apps Found!" }), void 0), _jsx("p", __assign({ className: styles.itemDescription }, { children: "We're sorry but it looks like we weren't able to find any apps available to connect with your calendar." }), void 0)] }), void 0))] }), void 0));
};
AppsWidget.displayName = "Widgets.AppsWidget";
