import { camelizeKeys } from "humps"
import { SubmissionError } from "redux-form"
import isEmail from "is-email"
import { requestInvitationValidation } from "../api"

export const asyncValidate = (values, dispatch) => {
  const { subdomain, ...valsToPost } = values
  return dispatch(requestInvitationValidation(subdomain, valsToPost)).then(
    (action) => {
      if (action.payload && action.payload.errors && !action.payload.valid) {
        const error = camelizeKeys(action.payload.errors)
        throw error
      }
    }
  )
}

export const validate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  if (!formProps.email || !isEmail(formProps.email)) {
    errors.email = "Please enter a valid email for this invitation"
  }
  return errors
}

export const submitValidate = (result) => {
  if (result.error) {
    const errors = camelizeKeys(result.payload.errors).map
    throw new SubmissionError(
      Object.assign({}, errors, { _error: "Invitation could not be sent!" })
    )
  }
  return result
}
