import React, { useState, useRef } from "react"
import clsx from "clsx"
import { DropDownItem, BarDropDownList } from "ui"
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export enum MenuItem {
  Rename = "Name this Version",
  Restore = "Restore this Version",
  Duplicate = "Make a Copy",
  Delete = "Delete Version",
}

const styles = {
  container: "p-2 px-4 h-full",
  list: "flex flex-col",
  item: "border-b border-gray-300 flex flex-col",
  button: "cursor-pointer rounded py-2 px-1 flex flex-col flex-grow",
  itemName:
    "font-semibold text-sm bg-opacity-0 bg-transparent focus:border hover:border border-gray-300 p-1 -mt-1",
  itemDescription: "text-xs px-1",
  selected: "bg-lochivar bg-opacity-25",
  notSelected: "hover:bg-lochivar-lighter/20",
  menuIcon: "my-1 py-2 px-4 mr-1",
}

interface CalendarHistoryItemProps {
  selected: boolean
  name: string
  description: string
  onSelect: React.MouseEventHandler
  onAction?: (action: MenuItem) => void
  onRename?: (newName: string) => void
}

export const CalendarHistoryItem: React.FC<CalendarHistoryItemProps> = ({
  selected,
  name: initialName,
  description,
  onSelect: handleSelect,
  onAction: handleAction,
  onRename: handleRename,
}) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const [hover, setHover] = useState(false)
  const [name, setName] = useState(initialName)
  const handleMouseOver =
    (over: boolean): React.MouseEventHandler =>
    () => {
      setHover(over)
    }
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.currentTarget.value)
  }
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.setSelectionRange(0, 99, "forward")
  }
  const updateName = () => {
    if (name !== initialName) {
      handleRename?.(name)
    }
  }
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    updateName()
  }
  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()
    inputEl.current?.blur()
    updateName()
  }
  return (
    <li
      className={clsx(
        styles.item,
        selected ? styles.selected : styles.notSelected
      )}
      onMouseOut={handleMouseOver(false)}
      onMouseOver={handleMouseOver(true)}
    >
      <ul className="flex flex-grow">
        <li className="flex flex-grow">
          <button
            className={styles.button}
            type="button"
            onClick={handleSelect}
          >
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className={styles.itemName}
                name="snapshotName"
                value={name}
                onChange={handleChange}
                ref={inputEl}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </form>
            <h6 className={styles.itemDescription}>{description}</h6>
          </button>
        </li>
        {handleAction ? (
          <li>
            <BarDropDownList
              name={`optionsFor${name}`}
              position="right"
              onDismiss={() => setHover(false)}
              toggle={(
                _active: boolean,
                handleToggle: React.MouseEventHandler
              ) => (
                <button
                  className={clsx(
                    styles.menuIcon,
                    hover ? "opacity-100" : "opacity-0"
                  )}
                  onClick={(e) => {
                    handleToggle(e)
                    handleSelect(e)
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </button>
              )}
            >
              {(dismiss) =>
                [MenuItem.Rename, MenuItem.Restore, MenuItem.Duplicate].map(
                  (item) => (
                    <DropDownItem
                      key={item}
                      onClick={() => {
                        if (item === MenuItem.Rename) {
                          inputEl.current?.focus()
                        } else {
                          handleAction(item)
                        }
                        dismiss()
                      }}
                      item={{
                        id: item,
                        label: item,
                      }}
                    />
                  )
                )
              }
            </BarDropDownList>
          </li>
        ) : null}
      </ul>
    </li>
  )
}

CalendarHistoryItem.displayName = "CalendarHistoryItem"
