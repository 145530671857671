import React, { useRef } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { DateTime } from "luxon"
import Week from "../containers/Week.react"
import styles from "./MonthView.module.css"
import { WeekView, DateMap, WeekViewHTML, useResizeObserver } from "ui"
import { WeekHeading } from "./WeekHeading"
import { iconSelector, useEventsMappedToRows } from "../../api"
import { ItemTypes as ExplorerItemTypes } from "../../itemExplorer"
import { ItemTypes } from "../constants"
import clsx from "clsx"

const MIN_WEEK_HEIGHT = 32

export interface MonthViewProps {
  /**
   * Indicates whether or not the calendar days should be selectable
   * as opposed to the calendar events.
   */
  useDaySelectMode: boolean

  /**
   * A callback that is called when a month day view is selected
   * by the user.
   */
  handleDaySelection: (date: string) => void

  /**
   * A callback that generates a momentJS object representing
   * the first date in the calendar. This is typically the
   * first day of the first week in the month and this often
   * may be one of the last few days of the prior month.
   */
  getStartMoment: () => any

  /**
   * Indicates whether or not the view should render in a
   * simplified format due to limited screen real estate.
   */
  simplified?: boolean

  /**
   * Indicates whether or not the view should render in a
   * an extremely simplified format due to limited screen
   * real estate.
   */
  limited?: boolean

  /**
   * The amount of weeks to render for the given month.
   */
  weeks: number

  /**
   * The id of the calendar to be rendered by this view.
   */
  calendarIds: number[]

  /**
   * The id of the snapshot to be rendered by this view.
   */
  snapshotId?: string

  /**
   * An event handler that accepts an event ID to trigger an
   * edit modal for an event.
   */
  handleEditEvent: (id: string) => void

  /**
   * An event handler that accepts a moment representing a
   * week to display.
   */
  handleSelectWeek: (date: string) => void

  /**
   * An event handler that accepts a moment representing a
   * month to display.
   */
  handleSelectMonth: (date: string) => void

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function creates a calendar event against the API.
   */
  onItemDrop: (
    type: string,
    uuid: string,
    calendarId: number,
    date: string
  ) => void

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function creates a calendar event against the API.
   */
  onItemDropHover: (
    type: string,
    id: string,
    calendarId: number,
    start: boolean,
    date: string
  ) => void

  /**
   * An action creator that handles updates to an event expand
   * drag and drop event.
   */
  expandEventPreview: any

  /**
   * An action creator that handles updates to an event expand
   * drag and drop event.
   */
  handleContextMenu: (params: any) => void

  /**
   * A callback passed down into the event views to handle
   * any possible mouse activity from the user.
   */
  handleMouseDown: React.MouseEventHandler

  /**
   * A callback that is passed down into event views to handle
   * the event they are selected by the user.
   */
  handleSelectedEvent: (id: string, calendarId: number) => void

  /**
   * The date currently selected on the calendar.
   */
  selectedDate: any

  /**
   * Ensures that editing cannot be done with this view if set to true.
   */
  readOnly: boolean

  /**
   * An array of holidays that apply to the current calendar.
   */
  darkDays: DateMap

  /**
   * An optional ovverride to replace the default font size for the month label.
   */
  monthNameFontSizeOverride?: string

  /**
   * Affects formatting and sizing of events for maximum use of space
   * while printing.
   */
  forPrint?: boolean

  /**
   * An optional override to the default font size for the day labels.
   */
  hideLabels?: boolean

  /**
   * The background color of a weekend cell.
   */
  weekendColor?: string

  /**
   * The background color of a holiday cell.
   */
  holidayColor?: string

  /**
   * The background color of an adjacent month's cell.
   */
  cutoffColor?: string

  /**
   * The id of the parent report if one is being rendered. This is null when rendering the standard calendar.
   */
  reportId?: string

  /**
   * Any event we want to manually inject into the view. These events will be ignored if they
   * are not within the date range
   */
  specialEvents?: any[]

  /**
   * If true a min-height will be applied to the month.
   */
  enforceStrictHeight?: boolean

  /**
   * If true the month will be rendered with a border.
   * This is useful for printing.
   * @default false
   */
  highContrast?: boolean

  /**
   * If true the weeks will be optimized for maximal content based
   * on the number of rows in each week.
   */
  weighted?: boolean
}

/**
 * Renders all of the events for a specified calendar in
 * a one month format. We actually render 6 weeks to always
 * provide a glimpse into the next month and to prevent a
 * jarring appearance where some longer or offset months start
 * later in the week or end earlier in the week.
 */
const MonthView: React.FC<MonthViewProps> = (props) => {
  const container = useRef<HTMLDivElement | null>(null)
  const { size } = useResizeObserver<HTMLDivElement>({
    element: container,
    wait: 10,
  })
  const findIconsForCalendars = useSelector(iconSelector.forCalendar)
  const findIconsForSnapshot = useSelector(iconSelector.forSnapshot)
  const startDate = props
    .getStartMoment?.()
    .startOf("month")
    .startOf("week")
    .toISOString()
  const endDate = props
    .getStartMoment?.()
    .endOf("month")
    .startOf("week")
    .toISOString()
  const eventWeeks = useEventsMappedToRows({
    calendarIds: props.calendarIds.map((i) => i.toString()),
    startDate,
    endDate,
  })
  const rowCounts = eventWeeks.reduce((acc, week) => {
    return [
      ...acc,
      week.events.reduce((acc, partition) => acc + partition.length, 0),
    ]
  }, [] as number[])
  const totalRows = rowCounts.reduce(
    (acc, count) => acc + Math.max(count, 2),
    0.0
  )
  const weights = rowCounts.map((count) => Math.max(count, 2) / totalRows)
  const heights = weights.map((weight) =>
    Math.max(weight * size.height, MIN_WEEK_HEIGHT)
  )
  const useWeightedLayout =
    props.weighted && rowCounts.filter((r) => r > 5).length
  const travelDates = (
    props.snapshotId
      ? findIconsForSnapshot(props.snapshotId)
      : findIconsForCalendars(props.calendarIds)
  ).map((i: any) => DateTime.fromISO(i.startDate, { zone: "utc" }).toISO())

  /**
   * Renders a heading with current month.
   *
   * @return {ReactClass} div  representing the header view.
   */
  const renderMonthHeading = () => {
    const {
      simplified,
      limited,
      forPrint,
      handleSelectMonth,
      getStartMoment,
      highContrast,
    } = props
    return (
      <div
        className={clsx(
          "flex text-center items-center align-middle bg-rose-lighter",
          simplified ? "p-1" : "p-2",
          "print:border-b",
          highContrast ? "border-black" : "border-gray-400"
        )}
        style={{
          minHeight: limited ? undefined : props.monthNameFontSizeOverride,
          padding: limited ? undefined : "8px",
          background: highContrast ? "#eee" : undefined,
        }}
        onClick={() => handleSelectMonth(getStartMoment())}
      >
        <h3
          className={clsx(
            styles.monthName,
            simplified && styles.simplified,
            limited && styles.limited,
            forPrint && limited && styles.limitedForPrint
          )}
          style={{
            fontSize: limited ? undefined : props.monthNameFontSizeOverride,
            color: highContrast ? "black" : undefined,
          }}
        >
          {getStartMoment().format("MMMM YYYY")}
        </h3>
      </div>
    )
  }

  const handleItemDrop = (
    itemType: string,
    { uuid, calendarId }: any,
    date: string
  ) => {
    props.onItemDrop(itemType, uuid, calendarId, date)
  }

  /**
   * Renders a multi-dimensional array of MonthDayView components to draw the actual calendar UI which
   * serves as a back drop to the actual WeekView components that render the events.
   *
   * @return  {ReactClass}                    An array of divs representing each week of the calendar.
   */
  const renderMonth = () => {
    const {
      weeks,
      handleDaySelection,
      forPrint,
      darkDays,
      selectedDate,
      handleContextMenu,
      cutoffColor,
      holidayColor,
      weekendColor,
      highContrast,
      hideLabels,
    } = props
    const startUnit = weeks > 2 ? "month" : "week"
    const start = props.getStartMoment().startOf(startUnit).startOf("week")
    const month = props.getStartMoment().month()
    if (!start.isValid()) {
      return <div />
    }
    return Array(weeks)
      .fill(null)
      .map((_, i) => {
        // 0..6 == 7 days in a week...
        const weekNumber = start.week() + i
        const week = moment.utc(start).week(weekNumber)
        const startOfWeek = moment.utc(week.startOf("week"))
        const endOfWeek = moment.utc(week.endOf("week"))
        const calendarIdKey = props.calendarIds.join("_")
        return (
          <div
            key={`${weekNumber}_${calendarIdKey}`}
            className="relative flex flex-grow flex-shrink"
            style={{
              maxHeight: useWeightedLayout
                ? `${(heights[i] / size.height) * 100}%`
                : undefined,
            }}
          >
            {forPrint ? (
              <WeekViewHTML
                startDate={startOfWeek.format()}
                month={month + 1}
                darkDays={darkDays}
                travel={travelDates}
                limited={true}
                showToday={!forPrint}
                inactiveCellBackgroundColor={holidayColor}
                weekendCellBackgroundColor={weekendColor}
                outOfRangeCellBackgroundColor={cutoffColor}
                x={0}
                y={0}
                highContrast={highContrast}
                hideLabels={hideLabels}
              />
            ) : (
              <WeekView
                startDate={startOfWeek.format()}
                accept={[
                  ExplorerItemTypes.ITEM_VIEW,
                  ItemTypes.EVENT_EXPAND_VIEW,
                ]}
                onDrop={(type, item, date) => handleItemDrop(type, item, date)}
                onDropHover={() => {}}
                month={month + 1}
                darkDays={darkDays}
                travel={travelDates}
                limited={true}
                showToday={!forPrint}
                onContextMenu={handleContextMenu}
                selectedDate={selectedDate}
                onSelect={handleDaySelection}
                inactiveCellBackgroundColor={holidayColor}
                weekendCellBackgroundColor={weekendColor}
                outOfRangeCellBackgroundColor={cutoffColor}
                x={0}
                y={0}
                width={0}
                height={(size.height ?? 80) / 6}
              />
            )}
            {renderWeek(startOfWeek.format(), endOfWeek.format(), heights[i])}
          </div>
        )
      })
  }

  /**
   * Accepts a multidimensional array of events to be rendered
   * during a specified period. The positionmap array is passed
   * down into the event rendering method to provide a snapshot
   * of the event positions during an eventual drag and drop.
   *
   * @param   {Object}   start        A moment JS object representing the start of the week.
   * @param   {Object}   end          A moment JS object representing the end of the week.
   * @param   {Number}   height       The height of the week view.
   * @returns {ReactClass}            A div representing the events for the week.
   */
  const renderWeek = (start: any, end: any, height?: number) => {
    const {
      calendarIds,
      snapshotId,
      simplified,
      handleDaySelection,
      selectedDate,
      limited,
      handleMouseDown,
      handleSelectedEvent,
      readOnly,
    } = props
    const maxRowsBeforeScale = useWeightedLayout
      ? Math.max(1, (height ?? 100) / 40)
      : 6
    return (
      <Week
        start={start}
        end={end}
        calendarIds={calendarIds}
        snapshotId={(snapshotId && parseInt(snapshotId, 0)) || null}
        simplified={simplified}
        limited={limited}
        handleMouseDown={handleMouseDown}
        handleMouseClick={() => {
          handleDaySelection(selectedDate)
        }}
        handleSelectedEvent={handleSelectedEvent}
        handleSelectWeek={props.handleSelectWeek}
        handleEditEvent={props.handleEditEvent}
        readOnly={readOnly}
        forPrint={props.forPrint}
        reportId={props.reportId}
        specialEvents={props.specialEvents}
        height={useWeightedLayout ? height : size.height / 5 - 20}
        maxRowsBeforeScale={maxRowsBeforeScale}
      />
    )
  }

  const { limited, simplified, enforceStrictHeight, highContrast } = props

  return (
    <div
      className="flex flex-col flex-grow flex-1 flex-shrink-0 "
      style={{
        minHeight: enforceStrictHeight ? "900px" : "auto",
        maxHeight: enforceStrictHeight ? "900px" : "auto",
      }}
    >
      {renderMonthHeading()}
      {!limited && (
        <WeekHeading
          simplified={simplified}
          limited={limited}
          highContrast={highContrast}
        />
      )}
      <div ref={container} className="flex flex-col flex-grow">
        {renderMonth()}
      </div>
    </div>
  )
}

export default MonthView
