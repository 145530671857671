import React, { Component } from "react"
import PropTypes from "prop-types"
import { Modal } from "../../shared"
import ReportCalendarFormView, { Note } from "./ReportCalendarFormView.react"
import AutoCompleteField from "./AutoCompleteField.react"
import ReportCalendarListItem from "./ReportCalendarListItem.react"

class ReportSettingsModalView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optimisticCalendarIds: [],
      currentCalendarId: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { subdomain, reportId } = this.props
    this.props.requestReport(subdomain, reportId)
    this.props.requestFullReportCalendars(subdomain, reportId)
  }

  addCalendarToReport(calendarId) {
    const { optimisticCalendarIds } = this.state
    const { addReportCalendar, subdomain, reportId } = this.props
    this.setState({
      optimisticCalendarIds: [...optimisticCalendarIds, calendarId],
    })
    addReportCalendar(subdomain, reportId, calendarId)
  }

  removeCalendarFromReport(calendarId) {
    const { optimisticCalendarIds } = this.state
    const { deleteReportCalendar, subdomain, reportId } = this.props
    this.setState({
      optimisticCalendarIds: optimisticCalendarIds.filter(
        (id) => id !== calendarId
      ),
    })
    deleteReportCalendar(subdomain, reportId, calendarId)
  }

  updateCalendarForReport(calendarId, values) {
    const { subdomain, reportId, updateReportCalendar } = this.props
    this.setState({ loading: true })
    updateReportCalendar(subdomain, reportId, calendarId, values)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  renderAutocompleteField() {
    const { optimisticCalendarIds } = this.state
    const { findReportCalendarIds, reportId, findCalendars, subdomain } =
      this.props
    const selectedCalendarIds = [
      ...optimisticCalendarIds,
      ...findReportCalendarIds(reportId),
    ]
    const reduceCalendars = (filterText) =>
      findCalendars(subdomain)
        .filter(
          (c) =>
            filterText.length < 1 ||
            c.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
        )
        .filter((c) => selectedCalendarIds.indexOf(c.id) < 0)
        .map((c) => ({ id: c.id, name: c.name, icon: "calendar" }))
    const handleAddCalendar = (result) => () =>
      this.addCalendarToReport(result.id)
    return (
      <AutoCompleteField
        label="Add a calendar to this report:"
        placeholder="Search calendar by name"
        name="searchCalendars"
        onSelectResult={handleAddCalendar}
        filterResults={reduceCalendars}
      />
    )
  }

  render() {
    const {
      findCalendars,
      findReportCalendarIds,
      subdomain,
      reportId,
      findReportCalendar,
    } = this.props
    const { currentCalendarId, loading } = this.state
    const calendarIds = findReportCalendarIds(reportId)
    const calendars = findCalendars(subdomain).filter(
      (c) => calendarIds.indexOf(c.id) > -1
    )
    const reportCalendar = currentCalendarId
      ? findReportCalendar({ reportId, calendarId: currentCalendarId })
      : null

    return (
      <div>
        <Modal.Body
          disableScrolling={calendars.length < 5 && !currentCalendarId}
        >
          {currentCalendarId ? (
            calendars
              .filter((c) => c.id === currentCalendarId)
              .map((c) => (
                <ReportCalendarFormView
                  calendar={c}
                  onDismiss={() => this.setState({ currentCalendarId: null })}
                  onSubmit={(values) =>
                    this.updateCalendarForReport(c.id, values)
                  }
                  key={c.id}
                  initialValues={reportCalendar}
                  loading={loading}
                />
              ))
          ) : (
            <div>
              {this.renderAutocompleteField()}
              {calendars.map((c) => {
                const editCalendar = (id) => {
                  this.setState({ currentCalendarId: id })
                }
                const deleteCalendar = (id) => this.removeCalendarFromReport(id)
                return (
                  <ReportCalendarListItem
                    key={`reportCalendar${c.id}`}
                    calendarId={c.id}
                    name={c.name}
                    reportCalendar={findReportCalendar({
                      reportId,
                      calendarId: c.id,
                    })}
                    onEdit={editCalendar}
                    onRemove={deleteCalendar}
                  />
                )
              })}
            </div>
          )}
          <Note style={{ marginTop: "1rem" }}>
            You can make specific modifications to how these calendars render
            for reporting purposes here.
          </Note>
        </Modal.Body>
      </div>
    )
  }
}

ReportSettingsModalView.propTypes = {
  /**
   * The ID of the current report.
   */
  reportId: PropTypes.string,

  /**
   * The subdomain for the current calendar.
   */
  subdomain: PropTypes.string,

  /**
   * A redux action creator that requests a specific report against the API.
   */
  requestReport: PropTypes.func,

  /**
   * A redux action creator that requests all calendars for a report against the API.
   */
  requestFullReportCalendars: PropTypes.func,

  /**
   * A redux action creator that deletes a specific calendar to a report against the API.
   */
  deleteReportCalendar: PropTypes.func,

  /**
   * A redux action creator that updates a specific calendar to a report against the API.
   */
  updateReportCalendar: PropTypes.func,

  /**
   * A redux action creator that adds a specific calendar to a report against the API.
   */
  addReportCalendar: PropTypes.func,

  /**
   * A redux selector to find the details of a specific report cached in state.
   */
  findReport: PropTypes.func,

  /**
   * A redux selector to find the IDs of calendars associated to a specific report cached in state.
   */
  findReportCalendarIds: PropTypes.func,

  /**
   * A redux selector to find a specific report calendar cached in state.
   */
  findReportCalendar: PropTypes.func,

  /**
   * All available calendars in the current organization.
   */
  findCalendars: PropTypes.func,

  /**
   * A handler to handle the dismissal of the window.
   */
  handleDismiss: PropTypes.func,
}

export default ReportSettingsModalView
