import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { InvitationResponse } from "./types"
import { camelizeKeys } from "humps"
export interface CreateInvitationBody {
  accessLevel: "user" | "admin" | "creator"
  name: string
  email: string
  calendarId: number
}

export interface CreateInvitationParams extends RequestParams {
  invitationId: string
}

export const useCreateInvitation = (subdomain: string) => {
  const updateEntity = useUpdateEntity()
  const createInvitation = useApiRequest<
    InvitationResponse,
    CreateInvitationBody,
    CreateInvitationParams
  >({
    method: "POST",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/invitations`,
    onResponse: (response) => {
      updateEntity("invitations", response.id, {
        ...camelizeKeys(response),
        organization: subdomain,
      })
    },
  })
  return createInvitation
}
