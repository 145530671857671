import React from "react"
import PropTypes from "prop-types"
import styles from "./ShortListSecondaryAction.module.css"

const ShortListSecondaryAction = ({ children, ...props }) => (
  <button {...props} className={styles.settings}>
    {children}
  </button>
)

ShortListSecondaryAction.propTypes = {
  children: PropTypes.node,
}

export default ShortListSecondaryAction
