import React from "react"
import { useDispatch, useSelector } from "react-redux"
import * as actions from "../actions"
import { ContextMenu, MenuSelectionHandler, WeekNumberMode } from "ui"
import * as sel from "../selectors"
import * as selectable from "../../selectable"
import api, { useCalendar, useStyles, useItem } from "../../api"
import { DateTime } from "luxon"
import { StyleOption } from "ui"

const { Selectable } = selectable

interface CalendarContextMenuProps {
  calendarId: number
  subdomain: string
  onSelect?: MenuSelectionHandler
}

export const CalendarContextMenu: React.FC<CalendarContextMenuProps> = ({
  calendarId,
  subdomain,
  onSelect: externalSelectionHandler,
}) => {
  const { calendar } = useCalendar(subdomain, `${calendarId}`)
  const { styles } = useStyles(subdomain, `${calendarId}`)
  const dispatch = useDispatch()
  const handleDismiss = () => {
    dispatch(actions.clearContext())
  }

  const {
    x,
    y,
    entityType,
    referenceDate,
    targetUuid,
    holidays,
    icons,
    recurringEvent,
  } = useSelector(sel.contextMenu)

  const travelIconUuid = icons?.find(
    (i: any) => i.name === "travel" && i.startDate === referenceDate
  )?.uuid

  const selectedEvents = useSelector(
    (state) => selectable.getSelectedItems(state)[Selectable.EVENT]
  )
  const lookupEvent = useSelector(api.selectors.eventSelector.find)
  const { item } = useItem(
    subdomain,
    `${calendarId}`,
    lookupEvent(targetUuid, calendarId)?.itemUuid
  )
  const clipboardMode = useSelector((state) => sel.clipboardMode(state))
  const clipboardEvents = useSelector((state) => sel.clipboardEvents(state))
  const startsAt = DateTime.fromISO(referenceDate, { zone: "utc" }).toISO()

  const handlePasteEvents = (increment: boolean) => {
    dispatch(
      actions.pasteEventsToDate(
        clipboardEvents,
        calendarId,
        startsAt,
        clipboardMode,
        increment
      )
    )
  }

  const handleBlackoutDay = () =>
    dispatch(
      api.actions.holidays.createHoliday(subdomain, calendarId, {
        name: "Dark Day",
        startsAt,
        length: 1,
      })
    ).then(() => {
      dispatch(api.actions.events.requestEvent(subdomain, calendarId, null))
    })

  const handleTravelDay = (destructive?: boolean) => {
    dispatch(
      destructive
        ? api.actions.icons.removeIcon(subdomain, calendarId, travelIconUuid)
        : api.actions.icons.createIcon(subdomain, calendarId, {
            name: "travel",
            startDate: startsAt,
          })
    )
  }

  const handleUngroup = () => {
    const event = lookupEvent(targetUuid, calendarId)
    dispatch(
      api.actions.items.ungroupItem(subdomain, calendarId, event.itemUuid)
    )
  }

  const handleSkipEvent = () => {
    const event = lookupEvent(targetUuid, calendarId)
    dispatch(
      api.actions.items.skipDateForItem(
        subdomain,
        calendarId,
        event.itemUuid,
        event.startsAt
      )
    )
  }

  const handleHideWeekNumbers = () => {
    dispatch(
      api.actions.cals.requestPatchWeekNumbers(subdomain, calendarId, "none")
    )
  }

  const handleShowProjectWeekNumbers = () => {
    dispatch(
      api.actions.cals.requestPatchWeekNumbers(
        subdomain,
        calendarId,
        "calendar_start"
      )
    )
  }

  const handleShowAnnualWeekNumbers = () => {
    dispatch(
      api.actions.cals.requestPatchWeekNumbers(subdomain, calendarId, "year")
    )
  }

  const handleSelectStyle = (style: StyleOption) => {
    dispatch(
      api.actions.events.updateEventStyle(
        subdomain,
        calendarId,
        targetUuid,
        style.uuid
      )
    )
  }

  const handleSelection: MenuSelectionHandler = (selection, params) => {
    switch (selection) {
      case "travelDay":
        return handleTravelDay()
      case "clearTravelDay":
        return handleTravelDay(true)
      case "blackoutDay":
        return handleBlackoutDay()
      case "cutEvents":
        return dispatch(actions.cutEvents(selectedEvents, calendarId))
      case "copyEvents":
        return dispatch(actions.copyEvents(selectedEvents, calendarId))
      case "paste":
        return handlePasteEvents(params?.autoIncrement ?? false)
      case "ungroupItem":
        return handleUngroup()
      case "skipEventDate":
        return handleSkipEvent()
      case "hideWeekNumbers":
        return handleHideWeekNumbers()
      case "showWeekOfProject":
        return handleShowProjectWeekNumbers()
      case "showWeekOfYear":
        return handleShowAnnualWeekNumbers()
      default:
        if (externalSelectionHandler) {
          externalSelectionHandler(selection, {
            ...params,
            targetUuid,
            referenceDate,
          })
        }
    }
  }

  let weekNumberMode: WeekNumberMode = null
  switch (calendar?.showWeekNumbers) {
    case "calendar_start":
      weekNumberMode = "showWeekOfProject"
      break
    case "year":
      weekNumberMode = "showWeekOfYear"
      break
  }

  return calendarId ? (
    <ContextMenu
      clipboardMode={clipboardMode}
      clipboardCount={clipboardEvents?.length ?? 0}
      x={x}
      y={y}
      entityType={entityType}
      onDismiss={handleDismiss}
      onSelect={handleSelection}
      hasHolidays={holidays?.length ?? false}
      hasTravel={travelIconUuid}
      recurringEvent={recurringEvent}
      weekNumberMode={weekNumberMode}
      styles={styles}
      styleUuid={item?.applicableStyleId}
      onSelectStyle={handleSelectStyle}
      onSelectManageStyles={() => {
        handleSelection("manageTheme")
      }}
    />
  ) : null
}
