import React, { Component } from "react"
import PropTypes from "prop-types"
import { DragLayer } from "react-dnd"
import { CalendarItemTypes } from "../../calendarView"
import { ItemTypes, PreviewTypes } from "../constants"
import ItemEventPreview from "./ItemEventPreview.react"
import ItemPreview from "./ItemPreview.react"
import styles from "./ItemDragLayer.module.css"

function getItemStyles(props) {
  const { currentOffset } = props
  if (!currentOffset) {
    return {
      display: "none",
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform: transform,
    WebkitTransform: transform,
  }
}

class ItemDragLayer extends Component {
  renderEventPreview() {
    const { item } = this.props
    return (
      <ItemEventPreview
        {...item}
        length={item.length || 1}
        fontStyle={item.fontStyle}
        fontSize={item.fontSize}
        fontWeight={item.fontWeight}
        font={item.font}
        textDecoration={item.textDecoration}
        textAlignment={item.textAlignment}
        fillColor={item.fillColor}
        outlineColor={item.outlineColor}
      />
    )
  }

  /* eslint-disable complexity */
  renderItem(type, item) {
    const { itemPreviewType } = this.props
    switch (type) {
      case CalendarItemTypes.EVENT_EXPAND_VIEW:
      case CalendarItemTypes.EVENT_VIEW:
        return this.renderEventPreview()
      case ItemTypes.ITEM_VIEW:
        switch (itemPreviewType) {
          case PreviewTypes.EVENT_PREVIEW:
            return this.renderEventPreview()
          default:
            return <ItemPreview name={item.name} />
        }
      case ItemTypes.CATEGORY_VIEW:
        return <ItemPreview name={item.name} />
      default:
        return null
    }
  }
  /* eslint-enable complexity */

  render() {
    const { item, itemType, isDragging } = this.props
    if (!isDragging) {
      return null
    }

    return (
      <div className={styles.itemDragLayer}>
        <div style={getItemStyles(this.props)}>
          {this.renderItem(itemType, item)}
        </div>
      </div>
    )
  }
}

ItemDragLayer.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,

  /**
   * Indicates the type of preview to render when a
   * cell from the item explorer view is being dragged
   * accross the UI.
   */
  itemPreviewType: PropTypes.string.isRequired,
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

export default DragLayer(collect)(ItemDragLayer)
