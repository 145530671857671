var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/pro-solid-svg-icons";
import { WeekDayLabelHTML } from "./WeekDayLabelHTML";
import clsx from "clsx";
var FONT = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif";
export var WeekViewHTML = function (_a) {
    var _b = _a.borderColor, borderColor = _b === void 0 ? "rgba(0, 0, 0, 0.2)" : _b, _c = _a.cellBackgroundColor, cellBackgroundColor = _c === void 0 ? "#fff" : _c, _d = _a.weekendCellBackgroundColor, weekendCellBackgroundColor = _d === void 0 ? "rgba(168, 156, 168, 0.17)" : _d, _e = _a.inactiveCellBackgroundColor, inactiveCellBackgroundColor = _e === void 0 ? "rgba(168, 156, 168, 0.17)" : _e, _f = _a.outOfRangeCellBackgroundColor, outOfRangeCellBackgroundColor = _f === void 0 ? "rgba(168, 156, 168, 0.5)" : _f, _g = _a.labelColor, labelColor = _g === void 0 ? "rgb(171, 155, 169)" : _g, _h = _a.todayFillColor, todayFillColor = _h === void 0 ? "#dc6939" : _h, startDate = _a.startDate, month = _a.month, currentDate = _a.currentDate, _j = _a.darkDays, darkDays = _j === void 0 ? {} : _j, limited = _a.limited, showToday = _a.showToday, travel = _a.travel, number = _a.number, style = _a.style, highContrast = _a.highContrast, hideLabels = _a.hideLabels;
    return (_jsx("div", __assign({ className: "flex flex-row flex-grow flex-shrink-0", style: style }, { children: __spreadArray([], Array(7), true).map(function (_, i) {
            var date = DateTime.fromISO(startDate, { zone: "utc" })
                .plus({ days: i })
                .startOf("day");
            var key = "WeekDay" + date.toISO();
            var dark = darkDays[date.toFormat("yyyy-MM-dd")];
            var outOfRange = month && date.month !== month;
            var weekend = date.weekday > 5;
            var isToday = showToday &&
                (currentDate
                    ? DateTime.fromISO(currentDate, { zone: "utc" }).startOf("day")
                    : DateTime.local().startOf("day")).toISODate() === date.toISODate();
            var fill = outOfRange
                ? outOfRangeCellBackgroundColor
                : dark
                    ? inactiveCellBackgroundColor
                    : weekend
                        ? weekendCellBackgroundColor
                        : cellBackgroundColor;
            var additionalText = hideLabels
                ? ""
                : dark === null || dark === void 0 ? void 0 : dark.filter(function (v, i, a) { return a.indexOf(v) === i; }).join(", ");
            var travelDay = hideLabels
                ? false
                : travel === null || travel === void 0 ? void 0 : travel.join().includes(date.toISODate());
            return (_jsxs("div", __assign({ className: clsx("border-t relative flex flex-grow flex-shrink-0 overflow-hidden", i < 6 && "border-r"), style: {
                    background: fill,
                    borderColor: highContrast ? "#000" : borderColor,
                    flexBasis: 1,
                    width: 100 / 7 + "%",
                } }, { children: [travelDay ? (_jsx("p", __assign({ className: "absolute flex text-sm text-black pointer-events-none", style: { top: i === 0 && number ? 18 : 8, right: 8 } }, { children: _jsx(FontAwesomeIcon, { icon: faPlane }, void 0) }), void 0)) : null, _jsx(WeekDayLabelHTML, { x: limited ? 4 : 8, y: limited ? 2 : 4, font: FONT, today: isToday, todayFillColor: todayFillColor, labelColor: highContrast ? "#000" : labelColor, highContrast: highContrast, date: date.toISO(), additionalText: additionalText, limited: limited }, void 0), number && i === 0 ? (_jsxs("p", __assign({ className: "bg-white border absolute top-0 right-0", style: {
                            font: FONT,
                            borderColor: highContrast ? "#000" : borderColor,
                            fontSize: limited ? 6 : 10,
                            paddingLeft: limited ? 2 : 3,
                            paddingRight: limited ? 2 : 3,
                            paddingTop: limited ? 1 : 1,
                            paddingBottom: limited ? 1 : 1,
                            lineHeight: "1em",
                        } }, { children: ["WEEK ", number] }), void 0)) : null] }), key));
        }) }), void 0));
};
