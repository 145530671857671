import React, { useState } from "react"
import {
  FileToEndpointFn,
  HandleAssociatedFileUpdateFn,
  HandleFileUploadFn,
  HandleDeleteFn,
  FilePreview,
  MimeTypes,
  AttachmentList,
} from "ui"
import { useCreateUploads } from "../../api/hooks"

export interface DocumentUploaderProps {
  maxFiles?: number
  onUpload?: HandleFileUploadFn
  onDelete?: HandleDeleteFn
  previews?: FilePreview[]
}

export const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  maxFiles,
  onDelete,
  onUpload,
  previews = [],
}) => {
  const [attachments, setAttachments] = useState(previews as any)
  const [makeUpload, { success }] = useCreateUploads()
  const makeEndpoints: FileToEndpointFn = async (filenames) => {
    const result = await makeUpload({
      body: {
        uploads: filenames.map(({ name: key, contentType }) => ({
          key,
          contentType,
        })),
      },
    })
    const endpoints = result?.data?.uploads?.map((d) => ({
      ...d,
      uploadUrl: d.url,
    }))
    return endpoints ?? []
  }
  const handleAttachments: HandleAssociatedFileUpdateFn = (files) => {
    setAttachments(
      files.map((f) => ({
        ...f,
        id: f.id,
        position: f.position,
        name: f.file?.name ?? f.name ?? f.id,
      }))
    )
  }
  return (
    <>
      <AttachmentList
        previews={attachments}
        onUpdate={handleAttachments}
        accept={[...MimeTypes.Documents]}
        getEndpointsForFilenames={makeEndpoints}
        maxFiles={maxFiles}
        onDelete={onDelete}
        onUpload={onUpload}
        label="Drag and drop your file here, or select it"
      />
      {!success === false ? (
        <p className="text-error">
          There was a problem uploading this document.
        </p>
      ) : null}
    </>
  )
}
