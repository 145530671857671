import { createSelector } from "reselect"
import { entities } from "./baseSelector"
import flatten from "lodash/flatten"

/**
 * A simple selector that grabs all available categories from the
 * current state tree.
 * @param  {Object} state The current redux state tree.
 * @return {Object}       An object of category objects mapped to their UUID as a key value.
 */
const allCategories = (state) => entities(state).categories

/**
 * Returns all categories entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} categories  An object of category objects mapped to their UUID as a key value.
 * @return {Function}           A sorted subset of the supplied categories object.
 */
const filterAvailableCategories = (categories) => {
  return Object.keys(categories)
    .map((key) => categories[key])
    .filter((entity) => !entity.isDeleted)
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
}

/**
 * Returns a memoized selector that returns all available categories.
 * @type {Function}
 */
export const all = createSelector(allCategories, filterAvailableCategories)

/**
 * Recursively searches a set of supplied categories to determine if a category
 * is inherently inactive.
 * @param  {Array} categories An object of category objects mapped to their UUID as a key value.
 * @param  {Integer} uuid       The uuid of the category to inspect.
 * @return {Boolean}          True if the item is inheritedly or directly inactive.
 */
const trulyInactive = (categories, uuid) => {
  const category = categories.filter((c) => c.uuid === uuid)[0]
  if (category && category.snapshotId) {
    return !category.active // FIXME: Simple workaround for snapshots... at least for now. This needs to be debugged and rewritten to work with HashIDs.
  }
  return (
    !category ||
    !category.active ||
    (category.categoryUuid !== uuid &&
      category.categoryUuid &&
      trulyInactive(categories, category.categoryUuid)) ||
    false
  )
}

/**
 * Returns all categories entities in the cache that are not active.
 *
 * @param  {Object} categories  An object of category objects mapped to their Id as a key value.
 * @return {Function}           A sorted subset of the supplied categories object.
 */
const filterInactive = (categories) => {
  const matches = Object.keys(categories)
    .map((key) => categories[key])
    .filter((category) => trulyInactive(categories, category.uuid))
    .map((category) => category.uuid)
  return matches
}

/**
 * Returns a memoized selector that returns all active categories.
 * @type {Function}
 */
export const inactiveCategoryUuids = createSelector(all, filterInactive)

/**
 * Recursively searches a set of supplied categories to determine if a category
 * is inherently locked.
 * @param  {Array}    categories  An object of category objects mapped to their UUID as a key value.
 * @param  {Integer}  uuid        The uuid of the category to inspect.
 * @return {Boolean}              True if the item is inheritedly or directly locked.
 */
const trulyLocked = (categories, uuid) => {
  const category = categories.filter((c) => c.uuid === uuid)[0]
  return (
    !category ||
    category.locked ||
    (category.categoryUuid !== uuid &&
      category.categoryUuid &&
      trulyLocked(categories, category.categoryUuid)) ||
    false
  )
}

/**
 * Returns all categories entities in the cache that are not locked.
 *
 * @param  {Object} categories  An object of category objects mapped to their Id as a key value.
 * @return {Function}           A sorted subset of the supplied categories object.
 */
const filterLocked = (categories) => {
  const matches = Object.keys(categories)
    .map((key) => categories[key])
    .filter((category) => trulyLocked(categories, category.uuid))
    .map((category) => category.uuid)
  return matches
}

/**
 * Returns a memoized selector that returns all locked categories.
 * @type {Function}
 */
export const lockedCategoryUuids = createSelector(all, filterLocked)

/**
 * Retrieves a specific category object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching item if one exists.
 */
export const find = (state) => (categoryUuid, calendarId) => {
  const hashId = [categoryUuid, calendarId].filter((i) => i).join("#")
  const entity = entities(state).categories[hashId]
  return entity && !entity.isDeleted && entity
}

/**
 * Returns a query function that fetches all categories for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of categories that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarId) =>
  all(state).filter((category) =>
    flatten([calendarId])
      .map((id) => parseInt(id, 0))
      .includes(parseInt(category.calendarId, 0))
  )

/**
 * Returns a query function that fetches all categories for a single snapshot.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of categories that match a supplied snapshot ID.
 */
export const forSnapshot = (state) => (snapshotId) =>
  all(state).filter(
    (category) => parseInt(category.snapshotId, 0) === parseInt(snapshotId, 0)
  )

/**
 * Returns a query function that fetches the notes category for a sepcific calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns the notes category that match a supplied calendar ID.
 */
export const notesCategoryForCalendarEntity =
  (state) => (calendarId, entityType) =>
    (entityType === "snapshot" ? forSnapshot : forCalendar)(state)(
      calendarId
    ).filter((category) => category.name === "Notes")[0]
