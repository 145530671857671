import * as types from "./actionTypes"
import { eventSelector, itemSelector } from "../api"
import { Selectable } from "./constants"
import { getSelectedItems, getMultiselect } from "./selectors"

export const updateItemDraggingStatus = (dragging) => {
  return {
    type: dragging ? types.BEGIN_NEW_ITEM_DRAG : types.END_NEW_ITEM_DRAG,
    payload: {},
  }
}

export const setSelectedItems = (append, selectableType, selectedItems) => ({
  type: append ? types.APPEND_SELECT_ITEM : types.SELECT_ITEM,
  payload: {
    selectableType,
    selectedItems,
  },
})

export const setMultiselect = (enabled) => ({
  type: types.UPDATE_MULTI_SELECT,
  payload: {
    enabled,
  },
})

const findRelatedEvents = (state, selectableType, itemUuid, calendarId) => {
  switch (selectableType) {
    case Selectable.EVENT: {
      const event = eventSelector.find(state)(itemUuid, calendarId)
      if (!event) {
        return []
      }
      return eventSelector
        .forItem(state)(event.itemUuid, calendarId)
        .map((e) => e.uuid)
    }
    default:
      return [itemUuid]
  }
}

/* eslint-disable complexity */
/**
 * Returns a thunk which will add the ID for a selectable type or reset
 * the state of a selectable type with a supplied ID.
 * @param  {String} selectableType  A string representing the type of selectable list to apply this ID to.
 * @param  {String} itemUuid        The id of the item which will be added to the selected state.
 * @param  {Integer} calendarId      The id of the calendar.
 * @return {Function}               A promise with the result of the dispatched thunk.
 */
export const selectItem =
  (selectableType, itemUuid, calendarId) => (dispatch, getState) => {
    const state = getState()
    const append = getMultiselect(state)
    const items = (append ? getSelectedItems(state)[selectableType] : []) || []
    const newlySelectedItems = findRelatedEvents(
      state,
      selectableType,
      itemUuid,
      calendarId
    )
    const updatedItems = items.includes(itemUuid)
      ? items.filter((i) => !newlySelectedItems.includes(i))
      : items.concat(newlySelectedItems)
    const selectedItems = { [selectableType]: updatedItems }
    switch (selectableType) {
      case Selectable.ITEM:
        itemSelector.find(state)(itemUuid, calendarId) &&
          dispatch(
            setLastSelectedCategory(
              itemSelector.find(state)(itemUuid, calendarId).categoryUuid,
              calendarId
            )
          )
        break
      case Selectable.CATEGORY:
        dispatch(setLastSelectedCategory(itemUuid))
        break
      case Selectable.EVENT:
        const event = eventSelector.find(state)(itemUuid, calendarId)
        if (event) {
          dispatch(setLastSelectedEventStartDate(event.startsAt))
        }
        break
      default:
        break
    }
    return dispatch(setSelectedItems(append, selectableType, selectedItems))
  }
/* eslint-enable complexity */

/**
 * Creates a redux action which is used to clear the selected items from
 * the current state.
 *
 * @returns {Object}  A redux FSA action.
 */
export const resetSelection = () => ({
  type: types.RESET_SELECTED_ITEMS,
  payload: {},
})

export const setLastSelectedCategory = (categoryUuid, calendarId) => ({
  type: types.UPDATE_LAST_SELECTED_ITEM,
  payload: { categoryUuid, calendarId },
})

export const setLastSelectedEventStartDate = (start) => ({
  type: types.SET_LAST_SELECTED_EVENT_DATE,
  payload: { start },
})
