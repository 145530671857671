import { combineReducers } from "redux"
import * as t from "./actionTypes"
import { Selectable } from "./constants"

const defaultSelectedItems = {
  [Selectable.ITEM]: [],
  [Selectable.CATEGORY]: [],
  [Selectable.EVENT]: [],
}

/**
 * Marks a specified date as that which is currently
 * selected on the calendar.
 * @param {Object} selectedDate The new date that was selected.
 * @return {Object} An FSA action.
 */
export const selectDate = (selectedDate) => ({
  type: t.SELECT_DATE,
  payload: { selectedDate },
})

function selectedItems(state = defaultSelectedItems, action) {
  switch (action.type) {
    case t.SELECT_ITEM:
      return Object.assign(
        {},
        defaultSelectedItems,
        action.payload.selectedItems
      )
    case t.APPEND_SELECT_ITEM:
      return Object.assign({}, state, action.payload.selectedItems)
    case t.RESET_SELECTED_ITEMS:
      return defaultSelectedItems
    default:
      return state
  }
}

function lastItemTypeSelected(state = Selectable.NONE, action) {
  switch (action.type) {
    case t.SELECT_ITEM:
    case t.APPEND_SELECT_ITEM:
      return Object.keys(action.payload.selectedItems)[0]
    case t.RESET_SELECTED_ITEMS:
      return Selectable.NONE
    default:
      return state
  }
}

function selectedDate(state = null, action) {
  switch (action.type) {
    case t.SELECT_DATE:
      return action.payload.selectedDate || null
    default:
      return state
  }
}

function multiselect(state = false, action) {
  switch (action.type) {
    case t.UPDATE_MULTI_SELECT:
      return action.payload.enabled || false
    default:
      return state
  }
}

function lastSelectedCategory(state = null, action) {
  switch (action.type) {
    case t.UPDATE_LAST_SELECTED_ITEM:
      return action.payload || null
    case t.RESET_SELECTED_ITEMS:
      return null
    default:
      return state
  }
}

function lastSelectedEventDate(state = null, action) {
  switch (action.type) {
    case t.SET_LAST_SELECTED_EVENT_DATE:
      return action.payload.start
    case t.RESET_SELECTED_ITEMS:
      return null
    default:
      return state
  }
}

export default combineReducers({
  selectedItems,
  lastItemTypeSelected,
  selectedDate,
  lastSelectedCategory,
  lastSelectedEventDate,
  multiselect,
})
