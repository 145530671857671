var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from "luxon";
import { useState } from "react";
import { Group, Rect } from "react-konva";
import { WeekDayLabel } from "./WeekDayLabel";
import { Html } from "react-konva-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/pro-solid-svg-icons";
import { WeekNumberLabel } from "./WeekNumberLabel";
import Color from "color";
var rgbaConversions = {
    "RGBA(168, 156, 168, 0.17)": "#ebe8ea",
    "RGBA(168, 156, 168, 0.5)": "#cfc7cd",
};
var FONT = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif";
var STROKE_WIDTH = 1;
var SELECTED_STROKE_WIDTH = 2;
var DASH_SIZE = [4, 4];
export var WeekViewKonva = function (_a) {
    var _b = _a.borderColor, borderColor = _b === void 0 ? "rgba(0, 0, 0, 0.1)" : _b, _c = _a.cellBackgroundColor, cellBackgroundColor = _c === void 0 ? "#fff" : _c, _d = _a.weekendCellBackgroundColor, weekendCellBackgroundColor = _d === void 0 ? "#ebe8ea" : _d, _e = _a.inactiveCellBackgroundColor, inactiveCellBackgroundColor = _e === void 0 ? "#ebe8ea" : _e, _f = _a.outOfRangeCellBackgroundColor, outOfRangeCellBackgroundColor = _f === void 0 ? "#cfc7cd" : _f, _g = _a.labelColor, labelColor = _g === void 0 ? "#b39ba9" : _g, _h = _a.todayFillColor, todayFillColor = _h === void 0 ? "#dc6939" : _h, _j = _a.selectedColor, selectedColor = _j === void 0 ? "#4e83bc" : _j, hoverColor = _a.hoverColor, startDate = _a.startDate, month = _a.month, currentDate = _a.currentDate, selectedDate = _a.selectedDate, handleSelectDate = _a.onSelect, handleContextMenu = _a.onContextMenu, height = _a.height, width = _a.width, x = _a.x, y = _a.y, _k = _a.darkDays, darkDays = _k === void 0 ? {} : _k, limited = _a.limited, showToday = _a.showToday, travel = _a.travel, number = _a.number, ignoreOutOfRange = _a.ignoreOutOfRange;
    var _l = useState(0), lastClick = _l[0], _ = _l[1];
    var handleOnDayClick = function (date) { return function (_a) {
        var evt = _a.evt;
        evt.stopPropagation();
        evt.preventDefault();
        var time = new Date().getTime();
        if (time - lastClick < 1000) {
        }
        if (handleSelectDate) {
            handleSelectDate === null || handleSelectDate === void 0 ? void 0 : handleSelectDate(date);
        }
    }; };
    var handleOnMouseOver = function (fill) { return function (evt) {
        var currentShape = evt.currentTarget;
        currentShape === null || currentShape === void 0 ? void 0 : currentShape.fill(fill);
    }; };
    var handleOnMouseOut = function (fill) { return function (evt) {
        var currentShape = evt.currentTarget;
        currentShape === null || currentShape === void 0 ? void 0 : currentShape.fill(fill);
    }; };
    var handleOnContextMenu = function (date) { return function (_a) {
        var evt = _a.evt;
        evt.stopPropagation();
        evt.preventDefault();
        handleContextMenu === null || handleContextMenu === void 0 ? void 0 : handleContextMenu({ x: evt.clientX, y: evt.clientY, date: date });
    }; };
    var rectWidth = width / 7;
    return (_jsxs(Group, __assign({ x: x, y: y }, { children: [__spreadArray([], Array(7), true).map(function (_, i) {
                var _a;
                var date = DateTime.fromISO(startDate, { zone: "utc" })
                    .plus({ days: i })
                    .startOf("day");
                var key = "WeekDay" + date.toISO();
                var rectX = rectWidth * i;
                var isSelected = selectedDate === date.toISO();
                var dark = darkDays[date.toFormat("yyyy-MM-dd")];
                var outOfRange = !ignoreOutOfRange && month && date.month !== month;
                var weekend = date.weekday > 5;
                var isToday = showToday &&
                    (currentDate
                        ? DateTime.fromISO(currentDate, { zone: "utc" }).startOf("day")
                        : DateTime.local().startOf("day")).toISODate() === date.toISODate();
                var fill = outOfRange
                    ? outOfRangeCellBackgroundColor
                    : dark
                        ? inactiveCellBackgroundColor
                        : weekend
                            ? weekendCellBackgroundColor
                            : cellBackgroundColor;
                var hover = hoverColor !== null && hoverColor !== void 0 ? hoverColor : "";
                var adjustedLabelColor = "rgba(255,255,255,0.85)";
                // Swallow the error thrown from Color constructor if an RGBA string is passed from
                // a legacy calendar.
                try {
                    var convertedFill = (_a = rgbaConversions[fill]) !== null && _a !== void 0 ? _a : fill;
                    hover = Color(convertedFill).isLight()
                        ? Color(convertedFill).darken(0.05).hex()
                        : Color(convertedFill).lighten(0.05).hex();
                    adjustedLabelColor = Color(convertedFill).isLight()
                        ? labelColor
                        : "rgba(255,255,255,0.9)";
                }
                catch (_b) {
                    adjustedLabelColor = adjustedLabelColor;
                }
                var additionalText = dark === null || dark === void 0 ? void 0 : dark.join(", ");
                var travelDay = travel === null || travel === void 0 ? void 0 : travel.join().includes(date.toISODate());
                return (_jsxs(Group, __assign({ onContextMenu: handleOnContextMenu(date.toISO()), onDblClick: handleOnContextMenu(date.toISO()), x: rectX, y: 0 }, { children: [travelDay ? (_jsx(Html, { children: _jsx("p", __assign({ className: "absolute text-sm text-rose-darkest pointer-events-none", style: { top: 6, left: rectWidth - 24 } }, { children: _jsx(FontAwesomeIcon, { icon: faPlane }, void 0) }), void 0) }, void 0)) : null, _jsx(Rect, { x: 0, y: 0, fill: fill, width: rectWidth, height: height, stroke: borderColor, strokeWidth: STROKE_WIDTH, onClick: handleOnDayClick(date.toISO()), onMouseOver: handleOnMouseOver(hover), onMouseOut: handleOnMouseOut(fill) }, void 0), isSelected ? (_jsx(Rect, { x: 2, y: 2, width: rectWidth - 4, height: height - 4, dash: DASH_SIZE, strokeWidth: SELECTED_STROKE_WIDTH, stroke: selectedColor }, void 0)) : null, _jsx(WeekDayLabel, { x: 8, y: 4, font: FONT, today: isToday, todayFillColor: todayFillColor, labelColor: adjustedLabelColor, date: date.toISO(), additionalText: additionalText, width: rectWidth - (travelDay ? 30 : 0), limited: limited }, void 0)] }), key));
            }), number ? (_jsx(WeekNumberLabel, { number: number, font: FONT, borderColor: borderColor, x: rectWidth, y: 0, limited: limited }, void 0)) : null] }), void 0));
};
