import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { snapshots } from "../actions"
import { snapshotSelector } from "../selectors"

export const useSnapshot = (
  subdomain: string,
  calendarId: string,
  snapshotId: string
) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(snapshots.requestSnapshot(subdomain, calendarId, snapshotId))
  const snapshot = useSelector(snapshotSelector.find)(snapshotId)
  const { loading, refetch } = useRequest(request)
  return { loading, snapshot, refetch }
}
