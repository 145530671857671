import React, { Component } from "react"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import { DropTarget } from "react-dnd"
import { EventView, EventTargetView } from "ui"
import styles from "./MonthView.module.css"
import { DATE_FORMAT } from "../constants"
import moment from "moment"
import clsx from "clsx"

class WeekView extends Component {
  constructor(props) {
    super(props)
    this.state = { positionMap: null }
  }

  generatePositionMap() {
    const { positionMap } = this.state
    const { partitions } = this.props
    if (positionMap !== null) {
      return
    }
    const newMap =
      partitions.map((eventRows) => eventRows.map((r) => r[0].position)) || []
    this.setState({ positionMap: newMap })
  }

  clearPositionMap() {
    this.setState({ positionMap: null })
  }

  /**
   * Renders an event name taking into account the item and event name.
   *
   * @param {Object} event An event to extract a name from.
   * @param {Object} item An item to extract a name from.
   * @return {String} The completed name to display on the event.
   */
  renderNameFor(event, item) {
    if (event && event.name) {
      return event.name
    }
    if (item && item.name) {
      return item.name
    }
    return "untitled"
  }

  /**
   * Determines the offset in days between two events.
   *
   * @param {Object} event An event to calculate an offset.
   * @param {Object} prevEvent The adjacent event to use as a reference point.
   * @param {Boolean} starts A flag indicating whether or not the event started on the current week.
   * @return {Integer} The value in days between the two events.
   */
  offsetForEvent(event, prevEvent, starts) {
    if (!starts) {
      return 0
    }
    const prevEndDay = prevEvent && moment.utc(prevEvent.endsAt).weekday()
    const startDay = moment.utc(event.startsAt).weekday()
    if (!prevEvent) {
      return startDay
    }
    const offset = startDay - prevEndDay
    return offset - 1
  }

  /**
   * Determines the length in days for the current event in the current week.
   *
   * @param {Object} event The event to calculate the length of in days.
   * @param {Boolean} starts Flag indicating whether the event has started on the current calendar week.
   * @param {Boolean} ends Flag indicatingb whether the event has ended on the current calendar week.
   * @return {Integer} The length in days the event takes up on the current calendar week.
   */
  lengthForEvent(event, starts, ends) {
    const endDay = moment.utc(event.endsAt).weekday()
    const startDay = moment.utc(event.startsAt).weekday()
    if (!starts && !ends) {
      return 7
    }
    if (!starts && ends) {
      return endDay + 1
    }
    if (starts && !ends) {
      return 7 - startDay
    }
    return 1 + (endDay - startDay)
  }

  /**
   * Safely extracts a textColor from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS HEX color value.
   */
  getTextColorForStyle(style) {
    return (style && style.textColor) || "#000"
  }

  /**
   * Safely extracts a fillColor from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS HEX color value.
   */
  getFillColorForStyle(style) {
    return (style && style.fillColor) || "#FFF"
  }

  /**
   * Safely extracts a borderRadius from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS HEX color value.
   */
  getBorderRadiusForStyle(style) {
    return (style && style.borderRadius) || "round"
  }

  /**
   * Safely extracts a outlineColor from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS HEX color value.
   */
  getOutlineColorForStyle(style) {
    return (style && style.outlineColor) || "#000"
  }

  /**
   * Safely extracts a textAlignment from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS text-alignment value.
   */
  getTextAlignmentForStyle(style) {
    return (style && style.textAlignment) || "center"
  }

  /**
   * Safely extracts a textDecoration from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS text-decoration value.
   */
  getTextDecorationForStyle(style) {
    return (style && style.textDecoration) || "none"
  }

  /**
   * Safely extracts a fontSize from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {Integer} A CSS font size value.
   */
  getFontSizeForStyle(style) {
    return (style && style.fontSize) || 12
  }

  /**
   * Safely extracts a font from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS font name value.
   */
  getFontForStyle(style) {
    return (style && style.font) || "Arial"
  }

  /**
   * Safely extracts a fontStyle from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS font-style property value.
   */
  getFontStyleForStyle(style) {
    return (style && style.fontStyle) || "normal"
  }

  /**
   * Safely extracts a fontWeight from a supplied style object or
   * returns a default value.
   *
   * @param {Object} style The style to extract from.
   * @return {String} A CSS font-weight value.
   */
  getFontWeightForStyle(style) {
    return (style && style.fontWeight) || "normal"
  }

  getPropsForEvent(event, start, fontSizeOverride, reportCalendar) {
    const { findItem, findStyle, findCategory } = this.props

    const startsAt = DateTime.fromISO(event.startsAt, { zone: "utc" })
    const viewStartsAt = DateTime.fromISO(start, { zone: "utc" })
    const item = findItem(event.itemUuid, event.calendarId)
    const category = item && findCategory(item.categoryUuid, item.calendarId)
    const style = findStyle(
      event.applicableStyleId,
      event.snapshotId || event.calendarId
    )
    return {
      name: this.renderNameFor(event, item),
      renderAsNote: (category && category.name === "Notes") || false,
      notes: event.notes,
      startsAt,
      viewStartsAt,
      length: event.length,
      style: {
        borderRadius: this.getBorderRadiusForStyle(style),
        textColor:
          reportCalendar && reportCalendar.showTint
            ? reportCalendar.tintTextColor
            : this.getTextColorForStyle(style),
        fillColor:
          reportCalendar && reportCalendar.showTint
            ? reportCalendar.tintFillColor
            : this.getFillColorForStyle(style),
        outlineColor:
          reportCalendar && reportCalendar.showTint
            ? reportCalendar.tintOutlineColor
            : this.getOutlineColorForStyle(style),
        textAlignment: this.getTextAlignmentForStyle(style),
        textDecoration: this.getTextDecorationForStyle(style),
        font: this.getFontForStyle(style),
        fontStyle: this.getFontStyleForStyle(style),
        fontSize: fontSizeOverride || this.getFontSizeForStyle(style),
        fontWeight: this.getFontWeightForStyle(style),
      },
      fontSizeOverride,
      recurringEvent: (item || {}).numberOfIterationsRecurring > 0,
      calendarId: event.calendarId,
    }
  }

  /**
   * Accepts a list of event JSON objects and a range for the
   * start and end of the period. Then renders each event for
   * a given row. For conceptual purposes -- a row is not a
   * calendar week. Rather, a calendar week will contain many
   * rows of events that are stacked vertically when event
   * dates overlap.
   *
   * @param   {Array}    events             An array of events for a given row in a given period.
   * @param   {Object}   start              A moment JS object representing the start of the week.
   * @param   {Integer}  partition          The partition this map represents.
   * @param   {Integer}  fontSizeOverride   Overrides the event style for font-size if not null.
   * @param   {Array}    reportCalendars     Specifies any report calendars associated.
   * @return  {ReactClass}                  An array of <EventView> nodes.
   */
  renderEventsForRowInPartition(
    events,
    start,
    partition,
    fontSizeOverride,
    reportCalendars
  ) {
    const props = this.props
    return events.map((event, index) => {
      const reportCalendar = props.reportId
        ? reportCalendars.filter((r) => r.calendarId === event.calendarId)[0]
        : {}
      const eventProps = this.getPropsForEvent(
        event,
        start,
        fontSizeOverride,
        reportCalendar
      )
      const contextHandler = (params) => {
        props.onContextMenu(params)
      }

      return (
        <EventView
          {...eventProps}
          weekStartsAt={DateTime.fromISO(start, { zone: "utc" })}
          calendarId={event.calendarId}
          key={event.hashId}
          uuid={event.uuid}
          breakOnDarkDays={event.interruptedByHolidays}
          breakOnWeekends={event.interruptedByWeekends}
          darkDays={event.interruptions || []}
          simplified={props.simplified || props.limited}
          locked={props.lockedItemUuids.includes(event.itemUuid)}
          partition={partition}
          showDragUI={props.showDragUI}
          position={event.position}
          selectedEvents={props.selectedEvents}
          selected={props.selectedEvents.includes(event.uuid)}
          includedOnClipboard={props.clipboardEvents.includes(event.uuid)}
          ignored={props.ignoreEvents}
          readOnly={props.readOnly}
          printable={props.forPrint}
          prefix={reportCalendar.showPrefix ? reportCalendar.prefix : ""}
          onEdit={props.handleEditEvent}
          onSelect={props.handleSelectedEvent}
          onDrag={(itemType, dragEvent, uuid, calendarId, offset) => {
            this.handleDrag(itemType, dragEvent, uuid, calendarId, offset)
          }}
          dragging={this.state.dragging === event.uuid}
          sibling={index > 0}
          onContextMenu={contextHandler}
        />
      )
    })
  }

  handleDrag(itemType, dragEvent, uuid, calendarId, offset) {
    if (itemType === "EventItemType/SEGMENT") {
      switch (dragEvent) {
        case "start":
          this.props.beginEventDrag(uuid, calendarId, offset)
          this.setState({ dragging: uuid })
          break
        case "end":
          this.props.endEventDrag(uuid, calendarId)
          this.setState({ dragging: null })
          break
        default:
          break
      }
    } else {
      switch (dragEvent) {
        case "start":
          this.props.beginExpandDrag()
          break
        case "end":
          this.props.endExpandDrag(uuid, calendarId)
          break
        default:
          break
      }
    }
    this.props.handleSelectedEvent(uuid, calendarId)
  }

  /**
   * Renders a grid of drop targets for drag and drop interaction. See the `EventTargetView`
   * component for more details on how the drop targets function. This grid is intended to
   * be invisible to the user and enables the complex drag and drop sorting interaction needed
   * for the calendar. The actual event objects are not sortable, rather this grid updates
   * the redux store so that the calendar can be re-rendered as the user drags.
   *
   * @param {Object} start                A momentJS date containing the start of a given week.
   * @param {Array} positionMap           A multidimensional array containing rows of events for the current calendar week.
   * @param {Integer} partition           The partition this map represents.
   * @param {Integer} fontSizeOverride    A calculated font-size that overrides the default font size on crowded weeks.
   * @returns {ReactClass}                A <div> containing a grid of <EventTargetView> nodes.
   */
  renderDragUIForPartition(start, positionMap, partition, fontSizeOverride) {
    const {
      shiftEventPreview,
      endEventDrag,
      limited,
      simplified,
      calendarMaxPosition,
      calendarIds,
      expandEventPreview,
    } = this.props
    const partitionPositionMap = positionMap[partition]
    const maxPosition =
      partitionPositionMap.length > 0
        ? partitionPositionMap[partitionPositionMap.length - 1] + 1
        : calendarMaxPosition
    const calendarKey = calendarIds.join("_")

    const handleEventDrop = async (
      uuid,
      calendarId,
      date,
      position,
      partition
    ) => {
      await shiftEventPreview(uuid, calendarId, date, position, partition)
      await endEventDrag(uuid, calendarId, date)
    }

    const handleEventExpand = async (uuid, calendarId, date, reverse) =>
      await expandEventPreview(uuid, calendarId, date, reverse ? -1 : 1)

    return (
      <div
        className={`${styles.dragUIContainer} ${
          simplified && styles.simplified
        } ${limited && styles.limited}`}
      >
        {Array(7)
          .fill()
          .map((_, dayOfweek) => {
            const date = moment.utc(start).day(dayOfweek)
            const keyBase = `dragTarget_${calendarKey}_${date.format(
              DATE_FORMAT
            )}`
            return (
              <div key={keyBase} className={styles.dragUIColumn}>
                {Array(partitionPositionMap.length + 1)
                  .fill()
                  .map((__, row, arr) => {
                    const position =
                      row < partitionPositionMap.length
                        ? partitionPositionMap[row]
                        : maxPosition
                    return (limited && row > 1) || (simplified && row > 3) ? (
                      ""
                    ) : (
                      <EventTargetView
                        key={`${keyBase}_${row}_${position}`}
                        position={position}
                        date={date}
                        onDrop={handleEventDrop}
                        onExpand={handleEventExpand}
                        calendarId={calendarIds[0]}
                        simplified={simplified}
                        limited={limited}
                        partition={partition}
                        fontSizeOverride={fontSizeOverride}
                        fontSize={12}
                        grow={row === arr.length - 1}
                      />
                    )
                  })}
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    const {
      showDragUI,
      simplified,
      limited,
      handleMouseClick,
      handleSelectWeek,
      start,
      connectDropTarget,
      isDraggingEventOver,
      readOnly,
      forPrint,
      findReportCalendars,
      reportId,
      partitions,
      maxRowsBeforeScale,
      height,
    } = this.props
    const reportCalendars = reportId ? findReportCalendars({ reportId }) : []
    const { positionMap } = this.state

    const totalRows = partitions.reduce(
      (total, partition) => total + partition.length,
      0
    )

    const debug = window.location.href.indexOf("debug=true") > -1

    var fontSizeOverride =
      totalRows >= maxRowsBeforeScale
        ? Math.min(9, height / totalRows / 1.5)
        : null
    fontSizeOverride =
      maxRowsBeforeScale === 1 && fontSizeOverride ? 6 : fontSizeOverride

    const showMore = forPrint
      ? false
      : (limited && totalRows > 3) || (simplified && totalRows > 4)

    return connectDropTarget(
      <div
        className={clsx(
          styles.eventsContainer,
          (simplified || forPrint) && styles.simplified,
          (limited || forPrint) && styles.limited
        )}
        style={{
          pointerEvents: showDragUI ? "all" : "none",
          overflow: "hidden",
        }}
        onClick={() => {
          handleMouseClick()
        }}
      >
        {partitions.map((eventRows, partition) =>
          forPrint && eventRows.length < 1 && totalRows > 5 ? null : (
            <div
              className={clsx(
                styles.dayPartition,
                debug && "bg-yellow-300 border-b border-orange-500"
              )}
              key={`partition${partition}week${start}`}
            >
              {eventRows.map((events, i) => {
                return (!forPrint && limited && i > 2) ||
                  (!forPrint && simplified && i > 3) ? (
                  ""
                ) : (
                  <div className={styles.eventRow} key={`row${i}week${start}`}>
                    {events
                      ? this.renderEventsForRowInPartition(
                          events,
                          start,
                          partition,
                          fontSizeOverride,
                          reportCalendars
                        )
                      : null}
                  </div>
                )
              })}
              {!readOnly &&
                isDraggingEventOver &&
                positionMap &&
                this.renderDragUIForPartition(
                  start,
                  positionMap,
                  partition,
                  fontSizeOverride
                )}
            </div>
          )
        )}
        {showMore && (
          <div className={styles.eventRow}>
            <span
              onClick={() => handleSelectWeek && handleSelectWeek(start)}
              className={`${styles.moreView} ${
                simplified && styles.simplified
              } ${limited && styles.limited}`}
            >
              See More This Week
            </span>
          </div>
        )}
      </div>
    )
  }
}

WeekView.propTypes = {
  /**
   * The highest possible position on the calendar.
   */
  calendarMaxPosition: PropTypes.number.isRequired,

  /**
   * Indicates whether or not events should be treated
   * as interactive elements or have their pointer events
   * removed.
   */
  ignoreEvents: PropTypes.bool.isRequired,

  /**
   * Indicates whether or not the view should render in a
   * simplified format due to limited screen real estate.
   */
  simplified: PropTypes.bool,

  /**
   * Indicates whether or not the view should render in a
   * an extremely simplified format due to limited screen
   * real estate.
   */
  limited: PropTypes.bool,

  /**
   * An event handler that accepts a moment representing a
   * week to display.
   */
  handleSelectWeek: PropTypes.func,

  /**
   * The event objects for the current week mapped to non-conflicting
   * rows which are groupped to corresponding day segements for rendering
   * purposes.
   */
  partitions: PropTypes.array.isRequired,

  /**
   * The start of the week.
   */
  start: PropTypes.string.isRequired,

  /**
   * The end of the week.
   */
  end: PropTypes.string.isRequired,

  /**
   * An array of item IDs that have been locked by the user.
   */
  lockedItemUuids: PropTypes.array.isRequired,

  /**
   * A selector that allows us to fetch an applicable style
   * from the current redux state.
   */
  findStyle: PropTypes.func.isRequired,

  /**
   * The ID of the calendar currently being presented.
   */
  calendarIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * Indicates whether or not the drag you should be rendered
   * internally on any active child event views currently being
   * dragged.
   */
  showDragUI: PropTypes.bool.isRequired,

  /**
   * A selector function that retrieves a specific item.
   */
  findItem: PropTypes.func.isRequired,

  /**
   * A selector function that retrieves a specific category.
   */
  findCategory: PropTypes.func.isRequired,

  /**
   * A callback that is passed down into event views to handle
   * the event they are selected by the user.
   */
  handleSelectedEvent: PropTypes.func.isRequired,

  /**
   * An array of event IDs that have been marked as selected
   * by the user.
   */
  selectedEvents: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * An action creator that handles updates to an event expand
   * drag and drop event.
   */
  expandEventPreview: PropTypes.func.isRequired,

  /**
   * An action dispatcher that toggles whether or not an event on the
   * calendar has started an expand interaction
   */
  beginExpandDrag: PropTypes.func.isRequired,

  /**
   * An action dispatcher that toggles whether or not an event on the
   * calendar has started an expand interaction
   */
  endExpandDrag: PropTypes.func.isRequired,

  /**
   * An action dispatcher that updates the current state of an event.
   */
  endEventDrag: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This method
   * updates the local event cache to display a date or position change for
   * any local events. This is used as an optimistic update to give the user
   * positive visual feedback of their action. For example, updating the API
   * while a drag action is in progress would overload the API and latency
   * would make such an interaction unresponsive and impractical.
   */
  shiftEventPreview: PropTypes.func.isRequired,

  /**
   * A callback passed down into the event views to handle
   * any possible mouse activity from the user.
   */
  handleMouseDown: PropTypes.func.isRequired,

  /**
   * A callback to handle any click on the events container.
   */
  handleMouseClick: PropTypes.func.isRequired,

  /**
   * An array of IDs that are currently stored in the clipboard.
   */
  clipboardEvents: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Indicates if an event is currently getting dragged over this
   * view.
   */
  isDraggingEventOver: PropTypes.bool.isRequired,

  /**
   * Utility function that allows us to assign JSX to a ReactDND
   * drop target.
   */
  connectDropTarget: PropTypes.func.isRequired,

  /**
   * A redux action that clears the drag interations from the calendar views.
   */
  clearDragInteractions: PropTypes.func.isRequired,

  /**
   * Ensures that editing cannot be performed if set to true.
   */
  readOnly: PropTypes.bool.isRequired,

  /**
   * Affects formatting and sizing of events for maximum use of space
   * while printing.
   */
  forPrint: PropTypes.bool,

  /**
   * The id of the parent report if one is being rendered. This is null when rendering the standard calendar.
   */
  reportId: PropTypes.string,

  /**
   * A redux selector that queries an associated report calendar for the parent report if present.
   */
  findReportCalendar: PropTypes.func.isRequired,

  /**
   * A redux selector that queries all report calendars for the parent report if present.
   */
  findReportCalendars: PropTypes.func.isRequired,

  /**
   * Any event we want to manually inject into the view. These events will be ignored if they
   * are not within the date range
   */
  specialEvents: PropTypes.arrayOf(PropTypes.object),

  /**
   * A callback that is fired when a child item requests the context menu.
   */
  onContextMenu: PropTypes.func,

  /**
   * The maximum amount of rows visible before autoscaling is applied.
   */
  maxRowsBeforeScale: PropTypes.number,

  /**
   * The height of the view.
   */
  height: PropTypes.number,
}

const spec = {
  hover(_, monitor, component) {
    monitor.isOver()
      ? component.generatePositionMap()
      : component.clearPositionMap()
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isDraggingEventOver: monitor.isOver(),
  }
}

export default DropTarget(["EventItemType/SEGMENT"], spec, collect)(WeekView)
