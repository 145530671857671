import { useApiRequest } from "./useApiRequest"

export interface ImportCalendarBody {
  id?: number
  name: string
  groupUuid?: string
  file: string
  subdomain: string
}

export interface ImportCalendarOutput {
  success: boolean
  calendar: ImportCalendarBody
  errors: Object[]
}

export interface ImportCalendarResponse {
  data: ImportCalendarOutput
}

export const useImportCalendar = () => {
  const getUpload = useApiRequest<ImportCalendarResponse, ImportCalendarBody>({
    method: "POST",
    url: `//${process.env.REACT_APP_API_V2_BASE_DOMAIN}/calendars/import`,
  })
  return getUpload
}
