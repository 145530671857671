import React from "react"
import PropTypes from "prop-types"
import styles from "./Group.module.css"

const Group = ({ error, children }) => (
  <div className={`form-group ${styles.group} ${error && styles.error}`}>
    {children}
  </div>
)

Group.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
}

export default Group
