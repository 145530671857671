import React from "react"
import { StylableProps } from "./Styleable"
import clsx from "clsx"

export interface TitleProps {
  responsive?: boolean
}

export const Title: React.FC<StylableProps & TitleProps> = ({
  children,
  className,
  style,
  responsive,
}) => (
  <h2
    className={clsx(
      "flex border-b border-gray-400 mb-4 pb-2 font-semibold",
      responsive ? "text-sm md:text-lg" : "",
      className
    )}
    style={style}
  >
    {children}
  </h2>
)
