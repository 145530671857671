var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState, } from "react";
import { WeekViewKonva } from "./WeekViewKonva";
import { Layer, Stage } from "react-konva";
import { useResizeObserver, useScrollableCanvas } from "../../hooks";
import { DateTime } from "luxon";
import clsx from "clsx";
import { WeekViewDnD } from "./WeekViewDnD";
import { ScrollBar } from "./ScrollBar";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
export var weekCanvasState = atom({
    key: "weekCanvasState",
    default: {
        anchorDate: DateTime.utc(),
        initialDate: DateTime.utc(),
        lastAdjustedDate: DateTime.utc(),
        scrollCursorDate: DateTime.utc(),
    },
});
export var useWeekCanvasState = function () { return useRecoilValue(weekCanvasState); };
export var useSetWeekCanvasState = function () { return useSetRecoilState(weekCanvasState); };
var SCROLL_TIMEOUT = 1500;
var FLOATING_HEADING_STYLES = "text-lochivar-default py-2 px-3 text-2xl absolute top-0 left-0 ml-2 my-1 bg-opacity-75 transition-opacity ease-out delay-300 duration-1000 bg-white rounded-full z-20 pointer-events-none";
var SCROLL_BAR_WIDTH = 16;
export var WeekCanvas = function (_a) {
    var _b, _c;
    var className = _a.className, _d = _a.weeksVisible, weeksVisible = _d === void 0 ? 6 : _d, _e = _a.minViewportHeight, minViewportHeight = _e === void 0 ? 900 : _e, children = _a.children, weekNumberForWeek = _a.weekNumberForWeek, showMonthHeadings = _a.showMonthHeadings, props = __rest(_a, ["className", "weeksVisible", "minViewportHeight", "children", "weekNumberForWeek", "showMonthHeadings"]);
    var _f = useRecoilValue(weekCanvasState), lastAdjustedDate = _f.lastAdjustedDate, initialDate = _f.initialDate, anchorDate = _f.anchorDate;
    var _g = useScrollableCanvas(), scroll = _g.scroll, setScroll = _g.setScroll, scrolling = _g.scrolling, setScrolling = _g.setScrolling, htmlProps = _g.htmlProps, stageProps = _g.stageProps, layerProps = _g.layerProps;
    var _h = useState(52), maxScrollableWeeks = _h[0], setMaxScrollableWeeks = _h[1];
    var _j = useState(0), lastIndex = _j[0], setLastIndex = _j[1];
    var konvaWrapper = useRef(null);
    var stage = useRef(null);
    var size = useResizeObserver({ element: konvaWrapper }).size;
    var weekHeight = Math.max(size.height, minViewportHeight) / weeksVisible;
    var index = scroll ? Math.floor(-scroll / weekHeight) : 0;
    var effectiveDate = initialDate;
    var setWeekCanvasState = useSetWeekCanvasState();
    useEffect(function () {
        if (lastAdjustedDate !== anchorDate) {
            var targetDate_1 = (anchorDate !== null && anchorDate !== void 0 ? anchorDate : DateTime.utc())
                .startOf("week")
                .minus({ days: 1 });
            var originDate = initialDate.startOf("week").minus({ days: 1 });
            var weeks = Math.round(targetDate_1.diff(originDate, "weeks").weeks);
            setWeekCanvasState(function (state) { return (__assign(__assign({}, state), { anchorDate: targetDate_1, lastAdjustedDate: targetDate_1 })); });
            setScroll(-weeks * weekHeight);
            setScrolling(true);
        }
    }, [
        anchorDate,
        initialDate,
        lastAdjustedDate,
        setScroll,
        setScrolling,
        setWeekCanvasState,
        weekHeight,
    ]);
    var scrollTimeout = useRef(null);
    useEffect(function () {
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }
        scrollTimeout.current = setTimeout(function () {
            setScrolling(false);
        }, SCROLL_TIMEOUT);
    }, [scroll, scrollTimeout, setScrolling]);
    useEffect(function () {
        if (index !== lastIndex) {
            setLastIndex(index);
            setWeekCanvasState(function (state) { return (__assign(__assign({}, state), { scrollCursorDate: effectiveDate
                    .startOf("week")
                    .plus({ weeks: index })
                    .minus({ days: 1 }) })); });
            if (Math.abs(index) > maxScrollableWeeks / 2 - 5) {
                setMaxScrollableWeeks(maxScrollableWeeks + 10);
            }
        }
    }, [
        lastIndex,
        setLastIndex,
        index,
        setMaxScrollableWeeks,
        maxScrollableWeeks,
        setWeekCanvasState,
        effectiveDate,
    ]);
    var renderWeeks = function (renderWeek) {
        return Array(weeksVisible + 1)
            .fill(0)
            .map(function (_, i) {
            var weekIndex = index + i;
            var weekStart = effectiveDate
                .startOf("week")
                .plus({ weeks: weekIndex + (weeksVisible < 3 ? 1 : 0) })
                .minus({ days: 1 })
                .startOf("day")
                .toUTC();
            var weekTop = scroll + weekHeight * weekIndex;
            return renderWeek({
                isoDate: weekStart.toISODate(),
                date: weekStart,
                top: weekTop,
                height: weekHeight,
            });
        });
    };
    var contentHeight = weekHeight * maxScrollableWeeks;
    var handleScroll = useCallback(function (y) {
        setScroll(y);
    }, [setScroll]);
    var handleScrollClick = function (e) {
        e.preventDefault();
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: clsx("inset-0 absolute", className), ref: konvaWrapper }, htmlProps, { children: [_jsx(Stage, __assign({ height: size.height, width: size.width, ref: stage }, stageProps, { children: _jsx(Layer, __assign({}, layerProps, { children: renderWeeks(function (_a) {
                            var isoDate = _a.isoDate, date = _a.date, top = _a.top;
                            return (_jsx(WeekViewKonva, __assign({}, props, { startDate: isoDate, x: 0, y: top, height: weekHeight, width: size.width, month: date.month, number: weekNumberForWeek === null || weekNumberForWeek === void 0 ? void 0 : weekNumberForWeek(date), ignoreOutOfRange: true }), isoDate));
                        }) }), void 0) }), void 0), renderWeeks(function (_a) {
                    var isoDate = _a.isoDate, top = _a.top, date = _a.date, rest = __rest(_a, ["isoDate", "top", "date"]);
                    var endDate = date.plus({ days: 1 }).endOf("week");
                    var newMonth = showMonthHeadings && date.month !== endDate.month;
                    return (_jsxs("div", __assign({ className: "absolute w-full left-0 top-0", style: {
                            transform: "translate3d(0, " + top + "px, 0)",
                            height: weekHeight,
                            pointerEvents: "none",
                        } }, { children: [newMonth ? (_jsxs("h1", __assign({ className: clsx(FLOATING_HEADING_STYLES, !scrolling && "opacity-0") }, { children: [_jsx("span", __assign({ className: "font-bold" }, { children: endDate.monthLong }), void 0), " ", endDate.year] }), void 0)) : null, _jsx(WeekViewDnD, __assign({}, props, { startDate: isoDate }), void 0), children === null || children === void 0 ? void 0 : children(__assign({ isoDate: isoDate, top: top, date: date, newMonth: newMonth }, rest))] }), "htmlContents" + isoDate));
                }), _jsx("div", __assign({ className: "absolute inset-y-0 right-0 cursor-pointer z-20", onClick: handleScrollClick }, { children: _jsx(Stage, __assign({ height: size.height, width: SCROLL_BAR_WIDTH, ref: stage }, { children: _jsx(Layer, { children: _jsx(ScrollBar, { x: 0, y: 0, width: SCROLL_BAR_WIDTH, height: size.height, scroll: scroll, onScroll: handleScroll, contentHeight: contentHeight, offset: (_c = (_b = stage.current) === null || _b === void 0 ? void 0 : _b.getClientRect().y) !== null && _c !== void 0 ? _c : 0 }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0) }, void 0));
};
