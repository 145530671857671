import React, { useRef, useEffect } from "react"
import { MultiSelectField } from "ui"
import { useSetKeeperCalendars } from "../../api"

export const SetKeeperCalendarSelectField: React.FC<{
  projectId: string
  value?: string | null
  onSelect: (value: string | null) => void
  className?: string
}> = ({ onSelect: handleSelect, value, projectId, className }) => {
  const [requestCalendars, { data }] = useSetKeeperCalendars()

  const fetchedProjectId = useRef(null as null | string)
  useEffect(() => {
    if (fetchedProjectId.current === projectId) return
    requestCalendars({
      body: { projectId },
    })
    fetchedProjectId.current = projectId
  })
  const projects = data ?? []
  return (
    <MultiSelectField
      name="calendar"
      label="Which SetKeeper Calendar do You Wish to Sync"
      options={projects?.map((p) => ({
        label: p.name ?? `ID: ${p.id}`,
        value: `${p.id}`,
        id: `${p.id}`,
      }))}
      value={value ?? undefined}
      onSelect={handleSelect}
      placeholder="Select a Calendar"
      closeMenuOnSelect={true}
      className={className}
    />
  )
}
