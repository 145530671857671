import React from "react"
import PropTypes from "prop-types"
import styles from "./FolderIcon.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFolderOpen,
  faAngleDown,
  faFolder,
  faAngleRight,
} from "@fortawesome/pro-regular-svg-icons"

const FolderIcon = ({ open }) => (
  <span className={styles.folderIconGroup}>
    <FontAwesomeIcon
      className={styles.folderCaret}
      icon={open ? faAngleDown : faAngleRight}
    />
    <FontAwesomeIcon
      className={styles.folderIcon}
      icon={open ? faFolderOpen : faFolder}
    />
  </span>
)

FolderIcon.propTypes = {
  /**
   * If true the folder view should indicate that it
   * is in a maximized state.
   */
  open: PropTypes.bool.isRequired,
}

export default FolderIcon
