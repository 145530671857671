import React from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import {
  Button,
  TextField,
  HandleFileUploadFn,
  Fields,
  ErrorList,
  HandleDeleteFn,
  Indicators,
  SimpleSelectField,
  Group,
  GroupField,
} from "ui"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { DocumentUploader } from "./DocumentUploader"

export type CalendarImportFormValues = {
  name: string
  groupUuid: string
  file: string
  dateProcessing: string
}

interface CalendarImportFormProps {
  onSave?: (calendar: CalendarImportFormValues) => Promise<void>
  defaultValues?: CalendarImportFormValues
  loading?: boolean
  groups: Group[]
  onCreateGroup?: (value: string) => Promise<string | null | void>
}

const schema = yup.object({
  name: yup.string().required(),
  group: yup.string(),
  file: yup.string().required(),
  dateProcessing: yup.string().required(),
})

export const CalendarImportForm: React.FC<CalendarImportFormProps> = ({
  defaultValues,
  onSave,
  groups,
  onCreateGroup: handleCreateGroup,
  loading = false,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CalendarImportFormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues,
  })

  const [groupUuid] = watch(["groupUuid"])

  const handleSelectCreateGroup = async (value: string) => {
    const result = await handleCreateGroup?.(value)
    if (result) {
      setValue("groupUuid", result)
    }
  }

  const handleGroupSelect = (newVal: string | null) => {
    setValue("groupUuid", newVal ?? "", {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const onSubmit: SubmitHandler<CalendarImportFormValues> = async (data) => {
    if (onSave) {
      try {
        await onSave({ ...defaultValues, ...data })
      } catch (e) {
        console.error(e)
      }
    }
  }
  const handleUpload: HandleFileUploadFn = (file) => {
    setValue("file", file.id, { shouldValidate: true, shouldDirty: true })
  }

  const handleRemoveFile: HandleDeleteFn = () => {
    setValue("file", "", { shouldValidate: true, shouldDirty: true })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      {loading ? <Indicators.AbsoluteOverlay /> : null}
      <Fields register={register} errors={errors} grow>
        <TextField
          name="name"
          label="Calendar Name"
          placeholder="Calendar Name"
          className="w-full"
          required
        />
        <GroupField
          name="groupUuid"
          label="Calendar Group (folder)"
          placeholder="(optional)"
          options={groups}
          value={groupUuid}
          onSelect={handleGroupSelect}
          onCreate={handleSelectCreateGroup}
          creatable={handleCreateGroup ? true : false}
          className="w-full"
          clearable
          selfControllable
        />
        <input type="hidden" name="groupUuid" />
        <input type="hidden" name="file" />
        <DocumentUploader
          onUpload={handleUpload}
          onDelete={handleRemoveFile}
          maxFiles={1}
        />
        <SimpleSelectField
          label="Date Processing:"
          name="dateProcessing"
          options={[
            { label: "Use exact dates from excel.", value: "exact" },
            { label: "Events to only occur Mon-Fri.", value: "workweek" },
          ]}
          className="w-full"
        />
        <ErrorList errors={errors as any} />
      </Fields>
      <Button type="submit" className="mt-2 w-full">
        Import Calendar
      </Button>
    </form>
  )
}
