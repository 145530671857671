import React from "react"
import PropTypes from "prop-types"
import styles from "./CardTitle.module.css"

const CardTitle = ({ children }) => (
  <h2 className={styles.cardTitle}>{children}</h2>
)

CardTitle.propTypes = {
  children: PropTypes.node,
}

export default CardTitle
