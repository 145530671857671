import { CALL_API, Schemas } from "../middlewares"
import { PROTECTED } from "redux-jwt-protected-middleware"
import LogRocket from "logrocket"
import * as types from "../actionTypes"
import jwtDecode from "jwt-decode"
import { camelizeKeys } from "humps"
import { isNotBlank } from "../../utils/helpers"

import {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  setEmail,
  getEmail,
} from "../tokens"

export const fetchCurrentUser = () => ({
  type: types.FETCH_CURRENT_USER,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.USER,
    method: "GET",
    endpoint: "/api/me",
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_CURRENT_USER_REQUEST,
      types.FETCH_CURRENT_USER_SUCCESS,
      types.FETCH_CURRENT_USER_FAILURE,
    ],
  },
})

export const requestCurrentUser = () => (dispatch) => {
  dispatch(fetchCurrentUser()).then((res) => {
    try {
      const { id, email } = Object.values(res.payload.entities.users)[0]
      LogRocket.identify(id, {
        email,
      })
    } catch (e) {
      console.error("Could not log current user.")
    }
  })
}

export const fetchAuthToken = (creds) => ({
  type: types.FETCH_AUTH_TOKEN,
  [CALL_API]: {
    method: "POST",
    endpoint: "/oauth/token",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `username=${encodeURIComponent(
      creds.username
    )}&password=${encodeURIComponent(creds.password)}&grant_type=password`,
    types: [
      types.FETCH_AUTH_TOKEN_REQUEST,
      types.FETCH_AUTH_TOKEN_SUCCESS,
      types.FETCH_AUTH_TOKEN_FAILURE,
    ],
  },
})

const getDecodedToken = (accessToken) => {
  return accessToken && camelizeKeys(jwtDecode(accessToken))
}

export const updateAuthRoles = (accessToken) => {
  const decodedToken = getDecodedToken(accessToken)
  return {
    type: types.AUTH_ROLE_UPDATE,
    payload: {
      admin: decodedToken && decodedToken.user && decodedToken.user.admin,
      superAdmin:
        decodedToken && decodedToken.user && decodedToken.user.superAdmin,
    },
  }
}

export const updateAuthStatus = () => ({
  type: types.UPDATE_AUTH_STATUS,
  payload: {
    isAuthenticated: isNotBlank(getAccessToken()),
  },
})

export const tokenRefreshFailure = () => (dispatch) => {
  if (isNotBlank(getEmail())) {
    dispatch({
      type: types.FETCH_AUTH_TOKEN_EXPIRED,
      payload: {
        needsPassword: true,
      },
    })
  } else {
    dispatch(logoutUser())
  }
}

export const loadAuthToken =
  (creds = {}) =>
  (dispatch) => {
    return dispatch(fetchAuthToken(creds)).then((action) => {
      if (action.payload && action.payload.accessToken) {
        setEmail(creds.username)
        setAccessToken(action.payload.accessToken)
        setRefreshToken(action.payload.refreshToken)
        dispatch(updateAuthStatus())
      }
      return action
    })
  }

export const requestLogout = () => ({
  type: types.LOGOUT_REQUEST,
  isFetching: true,
  isAuthenticated: true,
})

export const receiveLogout = () => ({
  type: types.LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false,
})

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout())
  setAccessToken("")
  setRefreshToken("")
  setEmail("")
  dispatch(receiveLogout())
}
