import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"
import * as cat from "../selectors/categorySelector"
import * as cal from "../selectors/calendarSelector"
import queryString from "query-string"
import { optimisticEntityUpdate, optimisticEntityDelete } from "./entities"
import { ENTITY_TYPES } from "../constants"

//
// GET / Find all OR by :category
//

/**
 * Action creator that generates an API call to fetcb all events
 * or a specific event by ID.
 *
 * @param  {String} subdomain     The id of the organization which the category of the event belongs to.
 * @param  {Integer} calendarId   The id of the category the event belongs to.
 * @param  {Integer} categoryUuid   The (optional) id of the category to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getCategory = (subdomain, calendarId, categoryUuid) => ({
  type: types.FETCH_CATEGORIES,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: categoryUuid ? Schemas.CATEGORY : Schemas.CATEGORIES_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${
      categoryUuid || ""
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.FETCH_CATEGORIES_REQUEST,
      types.FETCH_CATEGORIES_SUCCESS,
      types.FETCH_CATEGORIES_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetcb all events
 * or a specific event by ID. This is a convenience method that wraps
 * the getCategory() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Integer}  categoryUuid    The (optional) id of the category to fetch.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const requestCategory =
  (subdomain, calendarId, categoryUuid) => (dispatch) => {
    return dispatch(getCategory(subdomain, calendarId, categoryUuid))
  }

//
// GET / validation errors
//

/**
 * Action creator that generates an API call to validate a new or existing
 * category.
 *
 * @param  {String}  subdomain    The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the category belongs to.
 * @param  {Object}  values       Category data or body for the API call.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const getCategoryValidation = (subdomain, calendarId, values) => ({
  type: types.VALIDATE_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/validations?${queryString.stringify(
      values
    )}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.VALIDATE_CATEGORY_REQUEST,
      types.VALIDATE_CATEGORY_SUCCESS,
      types.VALIDATE_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to validate a new or existing
 * category. This is a convenience method that wraps the getCategoryValidation()
 * creator in a thunk.
 *
 * @param  {String}  subdomain    The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the category belongs to.
 * @param  {Object}  values       Category data or body for the API call.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const requestCategoryValidation =
  (subdomain, calendarId, values) => (dispatch) => {
    return dispatch(getCategoryValidation(subdomain, calendarId, values))
  }

//
// POST / New category
//

/**
 * Action creator that generates an API call to create a new category
 * for a specific calendar.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Integer}  category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const postCategory = (subdomain, calendarId, category) => ({
  type: types.CREATE_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.CATEGORY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys(category)),
    types: [
      types.CREATE_CATEGORY_REQUEST,
      types.CREATE_CATEGORY_SUCCESS,
      types.CREATE_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to create a new category
 * for a specific category. This is a convenience method that wraps
 * the postCategory() creator in a thunk.
 *
 * @param  {String}  subdomain    The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the category belongs to.
 * @param  {Object}  category     Category data or body for the API call.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const createCategory =
  (subdomain, calendarId, category) => (dispatch) => {
    return dispatch(postCategory(subdomain, calendarId, category))
  }

//
// PUT / Update Category
//

/**
 * Action creator that generates an API call to update an existing event
 * against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const putCategory = (subdomain, calendarId, category) => ({
  type: types.UPDATE_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.CATEGORY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${category.uuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys(category)),
    types: [
      types.UPDATE_CATEGORY_REQUEST,
      types.UPDATE_CATEGORY_SUCCESS,
      types.UPDATE_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * event against the API. This is a convenience method that wraps
 * the putCategory() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const updateCategory =
  (subdomain, calendarId, category) => (dispatch) => {
    return dispatch(putCategory(subdomain, calendarId, category))
  }

/**
 * Action creator that generates an API call to update an existing event's
 * parent category against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchCategoryParent = (subdomain, calendarId, category) => ({
  type: types.UPDATE_CATEGORY_PARENT,
  meta: {
    debounce: {
      time: 1250,
      key: `setParentCategory${category.uuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CATEGORY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${category.uuid}/parent`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({ categoryUuid: category.categoryUuid })
    ),
    types: [
      types.UPDATE_CATEGORY_PARENT_REQUEST,
      types.UPDATE_CATEGORY_PARENT_SUCCESS,
      types.UPDATE_CATEGORY_PARENT_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * category against the API. This is a convenience method that wraps
 * the putCategory() creator in a thunk which only modifies the
 * categoryUuid property for a given category by setting it to a supplied
 * param.
 *
 * @param  {Integer}  categoryUuid    The ID of the category to update.
 * @param  {Integer}  parentId        The ID of the new parent category.
 * @return {Promise}                  A promise representing the dispatched redux action creator.
 */
export const updateCategoryCategory =
  (categoryUuid, parentId, calendarId) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    if (!category) {
      return
    }
    const calendar = cal.find(getState())(category.calendarId)
    const updatedCategory = Object.assign({}, category, {
      categoryUuid: parentId,
    })
    dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedCategory
      )
    )
    return dispatch(
      patchCategoryParent(
        calendar.organization,
        category.calendarId,
        updatedCategory
      )
    )
  }

/**
 * Action creator that generates an API call to update an existing event's
 * active status against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchCategoryActive = (subdomain, calendarId, category) => ({
  type: types.UPDATE_CATEGORY_ACTIVE,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CATEGORY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${category.uuid}/activate`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    ignoreCache: true,
    body: JSON.stringify(decamelizeKeys({ active: category.active })),
    types: [
      types.UPDATE_CATEGORY_ACTIVE_REQUEST,
      types.UPDATE_CATEGORY_ACTIVE_SUCCESS,
      types.UPDATE_CATEGORY_ACTIVE_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * event against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the active
 * property for a given category by setting it to true.
 *
 * @param  {Integer}  categoryUuid    The ID of the category to show.
 * @param  {Boolean}  readOnly      If true this action will not be persisted against the API.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const showCategory =
  (categoryUuid, calendarId, readOnly) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    const updatedCategory = Object.assign({}, category, { active: true })
    const optimistic = dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedCategory
      )
    )
    if (readOnly) {
      return optimistic
    }
    const calendar = cal.find(getState())(category.calendarId)
    return dispatch(
      patchCategoryActive(
        calendar.organization,
        category.calendarId,
        updatedCategory
      )
    )
  }

/**
 * Action creator that generates an API call to update an existing
 * event against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the active
 * property for a given category by setting it to false.
 *
 * @param  {Integer}  categoryUuid    The ID of the category to hide.
 * @param  {Boolean}  readOnly      If true this action will not be persisted against the API.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const hideCategory =
  (categoryUuid, calendarId, readOnly) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    const updatedCategory = Object.assign({}, category, { active: false })
    const optimistic = dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedCategory
      )
    )
    if (readOnly) {
      return optimistic
    }
    const calendar = cal.find(getState())(category.calendarId)
    return dispatch(
      patchCategoryActive(
        calendar.organization,
        category.calendarId,
        updatedCategory
      )
    )
  }

/**
 * Action creator that generates an API call to update an existing category's
 * locked status against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchCategoryLock = (subdomain, calendarId, category) => ({
  type: types.UPDATE_CATEGORY_LOCK,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CATEGORY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${category.uuid}/lock`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    ignoreCache: true,
    body: JSON.stringify(decamelizeKeys({ locked: category.locked })),
    types: [
      types.UPDATE_CATEGORY_LOCK_REQUEST,
      types.UPDATE_CATEGORY_LOCK_SUCCESS,
      types.UPDATE_CATEGORY_LOCK_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * category against the API. This is a convenience method that wraps
 * the putcategory() creator in a thunk which only modifies the locked
 * property for a given category by setting it to true.
 *
 * @param  {String}   categoryUuid  The ID of the category to lock..
 * @param  {Boolean}  readOnly      If false the change will be persisted against the API.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const lockCategory =
  (categoryUuid, calendarId, readOnly) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    const calendar = cal.find(getState())(category.calendarId)
    const updatedcategory = Object.assign({}, category, { locked: true })
    const optimistic = dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedcategory
      )
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchCategoryLock(
            calendar.organization,
            category.calendarId,
            updatedcategory
          )
        )
  }

/**
 * Action creator that generates an API call to update an existing
 * category against the API. This is a convenience method that wraps
 * the putcategory() creator in a thunk which only modifies the locked
 * property for a given category by setting it to false.
 *
 * @param  {Integer}  categoryUuid     The ID of the category to lock..
 * @param  {Boolean}  readOnly   If false the change will be persisted against the API.
 * @return {Promise}             A promise representing the dispatched redux action creator.
 */
export const unlockCategory =
  (categoryUuid, calendarId, readOnly) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    const calendar = cal.find(getState())(category.calendarId)
    const updatedcategory = Object.assign({}, category, { locked: false })
    const optimistic = dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedcategory
      )
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchCategoryLock(
            calendar.organization,
            category.calendarId,
            updatedcategory
          )
        )
  }

/**
 * Action creator that generates an API call to update an existing event
 * against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   category      The category that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchOpenCategory = (subdomain, calendarId, category) => ({
  type: types.OPEN_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.CATEGORY,
    ignoreCache: true,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${category.uuid}/open`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys({ open: category.open })),
    types: [
      types.OPEN_CATEGORY_REQUEST,
      types.OPEN_CATEGORY_SUCCESS,
      types.OPEN_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * event against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the open
 * property for a given category by setting it to true or false.
 *
 * @param  {String}   categoryUuid  The ID of the category to update.
 * @param  {Integer}  calendarId    The ID of the calendar the category belongs to.
 * @param  {Boolean}  open          The new open state of the category.
 * @param  {Boolean}  readOnly      If true this action will not be persisted against the API.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const openCategory =
  (categoryUuid, calendarId, open, readOnly) => (dispatch, getState) => {
    const category = cat.find(getState())(categoryUuid, calendarId)
    const updatedCategory = Object.assign({}, category, { open })
    const optimistic = dispatch(
      optimisticEntityUpdate(
        ENTITY_TYPES.CATEGORIES,
        category.hashId,
        updatedCategory
      )
    )
    if (readOnly) {
      return optimistic
    }
    const calendar = cal.find(getState())(calendarId)
    return dispatch(
      patchOpenCategory(
        calendar.organization,
        category.calendarId,
        updatedCategory
      )
    )
  }

//
// DELETE / Delete existing Category via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * event against the API.
 *
 * @param  {String} subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the category belongs to.
 * @param  {Integer} categoryUuid   The id of the category that will be deleted.
 * @return {Object}               An object representing the redux action.
 */
export const deleteCategory = (subdomain, calendarId, categoryUuid) => ({
  type: types.DELETE_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.CATEGORY,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories/${categoryUuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.DELETE_CATEGORY_REQUEST,
      types.DELETE_CATEGORY_SUCCESS,
      types.DELETE_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * event against the API. This is a convenience method that wraps
 * the deleteCategory() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Integer}  categoryUuid    The id of the category that will be deleted.
 * @return {Promise}                An object representing the redux action.
 */
export const removeCategory =
  (subdomain, calendarId, categoryUuid) => (dispatch) => {
    return dispatch(deleteCategory(subdomain, calendarId, categoryUuid))
  }

/**
 * Action creator that generates an API call to delete an existing
 * event against the API.
 *
 * @param  {String}   subdomain   The id of the organization the event belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the event belongs to.
 * @param  {Array}    uuids         An array of items uuids to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const deleteCategories = (subdomain, calendarId, uuids) => ({
  type: types.DELETE_CATEGORY,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.CATEGORIES_ARRAY,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/categories`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys({ uuids })),
    types: [
      types.DELETE_CATEGORY_REQUEST,
      types.DELETE_CATEGORY_SUCCESS,
      types.DELETE_CATEGORY_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete existing
 * items against the API. This is a convenience method that wraps
 * the deleteCategories() creator in a thunk.
 *
 * @param  {String}   subdomain   The id of the organization the event belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the event belongs to.
 * @param  {Array}    ids         An array of item ids to delete.
 * @return {Promise}            An object representing the redux action.
 */
export const removeCategories = (subdomain, calendarId, ids) => (dispatch) => {
  ids.forEach((id) => {
    dispatch(optimisticEntityDelete(ENTITY_TYPES.CATEGORIES, id, cat.find(id)))
  })
  return dispatch(deleteCategories(subdomain, calendarId, ids))
}
