var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { faMinusSquare, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
var styles = {
    checkbox: "w-8 border-0 hover:text-gray-700 text-sm focus:outline-none flex-shrink-0",
    disabled: "opacity-25 pointer-events-none cursor-not-allowed",
    enabled: "opacity-100 pointer-events-auto",
};
export var CheckBox = function (_a) {
    var onToggle = _a.onToggle, buttonState = _a.buttonState, disabled = _a.disabled;
    var active = ["enabled", "partial"].includes(buttonState);
    var handleToggle = function (e) {
        e.preventDefault();
        onToggle(!active);
    };
    var icon = buttonState === "disabled"
        ? faSquare
        : buttonState === "enabled"
            ? faCheckSquare
            : faMinusSquare;
    return (_jsx("button", __assign({ onClick: handleToggle, type: "button", className: clsx(styles.checkbox, active ? "text-lochivar-default" : "text-rose-darkest", disabled ? styles.disabled : styles.enabled) }, { children: _jsx(FontAwesomeIcon, { icon: icon }, void 0) }), void 0));
};
