import * as types from "../actionTypes"

/**
 * Prepares the calendar view for an event expand drag and drop
 * interaction.
 * @return {Object} An FSA action.
 */
export const beginExpandDrag = () => ({
  type: types.BEGIN_EVENT_EXPAND,
  payload: {},
})
