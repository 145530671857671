import React from "react"
import { SideBar, BarItem, BarSpacer, Avatar } from "ui"
import { VisibleOnlyAdmin } from "../../login"
import {
  faAddressBook,
  faQuestionCircle,
  faCalendarAlt,
  faSignOut,
  faTachometerAltFast,
  faTasksAlt,
  faMegaphone,
} from "@fortawesome/pro-light-svg-icons"
import { ReactComponent as Logo } from "../../images/ProCal-logo-solid.svg"
import { useSelector } from "react-redux"
import { analyticsSelector } from "../../api"

export type AppNavBarOption =
  | "organization"
  | "calendar"
  | "user"
  | "report"
  | "dashboard"
  | "help"
  | "logout"
  | "share"

export interface AppNavBarProps {
  orgName?: string
  orgColor?: string
  subdomain?: string
  onSelect?: (selection: AppNavBarOption) => void
}

export const AppNavBar: React.FC<AppNavBarProps> = ({
  orgName,
  orgColor,
  subdomain,
  onSelect,
}) => {
  const canViewAnalytics = useSelector(analyticsSelector.canViewAnalytics)
  const handleSelection = (selection: AppNavBarOption) => () =>
    onSelect ? onSelect(selection) : null

  return (
    <SideBar
      alignment="left"
      mode="compact"
      appearance="navigator"
      renderHeading={() => (
        <div className="bg-gradient-to-tr from-procal-pink via-procal-orange to-procal-gold flex w-full h-14">
          <Logo width={28} height={29} className="m-auto fill-white" />
        </div>
      )}
    >
      <BarItem
        onClick={handleSelection("organization")}
        toolTip={orgName}
        toolTipDirection="right"
      >
        <Avatar
          name={orgName ?? ""}
          color={orgColor}
          size="responsive"
          className="m-auto"
        />
      </BarItem>
      <BarItem
        icon={faCalendarAlt}
        onClick={handleSelection("calendar")}
        toolTip="Calendars"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
      <VisibleOnlyAdmin subdomain={subdomain}>
        <BarItem
          icon={faAddressBook}
          onClick={handleSelection("user")}
          toolTip="Users"
          toolTipDirection="right"
          mode="negative"
          indicated={true}
          indicatorMode="secondary"
        />
      </VisibleOnlyAdmin>
      <VisibleOnlyAdmin subdomain={subdomain}>
        <BarItem
          icon={faTasksAlt}
          onClick={handleSelection("report")}
          toolTip="Reports"
          toolTipDirection="right"
          mode="negative"
          indicated={true}
          indicatorMode="secondary"
        />
      </VisibleOnlyAdmin>
      <BarSpacer />
      {canViewAnalytics ? (
        <BarItem
          icon={faTachometerAltFast}
          onClick={handleSelection("dashboard")}
          toolTip="Site Analytics"
          toolTipDirection="right"
          mode="negative"
          indicated={true}
          indicatorMode="secondary"
        />
      ) : null}
      <BarItem
        icon={faQuestionCircle}
        onClick={handleSelection("help")}
        toolTip="Help"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
      <BarItem
        icon={faMegaphone}
        onClick={handleSelection("share")}
        toolTip="Share ProCal"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
      <BarItem
        icon={faSignOut}
        onClick={handleSelection("logout")}
        toolTip="Logout"
        toolTipDirection="right"
        mode="negative"
        indicated={true}
        indicatorMode="secondary"
      />
    </SideBar>
  )
}
