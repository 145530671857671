import { useRef, useEffect } from "react";
export var useAnimationFrame = function (callback) {
    var requestRef = useRef();
    var previousTimeRef = useRef();
    var animate = function (time) {
        var _a;
        if (previousTimeRef.current !== undefined) {
            var deltaTime = time - ((_a = previousTimeRef.current) !== null && _a !== void 0 ? _a : 0);
            callback(deltaTime);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };
    useEffect(function () {
        var request = requestAnimationFrame(animate);
        requestRef.current = request;
        return function () { return cancelAnimationFrame(request); };
    });
};
