import moment from "moment"
import React from "react"
import PropTypes from "prop-types"
import { Form, Flex, Indicator } from "../../shared"
import { Button } from "ui"

import styles from "./PrintSettingsForm.module.css"

const fontSizes = ["0.75rem", "1rem", "1.25rem"]

class PrintSettingsFormView extends React.Component {
  constructor(props) {
    super(props)
    const { endsAt, startsAt } = props
    this.state = {
      endsAt,
      startsAt,
      printCalendarName: true,
      endValid: true,
      startValid: true,
      datesNeedUpdate: false,
    }
  }

  handleViewStyleChange(viewStyle) {
    const { onChangeViewStyle } = this.props
    onChangeViewStyle(viewStyle)
  }

  handleStartChange(event) {
    const { startsAt, endsAt } = this.props
    const datesNeedUpdate =
      event.target.value !== startsAt || this.state.endsAt !== endsAt
    this.setState({
      startsAt: event.target.value,
      startValid: moment(event.target.value).isValid(),
      datesNeedUpdate,
    })
  }

  handleEndChange(event) {
    const { startsAt, endsAt } = this.props
    const datesNeedUpdate =
      event.target.value !== endsAt || this.state.startsAt !== startsAt
    this.setState({
      endsAt: event.target.value,
      endValid: moment(event.target.value).isValid(),
      datesNeedUpdate,
    })
  }

  handleMonthSizeChange(event) {
    const { nameFontSize, onFontSizeUpdated, headerFontSize, footerFontSize } =
      this.props
    onFontSizeUpdated({
      monthFontSize: event.target.value,
      nameFontSize,
      headerFontSize,
      footerFontSize,
    })
  }

  handleNameSizeChange(event) {
    const { monthFontSize, onFontSizeUpdated, headerFontSize, footerFontSize } =
      this.props
    onFontSizeUpdated({
      nameFontSize: event.target.value,
      monthFontSize,
      headerFontSize,
      footerFontSize,
    })
  }

  handleHeaderFontSize(event) {
    const { footerFontSize, onFontSizeUpdated, nameFontSize, monthFontSize } =
      this.props
    onFontSizeUpdated({
      headerFontSize: event.target.value,
      footerFontSize,
      nameFontSize,
      monthFontSize,
    })
  }

  handleFooterFontSize(event) {
    const { headerFontSize, nameFontSize, monthFontSize, onFontSizeUpdated } =
      this.props
    onFontSizeUpdated({
      footerFontSize: event.target.value,
      nameFontSize,
      monthFontSize,
      headerFontSize,
    })
  }

  headersForm() {
    const { headers } = this.props
    if (!headers) {
      return (
        <Form.Group>
          <Button type="button" align="stretch" onClick={() => window.print()}>
            <Flex.Container>Customize Header</Flex.Container>
          </Button>
        </Form.Group>
      )
    }
    return <span />
  }

  renderHeadings() {
    return <span />
  }

  render() {
    const { startsAt, startValid, endsAt, endValid, datesNeedUpdate } =
      this.state
    const {
      printCalendarName,
      onChangePrintCalendarName,
      showPageNumbers,
      onChangeShowPageNumbers,
      viewStyle,
      highContrast,
      onChangeHighContrastMode,
    } = this.props
    const { onDatesUpdated, submitting, onPrint } = this.props

    return (
      <div className={styles.printControls}>
        <div className={styles.printControlsContents}>
          <div className={`hidden-md-down ${styles.printFields}`}>
            <Form.Collapsable name="Appearance">
              <Form.Group>
                <label className="mb-4">
                  <input
                    type="checkbox"
                    name="highContrast"
                    checked={highContrast}
                    onChange={() => {
                      onChangeHighContrastMode(!highContrast)
                    }}
                    style={{ display: "inline-block", marginRight: "0.25em" }}
                  />
                  High Contrast Mode
                </label>
              </Form.Group>
              <Form.Group>
                <div className="flex flex-col">
                  <label>
                    <input
                      type="radio"
                      name="viewStyle"
                      checked={viewStyle === "monthly"}
                      onChange={() => {
                        this.handleViewStyleChange("monthly")
                      }}
                      style={{ display: "inline-block", marginRight: "0.25em" }}
                    />
                    Monthly
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="viewStyle"
                      checked={viewStyle === "annual"}
                      onChange={() => {
                        this.handleViewStyleChange("annual")
                      }}
                      style={{ display: "inline-block", marginRight: "0.25em" }}
                    />
                    Annual
                  </label>
                </div>
              </Form.Group>
            </Form.Collapsable>
            <Form.Collapsable name="Headings">
              <Form.Group>
                <label>
                  <input
                    type="checkbox"
                    name="printCalendar"
                    checked={printCalendarName}
                    onChange={() => {
                      onChangePrintCalendarName(!printCalendarName)
                    }}
                    style={{ display: "inline-block", marginRight: "0.25em" }}
                  />
                  Print Calendar Name
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="showPageNumbers"
                    checked={showPageNumbers}
                    onChange={() => {
                      onChangeShowPageNumbers(!showPageNumbers)
                    }}
                    style={{ display: "inline-block", marginRight: "0.25em" }}
                  />
                  Show Page Numbers
                </label>
                <Form.Group>
                  <label htmlFor="nameFontSize">Calendar Name Size</label>
                  <select
                    name="nameFontSize"
                    className="form-control"
                    onChange={(e) => this.handleNameSizeChange(e)}
                  >
                    {fontSizes.map((fontSize) => (
                      <option
                        value={`${fontSize}`}
                        key={"nameFontSize" + fontSize}
                        selected={fontSize === this.props.nameFontSize}
                      >
                        {fontSize}
                      </option>
                    ))}
                  </select>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="monthFontSize">Month/Year Label Size</label>
                  <select
                    name="monthFontSize"
                    className="form-control"
                    onChange={(e) => this.handleMonthSizeChange(e)}
                  >
                    {fontSizes.map((fontSize) => (
                      <option
                        value={`${fontSize}`}
                        key={"monthFontSize" + fontSize}
                        selected={fontSize === this.props.monthFontSize}
                      >
                        {fontSize}
                      </option>
                    ))}
                  </select>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="headerFontSize">Header Size</label>
                  <select
                    name="headerFontSize"
                    className="form-control"
                    onChange={(e) => this.handleHeaderFontSize(e)}
                  >
                    {fontSizes.map((fontSize) => (
                      <option
                        value={`${fontSize}`}
                        key={"headerFontSize" + fontSize}
                        selected={fontSize === this.props.headerFontSize}
                      >
                        {fontSize}
                      </option>
                    ))}
                  </select>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="footerFontSize">Footer Size</label>
                  <select
                    name="footerFontSize"
                    className="form-control"
                    onChange={(e) => this.handleFooterFontSize(e)}
                  >
                    {fontSizes.map((fontSize) => (
                      <option value={`${fontSize}`} key={fontSize}>
                        {fontSize}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Form.Group>
            </Form.Collapsable>
            <Form.Collapsable name="Date Range">
              <Form.Group error={!startValid}>
                <label>Start Month</label>
                <Form.Input
                  name="startsAt"
                  value={startsAt}
                  onChange={(e) => this.handleStartChange(e)}
                  component={Form.Input}
                  type="date"
                  placeholder="mm/dd/yy"
                />
              </Form.Group>
              <Form.Group error={!endValid}>
                <label>End Month</label>
                <Form.Input
                  name="endsAt"
                  value={endsAt}
                  onChange={(e) => this.handleEndChange(e)}
                  component={Form.Input}
                  type="date"
                  placeholder="mm/dd/yy"
                />
              </Form.Group>
            </Form.Collapsable>
          </div>
          {datesNeedUpdate ? (
            <Form.Group>
              <Button
                type="button"
                align="stretch"
                disabled={!startValid || !endValid}
                onClick={() => {
                  onDatesUpdated(
                    moment(startsAt).format("YYYYMMDD"),
                    moment(endsAt).format("YYYYMMDD")
                  ).then((result) =>
                    this.setState({
                      startsAt: result.startsAt,
                      endsAt: result.endsAt,
                      datesNeedUpdate: false,
                    })
                  )
                }}
              >
                <Flex.Container>Update Range</Flex.Container>
              </Button>
            </Form.Group>
          ) : (
            <span />
          )}
          <div className={styles.spacer} />
          <Form.Group>
            <Button
              type="button"
              align="stretch"
              disabled={submitting}
              onClick={onPrint}
            >
              <Flex.Container>
                {submitting && <Indicator.Ring theme={"button"} />}
                Create PDF
              </Flex.Container>
            </Button>
          </Form.Group>
        </div>
      </div>
    )
  }
}

PrintSettingsFormView.propTypes = {
  /**
   * The timestamp representing the end date for the print range.
   */
  endsAt: PropTypes.string.isRequired,

  /**
   * The timestamp representing the start date for the print range.
   */
  startsAt: PropTypes.string.isRequired,

  /**
   * An action handler that accepts a start and end date in YYYYMMDD format.
   */
  onDatesUpdated: PropTypes.func.isRequired,

  /**
   * A callback that is called when the print button is clicked.
   */
  onPrint: PropTypes.func.isRequired,

  /**
   * The left user customizeable heading to print above the calendar.
   */
  leftHeading: PropTypes.string,

  /**
   * The right user customizeable heading to print above the calendar.
   */
  rightHeading: PropTypes.string,

  /**
   * A bit flag representing that customizeable headings are enabled..
   */
  headers: PropTypes.bool,

  /**
   * A bit flag representing whether or not the form is currently printing.
   */
  submitting: PropTypes.bool,

  /**
   * The current value for the month font size.
   */
  monthFontSize: PropTypes.string,

  /**
   * The current value for the header font size.
   */
  headerFontSize: PropTypes.string,

  /**
   * The current value for the footer font size.
   */
  footerFontSize: PropTypes.string,

  /**
   * The current value for the name font size.
   */
  nameFontSize: PropTypes.string,

  /**
   * A handler for updates to the font size configurations.
   */
  onFontSizeUpdated: PropTypes.func.isRequired,

  /**
   * Indicates whether or not printCalendarName has been toggled.
   */
  printCalendarName: PropTypes.bool,

  /**
   * A handler for toggling the calendar print name.
   */
  onChangePrintCalendarName: PropTypes.func.isRequired,

  /**
   * Indicates whether or not showPageNumbers has been toggled.
   */
  showPageNumbers: PropTypes.bool,

  /**
   * A handler for toggling high contrast mode.
   */
  onChangeHighContrastMode: PropTypes.func.isRequired,

  /**
   * Indicates whether or not highContrast has been toggled.
   */
  highContrast: PropTypes.bool,

  /**
   * A handler for toggling the pade number configuration.
   */
  onChangeShowPageNumbers: PropTypes.func.isRequired,

  viewStyle: PropTypes.string.isRequired,
  onChangeViewStyle: PropTypes.func.isRequired,
}

export default PrintSettingsFormView
