import { DateTime } from "luxon";
var defaultLabel = {
    top: {
        date: "",
        fontSize: 0,
        fontStyle: "",
    },
    bottom: {
        date: "",
        fontSize: 15,
        fontStyle: "",
    },
};
var MONDAY = 1;
var FRIDAY = 5;
export function getBeginningOfMonth(date) {
    return DateTime.fromISO(date, { zone: "utc" }).startOf("month").startOf("day");
}
export function getEndOfMonth(date) {
    return DateTime.fromISO(date, { zone: "utc" }).endOf("month").startOf("day");
}
export function isBeginningOfWeek(day) {
    return day === MONDAY;
}
export function isWeekend(day) {
    return day > FRIDAY;
}
export function isDateSelected(currDate, selectedDate) {
    return selectedDate
        ? Math.round(currDate.diff(DateTime.fromISO(selectedDate, { zone: "utc" }), "days")
            .days) === 0
        : false;
}
export function getModeStyles(date, mode) {
    if (mode === "months") {
        return createMonthsLabels(date);
    }
    if (mode === "days") {
        return createDaysLabels(date);
    }
    return defaultLabel;
}
function createMonthsLabels(date) {
    var fontSize = 14;
    var fontStyle = "normal";
    var dateLabel = date.toFormat("d");
    var dayLabel = date.toFormat("EEEEE");
    return {
        top: createDateLabelDate(dateLabel, fontSize, fontStyle),
        bottom: createDateLabelDate(dayLabel, fontSize, fontStyle),
    };
}
function createDaysLabels(date) {
    var fontSize = 14;
    var fontStyle = "normal";
    var dayLabel = date.toFormat("ccc");
    var dateLabel = date.toFormat("LLL") + " " + date.toFormat("d");
    return {
        top: createDateLabelDate(dayLabel, fontSize, fontStyle),
        bottom: createDateLabelDate(dateLabel, fontSize, fontStyle),
    };
}
var createDateLabelDate = function (date, fontSize, fontStyle) { return ({ date: date, fontSize: fontSize, fontStyle: fontStyle }); };
