import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import jwtDecode from "jwt-decode"
import queryString from "query-string"
import { Placeholder } from "../../shared"
import { isNotBlank } from "../../utils/helpers"

/**
 * Displays a blank view with a placeholder Loading
 * indicator to display activity while it retrieves
 * and validates an invitation token before redirecting
 * the user to the correct location for accepting
 * their invitation.
 */
class InvitationLoaderView extends Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    const invitationToken = query.invitation
    if (isNotBlank(invitationToken)) {
      const invitation = jwtDecode(invitationToken)
      this.checkInvitation(invitation.organization.subdomain, invitationToken)
    } else {
      this.props.history.replace("/login")
    }
  }

  /**
   * Validates that an invitation is valid. If so the user
   * is redirected to the login or register form depending
   * on whether or not an existing account has been associated
   * to the invitation.

   * @param {String} subdomain    The subdomain of the organization the invitation belongs to.
   * @param {String} token        The JWT representing the invitation.
   */
  checkInvitation(subdomain, token) {
    const { loadInvitation, handleInvitationLoaded } = this.props
    loadInvitation(subdomain, token).then((action) => {
      if (action.error || action.payload.result.length < 1) {
        this.props.history.push("/login")
      } else {
        const invitationId = action.payload.result
        const invitations = action.payload.entities.invitations
        handleInvitationLoaded(invitationId, token)
        if (parseInt(invitations[invitationId].userId) > 0) {
          this.props.history.push("/login")
        } else {
          this.props.history.push("/login/register")
        }
      }
    })
  }

  render() {
    const { Loading } = Placeholder
    return <Loading />
  }
}

InvitationLoaderView.propTypes = {
  /**
   * The location object containing a query passed in
   * from react router.
   */
  location: PropTypes.shape({
    search: PropTypes.string,
  }),

  /**
   * A redux action creator that should be mapped to dispatch
   * and used to load an invitation from the API.
   */
  loadInvitation: PropTypes.func.isRequired,

  /**
   * An event handler that is called when an invitation
   * has been loaded.
   */
  handleInvitationLoaded: PropTypes.func.isRequired,

  /**
   * A redux router action creator mapped to dispatch which
   * will be used to redirect the user without pushing the
   * url to the HTML5 history stack.
   */
  history: PropTypes.object.isRequired,
}

export default withRouter(InvitationLoaderView)
