import Dialog from "./Dialog.react"
import Header from "./Header.react"
import Title from "./ModalTitle.react"
import Body from "./Body.react"
import Section from "./Section.react"
import Modal from "./Modal.react"
import Footer from "./Footer.react"
import Prompt from "./Prompt.react"
import FinePrint from "./FinePrint.react"
import Logo from "./Logo.react"
import * as SelectGroup from "./SelectGroup.react"

const content = {
  Modal,
  Dialog,
  Header,
  Title,
  Body,
  Footer,
  Prompt,
  FinePrint,
  Logo,
  SelectGroup,
  Section,
}
export default content
