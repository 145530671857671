import { useRequest } from "../useRequest"
import { useSelector, useDispatch } from "react-redux"
import { invites as actions } from "../../actions"
import { invitationSelector } from "../../selectors"

export const useInvitations = (subdomain: string) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestInvitation(subdomain))
  const invitations = useSelector(invitationSelector.forOrganization)(subdomain)
  const { loading, refetch } = useRequest(request)
  return { loading, invitations, refetch }
}
