import * as constants from "./constants"
import reducer from "./reducer"
import * as selectors from "./selectors"
import * as actions from "./actions"
import * as types from "./actionTypes"
import * as middlewares from "./middlewares"
import { getAccessToken } from "./tokens"

export * from "./hooks"

const content = {
  constants,
  reducer,
  selectors,
  actions,
  types,
}
export default content

export { getAccessToken }

// EXPORT MIDDLEWARE

export const { apiMiddleware, authMiddleware } = middlewares

// EXPOSE ACTION CREATORS

export const {
  requestActiveUserCount,
  requestActiveCalendarCount,
  requestTotalUserCount,
  requestActiveUsers,
  requestActiveCalendars,
  requestInvitations,
} = actions.analytics

export const {
  loadAuthToken,
  updateAuthToken,
  logoutUser,
  updateAuthRoles,
  requestCurrentUser,
} = actions.auth

export const {
  postForgotPassword,
  createForgotPassword,
  registerUser,
  registerAndLoginUser,
  updateForgottenPassword,
} = actions.users

export const {
  requestEvent,
  requestEventForCalendar,
  removeEvent,
  removeEvents,
  createEvent,
  updateEvent,
  shiftEvent,
  saveEventPosition,
  adjustEventsWithOffset,
} = actions.events

export const {
  requestHoliday,
  removeHoliday,
  createHoliday,
  updateHoliday,
  requestHolidaySchedule,
} = actions.holidays

export const {
  getReport,
  requestReport,
  removeReport,
  createReport,
  updateReport,
} = actions.reports

export const {
  requestItem,
  requestItemForCalendar,
  removeItem,
  createItem,
  lengthenItem,
  updateItem,
  shiftItem,
  saveItemPosition,
  lockItem,
  unlockItem,
  hideItem,
  showItem,
  removeItems,
  updateItemCategory,
  renameItem,
} = actions.items

export const {
  requestOrganization,
  getOrganization,
  requestOrganizationValidation,
  createOrganization,
  updateOrganization,
  removeOrganization,
} = actions.orgs

export const {
  requestCalendar,
  getCalendar,
  requestCalendarValidation,
  createCalendar,
  updateCalendar,
  removeCalendar,
  restoreCalendar,
  pushCalendar,
  downloadCalendar,
  createCalendarPrintToken,
  checkCalendarPrintStatus,
} = actions.cals

export const {
  requestCategory,
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
  showCategory,
  hideCategory,
  openCategory,
  removeCategories,
  updateCategoryCategory,
} = actions.categories

export const {
  requestSnapshot,
  createSnapshot,
  updateSnapshot,
  removeSnapshot,
  showSnapshot,
} = actions.snapshots

export const {
  requestAcceptInvitation,
  requestInvitation,
  getInvitation,
  requestInvitationValidation,
  createInvitation,
  getInvitationStatus,
  requestInvitationStatus,
  clearedPendingInvitation,
  loadedPendingInvitation,
  removeInvitation,
  updateInvitation,
  resendInvitation,
  postResendInvitation,
  requestInvitationPermissions,
  createInvitationPermission,
  updateInvitationPermission,
} = actions.invites

export const { createStyle, updateStyle, deleteStyle, requestStyle } =
  actions.styles

// EXPOSE SELECTORS

export const { baseDomain } = selectors.baseSelector
export const { API_ROOT, isFetching } = selectors.baseSelector

export const {
  analyticsSelector,
  organizationSelector,
  calendarSelector,
  invitationSelector,
  eventSelector,
  authSelector,
  categorySelector,
  itemSelector,
  styleSelector,
  snapshotSelector,
  holidaySelector,
  reportSelector,
  iconSelector,
} = selectors
