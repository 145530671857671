import React from "react"
import PropTypes from "prop-types"

const RadioGroup = ({ children }) => <ul className="list-group">{children}</ul>

RadioGroup.propTypes = {
  children: PropTypes.node,
}

export default RadioGroup
