import { connect } from "react-redux"
import { ItemExplorerView } from "../components"
import {
  updateItemDraggingStatus,
  getSelectablesForType,
  Selectable,
  selectItem,
  getMultiselect,
} from "../../selectable"
import api from "../../api"

const {
  actions: { items, categories, cals },
  selectors: { styleSelector, reportSelector },
} = api

export default connect(
  (state) => ({
    selectedItems: getSelectablesForType(Selectable.ITEM)(state),
    selectedEvents: getSelectablesForType(Selectable.EVENT)(state),
    selectedCategories: getSelectablesForType(Selectable.CATEGORY)(state),
    findStyle: styleSelector.find(state),
    findReportCalendarIds: reportSelector.calendarIdsForReport(state),
    findReportCalendars: reportSelector.calendarsForReport(state),
    multiselectEnabled: getMultiselect(state),
  }),
  {
    lockItem: items.lockItem,
    unlockItem: items.unlockItem,
    showItem: items.showItem,
    hideItem: items.hideItem,
    lockCategory: categories.lockCategory,
    unlockCategory: categories.unlockCategory,
    showCategory: categories.showCategory,
    hideCategory: categories.hideCategory,
    openCategory: categories.openCategory,
    updateItemCategory: items.updateItemCategory,
    renameItem: items.renameItem,
    updateCategoryCategory: categories.updateCategoryCategory,
    updateItemDraggingStatus,
    selectItem,
    findAndReplaceItems: cals.findAndReplaceItems,
  }
)(ItemExplorerView)
