import moment from "moment"
import { getUsuableEndDate, getUsuableStartDate } from "./getUsableDates"
import { calculateInterruptions } from "./calculateInterruptions"

/**
 * Compares two arrays to determine if there is a difference in length
 * @param  {Array} first  The first array to test.
 * @param  {Array} second The second array to test.
 * @return {Boolean}      True if both arrays exist and are not the same length.
 */
const lengthChanged = (first, second) =>
  first ? first.length !== second.length : false

/**
 * Merges the count of interruptions with the explicit length of an event to
 * get a usable length useful in date calculations which need the true span
 * of an event including working and non-working days.
 * @param  {Integer} length        The explicit length of an event.
 * @param  {Array} interruptions   An array of dates representing holidays or weekends.
 * @return {Integer}               The true length of the event including working days + holidays + weekends.
 */
const combineInterruptionsWithLength = (length, interruptions) =>
  length - 1 + (interruptions ? interruptions.length : 0)

/**
 * Determines if an event requires new dates to be calculated given mutations applied
 * to it's properties.
 * @param  {Boolean} flexibleDates        If true it indicates that the event can have its dates adjusted.
 * @param  {Array} priorInterruptions     An array of interruptions that were previously calculated for the event.
 * @param  {Array} currentInterruptions   An array of new interruptions that have been calculated for the event based on its current date and length.
 * @return {Boolean}                      True if the dates for an event should be recalculated.
 */
const dateRecalculationRequired = (
  flexibleDates,
  priorInterruptions,
  currentInterruptions
) => {
  const interruptionsChanged = lengthChanged(
    priorInterruptions,
    currentInterruptions
  )
  const foundNewInterruptions =
    !priorInterruptions && currentInterruptions.length > 0
  return flexibleDates && (foundNewInterruptions || interruptionsChanged)
}

/**
 * Updates a given event in the local redux store. Used to provide an optimistic update
 * before getting a final result back from the API. This is primarily used for drop and
 * drag previews.
 * @param  {Object} event               The event to update.
 * @param  {String} newDate             A date string representing the new start date.
 * @param  {Boolean} flexibleDates      If true the start / end date will shift to the first available and interrupts are recursivley calculated.
 * @param  {Integer} newPosition        The new position in the row heiarchy.
 * @param  {Integer} newPartition       The new partition this event should render within.
 * @param  {Object} currentState        The current state of the redux application.
 * @param  {Array} priorInterruptions   An array of dates representing interruptions for the event.
 * @param  {Integer} maxIterations      The maxmimum attempts allowed to recalculate interruptions.
 * @param  {Integer} currentIteration   The current iterative attempt at recursively calculating dates.
 * @return {Object}                     An updated representation of the event which can be passed back to redux.
 */
export const updateEvent = (
  event,
  newDate,
  flexibleDates,
  newPosition,
  newPartition,
  currentState,
  priorInterruptions,
  maxIterations = 2,
  currentIteration = 0
) => {
  const length = event.length
  const startsAt = moment
    .utc(getUsuableStartDate(newDate, priorInterruptions))
    .format()
  const actualLength = combineInterruptionsWithLength(
    event.length,
    priorInterruptions
  )
  const calculatedEndsAt = moment
    .utc(newDate)
    .add(actualLength, "days")
    .format()
  const interruptions = calculateInterruptions(
    event.interruptedByWeekends,
    event.interruptedByHolidays,
    startsAt,
    calculatedEndsAt,
    event.calendarId,
    currentState
  )

  if (
    maxIterations > currentIteration &&
    dateRecalculationRequired(flexibleDates, priorInterruptions, interruptions)
  ) {
    return updateEvent(
      event,
      startsAt,
      flexibleDates,
      newPosition,
      newPartition,
      currentState,
      interruptions,
      maxIterations,
      currentIteration + 1
    )
  }
  const updatedInterruptions = interruptions.length > 0 ? interruptions : null // null forces redux/normalizer to clear.
  const calculatedLength = length - (flexibleDates ? 0 : interruptions.length)
  const endsAt = flexibleDates
    ? calculatedEndsAt
    : getUsuableEndDate(
        moment.utc(newDate).add(length - 1, "d"),
        interruptions
      ).format()
  return {
    [event.hashId]: {
      ...event,
      startsAt,
      endsAt,
      interruptions: updatedInterruptions,
      length: calculatedLength,
      position: newPosition,
      partition: newPartition,
    },
  }
}
