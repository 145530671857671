import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { reports as actions } from "../actions"
import { reportSelector } from "../selectors"

export const useReportCalendars = (subdomain: string, id: number) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestFullReportCalendars(subdomain, id))
  const calendars = useSelector(reportSelector.calendarsForReport)(id)
  const { loading, refetch } = useRequest(request)
  return { loading, calendars, refetch }
}
