import { connect } from "react-redux"
import {
  requestOrganization,
  requestCalendar,
  requestCurrentUser,
  isFetching,
} from "../../api"
import CalendarBlankSlateView from "../components/CalendarBlankSlateView.react"

export default connect(
  () => (state) => ({
    fetching: isFetching(state),
  }),
  {
    loadCurrentUser: requestCurrentUser,
    loadCalendars: requestCalendar,
    loadOrganization: requestOrganization,
  }
)(CalendarBlankSlateView)
