import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"
import * as i from "../selectors/itemSelector"
import * as c from "../selectors/calendarSelector"
import { optimisticEntityUpdate, optimisticEntityDelete } from "./entities"
import { ENTITY_TYPES } from "../constants"
import { applyUTC } from "../helpers"
import { optimisticDateRangeUpdate } from "./events"
import { eventSelector } from "../selectors"

//
// GET / Find all OR by :item
//

/**
 * Action creator that generates an API call to fetcb all items
 * or a specific item by ID.
 *
 * @param  {String} subdomain     The id of the organization which the item of the item belongs to.
 * @param  {Integer} calendarId   The id of the item the item belongs to.
 * @param  {Integer} itemUuid       The (optional) id of the item to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getItem = (subdomain, calendarId, itemUuid) => ({
  type: types.FETCH_ITEMS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: itemUuid ? Schemas.ITEM : Schemas.ITEMS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${
      itemUuid || ""
    }`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_ITEMS_REQUEST,
      types.FETCH_ITEMS_SUCCESS,
      types.FETCH_ITEMS_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetcb all items
 * or a specific item by ID. This is a convenience method that wraps
 * the getItem() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the item belongs to.
 * @param  {Integer}  itemUuid        The (optional) id of the item to fetch.
 * @return {Promise}                A promise representing the dispatched redux action creator.
 */
export const requestItem = (subdomain, calendarId, itemUuid) => (dispatch) => {
  if (calendarId === "undefined") {
    return
  }
  return dispatch(getItem(subdomain, calendarId, itemUuid))
}

//
// POST / New item
//

/**
 * Action creator that generates an API call to create a new item
 * for a specific calendar.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the item belongs to.
 * @param  {Integer}  item          The item that will be created.
 * @return {Object}                 An object representing the redux action.
 */
export const postItem = (subdomain, calendarId, item) => ({
  type: types.CREATE_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(item)),
    types: [
      types.CREATE_ITEM_REQUEST,
      types.CREATE_ITEM_SUCCESS,
      types.CREATE_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to create a new item
 * for a specific item. This is a convenience method that wraps
 * the postItem() creator in a thunk.
 *
 * @param  {String}  subdomain    The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the item belongs to.
 * @param  {Object}  item         Item data or body for the API call.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const createItem = (subdomain, calendarId, item) => (dispatch) => {
  return dispatch(postItem(subdomain, calendarId, item)).then((response) => {
    dispatch(optimisticDateRangeUpdate(calendarId))
    return response
  })
}

//
// PUT / Update Item
//

/**
 * Action creator that generates an API call to update an existing item
 * against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the item belongs to.
 * @param  {Integer}  item          Item data or body for the API call.
 * @return {Object}                 An object representing the redux action.
 */
export const putItem = (subdomain, calendarId, item) => ({
  type: types.UPDATE_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${item.uuid}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(item)),
    types: [
      types.UPDATE_ITEM_REQUEST,
      types.UPDATE_ITEM_SUCCESS,
      types.UPDATE_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the item belongs to.
 * @param  {Integer}  item          Item data or body for the API call.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const updateItem = (subdomain, calendarId, item) => (dispatch) => {
  return dispatch(putItem(subdomain, calendarId, item)).then((response) => {
    dispatch(optimisticDateRangeUpdate(calendarId))
    return response
  })
}

/**
 * Updates the blacklist of dates a reoccuring item cannot have an event
 * generated on.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @param  {String}   cannotOccurOn   The new date the reoccuring event cannot occur.
 * @return {Object}                   A redux action representing the API request.
 */
export const patchSkipDateForItem = (
  subdomain,
  calendarId,
  itemUuid,
  cannotOccurOn
) => ({
  type: types.PATCH_SKIP_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${itemUuid}/skip`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        cannotOccurOn: applyUTC(cannotOccurOn),
      })
    ),
    types: [
      types.PATCH_SKIP_ITEM_REQUEST,
      types.PATCH_SKIP_ITEM_SUCCESS,
      types.PATCH_SKIP_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to skip an occurence for
 * an existing item against the API. This is a convenience method that
 * wraps the patchSkipDateForItem() creator in a thunk.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @param  {String}   cannotOccurOn   The new date the reoccuring event cannot occur.
 * @return {Promise}                  A promise representing the dispatched redux action creator.
 */
export const skipDateForItem =
  (subdomain, calendarId, itemUuid, cannotOccurOn) => (dispatch) => {
    return dispatch(
      patchSkipDateForItem(subdomain, calendarId, itemUuid, cannotOccurOn)
    )
  }

/**
 * Renames an item.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @param  {String}   name            The new name of item.
 * @return {Object}                   A redux action representing the API request.
 */
export const patchRenameItem = (subdomain, calendarId, itemUuid, name) => ({
  type: types.PATCH_RENAME_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${itemUuid}/rename`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        name,
      })
    ),
    types: [
      types.PATCH_RENAME_ITEM_REQUEST,
      types.PATCH_RENAME_ITEM_SUCCESS,
      types.PATCH_RENAME_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update the name of
 * an existing item against the API. This is a convenience method that
 * wraps the patchRenameItem() creator in a thunk.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @param  {String}   name            The new name of item.
 * @return {Promise}                  A promise representing the dispatched redux action creator.
 */
export const renameItem =
  (subdomain, calendarId, itemUuid, name) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const updatedItem = Object.assign({}, item, { name })
    dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return dispatch(patchRenameItem(subdomain, calendarId, itemUuid, name))
  }

/**
 * Updates the blacklist of dates a reoccuring item cannot have an event
 * generated on.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @return {Object}                   A redux action representing the API request.
 */
export const patchUngroupItem = (subdomain, calendarId, itemUuid) => ({
  type: types.PATCH_UNGROUP_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEMS_ARRAY,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${itemUuid}/ungroup`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.PATCH_UNGROUP_ITEM_REQUEST,
      types.PATCH_UNGROUP_ITEM_SUCCESS,
      types.PATCH_UNGROUP_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to skip an occurence for
 * an existing item against the API. This is a convenience method that
 * wraps the patchSkipDateForItem() creator in a thunk.
 *
 * @param  {String}   subdomain       The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId      The id of the calendar the item belongs to.
 * @param  {String}   itemUuid        The id of the item to update.
 * @return {Promise}                  A promise representing the dispatched redux action creator.
 */
export const ungroupItem = (subdomain, calendarId, itemUuid) => (dispatch) => {
  return dispatch(patchUngroupItem(subdomain, calendarId, itemUuid))
}

/**
 * Updates the date and position attributes for an event on a given
 * calendar. This method will also cause all positions in the list of
 * events to be resorted depending on the new position.
 *
 * @param  {Object}   calendar    The calendar which the event belongs to.
 * @param  {String}   itemUuid    The uuid of the item to be updated.
 * @param  {Integer}  startsAt    The number of days to startsAt the start date by.
 * @param  {String}   length      The length of the item's event(s) in days.
 * @return {Object}               A redux action representing the API request.
 */
export const patchLengthenItem = (calendar, itemUuid, startsAt, length) => ({
  type: types.PATCH_LENGTHEN_ITEM,
  meta: {
    debounce: {
      time: 1250,
      key: `patchLengthenItem${itemUuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/items/${itemUuid}/lengthen`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        startsAt: applyUTC(startsAt),
        length,
      })
    ),
    types: [
      types.PATCH_LENGTHEN_ITEM_REQUEST,
      types.PATCH_LENGTHEN_ITEM_SUCCESS,
      types.PATCH_LENGTHEN_ITEM_FAILURE,
    ],
  },
})

/**
 * Updates the length of a given item. This is a convenience method that wraps the
 * patchLengthenItem() creator in a thunk.
 *
 * @param  {String}   itemUuid    The uuid of the item to be updated.
 * @param  {Integer}  calendarId  The id of the calendar the item belongs to.
 * @param  {Integer}  startsAt    The number of days to startsAt the start date by.
 * @param  {Integer}  length      The arbitrary index representing the position of the item.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const lengthenItem =
  (itemUuid, calendarId, startsAt, length) => (dispatch, getState) => {
    const state = getState()
    const item = i.find(state)(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    return dispatch(
      patchLengthenItem(calendar, itemUuid, startsAt, length)
    ).then(() => dispatch(optimisticDateRangeUpdate(calendarId)))
  }

/**
 * Updates the date and position attributes for an event on a given
 * calendar. This method will also cause all positions in the list of
 * events to be resorted depending on the new position.
 *
 * @param  {Object}   calendar    The calendar which the event belongs to.
 * @param  {String}   itemUuid    The uuid of the item to be updated.
 * @return {Object}               A redux action representing the API request.
 */
export const patchClearItem = (calendar, itemUuid) => ({
  type: types.PATCH_CLEAR_ITEM,
  meta: {
    debounce: {
      time: 1250,
      key: `patchClearItem${itemUuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/items/${itemUuid}/lengthen`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.PATCH_CLEAR_ITEM_REQUEST,
      types.PATCH_CLEAR_ITEM_SUCCESS,
      types.PATCH_CLEAR_ITEM_FAILURE,
    ],
  },
})

/**
 * Updates the length of a given item. This is a convenience method that wraps the
 * patchClearItem() creator in a thunk.
 *
 * @param  {String}   itemUuid  The uuid of the item to be updated.
 * @param  {Integer}  startsAt  The number of days to startsAt the start date by.
 * @return {Promise}            A promise representing the dispatched redux action creator.
 */
export const clearItem = (itemUuid) => (dispatch, getState) => {
  const state = getState()
  const item = i.find(state)(itemUuid)
  const calendar = c.find(getState())(item.calendarId)
  return dispatch(patchClearItem(calendar, itemUuid))
}

/**
 * Action creator that generates an API call to update an existing item's
 * active status against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   item          The item that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchItemParent = (subdomain, calendarId, item) => ({
  type: types.UPDATE_ITEM_PARENT,
  meta: {
    debounce: {
      time: 1250,
      key: `updateItemParent${item.uuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${item.uuid}/parent`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys({ categoryUuid: item.categoryUuid })),
    types: [
      types.UPDATE_ITEM_PARENT_REQUEST,
      types.UPDATE_ITEM_PARENT_SUCCESS,
      types.UPDATE_ITEM_PARENT_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the categoryUuid
 * property for a given item by setting it to a supplied param.
 *
 * @param  {Integer}  itemUuid      The ID of the item to lock.
 * @param  {Integer}  categoryUuid  The ID of the new parent category.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const updateItemCategory =
  (itemUuid, categoryUuid, calendarId) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    const updatedItem = Object.assign({}, item, { categoryUuid })
    dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return dispatch(
      patchItemParent(calendar.organization, item.calendarId, updatedItem)
    )
  }

/**
 * Action creator that generates an API call to update an existing item's
 * active status against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   item          The item that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchItemLock = (subdomain, calendarId, item) => ({
  type: types.UPDATE_ITEM_LOCK,
  meta: {
    debounce: {
      time: 5000,
      key: `updateItemLock${item.uuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${item.uuid}/lock`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys({ locked: item.locked })),
    types: [
      types.UPDATE_ITEM_LOCK_REQUEST,
      types.UPDATE_ITEM_LOCK_SUCCESS,
      types.UPDATE_ITEM_LOCK_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the locked
 * property for a given item by setting it to true.
 *
 * @param  {Integer}  itemUuid     The ID of the item to lock..
 * @param  {Boolean}  readOnly   If false the change will be persisted against the API.
 * @return {Promise}             A promise representing the dispatched redux action creator.
 */
export const lockItem =
  (itemUuid, calendarId, readOnly) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    const updatedItem = Object.assign({}, item, { locked: true })
    const optimistic = dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchItemLock(calendar.organization, item.calendarId, updatedItem)
        )
  }

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the locked
 * property for a given item by setting it to false.
 *
 * @param  {Integer}  itemUuid     The ID of the item to lock..
 * @param  {Boolean}  readOnly   If false the change will be persisted against the API.
 * @return {Promise}             A promise representing the dispatched redux action creator.
 */
export const unlockItem =
  (itemUuid, calendarId, readOnly) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    const updatedItem = Object.assign({}, item, { locked: false })
    const optimistic = dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchItemLock(calendar.organization, item.calendarId, updatedItem)
        )
  }

/**
 * Action creator that generates an API call to update an existing item's
 * active status against the API.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the category belongs to.
 * @param  {Object}   item          The item that will be updated.
 * @return {Object}                 An object representing the redux action.
 */
export const patchItemActive = (subdomain, calendarId, item) => ({
  type: types.UPDATE_ITEM_ACTIVE,
  meta: {
    debounce: {
      time: 1250,
      key: `updateItemActive${item.uuid}`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.ITEM,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${item.uuid}/activate`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys({ active: item.active })),
    types: [
      types.UPDATE_ITEM_ACTIVE_REQUEST,
      types.UPDATE_ITEM_ACTIVE_SUCCESS,
      types.UPDATE_ITEM_ACTIVE_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the active
 * property for a given item by setting it to false.
 *
 * @param  {Integer}  itemUuid     The ID of the item to lock..
 * @param  {Boolean}  readOnly   If false the change will be persisted against the API.
 * @return {Promise}             A promise representing the dispatched redux action creator.
 */
export const hideItem =
  (itemUuid, calendarId, readOnly) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    const updatedItem = Object.assign({}, item, { active: false })
    const optimistic = dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchItemActive(calendar.organization, item.calendarId, updatedItem)
        )
  }

/**
 * Action creator that generates an API call to update an existing
 * item against the API. This is a convenience method that wraps
 * the putItem() creator in a thunk which only modifies the active
 * property for a given item by setting it to false.
 *
 * @param  {Integer}  itemUuid     The ID of the item to lock..
 * @param  {Boolean}  readOnly   If false the change will be persisted against the API.
 * @return {Promise}             A promise representing the dispatched redux action creator.
 */
export const showItem =
  (itemUuid, calendarId, readOnly) => (dispatch, getState) => {
    const item = i.find(getState())(itemUuid, calendarId)
    const calendar = c.find(getState())(item.calendarId)
    const updatedItem = Object.assign({}, item, { active: true })
    const optimistic = dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.ITEMS, item.hashId, updatedItem)
    )
    return readOnly
      ? optimistic
      : dispatch(
          patchItemActive(calendar.organization, item.calendarId, updatedItem)
        )
  }

//
// DELETE / Delete existing Item via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * item against the API.
 *
 * @param  {String} subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer} calendarId   The id of the calendar the item belongs to.
 * @param  {Integer} itemUuid       The id of the item that will be deleted.
 * @return {Object}               An object representing the redux action.
 */
export const deleteItem = (subdomain, calendarId, itemUuid) => ({
  type: types.DELETE_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ITEM,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items/${itemUuid}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_ITEM_REQUEST,
      types.DELETE_ITEM_SUCCESS,
      types.DELETE_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * item against the API. This is a convenience method that wraps
 * the deleteItem() creator in a thunk.
 *
 * @param  {String}   subdomain     The subdomain of the organization the calendar belongs to.
 * @param  {Integer}  calendarId    The id of the calendar the item belongs to.
 * @param  {Integer}  itemUuid        The id of the item that will be deleted.
 * @return {Promise}                An object representing the redux action.
 */
export const removeItem = (subdomain, calendarId, itemUuid) => (dispatch) => {
  return dispatch(deleteItem(subdomain, calendarId, itemUuid)).then(
    (response) => {
      dispatch(optimisticDateRangeUpdate(calendarId))
      return response
    }
  )
}

/**
 * Action creator that generates an API call to delete an existing
 * item against the API.
 *
 * @param  {String}   subdomain   The id of the organization the item belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the item belongs to.
 * @param  {Array}    uuids       An array of items uuids to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const deleteItems = (subdomain, calendarId, uuids) => ({
  type: types.DELETE_ITEM,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ITEMS_ARRAY,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/items`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys({ uuids })),
    types: [
      types.DELETE_ITEM_REQUEST,
      types.DELETE_ITEM_SUCCESS,
      types.DELETE_ITEM_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete existing
 * items against the API. This is a convenience method that wraps
 * the deleteItems() creator in a thunk.
 *
 * @param  {String}   subdomain   The id of the organization the item belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the item belongs to.
 * @param  {Array}    hashIds     An array of item hashIds to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const removeItems =
  (subdomain, calendarId, hashIds) => async (dispatch, getState) => {
    const state = getState()
    await Promise.all(
      hashIds.map(async (hashId) => {
        const item = i.find(state)(hashId, calendarId)
        const events = eventSelector.forItem(state)(hashId, calendarId)
        await Promise.all(
          events.map(async (event) => {
            await dispatch(
              optimisticEntityDelete(ENTITY_TYPES.EVENTS, event.hashId, event)
            )
          })
        )
        await dispatch(optimisticEntityDelete(ENTITY_TYPES.ITEMS, hashId, item))
      })
    )
    await dispatch(optimisticDateRangeUpdate(calendarId))
    return await dispatch(deleteItems(subdomain, calendarId, hashIds))
  }
