var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { concatStyles } from "utils";
import { Arrow } from "./Arrow";
import { Step } from "./Step";
export var StepProgress = function (_a) {
    var steps = _a.steps, className = _a.className, currentIndex = _a.currentIndex;
    return (_jsx("ul", __assign({ className: concatStyles(["flex", className]) }, { children: steps.map(function (step, i) { return (_jsxs(React.Fragment, { children: [i > 0 ? _jsx(Arrow, { disabled: currentIndex < i }, void 0) : null, _jsx(Step, { step: step.id, label: step.label, active: currentIndex === i, disabled: currentIndex < i }, void 0)] }, step.id)); }) }), void 0));
};
