import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var BASE_ICON = "flex m-auto";
var DEFAULT_ICON = BASE_ICON + " text-current";
var PRIMARY_ICON = BASE_ICON + " text-sorbus-default-default";
var SECONDARY_ICON = BASE_ICON + " text-lochivar-default hover:text-lochivar-dark";
var NEGATIVE_ICON = BASE_ICON + " text-white";
var classForMode = function (mode) {
    switch (mode) {
        case "primary":
            return PRIMARY_ICON;
        case "secondary":
            return SECONDARY_ICON;
        case "negative":
            return NEGATIVE_ICON;
        default:
            return DEFAULT_ICON;
    }
};
export var Icon = function (_a) {
    var icon = _a.icon, mode = _a.mode;
    return _jsx(FontAwesomeIcon, { className: classForMode(mode), icon: icon }, void 0);
};
