import { useRequest, RequestConfig } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { cals } from "../actions"
import { calendarSelector } from "../selectors"

export const useCalendar = (
  subdomain: string,
  calendarId: string,
  config?: RequestConfig
) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(cals.requestCalendar(subdomain, calendarId))
  const calendar = useSelector(calendarSelector.find)(calendarId)
  const { loading, refetch } = useRequest(request, config)
  return { loading, calendar, refetch }
}
