var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFilePdf, faSave, } from "@fortawesome/pro-solid-svg-icons";
import { faArrowLeft, faChevronLeft, faChevronRight, } from "@fortawesome/pro-regular-svg-icons";
import { BarDropDown, ToolBar, BarGroup, BarItem, BarSpacer, BarDivider, BarHeader, } from "../Navigation";
import { DropDownItem } from "../Form";
import { VIEW_MODES, MD_VISIBLE, } from "./CalendarToolBar";
export var SnapshotToolBar = function (_a) {
    var calendarName = _a.calendarName, calendarDescription = _a.calendarDescription, _b = _a.disabledItems, disabledItems = _b === void 0 ? [] : _b, onItemSelect = _a.onItemSelect, viewMode = _a.viewMode, handleViewModeSelect = _a.onViewModeSelect;
    var handleClick = function (item) { return function () { return onItemSelect(item); }; };
    return (_jsxs(ToolBar, { children: [_jsx(BarItem, { icon: faArrowLeft, toolTip: "Back to calendar", onClick: handleClick("back"), mode: "secondary", toolTipAlignment: "left" }, void 0), _jsx(BarHeader, { primary: calendarName, secondary: calendarDescription }, void 0), _jsx(BarDivider, { className: MD_VISIBLE }, void 0), _jsx(BarDropDown, __assign({ name: "viewMode", primary: viewMode, hideLabel: true, className: MD_VISIBLE, avatar: true }, { children: function (dismiss) {
                    return VIEW_MODES.map(function (mode) { return (_jsx(DropDownItem, { onClick: function () {
                            handleViewModeSelect === null || handleViewModeSelect === void 0 ? void 0 : handleViewModeSelect(mode);
                            dismiss();
                        }, item: {
                            id: mode,
                            label: mode,
                        }, avatar: true }, mode)); });
                } }), void 0), _jsx(BarDivider, { className: MD_VISIBLE }, void 0), _jsxs(BarGroup, __assign({ className: MD_VISIBLE }, { children: [_jsx(BarItem, { icon: faChevronLeft, appearance: "button", toolTip: "Previous", onClick: handleClick("previous"), shortCut: "\u2190", mode: "secondary" }, void 0), _jsx(BarItem, { toolTip: "Current Month", shortCut: "space", label: "TODAY", appearance: "button", onClick: handleClick("today") }, void 0), _jsx(BarItem, { icon: faChevronRight, appearance: "button", toolTip: "Next", onClick: handleClick("next"), shortCut: "\u2192", mode: "secondary" }, void 0)] }), void 0), _jsx(BarDivider, { className: MD_VISIBLE }, void 0), _jsx(BarItem, { icon: faSave, toolTip: "Save As", onClick: handleClick("save"), mode: "secondary", disabled: disabledItems.includes("save"), className: MD_VISIBLE }, void 0), _jsx(BarSpacer, {}, void 0), _jsx(BarItem, { icon: faFilePdf, toolTip: "PDF", onClick: handleClick("pdf"), mode: "secondary", className: MD_VISIBLE, disabled: disabledItems.includes("pdf") }, void 0)] }, void 0));
};
