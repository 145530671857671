import { createSelector } from "reselect"
import { NAME } from "./constants"

export const s = (state) => state[NAME]

export const getSelectablesForType = (type) => (state) =>
  getSelectedItems(state)[type]

export const getMultiselect = (state) => s(state).multiselect

export const getSelectedItems = (state) => s(state).selectedItems

export const getLastSelectedType = (state) => s(state).lastItemTypeSelected

export const selectedDate = (state) => s(state).selectedDate

export const lastSelectedCategory = (state) =>
  (s(state).lastSelectedCategory || {}).categoryUuid

export const lastSelectedCalendarId = (state) =>
  (s(state).lastSelectedCategory || {}).calendarId

export const lastSelectedEventDate = (state) => s(state).lastSelectedEventDate

export const hasSelectedItems = createSelector(
  [getSelectedItems],
  (selectedItems) =>
    Object.keys(selectedItems)
      .map((key) => selectedItems[key].length > 0)
      .filter((res) => res).length > 0
)
