import { DateTime } from "luxon";
/**
 * Removes any time information from an ISO DateTime string.
 * @param date An ISO date/time string.
 * @returns A date time truncated to only the ISO Date.
 */
export var trunc = function (date) { return date === null || date === void 0 ? void 0 : date.slice(0, 10); };
/**
 * Filters and sorts an array of ISO date strings for a given date range.
 * @param dates An array of ISO Date strings to filter and sort.
 * @param start The start of the date range.
 * @param end The end of the date range.
 * @returns An array of ISO date strings that meet the range criteria.
 */
export var sortedWithinRange = function (dates, start, end) {
    return dates
        .filter(function (d) { return d; })
        .filter(function (d) { return trunc(d) >= trunc(start) && trunc(d) <= trunc(end); })
        .filter(function (d, i, a) { return a.indexOf(d) === i; })
        .map(function (d) {
        return DateTime.fromISO(d, { zone: "utc" }).toISO({ includeOffset: false });
    })
        .sort();
};
/**
 * Fetches all weekends (sat/sun) that intersect a given date range.
 * @param start An ISO date string representing the start of the date range.
 * @param end An ISO date string representing the end of the date range.
 * @returns An array of ISO date strings for any weekends that fall within the range.
 */
export var priorWeekendsBetweenDates = function (start, end) {
    var currentDate = DateTime.fromISO(start, { zone: "utc" }).startOf("week");
    var weekends = [];
    while (currentDate.diff(DateTime.fromISO(end, { zone: "utc" }), "days").days < 0) {
        // Our weekend math is funny since we start our weeks on a sunday but luxon uses Mon(0) - Sun (7).
        weekends.push(currentDate
            .startOf("week")
            .minus({ days: 1 })
            .toISO({ includeOffset: false }));
        weekends.push(currentDate
            .endOf("week")
            .minus({ days: 1 })
            .startOf("day")
            .toISO({ includeOffset: false }));
        currentDate = currentDate.plus({ week: 1 }).startOf("week");
    }
    return sortedWithinRange(weekends, DateTime.fromISO(start).startOf("week").toISO({ includeOffset: false }), end);
};
