import * as types from "../actionTypes"

/**
 * Shows the item explorer.
 * @return {Object} An FSA action.
 */
export const showItems = () => ({
  type: types.SHOW_ITEMS,
  payload: {
    itemExplorerVisible: true,
  },
})
