import { makePx } from "./px";
/**
 * Stroke the current path on an HTML 5 drawing context.
 * @param ctx The HTML canvas context to perform the operation on.
 * @param param1 Attributes of the stroke operation.
 */
export var stroke = function (ctx, _a) {
    var scale = _a.scale, _b = _a.lineWidth, lineWidth = _b === void 0 ? 0 : _b, _c = _a.lineDash, lineDash = _c === void 0 ? [] : _c, strokeStyle = _a.strokeStyle;
    if (lineWidth <= 0) {
        return;
    }
    var px = makePx(scale);
    ctx.lineWidth = px(lineWidth);
    ctx.strokeStyle = strokeStyle !== null && strokeStyle !== void 0 ? strokeStyle : ctx.strokeStyle;
    ctx.setLineDash(lineDash);
    ctx.stroke();
    ctx.setLineDash([]);
};
