import { useDispatch, useSelector } from "react-redux"
import jwtDecode from "jwt-decode"
import { DateTime } from "luxon"
import { API_ROOT } from "../selectors"
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../tokens"
import { tokenRefreshFailure } from "../actions/auth"

const MIN_LIFESPAN_IN_SECONDS = 10

export const useAccessToken = () => {
  const apiRoot = useSelector(API_ROOT)
  const dispatch = useDispatch()

  const fetchAccessToken = async (): Promise<string | undefined> => {
    const existingToken = getAccessToken() as any
    const { exp } = jwtDecode(existingToken) as { exp: number }
    const secondsRemaining = DateTime.fromMillis(exp * 1000).diffNow("seconds")
      .seconds
    const resetTokens = () => {
      setRefreshToken("")
      setAccessToken("")
      dispatch(tokenRefreshFailure())
    }
    if (secondsRemaining < MIN_LIFESPAN_IN_SECONDS) {
      const refreshToken = getRefreshToken()
      const response = await fetch(`${apiRoot}/oauth/token`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `refresh_token=${refreshToken}&grant_type=refresh_token`,
      })

      let json: any
      if (response.ok) {
        json = await response.json()
      }
      if (!json?.access_token) {
        resetTokens()
        return undefined
      }
      setRefreshToken(json.refresh_token)
      setAccessToken(json.access_token)
      return json.access_token as string
    }
    return existingToken
  }

  return fetchAccessToken
}
