import moment from "moment"
import { NAME } from "../constants"
import { entities } from "./baseSelector"
import * as orgs from "./organizationSelector"

/**
 * Returns sorts an array of invitation entities by
 * prioritizing pending invitations and then the name
 * of the person invited.
 * @param  {Array} invitations An array of invitation entities to sort.
 * @return {Array}             An array of sorted invitation entities.
 */
const sortedInvitations = (invitations) =>
  invitations.sort((a, b) => {
    if (a.accepted !== b.accepted) {
      return a.accepted ? 1 : -1
    }
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  })

/**
 * Returns all invitation entities in the cache that have not been
 * deleted.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of invitation entities.
 */
export const all = (state) =>
  sortedInvitations(
    Object.keys(entities(state).invitations)
      .map((key) => entities(state).invitations[key])
      .filter((entity) => !entity.isDeleted)
  )

/**
 * Retrieves a specific invitation object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching invitation if one exists.
 */
export const find = (state) => (invitationId) => {
  const entity = entities(state).invitations[invitationId]
  return entity && !entity.isDeleted && entity
}

/**
 * Retrieves permissions for a specific invitation object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching set of permissions if they exist.
 */
export const availablePermissions = (state) => (invitationId) => {
  const permissions = entities(state).permissions
  const perms = Object.keys(entities(state).permissions).map(
    (k) => permissions[k]
  )
  return (
    (perms &&
      perms.filter(
        (p) =>
          parseInt(p.invitation ?? p.invitationId, 0) ===
          parseInt(invitationId, 0)
      )) ||
    []
  )
}

/**
 * Retrieves permissions for a specific invitation and calendar object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching set of permissions if they exist.
 */
export const findPermission = (state) => (invitationId, calendarId) => {
  const permissions = availablePermissions(state)(invitationId)
  return permissions.filter((p) => p.calendar === calendarId)
}

/**
 * Find a specific invitation by email address. This method must use a filter so
 * it is recommended to use the standard `find` selector unless you do not
 * have access to the ID for the entity you need to fetch.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching invitation if one exists.
 */
export const findByEmail = (state) => (email) => {
  const entity = all(state).filter(
    (invitation) => invitation.email === email
  )[0]
  return entity && !entity.isDeleted && entity
}

/**
 * Returns a query function that fetches all invitations for a single organization.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of invitations that match a supplied org ID.
 */
export const forOrganization = (state) => (organization) =>
  sortedInvitations(
    all(state).filter((invitation) => invitation.organization === organization)
  )

/**
 * functions the same as the 'forOrganization' selector but filters out invisible users such as super admins.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of invitations that match a supplied org ID sans super users.
 */
export const editableInvitationsForOrganization = (state) => (organization) =>
  forOrganization(state)(organization).filter(
    (invitation) => !(invitation.accepted && invitation.accessLevel === "super")
  )

/**
 * Retrieves any pending invitation attached to the current redux state.
 * @param  {Object} state The current state of the redux store.
 * @return {Objerct}      A pending invitation if one exists.
 */
export const pendingInvitation = (state) => state[NAME].pendingInvitation

/**
 * Retrieves any pending invitation attached to the current redux state.
 * @param  {Object} state The current state of the redux store.
 * @return {String}       The current JWT for an invitation.
 */
export const pendingInvitationToken = (state) => pendingInvitation(state).token

/**
 * Retrieves any pending invitation that has been imported into the entities cache.
 * @param  {Object} state The current state of the redux store.
 * @return {Objerct}      An invitation object.
 */
export const pendingInvitationEntity = (state) => {
  const id = pendingInvitation(state).invitationId
  if (id > 0) {
    return find(state)(id) || pendingInvitation(state)
  } else {
    return {}
  }
}

/**
 * Retrieves a pending invitation and returns the organization the invitation
 * belongs to.
 * @param  {Object} state The current state of the redux store.
 * @return {String}       An ID (subdomain key) for an organization.
 */
export const pendingInvitationOrganization = (state) => {
  const entity = pendingInvitationEntity(state)
  return (entity.organization && orgs.find(state)(entity.organization)) || {}
}

/**
 * Helper method to determine whether or not an invitation is considered
 * to be pending.
 * @param  {Object} invitation The invitation to check..
 * @return {Boolean}           Returns true if the invitation is pending.
 */
export const checkPending = (invitation) =>
  invitation.id && !invitation.accepted

/**
 * Helper method to determine whether or not an invitation is considered
 * to be expired.
 * @param  {Object} invitation The invitation to check..
 * @return {Boolean}           Returns true if the invitation is expired.
 */
export const checkExpired = (invitation) =>
  checkPending(invitation) &&
  moment.utc().diff(moment.utc(invitation.updatedAt), "days") >= 6

/**
 * Helper method to determine whether or not an invitation should be allowed
 * to be resent..
 * @param  {Object} invitation The invitation to check..
 * @return {Boolean}           Returns true if the invitation is resendable.
 */
export const checkResend = (invitation) =>
  checkPending(invitation) &&
  moment.utc().diff(moment.utc(invitation.updatedAt), "seconds") >= 60
