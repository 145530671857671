import * as types from "../actionTypes"
import {
  adjustEventsWithOffset,
  eventSelector,
  saveEventPosition,
} from "../../api"
import { Selectable, getSelectedItems } from "../../selectable"

/**
 * Updates an event's dates after a drag and drop operation has finished.
 * @param {*} id The if of the event being dragged.
 */
export const endEventDrag = (id, calendarId) => (dispatch, getState) => {
  const state = getState()
  const selectedEvents = getSelectedItems(state)[Selectable.EVENT]
  dispatch({
    type: types.END_DRAG,
  })
  if (selectedEvents.length > 1) {
    const offset = [eventSelector.find(state)(id, calendarId)].map(
      (e) => 1 // moment.utc(e.startsAt).diff(moment.utc(startsAt), "days")
    )[0]
    return dispatch(
      adjustEventsWithOffset(
        selectedEvents,
        calendarId,
        calendarId,
        offset,
        null,
        false
      )
    )
  } else {
    return dispatch(saveEventPosition(id, calendarId))
  }
}
