import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { activeField } from "../selectors"
import { FORM_NAME } from "../constants"
import {
  createInvitation,
  requestInvitation,
  requestCalendar,
  requestOrganization,
  organizationSelector,
  invitationSelector,
  calendarSelector,
  updateInvitation,
  removeInvitation,
  resendInvitation,
  requestInvitationPermissions,
  createInvitationPermission,
  updateInvitationPermission,
} from "../../api"
import InvitationDetailView from "../components/InvitationDetailView.react"

/**
 * An object representing the bare minimum default values to
 * populate the invitation form.
 * @type {Object}
 */
const defaultInvitation = {
  email: "",
  accessLevel: "user",
}

const formValues = formValueSelector(FORM_NAME)

export default connect(
  (state) => ({
    currentEmailAddress: formValues(state, "email"),
    accessLevel: formValues(state, "accessLevel"),
    activeField: activeField(state),
    initialValues: defaultInvitation,
    findOrganization: organizationSelector.find(state),
    findInvitation: invitationSelector.find(state),
    findPermission: invitationSelector.findPermission(state),
    calendarsForOrg: calendarSelector.forOrganization(state),
    findInvitationByEmail: invitationSelector.findByEmail(state),
  }),
  {
    postInvitation: createInvitation,
    updateInvitation,
    loadInvitation: requestInvitation,
    loadOrganization: requestOrganization,
    loadCalendar: requestCalendar,
    requestInvitationPermissions,
    createInvitationPermission,
    updateInvitationPermission,
    removeInvitation,
    resendInvitation,
  }
)(InvitationDetailView)
