import { combineReducers } from "redux"
import { actionTypes as f } from "redux-form"

function activeField(state = "", action) {
  switch (action.type) {
    case f.FOCUS:
      return action.meta.field
    case f.BLUR:
      return ""
    default:
      return state
  }
}

export default combineReducers({
  activeField,
})
