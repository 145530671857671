import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { Avatar } from "ui"
import { ShortList } from "../../shared"
import { getOrganization } from "../../api"
import { VisibleOnlySuperAdmin } from "../../login"
import { faCog } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { DateTime } from "luxon"

const { List, Item, Group, SecondaryAction, Label, Search } = ShortList

const OrganizationListView = ({
  organizations,
  fetching,
  refreshList,
  onDismiss,
  subdomain,
}) => {
  const [search, setSearch] = useState("")
  const history = useHistory()
  const activeCutOff = DateTime.local().minus({ days: 90 }).toISO()
  return (
    <List
      isFetching={fetching(getOrganization())}
      addButtonText="New Company"
      addItemAuthWrapper={({ children }) => (
        <VisibleOnlySuperAdmin subdomain={subdomain}>
          {children}
        </VisibleOnlySuperAdmin>
      )}
      handleAddItemClick={() => {
        history.push("/org/new")
        onDismiss()
      }}
      refreshList={refreshList}
    >
      <Item>
        <Search
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder="Search Organizations"
        />
      </Item>
      {organizations
        .filter(
          (o) =>
            o.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            search === ""
        )
        .sort((a, b) =>
          a.lastActive < b.lastActive ? 1 : a.lastActive > b.lastActive ? -1 : 0
        )
        .map((organization) => (
          <Item
            key={organization.subdomain}
            handleClick={() => {
              history.push(`/org/${organization.subdomain}/cal/`)
              onDismiss()
            }}
            name={organization.name}
            selected={organization.subdomain === subdomain}
            secondaryAction={
              <VisibleOnlySuperAdmin>
                <SecondaryAction
                  onClick={() => {
                    onDismiss()
                    history.push(`/org/${organization.subdomain}/settings/`)
                  }}
                >
                  <FontAwesomeIcon icon={faCog} />
                </SecondaryAction>
              </VisibleOnlySuperAdmin>
            }
            handleSettingsClick={() => {
              onDismiss()
            }}
          >
            <div className="flex my-auto">
              <Avatar
                name={organization.name}
                color={
                  organization.lastActive < activeCutOff
                    ? "#ccc"
                    : organization.color
                }
              />
            </div>
            <Group>
              <Label style={Label.STYLES.PRIMARY}>{organization.name}</Label>
              <Label
                style={
                  organization.lastActive < activeCutOff
                    ? Label.STYLES.SECONDARY_ERROR
                    : Label.STYLES.SECONDARY
                }
                icon={
                  organization.lastActive < activeCutOff
                    ? faExclamationTriangle
                    : null
                }
              >
                last active{" "}
                {DateTime.fromISO(organization.lastActive, {
                  zone: "utc",
                }).toRelative()}
              </Label>
            </Group>
          </Item>
        ))}
    </List>
  )
}

OrganizationListView.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      usesGranularPermissions: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  fetching: PropTypes.func,
  refreshList: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
  subdomain: PropTypes.string,
}

export default OrganizationListView
