import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { styles as actions } from "../actions"
import { styleSelector } from "../selectors"

export const useStyle = (
  subdomain: string,
  calendarId: string,
  styleUuid: string
) => {
  const style = useSelector(styleSelector.find)(styleUuid, calendarId)
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestStyle(subdomain, calendarId, styleUuid))
  const { loading, refetch } = useRequest(request)
  return { loading, style, refetch }
}
