import { useSelector } from "react-redux"
import { authSelector } from "../selectors"

export interface CurrentUser {
  accessToken: string
  superAdmin: boolean
  admin: (subdomain: string) => boolean
  creator: (subdomain: string) => boolean
}
export const useCurrentUser = () =>
  useSelector((state) => authSelector.currentUser(state) as CurrentUser)
