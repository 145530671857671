import { PROTECTED } from "redux-jwt-protected-middleware"
import { camelizeKeys } from "humps"
import { schema, normalize } from "normalizr"
import { API_ROOT } from "../selectors"
import { CALL_API } from "./symbols"
import { getAccessToken } from "../tokens"

/**
 * A normalizer schema representing an organization from the API.
 */
const organizationSchema = new schema.Entity(
  "organizations",
  {},
  {
    idAttribute: (organization) => organization.subdomain,
  }
)

/**
 * A normalizer schema representing an external service API from the API.
 */
const connectionSchema = new schema.Entity(
  "connections",
  {},
  {
    idAttribute: (connection) => connection.subdomain,
  }
)

/**
 * A normalizer schema representing a performance metric from the API.
 */
const metricSchema = new schema.Entity(
  "metrics",
  {},
  {
    idAttribute: ({ id }) => id,
  }
)

/**
 * A normalizer schema representing an Icon from the API.
 */
const iconSchema = new schema.Entity(
  "icons",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing an Event from the API.
 */
const eventSchema = new schema.Entity(
  "events",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing a Holiday Schedule from the API.
 */
const holidayScheduleSchema = new schema.Entity(
  "holidaySchedules",
  {},
  {
    idAttribute: ({ key }) => key,
  }
)

/**
 * A normalizer schema representing a Holiday from the API.
 */
const holidaySchema = new schema.Entity(
  "holidays",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing a Category from the API.
 */
const categorySchema = new schema.Entity(
  "categories",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing a GranularPermission from the API.
 */
const granularPermissionSchema = new schema.Entity("permissions")

/**
 * A normalizer schema representing an Item from the API.
 */
const itemSchema = new schema.Entity(
  "items",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing a User from the API.
 */
const userSchema = new schema.Entity("users")

/**
 * A normalizer schema representing an Invitation from the API.
 */
const invitationSchema = new schema.Entity("invitations", {
  organization: organizationSchema,
  user: userSchema,
  granularPermissions: [granularPermissionSchema],
})

/**
 * A normalizer schema representing a Style from the API.
 */
const styleSchema = new schema.Entity(
  "styles",
  {},
  {
    idAttribute: ({ hashId }) => hashId,
  }
)

/**
 * A normalizer schema representing a Report from the API.
 */
const reportSchema = new schema.Entity("reports")

/**
 * A normalizer schema representing a Report Calendars from the API.
 */
const reportCalendarSchema = new schema.Entity("reportCalendars")

/**
 * A normalizer schema representing an Group from the API.
 */
const groupSchema = new schema.Entity(
  "groups",
  { organization: organizationSchema },
  {
    idAttribute: ({ uuid }) => uuid,
  }
)

/**
 * A normalizer schema representing a Calendar from the API.
 */
const calendarSchema = new schema.Entity("calendars", {
  organization: organizationSchema,
  events: [eventSchema],
  items: [itemSchema],
  categories: [categorySchema],
  styles: [styleSchema],
  holidays: [holidaySchema],
  icons: [iconSchema],
  group: groupSchema,
})

/**
 * A normalizer schema representing a Snapshot from the API.
 */
const snapshotSchema = new schema.Entity("snapshots", {
  calendar: calendarSchema,
  events: [eventSchema],
  items: [itemSchema],
  categories: [categorySchema],
  styles: [styleSchema],
  holidays: [holidaySchema],
  icons: [iconSchema],
})

userSchema.define({
  granularPermissions: [granularPermissionSchema],
  invitations: [invitationSchema],
})

granularPermissionSchema.define({
  invitation: invitationSchema,
  calendar: calendarSchema,
})

eventSchema.define({
  calendar: calendarSchema,
  style: styleSchema,
  item: itemSchema,
})

categorySchema.define({
  style: styleSchema,
  items: [itemSchema],
  categories: [categorySchema],
})

itemSchema.define({
  style: styleSchema,
  events: [eventSchema],
  dangerousUnfilteredEvents: [eventSchema],
})

reportSchema.define({
  reportCalendars: [reportCalendarSchema],
  organization: organizationSchema,
})

/**
 * A map of usable schemas to bind to action creators.
 */
export const Schemas = {
  CONNECTION: connectionSchema,
  CONNECTIONS_ARRAY: [connectionSchema],
  ORGANIZATION: organizationSchema,
  ORGANIZATION_ARRAY: [organizationSchema],
  ICON: iconSchema,
  ICONS_ARRAY: [iconSchema],
  METRIC: metricSchema,
  METRICS_ARRAY: [metricSchema],
  CALENDAR: calendarSchema,
  CALENDARS_ARRAY: [calendarSchema],
  SNAPSHOT: snapshotSchema,
  SNAPSHOTS_ARRAY: [snapshotSchema],
  CATEGORY: categorySchema,
  CATEGORIES_ARRAY: [categorySchema],
  ITEM: itemSchema,
  ITEMS_ARRAY: [itemSchema],
  USER: userSchema,
  INVITATION: invitationSchema,
  INVITATIONS_ARRAY: [invitationSchema],
  EVENT: eventSchema,
  EVENTS_ARRAY: [eventSchema],
  STYLE: styleSchema,
  STYLES_ARRAY: [styleSchema],
  PERMISSION: granularPermissionSchema,
  PERMISSIONS_ARRAY: [granularPermissionSchema],
  HOLIDAY: holidaySchema,
  HOLIDAYS_ARRAY: [holidaySchema],
  HOLIDAY_SCHEDULE: holidayScheduleSchema,
  HOLIDAY_SCHEDULES: [holidayScheduleSchema],
  REPORT: reportSchema,
  REPORTS_ARRAY: [reportSchema],
  REPORT_CALENDAR: reportCalendarSchema,
  GROUPS_ARRAY: [groupSchema],
  GROUP: groupSchema,
}

/**
 * Injects a JWT in an object representing http headers as the
 * authorization header.
 * @param {Object} headers  An object representing the headers for an HTTP request.
 * @param {String} token    A JWT access token to be injected into the supplied headers.
 * @return {Object}         A copy of the http headers object with the Authorization header set as the JWT.
 */
const injectToken = (headers, token) =>
  Object.assign({}, headers, { Authorization: `bearer ${token}` })

/**
 * Takes raw JSON from the rails API and converts it into
 * camelized json. If a schema is supplied the json will be
 * mapped to an object by normalizer.
 *
 * @param {Object} json     The raw json body from an API fetch request.
 * @param {Object} schema   An optional normalizer schema to apply to the JSON.
 * @return {Object}         The camelized and normalized representation of the supplied json.
 */
const formatJson = (json, schema) => {
  const camelizedJson = camelizeKeys(json)
  if (schema) {
    return normalize(camelizedJson, schema)
  }
  return camelizedJson
}

/**
 * Generates a fetch request with some chaining events to process and format
 * the payload.
 *
 * @param {String} baseUrl      The baseURL for the fetch request.
 * @param {String} endpoint     The API end point for the request.
 * @param {String} method       The HTTP verb to use in the request.
 * @param {Object} headers      A json object representing the HTTP headers for the request.
 * @param {String} body         The raw body to use in the http request.
 * @param {Object} schema       A normalizer schema to apply to the fetch results.
 * @param {Boolean} ignoreCache If true the payload will not be processed by normalizr and an empty object will be returned.
 * @return {Promise}            A promise returning the completed results from the fetch request.
 */
const callApi = (
  baseUrl,
  endpoint,
  method,
  headers,
  body,
  schema,
  ignoreCache
) => {
  const fullUrl =
    endpoint.indexOf(baseUrl) === -1 ? baseUrl + endpoint : endpoint
  let capturedResponse = {}
  let capturedJson = {}
  return fetch(fullUrl, { method, headers, body })
    .then((response) => {
      capturedResponse = response
      return response.json().then((json) => ({ json, response }))
    })
    .then(({ json, response }) => {
      capturedJson = json
      if (ignoreCache) {
        console.warn(`Ignoring cache for data returned from: ${endpoint}`)
      }
      const result = {
        payload: ignoreCache ? {} : formatJson(json, schema),
        response: {
          ok: response.ok || [200, 201].includes(response.status),
          status: response.status,
          rawResponse: response,
          responseJson: json,
        },
      }
      return result
    })
    .catch((e) => {
      return {
        payload: {},
        response: {
          ok:
            capturedResponse.ok || [200, 201].includes(capturedResponse.status),
          status: capturedResponse.status,
          rawResponse: capturedResponse,
          responseJson: capturedJson,
        },
      }
    })
}

/**
 * Validates a supplied endpoint is of the appropriate type. Throws an error
 * if the supplied endpoint is not a valid type.
 *
 * @param {String|Function} endpoint      The endpoint to test.
 */
const validateEndpoint = (endpoint) => {
  if (typeof endpoint !== "string" && typeof endpoint !== "function") {
    throw new Error("Specify a string or function to return an endpoint URL.")
  }
}

/**
 * Validates the supplied action types are valid for the API middleware. Throws
 * an error if an array three action identifiers have not been supplied. We expect
 * an array that matches: [REQUEST, SUCCESS, FAILURE].
 *
 * @param {Array} types      The array of action types to dispatch before and after the api request.
 */
const validateTypes = (types) => {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.")
  }

  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.")
  }
}

/**
 * Validates the supplied method is in fact present.
 *
 * @param {String} method      The HTTP method to test.
 */
const validateMethod = (method) => {
  if (typeof method !== "string") {
    throw new Error("Specify a string specifyinh an HTTP Method.")
  }
}

/**
 * Validates a supplied action to ensure it can be used by
 * the API Middleware. Any of the validations will throw an
 * error if they fail because unexpected results will occur
 * if the client specifies an invalid action to be processed
 * by the API Middleware.
 *
 * @param {Object} action      The redux action to test.
 */
const validateAction = (action) => {
  const { types, endpoint, method } = action[CALL_API]
  validateEndpoint(endpoint)
  validateMethod(method)
  validateTypes(types)
}

/**
 * Takes an action and extracts any error information from the payload.
 * Provides a default message if no string was supplied.
 *
 * @param {Object} action   The action to determine an error for.
 * @return {String}         The error message in the action's payload.
 */
const getErrorMessage = (action) =>
  (action && action.payload && action.payload.errorDescription) ||
  "There was a problem connecting to the server."

/**
 * Takes a supplied action and maps it's props into a unique string
 * based on it's endpoint and body contents. This enables us to track
 * and prevent duplicate requests.
 *
 * @param {Object} action   The action to generate an identifier for.
 * @return {String}         Te contents of an action mapped to an id string.
 */
export const requestIdentifierFor = (action) => {
  const callAPI = action[CALL_API]
  return callAPI && [callAPI.method, callAPI.body, callAPI.endpoint].join("::")
}

/**
 * The API middleware method. This is a standard redux middleware that
 * checks for a qualifying action by identifying if the action contains
 * the [CALL_API] symbol. If so it will validate the action and then
 * dispatch the three supplied action types for REQUEST, SUCCESS, and
 * FAILURE.
 *
 * @param {Object} store  The current redux store.
 * @return {Function}     The API middleware function
 */
const middleware = (store) => (next) => (action) => {
  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const callAPI = action[CALL_API]
  if (typeof callAPI === "undefined") {
    return next(action)
  }

  let { endpoint } = callAPI
  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState())
  }

  const { types, method, headers, body, schema, ignoreCache } = callAPI
  const [requestType, successType, failureType] = types

  validateAction(action)
  next(
    actionWith({
      type: requestType,
      meta: { api: { reqID: requestIdentifierFor(action), fetching: true } },
    })
  )

  const isProtected = action[PROTECTED]
  const mutatedHeaders = isProtected
    ? injectToken(headers, getAccessToken())
    : headers

  const baseUrl = API_ROOT(store.getState())

  return callApi(
    baseUrl,
    endpoint,
    method,
    mutatedHeaders,
    body,
    schema,
    ignoreCache
  )
    .then((response) => {
      return next(
        actionWith({
          payload: response.payload,
          response: response.response,
          type: response.response.ok ? successType : failureType,
          meta: {
            api: { reqID: requestIdentifierFor(action), fetching: false },
          },
          error: !response.response.ok,
          errorMessage: !response.response.ok
            ? getErrorMessage(response)
            : null,
          errors: response.response.errors,
        })
      )
    })
    .catch((error) =>
      next(
        actionWith({
          type: failureType,
          payload: error.payload || {},
          response: error.response || {},
          meta: {
            api: { reqID: requestIdentifierFor(action), fetching: false },
          },
          error: true,
          errorMessage: getErrorMessage(error),
        })
      )
    )
}

export default middleware
