import React, { Component } from "react"
import PropTypes from "prop-types"
import { View, Printable } from "../../shared"
import styles from "./CalendarView.module.css"
import { PreviewTypes } from "../../itemExplorer"
import { RendererContainerView } from "./RendererContainerView"
import { isNotBlank } from "../../utils/helpers"

class Page extends Component {
  /**
   * Checks for used defined headers.
   * @returns {Boolean} True if any of the custom headers have been defined.
   */
  hasHeaders() {
    const { topLeft, topRight, topCenter } = this.props.fields
    return isNotBlank(topLeft) || isNotBlank(topRight) || isNotBlank(topCenter)
  }

  /**
   * Checks for used defined footers.
   * @returns {Boolean} True if any of the custom footers have been defined.
   */
  hasFooters() {
    const { bottomLeft, bottomRight, bottomCenter } = this.props.fields
    return (
      isNotBlank(bottomLeft) ||
      isNotBlank(bottomRight) ||
      isNotBlank(bottomCenter)
    )
  }

  renderHeaders() {
    const { fields, onChangeFields } = this.props
    return (
      <Printable.Section active={this.hasHeaders()}>
        <Printable.Region>
          <Printable.Field
            value={fields.topLeft || ""}
            onChange={(event) =>
              onChangeFields({ topLeft: event.target.value })
            }
            placeholder="Customize Heading"
            textAlign="left"
            style={{ fontSize: fields.headerFontSize }}
          />
        </Printable.Region>
        <Printable.Region>
          <Printable.Field
            value={fields.topCenter || ""}
            onChange={(event) =>
              onChangeFields({ topCenter: event.target.value })
            }
            placeholder="Customize Heading"
            textAlign="center"
            style={{ fontSize: fields.headerFontSize }}
          />
        </Printable.Region>
        <Printable.Region>
          <Printable.Field
            value={fields.topRight || ""}
            onChange={(event) =>
              onChangeFields({ topRight: event.target.value })
            }
            placeholder="Customize Heading"
            textAlign="right"
            style={{ fontSize: fields.headerFontSize }}
          />
        </Printable.Region>
      </Printable.Section>
    )
  }

  renderFooters() {
    const { fields, onChangeFields } = this.props
    return (
      <Printable.Section active={this.hasFooters()}>
        <Printable.Region>
          <Printable.Field
            value={fields.bottomLeft || ""}
            onChange={(event) =>
              onChangeFields({ bottomLeft: event.target.value })
            }
            placeholder="Customize Footer"
            textAlign="left"
            style={{ fontSize: fields.footerFontSize }}
          />
        </Printable.Region>
        <Printable.Region>
          <Printable.Field
            value={fields.bottomCenter || ""}
            onChange={(event) =>
              onChangeFields({ bottomCenter: event.target.value })
            }
            placeholder="Customize Footer"
            textAlign="center"
            style={{ fontSize: fields.footerFontSize }}
          />
        </Printable.Region>
        <Printable.Region>
          <Printable.Field
            value={fields.bottomRight || ""}
            onChange={(event) =>
              onChangeFields({ bottomRight: event.target.value })
            }
            placeholder="Customize Footer"
            textAlign="right"
            style={{ fontSize: fields.footerFontSize }}
          />
        </Printable.Region>
      </Printable.Section>
    )
  }

  render() {
    const { children, resourceName, fields, page, totalPages, highContrast } =
      this.props
    return (
      <View.ViewContainer
        className={`${styles.printContainer} ${styles.calendarContainer}`}
      >
        <Printable.Column>
          {this.renderHeaders()}
          <div
            className="flex border border-gray-400 flex-grow flex-col print:border-0"
            style={{
              borderColor: highContrast ? "#000" : undefined,
            }}
          >
            <h1
              className={styles.calendarPrintTitle}
              style={{
                fontSize: fields.nameFontSize,
                display: fields.printCalendarName ? "block" : "none",
                color: highContrast ? "#000" : undefined,
              }}
            >
              {resourceName}
            </h1>
            <RendererContainerView
              itemPreviewType={PreviewTypes.EVENT_PREVIEW}
              updateItemPreviewType={() => PreviewTypes.EVENT_PREVIEW}
              printing={true}
            >
              {children}
            </RendererContainerView>
          </div>
          {this.renderFooters()}
          {fields.showPageNumbers ? (
            <p className="mx-auto text-sm">
              Page {page} of {totalPages}
            </p>
          ) : null}
        </Printable.Column>
      </View.ViewContainer>
    )
  }
}

Page.propTypes = {
  /**
   * A set of custom fields defining the headers, footers, and other
   * page metadata.
   */
  fields: PropTypes.object.isRequired,

  /**
   * Any react children passed to this component.
   */
  children: PropTypes.object.isRequired,

  /**
   * A change handler that is called when any related fields are updated from the page..
   */
  onChangeFields: PropTypes.func.isRequired,

  /**
   * The name of the resource represented on this page.
   */
  resourceName: PropTypes.string.isRequired,

  /**
   * The current page within its parent document.
   */
  page: PropTypes.number.isRequired,

  /**
   * The total pages included in the document this page belongs to.
   */
  totalPages: PropTypes.number.isRequired,

  /**
   * Indicates whether or not to adjust the appearance of the text and banners..
   */
  highContrast: PropTypes.bool,
}

export default Page
