var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "./Icon";
import { Loader } from "./Loader";
var colorForType = function (type) {
    switch (type) {
        case "error":
            return "red";
        case "confirmation":
            return "green";
        default:
            return "gray";
    }
};
var CONTAINER_CLASS = function (type) {
    return "rounded p-2 my-1 bg-" + colorForType(type) + "-200 relative";
};
var DISABLED_CLASS = "opacity-50 pointer-events-none";
var HEADING_CLASS = function (type) {
    return "flex border-b border-" + colorForType(type) + "-300 pb-1 mb-3";
};
var DISMISS_BUTTON_CLASS = function (type) {
    return "\"bg-" + colorForType(type) + "-100 px-2 rounded my-auto ml-auto border\"";
};
var TITLE_CLASS = "text-sm font-semibold my-auto";
export var Dismissable = function (_a) {
    var loading = _a.loading, disabled = _a.disabled, children = _a.children, onDismiss = _a.onDismiss, type = _a.type, title = _a.title;
    var userInteractionDisabled = loading || disabled;
    var handleDismiss = function (e) {
        e.preventDefault();
        if (onDismiss) {
            onDismiss();
        }
    };
    return (_jsxs("div", __assign({ className: CONTAINER_CLASS(type) + " " + (userInteractionDisabled ? DISABLED_CLASS : "") }, { children: [_jsxs("header", __assign({ className: HEADING_CLASS(type) }, { children: [_jsx("h3", __assign({ className: TITLE_CLASS }, { children: title }), void 0), onDismiss ? (_jsx("button", __assign({ className: DISMISS_BUTTON_CLASS(type), onClick: handleDismiss }, { children: _jsx(Icon, { icon: faTimes, mode: "default" }, void 0) }), void 0)) : null] }), void 0), children, loading ? _jsx(Loader, {}, void 0) : null] }), void 0));
};
