var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup } from "../Form";
import { Modal } from "./Modal";
var MESSAGE_STYLES = "text-md px-2 mb-4";
export var Dialog = function (_a) {
    var children = _a.children, message = _a.message, cancelLabel = _a.cancelLabel, confirmLabel = _a.confirmLabel, handleConfirm = _a.onConfirm, handleCancel = _a.onCancel, modalProps = __rest(_a, ["children", "message", "cancelLabel", "confirmLabel", "onConfirm", "onCancel"]);
    return (_jsxs(Modal, __assign({}, modalProps, { children: [_jsx("p", __assign({ className: MESSAGE_STYLES }, { children: message }), void 0), _jsxs(ButtonGroup, __assign({ direction: "row", grow: true }, { children: [handleCancel ? (_jsx(Button, __assign({ appearance: "secondary", className: "w-full flex-grow", onClick: handleCancel }, { children: cancelLabel !== null && cancelLabel !== void 0 ? cancelLabel : "Cancel" }), void 0)) : null, _jsx(Button, __assign({ appearance: "primary", className: "w-full flex-grow", onClick: handleConfirm }, { children: confirmLabel !== null && confirmLabel !== void 0 ? confirmLabel : "Confirm" }), void 0)] }), void 0)] }), void 0));
};
