import React, { Component } from "react"
import PropTypes from "prop-types"
import SelectableItem from "./SelectableItem.react"
import styles from "./EventView.module.css"

class EventView extends Component {
  constructor(props) {
    super(props)
    this.state = { hovering: false }
  }

  handleDoubleClick() {
    const { handleEditEvent, event } = this.props
    handleEditEvent(event.uuid)
  }

  handleClick(e) {
    e.stopPropagation()
    const { handleSelectedEvent, event } = this.props
    handleSelectedEvent(event.uuid)
  }

  handleMouseOver() {
    this.setState({ hovering: true })
  }

  handleMouseLeave() {
    this.setState({ hovering: false })
  }

  createNamePreview() {
    const { name, event } = this.props
    if (event.name) {
      return `${name} (${event.name})`
    }
    return name
  }

  render() {
    const { indentation, custom, selected } = this.props

    return (
      <div
        onMouseOver={() => this.handleMouseOver()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <SelectableItem
          handleClick={(e) => this.handleClick(e)}
          handleDoubleClick={(e) => e.preventDefault()}
          selected={selected}
        >
          <div
            className={`${styles.explorerEventView} ${custom && styles.custom}`}
            style={{ marginLeft: `${3.6 + indentation * 1.2}rem` }}
          >
            <span className={styles.name}>{this.createNamePreview()}</span>
          </div>
        </SelectableItem>
      </div>
    )
  }
}

EventView.propTypes = {
  /**
   * The event entity being represented by this view.
   */
  event: PropTypes.object.isRequired,

  /**
   * The name of the event to display.
   */
  name: PropTypes.string.isRequired,

  /**
   * If true - it should indicate that the item is using
   * a custom style to overwrite its parent.
   */
  custom: PropTypes.bool.isRequired,

  /**
   * Called on an interaction with an event to request
   * the editing modal.
   */
  handleEditEvent: PropTypes.func,

  /**
   * A callback that fired when this event is selected
   * by the user. Useful for keyboard actions.
   */
  handleSelectedEvent: PropTypes.func.isRequired,

  /**
   * The level of indentation to be applied to this view.
   * Indententation is used to simulate nesting in the
   * directory structure.
   */
  indentation: PropTypes.number.isRequired,

  /**
   * Indicates whether or not this item should appear
   * as if it were selected by the user.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * Ensures this view will not allow any edit interactions if set to true.
   */
  readOnly: PropTypes.bool.isRequired,
}

export default EventView
