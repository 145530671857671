var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var BASE_BAR = "flex flex-row border-b border-gray-300 bg-white-100 p-1 relative";
var COMPACT_BAR = BASE_BAR + " overflow-visible";
var HIDDEN_BAR = BASE_BAR + " h-0 overflow-hidden";
var classForMode = function (mode) {
    switch (mode) {
        case "compact":
            return COMPACT_BAR;
        case "hidden":
            return HIDDEN_BAR;
    }
};
export var ToolBar = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? "compact" : _b, children = _a.children;
    return _jsx("div", __assign({ className: classForMode(mode) }, { children: children }), void 0);
};
