import React from "react"
import PropTypes from "prop-types"
import gridStyles from "../../utils/gridStyles"

const FluidContainer = ({ children }) => (
  <div className={gridStyles("container-fluid")}>{children}</div>
)

FluidContainer.propTypes = {
  children: PropTypes.node,
}

export default FluidContainer
