var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { concatStyles } from "utils";
var BUTTON_STYLES = "rounded font-semibold text-sm text-center uppercase px-5 py-3";
var LINK_STYLES = "flex font-semibold text-lochivar-default underline";
var DISABLED_STYLES = "opacity-50 cursor-not-allowed";
export var backgroundStyles = function (appearance) {
    switch (appearance) {
        case "primary":
            return "bg-burgundy-default text-white";
        case "secondary":
            return "bg-white border-2 border-burgundy-default text-burgundy-default";
        case "destructive":
            return "bg-red-600 text-white";
        case "accent":
            return "bg-voodoo-dark text-white";
        case "custom":
        case "link":
            return "";
        default:
            return "bg-burgundy-default text-white";
    }
};
var alignmentStyles = function (alignment) {
    switch (alignment) {
        case "center":
            return "w-auto mx-auto";
        case "right":
            return "w-auto ml-auto";
        case "left":
            return "w-auto mr-auto";
        case "stretch":
            return "w-full";
        default:
            return "w-auto";
    }
};
export var Button = function (_a) {
    var align = _a.align, appearance = _a.appearance, children = _a.children, className = _a.className, disabled = _a.disabled, _b = _a.style, style = _b === void 0 ? {} : _b, onClick = _a.onClick, form = _a.form, _c = _a.type, type = _c === void 0 ? "button" : _c;
    var _d = useState(false), focus = _d[0], setFocus = _d[1];
    var _e = useState(false), hover = _e[0], setHover = _e[1];
    var toggleState = function (state, newVal) { return function () {
        state === "hover" ? setHover(newVal) : setFocus(newVal);
    }; };
    var brightness = focus ? 0.97 : hover ? 1.03 : 1;
    return (_jsx("button", __assign({ className: concatStyles([
            appearance === "link" && LINK_STYLES,
            !["custom", "link"].includes(appearance !== null && appearance !== void 0 ? appearance : "") && BUTTON_STYLES,
            backgroundStyles(appearance),
            alignmentStyles(align),
            disabled ? DISABLED_STYLES : "",
            className,
        ]), disabled: disabled, style: __assign({ filter: brightness !== 1 ? "brightness(" + brightness + ")" : "" }, style), onMouseDown: toggleState("focus", true), onMouseUp: toggleState("focus", false), onMouseEnter: toggleState("hover", true), onMouseLeave: toggleState("hover", false), onClick: onClick, form: form, type: type }, { children: children }), void 0));
};
Button.displayName = "Form.Button";
