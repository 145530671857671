import { NAME } from "./constants"

//
// API CONFIG
//

export const API_BASE_CHANGED = `${NAME}/API_BASE_CHANGED`
export const API_PROTOCOL_CHANGED = `${NAME}/API_PROTOCOL_CHANGED`

//
// ENTITIES
//

export const OPTIMISTIC_ENTITY_UPDATE = `${NAME}/OPTIMISTIC_ENTITY_UPDATE`

//
// AUTHENTICATION
//

export const LOGOUT_REQUEST = `${NAME}/LOGOUT_REQUEST`
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`
export const LOGOUT_FAILURE = `${NAME}/LOGOUT_FAILURE`

export const FETCH_AUTH_TOKEN = `${NAME}/FETCH_AUTH_TOKEN`
export const FETCH_AUTH_TOKEN_REQUEST = `${NAME}/FETCH_AUTH_TOKEN_REQUEST`
export const FETCH_AUTH_TOKEN_SUCCESS = `${NAME}/FETCH_AUTH_TOKEN_SUCCESS`
export const FETCH_AUTH_TOKEN_FAILURE = `${NAME}/FETCH_AUTH_TOKEN_FAILURE`
export const FETCH_AUTH_TOKEN_EXPIRED = `${NAME}/FETCH_AUTH_TOKEN_EXPIRED`

export const FETCH_CURRENT_USER = `${NAME}/FETCH_CURRENT_USER`
export const FETCH_CURRENT_USER_REQUEST = `${NAME}/FETCH_CURRENT_USER_REQUEST`
export const FETCH_CURRENT_USER_SUCCESS = `${NAME}/FETCH_CURRENT_USER_SUCCESS`
export const FETCH_CURRENT_USER_FAILURE = `${NAME}/FETCH_CURRENT_USER_FAILURE`

export const UPDATE_AUTH_STATUS = `${NAME}/UPDATE_AUTH_STATUS`
export const AUTH_ROLE_UPDATE = `${NAME}/AUTH_ROLE_UPDATE`

//
// USERS
//

export const CREATE_USER = `${NAME}/CREATE_USER`
export const CREATE_USER_REQUEST = `${NAME}/CREATE_USER_REQUEST`
export const CREATE_USER_SUCCESS = `${NAME}/CREATE_USER_SUCCESS`
export const CREATE_USER_FAILURE = `${NAME}/CREATE_USER_FAILURE`

export const CREATE_FORGOT_PASSWORD = `${NAME}/CREATE_FORGOT_PASSWORD`
export const CREATE_FORGOT_PASSWORD_REQUEST = `${NAME}/CREATE_FORGOT_PASSWORD_REQUEST`
export const CREATE_FORGOT_PASSWORD_SUCCESS = `${NAME}/CREATE_FORGOT_PASSWORD_SUCCESS`
export const CREATE_FORGOT_PASSWORD_FAILURE = `${NAME}/CREATE_FORGOT_PASSWORD_FAILURE`

export const PATCH_RESET_PASSWORD = `${NAME}/PATCH_RESET_PASSWORD`
export const PATCH_RESET_PASSWORD_REQUEST = `${NAME}/PATCH_RESET_PASSWORD_REQUEST`
export const PATCH_RESET_PASSWORD_SUCCESS = `${NAME}/PATCH_RESET_PASSWORD_SUCCESS`
export const PATCH_RESET_PASSWORD_FAILURE = `${NAME}/PATCH_RESET_PASSWORD_FAILURE`

//
// ORGANIZATIONS
//

export const FETCH_ORGANIZATIONS = `${NAME}/FETCH_ORGANIZATIONS_REQUEST`
export const FETCH_ORGANIZATIONS_REQUEST = `${NAME}/FETCH_ORGANIZATIONS_REQUEST`
export const FETCH_ORGANIZATIONS_FAILURE = `${NAME}/FETCH_ORGANIZATIONS_FAILURE`
export const FETCH_ORGANIZATIONS_SUCCESS = `${NAME}/FETCH_ORGANIZATIONS_SUCCESS`

export const CREATE_ORGANIZATION = `${NAME}/CREATE_ORGANIZATION`
export const CREATE_ORGANIZATION_REQUEST = `${NAME}/CREATE_ORGANIZATION_REQUEST`
export const CREATE_ORGANIZATION_FAILURE = `${NAME}/CREATE_ORGANIZATION_FAILURE`
export const CREATE_ORGANIZATION_SUCCESS = `${NAME}/CREATE_ORGANIZATION_SUCCESS`

export const UPDATE_ORGANIZATION = `${NAME}/UPDATE_ORGANIZATION`
export const UPDATE_ORGANIZATION_REQUEST = `${NAME}/UPDATE_ORGANIZATION_REQUEST`
export const UPDATE_ORGANIZATION_FAILURE = `${NAME}/UPDATE_ORGANIZATION_FAILURE`
export const UPDATE_ORGANIZATION_SUCCESS = `${NAME}/UPDATE_ORGANIZATION_SUCCESS`

export const DELETE_ORGANIZATION = `${NAME}/DELETE_ORGANIZATION`
export const DELETE_ORGANIZATION_REQUEST = `${NAME}/DELETE_ORGANIZATION_REQUEST`
export const DELETE_ORGANIZATION_FAILURE = `${NAME}/DELETE_ORGANIZATION_FAILURE`
export const DELETE_ORGANIZATION_SUCCESS = `${NAME}/DELETE_ORGANIZATION_SUCCESS`

export const VALIDATE_ORGANIZATION = `${NAME}/VALIDATE_ORGANIZATION`
export const VALIDATE_ORGANIZATION_REQUEST = `${NAME}/VALIDATE_ORGANIZATION_REQUEST`
export const VALIDATE_ORGANIZATION_FAILURE = `${NAME}/VALIDATE_ORGANIZATION_FAILURE`
export const VALIDATE_ORGANIZATION_SUCCESS = `${NAME}/VALIDATE_ORGANIZATION_SUCCESS`

//
// ANALYTICS
//
export const FETCH_METRIC_ACTIVE_USERS = `${NAME}/FETCH_METRIC_ACTIVE_USERS_REQUEST`
export const FETCH_METRIC_ACTIVE_USERS_REQUEST = `${NAME}/FETCH_METRIC_ACTIVE_USERS_REQUEST`
export const FETCH_METRIC_ACTIVE_USERS_FAILURE = `${NAME}/FETCH_METRIC_ACTIVE_USERS_FAILURE`
export const FETCH_METRIC_ACTIVE_USERS_SUCCESS = `${NAME}/FETCH_METRIC_ACTIVE_USERS_SUCCESS`

export const FETCH_METRIC_ACTIVE_CALENDARS = `${NAME}/FETCH_METRIC_ACTIVE_CALENDARS`
export const FETCH_METRIC_ACTIVE_CALENDARS_REQUEST = `${NAME}/FETCH_METRIC_ACTIVE_CALENDARS_REQUEST`
export const FETCH_METRIC_ACTIVE_CALENDARS_FAILURE = `${NAME}/FETCH_METRIC_ACTIVE_CALENDARS_FAILURE`
export const FETCH_METRIC_ACTIVE_CALENDARS_SUCCESS = `${NAME}/FETCH_METRIC_ACTIVE_CALENDARS_SUCCESS`

export const FETCH_METRIC_ACTIVE_USER_COUNT = `${NAME}/FETCH_METRIC_ACTIVE_USER_COUNT`
export const FETCH_METRIC_ACTIVE_USER_COUNT_REQUEST = `${NAME}/FETCH_METRIC_ACTIVE_USER_COUNT_REQUEST`
export const FETCH_METRIC_ACTIVE_USER_COUNT_FAILURE = `${NAME}/FETCH_METRIC_ACTIVE_USER_COUNT_FAILURE`
export const FETCH_METRIC_ACTIVE_USER_COUNT_SUCCESS = `${NAME}/FETCH_METRIC_ACTIVE_USER_COUNT_SUCCESS`

export const FETCH_METRIC_TOTAL_USER_COUNT = `${NAME}/FETCH_METRIC_TOTAL_USER_COUNT`
export const FETCH_METRIC_TOTAL_USER_COUNT_REQUEST = `${NAME}/FETCH_METRIC_TOTAL_USER_COUNT_REQUEST`
export const FETCH_METRIC_TOTAL_USER_COUNT_FAILURE = `${NAME}/FETCH_METRIC_TOTAL_USER_COUNT_FAILURE`
export const FETCH_METRIC_TOTAL_USER_COUNT_SUCCESS = `${NAME}/FETCH_METRIC_TOTAL_USER_COUNT_SUCCESS`

export const FETCH_METRIC_INVITATIONS = `${NAME}/FETCH_METRIC_INVITATIONS`
export const FETCH_METRIC_INVITATIONS_REQUEST = `${NAME}/FETCH_METRIC_INVITATIONS_REQUEST`
export const FETCH_METRIC_INVITATIONS_FAILURE = `${NAME}/FETCH_METRIC_INVITATIONS_FAILURE`
export const FETCH_METRIC_INVITATIONS_SUCCESS = `${NAME}/FETCH_METRIC_INVITATIONS_SUCCESS`

//
// REPORTS
//

export const FETCH_REPORTS = `${NAME}/FETCH_REPORTS`
export const FETCH_REPORTS_REQUEST = `${NAME}/FETCH_REPORTS_REQUEST`
export const FETCH_REPORTS_FAILURE = `${NAME}/FETCH_REPORTS_FAILURE`
export const FETCH_REPORTS_SUCCESS = `${NAME}/FETCH_REPORTS_SUCCESS`

export const FETCH_FULL_REPORT_CALENDARS = `${NAME}/FETCH_FULL_REPORT_CALENDARS_REQUEST`
export const FETCH_FULL_REPORT_CALENDARS_REQUEST = `${NAME}/FETCH_FULL_REPORT_CALENDARS_REQUEST`
export const FETCH_FULL_REPORT_CALENDARS_FAILURE = `${NAME}/FETCH_FULL_REPORT_CALENDARS_FAILURE`
export const FETCH_FULL_REPORT_CALENDARS_SUCCESS = `${NAME}/FETCH_FULL_REPORT_CALENDARS_SUCCESS`

export const CREATE_REPORT = `${NAME}/CREATE_REPORT`
export const CREATE_REPORT_REQUEST = `${NAME}/CREATE_REPORT_REQUEST`
export const CREATE_REPORT_FAILURE = `${NAME}/CREATE_REPORT_FAILURE`
export const CREATE_REPORT_SUCCESS = `${NAME}/CREATE_REPORT_SUCCESS`

export const UPDATE_REPORT = `${NAME}/UPDATE_REPORT`
export const UPDATE_REPORT_REQUEST = `${NAME}/UPDATE_REPORT_REQUEST`
export const UPDATE_REPORT_FAILURE = `${NAME}/UPDATE_REPORT_FAILURE`
export const UPDATE_REPORT_SUCCESS = `${NAME}/UPDATE_REPORT_SUCCESS`

export const DELETE_REPORT = `${NAME}/DELETE_REPORT`
export const DELETE_REPORT_REQUEST = `${NAME}/DELETE_REPORT_REQUEST`
export const DELETE_REPORT_FAILURE = `${NAME}/DELETE_REPORT_FAILURE`
export const DELETE_REPORT_SUCCESS = `${NAME}/DELETE_REPORT_SUCCESS`

export const ADD_REPORT_CALENDAR = `${NAME}/ADD_REPORT_CALENDAR`
export const ADD_REPORT_CALENDAR_REQUEST = `${NAME}/ADD_REPORT_CALENDAR_REQUEST`
export const ADD_REPORT_CALENDAR_FAILURE = `${NAME}/ADD_REPORT_CALENDAR_FAILURE`
export const ADD_REPORT_CALENDAR_SUCCESS = `${NAME}/ADD_REPORT_CALENDAR_SUCCESS`

export const UPDATE_REPORT_CALENDAR = `${NAME}/UPDATE_REPORT_CALENDAR`
export const UPDATE_REPORT_CALENDAR_REQUEST = `${NAME}/UPDATE_REPORT_CALENDAR_REQUEST`
export const UPDATE_REPORT_CALENDAR_FAILURE = `${NAME}/UPDATE_REPORT_CALENDAR_FAILURE`
export const UPDATE_REPORT_CALENDAR_SUCCESS = `${NAME}/UPDATE_REPORT_CALENDAR_SUCCESS`

export const REMOVE_REPORT_CALENDAR = `${NAME}/REMOVE_REPORT_CALENDAR`
export const REMOVE_REPORT_CALENDAR_REQUEST = `${NAME}/REMOVE_REPORT_CALENDAR_REQUEST`
export const REMOVE_REPORT_CALENDAR_FAILURE = `${NAME}/REMOVE_REPORT_CALENDAR_FAILURE`
export const REMOVE_REPORT_CALENDAR_SUCCESS = `${NAME}/REMOVE_REPORT_CALENDAR_SUCCESS`

//
// CALENDARS
//

export const FETCH_CALENDARS = `${NAME}/FETCH_CALENDARS_REQUEST`
export const FETCH_CALENDARS_REQUEST = `${NAME}/FETCH_CALENDARS_REQUEST`
export const FETCH_CALENDARS_FAILURE = `${NAME}/FETCH_CALENDARS_FAILURE`
export const FETCH_CALENDARS_SUCCESS = `${NAME}/FETCH_CALENDARS_SUCCESS`

export const FETCH_GROUPS = `${NAME}/FETCH_GROUPS_REQUEST`
export const FETCH_GROUPS_REQUEST = `${NAME}/FETCH_GROUPS_REQUEST`
export const FETCH_GROUPS_FAILURE = `${NAME}/FETCH_GROUPS_FAILURE`
export const FETCH_GROUPS_SUCCESS = `${NAME}/FETCH_GROUPS_SUCCESS`

export const TOUCH_CALENDAR = `${NAME}/TOUCH_CALENDAR_REQUEST`
export const TOUCH_CALENDAR_REQUEST = `${NAME}/TOUCH_CALENDAR_REQUEST`
export const TOUCH_CALENDAR_FAILURE = `${NAME}/TOUCH_CALENDAR_FAILURE`
export const TOUCH_CALENDAR_SUCCESS = `${NAME}/TOUCH_CALENDAR_SUCCESS`

export const CREATE_CALENDAR = `${NAME}/CREATE_CALENDAR`
export const CREATE_CALENDAR_REQUEST = `${NAME}/CREATE_CALENDAR_REQUEST`
export const CREATE_CALENDAR_FAILURE = `${NAME}/CREATE_CALENDAR_FAILURE`
export const CREATE_CALENDAR_SUCCESS = `${NAME}/CREATE_CALENDAR_SUCCESS`

export const UPDATE_CALENDAR = `${NAME}/UPDATE_CALENDAR`
export const UPDATE_CALENDAR_REQUEST = `${NAME}/UPDATE_CALENDAR_REQUEST`
export const UPDATE_CALENDAR_FAILURE = `${NAME}/UPDATE_CALENDAR_FAILURE`
export const UPDATE_CALENDAR_SUCCESS = `${NAME}/UPDATE_CALENDAR_SUCCESS`

export const PUSH_CALENDAR = `${NAME}/PUSH_CALENDAR`
export const PUSH_CALENDAR_REQUEST = `${NAME}/PUSH_CALENDAR_REQUEST`
export const PUSH_CALENDAR_FAILURE = `${NAME}/PUSH_CALENDAR_FAILURE`
export const PUSH_CALENDAR_SUCCESS = `${NAME}/PUSH_CALENDAR_SUCCESS`

export const DELETE_CALENDAR = `${NAME}/DELETE_CALENDAR`
export const DELETE_CALENDAR_REQUEST = `${NAME}/DELETE_CALENDAR_REQUEST`
export const DELETE_CALENDAR_FAILURE = `${NAME}/DELETE_CALENDAR_FAILURE`
export const DELETE_CALENDAR_SUCCESS = `${NAME}/DELETE_CALENDAR_SUCCESS`

export const VALIDATE_CALENDAR = `${NAME}/VALIDATE_CALENDAR`
export const VALIDATE_CALENDAR_REQUEST = `${NAME}/VALIDATE_CALENDAR_REQUEST`
export const VALIDATE_CALENDAR_FAILURE = `${NAME}/VALIDATE_CALENDAR_FAILURE`
export const VALIDATE_CALENDAR_SUCCESS = `${NAME}/VALIDATE_CALENDAR_SUCCESS`

export const DOWNLOAD_CALENDAR = `${NAME}/DOWNLOAD_CALENDAR_REQUEST`
export const DOWNLOAD_CALENDAR_REQUEST = `${NAME}/DOWNLOAD_CALENDAR_REQUEST`
export const DOWNLOAD_CALENDAR_FAILURE = `${NAME}/DOWNLOAD_CALENDAR_FAILURE`
export const DOWNLOAD_CALENDAR_SUCCESS = `${NAME}/DOWNLOAD_CALENDAR_SUCCESS`

export const PRINT_CALENDAR = `${NAME}/PRINT_CALENDAR_REQUEST`
export const PRINT_CALENDAR_REQUEST = `${NAME}/PRINT_CALENDAR_REQUEST`
export const PRINT_CALENDAR_FAILURE = `${NAME}/PRINT_CALENDAR_FAILURE`
export const PRINT_CALENDAR_SUCCESS = `${NAME}/PRINT_CALENDAR_SUCCESS`

export const CALENDAR_WEEK_NUMBERS = `${NAME}/CALENDAR_WEEK_NUMBERS_REQUEST`
export const CALENDAR_WEEK_NUMBERS_REQUEST = `${NAME}/CALENDAR_WEEK_NUMBERS_REQUEST`
export const CALENDAR_WEEK_NUMBERS_FAILURE = `${NAME}/CALENDAR_WEEK_NUMBERS_FAILURE`
export const CALENDAR_WEEK_NUMBERS_SUCCESS = `${NAME}/CALENDAR_WEEK_NUMBERS_SUCCESS`

export const CALENDAR_FIND_AND_REPLACE = `${NAME}/CALENDAR_FIND_AND_REPLACE_REQUEST`
export const CALENDAR_FIND_AND_REPLACE_REQUEST = `${NAME}/CALENDAR_FIND_AND_REPLACE_REQUEST`
export const CALENDAR_FIND_AND_REPLACE_FAILURE = `${NAME}/CALENDAR_FIND_AND_REPLACE_FAILURE`
export const CALENDAR_FIND_AND_REPLACE_SUCCESS = `${NAME}/CALENDAR_FIND_AND_REPLACE_SUCCESS`

export const CALENDAR_RESTORE = `${NAME}/CALENDAR_RESTORE_REQUEST`
export const CALENDAR_RESTORE_REQUEST = `${NAME}/CALENDAR_RESTORE_REQUEST`
export const CALENDAR_RESTORE_FAILURE = `${NAME}/CALENDAR_RESTORE_FAILURE`
export const CALENDAR_RESTORE_SUCCESS = `${NAME}/CALENDAR_RESTORE_SUCCESS`

//
// SNAPSHOTS
//

export const FETCH_SNAPSHOTS = `${NAME}/FETCH_SNAPSHOTS_REQUEST`
export const FETCH_SNAPSHOTS_REQUEST = `${NAME}/FETCH_SNAPSHOTS_REQUEST`
export const FETCH_SNAPSHOTS_FAILURE = `${NAME}/FETCH_SNAPSHOTS_FAILURE`
export const FETCH_SNAPSHOTS_SUCCESS = `${NAME}/FETCH_SNAPSHOTS_SUCCESS`

export const CREATE_SNAPSHOT = `${NAME}/CREATE_SNAPSHOT`
export const CREATE_SNAPSHOT_REQUEST = `${NAME}/CREATE_SNAPSHOT_REQUEST`
export const CREATE_SNAPSHOT_FAILURE = `${NAME}/CREATE_SNAPSHOT_FAILURE`
export const CREATE_SNAPSHOT_SUCCESS = `${NAME}/CREATE_SNAPSHOT_SUCCESS`

export const UPDATE_SNAPSHOT = `${NAME}/UPDATE_SNAPSHOT`
export const UPDATE_SNAPSHOT_REQUEST = `${NAME}/UPDATE_SNAPSHOT_REQUEST`
export const UPDATE_SNAPSHOT_FAILURE = `${NAME}/UPDATE_SNAPSHOT_FAILURE`
export const UPDATE_SNAPSHOT_SUCCESS = `${NAME}/UPDATE_SNAPSHOT_SUCCESS`

export const DELETE_SNAPSHOT = `${NAME}/DELETE_SNAPSHOT`
export const DELETE_SNAPSHOT_REQUEST = `${NAME}/DELETE_SNAPSHOT_REQUEST`
export const DELETE_SNAPSHOT_FAILURE = `${NAME}/DELETE_SNAPSHOT_FAILURE`
export const DELETE_SNAPSHOT_SUCCESS = `${NAME}/DELETE_SNAPSHOT_SUCCESS`

//
// HOLIDAYS
//

export const FETCH_HOLIDAYS = `${NAME}/FETCH_HOLIDAYS_REQUEST`
export const FETCH_HOLIDAYS_REQUEST = `${NAME}/FETCH_HOLIDAYS_REQUEST`
export const FETCH_HOLIDAYS_FAILURE = `${NAME}/FETCH_HOLIDAYS_FAILURE`
export const FETCH_HOLIDAYS_SUCCESS = `${NAME}/FETCH_HOLIDAYS_SUCCESS`

export const FETCH_HOLIDAY_SCHEDULES = `${NAME}/FETCH_HOLIDAY_SCHEDULES_REQUEST`
export const FETCH_HOLIDAY_SCHEDULES_REQUEST = `${NAME}/FETCH_HOLIDAY_SCHEDULES_REQUEST`
export const FETCH_HOLIDAY_SCHEDULES_FAILURE = `${NAME}/FETCH_HOLIDAY_SCHEDULES_FAILURE`
export const FETCH_HOLIDAY_SCHEDULES_SUCCESS = `${NAME}/FETCH_HOLIDAY_SCHEDULES_SUCCESS`

export const CREATE_HOLIDAY = `${NAME}/CREATE_HOLIDAY`
export const CREATE_HOLIDAY_REQUEST = `${NAME}/CREATE_HOLIDAY_REQUEST`
export const CREATE_HOLIDAY_FAILURE = `${NAME}/CREATE_HOLIDAY_FAILURE`
export const CREATE_HOLIDAY_SUCCESS = `${NAME}/CREATE_HOLIDAY_SUCCESS`

export const UPDATE_HOLIDAY = `${NAME}/UPDATE_HOLIDAY`
export const UPDATE_HOLIDAY_REQUEST = `${NAME}/UPDATE_HOLIDAY_REQUEST`
export const UPDATE_HOLIDAY_FAILURE = `${NAME}/UPDATE_HOLIDAY_FAILURE`
export const UPDATE_HOLIDAY_SUCCESS = `${NAME}/UPDATE_HOLIDAY_SUCCESS`

export const DELETE_HOLIDAY = `${NAME}/DELETE_HOLIDAY`
export const DELETE_HOLIDAY_REQUEST = `${NAME}/DELETE_HOLIDAY_REQUEST`
export const DELETE_HOLIDAY_FAILURE = `${NAME}/DELETE_HOLIDAY_FAILURE`
export const DELETE_HOLIDAY_SUCCESS = `${NAME}/DELETE_HOLIDAY_SUCCESS`

//
// CATEGORIES
//

export const VALIDATE_CATEGORY = `${NAME}/VALIDATE_CATEGORY`
export const VALIDATE_CATEGORY_REQUEST = `${NAME}/VALIDATE_CATEGORY_REQUEST`
export const VALIDATE_CATEGORY_FAILURE = `${NAME}/VALIDATE_CATEGORY_FAILURE`
export const VALIDATE_CATEGORY_SUCCESS = `${NAME}/VALIDATE_CATEGORY_SUCCESS`

export const FETCH_CATEGORIES = `${NAME}/FETCH_CATEGORIES_REQUEST`
export const FETCH_CATEGORIES_REQUEST = `${NAME}/FETCH_CATEGORIES_REQUEST`
export const FETCH_CATEGORIES_FAILURE = `${NAME}/FETCH_CATEGORIES_FAILURE`
export const FETCH_CATEGORIES_SUCCESS = `${NAME}/FETCH_CATEGORIES_SUCCESS`

export const CREATE_CATEGORY = `${NAME}/CREATE_CATEGORY`
export const CREATE_CATEGORY_REQUEST = `${NAME}/CREATE_CATEGORY_REQUEST`
export const CREATE_CATEGORY_FAILURE = `${NAME}/CREATE_CATEGORY_FAILURE`
export const CREATE_CATEGORY_SUCCESS = `${NAME}/CREATE_CATEGORY_SUCCESS`

export const UPDATE_CATEGORY = `${NAME}/UPDATE_CATEGORY`
export const UPDATE_CATEGORY_REQUEST = `${NAME}/UPDATE_CATEGORY_REQUEST`
export const UPDATE_CATEGORY_FAILURE = `${NAME}/UPDATE_CATEGORY_FAILURE`
export const UPDATE_CATEGORY_SUCCESS = `${NAME}/UPDATE_CATEGORY_SUCCESS`

export const UPDATE_CATEGORY_ACTIVE = `${NAME}/UPDATE_CATEGORY_ACTIVE`
export const UPDATE_CATEGORY_ACTIVE_REQUEST = `${NAME}/UPDATE_CATEGORY_ACTIVE_REQUEST`
export const UPDATE_CATEGORY_ACTIVE_FAILURE = `${NAME}/UPDATE_CATEGORY_ACTIVE_FAILURE`
export const UPDATE_CATEGORY_ACTIVE_SUCCESS = `${NAME}/UPDATE_CATEGORY_ACTIVE_SUCCESS`

export const UPDATE_CATEGORY_LOCK = `${NAME}/UPDATE_CATEGORY_LOCK`
export const UPDATE_CATEGORY_LOCK_REQUEST = `${NAME}/UPDATE_CATEGORY_LOCK_REQUEST`
export const UPDATE_CATEGORY_LOCK_FAILURE = `${NAME}/UPDATE_CATEGORY_LOCK_FAILURE`
export const UPDATE_CATEGORY_LOCK_SUCCESS = `${NAME}/UPDATE_CATEGORY_LOCK_SUCCESS`

export const UPDATE_CATEGORY_PARENT = `${NAME}/UPDATE_CATEGORY_PARENT`
export const UPDATE_CATEGORY_PARENT_REQUEST = `${NAME}/UPDATE_CATEGORY_PARENT_REQUEST`
export const UPDATE_CATEGORY_PARENT_FAILURE = `${NAME}/UPDATE_CATEGORY_PARENT_FAILURE`
export const UPDATE_CATEGORY_PARENT_SUCCESS = `${NAME}/UPDATE_CATEGORY_PARENT_SUCCESS`

export const OPEN_CATEGORY = `${NAME}/OPEN_CATEGORY`
export const OPEN_CATEGORY_REQUEST = `${NAME}/OPEN_CATEGORY_REQUEST`
export const OPEN_CATEGORY_FAILURE = `${NAME}/OPEN_CATEGORY_FAILURE`
export const OPEN_CATEGORY_SUCCESS = `${NAME}/OPEN_CATEGORY_SUCCESS`

export const PATCH_SHIFT_CATEGORY = `${NAME}/PATCH_SHIFT_CATEGORY`
export const PATCH_SHIFT_CATEGORY_REQUEST = `${NAME}/PATCH_SHIFT_CATEGORY_REQUEST`
export const PATCH_SHIFT_CATEGORY_FAILURE = `${NAME}/PATCH_SHIFT_CATEGORY_FAILURE`
export const PATCH_SHIFT_CATEGORY_SUCCESS = `${NAME}/PATCH_SHIFT_CATEGORY_SUCCESS`

export const DELETE_CATEGORY = `${NAME}/DELETE_CATEGORY`
export const DELETE_CATEGORY_REQUEST = `${NAME}/DELETE_CATEGORY_REQUEST`
export const DELETE_CATEGORY_FAILURE = `${NAME}/DELETE_CATEGORY_FAILURE`
export const DELETE_CATEGORY_SUCCESS = `${NAME}/DELETE_CATEGORY_SUCCESS`

//
// STYLES
//

export const FETCH_STYLES = `${NAME}/FETCH_STYLES_REQUEST`
export const FETCH_STYLES_REQUEST = `${NAME}/FETCH_STYLES_REQUEST`
export const FETCH_STYLES_FAILURE = `${NAME}/FETCH_STYLES_FAILURE`
export const FETCH_STYLES_SUCCESS = `${NAME}/FETCH_STYLES_SUCCESS`

export const CREATE_STYLE = `${NAME}/CREATE_STYLE`
export const CREATE_STYLE_REQUEST = `${NAME}/CREATE_STYLE_REQUEST`
export const CREATE_STYLE_FAILURE = `${NAME}/CREATE_STYLE_FAILURE`
export const CREATE_STYLE_SUCCESS = `${NAME}/CREATE_STYLE_SUCCESS`

export const UPDATE_STYLE = `${NAME}/UPDATE_STYLE`
export const UPDATE_STYLE_REQUEST = `${NAME}/UPDATE_STYLE_REQUEST`
export const UPDATE_STYLE_FAILURE = `${NAME}/UPDATE_STYLE_FAILURE`
export const UPDATE_STYLE_SUCCESS = `${NAME}/UPDATE_STYLE_SUCCESS`

export const DELETE_STYLE = `${NAME}/DELETE_STYLE`
export const DELETE_STYLE_REQUEST = `${NAME}/DELETE_STYLE_REQUEST`
export const DELETE_STYLE_FAILURE = `${NAME}/DELETE_STYLE_FAILURE`
export const DELETE_STYLE_SUCCESS = `${NAME}/DELETE_STYLE_SUCCESS`

//
// ICONS
//

export const FETCH_ICONS = `${NAME}/FETCH_ICONS_REQUEST`
export const FETCH_ICONS_REQUEST = `${NAME}/FETCH_ICONS_REQUEST`
export const FETCH_ICONS_FAILURE = `${NAME}/FETCH_ICONS_FAILURE`
export const FETCH_ICONS_SUCCESS = `${NAME}/FETCH_ICONS_SUCCESS`

export const CREATE_ICON = `${NAME}/CREATE_ICON`
export const CREATE_ICON_REQUEST = `${NAME}/CREATE_ICON_REQUEST`
export const CREATE_ICON_FAILURE = `${NAME}/CREATE_ICON_FAILURE`
export const CREATE_ICON_SUCCESS = `${NAME}/CREATE_ICON_SUCCESS`

export const DELETE_ICON = `${NAME}/DELETE_ICON`
export const DELETE_ICON_REQUEST = `${NAME}/DELETE_ICON_REQUEST`
export const DELETE_ICON_FAILURE = `${NAME}/DELETE_ICON_FAILURE`
export const DELETE_ICON_SUCCESS = `${NAME}/DELETE_ICON_SUCCESS`

//
// ITEMS
//

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS_REQUEST`
export const FETCH_ITEMS_REQUEST = `${NAME}/FETCH_ITEMS_REQUEST`
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`

export const CREATE_ITEM = `${NAME}/CREATE_ITEM`
export const CREATE_ITEM_REQUEST = `${NAME}/CREATE_ITEM_REQUEST`
export const CREATE_ITEM_FAILURE = `${NAME}/CREATE_ITEM_FAILURE`
export const CREATE_ITEM_SUCCESS = `${NAME}/CREATE_ITEM_SUCCESS`

export const UPDATE_ITEM = `${NAME}/UPDATE_ITEM`
export const UPDATE_ITEM_REQUEST = `${NAME}/UPDATE_ITEM_REQUEST`
export const UPDATE_ITEM_FAILURE = `${NAME}/UPDATE_ITEM_FAILURE`
export const UPDATE_ITEM_SUCCESS = `${NAME}/UPDATE_ITEM_SUCCESS`

export const UPDATE_ITEM_ACTIVE = `${NAME}/UPDATE_ITEM_ACTIVE`
export const UPDATE_ITEM_ACTIVE_REQUEST = `${NAME}/UPDATE_ITEM_ACTIVE_REQUEST`
export const UPDATE_ITEM_ACTIVE_FAILURE = `${NAME}/UPDATE_ITEM_ACTIVE_FAILURE`
export const UPDATE_ITEM_ACTIVE_SUCCESS = `${NAME}/UPDATE_ITEM_ACTIVE_SUCCESS`

export const UPDATE_ITEM_PARENT = `${NAME}/UPDATE_ITEM_PARENT`
export const UPDATE_ITEM_PARENT_REQUEST = `${NAME}/UPDATE_ITEM_PARENT_REQUEST`
export const UPDATE_ITEM_PARENT_FAILURE = `${NAME}/UPDATE_ITEM_PARENT_FAILURE`
export const UPDATE_ITEM_PARENT_SUCCESS = `${NAME}/UPDATE_ITEM_PARENT_SUCCESS`

export const UPDATE_ITEM_LOCK = `${NAME}/UPDATE_ITEM_LOCK`
export const UPDATE_ITEM_LOCK_REQUEST = `${NAME}/UPDATE_ITEM_LOCK_REQUEST`
export const UPDATE_ITEM_LOCK_FAILURE = `${NAME}/UPDATE_ITEM_LOCK_FAILURE`
export const UPDATE_ITEM_LOCK_SUCCESS = `${NAME}/UPDATE_ITEM_LOCK_SUCCESS`

export const PATCH_SHIFT_ITEM = `${NAME}/PATCH_SHIFT_ITEM`
export const PATCH_SHIFT_ITEM_REQUEST = `${NAME}/PATCH_SHIFT_ITEM_REQUEST`
export const PATCH_SHIFT_ITEM_FAILURE = `${NAME}/PATCH_SHIFT_ITEM_FAILURE`
export const PATCH_SHIFT_ITEM_SUCCESS = `${NAME}/PATCH_SHIFT_ITEM_SUCCESS`

export const PATCH_SKIP_ITEM = `${NAME}/PATCH_SKIP_ITEM`
export const PATCH_SKIP_ITEM_REQUEST = `${NAME}/PATCH_SKIP_ITEM_REQUEST`
export const PATCH_SKIP_ITEM_FAILURE = `${NAME}/PATCH_SKIP_ITEM_FAILURE`
export const PATCH_SKIP_ITEM_SUCCESS = `${NAME}/PATCH_SKIP_ITEM_SUCCESS`

export const PATCH_RENAME_ITEM = `${NAME}/PATCH_RENAME_ITEM`
export const PATCH_RENAME_ITEM_REQUEST = `${NAME}/PATCH_RENAME_ITEM_REQUEST`
export const PATCH_RENAME_ITEM_FAILURE = `${NAME}/PATCH_RENAME_ITEM_FAILURE`
export const PATCH_RENAME_ITEM_SUCCESS = `${NAME}/PATCH_RENAME_ITEM_SUCCESS`

export const PATCH_UNGROUP_ITEM = `${NAME}/PATCH_UNGROUP_ITEM`
export const PATCH_UNGROUP_ITEM_REQUEST = `${NAME}/PATCH_UNGROUP_ITEM_REQUEST`
export const PATCH_UNGROUP_ITEM_FAILURE = `${NAME}/PATCH_UNGROUP_ITEM_FAILURE`
export const PATCH_UNGROUP_ITEM_SUCCESS = `${NAME}/PATCH_UNGROUP_ITEM_SUCCESS`

export const PATCH_LENGTHEN_ITEM = `${NAME}/PATCH_LENGTHEN_ITEM`
export const PATCH_LENGTHEN_ITEM_REQUEST = `${NAME}/PATCH_LENGTHEN_ITEM_REQUEST`
export const PATCH_LENGTHEN_ITEM_FAILURE = `${NAME}/PATCH_LENGTHEN_ITEM_FAILURE`
export const PATCH_LENGTHEN_ITEM_SUCCESS = `${NAME}/PATCH_LENGTHEN_ITEM_SUCCESS`

export const PATCH_CLEAR_ITEM = `${NAME}/PATCH_CLEAR_ITEM`
export const PATCH_CLEAR_ITEM_REQUEST = `${NAME}/PATCH_CLEAR_ITEM_REQUEST`
export const PATCH_CLEAR_ITEM_FAILURE = `${NAME}/PATCH_CLEAR_ITEM_FAILURE`
export const PATCH_CLEAR_ITEM_SUCCESS = `${NAME}/PATCH_CLEAR_ITEM_SUCCESS`

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`
export const DELETE_ITEM_REQUEST = `${NAME}/DELETE_ITEM_REQUEST`
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`

//
// EVENTS
//

export const FETCH_EVENTS = `${NAME}/FETCH_EVENTS_REQUEST`
export const FETCH_EVENTS_REQUEST = `${NAME}/FETCH_EVENTS_REQUEST`
export const FETCH_EVENTS_FAILURE = `${NAME}/FETCH_EVENTS_FAILURE`
export const FETCH_EVENTS_SUCCESS = `${NAME}/FETCH_EVENTS_SUCCESS`

export const CREATE_EVENT = `${NAME}/CREATE_EVENT`
export const CREATE_EVENT_REQUEST = `${NAME}/CREATE_EVENT_REQUEST`
export const CREATE_EVENT_FAILURE = `${NAME}/CREATE_EVENT_FAILURE`
export const CREATE_EVENT_SUCCESS = `${NAME}/CREATE_EVENT_SUCCESS`

export const UPDATE_EVENT = `${NAME}/UPDATE_EVENT`
export const UPDATE_EVENT_REQUEST = `${NAME}/UPDATE_EVENT_REQUEST`
export const UPDATE_EVENT_FAILURE = `${NAME}/UPDATE_EVENT_FAILURE`
export const UPDATE_EVENT_SUCCESS = `${NAME}/UPDATE_EVENT_SUCCESS`

export const PATCH_SHIFT_EVENT = `${NAME}/PATCH_SHIFT_EVENT`
export const PATCH_SHIFT_EVENT_REQUEST = `${NAME}/PATCH_SHIFT_EVENT_REQUEST`
export const PATCH_SHIFT_EVENT_FAILURE = `${NAME}/PATCH_SHIFT_EVENT_FAILURE`
export const PATCH_SHIFT_EVENT_SUCCESS = `${NAME}/PATCH_SHIFT_EVENT_SUCCESS`

export const OFFSET_EVENT = `${NAME}/OFFSET_EVENT`
export const OFFSET_EVENT_REQUEST = `${NAME}/OFFSET_EVENT_REQUEST`
export const OFFSET_EVENT_FAILURE = `${NAME}/OFFSET_EVENT_FAILURE`
export const OFFSET_EVENT_SUCCESS = `${NAME}/OFFSET_EVENT_SUCCESS`

export const DELETE_EVENT = `${NAME}/DELETE_EVENT`
export const DELETE_EVENT_REQUEST = `${NAME}/DELETE_EVENT_REQUEST`
export const DELETE_EVENT_FAILURE = `${NAME}/DELETE_EVENT_FAILURE`
export const DELETE_EVENT_SUCCESS = `${NAME}/DELETE_EVENT_SUCCESS`

//
// INVITATIONS
//

export const FETCH_INVITATIONS = `${NAME}/FETCH_INVITATIONS_REQUEST`
export const FETCH_INVITATIONS_REQUEST = `${NAME}/FETCH_INVITATIONS_REQUEST`
export const FETCH_INVITATIONS_FAILURE = `${NAME}/FETCH_INVITATIONS_FAILURE`
export const FETCH_INVITATIONS_SUCCESS = `${NAME}/FETCH_INVITATIONS_SUCCESS`

export const FETCH_INVITATION_PERMISSIONS = `${NAME}/FETCH_INVITATION_PERMISSIONS_REQUEST`
export const FETCH_INVITATION_PERMISSIONS_REQUEST = `${NAME}/FETCH_INVITATION_PERMISSIONS_REQUEST`
export const FETCH_INVITATION_PERMISSIONS_FAILURE = `${NAME}/FETCH_INVITATION_PERMISSIONS_FAILURE`
export const FETCH_INVITATION_PERMISSIONS_SUCCESS = `${NAME}/FETCH_INVITATION_PERMISSIONS_SUCCESS`

export const CREATE_INVITATION = `${NAME}/CREATE_INVITATION`
export const CREATE_INVITATION_REQUEST = `${NAME}/CREATE_INVITATION_REQUEST`
export const CREATE_INVITATION_FAILURE = `${NAME}/CREATE_INVITATION_FAILURE`
export const CREATE_INVITATION_SUCCESS = `${NAME}/CREATE_INVITATION_SUCCESS`

export const CREATE_INVITATION_PERMISSION = `${NAME}/CREATE_INVITATION_PERMISSION_REQUEST`
export const CREATE_INVITATION_PERMISSION_REQUEST = `${NAME}/CREATE_INVITATION_PERMISSION_REQUEST`
export const CREATE_INVITATION_PERMISSION_FAILURE = `${NAME}/CREATE_INVITATION_PERMISSION_FAILURE`
export const CREATE_INVITATION_PERMISSION_SUCCESS = `${NAME}/CREATE_INVITATION_PERMISSION_SUCCESS`

export const RESEND_INVITATION = `${NAME}/RESEND_INVITATION`
export const RESEND_INVITATION_REQUEST = `${NAME}/RESEND_INVITATION_REQUEST`
export const RESEND_INVITATION_FAILURE = `${NAME}/RESEND_INVITATION_FAILURE`
export const RESEND_INVITATION_SUCCESS = `${NAME}/RESEND_INVITATION_SUCCESS`

export const UPDATE_INVITATION = `${NAME}/UPDATE_INVITATION`
export const UPDATE_INVITATION_REQUEST = `${NAME}/UPDATE_INVITATION_REQUEST`
export const UPDATE_INVITATION_FAILURE = `${NAME}/UPDATE_INVITATION_FAILURE`
export const UPDATE_INVITATION_SUCCESS = `${NAME}/UPDATE_INVITATION_SUCCESS`

export const UPDATE_INVITATION_PERMISSION = `${NAME}/UPDATE_INVITATION_PERMISSION_REQUEST`
export const UPDATE_INVITATION_PERMISSION_REQUEST = `${NAME}/UPDATE_INVITATION_PERMISSION_REQUEST`
export const UPDATE_INVITATION_PERMISSION_FAILURE = `${NAME}/UPDATE_INVITATION_PERMISSION_FAILURE`
export const UPDATE_INVITATION_PERMISSION_SUCCESS = `${NAME}/UPDATE_INVITATION_PERMISSION_SUCCESS`

export const DELETE_INVITATION = `${NAME}/DELETE_INVITATION`
export const DELETE_INVITATION_REQUEST = `${NAME}/DELETE_INVITATION_REQUEST`
export const DELETE_INVITATION_FAILURE = `${NAME}/DELETE_INVITATION_FAILURE`
export const DELETE_INVITATION_SUCCESS = `${NAME}/DELETE_INVITATION_SUCCESS`

export const VALIDATE_INVITATION = `${NAME}/VALIDATE_INVITATION`
export const VALIDATE_INVITATION_REQUEST = `${NAME}/VALIDATE_INVITATION_REQUEST`
export const VALIDATE_INVITATION_FAILURE = `${NAME}/VALIDATE_INVITATION_FAILURE`
export const VALIDATE_INVITATION_SUCCESS = `${NAME}/VALIDATE_INVITATION_SUCCESS`

export const LOADED_PENDING_INVITATION = `${NAME}/LOADED_PENDING_INVITATION`
export const CLEARED_PENDING_INVITATION = `${NAME}/CLEARED_PENDING_INVITATION`

export const FETCH_INVITATION_STATUS = `${NAME}/FETCH_INVITATION_STATUS`
export const FETCH_INVITATION_STATUS_REQUEST = `${NAME}/FETCH_INVITATION_STATUS_REQUEST`
export const FETCH_INVITATION_STATUS_FAILURE = `${NAME}/FETCH_INVITATION_STATUS_FAILURE`
export const FETCH_INVITATION_STATUS_SUCCESS = `${NAME}/FETCH_INVITATION_STATUS_SUCCESS`

export const ACCEPT_INVITATION = `${NAME}/ACCEPT_INVITATION`
export const ACCEPT_INVITATION_REQUEST = `${NAME}/ACCEPT_INVITATION_REQUEST`
export const ACCEPT_INVITATION_FAILURE = `${NAME}/ACCEPT_INVITATION_FAILURE`
export const ACCEPT_INVITATION_SUCCESS = `${NAME}/ACCEPT_INVITATION_SUCCESS`
