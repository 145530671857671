import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { GroupSelect } from "ui"
import { useCreateGroup, useGroups } from "../../api"
import { GroupDataStructure, useAddGroups } from "../../api/atoms"

interface Props {
  name: string
  subdomain: string
  initialValue?: string
  onSelect?: (uuid: string | null) => any
}

export const ConnectedGroupSelect: React.FC<Props> = ({
  name,
  subdomain,
  initialValue,
  onSelect: externalSelectionHandler,
}) => {
  const [selected, setSelected] = useState<string | undefined | null>(
    initialValue
  )
  const [externalValue, setExternalValue] = useState<string | null>(null)

  useEffect(() => {
    if (initialValue !== externalValue) {
      setExternalValue(initialValue ?? null)
      setSelected(initialValue)
    }
  }, [externalValue, setExternalValue, initialValue, setSelected])

  const { groups } = useGroups(subdomain)
  const options = groups
    ? groups.map((g) => ({ name: g.name, uuid: g.uuid }))
    : []

  const [createGroup] = useCreateGroup(subdomain)
  const addGroups = useAddGroups()

  const handleSelect = (selection: string | null) => {
    externalSelectionHandler?.(selection)
    setSelected(selection)
  }

  const handleCreateGroup = async (name: string) => {
    const group = await createGroup({ body: { name } })
    if (group) {
      toast.success("Group created")
      if (group) {
        addGroups([group as GroupDataStructure])
      }
      handleSelect(group.uuid)
    }
    return
  }

  return (
    <GroupSelect
      name={name}
      options={options}
      onCreate={handleCreateGroup}
      onSelect={handleSelect}
      value={selected ?? undefined}
      creatable
      clearable
    />
  )
}
