import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import ResetPasswordFormView from "../components/ResetPasswordFormView.react"
import { updateForgottenPassword, loadAuthToken } from "../../api"
import { FORM_NAME } from "../constants"
import { submitValidate, validate } from "../validators"

const ReduxResetPasswordFormView = reduxForm({
  form: FORM_NAME,
  validate,
})(ResetPasswordFormView)

export default connect(() => ({}), {
  submitForm: updateForgottenPassword,
  autoLogin: loadAuthToken,
  submitValidate,
})(ReduxResetPasswordFormView)
