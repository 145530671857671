import { updateDatesForEventsWithIDs } from "./updateDatesForEventsWithIDs"
import { eventSelector } from "../../api"
import { dragEventDateOffset } from "../selectors"
import { makeDate } from "./offsetDateForEvent"
import { updateEvent } from "./updateEvent"
import { updateEvents } from "./updateEvents"
import { Selectable, getSelectedItems } from "../../selectable"

/**
 * Updates the position (think vertical heirarchy) of events in a given day part.
 * @param  {Object}   state       The current state of the redux store.
 * @param  {Object}   event       The event to use as the anchor.
 * @param  {Integer}  position    The new position to apply to the anchor event.
 * @param  {Boolean}  multiselect If true this indicates that multiselect mode is currently in use.
 * @return {Object}               An object containing the updated events in a normalized object.
 */
const updatePositionsOfEventsRelatedToEvent = (
  state,
  event,
  position,
  multiselect
) => {
  if (multiselect || event.position === position) {
    return {}
  }
  return eventSelector
    .positionedBetween(state)(event.calendarId, event.position, position)
    .reduce((relatedEvents, currentEvent) => {
      const newPosition =
        currentEvent.position + (event.position > position ? 1 : -1)
      relatedEvents[currentEvent.hashId] = Object.assign({}, currentEvent, {
        position: newPosition,
      })
      return relatedEvents
    }, {})
}

/**
 * Determines if the event has been altered in a way prompting a renderable change
 * required in the redux store.
 * @param  {Object} event       An object representing an event entity in the redux store.
 * @param  {Integer} position   A possible new position to be applied to the event.
 * @param  {Integer} partition  A possible new partition to be applied to the event.
 * @param  {Integer} offset     A possible offset to be applied to the event.
 * @return {Boolean}            True if the event should be updated in the redux store.
 */
const renderableChange = (event, position, partition, offset) => {
  // ignore position & partition changes for multiselect
  const samePosition = event.position === position
  const samePartition = event.partition === partition
  const sameDate = offset === 0
  return !sameDate || !samePartition || !samePosition
}

/**
 * Updates a specific event along side all other events that may be affected by
 * the pending state change to give the user an accurate preview of the result of
 * their drag and drop actionb prior to completing the request against the API.
 * This method is effectively generating a preview of the resulting database changes
 * that will occur if the same update was ran against the API.
 * @param  {Integer} eventUuid  The UUID of the primary event being manipulated.
 * @param  {Integer} calendarId The UUID of the calendar for the event being manipulated.
 * @param  {string} newDate     A date string or momentJS object representing the new start date for the event.
 * @param  {Integer} position   The new position for the target event.
 * @param  {Integer} partition  An updated partition for the target event.
 * @param  {Boolean} debounced  If set to true the redux action creator will be debounced for performance reasons.
 * @return {Promise}            A redux thunk representing the dispatched action.
 */
export const shiftEventPreview =
  (eventUuid, calendarId, newDate, position, partition, debounced) =>
  (dispatch, getState) => {
    const state = getState()
    const dragOffset = dragEventDateOffset(state)
    const event = eventSelector.find(state)(eventUuid, calendarId)
    const selectedEvents = getSelectedItems(getState())[Selectable.EVENT]
    const multiselect = selectedEvents.length > 1
    const transformedDate = makeDate(newDate)
      .startOf("day")
      .subtract(dragOffset, "days")
    const offset = -makeDate(event.startsAt)
      .startOf("day")
      .diff(transformedDate, "days")

    const updatedPosition = multiselect ? event.position : position
    const updatedPartition = multiselect ? event.partition : partition
    if (renderableChange(event, updatedPosition, updatedPartition, offset)) {
      const updatedEvent = updateEvent(
        event,
        transformedDate,
        true,
        updatedPosition,
        updatedPartition,
        state
      )
      const eventsWithNewDates = updateDatesForEventsWithIDs(
        state,
        selectedEvents,
        calendarId,
        offset
      )
      const eventsWithNewPositions = updatePositionsOfEventsRelatedToEvent(
        state,
        event,
        updatedPosition,
        multiselect
      )
      const key = multiselect
        ? `offsetEvents[${selectedEvents.join("_")}]`
        : `shiftEvent${eventUuid}`
      const action = updateEvents(
        Object.assign(
          {},
          eventsWithNewPositions,
          eventsWithNewDates,
          updatedEvent
        ),
        key,
        debounced
      )
      return Promise.resolve().then(dispatch(action))
    }
    return Promise.resolve()
  }
