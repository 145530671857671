import React, { useState, useEffect } from "react"
import { DropDownList, BarItem, Button } from "ui"
import { useHistory, useParams } from "react-router-dom"
import { ActiveOrgsPanel } from "./ActiveOrgsPanel"
import { ActiveUsersPanel } from "./ActiveUsersPanel"
import { ActiveCalendarsPanel } from "./ActiveCalendarsPanel"
import { InactiveOrgsPanel } from "./InactiveOrgsPanel"
import { InvitationsPanel } from "./InvitationsPanel"
import { ActiveUsersPerDayPanel } from "./ActiveUsersPerDayPanel"
import { Split } from "./Split"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { useSelector } from "react-redux"
import { analyticsSelector } from "../api"

export interface SuperAdminDashboardProps {}

type ViewMode = "mode2yr" | "mode30d" | "google" | "legacy"

export const SuperAdminDashboard: React.FC<SuperAdminDashboardProps> = () => {
  const [renderViewOptions, setRenderViewOptions] = useState(false)
  const history = useHistory()
  const params = useParams<{ days: string }>()
  const period = parseInt(params.days ?? "7")
  useEffect(() => {
    if (params.days) {
      return
    }
    showPeriod()
  })
  const showPeriod = (days?: string) =>
    history.push(`/admin/dashboard/${days ?? 7}`)
  const canViewAnalytics = useSelector(analyticsSelector.canViewAnalytics)

  useEffect(() => {
    if (!canViewAnalytics) {
      history.push("/")
    }
  }, [canViewAnalytics, history])

  const handleView = (s: ViewMode) => (e: React.MouseEvent) => {
    e.preventDefault()
    switch (s) {
      case "mode2yr":
        window.open(
          "https://app.mode.com/sumocreations/reports/18ed041892bb",
          "_blank"
        )
        break
      case "mode30d":
        window.open(
          "https://app.mode.com/sumocreations/reports/c8e1bb8f8e74",
          "_blank"
        )
        break
      case "google":
        window.open(
          "https://analytics.google.com/analytics/web/?authuser=3#/p292025895/reports/explorer?params=_u..nav%3Dmaui%26_r.explorerCard..selmet%3D%5B%22eventCount%22%5D%26_r.explorerCard..seldim%3D%5B%22eventName%22%5D&r=top-events&ruid=top-events,life-cycle,engagement&collectionId=life-cycle",
          "_blank"
        )
        break
      default:
        return
    }
  }

  return (
    <div className="flex flex-col bg-gray-100 min-h-screen px-6 py-2 pb-8">
      <div className="mb-4 pt-4 pb-2 flex flex-col sticky top-0 bg-gray-100 bg-opacity-75 z-10">
        <h1 className="mb-4 text-xl font-semibold">Analytics Dashboards</h1>
        <p className="text-md">
          To stay current on the most up to date application performance
          resources please use one of the options from ModeAnalytics or Google
          Analytics. These dashboards will be maintained and updated regularaly.
          If you cannot access any of the dashboard below send a request to the{" "}
          <a
            href="mailto:jjeffers@revolutiones.com"
            className="underline font-semibold text-sorbus-default"
          >
            ProCal Admin
          </a>
          .
        </p>
      </div>
      <nav className="grid grid-cols-4 gap-4 flex-grow-0 border-b-2 border-gray-400 pb-4 mb-4">
        <Button onClick={handleView("mode2yr")}>Mode: 2-Year Report</Button>
        <Button onClick={handleView("mode30d")}>Mode: 30-Day Report</Button>
        <Button onClick={handleView("google")}>Google Analytics</Button>
      </nav>
      <div className="mx-2 text-xl font-semibold border-b border-gray-400 mb-4 pt-4 pb-2 flex sticky top-0 bg-gray-100 bg-opacity-75 z-10">
        <h1 className="my-auto">ProCal Legacy Analytics</h1>
        <div className="relative flex  ml-auto">
          <span className="font-light my-auto mr-3">Past {period} Days</span>
          <BarItem
            icon={renderViewOptions ? faChevronUp : faChevronDown}
            toolTip="Change Period"
            indicated={true}
            onClick={() => setRenderViewOptions(!renderViewOptions)}
            mode={renderViewOptions ? "primary" : "negative"}
            indicatorMode={renderViewOptions ? "default" : "primary"}
          />
          {renderViewOptions ? (
            <DropDownList
              items={[
                {
                  label: "7 days",
                  id: "7_DAYS",
                  value: "7",
                },
                {
                  label: "30 days",
                  id: "30_DAYS",
                  value: "30",
                },
              ]}
              onSelectItem={(item) => {
                showPeriod(item.value)
                setRenderViewOptions(false)
              }}
            />
          ) : null}
        </div>
      </div>
      <ActiveUsersPerDayPanel days={period} className="w-full" />
      <Split>
        <ActiveOrgsPanel className="w-full lg:w-1/2 md:mb-auto" days={period} />
        <InactiveOrgsPanel className="w-full lg:w-1/2 md:mb-auto" />
      </Split>
      <Split>
        <ActiveUsersPanel
          className="w-full lg:w-1/2 md:mb-auto"
          days={period}
        />
        <ActiveCalendarsPanel
          className="w-full lg:w-1/2 md:mb-auto"
          days={period}
        />
      </Split>
      <Split>
        <InvitationsPanel
          className="w-full lg:w-1/2 md:mb-auto"
          days={period}
          status="Pending"
        />
        <InvitationsPanel
          className="w-full lg:w-1/2 md:mb-auto"
          days={period}
          status="Accepted"
        />
      </Split>
    </div>
  )
}
