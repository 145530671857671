import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarHeading.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ToolBarHeading = ({ primaryText, secondaryText, onClick, icon }) => (
  <div
    className={[styles.barHeading, onClick ? styles.actionable : ""].join(" ")}
    onClick={onClick}
  >
    <h1 className={styles.barHeadingPrimary}>{primaryText}</h1>
    <h2 className={styles.barHeadingSecondary}>
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
      {secondaryText}
    </h2>
  </div>
)

ToolBarHeading.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
}

export default ToolBarHeading
