var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { concatStyles } from "utils";
import { useIsSelectedControl } from "./useIsSelectedControl";
import { useControlGroup } from "./useControlGroup";
var SELECTED_TEXT_COLOR = "text-blue-500";
var SELECTED_BG_COLOR = "bg-blue-100";
var UNSELECTED_TEXT_COLOR = "text-gray-600";
var CONTAINER_STYLES = "flex flex-row p-4 mb-1 border rounded relative";
var RADIO_INPUT_STYLES = "absolute top-0 bottom-0 left-0 right-0 z-20 opacity-0 w-full h-full rounded-lg shadow-sm cursor-pointer";
var ICON_COLUMN_STYLES = "w-2/12 flex pr-4 justify-center items-center";
var CONTENT_COLUMN_STYLES = "w-10/12";
var CONTENT_STYLES = "font-medium";
var TITLE_STYLES = "capitalize font-bold";
export var RadioCardControl = function (_a) {
    var icon = _a.icon, children = _a.children, title = _a.title, value = _a.value, disabled = _a.disabled, id = _a.id;
    var _b = useControlGroup(), register = _b.register, name = _b.name;
    var isSelected = useIsSelectedControl(value);
    var TEXT_COLOR = isSelected
        ? SELECTED_TEXT_COLOR
        : UNSELECTED_TEXT_COLOR;
    return (_jsxs("div", __assign({ className: concatStyles(CONTAINER_STYLES, isSelected && SELECTED_BG_COLOR) }, { children: [_jsx("div", __assign({ className: concatStyles(ICON_COLUMN_STYLES, TEXT_COLOR) }, { children: icon }), void 0), _jsxs("div", __assign({ className: CONTENT_COLUMN_STYLES }, { children: [_jsx("h3", __assign({ className: concatStyles(TITLE_STYLES, TEXT_COLOR) }, { children: title }), void 0), _jsx("p", __assign({ className: concatStyles(CONTENT_STYLES, TEXT_COLOR) }, { children: children }), void 0)] }), void 0), _jsx("input", __assign({}, register === null || register === void 0 ? void 0 : register(name), { id: id, value: value, type: "radio", disabled: disabled, className: RADIO_INPUT_STYLES }), void 0)] }), void 0));
};
