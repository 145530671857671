import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
var INDICATOR_STYLES = "absolute border-2 cursor-pointer pointer-events-auto border-opacity-0 border-lochivar-default inset-y-0 w-10 rounded-full z-20 cursor-pointer hover:bg-lochivar-default hover:bg-opacity-25 hover:border-opacity-100";
export var EventDraggableEdge = function (_a) {
    var type = _a.type, uuid = _a.uuid, calendarId = _a.calendarId, className = _a.className, endDrag = _a.endDrag, beginDrag = _a.beginDrag;
    var _b = useDrag(function () { return ({
        type: type,
        item: function () {
            beginDrag(uuid, calendarId);
            return { uuid: uuid, calendarId: calendarId, type: type };
        },
        end: function () {
            endDrag(uuid, calendarId);
        },
    }); }, [uuid, calendarId, type]), _ = _b[0], drag = _b[1], preview = _b[2];
    useEffect(function () {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);
    return _jsx("div", { ref: drag, className: clsx(INDICATOR_STYLES, className) }, void 0);
};
