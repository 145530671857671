import { PROTECTED } from "redux-jwt-protected-middleware"
import queryString from "query-string"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"

//
// GET / Find all OR by :subdomain
//

export const getOrganization = (subdomain) => ({
  type: types.FETCH_ORGANIZATIONS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ORGANIZATION_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain || ""}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_ORGANIZATIONS_REQUEST,
      types.FETCH_ORGANIZATIONS_SUCCESS,
      types.FETCH_ORGANIZATIONS_FAILURE,
    ],
  },
})

export const requestOrganization = (subdomain) => (dispatch) => {
  return dispatch(getOrganization(subdomain))
}

//
// POST / new organization
//

export const postOrganization = (organization) => ({
  type: types.CREATE_ORGANIZATION,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    endpoint: "/api/organizations/",
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(organization),
    types: [
      types.CREATE_ORGANIZATION_REQUEST,
      types.CREATE_ORGANIZATION_SUCCESS,
      types.CREATE_ORGANIZATION_FAILURE,
    ],
  },
})

export const createOrganization = (organization) => (dispatch) => {
  return dispatch(postOrganization(organization))
}

//
// PUT / update organization
//

export const putOrganization = (organization) => ({
  type: types.UPDATE_ORGANIZATION,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    endpoint: `/api/organizations/${organization.id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(organization),
    types: [
      types.UPDATE_ORGANIZATION_REQUEST,
      types.UPDATE_ORGANIZATION_SUCCESS,
      types.UPDATE_ORGANIZATION_FAILURE,
    ],
  },
})

export const updateOrganization = (organization) => (dispatch) => {
  return dispatch(putOrganization(organization))
}

//
// GET / validation errors
//

export const getOrganizationValidation = (values) => ({
  type: types.VALIDATE_ORGANIZATION,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/validations?${queryString.stringify(values)}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.VALIDATE_ORGANIZATION_REQUEST,
      types.VALIDATE_ORGANIZATION_SUCCESS,
      types.VALIDATE_ORGANIZATION_FAILURE,
    ],
  },
})

export const requestOrganizationValidation = (values) => (dispatch) => {
  return dispatch(getOrganizationValidation(values))
}

//
// DELETE / Delete existing Calendar via ID.
//

export const deleteOrganization = (subdomain) => ({
  type: types.DELETE_ORGANIZATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ORGANIZATION,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_ORGANIZATION_REQUEST,
      types.DELETE_ORGANIZATION_SUCCESS,
      types.DELETE_ORGANIZATION_FAILURE,
    ],
  },
})

export const removeOrganization = (subdomain) => (dispatch) => {
  return dispatch(deleteOrganization(subdomain))
}
