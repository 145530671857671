import { useEffect, useRef } from "react"
import { useCurrentUser, useCurrentUserProfile } from "../../api"
import LogRocket from "logrocket"

/**
 * Returns the current user's profile and invitations and signs them
 * in to any client based session tracking.
 * @param subdomain The subdomain of the organization to check
 */
export const useCurrentInvitedUser = (subdomain?: string) => {
  const user = useCurrentUser()
  const [getProfile, { data: profileData, loading }] = useCurrentUserProfile()
  const currentInvitation =
    profileData?.invitations?.find((i) => i.subdomain === subdomain) ??
    profileData?.invitations?.[0]
  const currentUserName = currentInvitation?.name ?? profileData?.name
  const currentUserEmail = currentInvitation?.email ?? profileData?.email
  const currentUserCreatedAt =
    currentInvitation?.created_at ?? profileData?.created_at
  const currentToken = useRef<string | null>(null)
  useEffect(() => {
    if (currentToken.current === user.accessToken || !user.accessToken) return
    getProfile()
    currentToken.current = user.accessToken
  }, [getProfile, profileData, loading, currentToken, user.accessToken])

  useEffect(() => {
    const intercom = (window as any).Intercom
    intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "ufudmoyk",
      name: currentUserName,
      email: currentUserEmail,
      created_at: currentUserCreatedAt,
      user_hash: profileData?.user_hash,
      user_id: profileData?.id,
    })
  })

  const intercomUser = useRef<undefined | string>(undefined)
  useEffect(() => {
    if (intercomUser.current === currentUserEmail) return
    const intercom = (window as any).Intercom
    intercomUser.current = currentUserEmail
    intercom("update", {
      name: currentUserName,
      email: currentUserEmail,
      created_at: currentUserCreatedAt,
      user_hash: profileData?.user_hash,
      user_id: profileData?.id,
    })
    if (profileData?.id) {
      LogRocket.identify(String(profileData?.id), {
        email: currentUserEmail ?? "Unknown",
        name: currentUserName ?? "Unknown",
      })
    }
  }, [
    currentUserName,
    currentUserEmail,
    currentUserCreatedAt,
    profileData?.id,
    profileData?.user_hash,
  ])

  return {
    profile: profileData,
    loading,
    invitation: currentInvitation,
    user,
  }
}
