var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { WeekViewKonva } from "./WeekViewKonva";
import { WeekViewDnD } from "./WeekViewDnD";
import { Layer, Stage } from "react-konva";
import { useResizeObserver } from "../../hooks";
import clsx from "clsx";
export var WeekView = function (props) {
    var konvaWrapper = useRef(null);
    var size = useResizeObserver({ element: konvaWrapper }).size;
    return (_jsxs("div", __assign({ className: clsx("flex flex-1 overflow-hidden", props.className), style: { height: props.height }, ref: konvaWrapper }, { children: [size.height === 0 || size.width === 0 ? null : (_jsx(Stage, __assign({ height: size.height, width: size.width }, { children: _jsx(Layer, { children: _jsx(WeekViewKonva, __assign({}, props, { x: 0, y: 0, height: props.height, width: size.width }), void 0) }, void 0) }), void 0)), _jsx(WeekViewDnD, __assign({}, props), void 0)] }), void 0));
};
