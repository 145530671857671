import React, { Component } from "react"
import PropTypes from "prop-types"
import { getEmptyImage } from "react-dnd-html5-backend"
import { DragSource } from "react-dnd"
import { Highlight } from "ui"
import { ItemTypes } from "../constants"
import SelectableItem from "./SelectableItem.react"
import InlineSetting from "./InlineSetting.react"
import styles from "./ItemView.module.css"
import {
  faEye,
  faEyeSlash,
  faLock,
  faUnlockAlt,
  faPencil,
  faTrash,
  faRepeat,
} from "@fortawesome/pro-regular-svg-icons"

class ItemView extends Component {
  constructor(props) {
    super(props)
    this.state = { hovering: false }
  }

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    })
  }

  handleDoubleClick() {
    const { handleEditItem, item } = this.props
    handleEditItem(item.uuid)
  }

  handleClick(e) {
    e.stopPropagation()
    const { handleSelectedItem, item } = this.props
    handleSelectedItem(item.uuid)
  }

  handleMouseOver() {
    this.setState({ hovering: true })
  }

  handleMouseLeave() {
    this.setState({ hovering: false })
  }

  handleLockSetting() {
    const { lockItem, unlockItem, locked, item, readOnly } = this.props
    if (readOnly) {
      return
    }
    locked
      ? unlockItem(item.uuid, item.calendarId)
      : lockItem(item.uuid, item.calendarId)
  }

  handleActiveSetting() {
    const { showItem, hideItem, active, item, readOnly } = this.props
    active
      ? hideItem(item.uuid, item.snapshotId || item.calendarId, readOnly)
      : showItem(item.uuid, item.snapshotId || item.calendarId, readOnly)
  }

  render() {
    const {
      name,
      active,
      locked,
      connectDragSource,
      isDragging,
      indentation,
      keywords,
      custom,
      selected,
      readOnly,
      item,
      parentInactive,
      parentLocked,
      ignoreToggleControls,
      replace,
      caseSensitive,
      ...props
    } = this.props
    const { hovering } = this.state
    return (
      <div
        onMouseOver={() => this.handleMouseOver()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <SelectableItem
          handleClick={(e) => this.handleClick(e)}
          handleDoubleClick={(e) => this.handleDoubleClick(e)}
          selected={isDragging || selected}
        >
          {keywords && keywords.length > 0 ? null : (
            <>
              <InlineSetting
                active={!active || parentInactive}
                disabled={parentInactive}
                visible={hovering && !isDragging && !ignoreToggleControls}
                handleClick={(e) => this.handleActiveSetting(e)}
                activeIcon={faEyeSlash}
                defaultIcon={faEye}
              />
              <InlineSetting
                active={locked || parentLocked}
                disabled={parentLocked}
                visible={hovering && !isDragging && !ignoreToggleControls}
                handleClick={(e) => this.handleLockSetting(e)}
                activeIcon={faLock}
                defaultIcon={faUnlockAlt}
              />
            </>
          )}
          {connectDragSource(
            <div
              className={`${styles.itemView} ${custom && styles.custom}`}
              style={{ marginLeft: `${indentation * 1.2}rem` }}
            >
              <span className={styles.name}>
                <Highlight
                  match={keywords}
                  content={name}
                  replace={replace}
                  caseSensitive={caseSensitive}
                />
              </span>
            </div>
          )}
          {!readOnly && keywords && replace && (
            <InlineSetting
              active={false}
              visible={hovering}
              handleClick={() => props.handleReplaceText()}
              activeIcon={faRepeat}
              defaultIcon={faRepeat}
              useAdditionalSpacing={false}
            />
          )}
          {!readOnly && (
            <InlineSetting
              active={false}
              visible={hovering}
              handleClick={() => props.handleEditItem(item.uuid)}
              activeIcon={faPencil}
              defaultIcon={faPencil}
              useAdditionalSpacing={false}
            />
          )}
          {!readOnly && (
            <InlineSetting
              active={false}
              visible={hovering}
              handleClick={() => props.handleDeleteItem(item.uuid)}
              activeIcon={faTrash}
              defaultIcon={faTrash}
            />
          )}
        </SelectableItem>
      </div>
    )
  }
}

ItemView.propTypes = {
  /**
   * The item entity being represented by this view.
   */
  item: PropTypes.object.isRequired,

  /**
   * The name of the category to display.
   */
  name: PropTypes.string.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be enabled.
   */
  active: PropTypes.bool.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be locked from date
   * shifts.
   */
  parentInactive: PropTypes.bool,

  /**
   * If true - it should indicate that the item is using
   * a custom style to overwrite its parent.
   */
  custom: PropTypes.bool.isRequired,

  /**
   * If true - the lock / hide indicators will not render on hover.
   */
  ignoreToggleControls: PropTypes.bool,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be locked from date
   * shifts.
   */
  locked: PropTypes.bool.isRequired,

  /**
   * If true - it should indicate that the event types
   * that belong to this item should be locked from date
   * shifts.
   */
  parentLocked: PropTypes.bool,

  /**
   * Indicates that the item is currently being dragged by the user.
   */
  isDragging: PropTypes.bool.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDragSource: PropTypes.func.isRequired,

  /**
   * Injected by ReactDND.
   */
  connectDragPreview: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an item to request
   * the editing modal.
   */
  handleEditItem: PropTypes.func.isRequired,

  /**
   * Called on an interaction with an item to request
   * the delete confirmation modal.
   */
  handleDeleteItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar item.
   */
  lockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar item.
   */
  unlockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar item.
   */
  showItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideItem: PropTypes.func.isRequired,

  /**
   * The level of indentation to be applied to this view.
   * Indententation is used to simulate nesting in the
   * directory structure.
   */
  indentation: PropTypes.number.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update the status of whether or not a
   * calendar item (not an existing event) is being dragged.
   */
  updateItemDraggingStatus: PropTypes.func.isRequired,

  /**
   * An array of keywords which any matches will be highlighted.
   */
  keywords: PropTypes.arrayOf(PropTypes.string),

  /**
   * An optional string to preview the replace text.
   */
  replace: PropTypes.string,

  /**
   * If true the find (and replace) operation will preview in a case sensitive fashion.
   */
  caseSensitive: PropTypes.bool,

  /**
   * A callback that fired when this item is selected
   * by the user. Useful for keyboard actions.
   */
  handleSelectedItem: PropTypes.func.isRequired,

  /**
   * A callback that is performed when the user hits
   * the find/replace button on a specific item.
   */
  handleReplaceText: PropTypes.func,

  /**
   * Indicates whether or not this item should appear
   * as if it were selected by the user.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * Get item style from the redux store.
   */
  findStyle: PropTypes.func.isRequired,

  /**
   * Ensures this view will not allow any edit interactions if set to true.
   */
  readOnly: PropTypes.bool.isRequired,
}

/**
 * A react DND specification for the DragSource component
 * representing the ItemView
 */
const itemViewSource = {
  beginDrag(props) {
    const { item } = props
    const style = props.findStyle(item.applicableStyleId, item.calendarId)
    props.updateItemDraggingStatus(true)
    return { ...style, ...item, start: true, end: true }
  },
  isDragging(props, monitor) {
    return props.name === monitor.getItem().name
  },
  endDrag(props) {
    props.updateItemDraggingStatus(false)
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

export default DragSource(
  ItemTypes.ITEM_VIEW,
  itemViewSource,
  collect
)(ItemView)
