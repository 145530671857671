import React, { useEffect } from "react"
import { StylableProps } from "./Styleable"
import { useActiveUsersOverTimeAnalytics } from "../api"
import { LineChartPanel } from "./LineChartPanel"

export interface ActiveUsersPerDayPanelProps {
  days?: number
}

export const ActiveUsersPerDayPanel: React.FC<
  StylableProps & ActiveUsersPerDayPanelProps
> = ({ days = 7, ...props }) => {
  const [periodLoaded, setPeriodLoaded] = React.useState(0)
  const [request, { loading, data }] = useActiveUsersOverTimeAnalytics(days)
  const chartData = (data ?? []).map((item) => ({
    x: item?.meta_1,
    y: item?.value,
  }))
  useEffect(() => {
    if (periodLoaded !== days) {
      request()
      setPeriodLoaded(days)
    }
  }, [request, setPeriodLoaded, days, periodLoaded])
  return (
    <LineChartPanel
      {...props}
      loading={loading}
      title="Active Users by Day"
      data={chartData.reverse()}
    />
  )
}
