import React from "react"
import PropTypes from "prop-types"
import styles from "./Ring.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"

/* eslint-disable complexity */
const getStyles = (theme) => {
  switch (theme) {
    case "dark":
      return styles.dark
    case "light":
      return styles.light
    case "placeholder":
      return [styles.neutral, styles.large].join(" ")
    case "button":
      return styles.button
    case "small":
      return [styles.dark, styles.small].join(" ")
    default:
      return styles.dark
  }
}
/* eslint-enable complexity */

const Ring = ({ theme }) => (
  <FontAwesomeIcon icon={faSpinnerThird} className={getStyles(theme)} spin />
)

Ring.propTypes = {
  theme: PropTypes.string,
}

export default Ring
