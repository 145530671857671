var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import clsx from "clsx";
import { remCalc, wait } from "utils";
var CONTAINER_STYLE = "mx-1 my-auto relative";
var RESULT_STYLES = "bg-white flex min-w-full flex-col border-2 border-t-0 shadow rounded-b overflow-y-auto absolute mt-0 top-100 z-40";
var LEFT_STYLES = "left-0";
var RIGHT_STYLES = "right-0";
var OVERLAY_STYLES = "fixed top-0 left-0 w-screen h-screen z-10 opacity-0";
var RESULT_ITEM_STYLES = "border-b border-contentAreaBorder text-left text-sm";
export var BarDropDownList = function (_a) {
    var name = _a.name, className = _a.className, children = _a.children, toggle = _a.toggle, _b = _a.position, position = _b === void 0 ? "left" : _b, onDismiss = _a.onDismiss;
    var _c = useState(false), active = _c[0], setActive = _c[1];
    var handleToggle = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        setActive(!active);
    };
    var delayedDismiss = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, wait(0.2)];
                case 1:
                    _a.sent();
                    setActive(false);
                    onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: clsx(className, CONTAINER_STYLE) }, { children: [toggle(active, handleToggle), active ? (_jsxs(_Fragment, { children: [_jsx("ul", __assign({ className: clsx(RESULT_STYLES, position === "left" ? LEFT_STYLES : RIGHT_STYLES), style: { maxHeight: remCalc(256) } }, { children: children(delayedDismiss).map(function (child, index) { return (_jsx("li", __assign({ className: RESULT_ITEM_STYLES, onClick: delayedDismiss }, { children: child }), "dropdown" + name + "Option" + index)); }) }), void 0), _jsx("button", __assign({ className: OVERLAY_STYLES, onClick: delayedDismiss }, { children: "Dismiss Dropdown" }), void 0)] }, void 0)) : null] }), void 0));
};
