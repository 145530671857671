import { reduxForm } from "redux-form"
import { FORM_NAME } from "../constants"
import { asyncValidate, validate } from "../validators"
import CalendarFormView from "../components/CalendarFormView.react"

export default reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ["name"],
})(CalendarFormView)
