import * as types from "../actionTypes"

/**
 * Updates the drag offset value to calculate a diff during drag and drop events.
 * @param {Integer} dragOffset The date offset used to calculate a DnD action.
 * @return {Object} An FSA action.
 */
export const setDragOffset = (dragOffset) => ({
  type: types.SET_DRAG_OFFSET,
  payload: {
    dragOffset,
  },
})
