var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
var BASE_BAR = "flex flex-col relative";
var EXPANDED_BAR = BASE_BAR + " overflow-visible w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5";
var COMPACT_BAR = BASE_BAR + " overflow-visible w-auto";
var HIDDEN_BAR = BASE_BAR + " w-0 overflow-hidden";
var classForMode = function (mode) {
    switch (mode) {
        case "compact":
            return COMPACT_BAR;
        case "expanded":
            return EXPANDED_BAR;
        default:
            return HIDDEN_BAR;
    }
};
var borderForAlignment = function (alignment) {
    return alignment === "left" ? "border-r" : "border-l";
};
var colorsForAppearance = function (appearance) {
    return appearance === "navigator"
        ? "border-gray-300 bg-navy"
        : "border-gray-300 bg-gray-100";
};
export var SideBar = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? "compact" : _b, _c = _a.appearance, appearance = _c === void 0 ? "default" : _c, _d = _a.alignment, alignment = _d === void 0 ? "right" : _d, children = _a.children, renderHeading = _a.renderHeading, className = _a.className, style = _a.style;
    return (_jsxs("div", __assign({ className: clsx([
            classForMode(mode),
            borderForAlignment(alignment),
            colorsForAppearance(appearance),
            className,
        ]), style: style }, { children: [renderHeading ? (_jsx("div", __assign({ className: "flex w-full mb-1" }, { children: renderHeading() }), void 0)) : null, _jsx("nav", __assign({ className: BASE_BAR + " p-1 h-full" }, { children: children }), void 0)] }), void 0));
};
