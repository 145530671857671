import React from "react"
import PropTypes from "prop-types"
import styles from "./Fade.module.css"

const Fade = ({ children, ...props }) => (
  <div className={styles.container} {...props}>
    {children}
  </div>
)

Fade.propTypes = {
  children: PropTypes.node,
}

export default Fade
