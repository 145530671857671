import { useApiRequest } from "./useApiRequest"
import { AnalyticResult } from "./useActiveOrgsAnalytics"

export const useInactiveOrgsAnalytics = () => {
  const getInactiveOrgs = useApiRequest<AnalyticResult[], undefined>({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/analytics/inactive_orgs`,
  })
  return getInactiveOrgs
}
