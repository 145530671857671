import { useApiRequest } from "./useApiRequest"
import { useAddGroups, useExistingGroups, GroupDataStructure } from "../atoms"
import { useEffect, useState } from "react"

export const useGroups = (subdomain?: string) => {
  const [requestGroups, { data, loading }] = useApiRequest<
    GroupDataStructure[],
    GroupDataStructure
  >({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/`,
  })
  const [persisted, setPersisted] = useState(false)
  const existingGroups = useExistingGroups()
  const addGroups = useAddGroups()

  useEffect(() => {
    if (!data) {
      requestGroups()
    } else if ((data as any)?.error) {
      console.error("Error loading groups", data)
    } else if (!persisted) {
      addGroups(data)
      setPersisted(true)
    }
  }, [data, loading, addGroups, persisted, setPersisted, requestGroups])

  const refetch = async () => {
    await setPersisted(false)
    await requestGroups()
  }

  return {
    refetch,
    loading,
    groups: Object.values(existingGroups).filter(
      (g) => g.subdomain === subdomain
    ),
  }
}
