import React, { Component } from "react"
import PropTypes from "prop-types"
import { isNotBlank } from "../../utils/helpers"
import Group from "./Group.react"
import TextArea from "./TextArea.react"
import Label from "./Label.react"

class ModalFieldTextArea extends Component {
  focus() {
    this.textInput.input.focus()
  }

  render() {
    const {
      meta,
      customizations,
      input,
      leadingAddOn,
      label,
      disabled,
      placeholder,
      type,
    } = this.props
    const showError = isNotBlank(meta.error) && meta.touched
    const changeHandler =
      (customizations && customizations.onChange) || input.onChange
    return (
      <Group error={showError}>
        <Label htmlFor={`${input.name}`}>{label}</Label>
        <div className="input-group">
          {leadingAddOn && (
            <span className="input-group-btn">{leadingAddOn}</span>
          )}
          <TextArea
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            ref={(textInput) => {
              this.textInput = textInput
            }}
            {...input}
            onChange={(e) => changeHandler(e)}
          />
        </div>
        {showError && <span className="error">{meta.error}</span>}
      </Group>
    )
  }
}

ModalFieldTextArea.propTypes = {
  meta: PropTypes.object,
  customizations: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  leadingAddOn: PropTypes.node,
}

export default ModalFieldTextArea
