import React, { Component } from "react"

class TextArea extends Component {
  render() {
    return (
      <textarea
        className="form-control"
        ref={(input) => (this.input = input)}
        {...this.props}
      />
    )
  }

  getValue() {
    if (this.input === null) {
      return ""
    }
    return this.input.value.trim()
  }
}

export default TextArea
