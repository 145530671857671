var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import clsx from "clsx";
import { useRenderableSegmentsForWeek, } from "./useRenderableSegmentsForWeek";
import { EventSegment, } from "./EventSegment";
var EVENT_STYLES = "w-full flex";
/**
 * An event bubble that appears on a calendar.
 */
export var EventView = function (_a) {
    var startsAt = _a.startsAt, weekStartsAt = _a.weekStartsAt, _b = _a.darkDays, darkDays = _b === void 0 ? [] : _b, _c = _a.breakOnDarkDays, breakOnDarkDays = _c === void 0 ? false : _c, _d = _a.breakOnWeekends, breakOnWeekends = _d === void 0 ? false : _d, simplified = _a.simplified, printable = _a.printable, dragging = _a.dragging, ignored = _a.ignored, name = _a.name, prefix = _a.prefix, length = _a.length, uuid = _a.uuid, calendarId = _a.calendarId, selected = _a.selected, renderAsNote = _a.renderAsNote, locked = _a.locked, includedOnClipboard = _a.includedOnClipboard, style = _a.style, notes = _a.notes, readOnly = _a.readOnly, fontSizeOverride = _a.fontSizeOverride, sibling = _a.sibling, handleEdit = _a.onEdit, externalContextHandler = _a.onContextMenu, handleSelect = _a.onSelect, handleDrag = _a.onDrag;
    var _e = useState(0), lastClicked = _e[0], setLastClicked = _e[1];
    var _f = useState(false), hovering = _f[0], setHovering = _f[1];
    var handleMouseHover = function (isHovering) { return function (e) {
        e.preventDefault();
        setHovering(isHovering);
    }; };
    var handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        var time = new Date().getTime();
        var double = time - (lastClicked || 0) < 500;
        setLastClicked(time);
        double ? handleEdit(uuid, calendarId) : handleSelect(uuid, calendarId);
    };
    var _g = useRenderableSegmentsForWeek({
        weekStartsAt: weekStartsAt,
        startsAt: startsAt,
        breakOnDarkDays: breakOnDarkDays,
        breakOnWeekends: breakOnWeekends,
        darkDays: darkDays,
        length: length,
    }), segments = _g.segments, initialOffset = _g.initialOffset;
    var draggable = hovering && !readOnly;
    var handleContextMenu = function (params) {
        externalContextHandler === null || externalContextHandler === void 0 ? void 0 : externalContextHandler(__assign(__assign({}, params), { uuid: uuid, calendarId: calendarId }));
    };
    return (_jsx("div", __assign({ className: clsx(EVENT_STYLES, sibling && "absolute") }, { children: segments.map(function (segment, i) {
            var segmentOffset = i === 0
                ? initialOffset
                : Math.ceil(segment.starts.diff(segments[i - 1].starts, "days").days) - segments[i - 1].length;
            return (_jsx(EventSegment, { onMouseOver: handleMouseHover(true), onMouseLeave: handleMouseHover(false), onContextMenu: handleContextMenu, onClick: handleClick, fontSizeOverride: fontSizeOverride, offset: segmentOffset, style: style, renderAsNote: renderAsNote, segment: segment, calendarId: calendarId, uuid: uuid, draggable: draggable, onDrag: handleDrag, name: name, simplified: simplified, printable: printable, dragging: dragging, disabled: ignored, prefix: prefix, selected: selected, hovering: hovering, locked: locked, includedOnClipboard: includedOnClipboard, notes: notes }, uuid + "-" + calendarId + "-" + segment.starts.toISODate()));
        }) }), void 0));
};
