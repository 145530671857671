import React, { useEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import {
  AsyncSubmitHandler,
  Avatar,
  LoginForm,
  LoginFormValues,
  Modal,
} from "ui"
import queryString from "query-string"
import toast from "react-hot-toast"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { wait } from "utils"
import { getCookie, setCookie } from "react-simple-cookie-store"
import { invitationSelector, loadAuthToken } from "../api"
import logo from "../images/ProCal.svg"
import logoIcon from "../images/ProCal-logo.svg"
import { useSetKeeperPrompt } from "./useSetKeeperPrompt"

export interface LoginModalProps {}

const AGREED_TO_TERMS_COOKIE = "agreed-to-terms"

export const LoginModal: React.FC<LoginModalProps> = () => {
  useSetKeeperPrompt()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [agreedToTerms] = useState(getCookie(AGREED_TO_TERMS_COOKIE) === "yes")

  const query = queryString.parse(location.search)
  const pendingInviteOrg: any = useSelector(
    invitationSelector.pendingInvitationOrganization
  )

  const handleAgreedToTerms = (agreed: boolean) => {
    setCookie(AGREED_TO_TERMS_COOKIE, agreed ? "yes" : "no", 365)
  }

  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<LoginFormValues> = async (data) => {
    const { email, password } = data

    setLoading(true)
    await wait(1)
    try {
      const { response } = (await dispatch(
        loadAuthToken({
          username: email,
          password: password,
        })
      )) as any
      console.error(response.status)
      if (response.status === 400) {
        toast.error("The email or password used was incorrect.")
      } else if (response.status === 200) {
        toast.dismiss()
        toast.success("Successfully logged in.")
      } else {
        toast.error("There was a problem connecting to the server.")
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  const field = useRef<HTMLInputElement>(null)
  useEffect(() => {
    field.current?.focus()
  })

  return (
    <Modal title="Login" open>
      <Helmet title="Login to Your Account" />
      {pendingInviteOrg?.name ? (
        <div className="flex">
          <Avatar name={pendingInviteOrg?.name} color="RGBA(220, 104, 57, 1)" />
          <div className="flex flex-col items-start">
            <p>You have been invited to join:</p>
            <h4>{pendingInviteOrg?.name}</h4>
          </div>
        </div>
      ) : query.logo ? (
        <div className="flex flex-col mx-auto my-2 relative">
          <img
            className="block mx-auto my-auto"
            style={{ maxWidth: 244 }}
            alt="ProCal"
            src={query.logo as string}
          />
          <div
            className="absolute bottom-0 right-0 mr-4 -mb-6 rounded-full bg-white border border-stone-50 h-16 w-16 bg-center shadow-lg"
            style={{
              backgroundImage: `url(${logoIcon})`,
              backgroundSize: 38,
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>
      ) : (
        <img
          className="block mx-auto my-2"
          alt="ProCal"
          src={logo}
          style={{
            width: 244,
            height: "auto",
          }}
        />
      )}
      <LoginForm
        onSubmit={onSubmit}
        defaultValues={{
          agreedToTerms,
          email: "",
          password: "",
        }}
        hasAgreedToTerms={handleAgreedToTerms}
        loading={loading}
      />
      <p className="text-center mx-auto pb-3">
        Having trouble logging in?{" "}
        <Link to="/login/forgot" className="text-lochivar-default font-bold">
          Reset your password.
        </Link>
      </p>
      <p className="border-t border-gray-400 my-3 pt-3 text-center">
        <strong>Have questions or suggestions?</strong>
        <br />
        Please email <a href="mailto:support@revolutiones.com">support</a> or
        call 818.303.3306
      </p>
      <p className="text-xs text-center">
        Powered by{" "}
        <a href="https://www.revolutiones.com" className="text-sorbus-default">
          Revolution Entertainment Services
        </a>
      </p>
      <p className="text-xs text-center">
        <a
          href="http://www.revolutiones.com/#/termsofuse"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Terms of Service
        </a>{" "}
        |{" "}
        <a
          href="http://www.revolutiones.com/#/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Privacy Policy
        </a>
      </p>
    </Modal>
  )
}
