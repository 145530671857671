import React from "react"
import PropTypes from "prop-types"
import Heading from "./Heading.react"
import Divider from "./Divider.react"
import styles from "./Collapsable.module.css"
import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons"

class Collapsable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: props.initiallyOpen || false }
  }

  render() {
    const { name, sub, children } = this.props
    const { open } = this.state
    return (
      <div className={styles.collapsable}>
        <Heading
          name={name}
          sub={sub}
          icon={open ? faCaretDown : faCaretRight}
          onClick={() => {
            this.setState({ open: !open })
            return false
          }}
        />
        <Divider />
        {open ? children : null}
      </div>
    )
  }
}

Collapsable.propTypes = {
  initiallyOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  sub: PropTypes.string,
  children: PropTypes.node,
}

export default Collapsable
