import React from "react"
import PropTypes from "prop-types"
import styles from "./ShortListLabel.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const STYLES = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SECONDARY_ERROR: "secondaryError",
}

const classNameForStyle = (style) => {
  switch (style) {
    case STYLES.PRIMARY:
      return styles.primaryText
    case STYLES.SECONDARY:
      return styles.secondaryText
    case STYLES.SECONDARY_ERROR:
      return styles.secondaryErrorText
    default:
      return styles.primaryText
  }
}

const ShortListLabel = ({ style, children, icon }) => (
  <span className={classNameForStyle(style)}>
    {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
    {children}
  </span>
)

ShortListLabel.STYLES = STYLES

ShortListLabel.propTypes = {
  style: PropTypes.string.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
}

export default ShortListLabel
