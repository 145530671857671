import React, { useState } from "react"
import moment from "moment"
import { useInvitations } from "./hooks"
import { Button } from "ui"
import { Panel } from "./Panel"
import { Title } from "./Title"
import { Results } from "./Resullts"
import { StylableProps } from "./Styleable"
import { Link } from "react-router-dom"

interface InvitationsPanelProps {
  maxResults?: number
  days?: number
  status?: "Pending" | "Accepted" | "All"
}
export const InvitationsPanel: React.FC<
  StylableProps & InvitationsPanelProps
> = ({ maxResults = 10, days = 30, status = "All", ...props }) => {
  const { loading, invitations } = useInvitations(days, status)
  const [showAll, setShowAll] = useState(false)
  const handleShowAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setShowAll(!showAll)
  }
  return (
    <Panel {...props} loading={loading}>
      <Title>
        {(invitations ?? []).length} {status !== "All" ? status : ""}{" "}
        Invitations (Last {days} days)
      </Title>
      <Results>
        <>
          <p className="px-2 w-1/4 text-sm font-bold">Name</p>
          <p className="px-2 w-1/5 text-sm font-bold">Sent on</p>
          <p className="px-2 w-1/5 text-sm font-bold">Status</p>
          <p className="px-2 w-1/3 text-sm font-bold">Organization</p>
        </>
        {(invitations ?? [])
          .filter((_: any, i: any) =>
            showAll ? true : maxResults ? i <= maxResults : true
          )
          .map((metric: any, i: number) => (
            <React.Fragment key={metric.id}>
              <p className="px-2 w-1/4 text-sm flex-grow-0 truncate">
                <Link
                  to={`/org/${metric.meta3}/invite/${metric.resourceId}/settings`}
                >
                  {metric.meta1}
                </Link>
              </p>
              <p className="px-2 w-1/5 text-sm flex-grow-0 truncate">
                {moment(metric.value).format("MMM DD h:mm A")}
              </p>
              <p className="px-2 w-1/5 text-sm flex-grow-0 truncate">
                {metric.meta4}
              </p>
              <p className="px-2 w-1/3 text-sm flex-grow-0 truncate">
                {metric.meta2}
              </p>
            </React.Fragment>
          ))}
        {invitations.length <= maxResults ? (
          <p className="px-2 w-full text-sm flex-grow-0 truncate font-italic text-gray-500">
            {invitations.length
              ? `Displaying all ${invitations.length} results.`
              : "No results found."}
          </p>
        ) : (
          <Button onClick={handleShowAll} appearance="secondary">
            {showAll ? "Show Less" : "Show All"}
          </Button>
        )}
      </Results>
    </Panel>
  )
}
