import React from "react";
export var GanntEventsView = React.forwardRef(function (_a, forwardedRef) {
    var events = _a.events, originDate = _a.originDate, originPoint = _a.originPoint, cellHeight = _a.cellHeight, dayCellSize = _a.dayCellSize, _b = _a.scrollWidth, scrollWidth = _b === void 0 ? 0 : _b, highlightedIndex = _a.highlightedIndex;
    return null;
    // return (
    //   <ScrollTileView
    //     defaultPosition="start"
    //     scrollDirection="vertical"
    //     tileCount={events.length}
    //     cellSize={{ width: scrollWidth, height: cellHeight }}
    //     ref={forwardedRef}
    //     scrollable
    //   >
    //     {({ index, tileSize }) => {
    //       const event = events[index]
    //       return (
    //         <GanntEvent
    //           originDate={originDate}
    //           originPoint={originPoint}
    //           cellSize={dayCellSize}
    //           background={event.background}
    //           foreground={event.foreground}
    //           outline={event.outline}
    //           startsAt={event.startsAt}
    //           length={event.length}
    //           id={event.id}
    //           name={event.name}
    //           width={scrollWidth}
    //           height={tileSize}
    //           highlighted={highlightedIndex === index}
    //         />
    //       )
    //     }}
    //   </ScrollTileView>
    // )
});
