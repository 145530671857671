var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, GroupField, Fields } from "../Form";
import { AbsoluteOverlay } from "../Indicators";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDefaultValueListener } from "../../hooks";
import { camelCase } from "lodash";
import { ErrorList } from "../Form/ErrorList";
var schema = yup.object({
    groupUuid: yup.string().required("cannot be blank"),
});
export var AssignGroupForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle, groups = _a.groups, handleCreateGroup = _a.onCreateGroup;
    var _b = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    }), handleSubmit = _b.handleSubmit, register = _b.register, formErrors = _b.formState.errors, setError = _b.setError, setValue = _b.setValue, reset = _b.reset, watch = _b.watch;
    var groupUuid = watch(["groupUuid"])[0];
    useDefaultValueListener(defaultValues, reset);
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var _c = React.useState(false), creating = _c[0], setCreating = _c[1];
    var handleSelectCreateGroup = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCreating(true);
                    return [4 /*yield*/, (handleCreateGroup === null || handleCreateGroup === void 0 ? void 0 : handleCreateGroup(value))];
                case 1:
                    result = _a.sent();
                    if (result) {
                        setValue("groupUuid", result);
                    }
                    setCreating(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleGroupSelect = function (newVal) {
        setValue("groupUuid", newVal !== null && newVal !== void 0 ? newVal : "");
    };
    return (_jsxs("form", __assign({ onSubmit: handleFormSubmit, className: "relative" }, { children: [_jsxs(Fields, __assign({ register: register, errors: formErrors, grow: true, className: "pb-2" }, { children: [_jsx(GroupField, { name: "groupUuid", label: "Name", placeholder: "Name of group", className: "w-full", value: groupUuid, options: groups, onSelect: handleGroupSelect, onCreate: handleSelectCreateGroup, creatable: handleCreateGroup ? true : false, selfControllable: true, clearable: true }, void 0), _jsx("input", { type: "hidden", name: "groupUuid" }, void 0), _jsx(ErrorList, { errors: formErrors }, void 0), _jsx(Button, __assign({ type: "submit", className: "mt-2 w-full" }, { children: submitTitle !== null && submitTitle !== void 0 ? submitTitle : "Assign Group" }), void 0)] }), void 0), loading || creating ? _jsx(AbsoluteOverlay, {}, void 0) : null] }), void 0));
};
