var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { concatStyles } from "utils";
import { Field, getErrorMessage } from "./Field";
import { Control } from "./Control";
import { RadioCardControl } from "./RadioCardControl";
import { ControlsContext } from "./ControlsContext";
var FIELD_LABEL_STYLES = "capitalize text-base mb-1";
var CONTAINER_STYLES = "inline-flex rounded-md";
var FLEX_COLUMN = "flex-col";
var FULL_WIDTH = "w-full";
var FLEX_ROW = "flex-row";
var SHADOW = "shadow";
export var ControlGroup = React.forwardRef(function (_a, ref) {
    var label = _a.label, name = _a.name, selected = _a.selected, className = _a.className, style = _a.style, disabled = _a.disabled, errorMessage = _a.errorMessage, errors = _a.errors, grow = _a.grow, register = _a.register, children = _a.children, _b = _a.column, column = _b === void 0 ? false : _b, _c = _a.shadow, shadow = _c === void 0 ? true : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, fieldProps = __rest(_a, ["label", "name", "selected", "className", "style", "disabled", "errorMessage", "errors", "grow", "register", "children", "column", "shadow", "fullWidth"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    return (_jsx(Field, __assign({ name: name, label: label, labelClassName: FIELD_LABEL_STYLES, style: style, errorMessage: determinedErrorMessage, grow: true }, fieldProps, { children: _jsx(ControlsContext.Provider, __assign({ value: { selected: selected, name: name, register: register } }, { children: _jsx("div", __assign({ className: concatStyles(CONTAINER_STYLES, shadow && SHADOW, fullWidth && FULL_WIDTH, column ? FLEX_COLUMN : FLEX_ROW), ref: ref }, { children: children }), void 0) }), void 0) }), void 0));
}); // * This to allow adding compound components as done below
ControlGroup.Control = Control;
ControlGroup.RadioCardControl = RadioCardControl;
