var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { Button, Control, ControlGroup, TextField, ErrorList, Fields, DateField, DropdownField, DropDownItem, StyleField, MultiSelectField, } from "../Form";
import { priorWeekendsBetweenDates } from "../Calendar/dateHelpers";
import { DateTime } from "luxon";
import { AbsoluteOverlay } from "../Indicators";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDefaultValueListener } from "../../hooks";
import { camelCase } from "lodash";
import { TextAreaField } from "../Form/TextAreaField";
import clsx from "clsx";
import { Dismissable } from "..";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
export var partitions = [
    {
        name: "Early",
        value: 0,
    },
    {
        name: "Mid-Day",
        value: 1,
    },
    {
        name: "Late",
        value: 2,
    },
];
var schema = yup.object({
    name: yup.string().required("event name cannot be blank"),
    length: yup
        .number()
        .typeError("of event must be at least 1 day")
        .min(1)
        .required()
        .nullable(),
    categoryUuid: yup.string().required("category cannot be blank"),
});
var compareAsString = function (a, b) {
    return "" + a === "" + b;
};
var makeTagId = function (tag) { return "#" + tag + "#"; };
var splitDefaultValue = function (value) { return value.split(", "); };
export var EventForm = function (_a) {
    var _b, _c, _d;
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, _e = _a.categories, categories = _e === void 0 ? [] : _e, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle, _f = _a.styles, styles = _f === void 0 ? [] : _f, handleEditCategory = _a.onEditCategory, handleEditTheme = _a.onEditTheme, handleEditStyle = _a.onEditStyle, handleCreateCategory = _a.onCreateCategory, _g = _a.darkDays, darkDays = _g === void 0 ? [] : _g, _h = _a.availableTags, availableTags = _h === void 0 ? [] : _h;
    var _j = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    }), handleSubmit = _j.handleSubmit, register = _j.register, formErrors = _j.formState.errors, watch = _j.watch, setError = _j.setError, setValue = _j.setValue, reset = _j.reset;
    var _k = useState(__spreadArray([], availableTags, true)), localTags = _k[0], setLocalTags = _k[1];
    var _l = useState(false), advancedMode = _l[0], setAdvancedMode = _l[1];
    var _m = useState(null), newCategory = _m[0], setNewCategory = _m[1];
    var _o = useState(null), newStyle = _o[0], setNewStyle = _o[1];
    var _p = watch([
        "categoryUuid",
        "tags",
        "startsAt",
        "length",
        "partition",
        "interruptedByWeekends",
        "interruptedByHolidays",
        "styleUuid",
    ]), categoryUuid = _p[0], tags = _p[1], startsAt = _p[2], length = _p[3], partition = _p[4], interruptedByWeekends = _p[5], interruptedByHolidays = _p[6], styleUuid = _p[7];
    var categoryStyles = categories.map(function (c) {
        return (__assign(__assign({}, styles.find(function (_a) {
            var uuid = _a.uuid;
            return uuid === c.styleUuid;
        })), { uuid: c.uuid, name: c.name }));
    });
    var getInterruptions = function (startDate, endDate, includeWeekends, includeHolidays) {
        var start = DateTime.fromISO(startDate, { zone: "utc" });
        var end = DateTime.fromISO(endDate, { zone: "utc" });
        var darkdaysInRange = includeHolidays
            ? darkDays
                .map(function (day) { return DateTime.fromISO(day, { zone: "utc" }); })
                .filter(function (day) {
                return day.diff(start, "days").days >= 0 &&
                    day.diff(end, "days").days <= 0;
            })
                .map(function (d) { return d.toISO(); })
            : [];
        var weekends = includeWeekends
            ? priorWeekendsBetweenDates(start.plus({ days: 1 }).toISO(), end.toISO())
            : [];
        return __spreadArray(__spreadArray([], darkdaysInRange, true), weekends, true).map(function (d) { return d.substring(0, 10); })
            .filter(function (d, i, a) { return a.indexOf(d) === i; })
            .sort();
    };
    // We have to apply a transform to the default values here where we do some
    // similar logic as that in the WeekView components to calculate the true end
    // date of the event when taking interruptions into account. This will ensure
    // that the form renders the proper end date if the length of the event is only
    // X days long while encountering Y interruptions.
    useDefaultValueListener(__assign(__assign({}, defaultValues), { occursOn: "" }), reset, function (values) {
        var _a, _b, _c, _d;
        if (!values)
            return values;
        setLocalTags(__spreadArray(__spreadArray([], splitDefaultValue((_a = values.tags) !== null && _a !== void 0 ? _a : ""), true), (availableTags || []), true).filter(function (t) { return t === null || t === void 0 ? void 0 : t.length; })
            .filter(function (t, i, a) { return a.indexOf(t) === i; }));
        var includeWeekends = values.interruptedByWeekends === "1";
        var includeHolidays = values.interruptedByHolidays === "1";
        var interruptions = getInterruptions(values.startsAt, DateTime.fromISO(values.startsAt, { zone: "utc" })
            .plus({ days: values.length })
            .toISO(), includeWeekends, includeHolidays);
        var i = 0;
        while (true) {
            var recalculatedInterruptions = getInterruptions(values.startsAt, DateTime.fromISO(values.startsAt, { zone: "utc" })
                .plus({
                days: values.length + interruptions.length,
            })
                .toISO(), includeWeekends, includeHolidays);
            i++;
            if (i === 10 ||
                recalculatedInterruptions.length === interruptions.length)
                break;
            interruptions = recalculatedInterruptions;
        }
        return values
            ? __assign(__assign({}, values), { tags: (_c = (_b = values.tags) === null || _b === void 0 ? void 0 : _b.split(", ").map(makeTagId).join(" ")) !== null && _c !== void 0 ? _c : "", length: ((_d = values === null || values === void 0 ? void 0 : values.length) !== null && _d !== void 0 ? _d : 1) + interruptions.length }) : values;
    });
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var start, end, interruptions, _a, errors, keys;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    start = DateTime.fromISO(data.startsAt, { zone: "utc" });
                    end = start.plus({ days: data.length - 1 });
                    interruptions = getInterruptions(start.toISO(), end.toISO(), interruptedByWeekends === "1", interruptedByHolidays === "1");
                    return [4 /*yield*/, externalSubmitHandler(__assign(__assign({}, data), { tags: (_b = data.tags) === null || _b === void 0 ? void 0 : _b.split("# ").join(", ").replace(/#/g, ""), length: length - interruptions.length }))];
                case 1:
                    _a = ((_c = (_d.sent())) !== null && _c !== void 0 ? _c : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var field = useRef(null);
    useEffect(function () {
        var _a;
        (_a = field.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    var toggleAdvancedMode = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setAdvancedMode(true);
    };
    var handleStyleSelect = function (newVal) {
        setValue("styleUuid", newVal !== null && newVal !== void 0 ? newVal : "");
    };
    var onCreateCategory = function (newVal) {
        setNewCategory(newVal);
    };
    var onCreateStyle = function (newVal) {
        setNewStyle(newVal);
    };
    var onCreateTag = function (newVal) {
        setLocalTags(__spreadArray(__spreadArray([], localTags, true), [newVal !== null && newVal !== void 0 ? newVal : ""], false));
        setValue("tags", __spreadArray(__spreadArray([], (tags !== null && tags !== void 0 ? tags : "").split(" "), true), ["#" + newVal + "#"], false).join(" "), {
            shouldValidate: true,
        });
    };
    var handleCategorySelect = function (newVal) {
        setValue("categoryUuid", newVal !== null && newVal !== void 0 ? newVal : "", { shouldValidate: true });
    };
    var handleTagSelect = function (newVal) {
        setValue("tags", newVal !== null && newVal !== void 0 ? newVal : "", {
            shouldValidate: true,
        });
    };
    var handleDateChange = function (d, r) {
        var _a, _b;
        setValue("startsAt", d[0].toISODate());
        setValue("length", ((_a = d[1]) === null || _a === void 0 ? void 0 : _a.diff((_b = d[0]) !== null && _b !== void 0 ? _b : d[1], "days").days) + 1, {
            shouldValidate: true,
        });
    };
    var handleCategoryClick = handleEditCategory
        ? function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleEditCategory(categoryUuid);
        }
        : null;
    var handleCreateCategoryStyleSelect = function (styleUuid) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        var category = handleCreateCategory === null || handleCreateCategory === void 0 ? void 0 : handleCreateCategory(newCategory !== null && newCategory !== void 0 ? newCategory : "New Category", styleUuid);
        setNewCategory(null);
        if (category) {
            setValue("categoryUuid", category.uuid);
        }
    }; };
    var handleEditStyleClick = handleEditStyle
        ? function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleEditStyle(styleUuid);
        }
        : null;
    var handleEditThemeClick = handleEditTheme
        ? function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleEditTheme === null || handleEditTheme === void 0 ? void 0 : handleEditTheme();
        }
        : null;
    var start = startsAt
        ? DateTime.fromISO(startsAt, { zone: "utc" })
        : undefined;
    var end = start === null || start === void 0 ? void 0 : start.plus({ days: length - 1 });
    return (_jsxs("form", __assign({ onSubmit: handleFormSubmit, className: "relative" }, { children: [_jsxs(Fields, __assign({ register: register, errors: formErrors, grow: true, className: "pb-2" }, { children: [_jsx(TextField, { name: "name", label: "Name", placeholder: "Name of Event", required: true, ref: field, className: "w-full", initiallyFocused: true }, void 0), _jsx(Fields, __assign({ nested: true, grow: true }, { children: _jsxs(Fields, __assign({ nested: true, strictVertical: true, grow: true }, { children: [_jsx(StyleField, { name: "categoryUuid", options: categoryStyles, value: categoryUuid, label: "Category / Appearance", id: "categoryUuid", placeholder: "Select category", className: clsx(newCategory ? "hidden" : "flex-grow flex-shrink-0 w-full"), onSelect: handleCategorySelect, onCreate: onCreateCategory, creatable: typeof handleCreateCategory === "function", required: true, clearable: true, selfControllable: true }, void 0), _jsx("input", { type: "hidden", name: "categoryUuid" }, void 0), newCategory ? (_jsx(Dismissable, __assign({ title: "Choose a style for \"" + newCategory + "\"", onDismiss: function () {
                                        setNewCategory(null);
                                    } }, { children: _jsx("div", __assign({ className: "flex" }, { children: styles.map(function (style, idx) { return (_jsx("button", __assign({ className: "block rounded-full h-6 w-6 mr-1 hover:scale-105 transition-transform", style: {
                                                backgroundColor: style.fillColor,
                                                border: "1px solid " + style.outlineColor,
                                                fontFamily: style.font,
                                                fontSize: style.fontSize,
                                                textDecoration: style.textDecoration,
                                                fontStyle: style.fontStyle,
                                                fontWeight: style.fontWeight,
                                                textAlign: "center",
                                                color: style.textColor,
                                            }, onClick: handleCreateCategoryStyleSelect(style.uuid) }, { children: idx + 1 }), style.uuid)); }) }), void 0) }), void 0)) : null, handleCategoryClick ? (_jsx(Button, __assign({ type: "button", appearance: "link", className: "-mt-2 text-xs", onClick: handleCategoryClick }, { children: categoryUuid ? "Edit Category Details" : "Create New Category" }), void 0)) : null] }), void 0) }), void 0), _jsx(DateField, { name: "occursOn", label: "Duration", value: startsAt, dates: [start, end].filter(function (i) { return i; }), onDateChange: handleDateChange, className: "flex-grow", error: (_b = formErrors.length) === null || _b === void 0 ? void 0 : _b.message, range: true, rangeDirection: "forward", darkDays: interruptedByHolidays === "1" ? darkDays : [], disableWeekends: interruptedByWeekends === "1", required: true }, void 0), _jsx(MultiSelectField, { name: "tags", label: "Tags", value: tags, options: localTags.sort().map(function (t) { return ({
                            label: t,
                            value: t,
                            id: "#" + t + "#",
                            icon: faTag,
                        }); }), onSelect: handleTagSelect, onCreateOption: onCreateTag, placeholder: "Add any tags i.e. 'ep101'", selfControllable: true, grow: true, labelClassName: "font-normal", isMulti: true, creatable: true }, void 0), _jsxs(Fields, __assign({ className: advancedMode ? "visible" : "hidden", nested: true, grow: true, strictVertical: true }, { children: [_jsxs(Fields, __assign({ nested: true, strictVertical: true, grow: true }, { children: [_jsx(StyleField, { name: "styleUuid", options: styles, value: styleUuid, label: "Custom Style", id: "styleUuid", placeholder: "leave blank to use category style", className: clsx(newStyle ? "hidden" : "flex-grow flex-shrink-0 w-full"), onSelect: handleStyleSelect, onCreate: onCreateStyle, clearable: true, selfControllable: true }, void 0), _jsx("input", { type: "hidden", name: "styleUuid" }, void 0), _jsxs("div", __assign({ className: "flex" }, { children: [handleEditStyleClick ? (_jsx(Button, __assign({ type: "button", appearance: "link", className: "-mt-2 text-xs", onClick: handleEditStyleClick }, { children: "Edit Style" }), void 0)) : null, handleEditThemeClick ? (_jsx(Button, __assign({ type: "button", appearance: "link", className: "-mt-2 ml-2 text-xs", onClick: handleEditThemeClick }, { children: "Manage Theme" }), void 0)) : null] }), void 0)] }), void 0), _jsx(DropdownField, __assign({ name: "partition", label: "Default Day Part", placeholder: "Select a Daypart", value: (_d = (_c = partitions.find(function (p) { return compareAsString(p.value, partition !== null && partition !== void 0 ? partition : 0); })) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "", grow: true, hideSearch: true }, { children: function (search) {
                                    return partitions
                                        .filter(function (o) {
                                        return search.length === 0 ||
                                            o.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                                    })
                                        .map(function (o) { return (_jsx(DropDownItem, __assign({ onClick: function (e) {
                                            e.preventDefault();
                                            setValue("partition", o.value);
                                        }, selected: "" + partition === "" + o.value, item: { label: o.name, id: "" + o.value } }, { children: o.name }), o.value)); });
                                } }), void 0), _jsxs(Fields, __assign({ nested: true }, { children: [_jsxs(ControlGroup, __assign({ name: "interruptedByWeekends", label: "Run on Weekends", selected: interruptedByWeekends !== null && interruptedByWeekends !== void 0 ? interruptedByWeekends : "0" }, { children: [_jsx(Control, __assign({ value: "0" }, { children: _jsx(FontAwesomeIcon, { icon: faPlay }, void 0) }), void 0), _jsx(Control, __assign({ value: "1" }, { children: _jsx(FontAwesomeIcon, { icon: faStop }, void 0) }), void 0)] }), void 0), _jsxs(ControlGroup, __assign({ name: "interruptedByHolidays", label: "Run on Holidays", selected: interruptedByHolidays !== null && interruptedByHolidays !== void 0 ? interruptedByHolidays : "0" }, { children: [_jsx(Control, __assign({ value: "0" }, { children: _jsx(FontAwesomeIcon, { icon: faPlay }, void 0) }), void 0), _jsx(Control, __assign({ value: "1" }, { children: _jsx(FontAwesomeIcon, { icon: faStop }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx(TextAreaField, { name: "notes", label: "Notes", placeholder: "Any optional notes?", className: "flex-grow" }, void 0)] }), void 0), advancedMode ? (_jsx("span", {}, void 0)) : (_jsx(Button, __assign({ onClick: toggleAdvancedMode, appearance: "link", className: "-my-4" }, { children: "Additional Details" }), void 0)), _jsx("input", { type: "hidden", name: "startsAt", value: startsAt }, void 0), _jsx("input", { type: "hidden", name: "length", value: length }, void 0), _jsx(ErrorList, { errors: formErrors }, void 0), _jsx(Button, __assign({ type: "submit", className: "mt-2 w-full" }, { children: submitTitle !== null && submitTitle !== void 0 ? submitTitle : "Save Event" }), void 0), _jsx("input", { type: "hidden", name: "occursInSidebar" }, void 0)] }), void 0), loading ? _jsx(AbsoluteOverlay, {}, void 0) : null] }), void 0));
};
