import {
  CalendarToolBarItem,
  SnapshotToolBarItem,
  useSetWeekCanvasState,
  useWeekCanvasState,
} from "ui"
import { DateTime } from "luxon"

export const useItemSelectHandler = <
  T extends SnapshotToolBarItem | CalendarToolBarItem
>(
  handler: (item: T) => void
) => {
  const { anchorDate } = useWeekCanvasState()
  const setWeekCanvasState = useSetWeekCanvasState()

  return (item: T) => {
    const anchor = (anchorDate ?? DateTime.utc())
      ?.plus({ days: 1 })
      .endOf("week")
      .startOf("month")

    const setDate = (increment?: number) => {
      const newDate = increment
        ? anchor.plus({ months: increment })
        : DateTime.utc().startOf("month")
      setWeekCanvasState((state) => ({
        ...state,
        anchorDate: increment ? newDate : undefined,
        scrollCursorDate: increment ? newDate : DateTime.utc(),
      }))
    }

    switch (item) {
      case "today":
        setDate()
        break
      case "previous":
        setDate(-1)
        break
      case "next":
        setDate(1)
        break
      default:
        handler(item)
    }
  }
}
