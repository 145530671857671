import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { Placeholder } from "../../shared"
import { Button } from "ui"
import styles from "./OrganizationBlankSlateView.css"
import { VisibleOnlySuperAdmin, VisibleOnlyNonSuperAdmin } from "../../login"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBriefcase } from "@fortawesome/pro-solid-svg-icons"

class OrganizationBlankSlateView extends Component {
  componentWillMount() {
    const { acceptInvitation, pendingInviteOrg, pendingInviteToken } =
      this.props
    if (pendingInviteOrg && pendingInviteOrg.subdomain && pendingInviteToken) {
      acceptInvitation(pendingInviteOrg.subdomain, pendingInviteToken).then(
        (action) => {
          if (action.payload && action.payload.result > 0) {
            this.props.history.replace(
              `/org/${pendingInviteOrg.subdomain}/cal/`
            )
          } else {
            this.loadOrganization()
          }
          this.props.clearInvitation()
        }
      )
    } else {
      this.loadOrganization()
    }
  }

  loadOrganization() {
    const { loadOrg, history } = this.props
    loadOrg().then((org) => {
      if (org && org.subdomain) {
        history.replace(`/org/${org.subdomain}/cal/`)
      }
    })
  }

  render() {
    const { fetching } = this.props
    if (fetching) {
      return <Placeholder.Loading />
    }
    return this.renderBlankSlate()
  }

  renderBlankSlate() {
    const { Container } = Placeholder

    const AdminBlankSlate = () => (
      <VisibleOnlySuperAdmin>
        <div>
          <h1 className={styles.headline}>Create Your First Company</h1>
          <p className={styles.text}>
            There are currently no companies in the system. Create a company to
            get started.
          </p>
          <Button onClick={() => this.props.history.push("/org/new")}>
            Create Company
          </Button>
        </div>
      </VisibleOnlySuperAdmin>
    )

    const UserBlankSlate = () => (
      <VisibleOnlyNonSuperAdmin>
        <div>
          <h1 className={styles.headline}>You Need an Invite</h1>
          <p className={styles.text}>
            Your account does not have access to a company. Please contact your
            manager if you believe this is an error.
          </p>
        </div>
      </VisibleOnlyNonSuperAdmin>
    )

    return (
      <Container theme="modal">
        <FontAwesomeIcon icon={faBriefcase} size="4x" />
        <AdminBlankSlate />
        <UserBlankSlate />
      </Container>
    )
  }
}

OrganizationBlankSlateView.propTypes = {
  loadOrg: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  acceptInvitation: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  pendingInviteOrg: PropTypes.object,
  pendingInviteToken: PropTypes.string,
  clearInvitation: PropTypes.func.isRequired,
}

export default withRouter(OrganizationBlankSlateView)
