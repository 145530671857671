import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import { RecoilRoot } from "recoil"
import Routes from "./config/routes"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import "ui/lib/calendar-ui.css"
import "./containers/base.scss"
import { UiProvider } from "ui"

const Root: React.FC<{ store: any }> = ({ store }) => (
  <UiProvider backend={HTML5Backend}>
    <DndProvider backend={HTML5Backend}>
      <RecoilRoot>
        <Provider store={store}>
          <Router>
            <Routes />
          </Router>
        </Provider>
      </RecoilRoot>
    </DndProvider>
  </UiProvider>
)

export default Root
