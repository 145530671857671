import React from "react"
import PropTypes from "prop-types"
import styles from "./PlaceholderContainer.module.css"

const getStyleForTheme = (theme) => {
  switch (theme) {
    case "modal":
      return styles.modal
    default:
      return styles.content
  }
}

const Container = ({ children, theme }) => (
  <div className={styles.placeHolderContainer}>
    <div className={getStyleForTheme(theme)}>{children}</div>
  </div>
)

Container.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
}

export default Container
