import * as containers from "./containers"
import * as components from "./components"
import reducer from "./reducer"
import * as actionTypes from "./actionTypes"
import * as actions from "./actions"
import * as constants from "./constants"
import * as selectors from "./selectors"

const content = {
  components,
  reducer,
  actions,
  actionTypes,
  constants,
  selectors,
  containers,
}
export default content

export const { CalendarAccessWidget } = components

export const { Calendar, PrintableCalendar } = containers
export const { itemPreviewType } = selectors
export const CalendarItemTypes = constants.ItemTypes
