import { NAME } from "./constants"

export const BEGIN_NEW_ITEM_DRAG = `${NAME}/BEGIN_NEW_ITEM_DRAG`
export const END_NEW_ITEM_DRAG = `${NAME}/END_NEW_ITEM_DRAG`
export const SELECT_DATE = `${NAME}/SELECT_DATE`
export const SELECT_ITEM = `${NAME}/SELECT_ITEM`
export const APPEND_SELECT_ITEM = `${NAME}/APPEND_SELECT_ITEM`
export const RESET_SELECTED_ITEMS = `${NAME}/RESET_SELECTED_ITEMS`
export const CLEAR_CONTEXT = `${NAME}/CLEAR_CONTEXT`
export const UPDATE_LAST_SELECTED_ITEM = `${NAME}/UPDATE_LAST_SELECTED_ITEM`
export const SET_LAST_SELECTED_EVENT_DATE = `${NAME}/SET_LAST_SELECTED_EVENT_DATE`
export const UPDATE_MULTI_SELECT = `${NAME}/UPDATE_MULTI_SELECT`
