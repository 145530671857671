import { entities } from "./baseSelector"

/**
 * Returns all snapshot entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of calendar entities.
 */
export const all = (state) =>
  Object.keys(entities(state).snapshots)
    .map((key) => entities(state).snapshots[key])
    .sort((a, b) => a.name > b.name)
    .filter((entity) => !entity.isDeleted)

/**
 * Retrieves a specific snapshot object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching snapshot if one exists.
 */
export const find = (state) => (snapshotId) => {
  const entity = entities(state).snapshots[snapshotId]
  return entity && !entity.isDeleted && entity
}

/**
 * Returns a query function that fetches all snapshots for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of snapshots that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarId) => {
  return all(state).filter(
    (snapshot) => parseInt(snapshot.calendarId, 0) === parseInt(calendarId, 0)
  )
}
