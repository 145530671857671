import { NAME } from "./constants"

export const REQUEST_CONTEXT = `${NAME}/REQUEST_CONTEXT`
export const CLEAR_CONTEXT = `${NAME}/CLEAR_CONTEXT`
export const BEGIN_DRAG = `${NAME}/BEGIN_DRAG`
export const END_DRAG = `${NAME}/END_DRAG`
export const COPY_EVENTS = `${NAME}/COPY_EVENTS`
export const CUT_ITEMS = `${NAME}/CUT_ITEMS`
export const CLEAR_CLIPBOARD = `${NAME}/CLEAR_CLIPBOARD`
export const UPDATE_EVENT = `${NAME}/UPDATE_EVENT`
export const SHOW_ITEMS = `${NAME}/SHOW_ITEMS`
export const HIDE_ITEMS = `${NAME}/HIDE_ITEMS`
export const CHANGE_SEARCH_VALUE = `${NAME}/CHANGE_SEARCH_VALUE`
export const UPDATE_ITEM_PREVIEW = `${NAME}/UPDATE_ITEM_PREVIEW`
export const SET_DRAG_OFFSET = `${NAME}/SET_DRAG_OFFSET`
export const BEGIN_EVENT_EXPAND = `${NAME}/BEGIN_EVENT_EXPAND`
export const END_EVENT_EXPAND = `${NAME}/END_EVENT_EXPAND`
export const SHOW_SETTINGS_FOR_DATE = `${NAME}/SHOW_SETTINGS_FOR_DATE`
