import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Field } from "redux-form"
import { Button } from "ui"
import { VisibleOnlyManager } from "../../login"
import { Form, Indicator } from "../../shared"
import { submitValidate } from "../validators"
import { isDefined } from "../../utils/helpers"
import { colors } from "../../utils"
import { CalendarAccessWidget } from "../../calendarView"
import ScheduleListGroup from "./ScheduleListGroup.react"
import {
  faCalendarAlt,
  faCalendar,
  faPause,
  faPlay,
} from "@fortawesome/pro-regular-svg-icons"
import { faBan, faSortNumericDown } from "@fortawesome/pro-solid-svg-icons"
import { ConnectedGroupSelect } from "./ConnectedGroupSelect"
import toast from "react-hot-toast"
/**
 * Renders a form which allows the user to edit or create
 * a new calendar.
 */
class CalendarFormView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      templateCalendarIds: [null],
      groupUuid: null,
    }
  }

  componentWillMount() {
    // Add 'hidden' values to the redux form.
    this.props.change("organization", this.props.subdomain)
  }

  newCalendar() {
    const { initialValues } = this.props
    return !(initialValues && isDefined(initialValues.id))
  }

  submitForm(values) {
    const { postCalendar, updateCalendar, history, subdomain } = this.props
    const { groupUuid } = this.state
    const templateCalendarIds = this.state.templateCalendarIds.filter(
      (id, idx) => id && this.state.templateCalendarIds.indexOf(id) === idx
    )
    const dispatchAction = this.newCalendar() ? postCalendar : updateCalendar
    const calendar = { ...values, groupUuid, templateCalendarIds }
    return dispatchAction(subdomain, calendar)
      .then(submitValidate)
      .then((result) => {
        toast.success("Calendar saved successfully.")
        history.push(`/org/${subdomain}/cal/${result.payload.result}`)
      })
  }

  calendarOptions() {
    const { calendars } = this.props
    return (
      <>
        <option>Select a calendar</option>
        {calendars &&
          calendars.map((calendar) => (
            <option value={calendar.id} key={calendar.id}>
              {calendar.name}
            </option>
          ))}
      </>
    )
  }

  snapshotOptions() {
    const { snapshots } = this.props
    return (
      snapshots &&
      snapshots.map((snapshot) => (
        <option value={snapshot.id} key={snapshot.id}>
          {snapshot.name} ({moment.utc(snapshot.createdAt).format("MM/DD/YYYY")}
          )
        </option>
      ))
    )
  }

  setManagerAccessToPermission(permission) {
    this.props.persistPermission({
      ...permission,
      read: true,
      write: true,
      manage: true,
    })
  }

  setWriteAccessToPermission(permission) {
    this.props.persistPermission({
      ...permission,
      read: true,
      write: true,
      manage: false,
    })
  }

  setReadOnlyAccessToPermission(permission) {
    this.props.persistPermission({
      ...permission,
      read: true,
      write: false,
      manage: false,
    })
  }

  disablePermission(permission) {
    this.props.persistPermission({
      ...permission,
      read: false,
      write: false,
      manage: false,
    })
  }

  render() {
    const { submitting, activeField, handleSubmit, isNew, initialValues } =
      this.props
    const { subdomain, calendarId } = this.props.match.params

    return (
      <form onSubmit={handleSubmit((values) => this.submitForm(values))}>
        <Form.Grid>
          <Form.Heading name="Calendar Settings" />
          <Form.Divider />
          <br />
          <Field
            component={Form.Field}
            name="name"
            label="Calendar Name"
            type="text"
            assistantText="The name of this calendar."
            customizations={{
              disabled: submitting,
              placeholder: "Calendar Name",
              tabIndex: "1",
            }}
            active={activeField === "name"}
          />

          <Form.GridRow>
            <Form.FieldCol>
              <label htmlFor="groupUuid" className="font-bold text-md">
                Group
              </label>
              <ConnectedGroupSelect
                subdomain={subdomain}
                name="groupUuid"
                initialValue={initialValues ? initialValues.groupUuid : null}
                onSelect={(uuid) =>
                  this.setState({ ...this.state, groupUuid: uuid })
                }
              />
            </Form.FieldCol>
            <Form.AssistantCol>
              <Form.AssistantText active={activeField !== "groupUuid"}>
                The folder the calendar will be organized in within the sidebar.
              </Form.AssistantText>
            </Form.AssistantCol>
          </Form.GridRow>

          {isNew && (
            <div className="flex flex-col mb-2" style={{ maxWidth: 431 }}>
              <label className="font-bold">Add Source Calendar(s)</label>
              {this.state.templateCalendarIds.map((id, idx) => (
                <select
                  className="rounded border py-2 px-3 mb-2"
                  name={`templateId${idx}`}
                  onChange={(e) => {
                    const templateCalendarIds = [
                      ...this.state.templateCalendarIds,
                    ]
                    templateCalendarIds.splice(
                      idx,
                      1,
                      parseInt(e.target.value, 0)
                    )
                    this.setState({
                      templateCalendarIds,
                    })
                  }}
                >
                  {this.calendarOptions()}
                </select>
              ))}
              <button
                className="text-sorbus-default font-bold text-sm text-left"
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    templateCalendarIds: [
                      ...this.state.templateCalendarIds,
                      null,
                    ],
                  })
                }}
              >
                Add an additional calendar to merge
              </button>
            </div>
          )}

          {isNew && this.state.templateCalendarIds.filter((c) => c).length > 0 && (
            <Form.GridRow>
              <Form.FieldCol>
                <Form.RadioGroup>
                  <Field
                    name="includeEvents"
                    component={Form.RadioField}
                    value={true}
                    type="radio"
                    label="Include Events"
                    icon={faCalendarAlt}
                    disabled={submitting}
                    description="The events and sidebar contents from the selected template will be copied to the actual calendar."
                  />
                  <Field
                    name="includeEvents"
                    component={Form.RadioField}
                    label="Copy Sidebar Template Only"
                    type="radio"
                    value={false}
                    icon={faCalendar}
                    disabled={submitting}
                    description="Only copy the contents of the sidebar's categories & events. The actual calendar will be a blank slate."
                  />
                </Form.RadioGroup>
              </Form.FieldCol>
            </Form.GridRow>
          )}

          <Form.Collapsable name="Appearance">
            <Field
              component={Form.Field}
              name="weekendColor"
              label="Weekend Cell Color"
              type="color"
              assistantText="The background color used to fill in weekend cells."
              customizations={{
                disabled: submitting,
                colorOptions: Object.values(colors),
              }}
              active={activeField === "weekendColor"}
            />
            <Field
              component={Form.Field}
              name="holidayColor"
              label="Holiday Cell Color"
              type="color"
              assistantText="The background color used to fill in holiday cells."
              customizations={{
                disabled: submitting,
                colorOptions: Object.values(colors),
              }}
              active={activeField === "holidayColor"}
            />
            <Field
              component={Form.Field}
              name="cutoffColor"
              label="External Day Cell Color"
              type="color"
              assistantText="The background color of days that are not in the current month being viewed."
              customizations={{
                disabled: submitting,
                colorOptions: Object.values(colors),
              }}
              active={activeField === "cutoffColor"}
            />
          </Form.Collapsable>

          <Form.Collapsable name="Week Numbers">
            <Form.GridRow>
              <Form.FieldCol>
                <Form.RadioGroup>
                  <Field
                    name="showWeekNumbers"
                    component={Form.RadioField}
                    value={"none"}
                    type="radio"
                    label="Disable Counting"
                    icon={faBan}
                    disabled={submitting}
                    description="Do not auto generate week numbers."
                  />
                  <Field
                    name="showWeekNumbers"
                    component={Form.RadioField}
                    label="Week of Year"
                    type="radio"
                    value={"year"}
                    icon={faCalendar}
                    disabled={submitting}
                    description="Render 1-52 depicting the week of the year."
                  />
                  <Field
                    name="showWeekNumbers"
                    component={Form.RadioField}
                    label="Week of Project"
                    type="radio"
                    value={"calendar_start"}
                    icon={faSortNumericDown}
                    disabled={submitting}
                    description="Only render counts for the duration of the project."
                  />
                </Form.RadioGroup>
              </Form.FieldCol>
              <Form.AssistantCol>
                <Form.AssistantText active={activeField !== "weekendColor"}>
                  ProCal can automatically label weeks with a count for you. You
                  can choose to have every week (1-52) of the year labeled or
                  just the weeks counting the duration of your project.
                </Form.AssistantText>
              </Form.AssistantCol>
            </Form.GridRow>
          </Form.Collapsable>

          <Form.Collapsable name="Weekends">
            <Form.GridRow>
              <Form.FieldCol>
                <Form.RadioGroup>
                  <Field
                    name="interruptedByWeekends"
                    component={Form.RadioField}
                    value={true}
                    type="radio"
                    label="Interrupt During Weekends"
                    icon={faPause}
                    disabled={submitting}
                    description="Events will be interrupted during weekends."
                  />
                  <Field
                    name="interruptedByWeekends"
                    component={Form.RadioField}
                    label="Run During Weekends"
                    type="radio"
                    value={false}
                    icon={faPlay}
                    disabled={submitting}
                    description="Events that occur during weekends will continue without interruption."
                  />
                </Form.RadioGroup>
              </Form.FieldCol>
              <Form.AssistantCol>
                <Form.AssistantText active={activeField !== "weekendColor"}>
                  This is useful functionality depending on if there will be
                  work activity on the show during weekends. Settings can be
                  changed at any time.
                </Form.AssistantText>
              </Form.AssistantCol>
            </Form.GridRow>
          </Form.Collapsable>

          <Form.Collapsable name="Holidays">
            <Form.GridRow>
              <Form.FieldCol>
                <Form.RadioGroup>
                  <Field
                    name="interruptedByHolidays"
                    component={Form.RadioField}
                    value={true}
                    type="radio"
                    label="Interrupt for Holidays"
                    icon={faPause}
                    disabled={submitting}
                    description="Events will be interrupted during holidays."
                  />
                  <Field
                    name="interruptedByHolidays"
                    component={Form.RadioField}
                    label="Run During Holidays"
                    type="radio"
                    value={false}
                    icon={faPlay}
                    disabled={submitting}
                    description="Events that occur during holidays will continue without interruption."
                  />
                </Form.RadioGroup>
              </Form.FieldCol>
              <Form.AssistantCol>
                <Form.AssistantText active={activeField !== "holidayColor"}>
                  This is useful functionality depending on if there will be
                  work activity on the show during holidays. Settings can be
                  changed at any time.
                </Form.AssistantText>
              </Form.AssistantCol>
            </Form.GridRow>
            <br />
            <Field
              component={ScheduleListGroup}
              name="holidaySchedules"
              label="Holiday Schedule"
              customizations={{
                disabled: submitting,
              }}
              holidaySchedules={this.props.holidaySchedules}
              active={activeField === "generateUsHolidays"}
            />
          </Form.Collapsable>

          <Form.Collapsable name="Print Headers">
            <Field
              component={Form.Field}
              name="printSettings.topLeft"
              label="Top Left"
              type="text"
              assistantText="Optional text for a default top left heading when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) top left heading",
                tabIndex: "6",
              }}
              active={activeField === "printSettings.topLeft"}
            />
            <Field
              component={Form.Field}
              name="printSettings.topCenter"
              label="Top Center"
              type="text"
              assistantText="Optional text for a default top center heading when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) top center heading",
                tabIndex: "7",
              }}
              active={activeField === "printSettings.topCenter"}
            />
            <Field
              component={Form.Field}
              name="printSettings.topRight"
              label="Top Right"
              type="text"
              assistantText="Optional text for a default top right heading when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) top right heading",
                tabIndex: "8",
              }}
              active={activeField === "printSettings.topRight"}
            />
          </Form.Collapsable>

          <Form.Collapsable name="Print Footers">
            <Field
              component={Form.Field}
              name="printSettings.bottomLeft"
              label="Bottom Left"
              type="text"
              assistantText="Optional text for a default bottom left footing when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) bottom left footing",
                tabIndex: "9",
              }}
              active={activeField === "printSettings.bottomLeft"}
            />
            <Field
              component={Form.Field}
              name="printSettings.bottomCenter"
              label="Bottom Center"
              type="text"
              assistantText="Optional text for a default bottom center footing when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) bottom center footing",
                tabIndex: "10",
              }}
              active={activeField === "printSettings.bottomCenter"}
            />
            <Field
              component={Form.Field}
              name="printSettings.bottomRight"
              label="Bottom Right"
              type="text"
              assistantText="Optional text for a default bottom right footing when printing. Text can be added/changed at print options as well."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) bottom right footing",
                tabIndex: "11",
              }}
              active={activeField === "printSettings.bottomRight"}
            />
          </Form.Collapsable>
          <VisibleOnlyManager subdomain={subdomain} calendarId={calendarId}>
            {isNew ? null : (
              <Form.Collapsable name="Manage Access">
                <Form.GridRow>
                  <Form.FieldCol>
                    <CalendarAccessWidget
                      subdomain={subdomain}
                      calendarId={calendarId}
                    />
                  </Form.FieldCol>
                </Form.GridRow>
              </Form.Collapsable>
            )}
          </VisibleOnlyManager>

          {!isNew && (
            <Field
              component={Form.Field}
              name="generateSnapshot"
              label="Backup Your Calendar"
              type="checkbox"
              checkboxText="Generate a backup before making these changes?"
              assistantText="Changing settings related to holidays and weekends can cause
                  unexpected results to occur on your calendar as events may be
                  pushed or adjusted to accomodate for the changes in
                  interruptions."
              customizations={{
                disabled: submitting,
                placeholder: "(optional) top left heading",
                tabIndex: "8",
              }}
              active={activeField === "snapshot"}
            />
          )}
          <Form.GridRow>
            <Form.FieldCol>
              <Button
                type="submit"
                disabled={submitting}
                className="w-full"
                align="stretch"
              >
                <div className="flex m-auto">
                  {submitting && (
                    <Indicator.Ring theme={"button"} className="my-auto" />
                  )}
                  <span className="my-auto mx-auto">
                    {this.newCalendar() ? "Create" : "Update"} Calendar
                  </span>
                </div>
              </Button>
            </Form.FieldCol>
          </Form.GridRow>
        </Form.Grid>
      </form>
    )
  }
}

CalendarFormView.propTypes = {
  /**
   * The default values which should be used to populateView
   * the form. The keys are mapped to the respective FieldCol
   * names in the form.
   */
  initialValues: PropTypes.object,

  /**
   * The current user logged in to the ProCal.
   */
  currentUser: PropTypes.object,

  /**
   * The subdomain of the organization the calendar will or
   * does belong to.
   */
  subdomain: PropTypes.string,

  /**
   * The current startDate value applied to the calendar/form.
   */
  startDate: PropTypes.string,

  /**
   * The current endDate value applied to the calendar/form.
   */
  endDate: PropTypes.string,

  /**
   * A boolean flag indicating whether or not the form is currently
   * submitting an asynchronous action.
   */
  submitting: PropTypes.bool,

  /**
   * An action creator to change a bound value for the form in the
   * redux store.
   */
  change: PropTypes.func.isRequired,

  /**
   * An array of possible group names for the group autocomplete.
   */
  suggestedGroupNames: PropTypes.func,

  /**
   * The name of the field currently in focus.
   */
  activeField: PropTypes.string,

  /**
   * An action creator mapped to dispatch which is used to post
   * the contents of the form to the calendars#create action in
   * the API.
   */
  postCalendar: PropTypes.func.isRequired,

  /**
   * An action creator mapped to dispatch which is used to post
   * the contents of the form to the calendars#update action in
   * the API.
   */
  updateCalendar: PropTypes.func.isRequired,

  /**
   * An event handler to process the form values and pass them
   * on to any dispatched actions or validations.
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which is intended
   * to push a new URL to the HTML5 history.
   */
  history: PropTypes.func.isRequired,

  /**
   * Indicates whether any fields which are only pertinent to the
   * creation of a new calendar should be visible.
   */
  isNew: PropTypes.bool.isRequired,

  /**
   * Available calendars to utilize as templates.
   */
  calendars: PropTypes.arrayOf(PropTypes.object),

  /**
   * Any react router provided location match.
   */
  match: PropTypes.object,

  /**
   * Available snapshots to utilize as templates.
   */
  snapshots: PropTypes.arrayOf(PropTypes.object),

  /**
   * An optional value representing the current template calendar ID.
   */
  templateCalendarId: PropTypes.string,

  /**
   * Retrieves a specific invitation for a given organization.
   */
  findInvitation: PropTypes.func.isRequired,

  /**
   * Indicates the permission system used within the organization.
   */
  usesGranularPermissions: PropTypes.bool,

  /**
   * A list of all available holiday schedules.
   */
  holidaySchedules: PropTypes.arrayOf(PropTypes.object),
}

export default CalendarFormView
