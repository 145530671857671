import React from "react"
import styles from "./Drawer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"

const ignoreClick: React.MouseEventHandler = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

export interface DrawerProps {
  show: boolean
  title?: string
  onClose?: () => void
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  show,
  title,
  onClose,
}) => {
  const handleCloseClick: React.MouseEventHandler = (e) => {
    ignoreClick(e)
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={`${styles.container} border-l border-gray-300 z-50 ${
        show && styles.in
      }`}
      onClick={onClose}
    >
      <div
        className={`${styles.content} ${show && styles.in}`}
        onClick={ignoreClick}
      >
        {title && (
          <div
            className="flex border-b border-gray-400 bg-white"
            style={{ height: "54px" }}
          >
            <h1 className="pl-4 py-2 font-semibold my-auto flex-grow">
              {title}
            </h1>
            <button className={styles.close} onClick={handleCloseClick}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
