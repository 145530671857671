import React from "react"
import PropTypes from "prop-types"
import Dialog from "./Dialog.react"
import Header from "./Header.react"
import ModalTitle from "./ModalTitle.react"
import styles from "./Modal.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dragging: false,
      originX: 0,
      originY: 0,
      translateX: 0,
      translateY: 0,
      onMouseMove: null,
      onMouseUp: null,
    }
    this.dialog = null
  }

  startDrag(e) {
    e.preventDefault()
    const onMouseMove = (ev) => this.handleDrag(ev)
    const onMouseUp = (ev) => this.endDrag(ev)
    this.setState({
      dragging: true,
      originX: e.pageX,
      originY: e.pageY,
      onMouseMove,
      onMouseUp,
    })
    window.addEventListener("mousemove", onMouseMove)
    window.addEventListener("mouseup", onMouseUp)
  }

  endDrag() {
    this.setState({
      dragging: false,
      originX: null,
      originY: null,
    })
    window.removeEventListener("mousemove", this.state.onMouseMove)
    window.removeEventListener("mouseup", this.state.onMouseUp)
  }

  handleDrag(e) {
    e.preventDefault()
    if (this.state.dragging && this.state.originX && this.state.originY) {
      const minX = -this.dialog.offsetLeft - this.dialog.offsetWidth / 2
      const minY = -this.dialog.offsetTop
      const maxY =
        window.innerHeight -
        this.dialog.offsetHeight / 2 +
        this.dialog.offsetTop
      const maxX =
        window.innerWidth - this.dialog.offsetWidth / 2 - this.dialog.offsetLeft
      const x = Math.min(
        maxX,
        Math.max(minX, e.pageX - this.state.originX + this.state.translateX)
      )
      const y = Math.min(
        maxY,
        Math.max(minY, e.pageY - this.state.originY + this.state.translateY)
      )
      this.setState({
        transform: `translate(${x}px, ${y}px)`,
        originX: e.pageX,
        originY: e.pageY,
        translateX: x,
        translateY: y,
      })
    }
  }

  render() {
    const { children, title, returnTo, draggable, extended, disableScrolling } =
      this.props
    return (
      <Dialog
        fadeIn={true}
        extended={extended}
        disableScrolling={disableScrolling}
        style={{
          transform: this.state.transform,
        }}
        innerRef={(dialog) => {
          this.dialog = dialog
        }}
      >
        <Header
          onMouseDown={(e) => draggable && this.startDrag(e)}
          draggable={draggable}
        >
          <ModalTitle title={`${title}`} />
          <button onClick={() => returnTo()} className={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Header>
        {children}
      </Dialog>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  draggable: PropTypes.bool,
  disableScrolling: PropTypes.bool,
  extended: PropTypes.bool,
  returnTo: PropTypes.func.isRequired,
}

export default Modal
