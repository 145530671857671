import React from "react"
import PropTypes from "prop-types"
import styles from "./Container.module.css"

const getStyles = (direction, align) => {
  const classNames = [styles.container]
  if (direction === "column") {
    classNames.push(styles.containerColumn)
  }
  if (align === "start") {
    classNames.push(styles.containerStart)
  }
  if (align === "end") {
    classNames.push(styles.containerEnd)
  }
  return classNames.join(" ")
}

const Container = ({ children, direction, alignItems }) => (
  <div className={`${getStyles(direction, alignItems)}`}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  alignItems: PropTypes.string,
}

export default Container
