import React, { useEffect, useState } from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { authSelector, useHasCreatorPrivilege } from "../api"

export const currentUser = authSelector.currentUser

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useSelector(currentUser)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

// A wrapper for <Route> that redirects any user to the
// app navigator's root if they are already signed in.
export const UnauthenticatedRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useSelector(currentUser)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/org",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const MemoizedVisibility = ({ visible, children }) => {
  const [currentlyVisible, setCurrentlyVisible] = useState(visible)
  useEffect(() => {
    if (currentlyVisible !== visible) {
      setCurrentlyVisible(visible)
    }
  }, [visible, currentlyVisible, setCurrentlyVisible])
  return currentlyVisible ? children : null
}

export const VisibleOnlyAdmin = ({ subdomain, children }) => {
  const isAdmin = useSelector(
    (state) =>
      currentUser(state).superAdmin || currentUser(state).admin(subdomain)
  )
  return <MemoizedVisibility visible={isAdmin}>{children}</MemoizedVisibility>
}

export const VisibleOnlyNonSuperAdmin = ({ children }) => {
  const isAdmin = useSelector((state) => currentUser(state).superAdmin)
  return !isAdmin ? children : null
}

export const VisibleOnlyNonAdmin = ({ subdomain, children }) => {
  const isNotAdmin = useSelector(
    (state) =>
      !currentUser(state).superAdmin && !currentUser(state).admin(subdomain)
  )
  return (
    <MemoizedVisibility visible={isNotAdmin}>{children}</MemoizedVisibility>
  )
}

export const VisibleOnlyCreator = ({ subdomain, children }) => {
  const isCreator = useHasCreatorPrivilege(subdomain)
  return <MemoizedVisibility visible={isCreator}>{children}</MemoizedVisibility>
}

export const VisibleOnlyNonCreator = ({ subdomain, children }) => {
  const isNotCreator = useSelector(
    (state) =>
      !currentUser(state).superAdmin &&
      !currentUser(state).admin(subdomain) &&
      !currentUser(state).creator(subdomain)
  )
  return (
    <MemoizedVisibility visible={isNotCreator}>{children}</MemoizedVisibility>
  )
}

export const VisibleOnlyManager = ({ subdomain, calendarId, children }) => {
  const isManager = useSelector(
    (state) =>
      currentUser(state).superAdmin ||
      currentUser(state).admin(subdomain) ||
      currentUser(state).canManage(subdomain, calendarId)
  )
  return <MemoizedVisibility visible={isManager}>{children}</MemoizedVisibility>
}

export const VisibleOnlySuperAdmin = ({ children }) => {
  const isSuperAdmin = useSelector((state) => currentUser(state).superAdmin)
  return (
    <MemoizedVisibility visible={isSuperAdmin}>{children}</MemoizedVisibility>
  )
}
