import React, { useState, useRef, useEffect } from "react"
import {
  Modal,
  ModalProps,
  SetKeeperConnectForm,
  SetKeeperConnectFormValues,
  AsyncSubmitHandler,
  Indicators,
  Button,
} from "ui"
import toast from "react-hot-toast"
import { wait } from "utils"
import setKeeperLogo from "../../images/setkeeper.png"
import {
  useConnections,
  useCreateSetKeeperConnection,
  useCreateSetKeeperCalendarConnection,
  useDeleteConnection,
  useEntity,
  ConnectionResponse,
} from "../../api"
import { SetKeeperProjectSelectField } from "./SetKeeperProjectSelectField"
import { SetKeeperCalendarSelectField } from "./SetKeeperCalendarSelectField"

export interface SetKeeperConnectModalProps {
  subdomain: string
  calendarId: number
  initialValues?: SetKeeperConnectFormValues
}

const connectionsEnabled = false

export const SetKeeperConnectModal: React.FC<
  SetKeeperConnectModalProps & Omit<ModalProps, "title">
> = ({
  onClose: handleDismiss,
  subdomain,
  onFocus: handleFocus,
  zIndex,
  initialValues,
  calendarId,
  ...props
}) => {
  const [skProjectId, setSkProjectId] = useState<null | string | undefined>()
  const [skCalendarId, setSkCalendarId] = useState<null | string | undefined>()
  const [loading, setLoading] = useState(false)
  const connections = useEntity<ConnectionResponse>({
    entity: "connections",
    filter: { name: "SetKeeper" },
  })
  const userConnection = [connections].flat().find((c) => !c.calendarId)
  const calendarConnection = [connections]
    .flat()
    .find((c) => c.calendarId === calendarId)
  const [requestConnection, { loading: loadingConnections }] = useConnections()
  const [createSetKeeperConnection] = useCreateSetKeeperConnection()
  const [createSetKeeperCalendarConnection] =
    useCreateSetKeeperCalendarConnection()
  const [deleteConnection] = useDeleteConnection()

  const executed = useRef(false)
  useEffect(() => {
    if (!executed.current) {
      requestConnection({ body: { name: "SetKeeper" } })
      requestConnection({
        body: { name: "SetKeeper", calendarId: `${calendarId}` },
      })
      executed.current = true
    }
  })

  const onSubmit: AsyncSubmitHandler<SetKeeperConnectFormValues> = async ({
    ...data
  }: SetKeeperConnectFormValues) => {
    setLoading(true)
    await wait(1)
    try {
      const response = await createSetKeeperConnection({
        body: {
          ...data,
        },
      })
      setLoading(false)
      if (response?.error) {
        toast.error(
          "Error connecting to SetKeeper. Double check your login credentials and try again."
        )
      } else {
        toast.success(`Successfully connected to SetKeeper!`)
      }
    } catch (e) {
      console.error(e)
    }
    return undefined
  }

  const handleCalendarConnect = async () => {
    setLoading(true)
    try {
      await createSetKeeperCalendarConnection({
        body: {
          calendarId,
          setKeeperProjectId: skProjectId as string,
          setKeeperCalendarId: skCalendarId as string,
        },
      })
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const handleDisconnect = (connectionId?: number) => async () => {
    setLoading(true)
    if (!connectionId) {
      return
    }
    if (connectionId) {
      await deleteConnection({
        params: {
          connectionId: `${connectionId}`,
        },
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!skProjectId && skCalendarId) {
      setSkCalendarId(undefined)
    }
  }, [skProjectId, skCalendarId, setSkCalendarId])

  return (
    <Modal
      {...props}
      title={userConnection ? "Sync to SetKeeper" : "Connect to SetKeeper"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
    >
      {loadingConnections || loading ? <Indicators.AbsoluteOverlay /> : null}
      {calendarConnection && (
        <article className="px-2">
          <header>
            <img
              src={setKeeperLogo}
              className="w-80 h-auto mx-auto my-4"
              alt="SetKeeper"
            />
          </header>
          <p>
            Currently connected to SetKeeper project:
            <br />
            <span className="font-bold">
              {calendarConnection.setKeeperProjectName ??
                `ID: ${calendarConnection.setKeeperProjectId}`}
            </span>{" "}
          </p>
          <p className="mt-4">
            SetKeeper calendar:
            <br />
            <span className="font-bold">
              {calendarConnection.setKeeperCalendarName ??
                `ID: ${calendarConnection.setKeeperCalendarId}`}
            </span>
          </p>
          <footer className="mt-8 text-sm border-t border-gray-300 p-2">
            <p>
              If you no longer wish to perform any syncing between SetKeeper and
              ProCal you may:
            </p>
            <Button
              onClick={handleDisconnect(calendarConnection.id)}
              className="mt-2 font-bold"
              appearance="link"
            >
              Disconnect This Calendar From SetKeeper
            </Button>
          </footer>
        </article>
      )}
      {userConnection && !calendarConnection && (
        <article className="px-2">
          <header>
            <img
              src={setKeeperLogo}
              className="w-80 h-auto mx-auto my-4"
              alt="SetKeeper"
            />
          </header>
          <SetKeeperProjectSelectField
            value={skProjectId}
            onSelect={setSkProjectId}
            className="mt-4"
          />
          {skProjectId && (
            <>
              <SetKeeperCalendarSelectField
                value={skCalendarId}
                onSelect={setSkCalendarId}
                projectId={skProjectId}
                className="mt-4"
              />
            </>
          )}
          <Button
            className="mt-4 font-bold"
            align="stretch"
            disabled={!skCalendarId}
            onClick={handleCalendarConnect}
          >
            Sync with SetKeeper
          </Button>
          <footer className="mt-8 text-sm">
            <p>
              If you no longer wish to perform any syncing between SetKeeper and
              ProCal you may:
            </p>
            <Button
              onClick={handleDisconnect(userConnection.id)}
              className="mt-2 font-bold"
              appearance="link"
            >
              Log Out Disconnect from SetKeeper
            </Button>
          </footer>
        </article>
      )}
      {!userConnection && connectionsEnabled && (
        <>
          <header className="px-2">
            <img
              src={setKeeperLogo}
              className="w-80 h-auto mx-auto my-4"
              alt="SetKeeper"
            />
            <p className="my-2 text-sm">
              Connect ProCal to your SetKeeper account to sync your calendar to
              a project within SetKeeper.
            </p>
            <strong className="font-bold block text-sm mb-6">
              <a
                href="https://setkeeper.com"
                target="_blank"
                rel="noreferrer"
                className="text-lochivar-default"
              >
                Learn more about SetKeeper .{" "}
              </a>
            </strong>
          </header>
          <SetKeeperConnectForm onSubmit={onSubmit} />
        </>
      )}
      {!connectionsEnabled && (
        <>
          <header className="px-2">
            <img
              src={setKeeperLogo}
              className="w-80 h-auto mx-auto my-4"
              alt="SetKeeper"
            />
            <p className="my-2 text-sm">
              Connect ProCal to your SetKeeper account to sync your calendar to
              a project within SetKeeper. SetKeeper can render the contents of
              your projects on ProCal in the SetKeeper schedule view.
            </p>
            <p className="my-2 text-sm">
              To enable this feature, please contact SetKeeper's support team to
              perform this connection.
            </p>
            <Button
              className="my-2"
              onClick={() => {
                window.location.href = "mailto:support@setkeeper.com"
              }}
            >
              Contact SetKeeper Support
            </Button>
            <strong className="font-bold block text-sm mb-6">
              <a
                href="https://setkeeper.com"
                target="_blank"
                rel="noreferrer"
                className="text-lochivar-default"
              >
                Learn more about SetKeeper .{" "}
              </a>
            </strong>
          </header>
        </>
      )}
    </Modal>
  )
}
