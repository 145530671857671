import React, { useState } from "react"
import { StyleForm, StyleFormValues, AsyncSubmitHandler } from "ui"
import toast from "react-hot-toast"
import { useStyle, createStyle, updateStyle, useStyles } from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"

export interface ConnectedStyleFormProps {
  styleUuid?: string
  onSave?: () => void
}

export const ConnectedStyleForm: React.FC<ConnectedStyleFormProps> = ({
  styleUuid,
  onSave,
}) => {
  const [loading, setLoading] = useState(false)

  const params = useParams<{ subdomain: string; calendarId: string }>()
  const { styles } = useStyles(params.subdomain, params.calendarId)
  const { loading: loadingStyle, style } = useStyle(
    params.subdomain,
    params.calendarId,
    styleUuid ?? ""
  )

  const colors = styles
    .reduce(
      (p: string[], style: any) => [
        ...p,
        style.textColor,
        style.fillColor,
        style.outlineColor,
      ],
      []
    )
    .filter(
      (color: string, i: number, self: string[]) =>
        color && self.indexOf(color) === i
    )
    .sort()

  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<StyleFormValues> = async (data) => {
    const { fontStyle, ...formData } = data
    setLoading(true)
    await wait(1)

    const values = {
      ...formData,
      fontStyle: fontStyle.includes("italic") ? "italic" : "normal",
      fontWeight: fontStyle.includes("bold") ? "bold" : "normal",
      textDecoration:
        data.fontStyle.indexOf("underline") > -1 ? "underline" : "none",
    }
    try {
      const response = await dispatch(
        styleUuid
          ? updateStyle(params.subdomain, params.calendarId, {
              ...values,
              uuid: styleUuid,
            })
          : createStyle(params.subdomain, params.calendarId, values)
      )
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(
        `Successfully ${style ? "updated" : "created"} Style "${data.name}".`
      )
      onSave?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return
  }

  return (
    <StyleForm
      loading={loading || loadingStyle}
      onSubmit={onSubmit}
      defaultValues={{
        ...style,
        borderRadius: style.borderRadius ?? "round",
        fontStyle: [style.textDecoration, style.fontStyle, style.fontWeight],
      }}
      presetColorOptions={colors.length ? colors : undefined}
    />
  )
}
