import React from "react"
import PropTypes from "prop-types"
import { FluidContainer, Row } from "../Grid"
import styles from "./Card.module.css"
import gridStyles from "../../utils/gridStyles"

const Card = ({ children }) => (
  <FluidContainer>
    <Row>
      <div className={gridStyles("col-xs-12")}>
        <div className={styles.card}>{children}</div>
      </div>
    </Row>
  </FluidContainer>
)

Card.propTypes = {
  children: PropTypes.node,
}

export default Card
