import { entities } from "./baseSelector"

const DEFAULT_STYLE = {
  outlineColor: "#000",
  fillColor: "#fff",
  textColor: "#000",
  textAlignment: "left",
  textDecoration: "none",
  font: "Default",
  fontSize: 12,
  fontWeight: "normal",
  fontStyle: "normal",
}

/**
 * Returns all style entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of item entities.
 */
export const all = (state) =>
  Object.keys(entities(state).styles)
    .map((key) => entities(state).styles[key])
    .filter((entity) => !entity.isDeleted)

/**
 * Retrieves all styles belonging to a specific calendar.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching item if one exists.
 */
export const forCalendar = (state) => (calendarId) =>
  Object.values(entities(state).styles).filter(
    (s) => s.calendarId === parseInt(`${calendarId}`)
  )

/**
 * Retrieves a specific style object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching item if one exists.
 */
export const find = (state) => (styleUuid, calendarId) => {
  const hashId = [styleUuid, calendarId].filter((i) => i).join("#")
  const entity = entities(state).styles[hashId]
  return (entity && !entity.isDeleted && entity) || DEFAULT_STYLE
}
