import { getCookie, setCookie } from "react-simple-cookie-store"

/**
 * The name of the cookie storing the current access token.
 */
export const ACCESS_TOKEN = "accessToken"

/**
 * The name of the cookie storing the refresh token.
 */
export const REFRESH_TOKEN = "refreshToken"

/**
 * The name of the cookie storing the current user's email address.
 */
export const EMAIL = "sessionEmail"

/**
 * A convenience handler for fetching the current access token.
 * @returns {Function} A function returning the current access token string.
 */
export const getAccessToken = () => getCookie(ACCESS_TOKEN)

/**
 * A convenience handler for fetching the current refresh token.
 * @returns {Function} A function returning the current refresh token string.
 */
export const getRefreshToken = () => getCookie(REFRESH_TOKEN)

/**
 * A convenience handler for fetching the current user's email address.
 * @returns {Function} A function returning the current refresh token string.
 */
export const getEmail = () => getCookie(EMAIL)

/**
 * A convenience handler for fetching the setting the current refresh token.
 * @param {String} refreshToken The updated refresh token.
 * @returns {Boolean} True if the action was successful.
 */
export const setRefreshToken = (refreshToken) =>
  setCookie(REFRESH_TOKEN, refreshToken, 2)

/**
 * A convenience handler for fetching the setting the current access token.
 * @param {String} accessToken The updated access token.
 * @returns {Boolean} True if the action was successful.
 */
export const setAccessToken = (accessToken) =>
  setCookie(ACCESS_TOKEN, accessToken, 2)

/**
 * A convenience handler for fetching the email used for the current login.
 * @param {String} email The most recent email address used for sign in.
 * @returns {Boolean} True if the action was successful.
 */
export const setEmail = (email) => setCookie(EMAIL, email, 7)
