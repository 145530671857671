import { useDeleteEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { PermissionResponse } from "./types"

export interface DeletePermissionParams extends RequestParams {
  permissionId: string
  invitationId: string
}

export const useDeletePermission = (subdomain: string) => {
  const updateEntity = useDeleteEntity()
  const deletePermission = useApiRequest<
    PermissionResponse,
    undefined,
    DeletePermissionParams
  >({
    method: "DELETE",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/invitations/:invitationId/granular_permissions/:permissionId`,
    beforeRequest: (params) => {
      updateEntity("permissions", parseInt(params?.permissionId ?? "0"))
    },
  })
  return deletePermission
}
