import { combineReducers } from "redux"
import * as t from "./actionTypes"
import { isRealProp } from "../utils/helpers"

function currentSection(state = "", action) {
  switch (action.type) {
    case t.CHANGE_SECTION:
      return isRealProp(action.section, state)
    default:
      return state
  }
}

export default combineReducers({
  currentSection,
})
