import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { useActiveOrgsAnalytics } from "../api"
import { Button } from "ui"
import { Panel } from "./Panel"
import { Title } from "./Title"
import { Results } from "./Resullts"
import { StylableProps } from "./Styleable"

interface ActiveOrgsPanelProps {
  maxResults?: number
  days?: number
}
export const ActiveOrgsPanel: React.FC<StylableProps & ActiveOrgsPanelProps> =
  ({ maxResults = 10, days = 7, ...props }) => {
    const [request, { loading, data }] = useActiveOrgsAnalytics(days)
    const [showAll, setShowAll] = useState(false)
    const handleShowAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.preventDefault()
      setShowAll(!showAll)
    }

    const daysLoaded = useRef(0)
    useEffect(() => {
      if (daysLoaded.current !== days) {
        daysLoaded.current = days
        request()
      }
    }, [days, request])

    const count = data?.length ?? 0
    return (
      <Panel {...props} loading={loading}>
        <Title className="text-green-600">
          {(data ?? []).length} Active Orgs (Last {days} days)
        </Title>
        <Results>
          <>
            <p className="px-2 w-1/2 text-sm font-bold">Name</p>
            <p className="px-2 w-1/4 text-sm font-bold">Last Active</p>
          </>
          {(data ?? [])
            .filter((_: any, i: any) =>
              showAll ? true : maxResults ? i <= maxResults : true
            )
            .sort((a, b) => moment(b.meta_1).diff(moment(a.meta_1)))
            .map((metric: any, i: number) => (
              <React.Fragment key={metric.id}>
                <p className="px-2 w-1/2 text-sm flex-grow-0 truncate">
                  <Link to={`/org/${metric.meta_2}/`}>{metric.value}</Link>
                </p>
                <p className="px-2 w-1/4 text-sm flex-grow-0 truncate">
                  {moment(metric.meta_1).format("MMM DD h:mm A")}
                </p>
              </React.Fragment>
            ))}
          {count <= maxResults ? (
            <p className="px-2 w-full text-sm flex-grow-0 truncate font-italic text-gray-500">
              {count ? `Displaying all ${count} results.` : "No results found."}
            </p>
          ) : (
            <Button onClick={handleShowAll} appearance="secondary">
              {showAll ? "Show Less" : "Show All"}
            </Button>
          )}
        </Results>
      </Panel>
    )
  }
