import React, { Component } from "react"
import PropTypes from "prop-types"
import { camelizeKeys } from "humps"
import { Field, reduxForm, SubmissionError } from "redux-form"
import Flex from "../Flex"
import Indicator from "../Indicator"
import Form from "../Form"
import { Button } from "ui"

import styles from "./DeleteForm.module.css"
import toast from "react-hot-toast"

export const submitValidate = (result) => {
  if (result.error) {
    const errors = camelizeKeys(result.payload.errors)
    throw new SubmissionError(
      Object.assign({}, errors, { _error: "Request could not be completed!" })
    )
  }
  return result
}

export const validate = (formProps) => {
  const errors = {}
  if (!formProps) {
    return errors
  }
  if (formProps.confirmationName !== formProps.completedName) {
    errors.confirmationName = `Please type '${formProps.completedName}' to confirm deletion.`
  }
  return errors
}

class DeleteForm extends Component {
  componentWillMount() {
    this.resetForm()
  }

  componentDidUpdate(newProps) {
    this.props.confirmationName !== newProps.confirmationName &&
      this.resetForm()
  }

  resetForm() {
    this.props.reset()
    this.setState({ requestedDelete: false })
    this.props.change("completedName", this.props.confirmationName)
  }

  submitForm() {
    const { deleteAction, redirectAction, entityName } = this.props
    return deleteAction()
      .then(submitValidate)
      .then(() => {
        toast.success(`Successfully deleted ${entityName}`)
        return redirectAction()
      })
  }

  handleClick() {
    this.setState({ requestedDelete: true })
  }

  render() {
    const { submitting, handleSubmit, entityName, confirmationName, error } =
      this.props
    return (
      <form
        onSubmit={handleSubmit((values) => this.submitForm(values))}
        className={styles.deleteForm}
      >
        <Form.Grid>
          <Form.Heading name={`Delete ${entityName}?`} />
          <Form.GridRow>
            <Form.FieldCol>
              {!this.state.requestedDelete && (
                <div>
                  <p>
                    Please note this action is permanent and cannot be undone.
                  </p>
                  <button
                    className={styles.deleteLink}
                    onClick={(e) => {
                      e.preventDefault()
                      this.handleClick()
                    }}
                  >
                    Yes, I want to delete this {entityName}
                  </button>
                </div>
              )}
              {this.state.requestedDelete && (
                <p>
                  You cannot undo this action! If you really want to delete this{" "}
                  {entityName.toLowerCase()}, please type the name of the{" "}
                  {entityName.toLowerCase()} to confirm.
                </p>
              )}
            </Form.FieldCol>
          </Form.GridRow>

          {this.state.requestedDelete && (
            <Field
              component={Form.Field}
              name="confirmationName"
              label={`${entityName} Name`}
              type="text"
              active={true}
              assistantText="Please retype the name to ensure you truly want to take this action."
              customizations={{
                disabled: submitting,
                placeholder: `Retype '${confirmationName}'`,
              }}
            />
          )}

          {this.state.requestedDelete && (
            <Form.GridRow>
              <Form.FieldCol>
                <br />
                {error && <Form.Message error={true}>{error}</Form.Message>}
                <Button
                  type="submit"
                  align="stretch"
                  disabled={submitting}
                  danger={true}
                >
                  <Flex.Container>
                    {submitting && <Indicator.Ring theme={"button"} />}
                    Delete {entityName}
                  </Flex.Container>
                </Button>
              </Form.FieldCol>
            </Form.GridRow>
          )}
        </Form.Grid>
      </form>
    )
  }
}

DeleteForm.propTypes = {
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  redirectAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmationName: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  error: PropTypes.string,
}

export const deleteFormWithName = (formName) =>
  reduxForm({
    form: formName,
    validate,
    submitValidate,
  })(DeleteForm)

export default deleteFormWithName("DELETE_FORM")
