import React from "react"
import PropTypes from "prop-types"
import styles from "./Column.module.css"

const Column = ({ children }) => <div className={styles.column}>{children}</div>

Column.propTypes = {
  children: PropTypes.node,
}

export default Column
