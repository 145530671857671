import React from "react"
import PropTypes from "prop-types"
import styles from "./Button.module.css"

const classNameList = (full, secondary, danger, active) => {
  return ["btn", styles.btn]
    .concat(secondary ? "btn-secondary" : styles["btn-primary"])
    .concat(full && "btn-block")
    .concat(danger && "btn-danger")
    .concat(active && "active")
    .join(" ")
}

const Button = ({
  full,
  secondary,
  children,
  type,
  danger,
  active,
  ...input
}) => (
  <button
    {...input}
    type={type || "button"}
    className={classNameList(full, secondary, danger, active)}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  full: PropTypes.bool,
  type: PropTypes.string,
  active: PropTypes.bool,
  danger: PropTypes.bool,
}

export default Button
