import { PROTECTED } from "redux-jwt-protected-middleware"
import queryString from "query-string"
import { decamelizeKeys } from "humps"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"

//
// GET / Find all by :subdomain or specific :id
//

export const getInvitation = (subdomain, id, filter = "all") => ({
  type: types.FETCH_INVITATIONS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.INVITATION : Schemas.INVITATIONS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/invitations/${
      id || `?filter=${filter}`
    }`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_INVITATIONS_REQUEST,
      types.FETCH_INVITATIONS_SUCCESS,
      types.FETCH_INVITATIONS_FAILURE,
    ],
  },
})

export const requestInvitation = (subdomain, id, filter) => (dispatch) => {
  return dispatch(getInvitation(subdomain, id, filter))
}

//
// GET / Find all permissions by :subdomain and specific :id
//

export const getInvitationPermissions = (subdomain, id) => ({
  type: types.FETCH_INVITATION_PERMISSIONS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.PERMISSIONS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/invitations/${id}/granular_permissions`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_INVITATION_PERMISSIONS_REQUEST,
      types.FETCH_INVITATION_PERMISSIONS_SUCCESS,
      types.FETCH_INVITATION_PERMISSIONS_FAILURE,
    ],
  },
})

export const requestInvitationPermissions = (subdomain, id) => (dispatch) => {
  return dispatch(getInvitationPermissions(subdomain, id))
}

//
// POST / new invitation
//

export const postInvitation = (subdomain, invitation) => ({
  type: types.CREATE_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/invitations/`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(invitation)),
    types: [
      types.CREATE_INVITATION_REQUEST,
      types.CREATE_INVITATION_SUCCESS,
      types.CREATE_INVITATION_FAILURE,
    ],
  },
})

export const createInvitation = (subdomain, invitation) => (dispatch) => {
  return dispatch(postInvitation(subdomain, invitation))
}

//
// POST / Create a new permissions by :subdomain and specific :id
//

export const postInvitationPermission = (subdomain, id, permission) => ({
  type: types.CREATE_INVITATION_PERMISSION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.PERMISSION,
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/invitations/${id}/granular_permissions/`,
    body: JSON.stringify(decamelizeKeys(permission)),
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.CREATE_INVITATION_PERMISSION_REQUEST,
      types.CREATE_INVITATION_PERMISSION_SUCCESS,
      types.CREATE_INVITATION_PERMISSION_FAILURE,
    ],
  },
})

export const createInvitationPermission =
  (subdomain, id, permission) => (dispatch) => {
    return dispatch(postInvitationPermission(subdomain, id, permission))
  }

//
// POST / resend invitation
//

export const postResendInvitation = (subdomain, invitationId) => ({
  type: types.RESEND_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "POST",
    endpoint: `/api/organizations/${subdomain}/invitations/${invitationId}/resend`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.RESEND_INVITATION_REQUEST,
      types.RESEND_INVITATION_SUCCESS,
      types.RESEND_INVITATION_FAILURE,
    ],
  },
})

export const resendInvitation = (subdomain, invitationId) => (dispatch) => {
  return dispatch(postResendInvitation(subdomain, invitationId))
}

//
// PATCH / update invitation
//

export const patchInvitation = (subdomain, invitation) => ({
  type: types.UPDATE_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/invitations/${invitation.id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invitation),
    types: [
      types.UPDATE_INVITATION_REQUEST,
      types.UPDATE_INVITATION_SUCCESS,
      types.UPDATE_INVITATION_FAILURE,
    ],
  },
})

export const updateInvitation = (subdomain, invitation) => (dispatch) => {
  return dispatch(patchInvitation(subdomain, invitation))
}

//
// PUT / Update a new permissions by :subdomain and specific :id
//

export const putInvitationPermission = (subdomain, id, permission) => ({
  type: types.UPDATE_INVITATION_PERMISSION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.PERMISSION,
    method: "PUT",
    endpoint: `/api/organizations/${subdomain}/invitations/${id}/granular_permissions/${permission.id}`,
    body: JSON.stringify(decamelizeKeys(permission)),
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.UPDATE_INVITATION_PERMISSION_REQUEST,
      types.UPDATE_INVITATION_PERMISSION_SUCCESS,
      types.UPDATE_INVITATION_PERMISSION_FAILURE,
    ],
  },
})

export const updateInvitationPermission =
  (subdomain, id, permission) => (dispatch) => {
    return dispatch(putInvitationPermission(subdomain, id, permission))
  }

//
// GET / validation errors
//

export const getInvitationValidation = (subdomain, values) => ({
  type: types.VALIDATE_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/invitations/validations?${queryString.stringify(
      values
    )}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.VALIDATE_INVITATION_REQUEST,
      types.VALIDATE_INVITATION_SUCCESS,
      types.VALIDATE_INVITATION_FAILURE,
    ],
  },
})

export const requestInvitationValidation =
  (subdomain, values) => (dispatch) => {
    return dispatch(getInvitationValidation(subdomain, values))
  }

//
// GET / Check invitation status for token.
//

export const getInvitationStatus = (subdomain, token) => ({
  type: types.FETCH_INVITATION_STATUS,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/invitations/status?token=${token}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_INVITATION_STATUS_REQUEST,
      types.FETCH_INVITATION_STATUS_SUCCESS,
      types.FETCH_INVITATION_STATUS_FAILURE,
    ],
  },
})

export const requestInvitationStatus = (subdomain, token) => (dispatch) => {
  return dispatch(getInvitationStatus(subdomain, token))
}

export const loadedPendingInvitation = (invitationId, token) => ({
  type: types.LOADED_PENDING_INVITATION,
  pendingInvitation: {
    invitationId,
    token,
  },
})

export const clearedPendingInvitation = () => ({
  type: types.CLEARED_PENDING_INVITATION,
})

//
// POST / Check invitation status for token.
//

export const acceptInvitation = (subdomain, token) => ({
  type: types.ACCEPT_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/invitations/accept`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
    types: [
      types.ACCEPT_INVITATION_REQUEST,
      types.ACCEPT_INVITATION_SUCCESS,
      types.ACCEPT_INVITATION_FAILURE,
    ],
  },
})

export const requestAcceptInvitation = (subdomain, token) => (dispatch) => {
  return dispatch(acceptInvitation(subdomain, token))
}

//
// DELETE / Delete existing Invitation via ID.
//

export const deleteInvitation = (subdomain, id) => ({
  type: types.DELETE_INVITATION,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.INVITATION,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/invitations/${id}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_INVITATION_REQUEST,
      types.DELETE_INVITATION_SUCCESS,
      types.DELETE_INVITATION_FAILURE,
    ],
  },
})

export const removeInvitation = (subdomain, id) => (dispatch) => {
  return dispatch(deleteInvitation(subdomain, id))
}
