import React from "react"
import GridRow from "./GridRow.react"
import FieldCol from "./FieldCol.react"
import styles from "./Break.module.css"

const Break = () => (
  <GridRow>
    <FieldCol>
      <hr className={styles.break} />
    </FieldCol>
  </GridRow>
)

export default Break
