import React from "react"
import PropTypes from "prop-types"
import styles from "./ModalTitle.module.css"

const Title = ({ title }) => (
  <h4 className={`modal-title ${styles.title}`}>{title}</h4>
)

Title.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Title
