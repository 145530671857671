import React from "react"
import PropTypes from "prop-types"
import { Form, Flex, Indicator } from "../../shared"
import styles from "./reportCalendarFormView.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { Button } from "ui"

export const Note = ({ children, style }) => (
  <small className={styles.note} style={{ marginTop: 0, ...style }}>
    {children}
  </small>
)

Note.propTypes = {
  /**
   * The child nodes for this component.
   */
  children: PropTypes.array,
  /**
   * Any associated style for this node.
   */
  style: PropTypes.object,
}

/**
 * This is one of a few forms that is just using plain state until
 * we migrate away from ReduxForm. This will most likely be after we
 * migrate to modern react.
 */
class ReportCalendarFormView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: props.initialValues || {},
    }
  }

  render() {
    const { calendar, loading, onDismiss, onSubmit } = this.props
    const { values } = this.state
    const handleDismiss = (e) => {
      e.preventDefault()
      onDismiss()
    }
    const handleSubmit = (e) => {
      e.preventDefault()
      onSubmit(values)
    }
    const renderColor = ({ label, name, disabled }) => (
      <Form.ModalColorInput
        name={name}
        label={label}
        placeholder=""
        type="hidden"
        disabled={disabled || loading}
        input={{
          onChange: (value) => {
            this.setState({ values: { ...values, [name]: value } })
          },
          value: values[name],
        }}
        meta={{
          touched: false,
          error: null,
        }}
      />
    )
    return (
      <form
        style={{
          pointerEvents: loading ? "none" : "all",
          opacity: loading ? 0.5 : 1,
        }}
        onSubmit={handleSubmit}
      >
        <button href="#" onClick={handleDismiss} className={styles.dismissLink}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Back to Calendar List
        </button>
        <Form.Divider />
        <p className={styles.flexRow}>
          <span className={styles.name}>{calendar.name}</span>
        </p>
        <div>
          <label htmlFor={"showTint"} className={styles.option}>
            <input
              name={"showTint"}
              className={styles.checkbox}
              type="checkbox"
              checked={values.showTint}
              onChange={() =>
                this.setState({
                  values: { ...values, showTint: !values.showTint },
                })
              }
            />
            Use Tint
          </label>
          {!values.showTint ? null : (
            <Form.Split>
              {renderColor({
                label: "Fill",
                name: "tintFillColor",
                disabled: !values.showTint,
              })}
              {renderColor({
                label: "Outline",
                name: "tintOutlineColor",
                disabled: !values.showTint,
              })}
              {renderColor({
                label: "Text",
                name: "tintTextColor",
                disabled: !values.showTint,
              })}
            </Form.Split>
          )}
          <Note>
            If enabled all banners for this calendar will utilize the style
            specified.
          </Note>
        </div>
        <div>
          <label htmlFor={"showPrefix"} className={styles.option}>
            <input
              type="checkbox"
              name={"showPrefix"}
              className={styles.checkbox}
              checked={values.showPrefix}
              onChange={() =>
                this.setState({
                  values: { ...values, showPrefix: !values.showPrefix },
                })
              }
            />
            Use Prefix
          </label>
          {!values.showPrefix ? null : (
            <Form.ModalFieldInput
              name="prefix"
              label="Prefix"
              placeholder="This text will render on all banners for this calendar."
              type="text"
              disabled={!values.showPrefix || loading}
              input={{
                onChange: (e) => {
                  this.setState({
                    values: { ...values, prefix: e.target.value },
                  })
                },
                value: values.prefix,
              }}
              meta={{
                touched: false,
                error: null,
              }}
            />
          )}
          <Note>
            If enabled all banners will render the prefix text prior to the
            banner's actual label.
          </Note>
        </div>
        <div>
          <label htmlFor={"showInOut"} className={styles.option}>
            <input
              type="checkbox"
              name={"showInOut"}
              className={styles.checkbox}
              checked={values.showInOut}
              onChange={() =>
                this.setState({
                  values: { ...values, showInOut: !values.showInOut },
                })
              }
            />
            Only Show Start / End
          </label>
          <Note style={{ marginTop: 0 }}>
            If enabled, two colored dots will appear in the day cells. One
            representing the start date and another representing the end date,
            based on the calendar settings. This is useful for annual calendar
            views to see when a project starts and ends.
          </Note>
        </div>
        <Button type="submit" full={true} disabled={loading}>
          <Flex.Container>
            {loading && <Indicator.Ring theme={"button"} />}
            Save Changes
          </Flex.Container>
        </Button>
      </form>
    )
  }
}

ReportCalendarFormView.propTypes = {
  /**
   * The calendar for the report.
   */
  calendar: PropTypes.string,

  /**
   * Indicates whether or not the form is processing over the network.
   */
  loading: PropTypes.bool,

  /**
   * An event handler that is called when the user hits the back button on the form.
   */
  onDismiss: PropTypes.func,

  /**
   * The initial values to populate the form with.
   */
  initialValues: PropTypes.object,

  /**
   * An event handler that is called when the form is submitted.
   */
  onSubmit: PropTypes.func,
}

export default ReportCalendarFormView
