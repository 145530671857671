import * as types from "../actionTypes"

/**
 * Sends event IDs to the clipboard and sets the
 * clipboard to cut mode.
 * @param {Array} eventUuids An array of IDs for the affected events.
 * @param {Number} calendarId The source calendar ID to look up events from.
 * @return {Object} An FSA action.
 */
export const cutEvents = (eventUuids, calendarId) => ({
  type: types.CUT_ITEMS,
  payload: { eventUuids, calendarId },
})
