import React from "react"
import { Modal, ModalProps } from "ui"
import { useStyle } from "../api"
import { useParams } from "react-router"
import { ConnectedStyleForm } from "./ConnectedStyleForm"

export interface StyleModalProps {
  styleUuid?: string
  isNew?: string
  onCreateStyle?: (uuid: string) => void
  onDuplicateStyle?: (uuid: string) => void
}

export const StyleModal: React.FC<StyleModalProps & ModalProps> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  styleUuid,
  zIndex,
}) => {
  const params = useParams<{ subdomain: string; calendarId: string }>()
  const { style } = useStyle(
    params.subdomain,
    params.calendarId,
    styleUuid ?? ""
  )

  return (
    <Modal
      title={style?.uuid ? "Edit Style" : "New Style"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <ConnectedStyleForm styleUuid={styleUuid} onSave={handleDismiss} />
    </Modal>
  )
}
