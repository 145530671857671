import { createSelector } from "reselect"
import { entities } from "./baseSelector"
import * as categorySelector from "./categorySelector"
import flatten from "lodash/flatten"

/**
 * Returns all items entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the item entities from state.
 */
const allItems = (state) => entities(state).items

/**
 * Returns all items entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} items The current state of the redux store.
 * @return {Array}        A function that returns an array of item entities.
 */
const availableItems = (items) =>
  Object.keys(items)
    .map((key) => items[key])
    .sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
    .filter((entity) => !entity.isDeleted)

/**
 * Retrieves a specific category object from the redux store by ID and confirms whether
 * or not it has been locked.
 *
 * @param  {Object}       state The current state of the redux store.
 * @param {String}        categoryUuid The uuid of the category to check.
 * @return {Function}     A function that returns a matching item if one exists.
 */
const categoryLocked = (state, categoryUuid) => {
  /* eslint-disable */
  const category = categorySelector.find(state)(categoryUuid)
  if (!category) {
    return false
  }
  if (category.locked) {
    return category.locked
  }
  /* eslint-enable */
  return categoryLocked(state, category.categoryUuid)
}

/**
 * Retrieves a specific item object from the redux store by ID and confirms it or its
 * category has been locked.
 *
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching item if one exists.
 */
export const findLocked = (state) => (itemUuid) => {
  const entity = entities(state).items[itemUuid]
  return entity.locked
    ? entity.locked
    : categoryLocked(state, entity.categoryUuid)
}

const includeDeletedItems = (items) =>
  Object.keys(items).map((key) => items[key])

export const all = createSelector(allItems, availableItems)
export const allDeleted = createSelector(allItems, includeDeletedItems)

const trulyInactive = (inactiveCategoryUuids, item) =>
  !item ||
  !item.active ||
  item.isDeleted ||
  (item.categoryUuid && inactiveCategoryUuids.includes(item.categoryUuid))

const filterInactiveItemUuids = (inactiveCategoryUuids, items) =>
  items
    .filter((item) => trulyInactive(inactiveCategoryUuids, item))
    .map((item) => item.uuid)

export const inactiveItemUuids = createSelector(
  categorySelector.inactiveCategoryUuids,
  allDeleted,
  filterInactiveItemUuids
)

const trulyLocked = (lockedCategoryUuids, item) =>
  !item ||
  item.locked ||
  (item.categoryUuid && lockedCategoryUuids.includes(item.categoryUuid))

const filterLockedItemUuids = (lockedCategoryUuids, items) =>
  items
    .filter((item) => trulyLocked(lockedCategoryUuids, item))
    .map((item) => item.uuid)

export const lockedItemUuids = createSelector(
  categorySelector.lockedCategoryUuids,
  all,
  filterLockedItemUuids
)

/**
 * Retrieves a specific item object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching item if one exists.
 */
export const find = (state) => (itemUuid, calendarId) => {
  const hashId = [itemUuid, calendarId].filter((i) => i).join("#")
  const entity = entities(state).items[hashId]
  return entity && !entity.isDeleted && entity
}

/**
 * Returns a query function that fetches all items for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of items that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarIds) =>
  all(state).filter((item) =>
    flatten([calendarIds])
      .map((id) => parseInt(id, 0))
      .includes(parseInt(item.calendarId, 0))
  )

/**
 * Returns a query function that fetches all items belonging to the special notes category for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of items that match a supplied calendar ID.
 */
export const notesForCalendar = (state) => (calendarIds, entityType) => {
  return flatten(
    flatten([calendarIds]).map((id) => {
      const calendarId = parseInt(id, 0)
      const notesCategory = categorySelector.notesCategoryForCalendarEntity(
        state
      )(calendarId, entityType)
      return forCalendar(state)(calendarId, entityType).filter(
        (item) => notesCategory && item.categoryUuid === notesCategory.uuid
      )
    })
  )
}

/**
 * Returns a query function that fetches all items for a single snapshot.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of items that match a supplied calendar ID.
 */
export const forSnapshot = (state) => (snapshotId) =>
  all(state).filter(
    (item) => parseInt(item.snapshotId, 0) === parseInt(snapshotId, 0)
  )
