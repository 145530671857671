import React, { useState } from "react"
import {
  Modal,
  ModalProps,
  AssignGroupForm,
  AsyncSubmitHandler,
  AssignGroupFormValues,
} from "ui"
import toast from "react-hot-toast"
import {
  useCreateGroup,
  useGroups,
  useUpdateGroupParent,
  useUpdateCalendarGroup,
} from "../api"
import {
  GroupDataStructure,
  useAddGroups,
  useGroupsAccessibleFor,
} from "../api/atoms"
import { wait } from "utils"

export interface SendToGroupModalProps {
  targetId?: string
  targetType?: "group" | "calendar"
  targetName?: string
  subdomain: string
}

export const SendToGroupModal: React.FC<SendToGroupModalProps & ModalProps> = ({
  onClose: handleDismiss,
  targetId,
  targetType,
  subdomain,
  onFocus: handleFocus,
  zIndex,
  targetName,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  const { loading: loadingGroups } = useGroups(subdomain)

  const addGroups = useAddGroups()
  const [createGroup] = useCreateGroup(subdomain)
  const [updateGroupParent] = useUpdateGroupParent(subdomain)
  const [updateCalendarGroup] = useUpdateCalendarGroup(subdomain)

  const handleCreateGroup = async (name: string) => {
    setLoading(true)
    const group = await createGroup({ body: { name } })
    setLoading(false)
    if (group) {
      toast.success("Group created")
      if (group) {
        addGroups([group as GroupDataStructure])
      }
      return group.uuid
    }
    return
  }

  const handleSubmit: AsyncSubmitHandler<AssignGroupFormValues> = async (
    data
  ) => {
    setLoading(true)
    try {
      if (targetType === "calendar") {
        await updateCalendarGroup({
          params: { calendarId: targetId as string },
          body: { group_uuid: data.groupUuid },
        })
      } else if (targetType === "group") {
        const group = await updateGroupParent({
          params: { uuid: targetId as string },
          body: { group_uuid: data.groupUuid },
        })
        if (group) {
          await wait(1)
          await addGroups([group as GroupDataStructure])
        }
      }
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
    return undefined
  }

  const groupOptions = useGroupsAccessibleFor(targetId as string)
  return (
    <Modal
      {...props}
      title={"Send to Group"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
    >
      <AssignGroupForm
        onCreateGroup={handleCreateGroup}
        onSubmit={handleSubmit}
        groups={groupOptions}
        loading={loading || loadingGroups}
      />
      {targetName ? (
        <p className="text-sm text-center">
          You are moving <span className="font-bold">{targetName}</span> to the
          selected group.
        </p>
      ) : null}
    </Modal>
  )
}
