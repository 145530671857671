import { GroupDataStructure } from "../atoms"
import { useApiRequest } from "./useApiRequest"

interface Group {
  id?: number
  uuid?: string
  position?: number
  name: string
  groupUuid?: string
}

export interface CreateGroupBody extends Group {}

export const useCreateGroup = (subdomain: string) => {
  const createGroup = useApiRequest<GroupDataStructure, CreateGroupBody>({
    method: "POST",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/`,
  })
  return createGroup
}
