import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarSection.module.css"

const stylesForSection = (extended, required, grow, ignoresGrid) => {
  const classNames = [styles.toolbarSection]
  if (!ignoresGrid) {
    classNames.push(extended ? styles.extended : styles.short)
  }
  classNames.push(required ? styles.required : "")
  classNames.push(grow ? styles.grow : "")
  return classNames.join(" ")
}

const ToolBarSection = ({
  children,
  extended,
  required,
  grow,
  ignoresGrid,
}) => (
  <div className={stylesForSection(extended, required, grow, ignoresGrid)}>
    {children}
  </div>
)

ToolBarSection.propTypes = {
  children: PropTypes.node,
  extended: PropTypes.bool,
  grow: PropTypes.bool,
  required: PropTypes.bool,
  ignoresGrid: PropTypes.bool,
}

export default ToolBarSection
