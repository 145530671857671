import React, { useCallback, useEffect } from "react"
import toast from "react-hot-toast"

// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly"

export const useGoogleCalendarAPI = () => {
  const [gisInited, setGisInited] = React.useState(false)
  const [gapiInited, setGapiInited] = React.useState(false)
  const [tokenClient, setTokenClient] =
    React.useState<google.accounts.oauth2.TokenClient | null>(null)
  const [loadingFlags, setLoadingFlags] = React.useState([] as string[])
  const [loading, setLoading] = React.useState(false)
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [isAuthorized, setIsAuthorized] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [events, setEvents] = React.useState<{
    [key: string]: gapi.client.calendar.Event[]
  }>({})
  const [calendars, setCalendars] = React.useState<
    gapi.client.calendar.CalendarListEntry[]
  >([])

  const initializeTokenClient = () => {
    try {
      setTokenClient(
        google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID ?? "",
          scope: SCOPES,
          callback: (tokenResponse) => {
            console.log(tokenResponse)
          },
        })
      )
      setGisInited(true)
    } catch (e) {
      console.log("Could not initialize google client:")
      console.log(e)
      console.log("CLIENT_ID", CLIENT_ID)
      console.log("API_KEY", API_KEY)
    }
  }

  const initializeGapi = async () => {
    try {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      })
    } catch (e) {
      console.log("ERROR", API_KEY, e)
      toast.error("Could not connect to google.")
    }
    setGapiInited(true)
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  useEffect(() => {
    if (!gisInited && !tokenClient) {
      initializeTokenClient()
    } else if (gisInited && !gapiInited) {
      gapi.load("client", initializeGapi)
    } else if (gisInited && gapiInited) {
      setIsInitialized(true)
    }
  }, [gisInited, gapiInited, tokenClient])

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    const hasLoadingFlag = loadingFlags.length > 0
    if (hasLoadingFlag && !loading) {
      setLoading(true)
    } else if (!hasLoadingFlag && loading) {
      timeout.current = setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [loadingFlags, setLoading, loading])

  /**
   * Fetch calendar items / events.
   */
  const loadCalendars = useCallback(async () => {
    console.log("loading calendars")
    try {
      setLoadingFlags([...loadingFlags, "calendars"])
      const response = await gapi.client.calendar.calendarList.list()
      const { items } = response.result
      setCalendars(items)
      setLoadingFlags(loadingFlags.filter((item) => item !== "calendars"))
      return items
    } catch (error) {
      setLoadingFlags(loadingFlags.filter((item) => item !== "calendars"))
      return []
    }
  }, [setCalendars, setLoadingFlags, loadingFlags])

  function handleAuth() {
    const client = tokenClient as any
    client.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        toast.error("Could not connect to google calendar.")
      }
      setIsAuthorized(true)
    }

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient?.requestAccessToken({ prompt: "consent" })
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient?.requestAccessToken({ prompt: "" })
    }
  }

  function handleSignout() {
    const token = gapi.client.getToken()
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token, () => {
        console.log("Access token revoked", token)
      })
      gapi.client.setToken(null)
      setIsAuthorized(false)
    }
  }

  /**
   * Fetch calendar items / events.
   */
  const loadEvents = async (calendarId: string = "primary") => {
    try {
      setLoadingFlags([...loadingFlags, "events"])
      const results = await Promise.all(
        calendarId
          .split(" ")
          .filter((c) => c.length)
          .filter((c) => !events.hasOwnProperty(c))
          .map(async (calendar) => {
            const response = await gapi.client.calendar.events.list({
              calendarId: calendar,
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              orderBy: "startTime",
            })
            const { items } = response.result
            setEvents({ ...events, [calendar]: items })
            return items
          })
      )
      setLoadingFlags(loadingFlags.filter((item) => item !== "events"))
      return results
    } catch (error) {
      console.log(error, JSON.stringify(error, null, 2))
      setEvents({ ...events, [calendarId]: [] })
      setError(JSON.stringify(error, null, 2))
      setLoadingFlags(loadingFlags.filter((item) => item !== "events"))
    }
  }

  return {
    handleAuth,
    handleSignout,
    isAuthorized,
    isInitialized,
    error,
    loading,
    loadEvents,
    loadCalendars,
    events,
    calendars,
  }
}
