import { useMemo, useState, useRef, useEffect, } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { throttle } from "lodash";
export var useResizeObserver = function (_a) {
    var elementRef = _a.element, _b = _a.wait, wait = _b === void 0 ? 100 : _b;
    var _c = useState({ width: 0, height: 0 }), size = _c[0], setSize = _c[1];
    var observerRef = useRef(null);
    var callback = useMemo(function () {
        return throttle(function (entries) {
            var height = 0;
            var width = 0;
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (entry.contentBoxSize) {
                    // Firefox implements `contentBoxSize` as a single content rect, rather than an array
                    var contentBoxSize = Array.isArray(entry.contentBoxSize)
                        ? entry.contentBoxSize[0]
                        : entry.contentBoxSize;
                    height = contentBoxSize.blockSize;
                    width = contentBoxSize.inlineSize;
                }
                else {
                    height = entry.contentRect.height;
                    width = entry.contentRect.width;
                }
            }
            if (size.width !== width || size.height !== height) {
                setSize({
                    height: height,
                    width: width,
                });
            }
        }, wait);
    }, [setSize, wait, size]);
    useEffect(function () {
        var _a, _b;
        var element = elementRef.current;
        if (elementRef.current) {
            (_a = observerRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(element);
        }
        var ResizeObserverOrPolyfill = ResizeObserver;
        observerRef.current = new ResizeObserverOrPolyfill(callback);
        if (elementRef.current) {
            (_b = observerRef.current) === null || _b === void 0 ? void 0 : _b.observe(element);
        }
        return function () {
            var _a;
            if (elementRef.current) {
                (_a = observerRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(element);
            }
        };
    }, [elementRef, wait, observerRef]);
    return { size: size };
};
