import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { AsyncSubmitHandler, CompanyForm, CompanyFormValues } from "ui"
import { View, deleteFormWithName } from "../../shared"
import { DELETE_FORM_NAME } from "../constants"
import {
  useHasSuperAdminPrivilege,
  useOrganization,
  createOrganization,
  updateOrganization,
  removeOrganization,
  useUpdateTrial,
} from "../../api"
import { useDispatch } from "react-redux"
import { faBuilding } from "@fortawesome/pro-regular-svg-icons"
import { wait, randomColor } from "utils"
import { DateField } from "ui/lib/components/Form/DateField"
import { DateTime } from "luxon"
import { Button } from "ui"
import clsx from "clsx"
import toast from "react-hot-toast"

const { ViewContainer, ToolBar, ToolBarIcon, ToolBarSection, ToolBarHeading } =
  View

const OrganizationDeleteForm = deleteFormWithName(DELETE_FORM_NAME)

const OrganizationDetail = () => {
  const [randomizedColor] = useState(randomColor())
  const [submitting, setSubmitting] = useState(false)
  const isSuperAdmin = useHasSuperAdminPrivilege()
  const { subdomain } = useParams<{ subdomain: string }>()
  const history = useHistory()
  const [updateTrial, { loading: submittingTrial }] = useUpdateTrial()
  const { organization, loading, refetch } = useOrganization(subdomain)
  const dispatch = useDispatch()

  const [trialEndDate, setTrialEndDate] = useState(null as DateTime | null)
  const [trialActive, setTrialActive] = useState(null as boolean | null)
  const isTrial = trialActive ?? organization?.isTrial
  const handleDateChange = (d: DateTime[]) => {
    setTrialEndDate(d[0])
  }
  const handleActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrialActive(e.target.checked)
  }

  const [lastSubdomain, setLastSubdomain] = useState(subdomain)
  useEffect(() => {
    if (subdomain !== lastSubdomain) {
      setLastSubdomain(subdomain)
      setTrialActive(null)
      setTrialEndDate(null)
    }
  }, [subdomain, lastSubdomain, setTrialActive])

  const handleCompanySubmit: AsyncSubmitHandler<CompanyFormValues> = async ({
    companyName,
    color,
    subdomain,
    id,
  }) => {
    setSubmitting(true)
    await wait(1)
    const result = await dispatch(
      organization
        ? updateOrganization({
            id,
            name: companyName,
            color,
          })
        : createOrganization({
            name: companyName,
            color,
            subdomain,
          })
    )

    const errors = (result as any)?.payload?.errors
    if (errors) {
      const key = Object.keys(errors)[0]
      toast.error(`Could not create company '${key}' ${errors[key]}.`)
      setSubmitting(false)
      return undefined
    }

    await refetch()
    setSubmitting(false)
    return undefined
  }

  const handleDelete = async () => {
    await dispatch(removeOrganization(organization.id))
  }

  const handleUpdateTrial = async () => {
    await updateTrial({
      body: {
        active: trialActive ?? false,
        trialEndsAt: trialEndDate?.toISODate(),
      },
      params: {
        id: `${organization?.id}`,
      },
    })
    await refetch()
    toast.success("Successfully updated trial status.")
  }

  return (
    <ViewContainer>
      <ToolBar>
        <ToolBarSection required={true} extended={true}>
          <ToolBarIcon icon={faBuilding} />
          <ToolBarHeading
            primaryText={organization?.name ?? "New Company"}
            secondaryText={
              (organization?.name && "Settings") ?? "Add a New Member Company"
            }
          />
        </ToolBarSection>
      </ToolBar>
      <div className="flex flex-col p-4">
        <div className="max-w-[440px] relative mx-8">
          <CompanyForm
            onSubmit={handleCompanySubmit}
            defaultValues={
              subdomain
                ? {
                    id: organization?.id,
                    companyName: organization?.name,
                    color: organization?.color,
                    subdomain: organization?.subdomain,
                  }
                : {
                    id: undefined,
                    companyName: "",
                    color: randomizedColor,
                    subdomain: "",
                  }
            }
            loading={loading || submitting}
            submitTitle={organization ? "Update Company" : "Create Company"}
          />
          {isSuperAdmin && organization && (
            <div
              className={clsx(
                "mt-10 mx-4 mb-4 p-4 bg-stone-200 rounded-lg",
                submittingTrial ? "opacity-45" : !isTrial && "opacity-90"
              )}
            >
              <h2 className="text-md font-bold mb-4 flex flex-row">
                <input
                  type="checkbox"
                  name="trialEnabled"
                  checked={isTrial}
                  onChange={handleActive}
                  className="mr-2"
                />{" "}
                Trial Account
              </h2>
              <p className="text-sm mb-4">
                You can promote or demote this company from PRO to trial status
                and extend the deadline of the trial. If the trial has expired
                the company and all of it's calendars will be READ-ONLY until
                promoted back into the PRO / paid plan.
              </p>
              <DateField
                value={organization.trialEndsAt}
                name="trialEndsAt"
                onDateChange={handleDateChange}
                dates={
                  trialEndDate ?? organization.trialEndsAt
                    ? [
                        trialEndDate ??
                          DateTime.fromISO(organization.trialEndsAt),
                      ]
                    : []
                }
                label="Trial End Date"
              />
              <Button
                appearance="primary"
                className="my-2"
                align="stretch"
                onClick={handleUpdateTrial}
              >
                Update Trial
              </Button>
            </div>
          )}
          {organization && isSuperAdmin && (
            <div className="font-mono text-sm my-2 mx-4 rounded bg-stone-50 p-4">
              <h4 className="font-bold mb-2">Internal Details</h4>
              <p>Last Active: {organization.lastActive}</p>
              <p>Created At: {organization.createdAt}</p>
              <p>Created By: {organization.createdByEmail ?? "Unknown"}</p>
            </div>
          )}
        </div>

        {organization?.name && isSuperAdmin && (
          <OrganizationDeleteForm
            entityName="Company"
            confirmationName={organization?.name}
            deleteAction={handleDelete}
            redirectAction={() => history.replace("/org/")}
          />
        )}
      </div>
    </ViewContainer>
  )
}

export default OrganizationDetail
