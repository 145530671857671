import React from "react"
import PropTypes from "prop-types"
import gridStyles from "../../utils/gridStyles"

const gridAttributes = "col-xs-12 col-sm-7 col-md-6 col-lg-4"

const FieldCol = ({ children }) => (
  <div className={gridStyles(gridAttributes)}>{children}</div>
)

FieldCol.propTypes = {
  children: PropTypes.node,
}

export default FieldCol
