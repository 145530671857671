import React from "react"
import PropTypes from "prop-types"
import styles from "./Field.module.css"

const Field = ({ placeholder, value, onChange, textAlign, style }) => (
  <input
    className={styles.field}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    style={{ textAlign: textAlign || "left", ...style }}
  />
)

Field.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  textAlign: PropTypes.string,
  style: PropTypes.object,
}

export default Field
