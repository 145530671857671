import * as types from "../actionTypes"

/**
 * Sends event IDs to the clipboard and sets the
 * clipboard to copy mode.
 * @param {Object} payload A set of descriptors used to present a context menu.
 * @return {Object} An FSA action.
 */
export const requestContext = ({
  holidays,
  icons,
  entityType,
  referenceDate,
  uuids,
  targetUuid,
  recurringEvent,
  x,
  y,
}) => ({
  type: types.REQUEST_CONTEXT,
  payload: {
    holidays,
    icons,
    entityType,
    referenceDate,
    uuids,
    targetUuid,
    recurringEvent,
    x,
    y,
  },
})
