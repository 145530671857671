import React, { useRef } from "react"
import { useDrop } from "react-dnd"
// import { PreviewTypes } from "../../itemExplorer"
import styles from "./RendererContainerView.module.css"
import clsx from "clsx"

const PreviewTypes = {} as any

interface RendererContainerViewProps {
  updateItemPreviewType: (params: any) => void
  itemPreviewType: string
  printing?: boolean
  onClick?: React.MouseEventHandler
}

/**
 * This class was created out of convenience so that each alternate
 * calendare renderer does not need to replicate the DropTarget logic
 * we use to determine whether or not the user is dragging an item over
 * the calendar or the item explorer.
 *
 * @param {Object} props               The child JSX elements for this view.
 * @return {ReactClass}                 A node representing this element.
 */
export const RendererContainerView: React.FC<RendererContainerViewProps> = ({
  children,
  onClick,
  printing,
  itemPreviewType,
  updateItemPreviewType,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop(
    () => ({
      accept: itemPreviewType,
      hover: (_item, _monitor) => {
        if (itemPreviewType !== PreviewTypes.EVENT_PREVIEW) {
          updateItemPreviewType?.(PreviewTypes.EVENT_PREVIEW)
        }
      },
    }),
    [itemPreviewType, updateItemPreviewType]
  )

  drop(ref)

  return (
    <div
      ref={ref}
      className={clsx(
        "flex-col",
        printing
          ? styles.rendererContainerPrintView
          : styles.rendererContainerView
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
