var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ContextItem } from "./ContextItem";
import { faCut, faEdit, faCopy, faForward, faTrash, faUnlink, faBackward, faBan, faCog, faPlus, } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * A set of context options for a selected event.
 */
export var ContextMenuEvent = function (_a) {
    var _b, _c;
    var recurringEvent = _a.recurringEvent, singleEvent = _a.singleEvent, onSelect = _a.onSelect, styles = _a.styles, styleUuid = _a.styleUuid, handleSelectStyle = _a.onSelectStyle, handleSelectManageStyles = _a.onSelectManageStyles, handleSelectNewStyle = _a.onSelectNewStyle;
    var _d = useState(null), styleName = _d[0], setStyleName = _d[1];
    var handleSelection = function (selection, params) { return function () {
        if (onSelect) {
            onSelect(selection, params);
        }
    }; };
    var label = singleEvent ? "Event" : "Events";
    var selectedStyleName = (_b = styles === null || styles === void 0 ? void 0 : styles.find(function (style) { return style.uuid === styleUuid; })) === null || _b === void 0 ? void 0 : _b.name;
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            flexDirection: "column",
        } }, { children: [singleEvent ? (_jsx(ContextItem, { name: "Edit " + label, icon: faEdit, onClick: handleSelection("editEvent") }, void 0)) : null, _jsx(ContextItem, { name: "Cut " + label, icon: faCut, onClick: handleSelection("cutEvents") }, void 0), _jsx(ContextItem, { name: "Copy " + label, icon: faCopy, onClick: handleSelection("copyEvents") }, void 0), _jsx(ContextItem, { name: "Push " + label, icon: faForward, onClick: handleSelection("push") }, void 0), _jsx(ContextItem, { name: "Pull " + label, icon: faBackward, onClick: handleSelection("pull") }, void 0), _jsx(ContextItem, { name: "Delete " + label, icon: faTrash, onClick: handleSelection("deleteEvent") }, void 0), _jsx(ContextItem, { name: "Ungroup", icon: faUnlink, disabled: !recurringEvent, onClick: handleSelection("ungroupItem") }, void 0), _jsx(ContextItem, { name: "Skip this Occurence", icon: faBan, disabled: !recurringEvent, onClick: handleSelection("skipEventDate") }, void 0), styles ? (_jsxs("ul", __assign({ className: "flex flex-wrap mt-4 px-2 pt-2 pb-6 border-t border-gray-300 relative" }, { children: [styles.map(function (s) { return (_jsx("li", { children: _jsx("button", __assign({ className: clsx("p-2 border rounded", styleUuid === s.uuid
                                ? "border-lochivar-default hover:border-lochivar-dark"
                                : "border-white hover:border-gray-200"), onClick: function (e) {
                                e.preventDefault();
                                handleSelectStyle === null || handleSelectStyle === void 0 ? void 0 : handleSelectStyle(s);
                            }, onMouseOver: function () {
                                setStyleName(s.name);
                            }, onMouseOut: function () {
                                setStyleName(null);
                            } }, { children: _jsx("span", { className: "flex h-4 w-4 rounded-full border", style: {
                                    background: s.fillColor,
                                    borderColor: s.outlineColor,
                                } }, void 0) }), void 0) }, "stylePallette" + s.uuid)); }), handleSelectNewStyle ? (_jsx("li", { children: _jsx("button", __assign({ className: clsx("px-2 py-1 h-6 rounded"), onClick: function (e) {
                                e.preventDefault();
                                handleSelectNewStyle === null || handleSelectNewStyle === void 0 ? void 0 : handleSelectNewStyle();
                            }, onMouseOver: function () {
                                setStyleName("New Style");
                            }, onMouseOut: function () {
                                setStyleName(null);
                            } }, { children: _jsx(FontAwesomeIcon, { icon: faPlus }, void 0) }), void 0) }, void 0)) : null, handleSelectManageStyles ? (_jsx("li", { children: _jsx("button", __assign({ className: clsx("px-2 py-1 h-6 rounded mb-auto"), onClick: function (e) {
                                e.preventDefault();
                                handleSelectManageStyles === null || handleSelectManageStyles === void 0 ? void 0 : handleSelectManageStyles();
                            }, onMouseOver: function () {
                                setStyleName("Manage Styles");
                            }, onMouseOut: function () {
                                setStyleName(null);
                            } }, { children: _jsx(FontAwesomeIcon, { icon: faCog }, void 0) }), void 0) }, void 0)) : null, _jsx("li", __assign({ className: "absolute bottom-0 left-0 bg-gray-200 rounded p-1 text-xs -mb-1 ml-2" }, { children: (_c = styleName !== null && styleName !== void 0 ? styleName : selectedStyleName) !== null && _c !== void 0 ? _c : "Select a Category" }), void 0)] }), void 0)) : null] }), void 0));
};
