import { useWindowSize } from "./useWindowSize";
var breakpoints = {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
};
export var useBreakpoint = function (wait, method) {
    if (wait === void 0) { wait = 500; }
    if (method === void 0) { method = "throttle"; }
    var size = useWindowSize();
    var viewports = Object.keys(breakpoints).filter(function (key) { return breakpoints[key] <= size.width; });
    return { viewports: viewports, size: size };
};
