import React from "react"
import {
  Fields,
  Modal,
  ModalProps,
  TextField,
  Button,
  ErrorList,
  ErrorMap,
  Indicators,
} from "ui"
import { useCreateReferral } from "../api"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"

export interface ShareModalProps {
  position?: number
  subdomain?: string
}

const schema = yup.object({
  name: yup.string().required("cannot be blank"),
  company: yup.string().required("cannot be blank"),
  email: yup.string().email().required("cannot be blank"),
})

interface TrialInviteFormValues {
  name: string
  company: string
  email: string
}

export const ShareModal: React.FC<
  ShareModalProps & Omit<ModalProps, "title" | "subdomain" | "open">
> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  zIndex,
  subdomain,
  position,
  ...props
}) => {
  const [createReferral, { loading }] = useCreateReferral()
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm<TrialInviteFormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  const handleFormSubmit = handleSubmit(async (data) => {
    const result = await createReferral({ body: data })
    console.log(result)
    if (result) {
      toast.success(`Invitation sent to ${result?.name}!`)
    } else {
      toast.error(`Invitation failed to send to ${data?.name}!`)
    }
    handleDismiss?.()
  })

  return (
    <Modal
      {...props}
      title="Share ProCal"
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
    >
      {loading && <Indicators.AbsoluteOverlay />}
      <h4 className="font-bold my-2">
        Do you have a friend or colleague who could use ProCal?
      </h4>
      <p>
        Invite them to a free trial so that they too can experience the awesome
        productivity unlocks achieved with ProCal!
      </p>
      <form onSubmit={handleFormSubmit} className="relative">
        <Fields register={register} grow>
          <TextField
            name="name"
            label="Name"
            placeholder="i.e. Jane Appleseed"
            type="text"
            className="w-full"
            required
          />
          <TextField
            name="company"
            label="Company or Studio"
            placeholder="Where do they work?"
            type="text"
            className="w-full"
            required
          />
          <TextField
            name="email"
            label="Email Address"
            placeholder="Email to send trial invite"
            type="text"
            className="w-full"
            required
          />
          {formErrors ? <ErrorList errors={formErrors as ErrorMap} /> : null}
          <Button type="submit" className="mt-2 w-full" align="stretch">
            Send ProCal Trial
          </Button>
        </Fields>
      </form>
    </Modal>
  )
}
