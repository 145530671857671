import { NAME } from "../constants"
import { entities } from "./baseSelector"
import { canRead } from "./authSelector"

/**
 * Returns all calendars entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of calendar entities.
 */
export const all = (state) =>
  Object.keys(entities(state).calendars)
    .map((key) => entities(state).calendars[key])
    .filter((entity) => !entity.isDeleted)
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

/**
 * Retrieves a specific calendar object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching calendar if one exists.
 */
export const find = (state) => (calendarId) => {
  const entity = entities(state).calendars[calendarId]
  return entity && !entity.isDeleted && entity
}

/**
 * Retrieves indicator dates and color for the associated calendar.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns some derived state if the calendar exists.
 */
export const findIndicatorDates = (state) => (calendarId) => {
  const calendar = find(state)(calendarId)
  return (
    calendar && {
      color: calendar.color,
      startDate: calendar.startDate,
      endDate: calendar.endDate,
    }
  )
}

/**
 * Sorts calendar objects by their name attribute
 * @param {Object} a First calendar to compare
 * @param {Object} b Second calendar to compare
 * @returns {Number} A value of -1, 0, or 1
 */
const sortByName = (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)

/**
 * Sorts calendar objects by their position attribute
 * @param {Object} a First calendar to compare
 * @param {Object} b Second calendar to compare
 * @returns {Number} A value of -1, 0, or 1
 */
const sortByPosition = (a, b) =>
  a.position > b.position ? 1 : a.position < b.position ? -1 : 0

/**
 * Returns a query function that fetches all calendars for a single organization.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of calendars that match a supplied org ID.
 */
export const forOrganization = (state) => (organization) =>
  all(state)
    .filter((calendar) => calendar.organization === organization)
    .sort(sortByPosition)

/**
 * Returns a query function that fetches all calendars for a single organization.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of calendars that match a supplied org ID.
 */
export const readableForOrganization = (state) => (organization) =>
  forOrganization(state)(organization)
    .filter((calendar) => canRead(state)(organization, calendar.id))
    .sort(sortByName)

/**
 * Returns a string with a secure download token to access the
 * calendar CSV.
 * @param  {Object} state The current state of the redux store.
 * @return {String}       The current download token to access a calendar.
 */
export const downloadToken = (state) => state[NAME].downloadToken

/**
 * Returns a string with a secure download url to access the
 * calendar CSV.
 * @param  {Object} state The current state of the redux store.
 * @return {String}       The current download URL to access a calendar.
 */
export const downloadUrl = (state) => state[NAME].downloadUrl

/**
 * Retrieves permissions for a specific calendar object from the redux store.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching set of permissions if they exist.
 */
export const appliedPermissions = (state) => (calendarId) => {
  const permissions = entities(state).permissions
  const perms = Object.keys(entities(state).permissions).map(
    (k) => permissions[k]
  )
  const results =
    (perms &&
      perms.filter(
        (p) => parseInt(p.calendarId, 0) === parseInt(calendarId, 0)
      )) ||
    []
  return results
}
