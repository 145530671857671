import jwtDecode from "jwt-decode"
import { isBlank, isNotBlank } from "./helpers"
import { setCookie, getCookie, removeCookie } from "react-simple-cookie-store"

export const getItem = (key) => {
  const item = getCookie(key)
  return isBlank(item) ? undefined : item
}

export const setItem = (key, value) => {
  setCookie(key, value, 2)
}

export const removeItem = (key) => {
  removeCookie(key)
}

export const decode = (token) => (isNotBlank(token) ? jwtDecode(token) : {})

export const hasExpired = (token) => {
  if (isBlank(token)) {
    return true
  }
  const currentToken = decode(token)
  const { exp } = currentToken
  const now = new Date().getTime() / 1000
  const secondsRemaining = exp - now
  return secondsRemaining < 10
}
