import React, { Component } from "react"
import PropTypes from "prop-types"
import Color from "color"
import { isDefined, isNotBlank } from "../../utils/helpers"
import styles from "./ItemEventPreview.module.css"

/**
 * Renders an event bubble with the settings for the current item
 * that is being dragged.
 */
class ItemEventPreview extends Component {
  constructor(props) {
    super(props)
    const { fillColor } = props
    const testColor = isNotBlank(fillColor) ? fillColor : "#FFF"
    this.state = {
      light: Color(testColor).lighten(0.5),
    }
  }

  fontClassName() {
    const { font } = this.props
    return isDefined(font) && styles[font.toLowerCase()]
  }

  /**
   * Generates a list of styles to apply to the component based on the
   * the components state.
   *
   * @return {String} A string of joined CSS classes deliminated by spaces.
   */
  classNames() {
    const { start, end, simplified } = this.props
    const names = [styles.eventView, this.fontClassName()]
    if (start) {
      names.push(styles.starts)
    }
    if (end) {
      names.push(styles.ends)
    }
    if (simplified) {
      names.push(styles.simplified)
    }
    return names.join(" ")
  }

  render() {
    const {
      outlineColor,
      fillColor,
      textAlignment,
      fontStyle,
      fontWeight,
      fontSize,
      textDecoration,
      length,
      name,
      simplified,
      limited,
    } = this.props
    // Just an approximation as the calendar view is never the full screen.
    const widthScale = 0.95 * (limited ? 0.25 : simplified ? 0.33 : 1)
    return (
      <div
        style={{
          background: fillColor,
          border: `1px solid ${outlineColor}`,
          flexBasis: `${14.285714285714285 * length * widthScale}%`,
          width: `${14.285714285714285 * length * widthScale}%`,
          opacity: "0.25",
          pointerEvents: "none",
          cursor: "move",
          fontSize: `${simplified ? 0.675 : fontSize * 0.0625}rem`,
          fontWeight,
          fontStyle,
          textDecoration,
        }}
        className={this.classNames()}
      >
        <span
          className={`${styles.eventName} ${styles[textAlignment]}`}
          style={{ color: this.state.light ? "#000" : "#fff" }}
        >
          {name}
        </span>
      </div>
    )
  }
}

ItemEventPreview.propTypes = {
  /**
   * The name to display in the preview bubble.
   */
  name: PropTypes.string,

  /**
   * The CSS style for text alignment.
   */
  textAlignment: PropTypes.string,

  /**
   * The CSS style for the font.
   */
  font: PropTypes.string,

  /**
   * The css style for the font's style i.e. italic.
   */
  fontStyle: PropTypes.string,

  /**
   * The css style for the font-weight attribute i.e. bold.
   */
  fontWeight: PropTypes.string,

  /**
   * The css style for the font-size property.
   */
  fontSize: PropTypes.number,

  /**
   * The css style for the text-decoration property.
   */
  textDecoration: PropTypes.string,

  /**
   * The fill color of the event bubble.
   */
  fillColor: PropTypes.string,

  /**
   * The outline color for the event bubble.
   */
  outlineColor: PropTypes.string,

  /**
   * A boolean flag indicating whether or not to 'cap' the start of the bubble.
   */
  start: PropTypes.bool,

  /**
   * A boolean flag indicating whether or not to 'cap' the end of the bubble.
   */
  end: PropTypes.bool,

  /**
   * The length of the event bubble.
   */
  length: PropTypes.number,

  /**
   * Determines if a smaller rendering mode should be used.
   */
  simplified: PropTypes.bool,

  /**
   * Determines if a smaller rendering mode should be used.
   */
  limited: PropTypes.bool,
}

export default ItemEventPreview
