var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatStyles } from "utils";
import { Error } from "./Error";
export var getErrorMessage = function (_a) {
    var _b;
    var name = _a.name, errorMessage = _a.errorMessage, errors = _a.errors;
    return errorMessage !== null && errorMessage !== void 0 ? errorMessage : (_b = (errors !== null && errors !== void 0 ? errors : {})[name]) === null || _b === void 0 ? void 0 : _b.message;
};
var CONTAINER_STYLES = "flex flex-col";
var ICON_WRAPPER_STYLES = "absolute inset-y-0 flex text-contentAreaBorder";
var ICON_STYLES = "m-auto text-gray-500";
var WRAPPER_STYLES = "relative flex flex-grow flex-wrap";
var REQUIRED_STYLES = "text-sorbus-default text-xs mx-2 my-auto";
var getGrowStyle = function (grow) { return (grow ? "flex-grow" : ""); };
var getDisabledStyle = function (disabled) { return (disabled ? "opacity-75" : ""); };
var getIconPositionIf = function (searchField) {
    return searchField ? "left-0 ml-3" : "right-0 mr-3";
};
export var Field = function (_a) {
    var label = _a.label, htmlFor = _a.htmlFor, name = _a.name, className = _a.className, labelClassName = _a.labelClassName, required = _a.required, icon = _a.icon, errorMessage = _a.errorMessage, grow = _a.grow, children = _a.children, disabled = _a.disabled, searchField = _a.searchField;
    return (_jsxs("div", __assign({ className: concatStyles([
            CONTAINER_STYLES,
            getGrowStyle(grow),
            getDisabledStyle(disabled),
            className,
        ]) }, { children: [_jsxs("label", __assign({ htmlFor: htmlFor !== null && htmlFor !== void 0 ? htmlFor : name, className: concatStyles("flex", labelClassName) }, { children: [label, " ", required && (_jsx(FontAwesomeIcon, { icon: faAsterisk, className: REQUIRED_STYLES }, void 0))] }), void 0), _jsxs("div", __assign({ className: WRAPPER_STYLES }, { children: [children, icon ? (_jsx("span", __assign({ className: concatStyles([
                            ICON_WRAPPER_STYLES,
                            getIconPositionIf(searchField),
                        ]) }, { children: _jsx(FontAwesomeIcon, { icon: icon, className: ICON_STYLES }, void 0) }), void 0)) : (_jsx("span", {}, void 0)), errorMessage ? _jsx(Error, { children: errorMessage }, void 0) : null] }), void 0)] }), void 0));
};
Field.displayName = "Form.Field";
