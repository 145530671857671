import React, { useEffect, useRef } from "react"
import { AppsWidget, AppSelectAction, AppDefinition, Indicators } from "ui"
import {
  SetKeeperConnectModal,
  GoogleCalendarModal,
  useGoogleCalendarAPI,
} from "../../modals"
import { useEntity, useConnections, ConnectionResponse } from "../../api"
import setKeeper from "../../images/setkeeper-icon.png"
import googleCalendar from "../../images/google-calendar.png"

interface AppManagementWidgetProps {
  /**
   * The identifier for the org
   */
  subdomain: string
  /**
   * The id of the calendar to present permissions for.
   */
  calendarId: string
}

export const AppManagementWidget: React.FC<AppManagementWidgetProps> = ({
  subdomain,
  calendarId,
}) => {
  const [requestConnections, { loading }] = useConnections()

  const connections = [
    useEntity<ConnectionResponse>({ entity: "connections" }),
  ].flat()
  const { isAuthorized, loading: googleLoading } = useGoogleCalendarAPI()
  if (isAuthorized) {
    connections.push({
      name: "Google",
      calendarId: parseInt(calendarId, 10),
    })
  }
  const executed = useRef(false)
  useEffect(() => {
    if (!executed.current) {
      requestConnections()
      executed.current = true
    }
  }, [requestConnections, executed])

  const [appAction, setAppAction] = React.useState<{
    app: string
    action: AppSelectAction
  } | null>(null)

  const clearModals = () => {
    setAppAction(null)
  }

  const handleSelectApp = (
    { name: app }: AppDefinition,
    action: AppSelectAction
  ) => {
    setAppAction({ app, action })
  }

  const connectedServiceNames = connections
    ?.filter((c) => c.calendarId === parseInt(calendarId, 10))
    ?.map((connection) => connection.name)

  const availableApps = [
    {
      name: "Google Calendar",
      icon: googleCalendar,
      description: "Import an existing Google Calendar to your project",
      serviceName: "Google",
    },
    {
      name: "SetKeeper",
      icon: setKeeper,
      description: "Sync your project to SetKeeper in real time",
      serviceName: "SetKeeper",
    },
    // {
    //   name: "Airtable",
    //   icon: airTable,
    //   description: "Sync your project to an Airtable base in real time",
    //   serviceName: "SetKeeper",
    // },
  ].filter((app) => !connectedServiceNames?.includes(app.serviceName))

  const connectedApps = [
    {
      name: "SetKeeper",
      icon: setKeeper,
      description:
        "This project is synced to SetKeeper. You can disconnect anytime.",
      serviceName: "SetKeeper",
    },
    {
      name: "Google Calendar",
      icon: googleCalendar,
      description: "Import an existing Google Calendar to your project",
      serviceName: "Google",
    },
    // {
    //   name: "Airtable",
    //   icon: airTable,
    //   description:
    //     "This project is synced to an Air Table base. You can disconnect at anytime.",
    //   serviceName: "Google",
    // },
  ].filter((app) => connectedServiceNames?.includes(app.serviceName))

  return (
    <div className="relative">
      <AppsWidget
        className="mt-2 m-1"
        onSelectApp={handleSelectApp}
        availableApps={availableApps}
        connectedApps={connectedApps}
      />
      {loading || googleLoading ? <Indicators.AbsoluteOverlay /> : null}
      {appAction && appAction.app === "SetKeeper" && (
        <SetKeeperConnectModal
          onClose={clearModals}
          subdomain={subdomain}
          calendarId={parseInt(calendarId)}
          draggable
          open
        />
      )}
      {appAction && appAction.app === "Google Calendar" && (
        <GoogleCalendarModal
          onClose={clearModals}
          subdomain={subdomain}
          calendarId={parseInt(calendarId)}
          draggable
          open
        />
      )}
    </div>
  )
}
