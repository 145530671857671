import React from "react"
import PropTypes from "prop-types"
import styles from "./Fun.module.css"

const Fun = (props) => <div className={styles.container}>{props.children}</div>

Fun.propTypes = {
  children: PropTypes.node,
}

export default Fun
