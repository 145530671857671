import moment from "moment"

/**
 * Recursively searches for a date by incrementing a date by a specific
 * amount until a non-conflicting date has been reached.
 * @param  {Object} date          A momentJS date object representing the initial start date.
 * @param  {Integer} step         The amount to increment by when a conflicting date is found.
 * @param  {Array} interruptions  An array of interruption dates.
 * @return {Object}               A momentJS date representing a date without conflicts.
 */
export const getUsableDate = (date, step, interruptions) => {
  return interruptions && interruptions.includes(moment.utc(date).toISOString())
    ? getUsableDate(moment.utc(date).add(step, "d"), step, interruptions)
    : date
}

/**
 * Continues to shift forward until a suitable start date has been found.
 * @param  {Object} date          A momentJS date object representing the initial start date.
 * @param  {Array} interruptions  An array of interruptions
 * @return {Object}               A momentJS date representing a date without conflicts.
 */
export const getUsuableStartDate = (date, interruptions) =>
  getUsableDate(date, 1, interruptions)

/**
 * Continues to shift backward until a suitable end date has been found.
 * @param  {Object} date          A momentJS date object representing the initial end date.
 * @param  {Array} interruptions  An array of interruptions
 * @return {Object}               A momentJS date representing a date without conflicts.
 */
export const getUsuableEndDate = (date, interruptions) =>
  getUsableDate(date, -1, interruptions)
