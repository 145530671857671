var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { stroke } from "./stroke";
import { makePx } from "./px";
/**
 * Render a simple line path on an HTML5 2d canvas.
 * @param ctx The current canvas context to draw on.
 * @param props The properties defining the appearance of the rectangle.
 */
export var line = function (ctx, _a) {
    var from = _a.from, to = _a.to, strokeProps = __rest(_a, ["from", "to"]);
    var px = makePx(strokeProps.scale);
    ctx.beginPath();
    ctx.moveTo(px(from.x), px(from.y));
    ctx.lineTo(px(to.x), px(to.y));
    stroke(ctx, strokeProps);
};
