import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { requestInvitations, analyticsSelector } from "../../api"

export const useInvitations = (
  days: number,
  status?: "Pending" | "Accepted" | "All"
) => {
  const invitations = useSelector(analyticsSelector.invitations)(
    days,
    status?.toLowerCase()
  )
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [daysLoaded, setDaysLoaded] = useState(0)
  if (days && daysLoaded !== days && !loading) {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(requestInvitations(days))
      setLoading(false)
      setDaysLoaded(days)
    }
    fetchData()
  }
  return {
    loading,
    invitations,
  }
}
