var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { findAll } from "highlight-words-core";
var STRIKE_CLASS = "text-sorbus-default bg-danger bg-opacity-25 line-through";
var HIGHLIGHT_CLASS = "text-sorbus-default border-sorbus-default bg-opacity-25 font-bold";
var REPLACE_CLASS = "text-lochivar-default bg-lochivar bg-opacity-25 font-bold";
export var Highlight = function (_a) {
    var textToHighlight = _a.content, searchWords = _a.match, replace = _a.replace, caseSensitive = _a.caseSensitive, _b = _a.replaceClass, replaceClass = _b === void 0 ? REPLACE_CLASS : _b, _c = _a.strikeClass, strikeClass = _c === void 0 ? STRIKE_CLASS : _c, _d = _a.highlightClass, highlightClass = _d === void 0 ? HIGHLIGHT_CLASS : _d;
    var chunks = findAll({
        searchWords: searchWords,
        textToHighlight: textToHighlight,
        caseSensitive: caseSensitive,
    });
    var highlightedText = chunks.map(function (chunk) {
        var end = chunk.end, highlight = chunk.highlight, start = chunk.start;
        var text = textToHighlight.substr(start, end - start);
        var key = [text, end, highlight, start].join("-");
        if (highlight) {
            return (_jsxs(React.Fragment, { children: [_jsx("mark", __assign({ className: replace ? strikeClass : highlightClass }, { children: text }), void 0), replace ? _jsx("mark", __assign({ className: replaceClass }, { children: replace }), void 0) : null] }, key));
        }
        else {
            return _jsx("span", { children: text }, key);
        }
    });
    return _jsx("p", { children: highlightedText }, void 0);
};
