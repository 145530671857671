import { createSelector, createSelectorCreator, defaultMemoize } from "reselect"
import { entities } from "./baseSelector"
import isEqual from "lodash/isEqual"
import uniq from "lodash/uniq"
import flatten from "lodash/flatten"
import moment from "moment"
import { calendarIdsForReport } from "./reportSelector"

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

/**
 * Returns all holiday entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the holiday entities from state.
 */
const allHolidays = (state) => entities(state).holidays

/**
 * Returns all holiday schedules entities in the cache.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the holiday schedule entities from state.
 */
export const allHolidaySchedules = (state) =>
  Object.values(entities(state).holidaySchedules || {})

/**
 * Returns all holidays entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} holidays The current state of the redux store.
 * @return {Array}        A function that returns an array of holiday entities.
 */
/* eslint-disable no-confusing-arrow */
const availableHolidays = (holidays) =>
  Object.keys(holidays)
    .map((key) => holidays[key])
    .sort((a, b) =>
      b.startsAt < a.startsAt ? 1 : b.startsAt > a.startsAt ? -1 : 0
    )
    .filter((entity) => !entity.isDeleted)

export const all = createSelector(allHolidays, availableHolidays)
/* eslint-enable no-confusing-arrow */

/**
 * A convenience method for extracting the 'calendarId' value from
 * props for reselect memoization.
 * @param  {Object} state The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Integer}      The calendarId assigned in props.
 */
const getCalendarId = (state, props) => {
  if (props.params) {
    if (props.params.reportId) {
      return calendarIdsForReport(state)(props.params.reportId)
    }
    return [parseInt(props.params.calendarId, 0)]
  }
  return []
}

/**
 * A convenience method for extracting the 'snapshotId' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Integer}      The snapshotId assigned in props.
 */
const getSnapshotId = (_, props) => props.params && props.params.snapshotId

/**
 * A convenience method for extracting the 'startDate' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {String}      The startDate assigned in props.
 */
const getStartDate = (_, props) => props.params && props.params.startDate

/**
 * A convenience method for extracting the 'endDate' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {String}      The endDate assigned in props.
 */
const getEndDate = (_, props) => props.params && props.params.endDate

/**
 * Returns a query function that fetches all holidays for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of holidays that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarId) =>
  all(state).filter((holiday) =>
    flatten([calendarId])
      .map((id) => parseInt(id, 0))
      .includes(parseInt(holiday.calendarId, 0))
  )

/**
 * Returns a query function that fetches all holidays for a single snapshot.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of holidays that match a supplied calendar ID.
 */
export const forSnapshot = (state) => (snapshotId) =>
  all(state).filter(
    (holiday) => parseInt(holiday.snapshotId, 0) === parseInt(snapshotId, 0)
  )

/**
 * Retrieves a specific holiday object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching snapshot if one exists.
 */
export const find = (state) => (holidayId, calendarId) => {
  const hashId = [holidayId, calendarId].filter((i) => i).join("#")
  const entity = entities(state).styles[hashId]
  return entity && !entity.isDeleted && entity
}

/**
 * Maps a set of holiday objects so that the start and end dates apply to a set of
 * specified years.
 * @param  {Array} holidays   An array of holiday objects.
 * @param  {Array} years      An array of calendar years to apply to the holiday events.
 * @return {Array} An array of holiday objects with dates mapped to the specified years.
 */
const mapHolidaysForYears = (holidays, ...years) => {
  const mappedHolidays = uniq(years).map((y) =>
    holidays.map((holiday) => {
      const yearDiff =
        moment.utc(holiday.endsAt).year() - moment.utc(holiday.startsAt).year()
      return {
        ...holiday,
        startsAt: moment.utc(holiday.startsAt).year(y).utc(),
        endsAt: moment
          .utc(holiday.endsAt)
          .year(y + yearDiff)
          .utc(),
      }
    })
  )
  return flatten(mappedHolidays)
}

/**
 * Returns a memoized selector that returns a filtered set of
 * events for a specific calendar.
 * @type {Function}
 */
export const getForMonth = createDeepEqualSelector(
  [all, getCalendarId, getSnapshotId, getStartDate, getEndDate],
  (holidays, calendarIds, snapshotId, startDate, endDate) => {
    let filterBlock = (holiday) =>
      calendarIds.includes(parseInt(holiday.calendarId, 0))
    if (snapshotId) {
      filterBlock = (holiday) =>
        parseInt(holiday.snapshotId, 0) === parseInt(snapshotId, 0)
    }
    const applicableHolidays = holidays.filter(filterBlock)
    const startYear = moment.utc(startDate).subtract(1, "month").year()
    const endYear = moment.utc(endDate).add(1, "month").year()
    return mapHolidaysForYears(applicableHolidays, startYear, endYear)
  }
)
