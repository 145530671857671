import { useApiRequest } from "./useApiRequest"

export interface Referral {
  id?: number
  name: string
  email: string
  company: number
}

export interface CreateReferralBody {
  name: string
  email: string
  company: string
}

export const useCreateReferral = () => {
  const postReferral = useApiRequest<Referral, CreateReferralBody>({
    method: "POST",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/referrals`,
  })
  return postReferral
}
