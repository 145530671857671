import React, { useRef, useEffect } from "react"
import { MultiSelectField } from "ui"
import { useSetKeeperProjects } from "../../api"

export const SetKeeperProjectSelectField: React.FC<{
  value?: string | null
  onSelect: (value: string | null) => void
  className?: string
}> = ({ onSelect: handleSelect, value, className }) => {
  const [requestProjects, { data }] = useSetKeeperProjects()

  const executed = useRef(false)
  useEffect(() => {
    if (executed.current) return
    requestProjects()
    executed.current = true
  })
  const projects = data ?? []
  return (
    <MultiSelectField
      name="project"
      label="Select the SetKeeper Project to Sync"
      options={projects?.map((p) => ({
        label: p.name,
        value: `${p.id}`,
        id: `${p.id}`,
      }))}
      value={value ?? undefined}
      onSelect={handleSelect}
      placeholder="Select a Project"
      closeMenuOnSelect={true}
      className={className}
    />
  )
}
