import Button from "./Button.react"
import Break from "./Break.react"
import Message from "./Message.react"
import Group from "./Group.react"
import Input from "./Input.react"
import AssistantText from "./AssistantText.react"
import AssistantCol from "./AssistantCol.react"
import FieldCol from "./FieldCol.react"
import Field from "./Field.react"
import Collapsable from "./Collapsable.react"
import Divider from "./Divider.react"
import HelperMessage from "./HelperMessage.react"
import RadioGroup from "./RadioGroup.react"
import RadioField from "./RadioField.react"
import Grid from "./Grid.react"
import GridRow from "./GridRow.react"
import Heading from "./Heading.react"
import ModalFieldInput from "./ModalFieldInput.react"
import ModalFieldTextArea from "./ModalFieldTextArea.react"
import ModalColorInput from "./ModalColorInput.react"
import Split from "./Split.react"
import Label from "./Label.react"

const content = {
  Button,
  Break,
  Collapsable,
  Message,
  Group,
  Input,
  HelperMessage,
  Grid,
  GridRow,
  AssistantText,
  AssistantCol,
  FieldCol,
  Field,
  Divider,
  Label,
  ModalFieldInput,
  ModalFieldTextArea,
  ModalColorInput,
  RadioGroup,
  RadioField,
  Heading,
  Split,
}
export default content
