import { useApiRequest } from "./useApiRequest"

export interface CurrentUserInvitation {
  id: string
  email: string
  name: string
  accepted: boolean
  created_at: string
  updated_at: string
  subdomain: string
  access_level: string
  organization_id: number
  user_id: number
  granular_permissions: string[]
}

export interface CurrentProfileResponse {
  id: number
  name: string
  email: string
  user_hash: string
  created_at: string
  granular_permissions: string[]
  invitations: CurrentUserInvitation[]
}

export const useCurrentUserProfile = () => {
  const getCurrentProfile = useApiRequest<CurrentProfileResponse, {}>({
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/me`,
    method: "GET",
  })
  return getCurrentProfile
}
