import React, { useState, useEffect } from "react"
import {
  Modal,
  ModalProps,
  GroupForm,
  GroupFormValues,
  AsyncSubmitHandler,
} from "ui"
import toast from "react-hot-toast"
import { useCreateGroup, useGroup, useUpdateGroup } from "../api"
import { useAddGroups } from "../api/atoms"
import { wait } from "utils"

export interface GroupModalInitialValues {
  position?: number
  parentGroupUuid?: string
}

export interface GroupModalProps {
  groupUuid?: string
  subdomain: string
  position?: number
  initialValues?: GroupModalInitialValues
}

export const GroupModal: React.FC<GroupModalProps & ModalProps> = ({
  onClose: handleDismiss,
  groupUuid,
  subdomain,
  onFocus: handleFocus,
  zIndex,
  position,
  initialValues,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const addGroups = useAddGroups()

  const [getGroup, { loading: loadingGroup, data: group }] = useGroup(subdomain)
  useEffect(() => {
    if (groupUuid && !group && !loading) {
      getGroup({ params: { uuid: groupUuid } })
    }
  }, [groupUuid, getGroup, loading, group])

  const [updateGroup] = useUpdateGroup(subdomain)
  const [createGroup] = useCreateGroup(subdomain)

  const onSubmit: AsyncSubmitHandler<GroupFormValues> = async (
    data: GroupFormValues
  ) => {
    setLoading(true)
    await wait(1)
    try {
      const response = groupUuid
        ? await updateGroup({ body: data, params: { uuid: groupUuid ?? "" } })
        : await createGroup({
            body: {
              ...data,
              ...initialValues,
              groupUuid: initialValues?.parentGroupUuid,
            },
          })

      if (response) {
        console.error("response", response)
        addGroups([response])
      }

      toast.success(
        `Successfully ${groupUuid ? "updated" : "created"} group "${
          data.name
        }".`
      )
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
    return undefined
  }

  const defaultValues = {
    name: group?.name ?? "",
  }

  return (
    <Modal
      {...props}
      title={groupUuid ? "Edit Group" : "New Group"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
    >
      <GroupForm
        loading={loading || loadingGroup}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Modal>
  )
}
