import React from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import {
  Button,
  Control,
  ControlGroup,
  Fields,
  Group,
  GroupField,
  TextField,
  useDefaultValueListener,
} from "ui"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faCalendar,
  faSortNumericDown,
} from "@fortawesome/pro-solid-svg-icons"

export interface CalendarSettingsFormValues {
  name: string
  groupUuid: string
  showWeekNumbers: string
}

interface CalendarSettingsFormProps {
  onSave?: (calendar: any) => void
  defaultValues?: any
  groups: Group[]
  onCreateGroup?: (value: string) => Promise<string | null | void>
}

const schema = yup.object({
  name: yup.string().required(),
  groupUuid: yup.string(),
  showWeekNumbers: yup.string(),
})

export const CalendarSettingsForm: React.FC<CalendarSettingsFormProps> = ({
  defaultValues,
  onSave,
  groups,
  onCreateGroup: handleCreateGroup,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<CalendarSettingsFormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  useDefaultValueListener<CalendarSettingsFormValues>(defaultValues, reset)

  const [groupUuid, showWeekNumbers] = watch(["groupUuid", "showWeekNumbers"])

  const onSubmit: SubmitHandler<CalendarSettingsFormValues> = async (data) => {
    if (onSave) {
      try {
        await onSave({ ...defaultValues, ...data })
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleSelectCreateGroup = async (value: string) => {
    const result = await handleCreateGroup?.(value)
    if (result) {
      setValue("groupUuid", result)
    }
  }

  const handleGroupSelect = (newVal: string | null) => {
    setValue("groupUuid", newVal ?? "", {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
      <Fields register={register} grow>
        <TextField
          {...register("name")}
          label="Calendar Name"
          placeholder="Calendar Name"
          errors={errors as any}
          className="w-full"
          labelClassName="text-sm font-bold"
          required
        />
        <GroupField
          name="groupUuid"
          label="Calendar Group (folder)"
          placeholder="(optional)"
          options={groups}
          value={groupUuid}
          onSelect={handleGroupSelect}
          onCreate={handleSelectCreateGroup}
          creatable={handleCreateGroup ? true : false}
          className="w-full"
          labelClassName="text-sm font-bold"
          clearable
          selfControllable
        />
        <input type="hidden" name="groupUuid" />
        <ControlGroup
          name="showWeekNumbers"
          label="Week Numbers"
          labelClassName="text-sm font-bold"
          selected={showWeekNumbers}
        >
          <Control value="none" tooltip="None">
            <FontAwesomeIcon icon={faBan} />
          </Control>
          <Control value="year" tooltip="Week of Year">
            <FontAwesomeIcon icon={faCalendar} />
          </Control>
          <Control value="calendar_start" tooltip="Week of Project">
            <FontAwesomeIcon icon={faSortNumericDown} />
          </Control>
        </ControlGroup>
      </Fields>
      <Button type="submit" className="mt-2 w-full flex-grow mx-2">
        Update Calendar
      </Button>
    </form>
  )
}
