var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Field, getErrorMessage } from "./Field";
import { SimpleSelect } from "./SimpleSelect";
var FIELD_LABEL_STYLES = "capitalize font-semibold text-base mb-1";
export var SimpleSelectField = React.forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, name = _a.name, value = _a.value, options = _a.options, onChange = _a.onChange, placeholder = _a.placeholder, unit = _a.unit, style = _a.style, className = _a.className, disabled = _a.disabled, errorMessage = _a.errorMessage, errors = _a.errors, filedProps = __rest(_a, ["id", "label", "name", "value", "options", "onChange", "placeholder", "unit", "style", "className", "disabled", "errorMessage", "errors"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    return (_jsx(Field, __assign({ name: name, label: label, className: className, labelClassName: FIELD_LABEL_STYLES, style: style, errorMessage: determinedErrorMessage }, filedProps, { children: _jsx(SimpleSelect, { ref: ref, id: id, name: name, unit: unit, value: value, options: options, disabled: disabled, onChange: onChange, placeholder: placeholder }, void 0) }), void 0));
});
