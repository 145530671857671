import React from "react"
import PropTypes from "prop-types"
import styles from "./InlineSetting.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/* eslint-disable complexity */
const classNames = (visible, active, disabled, useAdditionalSpacing) =>
  [styles.inlineSetting]
    .concat(visible || active ? styles.visible : styles.hidden)
    .concat(useAdditionalSpacing ? styles.additionalSpacing : "")
    .concat(disabled ? styles.disabled : "")
    .join(" ")
/* eslint-enable complexity */

const InlineSetting = ({
  visible,
  active,
  activeIcon,
  defaultIcon,
  handleClick,
  disabled,
  useAdditionalSpacing,
}) => (
  <FontAwesomeIcon
    onClick={(e) => {
      e.stopPropagation()
      !disabled && handleClick()
    }}
    icon={active ? activeIcon : defaultIcon}
    className={classNames(visible, active, disabled, useAdditionalSpacing)}
  />
)

InlineSetting.propTypes = {
  /**
   * If true - it should indicate that the icon should be
   * visible. If false it will simply have an opacity of 0
   * so that the layout remains consistent.
   */
  visible: PropTypes.bool,

  /**
   * If true - it should indicate that the event types
   * that belong to this category should be enabled.
   */
  active: PropTypes.bool,

  /**
   * If true - the view will apply a CSS class to render
   * with a larger amount of spacing
   */
  useAdditionalSpacing: PropTypes.bool,

  /**
   * Indicates whether or not the control can be interacted
   * with by the user.
   */
  disabled: PropTypes.bool,

  /**
   * The font awesome class to display when the item is
   * engaged.
   */
  activeIcon: PropTypes.object.isRequired,

  /**
   * The font awesome class to display when the item is
   * not engaged.
   */
  defaultIcon: PropTypes.object.isRequired,

  /**
   * An event handler that will be fired when this component
   * is clicked.
   */
  handleClick: PropTypes.func.isRequired,
}

export default InlineSetting
