import React, { Component } from "react"
import PropTypes from "prop-types"
import { isNotBlank } from "../../utils/helpers"
import Group from "./Group.react"
import Input from "./Input.react"
import Label from "./Label.react"

class ModalFieldInput extends Component {
  focus() {
    this.textInput.input.focus()
  }

  select(start, end) {
    if (typeof start === "number") {
      const endIndex = end || 9999
      this.textInput.input.setSelectionRange(start, endIndex)
    } else {
      this.textInput.input.select()
    }
  }

  selectPrefix() {
    this.focus()
    const value = this.textInput.input.value
    let start = null
    let end = null
    if (value && value.length > 1) {
      const chunks = value.split(" ")
      const lastValue = chunks[chunks.length - 1]
      if (!isNaN(chunks[0])) {
        start = 0
        end = chunks[0].length
      } else if (!isNaN(lastValue)) {
        start = value.length - lastValue.length
      }
    }
    this.select(start, end)
  }

  value() {
    return this.textInput.input.value
  }

  render() {
    const {
      meta,
      customizations,
      input,
      leadingAddOn,
      label,
      disabled,
      placeholder,
      type,
    } = this.props
    const showError = isNotBlank(meta.error) && meta.touched
    const changeHandler =
      (customizations && customizations.onChange) || input.onChange
    return (
      <Group error={showError}>
        <Label htmlFor={`${input.name}`}>{label}</Label>
        <div className="input-group">
          {leadingAddOn && (
            <span className="input-group-btn">{leadingAddOn}</span>
          )}
          <Input
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            ref={(textInput) => {
              this.textInput = textInput
            }}
            {...input}
            onChange={(e) => changeHandler(e)}
          />
        </div>
        {showError && <span className="error">{meta.error}</span>}
      </Group>
    )
  }
}

ModalFieldInput.propTypes = {
  meta: PropTypes.object,
  customizations: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  leadingAddOn: PropTypes.node,
}

export default ModalFieldInput
