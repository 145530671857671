var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from "luxon";
import clsx from "clsx";
var FONT_SIZE = 12;
export var WeekDayLabelHTML = function (_a) {
    var x = _a.x, y = _a.y, font = _a.font, todayFillColor = _a.todayFillColor, labelColor = _a.labelColor, dateString = _a.date, today = _a.today, additionalText = _a.additionalText, limited = _a.limited, highContrast = _a.highContrast;
    var date = DateTime.fromISO(dateString, { zone: "utc" }).startOf("day");
    var printableDate = date.toFormat("d");
    if (parseInt(printableDate) === 1) {
        printableDate = date.toFormat("MMM d");
    }
    return (_jsxs("p", __assign({ style: {
            paddingLeft: x !== null && x !== void 0 ? x : 0,
            paddingTop: y !== null && y !== void 0 ? y : 0,
            fontSize: FONT_SIZE - (limited ? 4 : 0),
            fontFamily: font,
            lineHeight: "1em",
        }, className: "w-full flex mb-auto" }, { children: [_jsx("span", __assign({ style: {
                    color: today ? "#fff" : labelColor,
                    background: today ? todayFillColor : "",
                    padding: 2,
                }, className: clsx(today && "rounded m3-2", highContrast && "font-bold") }, { children: printableDate }), void 0), additionalText ? (_jsx("span", __assign({ style: {
                    color: labelColor,
                    padding: 2,
                } }, { children: additionalText }), void 0)) : null] }), void 0));
};
