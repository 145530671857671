import React, { useState } from "react"
import {
  Modal,
  BatchForm,
  ModalProps,
  BatchFormValues,
  AsyncSubmitHandler,
} from "ui"
import toast from "react-hot-toast"
import { useParams } from "react-router"
import {
  useCategories,
  useTags,
  useCalendarMacro,
  useStyles,
  useCalendar,
} from "../api"
import { wait } from "utils"

export interface BatchModalProps {
  itemUuids?: string[]
}

export const BatchModal: React.FC<BatchModalProps & ModalProps> = ({
  onClose: handleDismiss,
  onFocus: handleFocus,
  zIndex,
  itemUuids = [],
}) => {
  const [loading, setLoading] = useState(false)
  const { calendarId, subdomain } = useParams<{
    subdomain: string
    calendarId: string
  }>()
  const [runMacro] = useCalendarMacro(subdomain)

  const { loading: loadingCategories, categories } = useCategories(
    subdomain,
    calendarId
  )

  const { refetch: reloadCalendar } = useCalendar(subdomain, calendarId, {
    lazy: true,
  })
  const { loading: loadingTags, tags } = useTags(subdomain, calendarId)
  const { loading: loadingStyles, styles } = useStyles(subdomain, calendarId)

  const loadingContent =
    loading || loadingCategories || loadingTags || loadingStyles

  const onSubmit: AsyncSubmitHandler<BatchFormValues> = async (data) => {
    setLoading(true)
    await wait(1)
    try {
      const response = await runMacro({
        body: {
          ...data,
          tags: data.tags
            ?.replaceAll("#", "")
            .split(",")
            .map((t: string) => t.trim()),
          itemUuids,
        },
        params: { calendarId },
      })
      if (!response) {
        setLoading(false)
        return {
          error: "Something went wrong",
          errors: { network: "Something went wrong" },
        }
      }
      toast.success(`Successfully updated ${itemUuids.length} items.`)
      await reloadCalendar()
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  return (
    <Modal
      title={"Perform Macro"}
      onClose={handleDismiss}
      onFocus={handleFocus}
      zIndex={zIndex}
      open
      draggable
    >
      <BatchForm
        loading={loadingContent}
        onSubmit={onSubmit}
        categories={categories}
        styles={styles}
        itemUuids={itemUuids}
        availableTags={tags}
      />
    </Modal>
  )
}
