import { useState, useEffect, useRef } from "react"

type RequestStatus = "pending" | "loading" | "complete" | "idle"
export interface RequestConfig {
  lazy?: boolean
}

export const useRequest = (
  promise: () => Promise<any>,
  config?: RequestConfig
) => {
  const [status, setStatus] = useState<RequestStatus>(
    config?.lazy ? "idle" : "pending"
  )
  const response = useRef(null)
  useEffect(() => {
    const load = async () => {
      setStatus("loading")
      response.current = await promise()
      setStatus("complete")
    }
    if (status === "pending") {
      load()
    }
  }, [setStatus, status, promise])
  const refetch = () => setStatus("pending")
  return {
    response: response.current,
    refetch,
    loading: !["idle", "complete"].includes(status),
  }
}
