import React from "react"
import PropTypes from "prop-types"
import styles from "./HelperMessage.module.css"

const HelperMessage = ({ children }) => (
  <p className={styles.helperMessage}>{children}</p>
)

HelperMessage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HelperMessage
