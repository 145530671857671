var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Creatable from "react-select/creatable";
export var InvitationSelect = React.forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, placeholder = _a.placeholder, _b = _a.options, options = _b === void 0 ? [] : _b, handleOnInputChange = _a.onChange, handleSelect = _a.onSelect, handleCreate = _a.onCreate, value = _a.value, disabled = _a.disabled, clearable = _a.clearable, creatable = _a.creatable, _c = _a.formatCreateLabel, formatCreateLabel = _c === void 0 ? function (inputValue) {
        return "Invite '" + inputValue + "' to collaborate";
    } : _c;
    var defaultValue = options.find(function (s) { return s.value === value; });
    var handleChange = function (s) {
        var _a;
        handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect((_a = s === null || s === void 0 ? void 0 : s.value) !== null && _a !== void 0 ? _a : null);
    };
    return (_jsxs("div", __assign({ className: "w-full flex-grow flex" }, { children: [_jsx(Creatable, { className: "pro__client__multi__select_container w-full", classNamePrefix: "pro__client__multi__select", name: name, options: options, value: defaultValue, defaultValue: defaultValue, getOptionLabel: function (option) { var _a, _b; return [(_a = option.name) !== null && _a !== void 0 ? _a : "", (_b = option.email) !== null && _b !== void 0 ? _b : ""].join("::"); }, 
                // getOptionValue={(option: Invitation) => option.id ?? ""}
                onInputChange: handleOnInputChange, onChange: handleChange, onCreateOption: handleCreate, placeholder: placeholder, isClearable: clearable, isDisabled: disabled, formatCreateLabel: formatCreateLabel, createOptionPosition: "last", isValidNewOption: function (inputValue) {
                    return inputValue.length > 0 && (creatable !== null && creatable !== void 0 ? creatable : false);
                }, formatOptionLabel: function (option) {
                    var _a, _b, _c;
                    return (_jsxs("p", __assign({ className: "flex flex-col" }, { children: [_jsx("span", __assign({ className: "text-sm font-bold text-left flex" }, { children: (_c = (_a = option.name) !== null && _a !== void 0 ? _a : (_b = option.label) === null || _b === void 0 ? void 0 : _b.split("::")[0]) !== null && _c !== void 0 ? _c : "Unnamed " + option.uuid }), void 0), option.email && (_jsx("span", __assign({ className: "text-sm my-auto text-left flex" }, { children: option.email }), void 0))] }), void 0));
                }, styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); } }, menuPortalTarget: document.body, allowCreateWhileLoading: true, closeMenuOnSelect: true }, void 0), _jsx("input", { type: "hidden", ref: ref, name: name, id: id }, void 0)] }), void 0));
});
InvitationSelect.displayName = "Form.InvitationSelect";
