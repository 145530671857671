import { useApiRequest } from "./useApiRequest"

export interface AnalyticResult {
  id: string
  metric_type: string
  resource_type: string
  resource_id: number
  label: string
  value: string
  meta_1: string
  meta_2: string
  meta_3: string
}

export interface ActiveOrgsAnalyticBody {
  days: number
}

export const useActiveOrgsAnalytics = (days: number) => {
  const getActiveOrgs = useApiRequest<
    AnalyticResult[],
    ActiveOrgsAnalyticBody | undefined
  >({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/analytics/${days}/active_orgs`,
  })
  return getActiveOrgs
}
