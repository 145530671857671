import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  AutoCompleteField,
  DropDownListItemType,
  List,
  TabGroup,
  Tab,
  Dismissable,
} from "ui"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
  faBan,
  faEye,
  faPencil,
  faCog,
} from "@fortawesome/pro-regular-svg-icons"
import {
  calendarSelector,
  invitationSelector,
  createInvitationPermission,
  updateInvitationPermission,
  requestInvitationPermissions,
} from "../../api"
import { faCalendarCheck } from "@fortawesome/pro-light-svg-icons"

interface InvitationPermissionWidgetProps {
  /**
   * The identifier for the org
   */
  subdomain: string
  /**
   * The id of the calendar to present permissions for.
   */
  invitationId: string
}

interface Permission {
  read: boolean
  write: boolean
  manage: boolean
  invitationId: number
  calendarId: number
}

const noAccess = ({ read, write, manage }: Permission) =>
  !read && !write && !manage
const readOnly = ({ read, write }: Permission) => read && !write
const readWrite = ({ write, manage }: Permission) => !manage && write
const canManage = ({ manage }: Permission) => manage
const contains = (str: string, match: string) =>
  str.toLowerCase().indexOf(match.toLowerCase()) > -1

export const InvitationPermissionWidget: React.FC<InvitationPermissionWidgetProps> = ({
  subdomain,
  invitationId,
}) => {
  const [error, setError] = useState("")
  const [matches, setMatches] = useState(null as any)
  const dispatch = useDispatch()
  const calendars = useSelector(calendarSelector.forOrganization)(subdomain)
  const permissions = useSelector(invitationSelector.availablePermissions)(
    invitationId
  )

  useEffect(() => {
    dispatch(requestInvitationPermissions(subdomain, invitationId))
  }, [dispatch, invitationId, subdomain])

  const activePermissions = permissions.filter((p: Permission) => !noAccess(p))
  const hasAccess = (id: any) =>
    activePermissions.map((p: any) => p.calendarId).includes(id)
  const availableCalendars = (matches ?? calendars).filter(
    (c: any) => !hasAccess(c.id)
  )

  const convertToItemWithIcon = (icon?: IconDefinition) => (i: any) => ({
    label: (calendars.filter((c: any) => c.id === i.calendarId)[0] ?? {}).name,
    id: i.calendarId,
    icon,
  })

  const findCalendars = (value: string) => {
    setMatches(calendars.filter((i: any) => contains(i.name, value)))
  }

  const updatePermission = (p: Permission) => {
    dispatch(updateInvitationPermission(subdomain, p.invitationId, p))
  }

  const autoCompleteResults = availableCalendars
    .map((i: any) => ({
      label: i.name,
      secondaryLabel: i.group,
      id: i.id,
      value: i.id,
      icon: faCalendarCheck,
    }))
    .filter((_: any, i: number) => i < 10)

  return (
    <div className="h-full">
      <AutoCompleteField
        items={autoCompleteResults}
        label="Add Calendar"
        name="calendarField"
        placeholder="Name of existing calendar"
        onValueChanged={findCalendars}
        onSelectItem={(c: DropDownListItemType) => {
          const existingPermission = permissions.find(
            (p: Permission) => p.calendarId === parseInt(c.id)
          )
          const defaultPermission = {
            read: true,
            write: false,
            manage: false,
          }
          dispatch(
            existingPermission
              ? updateInvitationPermission(
                  subdomain,
                  existingPermission.invitationId,
                  { ...existingPermission, ...defaultPermission }
                )
              : createInvitationPermission(subdomain, invitationId, {
                  calendarId: c.id,
                  ...defaultPermission,
                })
          )
        }}
      />
      {error.length > 0 ? (
        <Dismissable
          title="Could Not Update Permission"
          onDismiss={() => setError("")}
          type="error"
        >
          <p className="text-sm">{error}</p>
        </Dismissable>
      ) : null}
      {activePermissions.length > 1 ? (
        <>
          <h3 className="text-sm font-semibold mt-2">Calendars</h3>
          <List
            items={activePermissions.map((p: any) => {
              return {
                ...convertToItemWithIcon()(p),
                accessory: () => {
                  return (
                    <TabGroup>
                      <Tab
                        icon={faBan}
                        selected={noAccess(p)}
                        onClick={() =>
                          updatePermission({
                            ...p,
                            read: false,
                            write: false,
                            manage: false,
                          })
                        }
                        grow={false}
                        toolTip="Remove"
                        compact
                      />
                      <Tab
                        icon={faEye}
                        selected={readOnly(p)}
                        onClick={() =>
                          updatePermission({
                            ...p,
                            read: true,
                            write: false,
                            manage: false,
                          })
                        }
                        grow={false}
                        toolTip="View"
                        compact
                      />
                      <Tab
                        icon={faPencil}
                        selected={readWrite(p)}
                        onClick={() =>
                          updatePermission({
                            ...p,
                            read: true,
                            write: true,
                            manage: false,
                          })
                        }
                        grow={false}
                        toolTip="Edit"
                        compact
                      />
                      <Tab
                        icon={faCog}
                        selected={canManage(p)}
                        onClick={() =>
                          updatePermission({
                            ...p,
                            read: true,
                            write: true,
                            manage: true,
                          })
                        }
                        grow={false}
                        toolTip="Manage"
                        compact
                      />
                    </TabGroup>
                  )
                },
              }
            })}
          />
        </>
      ) : null}
    </div>
  )
}
