var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef, useCallback } from "react";
import { Group, Rect } from "react-konva";
export var ScrollBar = function (_a) {
    var x = _a.x, y = _a.y, height = _a.height, width = _a.width, scroll = _a.scroll, onScroll = _a.onScroll, contentHeight = _a.contentHeight, offset = _a.offset;
    var timeout = useRef(null);
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var _c = useState(false), hovering = _c[0], setHovering = _c[1];
    var _d = useState(scroll), lastScroll = _d[0], setLastScroll = _d[1];
    var scrollBar = useRef(null);
    useEffect(function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        if ((!active && lastScroll !== scroll) || hovering) {
            setActive(true);
        }
        if (active && !hovering) {
            timeout.current = setTimeout(function () {
                setActive(false);
                setLastScroll(scroll);
            }, 1000);
        }
    }, [
        scroll,
        timeout,
        setActive,
        active,
        setLastScroll,
        lastScroll,
        hovering,
        setHovering,
    ]);
    useEffect(function () {
        var _a;
        (_a = scrollBar.current) === null || _a === void 0 ? void 0 : _a.to({
            opacity: active ? 1 : 0,
            duration: 0.2,
        });
    }, [active, scrollBar]);
    var handleHover = function (hover) { return function () {
        setHovering(hover);
    }; };
    var _e = useState(false), dragging = _e[0], setDragging = _e[1];
    var indicatorHeight = Math.max((height * height) / contentHeight, 24);
    var handleClickToScroll = useCallback(function (e) {
        e.evt.preventDefault();
        var midPoint = height / 2;
        var delta = midPoint - e.evt.clientY + indicatorHeight;
        onScroll((delta / midPoint / 2) * contentHeight);
        setDragging(true);
    }, [height, indicatorHeight, onScroll, contentHeight, setDragging]);
    var endScroll = useCallback(function () {
        setDragging(false);
    }, [setDragging]);
    // const handleDrag = useCallback(
    //   (e: Konva.KonvaEventObject<MouseEvent>) => {
    //     if (dragging) {
    //       const midPoint = height / 2
    //       const delta = midPoint - e.evt.clientY
    //       onScroll((delta / midPoint / 2) * contentHeight)
    //     }
    //   },
    //   [height, indicatorHeight, onScroll, contentHeight, dragging]
    // )
    var handleDrag = useCallback(function (e) {
        if (dragging) {
            var midPoint = height / 2;
            console.log("INDICATOR HEIGHT", indicatorHeight);
            var delta = midPoint - e.clientY + indicatorHeight;
            onScroll((delta / midPoint / 2) * contentHeight);
        }
    }, [height, indicatorHeight, onScroll, contentHeight, dragging]);
    var dragListener = useRef(null);
    var endListener = useRef(null);
    useEffect(function () {
        if (dragging && !dragListener.current) {
            dragListener.current = handleDrag;
            endListener.current = endScroll;
            window.addEventListener("mouseup", endListener.current);
            window.addEventListener("mousemove", dragListener.current);
        }
        else if (!dragging && dragListener.current) {
            window.removeEventListener("mousemove", dragListener.current);
            if (endListener.current) {
                window.removeEventListener("mouseup", endListener.current);
            }
            dragListener.current = null;
            endListener.current = null;
        }
    }, [dragging, endScroll, handleDrag]);
    return (_jsxs(Group, __assign({ x: x, y: y, onMouseOver: handleHover(true), onMouseOut: handleHover(false), onMouseDown: handleClickToScroll, onMouseUp: endScroll, ref: scrollBar }, { children: [_jsx(Rect, { x: 0, y: 0, fill: "rgba(0,0,0,0.025)", width: width, height: height }, void 0), _jsx(Rect, { x: 4, y: height / 2 - indicatorHeight / 2 - (scroll * height) / contentHeight, fill: "rgba(0,0,0,0.6)", width: width - 8, height: indicatorHeight, cornerRadius: 4 }, void 0)] }), void 0));
};
