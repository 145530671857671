import React, { useState } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { useActiveCalendars } from "./hooks"
import { Button } from "ui"
import { Panel } from "./Panel"
import { Title } from "./Title"
import { Results } from "./Resullts"
import { StylableProps } from "./Styleable"

interface CalendarsPanelProps {
  maxResults?: number
  days?: number
}
export const ActiveCalendarsPanel: React.FC<
  StylableProps & CalendarsPanelProps
> = ({ maxResults = 10, days = 7, ...props }) => {
  const { loading, activeCalendars } = useActiveCalendars(days)
  const [showAll, setShowAll] = useState(false)
  const handleShowAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setShowAll(!showAll)
  }
  return (
    <Panel {...props} loading={loading}>
      <Title>
        {(activeCalendars ?? []).length} Active Calendars (Last {days} days)
      </Title>
      <Results>
        <>
          <p className="px-2 w-1/2 text-sm font-bold">Calendar</p>
          <p className="px-2 w-1/4 text-sm font-bold">Last Active</p>
          <p className="px-2 w-1/4 text-sm font-bold">Organization</p>
        </>
        {(activeCalendars ?? [])
          .filter((_: any, i: any) =>
            showAll ? true : maxResults ? i <= maxResults : true
          )
          .map((metric: any, i: number) => (
            <React.Fragment key={metric.id}>
              <p className="px-2 w-1/2 text-sm flex-grow-0 truncate">
                <Link to={`/org/${metric.meta3}/cal/${metric.resourceId}`}>
                  {metric.meta1}
                </Link>
              </p>
              <p className="px-2 w-1/4 text-sm flex-grow-0 truncate">
                {moment(metric.value).format("MMM DD h:mm A")}
              </p>
              <p className="px-2 w-1/4 text-sm flex-grow-0 truncate">
                {metric.meta2}
              </p>
            </React.Fragment>
          ))}
        {activeCalendars.length <= maxResults ? (
          <p className="px-2 w-full text-sm flex-grow-0 truncate font-italic text-gray-500">
            {activeCalendars.length
              ? `Displaying all ${activeCalendars.length} results.`
              : "No results found."}
          </p>
        ) : (
          <Button onClick={handleShowAll} appearance="secondary">
            {showAll ? "Show Less" : "Show All"}
          </Button>
        )}
      </Results>
    </Panel>
  )
}
