import React from "react"
import PropTypes from "prop-types"

const ViewContainer = ({ children, ...props }) => (
  <div {...props}>{children}</div>
)

ViewContainer.propTypes = {
  children: PropTypes.node,
}

export default ViewContainer
