var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { MiniCal } from "../MiniCal";
import { Field } from "./Field";
import { Input } from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay } from "../Overlay";
import clsx from "clsx";
import { DateTime } from "luxon";
export var SEPARATOR = " to ";
var SELECTED_DATE_STYLE = "text-white font-bold text-sm bg-lochivar-default rounded p-1";
export var DateField = React.forwardRef(function (_a, forwardedRef) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q = _a.alignCalendar, alignCalendar = _q === void 0 ? "left" : _q, initialDate = _a.initialDate, dates = _a.dates, handleSelectedDate = _a.onDateChange, _r = _a.selectedDateFormat, selectedDateFormat = _r === void 0 ? "MMMM d, yyyy" : _r, disabled = _a.disabled, error = _a.error, required = _a.required, label = _a.label, name = _a.name, placeholder = _a.placeholder, className = _a.className, range = _a.range, _s = _a.rangeDirection, rangeDirection = _s === void 0 ? "both" : _s, darkDays = _a.darkDays, disableWeekends = _a.disableWeekends, inputProps = __rest(_a, ["alignCalendar", "initialDate", "dates", "onDateChange", "selectedDateFormat", "disabled", "error", "required", "label", "name", "placeholder", "className", "range", "rangeDirection", "darkDays", "disableWeekends"]);
    var _t = useState(), trackedDates = _t[0], setTrackedDates = _t[1];
    var _u = useState(), direction = _u[0], setDirection = _u[1];
    var _v = useState(false), focused = _v[0], setFocus = _v[1];
    var buttonRef = useRef(null);
    var start = (_d = (_c = (_b = trackedDates === null || trackedDates === void 0 ? void 0 : trackedDates[0]) !== null && _b !== void 0 ? _b : dates === null || dates === void 0 ? void 0 : dates[0]) !== null && _c !== void 0 ? _c : initialDate) !== null && _d !== void 0 ? _d : DateTime.utc();
    var end = (_g = (_f = (_e = trackedDates === null || trackedDates === void 0 ? void 0 : trackedDates[1]) !== null && _e !== void 0 ? _e : dates === null || dates === void 0 ? void 0 : dates[1]) !== null && _f !== void 0 ? _f : initialDate) !== null && _g !== void 0 ? _g : DateTime.utc();
    var currentDirection = ((_h = start.diff(end, "days").days) !== null && _h !== void 0 ? _h : 0) > 0 ? "backward" : "forward";
    useEffect(function () {
        if (currentDirection !== direction) {
            setDirection(currentDirection);
        }
    }, [currentDirection, direction, setDirection, start, end]);
    var toggleFocus = function (e) {
        e.preventDefault();
        setFocus(!focused);
    };
    var dismissAndHandleSelectedDate = function (dates, direction) {
        setTrackedDates(dates);
        handleSelectedDate === null || handleSelectedDate === void 0 ? void 0 : handleSelectedDate(dates, direction);
        setDirection(direction);
        setFocus(false);
    };
    var textValue = dates === null || dates === void 0 ? void 0 : dates.map(function (date) { return date.toFormat(selectedDateFormat); }).join(SEPARATOR);
    var _w = (_k = (_j = buttonRef.current) === null || _j === void 0 ? void 0 : _j.getBoundingClientRect()) !== null && _k !== void 0 ? _k : {}, x = _w.x, y = _w.y, height = _w.height;
    var sortedDates = __spreadArray([], (dates !== null && dates !== void 0 ? dates : []), true).sort();
    return (_jsxs(_Fragment, { children: [_jsxs(Field, __assign({ label: label, errorMessage: error, name: name, required: required, disabled: disabled, className: className }, { children: [_jsxs("button", __assign({ className: "bg-white p-2 border pl-4 pr-8 rounded flex flex-grow relative", onClick: toggleFocus, type: "button", ref: buttonRef }, { children: [((_l = dates === null || dates === void 0 ? void 0 : dates.length) !== null && _l !== void 0 ? _l : 0) > 0 ? (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: clsx(SELECTED_DATE_STYLE, direction === "backward"
                                            ? "bg-lochivar-default"
                                            : "bg-sorbus-default") }, { children: (_m = sortedDates[0]) === null || _m === void 0 ? void 0 : _m.toFormat(selectedDateFormat) }), void 0), ((_o = dates === null || dates === void 0 ? void 0 : dates.length) !== null && _o !== void 0 ? _o : 0) > 1 ? (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: direction === "backward" ? faArrowLeft : faArrowRight, className: "my-auto mx-2" }, void 0), _jsx("p", __assign({ className: clsx(SELECTED_DATE_STYLE, direction === "forward"
                                                    ? "bg-lochivar-default"
                                                    : "bg-sorbus-default") }, { children: (_p = sortedDates[1]) === null || _p === void 0 ? void 0 : _p.toFormat(selectedDateFormat) }), void 0)] }, void 0)) : null] }, void 0)) : (_jsx("p", __assign({ className: "text-gray-400" }, { children: placeholder !== null && placeholder !== void 0 ? placeholder : clsx("i.e. ", DateTime.utc().toFormat(selectedDateFormat), range && SEPARATOR, range &&
                                    DateTime.utc()
                                        .plus({ weeks: 1 })
                                        .toFormat(selectedDateFormat)) }), void 0)), _jsx("div", __assign({ className: "absolute right-0 inset-y-0 flex text-contentAreaBorder" }, { children: _jsx(FontAwesomeIcon, { icon: faCalendarAlt, className: "text-gray-500 mr-2 my-auto" }, void 0) }), void 0)] }), void 0), _jsx(Input, __assign({}, inputProps, { name: name, type: "hidden", ref: forwardedRef, value: textValue }), void 0)] }), void 0), focused ? (_jsxs(Overlay, { children: [_jsx("div", __assign({ className: "fixed bg-white shadow hover:shadow-lg mt-1 transition-shadow ease-in-out duration-500 z-50 my-auto flex-shrink-0", style: {
                            top: (y !== null && y !== void 0 ? y : 0) + (height !== null && height !== void 0 ? height : 0),
                            left: x,
                        } }, { children: _jsx(MiniCal, { initialDate: initialDate, selectedDates: dates, onDateSelected: dismissAndHandleSelectedDate, monthsToShow: range ? 2 : 1, className: "w-64", range: range, rangeDirection: rangeDirection, darkDays: darkDays, disableWeekends: disableWeekends }, void 0) }), void 0), _jsx("button", { className: "bg-white opacity-25 fixed z-40 h-screen w-screen top-0 left-0", onClick: toggleFocus }, void 0)] }, void 0)) : null] }, void 0));
});
