var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { concatStyles } from "utils";
var INDICATOR_STYLES = "flex mx-auto mb-2 rounded-full h-12 w-12";
var STEP_ID_STYLES = "text-white text-xl font-body m-auto";
var STEP_LABEL_STYLES = "font-body text-xs mx-auto";
var getColor = function (_a) {
    var disabled = _a.disabled, active = _a.active;
    if (disabled) {
        return "bg-contentAreaBorder";
    }
    else if (active) {
        return "bg-primary";
    }
    return "bg-secondary";
};
export var Step = function (_a) {
    var step = _a.step, label = _a.label, disabled = _a.disabled, active = _a.active;
    return (_jsxs("li", __assign({ className: "flex flex-col mx-2" }, { children: [_jsx("div", __assign({ className: concatStyles([
                    INDICATOR_STYLES,
                    getColor({ disabled: disabled, active: active }),
                ]) }, { children: _jsx("p", __assign({ className: STEP_ID_STYLES }, { children: step }), void 0) }), void 0), _jsx("p", __assign({ className: STEP_LABEL_STYLES }, { children: label }), void 0)] }), void 0));
};
