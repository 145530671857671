import { useGoogleCalendarAPI } from "./useGoogleCalendarAPI"
import React, { useEffect, useRef, useState } from "react"
import {
  Modal,
  ModalProps,
  Indicators,
  Button,
  MultiSelect,
  GoogleButton,
} from "ui"
import toast from "react-hot-toast"
import { DateTime } from "luxon"
import { useDispatch } from "react-redux"
import {
  createStyle,
  createCategory,
  createItem,
  useCategories,
} from "../../api"
import Color from "color"

import googleBtn from "../../images/btn_google_signin_dark_normal_web@2x.png"
import googleBtnFocus from "../../images/btn_google_signin_dark_focus_web@2x.png"
import googleBtnDisabled from "../../images/btn_google_signin_dark_disabled_web@2x.png"
import googleBtnPressed from "../../images/btn_google_signin_dark_pressed_web@2x.png"

const styleDefaults = {
  font: "Default",
  fontSize: "12",
  textAlignment: "left",
  borderRadius: "round",
  textDecoration: "none",
  fontWeight: "normal",
  fontStyle: "normal",
}
export interface GoogleConnectModalProps {
  subdomain: string
  calendarId: number
}

export const GoogleCalendarModal: React.FC<
  GoogleConnectModalProps & Omit<ModalProps, "title">
> = ({ onClose: handleDismiss, zIndex, onFocus: handleFocus, ...props }) => {
  const dispatch = useDispatch()
  const [importing, setImporting] = useState(false)
  const [selectedCalendars, setSelected] = useState("")
  const { categories } = useCategories(
    props.subdomain,
    props.calendarId.toString()
  )
  const {
    isAuthorized,
    events,
    loadEvents,
    loadCalendars,
    loading,
    calendars: googleCalendars,
    handleAuth,
    handleSignout,
  } = useGoogleCalendarAPI()

  const loadedCalendars = useRef(false)
  useEffect(() => {
    if (isAuthorized && !loadedCalendars.current) {
      loadCalendars()
      loadedCalendars.current = true
    }
    if (!isAuthorized) {
      loadedCalendars.current = false
    }
  }, [isAuthorized, loadCalendars, loadedCalendars])

  const handleSelectedCalendars = (selection: string) => {
    setSelected(selection)
    const handleRequests = async () => {
      if (isAuthorized) {
        loadEvents(selection)
      }
    }
    handleRequests()
  }

  const createEventsForCategory = async (
    calendarId: string,
    categoryUuid: string
  ) => {
    const requests = events[calendarId].map(async (event) => {
      const start = DateTime.fromISO(
        event.start.dateTime ?? event.start.date ?? "",
        {
          zone: "utc",
        }
      ).startOf("day")
      const end = DateTime.fromISO(event.end.dateTime ?? event.end.date ?? "", {
        zone: "utc",
      }).startOf("day")
      const action = createItem(props.subdomain, props.calendarId, {
        name: event.summary,
        occursOnCalendar: true,
        event: {
          startsAt: start.toISO(),
          length: Math.max(end.diff(start, "days").days, 1),
        },
        categoryUuid,
      })
      await dispatch(action)
    })
    await Promise.all(requests)
  }

  const handleImport = async () => {
    setImporting(true)
    await Promise.all(
      selectedCalendars.split(" ").map(async (calendarId) => {
        events[calendarId].flat()
        const name = googleCalendars.find((c) => c.id === calendarId)?.summary
        const fillColor = googleCalendars.find(
          (c) => c.id === calendarId
        )?.backgroundColor
        var category = categories.find(
          (c: any) => c.name.toLowerCase() === name?.toLowerCase()
        )

        if (!category) {
          const styleAction = createStyle(props.subdomain, props.calendarId, {
            ...styleDefaults,
            name,
            fillColor,
            outlineColor: fillColor,
            textColor: Color(fillColor).isLight() ? "#000" : "#FFF",
          })
          const { payload: stylePayload } = await dispatch(styleAction)
          const styleUuid = (
            Object.values(stylePayload.entities.styles)[0] as { uuid: string }
          ).uuid

          const categoryAction = createCategory(
            props.subdomain,
            props.calendarId,
            {
              name,
              styleUuid,
            }
          )
          const { payload: categoryPayload } = await dispatch(categoryAction)
          category = Object.values(categoryPayload.entities.categories)[0] as {
            uuid: string
          }
        }

        console.log("Using cat:", category)
        if (category) createEventsForCategory(calendarId, category.uuid)
      })
    )

    setImporting(false)
    toast.success("Successfully imported events for selected calendars.")
    handleDismiss?.()
  }

  const eventCount = selectedCalendars
    .split(" ")
    .map((c) => events[c])
    .flat().length

  const calendarCount = selectedCalendars.split(" ").length

  return (
    <Modal
      {...props}
      title={"Import Google Calendars"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
    >
      {loading || importing ? <Indicators.AbsoluteOverlay /> : null}
      {isAuthorized ? (
        <article className="px-2">
          <MultiSelect
            options={googleCalendars.map((c) => ({
              label: c.summary,
              value: c.id,
              id: c.id,
            }))}
            placeholder="Select calendars to import"
            name="calendars"
            onSelect={handleSelectedCalendars}
            value={selectedCalendars}
            isMulti
            closeMenuOnSelect
          />
          {selectedCalendars.length > 0 ? (
            <p className="rounded my-2 bg-gray-200 p-2 text-sm">
              Loading {eventCount} events from {calendarCount} calendars.
            </p>
          ) : null}
          <Button
            align="stretch"
            className="mt-2"
            disabled={selectedCalendars.length < 1}
            onClick={handleImport}
          >
            Import Calendars
          </Button>
          <footer className="mt-8 text-sm border-t border-gray-300 p-2">
            <p>
              If you no longer wish to perform any syncing between Google
              Calendar and ProCal you may:
            </p>
            <Button
              onClick={handleSignout}
              className="mt-2 font-bold"
              appearance="link"
            >
              Disconnect From Google
            </Button>
          </footer>
        </article>
      ) : (
        <>
          <header className="px-2">
            <p className="my-2 text-sm">
              Connect ProCal to your Google account to import your calendar from
              google.
            </p>
            <GoogleButton
              onClick={handleAuth}
              className="mt-2 font-bold"
              googleBtn={googleBtn}
              googleBtnDisabled={googleBtnDisabled}
              googleBtnFocus={googleBtnFocus}
              googleBtnPressed={googleBtnPressed}
            />
          </header>
        </>
      )}
    </Modal>
  )
}
