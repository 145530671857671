var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import React from "react";
var CONTAINER_STYLES = "flex flex-col shadow absolute bg-white rounded py-2";
/**
 * A context menu with overlay to dismiss itself when a user clicks outside of
 * the menu. Similar to a modal view but purely for menu navigation.
 */
export var ContextMenuContainer = React.forwardRef(function (_a, forwardRef) {
    var className = _a.className, style = _a.style, children = _a.children, handleClick = _a.onClick;
    return (_jsx("div", __assign({ onClick: handleClick, className: clsx(CONTAINER_STYLES, className), style: style, ref: forwardRef }, { children: _jsx("ol", __assign({ className: "flex flex-col" }, { children: children }), void 0) }), void 0));
});
