var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { GanntMonth } from "./GanntMonth";
import { ScrollTileView } from "../ScrollTileView";
import { DateTime } from "luxon";
import { SizeContext } from "../Window";
export var GanntScrollView = React.forwardRef(function (_a, forwardRef) {
    var anchorDate = _a.anchorDate, _b = _a.dayCellSize, dayCellSize = _b === void 0 ? 20 : _b, renderHeader = _a.renderHeader;
    var windowSize = useContext(SizeContext).size;
    var _c = useState(anchorDate), startDate = _c[0], setStartDate = _c[1];
    useEffect(function () {
        if (startDate !== anchorDate)
            setStartDate(anchorDate);
    }, [setStartDate, startDate, anchorDate]);
    var effectiveDate = startDate
        ? DateTime.fromISO(startDate, { zone: "utc" })
        : DateTime.utc();
    var sizeOfCellAtIndex = function (index) {
        var start = DateTime.fromISO(startDate !== null && startDate !== void 0 ? startDate : "", { zone: "utc" })
            .plus({ months: index })
            .startOf("month")
            .startOf("day");
        var end = start.endOf("month").startOf("day");
        var days = end.diff(start, "days").days + 1;
        var size = days * dayCellSize;
        return size;
    };
    var positionOfCellAtIndex = function (index, origin) {
        var start = DateTime.fromISO(startDate !== null && startDate !== void 0 ? startDate : "", { zone: "utc" })
            .startOf("month")
            .startOf("day");
        var end = start.plus({ months: index }).startOf("month").startOf("day");
        var days = end.diff(start, "days").days;
        var position = days * dayCellSize + origin;
        return position;
    };
    var indexAtDistance = function (distance) {
        var days = -Math.ceil(distance / dayCellSize);
        var start = DateTime.fromISO(startDate !== null && startDate !== void 0 ? startDate : "", { zone: "utc" }).startOf("month");
        var end = DateTime.fromISO(start.toISO(), { zone: "utc" })
            .plus({ days: days })
            .startOf("month");
        var index = end.diff(start, "months").months;
        return index;
    };
    return (_jsx(ScrollTileView, __assign({ scrollDirection: "horizontal", defaultPosition: "start", anchorValue: startDate, cellSize: { width: 400, height: 200 }, sizeOfCellAtIndex: sizeOfCellAtIndex, positionOfCellAtIndex: positionOfCellAtIndex, indexAtDistance: indexAtDistance, ref: forwardRef, throttle: 500, preRenderCount: 4 }, { children: function (_a) {
            var index = _a.index, tileSize = _a.tileSize;
            var weekStart = effectiveDate
                .plus({ months: index })
                .toUTC()
                .startOf("month");
            var date = weekStart.toISO();
            return (_jsx(GanntMonth, { startDate: date, width: tileSize, height: windowSize.height, index: index, renderHeader: renderHeader }, void 0));
        } }), void 0));
});
