import { makePx } from "./px";
/**
 * Clears a portion of an HMTL5 canvas context.
 * @param ctx The HTML canvas context to clear.
 * @param param1 Attributes defining the rect to clear.
 */
export var clear = function (ctx, _a) {
    var x = _a.x, y = _a.y, h = _a.h, w = _a.w, scale = _a.scale;
    var px = makePx(scale);
    ctx.clearRect(px(x), px(y), px(w), px(h));
};
