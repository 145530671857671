import { NAME } from "../constants"
import { requestIdentifierFor } from "../middlewares/apiMiddleware"

/**
 * Retreives the base domain for the API configuration stored in state.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {String}       The base domain to use for requests against the API.
 */
export const baseDomain = (_) => process.env.REACT_APP_API_BASE_DOMAIN

/**
 * Returns the fully qualified http root URL to use in API request stored in state.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {String}       The fully qualified http root URL to use in API requests.
 */
export const API_ROOT = (state) =>
  `${state[NAME].protocol}${process.env.REACT_APP_API_BASE_DOMAIN}`

/**
 * Returns all entities that have been cached in the redux store by the
 * api middleware.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       All entities cached by the API middleware..
 */
export const entities = (state) => {
  return state[NAME].entities
}

/**
 * Checks if a supplied action creator is currently fetching against
 * the API.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Boolean}     A function that returns accepts and action creator to check.
 */
export const isFetching = (state) => (action) =>
  state[NAME].fetching.includes(requestIdentifierFor(action))
