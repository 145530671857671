var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "../Icon";
import { useEventListener } from "../../hooks";
import clsx from "clsx";
var DEFAULT_STATE = {
    origin: { x: 0, y: 0 },
    mouse: { x: 0, y: 0 },
    translate: { x: 0, y: 0 },
    dragging: false,
};
var OVERLAY = "fixed inset-0 flex flex-col items-center justify-center w-screen h-screen pointer-events-none";
var MODAL = "flex flex-col bg-white w-full py-1 px-1 md:max-h-3/4 md:max-w-md rounded-md border m-auto pointer-events-auto transition-shadow transition-colors duration-300 ease-out relative";
var HEADER = "flex justify-between bg-rose-lighter rounded mt-0";
var TITLE = "capitalize text-gray-900 font-medium text-xl my-auto py-1 pl-3";
var DRAG_BUTTON = "cursor-move flex flex-grow hover:bg-rose-default ml-1 my-1 rounded transition-colors duration-100 ease-out";
var CLOSE_BUTTON = "hover:bg-rose-default rounded-full w-10 h-10 transition-colors my-1 mr-1 duration-150 text-2xl focus:outline-none";
var MODAL_BODY = "px-3 py-4 text-base font-normal overflow-scroll";
var NOT_DRAGGING = "shadow-xl border-rose-lighter";
var DRAGGING = "shadow-2xl border-rose";
export var Modal = function (_a) {
    var title = _a.title, open = _a.open, handleOnClose = _a.onClose, children = _a.children, draggable = _a.draggable, handleFocus = _a.onFocus, _b = _a.zIndex, zIndex = _b === void 0 ? "z-40" : _b;
    var _c = useState(DEFAULT_STATE), state = _c[0], setState = _c[1];
    var dialog = useRef(null);
    var handleCloseClick = function (e) {
        e.stopPropagation();
        handleOnClose === null || handleOnClose === void 0 ? void 0 : handleOnClose();
    };
    useEffect(function () {
        if (!open) {
            setState(DEFAULT_STATE);
        }
    }, [open]);
    var dragging = state.dragging, translate = state.translate, mouse = state.mouse;
    useEffect(function () {
        if (dragging && dialog.current) {
            var minX = -dialog.current.offsetLeft - dialog.current.offsetWidth / 2;
            var minY = -dialog.current.offsetTop;
            var maxY = window.innerHeight -
                dialog.current.offsetHeight / 2 +
                dialog.current.offsetTop;
            var maxX = window.innerWidth -
                dialog.current.offsetWidth / 2 -
                dialog.current.offsetLeft;
            var x = Math.min(maxX, Math.max(minX, mouse.x - state.origin.x));
            var y = Math.min(maxY, Math.max(minY, mouse.y - state.origin.y));
            dialog.current.style.transform = "translate(" + x + "px, " + y + "px)";
            if (translate.x !== x || translate.y !== y) {
                setState(__assign(__assign({}, state), { translate: { x: x, y: y } }));
            }
        }
    }, [mouse, dialog.current, translate, dragging, state, setState]);
    // Update mouse pos on move...
    var onMouseMove = useCallback(function (e) {
        if (dragging) {
            e.preventDefault();
            setState(__assign(__assign({}, state), { mouse: { x: e.pageX, y: e.pageY } }));
        }
    }, [dragging, state, setState]);
    useEventListener({
        type: "mousemove",
        element: window,
        listener: onMouseMove,
    });
    // Reset dragging state on mouse up.
    var onMouseUp = useCallback(function (e) {
        if (dragging) {
            e.preventDefault();
            setState(__assign(__assign({}, DEFAULT_STATE), { translate: translate }));
        }
    }, [dragging, translate, setState]);
    useEventListener({
        type: "mouseup",
        element: window,
        listener: onMouseUp,
    });
    var handleMouseDown = function (e) {
        e.preventDefault();
        setState(__assign(__assign({}, state), { dragging: true, origin: { x: e.pageX - translate.x, y: e.pageY - translate.y }, mouse: { x: e.pageX, y: e.pageY } }));
        handleFocus === null || handleFocus === void 0 ? void 0 : handleFocus();
    };
    var handleClick = function (e) {
        handleFocus === null || handleFocus === void 0 ? void 0 : handleFocus();
    };
    return open ? (_jsx("div", __assign({ className: clsx(OVERLAY, zIndex), onClick: handleClick }, { children: _jsxs("section", __assign({ className: clsx(MODAL, dragging ? DRAGGING : NOT_DRAGGING), ref: dialog }, { children: [_jsxs("header", __assign({ className: HEADER }, { children: [draggable ? (_jsx("button", __assign({ onMouseDown: handleMouseDown, className: DRAG_BUTTON }, { children: _jsx("h2", __assign({ className: TITLE }, { children: title }), void 0) }), void 0)) : (_jsx("h2", __assign({ className: TITLE }, { children: title }), void 0)), handleOnClose ? (_jsx("button", __assign({ className: CLOSE_BUTTON, onClick: handleCloseClick }, { children: _jsx(Icon, { icon: faTimes, mode: "default" }, void 0) }), void 0)) : null] }), void 0), _jsx("div", __assign({ className: MODAL_BODY }, { children: children }), void 0)] }), void 0) }), void 0)) : null;
};
