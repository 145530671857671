import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { holidays } from "../actions"
import { holidaySelector } from "../selectors"

export const useHoliday = (
  subdomain: string,
  calendarId: string,
  holidayUuid?: string
) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(holidays.requestHoliday(subdomain, calendarId, holidayUuid))
  const holiday = useSelector(holidaySelector.find)(holidayUuid, calendarId)
  const { loading, refetch } = useRequest(request)
  return { loading, holiday, refetch }
}
