var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var DISCLAIMER_STYLES = "fixed z-90 rounded-full w-full bottom-0 mb-8 flex";
var BUBBLE_STYLES = "bg-white shadow-xl p-3 text-md font-semibold text-lochivar-default border-gray-200 border mx-auto";
export var FixedDisclaimer = function (_a) {
    var message = _a.message;
    return _jsx("div", __assign({ className: DISCLAIMER_STYLES }, { children: _jsx("div", __assign({ className: BUBBLE_STYLES }, { children: message }), void 0) }), void 0);
};
