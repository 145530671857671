import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { orgs } from "../actions"
import { organizationSelector } from "../selectors"

export const useOrganization = (subdomain: string) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(orgs.requestOrganization(subdomain))
  const organization = useSelector(organizationSelector.find)(subdomain)
  const { loading, refetch } = useRequest(request)
  return { loading, organization, refetch }
}
