var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { getCookie, setCookie } from "react-simple-cookie-store";
import { Modal } from "./Modal";
export var useRetainedModalToggle = function (name) {
    var KEEP_MODAL_OPEN = "KEEP_" + name + "_MODAL_OPEN";
    var _a = useState({
        keepOpen: getCookie(KEEP_MODAL_OPEN) === "true",
    }), state = _a[0], setState = _a[1];
    var toggleKeepOpen = function () {
        setState({ keepOpen: !state.keepOpen });
        setCookie(KEEP_MODAL_OPEN, "" + !state.keepOpen, 999);
    };
    return { keepOpen: state.keepOpen, toggleKeepOpen: toggleKeepOpen };
};
export var RetainableModal = function (_a) {
    var name = _a.name, children = _a.children, keepOpen = _a.keepOpen, toggleKeepOpen = _a.toggleKeepOpen, modalProps = __rest(_a, ["name", "children", "keepOpen", "toggleKeepOpen"]);
    return (_jsxs(Modal, __assign({}, modalProps, { children: [children, _jsxs("label", __assign({ htmlFor: "keepOpen", onClick: toggleKeepOpen, style: {
                    display: "block",
                    cursor: "pointer",
                    marginTop: "10px",
                } }, { children: [_jsx("input", { type: "checkbox", name: "keepOpen", checked: keepOpen, onClick: toggleKeepOpen }, void 0), " ", "Keep window open after save?"] }), void 0)] }), void 0));
};
