var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Creatable from "react-select/creatable";
export var GroupSelect = React.forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, placeholder = _a.placeholder, _b = _a.options, options = _b === void 0 ? [] : _b, handleOnInputChange = _a.onChange, handleSelect = _a.onSelect, handleCreate = _a.onCreate, value = _a.value, disabled = _a.disabled, clearable = _a.clearable, creatable = _a.creatable;
    var defaultValue = options.filter(function (s) { return s.uuid === value; });
    var handleChange = function (s) {
        var _a;
        handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect((_a = s === null || s === void 0 ? void 0 : s.uuid) !== null && _a !== void 0 ? _a : null);
    };
    return (_jsxs("div", __assign({ className: "w-full flex-grow flex" }, { children: [_jsx(Creatable, { className: "pro__client__multi__select_container w-full", classNamePrefix: "pro__client__multi__select", name: name, options: options, value: defaultValue, defaultValue: defaultValue, getOptionLabel: function (option) { var _a; return (_a = option.name) !== null && _a !== void 0 ? _a : ""; }, getOptionValue: function (option) { return option.uuid; }, onInputChange: handleOnInputChange, onChange: handleChange, onCreateOption: handleCreate, placeholder: placeholder, isClearable: clearable, isDisabled: disabled, createOptionPosition: "last", isValidNewOption: function (inputValue) {
                    return inputValue.length > 0 && (creatable !== null && creatable !== void 0 ? creatable : false);
                }, formatOptionLabel: function (option) {
                    var _a, _b;
                    return (_jsx("p", __assign({ className: "flex" }, { children: (_b = (_a = option.name) !== null && _a !== void 0 ? _a : option.label) !== null && _b !== void 0 ? _b : "Unnamed " + option.uuid }), void 0));
                }, styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); } }, menuPortalTarget: document.body, allowCreateWhileLoading: true, closeMenuOnSelect: true }, void 0), _jsx("input", { type: "hidden", ref: ref, name: name, id: id }, void 0)] }), void 0));
});
