import React, { useEffect, useRef, useState } from "react"
import { Button, TextField, Fields, Indicators, Modal } from "ui"
import toast from "react-hot-toast"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { createCalendar, useCalendar, useSnapshot } from "../api"
import { useHistory, useParams } from "react-router"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"
import { wait } from "utils"

type FormValues = {
  name: string
  email: string
  includePermissions?: string
}

const schema = yup.object({
  name: yup.string().required(),
})

export interface SnapShotModalProps {
  onDismiss?: () => void
  specificSnapshotId?: string
}

export const SnapshotModal: React.FC<SnapShotModalProps> = ({
  onDismiss: handleDismiss,
  specificSnapshotId,
}) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const params = useParams<{
    subdomain: string
    calendarId: string
    snapshotId?: string
  }>()
  const { calendar } = useCalendar(params.subdomain, params.calendarId)
  const snapshotId = specificSnapshotId ?? params.snapshotId
  const { snapshot } = useSnapshot(
    params.subdomain,
    params.calendarId,
    snapshotId ?? "0"
  )

  const dispatch = useDispatch()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true)
    await wait(1)
    try {
      console.log({
        ...calendar,
        name: data.name,
        templateCalendarIds: snapshotId ? [] : [params.calendarId],
        templateSnapshotIds: snapshotId ? [snapshotId] : [],
        includeEvents: true,
        includePermissions: data.includePermissions === "true",
      })
      const response: any = await dispatch(
        createCalendar(params.subdomain, {
          ...calendar,
          name: data.name,
          templateCalendarIds: snapshotId ? [] : [params.calendarId],
          templateSnapshotIds: snapshotId ? [snapshotId] : [],
          includeEvents: true,
          includePermissions: data.includePermissions === "true",
        })
      )
      if (response.payload.result) {
        history.push(`/org/${params.subdomain}/cal/${response.payload.result}/`)
        toast.success(`Successfully created "${data.name}".`)
        handleDismiss?.()
      } else {
        toast.error(
          response?.response?.responseJson?.error ??
            `Could not create a copy named "${data.name}".`
        )
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const handleSubmitClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  const field = useRef<HTMLInputElement>(null)
  useEffect(() => {
    field.current?.focus()
  })

  return (
    <Modal title="Save As" onClose={handleDismiss} open draggable>
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        <Fields register={register} errors={errors} grow className="pb-2">
          <TextField
            name="name"
            label="Name"
            placeholder="Name of New Copy"
            required
            ref={field}
            className="w-full"
          />
          <label
            htmlFor="includePermissions"
            className="flex items-center mr-auto my-0"
          >
            <input
              type="checkbox"
              id="includePermissions"
              value="true"
              {...register("includePermissions")}
            />
            <span className="ml-2 flex-shrink">Include user permissions</span>
          </label>
          <Button
            type="submit"
            onClick={handleSubmitClick}
            className="-mt1 w-full"
          >
            Save As
          </Button>
        </Fields>
        <p className="px-2 text-sm">
          Create a new calendar based on{" "}
          {params.snapshotId ? " this back up of " : " "}
          <strong className="font-bold text-lochivar-default">
            "{calendar.name}"
          </strong>{" "}
          {snapshot ? (
            <>
              from{" "}
              <strong className="font-bold">
                {DateTime.fromISO(snapshot.createdAt, { zone: "utc" }).toFormat(
                  "MMMM d, yyyy @ h:mm a"
                )}
              </strong>
            </>
          ) : (
            ""
          )}
          .
        </p>
        {loading ? <Indicators.AbsoluteOverlay /> : null}
      </form>
    </Modal>
  )
}
