var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { humanize } from "utils";
var LIST_STYLES = "my-2 border border-danger p-2 rounded flex flex-col";
var ITEM_STYLES = "flex font-body text-error text-sm";
var NAME_STYLES = "font-semibold";
export var ErrorList = function (_a) {
    var className = _a.className, _b = _a.errors, errors = _b === void 0 ? {} : _b;
    var keys = Object.keys(errors !== null && errors !== void 0 ? errors : {});
    return keys.length ? (_jsx("ul", __assign({ className: clsx(LIST_STYLES, className) }, { children: keys.map(function (k) {
            var _a;
            return (_jsxs("li", __assign({ className: ITEM_STYLES }, { children: [_jsx(FontAwesomeIcon, { icon: faExclamationTriangle, size: "sm", className: "my-auto text-danger mr-2" }, void 0), _jsxs("strong", __assign({ className: NAME_STYLES }, { children: [humanize(k), "\u00A0"] }), void 0), " ", (_a = errors[k]) === null || _a === void 0 ? void 0 : _a.message] }), k));
        }) }), void 0)) : null;
};
