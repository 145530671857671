import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { snapshots as actions } from "../actions"
import { snapshotSelector } from "../selectors"

export const useSnapshots = (subdomain: string, calendarId: string) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestSnapshot(subdomain, calendarId))
  const snapshots = useSelector(snapshotSelector.forCalendar)(calendarId)
  const { loading, refetch } = useRequest(request)
  return {
    loading,
    snapshots: snapshots.reverse().map((s: any) => ({
      ...s,
      name: !s.customized ? "Automated Backup" : s.name,
    })),
    refetch,
  }
}
