import * as types from "../actionTypes"

/**
 * Overwrites multiple events in the api entities cache in redux.
 * @param {*} events The events to update.
 * @param {string} originId The source calendar the events belong to.
 * @param {*} debounceKey A unique key used to debounce the operation in the redux store.
 * @param {*} debounced If true the event will be debounced via redux debounce middleware.
 */
export const updateEvents = (events, originId, debounceKey = "", debounced) => {
  const debounce = {
    debounce: {
      time: 25,
      key: debounceKey,
    },
  }
  const meta = debounced ? debounce : {}
  return {
    type: types.UPDATE_EVENT,
    meta,
    payload: {
      entities: {
        events,
        originId,
      },
    },
  }
}
