import moment from "moment"
import { eventSelector } from "../../api"
import { calculateInterruptions } from "./calculateInterruptions"
import { updateEvents } from "./updateEvents"

/**
 * Updates the length of an event as opposed to shifting the start and end date.
 * This action effectively updates the start or end date depending on the specified
 * direction.
 * @param  {String} eventUuid     The ID of the event to adjust.
 * @param  {Integer} calendarId   The ID of the calendar the event belongs to.
 * @param  {string}  newDate      A date string representing the new date to set the end or start date to.
 * @param  {Integer} direction    If set to 1 or more the new date will be applied to the end date. If set to -1 or less the start date will be altered.
 * @param  {Boolean} debounced    If set to true the event handler which actually adjusts the state of the redux store will be passed with a debounce key.
 * @return {Promise}              A redux thunk representing the dispatched action.
 */
export const expandEventPreview =
  (eventUuid, calendarId, newDate, direction, debounced) =>
  (dispatch, getState) => {
    if (direction === 0) {
      return {}
    }
    const state = getState()
    const event = eventSelector.find(state)(eventUuid, calendarId)
    const recurringEvents = eventSelector.forItem(state)(
      event.itemUuid,
      calendarId
    )
    const key = `expandEvent${direction}${event.uuid}`
    const offset = moment
      .utc(direction < 0 ? event.startsAt : event.endsAt)
      .diff(moment.utc(newDate), "day")
    const startsAt = direction < 0 ? newDate : event.startsAt
    const endsAt = direction > 0 ? newDate : event.endsAt
    const length = moment.utc(moment.utc(endsAt)).diff(startsAt, "day") + 1
    const interruptions = calculateInterruptions(
      event.interruptedByWeekends,
      event.interruptedByHolidays,
      startsAt,
      endsAt,
      event.calendarId,
      state
    )

    const trueLength = length - interruptions.length
    const hasChanged = event.length !== trueLength

    const updates = recurringEvents.reduce((p, e) => {
      const offsetDate = moment
        .utc(direction < 0 ? e.startsAt : e.endsAt)
        .subtract(offset, "day")
        .toISOString()
      const newStartsAt = direction < 0 ? offsetDate : e.startsAt
      const newEndsAt = direction > 0 ? offsetDate : e.endsAt

      return {
        ...p,
        [e.hashId]: {
          ...e,
          length: trueLength,
          endsAt: newEndsAt,
          startsAt: newStartsAt,
          interruptions,
        },
      }
    }, {})

    return (
      hasChanged &&
      length > 0 &&
      dispatch(updateEvents(updates, key, debounced))
    )
  }
