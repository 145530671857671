import { entities } from "./baseSelector"

/**
 * Returns all organization entities in the cache.
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       The current entity map of organizations in the redux store.
 */
const organizations = (state) => entities(state).organizations

/**
 * Returns all organization entities in the cache that have not been
 * deleted.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of organization entities.
 */
export const all = (state) =>
  Object.keys(organizations(state))
    .map((key) => entities(state).organizations[key])
    .filter((entity) => !entity.isDeleted)
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

/**
 * Returns all organization entities in the cache that have not been
 * deleted.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns a specific organization via subdomain.
 */
export const find = (state) => (subdomain) => {
  const entity = organizations(state)[subdomain]
  return entity && !entity.isDeleted && entity
}
