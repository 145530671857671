import { CALL_API, Schemas } from "../middlewares"
import { PROTECTED } from "redux-jwt-protected-middleware"
import * as types from "../actionTypes"

//
// GET / Find all OR by :subdomain
//

export const getGroup = (subdomain, id) => ({
  type: types.FETCH_GROUPS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.GROUP : Schemas.GROUPS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/groups/${id || ""}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_GROUPS_REQUEST,
      types.FETCH_GROUPS_SUCCESS,
      types.FETCH_GROUPS_FAILURE,
    ],
  },
})

export const requestGroup = (subdomain, id) => (dispatch) => {
  return dispatch(getGroup(subdomain, id))
}
