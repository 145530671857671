import React, { Component } from "react"
import PropTypes from "prop-types"
import { Indicators, Button } from "ui"
import { Flex } from "../../shared"
import styles from "./ShortList.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"

const AddItem = ({ handleClick, text }) => (
  <li className={styles.addItem}>
    <Button
      align="stretch"
      full={true}
      onClick={() => {
        handleClick()
      }}
    >
      <Flex.Container>
        <FontAwesomeIcon icon={faPlus} />
        {text}
      </Flex.Container>
    </Button>
  </li>
)

AddItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

class ShortList extends Component {
  componentDidMount() {
    this.props.refreshList()
  }

  renderAddButton() {
    const { handleAddItemClick, addButtonText, addItemAuthWrapper } = this.props
    const SecuredAddItem = addItemAuthWrapper

    return SecuredAddItem ? (
      <SecuredAddItem>
        <AddItem handleClick={handleAddItemClick} text={addButtonText} />
      </SecuredAddItem>
    ) : (
      <AddItem handleClick={handleAddItemClick} text={addButtonText} />
    )
  }

  render() {
    const { isFetching, children } = this.props
    return (
      <ul className={styles.listContainer}>
        {this.renderAddButton()}
        {children}
        {isFetching && <Indicators.AbsoluteOverlay />}
      </ul>
    )
  }
}

ShortList.propTypes = {
  addButtonText: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  handleAddItemClick: PropTypes.func.isRequired,
  addItemAuthWrapper: PropTypes.func,
  children: PropTypes.node,
}

export default ShortList
