import moment from "moment"
import { eventSelector, saveEventPosition } from "../../api"
import { shiftEventPreview } from "./shiftEventPreview"

/**
 * Query a map of the events in their current multidimensional array.
 *
 * @param {Object} event An existing event object plucked from the redux store.
 * @param {Object} state The current state of the redux store.
 * @returns {Array} A 2D array of all events segmented in partitions during the supplied event.
 */
const partitionsRelatedToEvent = (event, state) => {
  const rowsQuery = eventSelector.makeGetRowsForCalendarDuringPeriod()
  const start = moment(event.startsAt).startOf("week")
  const end = moment(event.startsAt).endOf("week")
  return rowsQuery(state, {
    start,
    end,
    calendarIds: [event.calendarId],
  })
}

/**
 * Position map must only include relevant positions to events that
 * overlap with the event in question. We use the hasConflict method
 * exposed in the event selector to replicate the same row calculations
 * used to create the multidimensional array for partitions. In this
 * case we only want to consider the next available positions with events
 * that conflict with the event that is currently selected.
 *
 * @param {Object} event An existing event object plucked from the redux store.
 * @param {Object} state The current state of the redux store.
 * @returns {Array} A 2D array of top positions in partitions during the event.
 */
const positionMapForEvent = (event, state) => {
  const positionMap = partitionsRelatedToEvent(event, state).map((eventRows) =>
    eventRows
      .map((r) => r.filter((e) => eventSelector.hasConflict(e, event)))
      .map((r) => (r.length > 0 ? r[0].position : -1))
      .filter((i) => i >= 0)
  )
  return positionMap
}

/**
 * Decrement to the next position or the previous partition if possible.
 *
 * @param {Object} event An existing event object plucked from the redux store.
 * @param {Object} state The current state of the redux store.
 * @returns {Object} An object containing the updatedPosition and updatedPartition.
 */
export const decrementPositionOrPartition = (event, state) => {
  const partitions = partitionsRelatedToEvent(event, state)
  const positionMap = positionMapForEvent(event, state)
  const currentPartition = positionMap[event.partition]
  const indexInPartition = currentPartition.indexOf(event.position)
  const canDecrementPartition = event.partition > 0
  const isFirstPartition = indexInPartition === 0
  const updatedPartition =
    canDecrementPartition && isFirstPartition
      ? event.partition - 1
      : event.partition
  const decrementedPosition = currentPartition[indexInPartition - 1]
  const updatedPosition =
    updatedPartition !== event.partition
      ? Math.max(
          event.position,
          positionMap[updatedPartition][
            partitions[updatedPartition].length - 1
          ] || 0
        )
      : decrementedPosition > -1
      ? decrementedPosition
      : event.position
  return { updatedPosition, updatedPartition }
}

/**
 * Increment to the next position or the next partition if possible.
 *
 * @param {Object} event An existing event object plucked from the redux store.
 * @param {Object} state The current state of the redux store.
 * @returns {Object} An object containing the updatedPosition and updatedPartition.
 */
export const incrementPositionOrPartition = (event, state) => {
  const partitions = partitionsRelatedToEvent(event, state)
  const positionMap = positionMapForEvent(event, state)
  const currentPartition = positionMap[event.partition]
  const indexInPartition = currentPartition.indexOf(event.position)
  const canIncrementPartition = event.partition < partitions.length - 1
  const isLastInPartition = currentPartition.length - 1 === indexInPartition
  const updatedPartition =
    canIncrementPartition && isLastInPartition
      ? event.partition + 1
      : event.partition
  const incrementedPosition = currentPartition[indexInPartition + 1]
  const updatedPosition =
    updatedPartition !== event.partition
      ? Math.min(
          positionMap[updatedPartition][0] || event.position,
          event.position
        )
      : incrementedPosition > -1
      ? incrementedPosition
      : event.position
  return { updatedPosition, updatedPartition }
}

/**
 * Manually increments or decrements the position or partition of a supplied event without
 * affecting dates or other attributes. This method is not optimized for performance as
 * the use case should not effect frequent calls that may occur from drag and drop based
 * interaction. If performance becomes an issue see the note at the end of this method.
 *
 * @param {String} eventUuid The id of the event to adjust position.
 * @param {Integer} calendarId The id of the calendar the event belongs to.
 * @param {Boolean} increment If true the event's position will be incremented towards the bottom.
 * @returns {Promise} A redux thunk representing the dispatched action.
 */
export const incrementEventPosition =
  (eventUuid, calendarId, increment) => (dispatch, getState) => {
    const state = getState()
    const event = eventSelector.find(state)(eventUuid, calendarId)
    const { updatedPosition, updatedPartition } = increment
      ? incrementPositionOrPartition(event, state)
      : decrementPositionOrPartition(event, state)
    const debouncedSaveKey = `incrementEventPosition${eventUuid}#${calendarId}`

    // NOTE: Due to the complexity of this action -- if repetitive calls are
    // causing a notable effect on performance, you can set the debounce param
    // to true. We do this for drag and drop based interactions but are
    // optimistic that the arrow key based interaction should not need this.
    return dispatch(
      shiftEventPreview(
        event.uuid,
        event.calendarId,
        event.startsAt,
        updatedPosition,
        updatedPartition,
        false // Set this to true if performance becomes hindered from this action.
      )
    ).then(() =>
      dispatch(saveEventPosition(event.uuid, calendarId, debouncedSaveKey))
    )
  }
