var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from "react";
import { BlockPicker, SketchPicker } from "react-color";
import Color from "color";
import { Field, getErrorMessage } from "./Field";
import { useClickAway } from "../../hooks";
import { concatStyles } from "utils";
import { Overlay } from "../Overlay";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/pro-regular-svg-icons";
var FIELD_LABEL_STYLES = "capitalize font-semibold text-base mb-1";
var CONTAINER_STYLES = "w-full";
var BUTTON_STYLES = "w-full flex focus:outline-none shadow rounded relative";
export var ColorSwatch = function (_a) {
    var _b, _c;
    var id = _a.id, label = _a.label, name = _a.name, className = _a.className, errorMessage = _a.errorMessage, disabled = _a.disabled, errors = _a.errors, style = _a.style, colors = _a.colors, _d = _a.defaultValue, defaultValue = _d === void 0 ? "#ffffff" : _d, value = _a.value, onChange = _a.onChange, fieldProps = __rest(_a, ["id", "label", "name", "className", "errorMessage", "disabled", "errors", "style", "colors", "defaultValue", "value", "onChange"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    var _e = useState(false), advanced = _e[0], setAdvanced = _e[1];
    var _f = useState(defaultValue), color = _f[0], setColor = _f[1];
    var _g = useState(false), show = _g[0], toggle = _g[1];
    var containerRef = useRef(null);
    var buttonRef = useRef(null);
    useEffect(function () {
        if (color !== value && value) {
            setColor(value);
        }
    }, [setColor, value, color]);
    var dismiss = function () {
        toggle(false);
    };
    useClickAway(containerRef, dismiss, buttonRef);
    var handleOnClick = function (e) {
        e.preventDefault();
        disabled ? toggle(false) : toggle(function (prev) { return !prev; });
    };
    var handleOnChange = function (_a) {
        var hex = _a.hex;
        setColor(hex);
        onChange === null || onChange === void 0 ? void 0 : onChange(hex);
    };
    var handleSelectColor = function (e) {
        e.preventDefault();
        onChange === null || onChange === void 0 ? void 0 : onChange(color);
        dismiss();
    };
    var handleToggleAdvanced = function (e) {
        e.preventDefault();
        setAdvanced(!advanced);
    };
    var handleUpdatedColorField = function (color) {
        setColor(color);
        onChange === null || onChange === void 0 ? void 0 : onChange(color);
        dismiss();
    };
    var _h = (_c = (_b = buttonRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) !== null && _c !== void 0 ? _c : {
        x: 0,
        y: 0,
        height: 0,
    }, x = _h.x, y = _h.y, height = _h.height;
    var top = y + height + 5, left = x;
    return (_jsx(Field, __assign({ name: name, label: label, className: concatStyles(className, "w-full"), labelClassName: FIELD_LABEL_STYLES, style: style, errorMessage: determinedErrorMessage }, fieldProps, { children: _jsxs("div", __assign({ className: CONTAINER_STYLES }, { children: [_jsxs("div", __assign({ className: clsx(BUTTON_STYLES, disabled && "opacity-50 pointer-events-none"), ref: buttonRef, style: {
                        backgroundColor: color,
                        color: Color(color).isLight() ? "#000" : "#FFF",
                    } }, { children: [_jsx(ColorInput, { color: color, onChange: handleUpdatedColorField, className: "uppercase font-bold text-left px-2 py-5 w-28 mx-auto outline-none focus:outline-none" }, void 0), _jsx("button", __assign({ onClick: handleOnClick, className: "absolute right-0 pl-2 inset-y-0 flex bg-black/10" }, { children: _jsx(FontAwesomeIcon, { icon: faChevronCircleDown, className: "my-auto mr-2" }, void 0) }), void 0)] }), void 0), show ? (_jsx(Overlay, { children: _jsx(PositionAbsolute, __assign({ top: top, left: left, zIndex: 999, ref: containerRef }, { children: _jsxs("div", __assign({ className: "flex flex-col pt-1 px-2 pb-2 mt-1 bg-white rounded shadow" }, { children: [advanced ? (_jsx(SketchPicker, { color: color, presetColors: colors, onChangeComplete: handleOnChange }, void 0)) : (_jsx(BlockPicker, { color: color, colors: colors, onChangeComplete: handleOnChange }, void 0)), _jsx("button", __assign({ onClick: handleSelectColor, className: "text-white uppercase font-bold text-sm p-2 rounded mx-auto bg-sorbus-default mt-2 w-full shadow" }, { children: "Set Color" }), void 0), _jsxs("button", __assign({ onClick: handleToggleAdvanced, className: "text-lochivar-default uppercase bg-white text-xs p-1 rounded mx-auto border border-lochivar-default mt-2 w-full shadow" }, { children: ["Use ", advanced ? "Basic" : "Advanced", " Mode"] }), void 0)] }), void 0) }), void 0) }, void 0)) : null] }), void 0) }), void 0));
};
var ColorInput = function (_a) {
    var color = _a.color, onChange = _a.onChange, className = _a.className, style = _a.style;
    var _b = useState(color), userInput = _b[0], setUserInput = _b[1];
    var _c = useState(false), editing = _c[0], setEditing = _c[1];
    var _d = useState(true), valid = _d[0], setValid = _d[1];
    useEffect(function () {
        if (color !== userInput && !editing) {
            setUserInput(color);
            setValid(true);
        }
    }, [color, userInput, setUserInput, editing]);
    var isValidColor = function (str) {
        return (str.match(/^#[a-f0-9]{6}$/i) !== null ||
            str.match(/^#[a-f0-9]{3}$/i) !== null);
    };
    var focusHandler = function () {
        setEditing(true);
    };
    var blurHandler = function () {
        setEditing(false);
        if (valid) {
            onChange(userInput);
        }
    };
    var changeHandler = function (e) {
        e.preventDefault();
        setUserInput(e.target.value);
        var nowValid = isValidColor(e.target.value);
        setValid(nowValid);
        if (nowValid) {
            onChange(e.target.value);
        }
    };
    return (_jsx("input", { name: "color", type: "text", className: clsx("rounded bg-transparent", className), style: style, placeholder: "#ff9900", value: userInput, onChange: changeHandler, onFocus: focusHandler, onBlur: blurHandler }, void 0));
};
var PositionAbsolute = React.forwardRef(function (_a, ref) {
    var top = _a.top, left = _a.left, right = _a.right, bottom = _a.bottom, zIndex = _a.zIndex, children = _a.children, className = _a.className, props = __rest(_a, ["top", "left", "right", "bottom", "zIndex", "children", "className"]);
    return (_jsx("div", __assign({ ref: ref, className: concatStyles("absolute z-50", className), style: { top: top, left: left, right: right, bottom: bottom, zIndex: zIndex } }, props, { children: children }), void 0));
});
