import * as types from "../actionTypes"

/**
 * Clears the context menu state, effectively dismissing it.
 * @return {Object} An FSA action.
 */
export const clearContext = () => ({
  type: types.CLEAR_CONTEXT,
  payload: null,
})
