import React from "react"
import { DateTime } from "luxon"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  createReport,
  updateReport,
  removeReport,
  useReport,
  useCalendars,
} from "../api"
import Helmet from "react-helmet"
import {
  DetailView,
  Indicators,
  ReportForm,
  ReportFormValues,
  AsyncSubmitHandler,
} from "ui"

import { faTasksAlt } from "@fortawesome/pro-light-svg-icons"
import { deleteFormWithName } from "../shared"
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import { wait } from "utils"

/**
 * Generates a delete form that can be embedded in the current view.
 * @type {ReactClass} An instance of an invitation delete form.
 */
const ReportDeleteForm = deleteFormWithName("DELETE_FORM_NAME")

export const ReportDetailView: React.FC = () => {
  const { subdomain, reportId } = useParams<{
    reportId: string
    subdomain: string
  }>()
  const [submitting, setSubmitting] = React.useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading: loadingCals, calendars } = useCalendars(subdomain)
  const {
    loading: loadingReports,
    report,
    reportCalendars,
  } = useReport(subdomain, parseInt(reportId, 0))
  const loading = loadingCals || loadingReports

  const calendarIds = reportCalendars.map((r: any) => r.calendarId)

  const deleteAction = () => dispatch(removeReport(subdomain, reportId))
  const redirectAction = () => history.replace(`/org/${subdomain}/cal/`)

  const title = report?.id
    ? `Settings for ${report.name}`
    : "Create Multi-Calendar Report"

  const handleSubmit: AsyncSubmitHandler<ReportFormValues> = async (values) => {
    setSubmitting(true)
    const reportValues = {
      ...values,
      calendarIds: values.calendars.split(" ").map((id: string) => id),
    }
    const response: any = await dispatch(
      report?.id
        ? updateReport(subdomain, { id: report?.id, ...reportValues })
        : createReport(subdomain, reportValues)
    )
    const id = response?.payload?.result
    if (id) {
      await wait(1)
      window.location.href = `${
        window.location.origin
      }/org/${subdomain}/reports/${id}/start/${DateTime.local().toFormat(
        "yyyyMMdd"
      )}/view/1M`
    }
    setSubmitting(false)
    return undefined
  }

  const handleSearch = () => {} // TODO: implement search if we do not pre-load entire calendar array.

  return (
    <DetailView
      title={title}
      subtitle="A collection of calendars in a single view."
      icon={faTasksAlt}
    >
      <Helmet title={title} />
      <div className="grid grid-cols-12 gap-4">
        {loading ? (
          <Indicators.AbsoluteOverlay />
        ) : (
          <div className="col-span-6">
            <ReportForm
              onSubmit={handleSubmit}
              defaultValues={{
                name: report?.name ?? "",
                calendars: calendarIds,
              }}
              searchResults={calendars.map((c: any) => ({
                label: c.name,
                secondaryLabel: c.id,
                id: c.id,
                value: c.id,
                icon: faCalendarAlt,
              }))}
              onSearch={handleSearch}
              loading={submitting}
            />
          </div>
        )}
        <div className="col-span-4 py-3">
          <h3 className="font-semibold mb-2">
            What is a Multi Calendar Report?
          </h3>
          <p className="text-sm">
            A multi-calendar report allows you to combine as many calendar
            projects as necessary to view your schedules at an aggregate level.
          </p>
        </div>
      </div>
      {report?.id && (
        <ReportDeleteForm
          entityName="Report"
          confirmationName={report.name}
          deleteAction={deleteAction}
          redirectAction={redirectAction}
        />
      )}
    </DetailView>
  )
}
