import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./SelectableItem.module.css"

/**
 * A containing item in the item explorer view that abstracts
 * the logic to handle selection and double click actions.
 */
class SelectableItem extends Component {
  constructor(props) {
    super(props)
    this.state = { lastClicked: 0 }
  }

  handleClick(e) {
    const { lastClicked } = this.state
    const { handleClick, handleDoubleClick } = this.props
    const time = new Date().getTime()
    const double = time - lastClicked < 500
    this.setState({ lastClicked: time })
    if (double) {
      handleDoubleClick(e)
    } else {
      handleClick(e)
    }
  }

  render() {
    const { selected, children } = this.props
    return (
      <div
        className={`${styles.selectableItem} ${
          selected ? styles.selected : styles.default
        }`}
        onClick={(e) => this.handleClick(e)}
      >
        {children}
      </div>
    )
  }
}

SelectableItem.propTypes = {
  /**
   * If true - it should indicate that the item is currently
   * selected in the UI.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * React child nodes to embed in this container element.
   */
  children: PropTypes.node,

  /**
   * Event handler that is fired when the user clicks on this
   * item.
   */
  handleClick: PropTypes.func,

  /**
   * Event handler that is fired when the user double clicks
   * on tis item.
   */
  handleDoubleClick: PropTypes.func,
}

export default SelectableItem
