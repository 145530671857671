import { rect, label } from "../../canvas";
import { DateTime } from "luxon";
var whiteText = "#fff";
var CellSize;
(function (CellSize) {
    CellSize[CellSize["ExtraSmall"] = 6] = "ExtraSmall";
    CellSize[CellSize["Small"] = 12] = "Small";
    CellSize[CellSize["Normal"] = 16] = "Normal";
    CellSize[CellSize["Medium"] = 24] = "Medium";
    CellSize[CellSize["Large"] = 32] = "Large";
    CellSize[CellSize["ExtraLarge"] = 48] = "ExtraLarge";
})(CellSize || (CellSize = {}));
export var renderMonth = function (ctx, _a) {
    var days = _a.days, darkDays = _a.darkDays, _b = _a.cellWidth, cellWidth = _b === void 0 ? CellSize.Medium : _b, pos = _a.pos, cellBackgroundColor = _a.cellBackgroundColor, borderColor = _a.borderColor, labelColor = _a.labelColor, selectedDate = _a.selectedDate, hoverColor = _a.hoverColor, inactiveCellBackgroundColor = _a.inactiveCellBackgroundColor, weekendCellBackgroundColor = _a.weekendCellBackgroundColor, selectedColor = _a.selectedColor, secondaryLabelColor = _a.secondaryLabelColor, height = _a.height, hoverIndex = _a.hoverIndex, dragging = _a.dragging, start = _a.start, _c = _a.renderingMode, renderingMode = _c === void 0 ? "days" : _c, todayFillColor = _a.todayFillColor, showToday = _a.showToday;
    var isWeekMode = renderingMode === "weeks";
    var canShowLabels = cellWidth >= CellSize.ExtraSmall;
    var canShowFullMonths = cellWidth >= CellSize.ExtraSmall;
    var isExtraLarge = cellWidth >= CellSize.ExtraLarge;
    var xOffset = pos.x;
    var shouldChangeMonthFormat = !isExtraLarge || isWeekMode;
    // Draw header cell (with month name)
    var headerHeight = 24;
    var headerWidth = days * cellWidth;
    if (!isExtraLarge) {
        rect(ctx, {
            fillStyle: cellBackgroundColor,
            x: xOffset,
            y: 0,
            w: headerWidth,
            h: headerHeight,
            strokeStyle: borderColor,
            lineWidth: 1,
        });
    }
    if (shouldChangeMonthFormat) {
        var monthLabel = start
            .toFormat(canShowFullMonths ? "MMMM yyyy" : "MMM yyyy")
            .toUpperCase();
        label(ctx, {
            x: xOffset + headerWidth / 2,
            y: 16,
            labelSize: 12,
            fillStyle: labelColor,
            text: monthLabel,
            textAlign: "center",
        });
    }
    var weekLabels = {};
    var labelSize = isExtraLarge ? 13 : 10;
    new Array(days).fill("").forEach(function (_, i) {
        var _a;
        var d = start.plus({ days: i });
        var isToday = showToday === true && d.hasSame(DateTime.local(), "day");
        var isSelected = selectedDate
            ? Math.round(d.diff(DateTime.fromISO(selectedDate, { zone: "utc" }), "days").days) === 0
            : false;
        var dark = darkDays ? darkDays[d.toUTC().toFormat("yyyy-MM-dd")] : null;
        var x = xOffset + i * cellWidth;
        var weekend = d.weekday > 5;
        var inactive = weekend || ((_a = dark === null || dark === void 0 ? void 0 : dark.length) !== null && _a !== void 0 ? _a : -1) > 0;
        var cellY = isExtraLarge && !isWeekMode ? 0 : headerHeight;
        // Draw day cell
        rect(ctx, {
            fillStyle: isToday
                ? todayFillColor
                : hoverIndex === i
                    ? hoverColor
                    : weekend && canShowLabels
                        ? weekendCellBackgroundColor
                        : inactive && canShowLabels
                            ? inactiveCellBackgroundColor
                            : cellBackgroundColor,
            x: x,
            y: cellY,
            w: cellWidth,
            h: height,
            strokeStyle: borderColor,
            lineWidth: isWeekMode ? 0 : 1,
        });
        // Draw selection indicator
        if (dragging && hoverIndex === i) {
            rect(ctx, {
                x: x + 2,
                y: cellY + 2,
                w: cellWidth - 4,
                h: height - 4,
                lineWidth: 2,
                strokeStyle: selectedColor,
            });
        }
        else if (isSelected) {
            rect(ctx, {
                x: x + 2,
                y: cellY + 2,
                w: cellWidth - 4,
                h: height - 4,
                lineWidth: 2,
                strokeStyle: selectedColor,
                lineDash: [7, 7],
            });
        }
        var isBeginningOfTheWeek = d.weekday === 1;
        if (isWeekMode && isBeginningOfTheWeek) {
            var labelText = cellWidth > CellSize.ExtraSmall
                ? "Wk " + d.toFormat("W")
                : d.toFormat("W");
            weekLabels[labelText] = x;
        }
        else if (!isWeekMode) {
            // Draw date label
            var dateLabel = isExtraLarge
                ? start.toFormat("MMM") + " " + d.toFormat("d")
                : d.toFormat("d");
            var firstRow = cellY + 14;
            var secondRow = cellY + 14 * 2;
            label(ctx, {
                x: x + cellWidth / 2,
                y: isExtraLarge ? secondRow + 10 : firstRow,
                labelSize: labelSize,
                fillStyle: isToday ? whiteText : labelColor,
                text: isExtraLarge ? dateLabel.toUpperCase() : dateLabel,
                textAlign: "center",
            });
            // Draw day label
            var dayLabel = isExtraLarge
                ? d.toFormat("EEE").toUpperCase()
                : d.toFormat("EEEEE");
            label(ctx, {
                x: x + cellWidth / 2,
                y: isExtraLarge ? firstRow + 7 : secondRow,
                labelSize: labelSize,
                fillStyle: isToday ? whiteText : secondaryLabelColor,
                text: dayLabel,
                textAlign: "center",
            });
        }
        Object.keys(weekLabels).forEach(function (weekLabel) {
            var x = weekLabels[weekLabel];
            var topOffset = 14;
            rect(ctx, {
                x: x,
                y: headerHeight,
                w: 0,
                h: height,
                strokeStyle: borderColor,
                lineWidth: 1,
            });
            if (canShowLabels) {
                label(ctx, {
                    x: x + 2,
                    y: headerHeight + topOffset,
                    labelSize: 10,
                    fillStyle: labelColor,
                    text: weekLabel,
                    textAlign: "left",
                });
            }
        });
    });
};
