import React from "react"
import PropTypes from "prop-types"
import styles from "./ItemPreview.module.css"

/**
 * Renders an item label from the item explorer.
 * @returns {ReactClass} A node representing a simple item preview.
 */
const ItemPreview = ({ name }) => (
  <div className={styles.itemPreview}>{name}</div>
)

ItemPreview.propTypes = {
  /**
   * The name to display in the preview bubble.
   */
  name: PropTypes.string.isRequired,
}

export default ItemPreview
