var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import clsx from "clsx";
export var GoogleButton = function (_a) {
    var className = _a.className, style = _a.style, disabled = _a.disabled, handleClick = _a.onClick, googleBtn = _a.googleBtn, googleBtnFocus = _a.googleBtnFocus, googleBtnDisabled = _a.googleBtnDisabled, googleBtnPressed = _a.googleBtnPressed;
    var _b = useState("normal"), mode = _b[0], setMode = _b[1];
    var displayMode = disabled || !handleClick ? "disabled" : mode;
    return (_jsxs("button", __assign({ className: clsx("relative block outline-none cursor-pointer", className), style: style, onFocus: function () {
            setMode("focused");
        }, onBlur: function () {
            setMode("normal");
        }, onMouseDown: function () {
            setMode("pressed");
        }, onMouseUp: function () {
            setMode("normal");
        }, onClick: function () {
            handleClick === null || handleClick === void 0 ? void 0 : handleClick();
        } }, { children: [_jsx("img", { src: googleBtn, alt: "Sign in with Google", style: { height: 46 }, className: clsx("block w-auto", displayMode !== "normal" && "opacity-0") }, void 0), _jsx("img", { src: googleBtnPressed, alt: "Sign in with Google", style: { height: 46 }, className: clsx("block w-auto absolute inset-0 z-10", displayMode !== "pressed" && "opacity-0") }, void 0), _jsx("img", { src: googleBtnFocus, alt: "Sign in with Google", style: { height: 46 }, className: clsx("block w-auto absolute inset-0 z-10", displayMode !== "focused" && "opacity-0") }, void 0), _jsx("img", { src: googleBtnDisabled, alt: "Sign in with Google", style: { height: 46 }, className: clsx("block w-auto absolute inset-0 z-10", displayMode !== "disabled" && "opacity-0") }, void 0)] }), void 0));
};
GoogleButton.displayName = "Form.GoogleButton";
