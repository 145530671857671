import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { PermissionResponse } from "./types"
import { camelizeKeys } from "humps"

export interface UpdatePermissionBody {
  manage: boolean
  read: boolean
  write: boolean
}

export interface UpdatePermissionParams extends RequestParams {
  permissionId: string
  invitationId: string
}

export const useUpdatePermission = (subdomain: string) => {
  const updateEntity = useUpdateEntity()
  const updatePermission = useApiRequest<
    PermissionResponse,
    UpdatePermissionBody,
    UpdatePermissionParams
  >({
    method: "PUT",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/invitations/:invitationId/granular_permissions/:permissionId`,
    onResponse: (response) => {
      updateEntity("permissions", response.id, {
        ...camelizeKeys(response),
        organization: subdomain,
      })
    },
  })
  return updatePermission
}
