import WeekView from "../components/WeekView.react"
import { connect } from "react-redux"
import * as actions from "../actions"
import * as sel from "../selectors"
import api from "../../api"
import * as selectable from "../../selectable"

const {
  itemSelector,
  styleSelector,
  eventSelector,
  categorySelector,
  reportSelector,
} = api.selectors

const makeMapStateToProps = () => {
  const rowsQuery = eventSelector.makeGetRowsForCalendarDuringPeriod()
  const selectedEvents = selectable.getSelectablesForType(
    selectable.Selectable.EVENT
  )
  return (state, props) => ({
    calendarMaxPosition: eventSelector.calendarMaxPosition(state, props),
    findStyle: styleSelector.find(state),
    findItem: itemSelector.find(state),
    findCategory: categorySelector.find(state),
    findLockedItem: itemSelector.findLocked(state),
    itemExplorerVisible: sel.itemExplorerVisible(state),
    searchValue: sel.searchValue(state),
    ignoreEvents: sel.ignoreEvents(state),
    selectedEvents: selectedEvents(state),
    hasSelection: selectable.hasSelectedItems(state),
    lastSelectedType: selectable.getLastSelectedType(state),
    selectedDate: selectable.selectedDate(state),
    clipboardMode: sel.clipboardMode(state),
    clipboardEvents: sel.clipboardEvents(state),
    partitions: rowsQuery(state, props),
    showDragUI: sel.showDragUI(state),
    lockedItemUuids: itemSelector.lockedItemUuids(state),
    findReportCalendar: reportSelector.findReportCalendar(state),
    findReportCalendars: reportSelector.findReportCalendars(state),
  })
}

const mapDispatchToProps = {
  ...actions,
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WeekView)
