var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Creatable from "react-select/creatable";
import clsx from "clsx";
export var StyleSelect = function (_a) {
    var id = _a.id, name = _a.name, placeholder = _a.placeholder, _b = _a.options, options = _b === void 0 ? [] : _b, handleOnInputChange = _a.onChange, handleSelect = _a.onSelect, handleCreate = _a.onCreate, value = _a.value, disabled = _a.disabled, clearable = _a.clearable, creatable = _a.creatable;
    var defaultValue = options.filter(function (s) { return s.uuid === value; });
    var handleChange = function (s) {
        var _a;
        handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect((_a = s === null || s === void 0 ? void 0 : s.uuid) !== null && _a !== void 0 ? _a : null);
    };
    return (_jsx("div", __assign({ className: "w-full flex-grow flex" }, { children: _jsx(Creatable, { className: "pro__client__multi__select_container w-full", classNamePrefix: "pro__client__multi__select", name: name, options: options, value: defaultValue, defaultValue: defaultValue, inputId: id, formatOptionLabel: function (option) {
                var _a, _b, _c, _d, _e, _f;
                return (_jsx("div", __assign({ className: "flex" }, { children: _jsx("div", __assign({ className: clsx(option.borderRadius !== "none" && "rounded-full", "px-2 mr-auto truncate"), style: {
                            background: (_a = option === null || option === void 0 ? void 0 : option.fillColor) !== null && _a !== void 0 ? _a : "#fff",
                            color: (_b = option === null || option === void 0 ? void 0 : option.textColor) !== null && _b !== void 0 ? _b : "#000",
                            textAlign: option.textAlignment,
                            border: "1px solid " + ((_c = option === null || option === void 0 ? void 0 : option.outlineColor) !== null && _c !== void 0 ? _c : "#000"),
                            fontFamily: option.font,
                            fontWeight: option.fontWeight,
                            fontSize: (_d = option === null || option === void 0 ? void 0 : option.fontSize) !== null && _d !== void 0 ? _d : 12,
                            textDecoration: option.textDecoration,
                        } }, { children: (_f = (_e = option.name) !== null && _e !== void 0 ? _e : option.label) !== null && _f !== void 0 ? _f : "Unnamed " + option.id }), void 0) }), void 0));
            }, getOptionLabel: function (option) { var _a; return (_a = (option.name || option.label)) !== null && _a !== void 0 ? _a : ""; }, getOptionValue: function (option) { return option.uuid; }, onInputChange: handleOnInputChange, onChange: handleChange, onCreateOption: handleCreate, placeholder: placeholder, isClearable: clearable, isDisabled: disabled, createOptionPosition: "last", isValidNewOption: function (inputValue) {
                return inputValue.length > 0 && (creatable !== null && creatable !== void 0 ? creatable : false);
            }, styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); } }, menuPortalTarget: process.env.NODE_ENV !== "test" ? document.body : undefined, allowCreateWhileLoading: true, closeMenuOnSelect: true }, void 0) }), void 0));
};
