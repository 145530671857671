import { combineReducers } from "redux"
import * as t from "./actionTypes"
import { ClipboardMode } from "./constants"
import { BEGIN_NEW_ITEM_DRAG, END_NEW_ITEM_DRAG } from "../selectable"

/* eslint-disable complexity */
function ignoreEvents(state = false, action) {
  switch (action.type) {
    case BEGIN_NEW_ITEM_DRAG:
    case t.BEGIN_EVENT_EXPAND:
      return true
    case END_NEW_ITEM_DRAG:
    case t.END_EVENT_EXPAND:
      return false
    default:
      return state
  }
}
/* eslint-enable complexity */

function showDragUI(state = false, action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
      return action.payload.showDragUI
    case t.END_DRAG:
      return false
    default:
      return state
  }
}

function dragEventId(state = 0, action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
      return action.payload.eventUuid
    case t.END_DRAG:
      return 0
    default:
      return state
  }
}

function dragEventDateOffset(state = 0, action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
    case t.SET_DRAG_OFFSET:
      return action.payload.dragOffset
    case t.END_DRAG:
      return 0
    default:
      return state
  }
}

function dragStartPosition(state = 0, action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
      return action.payload.position
    case t.END_DRAG:
      return 0
    default:
      return state
  }
}

function dragPositionMap(state = [], action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
      return action.payload.positionMap || state
    case t.END_DRAG:
      return []
    default:
      return state
  }
}

function dragStartDate(state = 0, action) {
  switch (action.type) {
    case t.BEGIN_DRAG:
      return action.payload.startsAt || state
    case t.END_DRAG:
      return 0
    default:
      return state
  }
}

function itemExplorerVisible(state = true, action) {
  switch (action.type) {
    case t.SHOW_ITEMS:
    case t.HIDE_ITEMS:
      return action.payload.itemExplorerVisible || false
    default:
      return state
  }
}

function searchValue(state = "", action) {
  switch (action.type) {
    case t.CHANGE_SEARCH_VALUE:
      return action.payload.searchValue || ""
    default:
      return state
  }
}

function itemPreviewType(state = "", action) {
  switch (action.type) {
    case t.UPDATE_ITEM_PREVIEW:
      return action.payload.itemPreviewType || state
    default:
      return state
  }
}

function clipboardEvents(state = [], action) {
  switch (action.type) {
    case t.COPY_EVENTS:
    case t.CUT_ITEMS:
      return action.payload.eventUuids || state
    case t.CLEAR_CLIPBOARD:
      return []
    default:
      return state
  }
}

function clipboardOrigin(state = "", action) {
  switch (action.type) {
    case t.COPY_EVENTS:
    case t.CUT_ITEMS:
      return action.payload.calendarId || state
    case t.CLEAR_CLIPBOARD:
      return []
    default:
      return state
  }
}

function clipboardMode(state = ClipboardMode.NONE, action) {
  switch (action.type) {
    case t.COPY_EVENTS:
      return ClipboardMode.COPY
    case t.CUT_ITEMS:
      return ClipboardMode.CUT
    case t.CLEAR_CLIPBOARD:
      return ClipboardMode.NONE
    default:
      return state
  }
}

function dateForSettingsMenu(state = null, action) {
  switch (action.type) {
    case t.SHOW_SETTINGS_FOR_DATE:
      return action.payload.selectedDate
    // case t.RESET_SELECTED_ITEMS:
    //   return null
    default:
      return state
  }
}

function contextMenu(state = null, action) {
  switch (action.type) {
    case t.REQUEST_CONTEXT:
      return action.payload
    case t.CLEAR_CONTEXT:
      return null
    default:
      return state
  }
}

export default combineReducers({
  ignoreEvents,
  showDragUI,
  clipboardMode,
  clipboardOrigin,
  clipboardEvents,
  dragEventDateOffset,
  dragEventId,
  dragStartDate,
  dragStartPosition,
  dragPositionMap,
  itemExplorerVisible,
  searchValue,
  itemPreviewType,
  dateForSettingsMenu,
  contextMenu,
})
