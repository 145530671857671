var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";
import React from "react";
import { ScrollTileView } from "../ScrollTileView";
import { CheckBox } from "./CheckBox";
var styles = {
    cell: "flex hover:bg-gray-100 p-1 text-xs border-l border-gray-200 truncate",
    container: "flex flex-row border-b border-gray-400 w-full",
};
var EditableValue = function (_a) {
    var value = _a.value, onChange = _a.onChange, onValidate = _a.onValidate, tabIndex = _a.tabIndex;
    var _b = React.useState(false), editing = _b[0], setEditing = _b[1];
    var _c = React.useState(true), valid = _c[0], setValid = _c[1];
    var _d = React.useState(value), newValue = _d[0], setNewValue = _d[1];
    var inputRef = React.useRef(null);
    var handleEdit = function () {
        setEditing(true);
    };
    var handleUpdate = function () {
        if (!onValidate || (onValidate === null || onValidate === void 0 ? void 0 : onValidate(newValue))) {
            setEditing(false);
            onChange(newValue);
            setValid(true);
        }
        else {
            setValid(false);
        }
    };
    var handleChange = function (e) {
        setNewValue(e.target.value);
    };
    var handleBlur = function () {
        handleUpdate();
    };
    var handleKeyDown = function (e) {
        if (e.key === "Enter") {
            handleUpdate();
        }
    };
    React.useEffect(function () {
        var _a;
        if (editing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editing]);
    var incomingValue = React.useRef(value);
    React.useEffect(function () {
        if (incomingValue.current !== value) {
            setNewValue(value);
        }
    }, [value, incomingValue, setNewValue]);
    return editing ? (_jsx("input", { ref: inputRef, className: clsx("w-full", !valid && "bg-red-100 text-red-600", !!valid && "bg-blue-100/50"), value: newValue, onChange: handleChange, onBlur: handleBlur, onKeyDown: handleKeyDown }, void 0)) : (_jsx("span", __assign({ className: "truncate w-full my-auto", onClick: handleEdit, onFocus: handleEdit, tabIndex: tabIndex }, { children: value }), void 0));
};
export var GanntTreeView = React.forwardRef(function (_a, forwardedRef) {
    var _b = _a.contents, contents = _b === void 0 ? [] : _b, _c = _a.expanded, expanded = _c === void 0 ? {} : _c, highlightedIndex = _a.highlightedIndex, handleIndexHover = _a.onIndexHover, handleToggleGroup = _a.onToggleGroup, handleDisableItem = _a.onDisableItem, disabledItems = _a.disabledItems, _d = _a.rowHeight, rowHeight = _d === void 0 ? 24 : _d, onValueChange = _a.onValueChange;
    var updateIndex = function (index) { return function () {
        if (handleIndexHover) {
            handleIndexHover(index);
        }
    }; };
    var updateGroup = function (group) { return function (e) {
        e.preventDefault();
        if (handleToggleGroup) {
            handleToggleGroup(group, !expanded[group.id]);
        }
    }; };
    var handleToggleWithId = function (id, parentId) { return function (newVal) {
        handleDisableItem === null || handleDisableItem === void 0 ? void 0 : handleDisableItem(id, newVal, parentId);
    }; };
    var getGroupState = function (root) {
        var findDisabled = function (g) {
            var _a, _b;
            return ((_b = (_a = g.contents) !== null && _a !== void 0 ? _a : g.inlineContents) !== null && _b !== void 0 ? _b : []).reduce(function (a, c) { return __spreadArray(__spreadArray(__spreadArray([], a, true), [
                !!(disabledItems === null || disabledItems === void 0 ? void 0 : disabledItems[c.id])
            ], false), findDisabled(c), true); }, []);
        };
        var results = findDisabled(root);
        var hasEnabled = results.indexOf(false) > -1;
        var hasDisabled = results.indexOf(true) > -1;
        return hasEnabled ? (hasDisabled ? "partial" : "enabled") : "disabled";
    };
    return (_jsx(ScrollTileView, __assign({ defaultPosition: "start", scrollDirection: "vertical", tileCount: contents.length, cellSize: { width: 600, height: rowHeight }, ref: forwardedRef, scrollable: true, throttle: 150, preRenderCount: 2 }, { children: function (_a) {
            var _b;
            var index = _a.index, tileSize = _a.tileSize;
            var result = (_b = contents[index]) !== null && _b !== void 0 ? _b : {};
            var nodeState = (disabledItems === null || disabledItems === void 0 ? void 0 : disabledItems[result.id]) === true ? "disabled" : "enabled";
            var active = nodeState !== "disabled";
            if ("startsAt" in result) {
                var name_1 = result.name, startsAt_1 = result.startsAt, length_1 = result.length, _c = result.indent, indent = _c === void 0 ? 0 : _c;
                var format = function (date, days) {
                    if (days === void 0) { days = 0; }
                    return DateTime.fromISO(date, { zone: "utc" })
                        .plus({ days: days })
                        .toFormat("MM/dd/yy");
                };
                return (_jsxs("div", __assign({ className: styles.container, style: { height: tileSize } }, { children: [_jsx(CheckBox, { onToggle: handleToggleWithId(result.id, result.category), buttonState: nodeState }, void 0), _jsx("span", { className: "bg-gray-200 block h-full flex-shrink-0", style: { width: indent + "rem" } }, void 0), _jsxs("div", __assign({ className: clsx(!active && "opacity-25", "w-full grid grid-cols-5") }, { children: [_jsx("h4", __assign({ className: clsx("col-span-2", styles.cell, highlightedIndex === index && "bg-gray-100"), onMouseOver: updateIndex(index) }, { children: _jsx(EditableValue, { value: name_1, onChange: function (newVal) {
                                            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(result.id, "name", newVal);
                                        }, onValidate: function (newVal) { return newVal.length > 0; }, tabIndex: 0 }, void 0) }), void 0), _jsx("p", __assign({ className: clsx(styles.cell, highlightedIndex === index && "bg-gray-100") }, { children: _jsx(EditableValue, { value: format(startsAt_1), onChange: function (newVal) {
                                            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(result.id, "startsAt", DateTime.fromFormat(newVal, "MM/dd/yy").toISODate());
                                        }, onValidate: function (newVal) {
                                            return DateTime.fromFormat(newVal, "MM/dd/yy").isValid;
                                        }, tabIndex: 0 }, void 0) }), void 0), _jsx("p", __assign({ className: clsx(styles.cell, highlightedIndex === index && "bg-gray-100") }, { children: _jsx(EditableValue, { value: format(startsAt_1, length_1), onChange: function (newVal) {
                                            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(result.id, "length", (Math.floor(DateTime.fromFormat(newVal, "MM/dd/yy").diff(DateTime.fromISO(startsAt_1), "days").days) || 1).toString());
                                        }, onValidate: function (newVal) {
                                            return DateTime.fromFormat(newVal, "MM/dd/yy").isValid &&
                                                DateTime.fromFormat(newVal, "MM/dd/yy").diff(DateTime.fromISO(startsAt_1), "days").days > 0;
                                        }, tabIndex: 0 }, void 0) }), void 0), _jsx("p", __assign({ className: clsx(styles.cell, highlightedIndex === index && "bg-gray-100") }, { children: _jsx(EditableValue, { value: length_1.toString(), onChange: function (newVal) {
                                            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(result.id, "length", newVal);
                                        }, onValidate: function (newVal) { return Number(newVal) > 0; }, tabIndex: 0 }, void 0) }), void 0)] }), void 0)] }), void 0));
            }
            else if ("contents" in result) {
                var name_2 = result.name, id = result.id, _d = result.indent, indent = _d === void 0 ? 0 : _d;
                var nodeState_1 = getGroupState(result);
                var active_1 = nodeState_1 === "enabled";
                return (_jsxs("div", __assign({ className: styles.container, style: { height: tileSize } }, { children: [_jsx(CheckBox, { onToggle: handleToggleWithId(result.id), buttonState: nodeState_1 }, void 0), _jsx("span", { className: "bg-gray-200 block h-full", style: { width: indent + "rem" } }, void 0), _jsxs("button", __assign({ className: clsx(styles.cell, "w-full", !active_1 && "opacity-25", highlightedIndex === index && "bg-gray-100"), onClick: updateGroup(result) }, { children: [_jsx(FontAwesomeIcon, { icon: expanded[id] ? faMinus : faPlus, className: "my-auto mr-2" }, void 0), _jsx("span", __assign({ className: "my-auto" }, { children: name_2 }), void 0)] }), void 0)] }), void 0));
            }
            else {
                return _jsx("div", { className: styles.container }, void 0);
            }
        } }), void 0));
});
