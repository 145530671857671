import { entities } from "./baseSelector"

/**
 * Returns true if the current user has access to the analytics dashboard.
 * @param  {Object} state The current state of the redux store.
 * @return {Boolean}      A boolean flag indicating access.
 */
export const canViewAnalytics = (state) => {
  const isRevolutionAdmin = ((state.api || {}).admin || []).includes(
    "revolution-entertainment-services"
  )
  const isSuperAdmin = (state.api || {}).superAdmin
  return isRevolutionAdmin || isSuperAdmin
}

/**
 * Returns a count metric for active users in the past X days if one exists.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that accepts a number of days to fetch a related metric for..
 */
export const activeUserCount = (state) => (days) => {
  return (entities(state).metrics[`active_user_count_past_${days}_days`] || {})
    .value
}

/**
 * Returns a count metric for the total amount of users in the system.
 * @param  {Object} state The current state of the redux store.
 * @return {Number}       Thte total number of users.
 */
export const totalUserCount = (state) => {
  return (entities(state).metrics[`total_user_count`] || {}).value
}

/**
 * Returns all active users in the past X days if any exist.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that accepts a number of days to fetch a related metric for..
 */
export const activeUsers = (state) => (days) => {
  return Object.keys(entities(state).metrics)
    .filter((k) => k.indexOf(`active_users_past_${days}_days`) > -1)
    .map((k) => entities(state).metrics[k])
    .sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0))
}

/**
 * Returns all active calendars in the past X days if any exist.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that accepts a number of days to fetch a related metric for..
 */
export const activeCalendars = (state) => (days) => {
  return Object.keys(entities(state).metrics)
    .filter((k) => k.indexOf(`active_calendars_past_${days}_days`) > -1)
    .map((k) => entities(state).metrics[k])
    .sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0))
}

/**
 * Returns all invitations in the past X days if any exist.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that accepts a number of days to fetch a related metric for..
 */
export const invitations = (state) => (days, status) => {
  return Object.keys(entities(state).metrics)
    .filter((k) => k.indexOf(`invitations_past_${days}_days`) > -1)
    .map((k) => entities(state).metrics[k])
    .filter((i) =>
      ["pending", "accepted"].includes(status) ? i.meta4 === status : true
    )
    .sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0))
}
