export const NAME = "api"
export const ENTITY_TYPES = {
  CATEGORIES: "categories",
  EVENTS: "events",
  CALENDARS: "calendars",
  INVITATIONS: "invitations",
  ORGANIZATIONS: "organizations",
  ITEMS: "items",
  USERS: "users",
}
