import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { styles as actions } from "../actions"
import { styleSelector } from "../selectors"

export const useStyles = (
  subdomain: string,
  calendarId: string,
  requiredUuids: string[] = []
) => {
  const calendarDoesNotExist = !calendarId || calendarId === "undefined"
  const styles = useSelector(styleSelector.forCalendar)(calendarId)
  const dispatch = useDispatch()
  const request = async () => {
    if (calendarDoesNotExist) {
      return null
    }
    await dispatch(actions.requestStyle(subdomain, calendarId, null))
  }
  const { loading, refetch } = useRequest(request)
  if (calendarDoesNotExist) {
    return { loading, refetch, styles: [] }
  }
  const uniqueKey = (s: any) => [s.fillColor, s.textColor, s.name].join("#")
  const styleKeys = styles.map(uniqueKey)
  const uniqueStyles = styles.filter(
    (s: any, i: number) =>
      styleKeys.indexOf(uniqueKey(s)) === i || requiredUuids.includes(s.uuid)
  )
  return { loading, styles: uniqueStyles, refetch }
}
