import { useState, useEffect } from "react";
export var useDefaultValueListener = function (defaultValues, reset, transformValues) {
    var _a = useState(undefined), appliedDefaults = _a[0], setAppliedDefaults = _a[1];
    useEffect(function () {
        var _a;
        if (JSON.stringify(appliedDefaults) !== JSON.stringify(defaultValues)) {
            setAppliedDefaults(defaultValues);
            reset((_a = transformValues === null || transformValues === void 0 ? void 0 : transformValues(defaultValues)) !== null && _a !== void 0 ? _a : defaultValues);
        }
    }, [reset, defaultValues, appliedDefaults]);
};
