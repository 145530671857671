var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Icon } from "../Icon";
import { ToolTip } from "./ToolTip";
import clsx from "clsx";
var BUTTON_ITEM = "relative text-lochivar-default border border-lochivar-default text-xs lg:text-sm px-1 lg:px-2 my-auto h-8 xl:h-10 first:rounded-l-lg first:border-r-0 last:rounded-r-lg last:border-l-0";
var BASE_ITEM = "outline-none active:outline-none border-0 my-auto h-8 w-8 lg:w-10 lg:h-10 rounded-full relative transition ease-out duration-200 flex-shrink-0";
var DISABLED_ITEM = "opacity-50 cursor-not-allowed pointer-events-none";
var NEGATIVE_INDICATED_ITEM = BASE_ITEM + " bg-gray-200 hover:bg-gray-300";
var PRIMARY_INDICATED_ITEM = BASE_ITEM + " bg-burgundy-default hover:bg-burgundy-dark";
var SECONDARY_INDICATED_ITEM = BASE_ITEM + " bg-lochivar-default/0 hover:bg-lochivar-dark/50";
var styleForItem = function (_a) {
    var indicated = _a.indicated, disabled = _a.disabled, indicatorMode = _a.indicatorMode, appearance = _a.appearance;
    if (appearance === "button") {
        return BUTTON_ITEM;
    }
    var baseClass = BASE_ITEM;
    if (indicated) {
        switch (indicatorMode) {
            case "primary":
                baseClass = PRIMARY_INDICATED_ITEM;
                break;
            case "secondary":
                baseClass = SECONDARY_INDICATED_ITEM;
                break;
            default:
                baseClass = NEGATIVE_INDICATED_ITEM;
                break;
        }
    }
    return clsx(baseClass, disabled && DISABLED_ITEM);
};
export var BarItem = function (_a) {
    var children = _a.children, className = _a.className, disabled = _a.disabled, onClick = _a.onClick, icon = _a.icon, label = _a.label, toolTip = _a.toolTip, toolTipDirection = _a.toolTipDirection, toolTipAlignment = _a.toolTipAlignment, shortCut = _a.shortCut, _b = _a.mode, mode = _b === void 0 ? "primary" : _b, indicated = _a.indicated, _c = _a.indicatorMode, indicatorMode = _c === void 0 ? "negative" : _c, _d = _a.appearance, appearance = _d === void 0 ? "icon" : _d;
    var _e = useState(false), hover = _e[0], setHover = _e[1];
    var handleClick = function (e) {
        e.preventDefault();
        if (!disabled) {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }
    };
    var isButton = appearance === "button";
    return (_jsxs("button", __assign({ onClick: handleClick, style: isButton ? {} : { outlineColor: "rgba(0,0,0,0)" }, onMouseOver: function () { return setHover(true); }, onMouseOut: function () { return setHover(false); }, className: clsx(!disabled && "cursor-pointer", styleForItem({ indicated: indicated, disabled: disabled, indicatorMode: indicatorMode, appearance: appearance }), className) }, { children: [icon ? (_jsx("div", __assign({ className: clsx("flex m-auto", appearance === "button"
                    ? "text-md px-1"
                    : "text-md lg:text-md xl:text-xl") }, { children: _jsx(Icon, { icon: icon, mode: mode }, void 0) }), void 0)) : label ? (_jsx("span", __assign({ className: "m-auto" }, { children: label }), void 0)) : (_jsx("div", __assign({ className: "flex m-auto" }, { children: children }), void 0)), toolTip ? (_jsx(ToolTip, { label: toolTip, direction: toolTipDirection, align: toolTipAlignment, active: hover, shortCut: shortCut }, void 0)) : null] }), void 0));
};
