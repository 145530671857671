var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { concatStyles } from "utils";
var BUTTON_GROUP_STYLES = "flex";
var BUTTON_GROUP_ITEM_STYLES = BUTTON_GROUP_STYLES;
var makeFlexDirection = function (direction) {
    return direction === "column" ? "flex-col" : "flex-row";
};
var makeAdjacentStyles = function (direction) {
    return direction === "column" ? "mt-2" : "ml-2";
};
var makeFlexGrow = function (grow) { return (grow ? "flex-grow" : ""); };
export var ButtonGroup = function (_a) {
    var children = _a.children, _b = _a.direction, direction = _b === void 0 ? "row" : _b, className = _a.className, itemClassName = _a.itemClassName, style = _a.style, itemStyle = _a.itemStyle, grow = _a.grow;
    return (_jsx("ul", __assign({ className: concatStyles([
            BUTTON_GROUP_STYLES,
            makeFlexDirection(direction),
            makeFlexGrow(grow),
            className,
        ]), style: style }, { children: React.Children.map(children, function (child, index) {
            return child ? (_jsx("li", __assign({ className: concatStyles([
                    BUTTON_GROUP_ITEM_STYLES,
                    itemClassName,
                    makeFlexGrow(grow),
                    index > 0 ? makeAdjacentStyles(direction) : "",
                ]), style: itemStyle }, { children: child }), void 0)) : null;
        }) }), void 0));
};
ButtonGroup.displayName = "Form.ButtonGroup";
