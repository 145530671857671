import { useState } from "react"
import {
  OnBoardLayout,
  SignupForm,
  AsyncSubmitHandler,
  SignupFormValues,
} from "ui"
import { wait } from "utils"
import { registerAndLoginUser } from "../api"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import calendarScreenshot from "./calendar-screen.png"
import { useHistory } from "react-router"
import logoPath from "../images/ProCal-logo-and-wordmark.svg"
import { Link } from "react-router-dom"

const quote = {
  author: "Jeff Anderson",
  title: "Executive In Charge of Production",
  text: `In a position that relies heavily on creating functional and flexible production calendars, ProCal is proving to be an essential tool. The easy to use interface and ability to have multiple shared calendars in one location, has made the scheduling process for productions not only easier, but incredibly more efficient. As anyone responsible for tracking the ever changing dynamics of a production's schedule will know, this software has been missing in the industry for far too long.`,
}

export const OnboardFlowAccount = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleLogin: AsyncSubmitHandler<SignupFormValues> = async (data) => {
    const { name, email, password, confirm } = data
    setLoading(true)
    await wait(1)
    try {
      const { response } = (await dispatch(
        registerAndLoginUser({
          name,
          username: email,
          password,
          confirm,
        })
      )) as any
      setLoading(false)
      if ([200, 201].includes(response.status)) {
        toast.success("Your account has been created. Welcome to PROCal!")
        history.replace("/onboard/company")
      } else {
        toast.error(
          response?.responseJson?.error ??
            "There was a problem creating your account."
        )
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  return (
    <OnBoardLayout
      quoteAuthor={quote.author}
      quoteAuthorTitle={quote.title}
      quote={quote.text}
      headline="Get Started With ProCal"
      subheadline="Create your new account in a few seconds!"
      screenshotPath={calendarScreenshot}
      logoPath={logoPath}
    >
      <SignupForm onSubmit={handleLogin} loading={loading} />
      <Link
        to="/login"
        className="w-full underline text-sorbus-default my-4 text-center block"
      >
        Already have an account?
      </Link>
    </OnBoardLayout>
  )
}
