var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { camelCase, kebabCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AbsoluteOverlay } from "../Indicators";
import { Fields, TextField, ColorSwatch, ErrorList, Button } from "../Form";
import { useDefaultValueListener, useInputFocus } from "../../hooks";
var COLORS = [
    "#d0021b",
    "#f5a623",
    "#f8e71c",
    "#8b9bae",
    "#7ed321",
    "#9b59b6",
    "#3498db",
    "#2ecc71",
    "#e74c3c",
    "#1abc9c",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#1abc9c",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#1abc9c",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#1abc9c",
];
var schema = yup.object({
    id: yup.string().nullable(),
    companyName: yup.string().required(),
    subdomain: yup.string().required(),
    color: yup.string().required(),
});
var FULL_WIDTH = "w-full flex-grow";
var SMALL_STYLES = FULL_WIDTH + " italic text-sm mt-1 text-gray-500";
var BUTTON_STYLES = "mt-2 " + FULL_WIDTH;
export var DEFAULT_COMPANY_COLOR = function () {
    return COLORS[Math.floor(Math.random() * COLORS.length)];
};
export var CompanyForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, _b = _a.defaultValues, defaultValues = _b === void 0 ? {
        companyName: "",
        subdomain: "",
        color: DEFAULT_COMPANY_COLOR(),
    } : _b, _c = _a.submitTitle, submitTitle = _c === void 0 ? "Create Company" : _c;
    var _d = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
    }), handleSubmit = _d.handleSubmit, register = _d.register, formErrors = _d.formState.errors, setError = _d.setError, reset = _d.reset, watch = _d.watch, setValue = _d.setValue;
    var _e = watch([
        "companyName",
        "subdomain",
        "id",
        "color",
    ]), companyName = _e[0], subdomain = _e[1], id = _e[2], color = _e[3];
    useEffect(function () {
        if (companyName && !id) {
            setValue("subdomain", kebabCase(companyName));
        }
    }, [companyName, setValue]);
    var field = useInputFocus();
    useDefaultValueListener(defaultValues, reset);
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var handleColor = function (hex) {
        console.log("handleColor", hex);
        setValue("color", hex);
    };
    return (_jsxs("form", __assign({ onSubmit: handleFormSubmit }, { children: [_jsxs(Fields, __assign({ register: register, errors: formErrors, grow: true }, { children: [_jsx(TextField, { name: "companyName", label: "Company Name", placeholder: "Company Name", ref: field, className: FULL_WIDTH }, void 0), _jsx(TextField, __assign({ name: "subdomain", label: "Subdomain", placeholder: "Subdomain", ref: field, className: FULL_WIDTH, onChange: function (evt) {
                            var value = evt.target.value;
                            var formatted = kebabCase(value);
                            evt.target.value = formatted;
                            setValue("subdomain", formatted);
                        }, disabled: !!id }, { children: _jsxs("small", __assign({ className: SMALL_STYLES }, { children: ["app.procal.io/org/", subdomain] }), void 0) }), void 0), _jsx(ColorSwatch, { name: "color", label: "Color", value: color, onChange: handleColor, colors: COLORS, selfControllable: true }, void 0), _jsx("input", { type: "hidden", name: "id" }, void 0), _jsx(ErrorList, { errors: formErrors }, void 0), _jsx(Button, __assign({ type: "submit", className: BUTTON_STYLES }, { children: submitTitle }), void 0)] }), void 0), loading ? _jsx(AbsoluteOverlay, {}, void 0) : null] }), void 0));
};
