var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
var styles = {
    container: "bg-gradient-to-br from-revolution-purple via-revolution-blue to-revolution-green h-screen w-screen overflow-hidden relative flex",
    signupSection: "m-2 sm:m-6 w-full md:m-auto md:w-[500px] md:rounded-lg xl:rounded-none bg-white xl:h-screen overflow-auto p-4 md:p-8",
    shadow: "shadow-lg shadow-burgunday-dark/90",
    cta: "font-bold text-xl text-center",
    subtitle: "text-md mb-4 text-center",
    formWrapper: "rounded p-2 bg-white my-2 border border-stone-200",
    complimentaryContent: "hidden xl:block flex-grow relative overflow-hidden",
    screenshot: "hidden xl:block w-[1200px] h-auto absolute left-1/2 ml-[-600px] top-1/2 mt-[-400px] rounded-lg border border-stone-400 opacity-50",
    quote: "hidden xl:block mt-4 absolute bottom-0 mb-12 right-0 -mr-4 rounded xl:bg-white p-12 text-white xl:text-stone-800 w-[400px]",
    quoteText: "text-lg",
    quoteCite: "text-stone-500 flex flex-col mt-8",
    quoteAuthor: "font-bold text-lochivar-default",
    quoteAuthorTitle: "text-sm font-italic",
    logo: "w-48 h-auto block mx-auto my-8",
};
export var OnBoardLayout = function (_a) {
    var style = _a.style, screenshotPath = _a.screenshotPath, quote = _a.quote, quoteAuthor = _a.quoteAuthor, quoteAuthorTitle = _a.quoteAuthorTitle, headline = _a.headline, subheadline = _a.subheadline, children = _a.children, logoPath = _a.logoPath;
    return (_jsxs("div", __assign({ className: styles.container, style: style }, { children: [_jsxs("article", __assign({ className: clsx(styles.signupSection, styles.shadow) }, { children: [logoPath && _jsx("img", { src: logoPath, className: styles.logo }, void 0), _jsx("h1", __assign({ className: styles.cta }, { children: headline }), void 0), _jsx("h2", __assign({ className: styles.subtitle }, { children: subheadline }), void 0), _jsx("div", __assign({ className: styles.formWrapper }, { children: children }), void 0)] }), void 0), _jsxs("div", __assign({ className: styles.complimentaryContent }, { children: [screenshotPath && (_jsx("img", { src: screenshotPath, className: clsx(styles.screenshot, styles.shadow), alt: "" }, void 0)), _jsxs("article", __assign({ className: clsx(styles.quote, styles.shadow) }, { children: [_jsxs("p", __assign({ className: styles.quoteText }, { children: ["\"", quote, "\""] }), void 0), _jsxs("cite", __assign({ className: styles.quoteCite }, { children: [_jsx("span", __assign({ className: styles.quoteAuthor }, { children: quoteAuthor }), void 0), _jsx("span", __assign({ className: styles.quoteAuthorTitle }, { children: quoteAuthorTitle }), void 0)] }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
OnBoardLayout.displayName = "OnBoard.OnBoardLayout";
