import React, { useState, useEffect } from "react"
import { useLocation, useRouteMatch, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppNavBar, AppNavBarOption } from "./AppNavBar"
import {
  authSelector,
  organizationSelector,
  updateAuthRoles,
  getAccessToken,
  logoutUser,
  useTouchOrg,
} from "../../api"
import OrganizationList from "../containers/OrganizationList.react"
import styles from "./AppNavigator.module.css"
import queryString from "query-string"
import { DateTime } from "luxon"
import { HelpModal, ShareModal } from "../../modals"
import { ExpressSignInModal } from "./ExpressSignInModal"
import { Drawer } from "./Drawer"
import { AdminBar, AdminBarOption } from "./AdminNavBar"
import { WindowSize } from "ui"
import { CalendarDrawer } from "./CalendarDrawer"
import { ReportDrawer } from "./ReportDrawer"
import { InvitationDrawer } from "./InvitationDrawer"
import clsx from "clsx"
import revolutionLogo from "../../images/revolution-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { getCookie, setCookie } from "react-simple-cookie-store"
import { useCurrentInvitedUser } from "./useCurrentInvitedUser"

const COOKIE = "TRIAL_PROMPT"

declare const gtag: any

const DRAWER_CONTAINER_STYLES =
  "bg-white shadow-xl border-r border-lochivar-default border-opacity-25 fixed top-0 h-screen z-100 flex"
const DRAWER_LEFT_MARGIN = 49
const TRIAL_ALERT_STYLES =
  "text-sm text-white text-center py-3 px-4 rounded-lg shadow-lg fixed bottom-0 mb-12 z-10 left-[50px] ml-4 flex flex-row"
const CALM_STYLES =
  "bg-gradient-to-br from-cyan-500 to-blue-500 shadow-cyan-800/90"
const AGITATING_STYLES =
  "bg-gradient-to-br from-amber-500 to-red-500 shadow-amber-800/90"
const TRIAL_LINK_STYLES =
  "p-2 rounded-md underline shadow-lg ml-2 -mr-2 font-bold "
const TRIAL_LINK_ACTION_STYLES =
  "bg-gradient-to-br from-sorbus-default to-sorbus-dark hover:text-white text-white focus:text-white active:text-white shadow-cyan-800/90"
const TRIAL_LINK_INVERT_STYLES =
  "bg-gradient-to-br from-white to-stone-100 hover:text-sorbus-default text-sorbus-default focus:text-sorbus-default active:text-sorbus-default"
/**
 * The primary toolbar for navigating the ProCal application.
 * @param props Component configuration props.
 */
const AppNavigator: React.FC = ({ children }) => {
  const trialPromptDismissed = getCookie(COOKIE) === "yes"
  const dismissTrialPrompt: React.MouseEventHandler = (e) => {
    e.preventDefault()
    setCookie(COOKIE, "yes", 1)
  }
  const [section, setSection] = useState(
    undefined as AppNavBarOption | undefined
  )
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const subdomainMatch = useRouteMatch<{ subdomain: string }>("/org/:subdomain")
  const subdomain = subdomainMatch?.params?.subdomain
  const organization = useSelector((state) =>
    organizationSelector.find(state)(subdomain)
  )
  useCurrentInvitedUser(subdomain)

  const [touch] = useTouchOrg(subdomain ?? "")
  const [lastOrg, setLastOrg] = useState<string | null>(null)
  useEffect(() => {
    if (subdomain && subdomain !== lastOrg) {
      touch()
      setLastOrg(subdomain)
    }
  }, [location, setLastOrg, touch, subdomain, lastOrg])

  const isTrialMode = organization?.isTrial === true
  const trialDaysLeft = Math.round(
    DateTime.fromISO(organization?.trialEndsAt ?? "").diffNow("days").days
  )
  const trialExpired = trialDaysLeft < 1
  const upgradeSubject = `${organization?.name} ProCal Upgrade`
  const upgradeBody = encodeURI(`Hi there,
I would like to upgrade from my trial to a ProCal Pro account.

Company: ${organization?.name}
Subdomain: ${organization?.subdomain}

Any additional notes or questions:
[Please add any additional notes or questions here.]`)

  const needsPassword = useSelector<any, boolean>(authSelector.needsPassword)

  const dismissSection = () => setSection(undefined)

  const accessToken = getAccessToken()

  useEffect(() => {
    dispatch(updateAuthRoles(accessToken))
  }, [dispatch, accessToken])

  const handleAdminBarSelection = (selection: AdminBarOption) => {
    switch (selection) {
      case "back":
        history.push("/")
        break
      case "dashboard":
        history.push("/admin/")
        break
      case "help":
        history.push(`${window.location.pathname}?help=true`)
        break
      case "logout":
        dispatch(logoutUser())
        break
    }
  }

  const handleNavBarSelection = (selection: AppNavBarOption) => {
    switch (selection) {
      case "dashboard":
        history.push("/admin/")
        break
      case "help":
        history.push(`${window.location.pathname}?help=true`)
        break
      case "share":
        history.push(`${window.location.pathname}?share=true`)
        break
      case "logout":
        dispatch(logoutUser())
        break
    }
    if (section !== selection) {
      setSection(selection)
    } else {
      dismissSection()
    }
  }

  const titleForSection = (section?: AppNavBarOption) => {
    switch (section) {
      case "calendar":
        return "Calendars"
      case "organization":
        return "Organizations"
      case "report":
        return "Reports"
      default:
        return "Users"
    }
  }
  const showDrawer = ["organization", "calendar", "user", "report"].includes(
    section as string
  )

  useEffect(() => {
    if (showDrawer) {
      gtag("event", section, {
        event_category: "calendar toolbar",
        event_label: `toolbar => ${section}`,
        value: 1,
      })
    }
  }, [showDrawer, section])

  const query = queryString.parse(location.search)
  const { name, color } = organization || {}
  return (
    <WindowSize method="debounce" wait={500}>
      {query.isServer ? (
        children
      ) : (
        <div className={styles.container}>
          <div
            className={`${styles.barContainer} fixed z-50 left-0 flex print:hidden`}
          >
            {subdomain && subdomain !== "new" ? (
              <AppNavBar
                orgName={name}
                orgColor={color}
                subdomain={subdomain}
                onSelect={handleNavBarSelection}
              />
            ) : (
              <AdminBar onSelect={handleAdminBarSelection} />
            )}
            {section === "organization" ? (
              <div
                className={DRAWER_CONTAINER_STYLES}
                style={{ left: DRAWER_LEFT_MARGIN }}
              >
                <Drawer
                  title={titleForSection(section)}
                  show={showDrawer}
                  onClose={dismissSection}
                >
                  <OrganizationList
                    onDismiss={dismissSection}
                    subdomain={subdomain}
                  />
                </Drawer>
              </div>
            ) : null}
            {section === "calendar" ? (
              <div
                className={DRAWER_CONTAINER_STYLES}
                style={{ left: DRAWER_LEFT_MARGIN }}
              >
                <CalendarDrawer onDismiss={dismissSection} />
              </div>
            ) : null}
            {section === "report" ? (
              <div
                className={DRAWER_CONTAINER_STYLES}
                style={{ left: DRAWER_LEFT_MARGIN }}
              >
                <ReportDrawer onDismiss={dismissSection} />
              </div>
            ) : null}
            {section === "user" ? (
              <div
                className={DRAWER_CONTAINER_STYLES}
                style={{ left: DRAWER_LEFT_MARGIN }}
              >
                <InvitationDrawer onDismiss={dismissSection} />
              </div>
            ) : null}
          </div>
          <div className={styles.content}>
            <div className={styles.contentScroll}>{children}</div>
            {isTrialMode && !trialPromptDismissed && !trialExpired && (
              <div className={clsx(TRIAL_ALERT_STYLES, CALM_STYLES)}>
                <p>
                  <span className="font-bold">{trialDaysLeft} Days left</span>{" "}
                  in your free trial.{" "}
                  <a
                    href={`mailto:support@revolutiones.com?cc=jai.sathyan@revolutiones.com&subject=${upgradeSubject}&body=${upgradeBody}`}
                    className={clsx(
                      TRIAL_LINK_STYLES,
                      TRIAL_LINK_ACTION_STYLES
                    )}
                  >
                    Upgrade Today
                  </a>
                </p>
                <button
                  className={clsx(
                    AGITATING_STYLES,
                    "block h-8 w-8 absolute right-0 top-0 -m-4 rounded-full text-xl shadow-md"
                  )}
                  onClick={dismissTrialPrompt}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
            {isTrialMode && trialExpired && (
              <div className={clsx(TRIAL_ALERT_STYLES, AGITATING_STYLES)}>
                <p>
                  <span className="font-bold">Your trial has expired.</span>{" "}
                  Upgrade to continue using ProCal.{" "}
                  <a
                    href={`mailto:support@revolutiones.com?&subject=${upgradeSubject}&body=${upgradeBody}`}
                    className={clsx(
                      TRIAL_LINK_STYLES,
                      TRIAL_LINK_INVERT_STYLES
                    )}
                  >
                    Upgrade Today
                  </a>
                </p>
              </div>
            )}
            <div className={styles.footer}>
              <p className="truncate flex pl-16">
                <span>Brought to you by&nbsp;</span>
                <img
                  src={revolutionLogo}
                  alt="Revolution Logo"
                  className=" h-5 w-auto"
                />
                <span
                  className="uppercase my-auto ml-4"
                  style={{ fontSize: 9 }}
                >
                  Payroll | Tax Incentives | Labor Relations | Digital Crew
                  Onboarding | Short-Form Budgeting | Production Planning |
                  Accounting | Watermarking &amp; Distribution
                </span>
              </p>
            </div>
          </div>
          {needsPassword ? <ExpressSignInModal /> : null}
          {query.help ? (
            <HelpModal
              subdomain={subdomain ?? ""}
              draggable
              onClose={() => history.goBack()}
            />
          ) : null}
          {query.share ? (
            <ShareModal
              subdomain={subdomain ?? ""}
              draggable
              onClose={() => history.goBack()}
            />
          ) : null}
        </div>
      )}
    </WindowSize>
  )
}

export default AppNavigator
