import { reduxForm } from "redux-form"
import { FORM_NAME } from "../constants"
import { asyncValidate, validate } from "../validators"
import InvitationFormView from "../components/InvitationFormView.react"

export default reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ["email"],
})(InvitationFormView)
