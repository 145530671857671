import { entities } from "./baseSelector"
import flatten from "lodash/flatten"
import { DateTime } from "luxon"

/**
 * Returns all icon entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Object}       A function that returns the icon entities from state.
 */
const all = (state) => Object.values(entities(state).icons)

/**
 * Returns a query function that fetches all icons for a single calendar or calendars if
 * multiple ids are specified.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of icons that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarId) =>
  all(state)
    .filter((icon) =>
      flatten([calendarId])
        .map((id) => parseInt(id, 0))
        .includes(parseInt(icon.calendarId, 0))
    )
    .filter((i) => !i.isDeleted)

/**
 * Returns a query function that fetches all icons for a single calendar or calendars if
 * multiple ids are specified.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of icons that match a supplied calendar ID.
 */
export const forCalendarWithinRange =
  (state) => (calendarId, startDate, endDate) =>
    forCalendar(state)(calendarId).filter((icon) => {
      const iconDate = DateTime.fromISO(icon.startDate, { zone: "utc" })
      const start = DateTime.fromISO(startDate, { zone: "utc" })
      const end = DateTime.fromISO(endDate, { zone: "utc" })
      return (
        iconDate.diff(start, "days").days >= 0 &&
        iconDate.diff(end, "days").days <= 0
      )
    })

/**
 * Returns a query function that fetches all icons for a single snapshot.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of icons that match a supplied calendar ID.
 */
export const forSnapshot = (state) => (snapshotId) =>
  all(state).filter(
    (icon) => parseInt(icon.snapshotId, 0) === parseInt(snapshotId, 0)
  )

/**
 * Retrieves a specific icon object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching snapshot if one exists.
 */
export const find = (state) => (iconId) => {
  const entity = entities(state).icons[iconId]
  return entity && !entity.isDeleted && entity
}
