var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Icon } from "../Icon";
import { ToolTip } from "./ToolTip";
var BASE_TAB = function (args) {
    return "outline-none active:outline-none px-2 " + (args.compact ? "py-1" : "py-4") + " flex-shrink-0 relative";
};
var DEFAULT_TAB = function (args) {
    return BASE_TAB(args) + " bg-gray-200 hover:bg-gray-300";
};
var SELECTED_TAB = function (args) {
    return BASE_TAB(args) + " bg-lochivar-default hover:bg-lochivar-default";
};
export var Tab = function (_a) {
    var icon = _a.icon, selected = _a.selected, onClick = _a.onClick, grow = _a.grow, toolTip = _a.toolTip, compact = _a.compact;
    var handleClick = function (e) {
        e.preventDefault();
        onClick();
    };
    var _b = useState(false), hover = _b[0], setHover = _b[1];
    return (_jsxs("button", __assign({ style: { outlineColor: "rgba(0,0,0,0)" }, className: (selected ? SELECTED_TAB({ compact: compact }) : DEFAULT_TAB({ compact: compact })) + " " + (grow ? "flex flex-grow" : "flex"), onClick: handleClick, onMouseOver: function () { return setHover(true); }, onMouseOut: function () { return setHover(false); } }, { children: [_jsx(Icon, { mode: selected ? "negative" : "default", icon: icon }, void 0), toolTip ? (_jsx(ToolTip, { label: toolTip, direction: "below", active: hover }, void 0)) : null] }), void 0));
};
