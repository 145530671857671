import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Rect } from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
var FONT_SIZE = 12;
export var WeekDayLabel = function (_a) {
    var _b;
    var x = _a.x, y = _a.y, font = _a.font, todayFillColor = _a.todayFillColor, labelColor = _a.labelColor, dateString = _a.date, today = _a.today, additionalText = _a.additionalText, width = _a.width, limited = _a.limited;
    var _c = useState({ width: 0, height: 0 }), dateSize = _c[0], setDateSize = _c[1];
    var dateText = useRef(null);
    var date = DateTime.fromISO(dateString, { zone: "utc" }).startOf("day");
    var printableDate = date.toFormat("d");
    if (parseInt(printableDate) === 1) {
        printableDate = date.toFormat("MMM d");
    }
    useEffect(function () {
        var _a, _b, _c, _d;
        var width = (_b = (_a = dateText.current) === null || _a === void 0 ? void 0 : _a.textWidth) !== null && _b !== void 0 ? _b : 0;
        var height = (_d = (_c = dateText.current) === null || _c === void 0 ? void 0 : _c.textHeight) !== null && _d !== void 0 ? _d : 0;
        if ((dateSize.width !== width, dateSize.height !== height)) {
            setDateSize({
                width: width,
                height: height,
            });
        }
    }, [dateSize, setDateSize, dateText]);
    var rectWidth = dateSize.width + 12;
    var labelX = today ? x + 6 : x;
    var labelY = y + 3;
    var textSize = today ? rectWidth : (_b = dateSize.width) !== null && _b !== void 0 ? _b : 0;
    var additionalTextX = x + textSize + 6;
    return (_jsxs(React.Fragment, { children: [today ? (_jsx(Rect, { x: x, y: y, height: dateSize.height + 6, width: rectWidth, fill: todayFillColor, cornerRadius: 8 }, void 0)) : null, _jsx(Text, { ref: dateText, text: printableDate, x: labelX, y: labelY, fontSize: FONT_SIZE - (limited ? 4 : 0), fontFamily: font, align: "left", fill: today ? "#fff" : labelColor }, void 0), additionalText ? (_jsx(Text, { text: additionalText, x: additionalTextX, y: labelY, fontSize: FONT_SIZE - (limited ? 4 : 0), fontFamily: font, align: "left", fill: labelColor, width: (width !== null && width !== void 0 ? width : 100) - textSize }, void 0)) : null] }, void 0));
};
