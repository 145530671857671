import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { ConnectionResponse } from "./types"
import { camelizeKeys } from "humps"

export interface CreateCalendarConnectionBody {
  calendarId: number
  setKeeperCalendarId: string
  setKeeperProjectId: string
}

export const useCreateSetKeeperCalendarConnection = () => {
  const updateEntity = useUpdateEntity()
  const createConnection = useApiRequest<
    ConnectionResponse,
    CreateCalendarConnectionBody,
    RequestParams
  >({
    method: "POST",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/connections/setkeeper-calendar`,
    onResponse: (response) => {
      const connections = [response].flat()
      connections.forEach((connection) => {
        if (connection.id) {
          updateEntity("connections", connection.id, {
            ...camelizeKeys(connection),
          })
        }
      })
    },
  })
  return createConnection
}
