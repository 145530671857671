var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "../Avatar";
import { BarDropDownList } from "./BarDropDownList";
import { ToolTip } from "./ToolTip";
var BUTTON_STYLE = "flex flex-row border border-gray-200 hover:shadow rounded py-1 px-2 text-left";
var BAR_HEADER_STYLE = "flex flex-col";
var PRIMARY_HEADER_STYLE = "font-bold text-lochivar-default text-sm truncate";
var SECONDARY_HEADER_STYLE = "font-body text-rose-darkest text-xs truncate";
var ICON_STYLES = "text-lochivar-default ml-1 my-auto";
export var BarDropDown = function (_a) {
    var avatar = _a.avatar, primary = _a.primary, name = _a.name, secondary = _a.secondary, className = _a.className, children = _a.children, hideLabel = _a.hideLabel, icon = _a.icon, tooltip = _a.tooltip, toolTipAlignment = _a.toolTipAlignment, toolTipDirection = _a.toolTipDirection;
    var _b = useState(false), hover = _b[0], setHover = _b[1];
    var handleMouseOver = function (hovering) { return function () { return setHover(hovering); }; };
    var renderToggle = function (_active, handleToggle) { return (_jsxs("button", __assign({ className: BUTTON_STYLE, onClick: handleToggle, onMouseOut: handleMouseOver(false), onMouseOver: handleMouseOver(true) }, { children: [icon ? (_jsx("label", __assign({ className: "rounded-full h-8 w-8 border-2 border-lochivar-default text-lochivar-defaultflex my-auto text-sm" }, { children: _jsx(FontAwesomeIcon, { icon: icon, className: "m-auto" }, void 0) }), void 0)) : avatar ? (_jsx(Avatar, { name: primary, className: "my-auto mr-2 bg-lochivar-default", size: "sm" }, void 0)) : null, !hideLabel ? (_jsxs("header", __assign({ className: BAR_HEADER_STYLE }, { children: [_jsx("h1", __assign({ className: PRIMARY_HEADER_STYLE }, { children: primary }), void 0), secondary ? (_jsx("h2", __assign({ className: SECONDARY_HEADER_STYLE }, { children: secondary }), void 0)) : null] }), void 0)) : null, _jsx(FontAwesomeIcon, { icon: faChevronCircleDown, className: ICON_STYLES }, void 0), tooltip ? (_jsx(ToolTip, { label: tooltip, active: hover, direction: toolTipDirection, align: toolTipAlignment }, void 0)) : null] }), void 0)); };
    return (_jsx(BarDropDownList, { toggle: renderToggle, children: children, name: name, className: className }, void 0));
};
