import React from "react"
import Container from "./PlaceholderContainer.react"
import styles from "./Soon.module.css"

const Icon = require("./Soon.svg")

const Soon = () => (
  <Container>
    <img src={Icon} alt="Coming Soon" className={styles.icon} />
    <h1>Coming Soon</h1>
    <p>This feature is in development.</p>
  </Container>
)

export default Soon
