import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DRAWER_TYPE } from "./types";
import clsx from "clsx";
export var DraggableEdge = function () {
    var _a = useDrag(function () { return ({
        type: DRAWER_TYPE,
        item: {},
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }); }, []), isDragging = _a[0].isDragging, drag = _a[1], preview = _a[2];
    useEffect(function () {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);
    return (_jsx("button", { className: clsx("absolute inset-y-0 right-0 w-6 -mr-6 z-10 border-l-4 outline-none outline-0 focus:outline-none focus:outline-0", isDragging ? "border-lochivar-default" : "hover:border-sorbus-default"), ref: drag }, void 0));
};
