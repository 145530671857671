import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { SEGMENT_LENGTH } from "./constants";
var DRAGGABLE_SEGMENT_STYLE = "flex flex-grow border-2 border-transparent hover:border-lochivar-default cursor-pointer";
var DRAGGING_STYLE = "pointer-events-auto";
export var EventDraggableSegment = function (_a) {
    var type = _a.type, uuid = _a.uuid, calendarId = _a.calendarId, className = _a.className, endDrag = _a.endDrag, beginDrag = _a.beginDrag, offset = _a.offset;
    var _b = useState(false), dragging = _b[0], setDragging = _b[1];
    var _c = useDrag(function () { return ({
        type: type,
        item: function () {
            beginDrag(uuid, calendarId, offset);
            setDragging(true);
            return { uuid: uuid, calendarId: calendarId, type: type, offset: offset };
        },
        end: function () {
            endDrag(uuid, calendarId, offset);
            setDragging(false);
        },
    }); }, [uuid, calendarId, type, offset]), _ = _c[0], drag = _c[1], preview = _c[2];
    useEffect(function () {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);
    return (_jsx("div", { ref: drag, className: clsx(DRAGGABLE_SEGMENT_STYLE, dragging && DRAGGING_STYLE, className), style: {
            width: SEGMENT_LENGTH,
            flexBasis: SEGMENT_LENGTH,
        } }, void 0));
};
