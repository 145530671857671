import React, { useEffect } from "react"
import toast from "react-hot-toast"
import { setKeeperLogo } from "../appNavigator/components"
import clsx from "clsx"
import { getCookie, setCookie } from "react-simple-cookie-store"

const COOKIE = "setKeeperPrompt"

export const useSetKeeperPrompt = () => {
  const prompted = getCookie(COOKIE) === "yes"
  useEffect(() => {
    if (prompted) return
    setTimeout(() => {
      toast.custom(
        (t) => (
          <div
            className={clsx(
              t.visible ? "opacity-1" : "opacity-0 scale-90",
              "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 transition-all duration-300 ease-in-out hover:shadow-2xl transform-gpu"
            )}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex-1 relative flex flex-col">
                <img
                  className="w-44 block mx-auto"
                  src={setKeeperLogo}
                  alt=""
                />
                <p className="text-sm text-gray-700 text-center font-bold mt-3 mb-6">
                  Want to securely distribute production documents?
                </p>
                <div className="flex">
                  <a
                    className="text-sm font-medium rounded flex-1 bg-sorbus-default text-white px-4 py-2 mr-2 text-center hover:text-white hover:bg-sorbus-dark"
                    href="https://setkeeper.com"
                  >
                    Try SetKeeper!
                  </a>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      toast.dismiss(t.id)
                    }}
                    className="text-sm font-medium rounded flex-1 border-2 border-lochivar-default text-lochivar-dark px-4 py-2 hover:underline"
                  >
                    Maybe Later
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
        { duration: 9999999, position: "bottom-right" }
      )
      setCookie(COOKIE, "yes", 1)
    }, 250)
  }, [prompted])
}
