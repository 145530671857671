import { useApiRequest, RequestParams } from "./useApiRequest"

interface Group {
  id?: number
  uuid: string
  name: string
  organization_id: string
}

export interface GetGroupParams extends RequestParams {
  uuid: string
}

export const useGroup = (subdomain: string) => {
  const getGroup = useApiRequest<Group, Group, GetGroupParams>({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/:uuid`,
  })
  return getGroup
}
