import React, { Component } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import { decamelizeKeys } from "humps"
import { Button } from "ui"
import { Form, Indicator, View } from "../../shared"
import { submitValidate } from "../validators"
import { isDefined } from "../../utils/helpers"
import { InvitationPermissionWidget } from "./InvitationPermissionWidget"
import styles from "./InvitationFormView.module.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faEye,
  faPencil,
  faUser,
  faUserPlus,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons"
import { faCog } from "@fortawesome/pro-solid-svg-icons"
import toast from "react-hot-toast"

class InvitationFormView extends Component {
  submitForm(values) {
    const { postInvitation, updateInvitation } = this.props
    const { subdomain, ...invitation } = values
    const inviteAction = isDefined(invitation.id)
      ? updateInvitation
      : postInvitation
    return inviteAction(subdomain, decamelizeKeys(invitation))
      .then(submitValidate)
      .then(() => {
        const message = isDefined(invitation.id)
          ? `${invitation.name} Successfully Updated!`
          : `${invitation.name} Successfully Invited!`
        toast.success(message)
      })
  }

  componentDidUpdate(prevProps) {
    const {
      submitSucceeded,
      currentEmailAddress,
      findInvitationByEmail,
      history,
    } = this.props
    const { subdomain, invitationId } = this.props.match.params
    if (
      prevProps.submitSucceeded !== submitSucceeded &&
      submitSucceeded &&
      !isDefined(invitationId)
    ) {
      const updatedInvitation = findInvitationByEmail(currentEmailAddress)
      history.replace(
        `/org/${subdomain}/invite/${updatedInvitation.id}/settings`
      )
    }
  }

  /**
   * Determines if the invitation supplied to the form should be considered
   * new or as an exiting invitation based on the presence of an ID.
   *
   * @return {Boolean} True if the invitation is considered new.
   */
  newInvitation() {
    const { initialValues } = this.props
    return !(initialValues && isDefined(initialValues.id))
  }

  handleLock(e, id) {
    e.stopPropagation()
    this.persistPermission({
      calendarId: id,
      read: false,
      write: false,
      manage: false,
    })
  }

  handleRead(e, id) {
    e.stopPropagation()
    this.persistPermission({
      calendarId: id,
      read: true,
      write: false,
      manage: false,
    })
  }

  handleWrite(e, id) {
    e.stopPropagation()
    this.persistPermission({
      calendarId: id,
      read: true,
      write: true,
      manage: false,
    })
  }

  handleManage(e, id) {
    e.stopPropagation()
    this.persistPermission({
      calendarId: id,
      read: true,
      write: true,
      manage: true,
    })
  }

  persistPermission(permission) {
    const {
      updateInvitationPermission,
      createInvitationPermission,
      findPermission,
    } = this.props
    const { subdomain, invitationId } = this.props.match.params
    const existingPermission = findPermission(
      invitationId,
      permission.calendarId
    )
    if (existingPermission.length > 0) {
      const updatedPermission = Object.assign(
        {},
        existingPermission[0],
        permission
      )
      updateInvitationPermission(subdomain, invitationId, updatedPermission)
    } else {
      createInvitationPermission(subdomain, invitationId, permission)
    }
  }

  renderPermissions() {
    const { calendars, findPermission, findOrganization } = this.props
    const { subdomain, invitationId } = this.props.match.params
    const organization = findOrganization(subdomain)
    const permissions = calendars.map((c) => {
      const existingPermission = findPermission(invitationId, c.id)
      const noAccess =
        existingPermission.length < 1 || !existingPermission[0].read
      const readOnly = !noAccess && !existingPermission[0].write
      const readWrite =
        existingPermission.length > 0 &&
        existingPermission[0].write &&
        !existingPermission[0].manage
      const canManage =
        existingPermission.length > 0 && existingPermission[0].manage
      return (
        <Form.GridRow key={`calendar${c.id}`}>
          <Form.FieldCol>
            <div className={styles.selectGroupContainer}>
              <ul className={styles.selectGroup}>
                <li className={noAccess && styles.selectGroupActive}>
                  <View.ToolBarToolTip>
                    {({ renderToolTip, hideToolTip, showToolTip }) => (
                      <button
                        className={styles.selectButton}
                        type="button"
                        onClick={(e) => this.handleLock(e, c.id)}
                        onMouseOver={showToolTip}
                        onMouseOut={hideToolTip}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        {renderToolTip({ toolTipText: "No Access" })}
                      </button>
                    )}
                  </View.ToolBarToolTip>
                </li>
                {organization.usesGranularPermissions && (
                  <li className={readOnly && styles.selectGroupActive}>
                    <View.ToolBarToolTip>
                      {({ renderToolTip, hideToolTip, showToolTip }) => (
                        <button
                          className={styles.selectButton}
                          onClick={(e) => this.handleRead(e, c.id)}
                          type="button"
                          onMouseOver={showToolTip}
                          onMouseOut={hideToolTip}
                        >
                          <FontAwesomeIcon icon={faEye} />
                          {renderToolTip({ toolTipText: "Read Only" })}
                        </button>
                      )}
                    </View.ToolBarToolTip>
                  </li>
                )}
                <li className={readWrite && styles.selectGroupActive}>
                  <View.ToolBarToolTip>
                    {({ renderToolTip, hideToolTip, showToolTip }) => (
                      <button
                        type="button"
                        className={styles.selectButton}
                        onClick={(e) => this.handleWrite(e, c.id)}
                        onMouseOver={showToolTip}
                        onMouseOut={hideToolTip}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                        {renderToolTip({ toolTipText: "Read / Write" })}
                      </button>
                    )}
                  </View.ToolBarToolTip>
                </li>
                <li className={canManage && styles.selectGroupActive}>
                  <View.ToolBarToolTip>
                    {({ renderToolTip, hideToolTip, showToolTip }) => (
                      <button
                        type="button"
                        className={styles.selectButton}
                        onClick={(e) => this.handleManage(e, c.id)}
                        onMouseOver={showToolTip}
                        onMouseOut={hideToolTip}
                      >
                        <FontAwesomeIcon icon={faCog} />
                        {renderToolTip({ toolTipText: "Manage" })}
                      </button>
                    )}
                  </View.ToolBarToolTip>
                </li>
              </ul>
              <label className={styles.selectGroupLabel}>{c.name}</label>
            </div>
          </Form.FieldCol>
        </Form.GridRow>
      )
    })

    if (calendars.length > 0) {
      return (
        <div>
          <Form.Heading name="Access" />
          {permissions}
        </div>
      )
    } else {
      return ""
    }
  }

  render() {
    const {
      submitting,
      activeField,
      handleSubmit,
      accessLevel,
      invitationId,
      subdomain,
    } = this.props
    return (
      <form onSubmit={handleSubmit((values) => this.submitForm(values))}>
        <Form.Grid>
          <Field
            component={Form.Field}
            name="name"
            label="Name"
            type="text"
            assistantText="The name of this user."
            customizations={{
              disabled: submitting,
              placeholder: "Jane Doe",
              tabIndex: "1",
            }}
            active={activeField === "name"}
          />
          <Field
            component={Form.Field}
            name="email"
            label="Email Address"
            type="text"
            assistantText="The email address for this user."
            customizations={{
              disabled: submitting,
              placeholder: "Email Address",
              tabIndex: "1",
            }}
            active={activeField === "email"}
          />
          <Form.Heading name="Role" />
          <div className="flex flex-row">
            <Form.FieldCol>
              <Form.RadioGroup>
                <Field
                  name="accessLevel"
                  component={Form.RadioField}
                  value="user"
                  type="radio"
                  label="User"
                  icon={faUser}
                  description="Basic user access limited to ‘read only’ or ‘read/write’ existing calendars.  Cannot create or delete calendars."
                />
              </Form.RadioGroup>
            </Form.FieldCol>
            <Form.AssistantCol>
              <Form.AssistantText active={accessLevel === "user"}>
                This role is designed for individuals that have very limited
                decision making power in the overall production of the calendar.
                Access can be limited per calendar. They cannot invite other
                Users.
              </Form.AssistantText>
            </Form.AssistantCol>
          </div>
          <div className="flex flex-row">
            <Form.FieldCol>
              <Form.RadioGroup>
                <Field
                  name="accessLevel"
                  component={Form.RadioField}
                  label="Super User"
                  type="radio"
                  value="creator"
                  icon={faUserPlus}
                  description="Can create their own calendars and invite other Users to the calendars they create. Cannot delete calendars."
                />
              </Form.RadioGroup>
            </Form.FieldCol>
            <Form.AssistantCol>
              <Form.AssistantText active={accessLevel === "creator"}>
                This role is designed for individuals that need to create their
                own calendars and provide access to teams working on shows they
                oversee. Access can be limited per calendar.
              </Form.AssistantText>
            </Form.AssistantCol>
          </div>
          <div className="flex flex-row mb-4">
            <Form.FieldCol>
              <Form.RadioGroup>
                <Field
                  name="accessLevel"
                  component={Form.RadioField}
                  label="Admin"
                  type="radio"
                  value="admin"
                  icon={faUserCircle}
                  description="Can manage permissions for all Users, Super Users and calendars in the company."
                />
              </Form.RadioGroup>
            </Form.FieldCol>
            <Form.AssistantCol>
              <Form.AssistantText active={accessLevel === "admin"}>
                This role is designed for executives in the company who require
                access to all calendars and users. Admins can create and delete
                other Admins as well as view, create, edit and delete any user
                or calendar.
              </Form.AssistantText>
            </Form.AssistantCol>
          </div>

          {["user", "creator"].includes(accessLevel) && !this.newInvitation() && (
            <>
              <Form.Heading name="Permissions" />
              <Form.GridRow>
                <Form.FieldCol>
                  <InvitationPermissionWidget
                    subdomain={subdomain}
                    invitationId={invitationId}
                  />
                </Form.FieldCol>
              </Form.GridRow>
            </>
          )}

          <Form.GridRow>
            <Form.FieldCol>
              <br />
              <Button
                type="submit"
                disabled={submitting}
                className="w-full"
                align="stretch"
              >
                <div className="flex m-auto">
                  {submitting && (
                    <Indicator.Ring theme={"button"} className="my-auto" />
                  )}
                  <span className="my-auto mx-auto">
                    {this.newInvitation() ? "Send Invitation" : "Update User"}
                  </span>
                </div>
              </Button>
            </Form.FieldCol>
          </Form.GridRow>
        </Form.Grid>
      </form>
    )
  }
}

InvitationFormView.propTypes = {
  /**
   * The current subdomain for the invitation being edited.
   */
  subdomain: PropTypes.string,
  /**
   * The current id for the invitation being edited.
   */
  invitationId: PropTypes.string,

  /**
   * Injected by redux form. Indicates whether or not the form
   * is currently submitting.
   */
  submitting: PropTypes.bool,

  /**
   * An action creator injected by redux form. Allows us to manually
   * change the value of a field in the redux state.
   */
  change: PropTypes.func.isRequired,

  /**
   * The name of the current field in focus.
   */
  activeField: PropTypes.string,

  /**
   * A redux action creator mapped to dispatch which will
   * post the contents of the form to the APIs invitation#create
   * method.
   */
  postInvitation: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will
   * post the contents of the form to the APIs invitation#update
   * method.
   */
  updateInvitation: PropTypes.func.isRequired,

  /**
   * A function passed in from redux form to process the
   * form's values on submit.
   */
  handleSubmit: PropTypes.func.isRequired,

  /**
   * A redux router action mapped to dispatch which will
   * replace the current url without pushing it to HTML5
   * history.
   */
  replace: PropTypes.func.isRequired,

  /**
   * The default values to map to the form.
   */
  initialValues: PropTypes.object,

  /**
   * Parameters passed in from react router.
   */
  match: PropTypes.object,

  /**
   * This allows us to redirect the user to the edit path
   * for the newly created invitation.
   */
  findInvitationByEmail: PropTypes.func.isRequired,

  /**
   * This allows us to redirect the user to the edit path
   * for the newly created invitation.
   */
  currentEmailAddress: PropTypes.string,

  /**
   * Injected by redux form to indicate if the form was submitted.
   */
  submitSucceeded: PropTypes.bool.isRequired,

  /**
   * An array of calendar entities currently available to the organization.
   */
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * The current access level assigned to the user.
   */
  accessLevel: PropTypes.string,

  /**
   * Creates a permission for a specific invitation.
   */
  createInvitationPermission: PropTypes.func.isRequired,

  /**
   * Update a permission for a specific invitation.
   */
  updateInvitationPermission: PropTypes.func.isRequired,

  /**
   * Find a specific permission for the current invitation.
   */
  findPermission: PropTypes.func.isRequired,

  /**
   * Find a specific organization from the redux store.
   */
  findOrganization: PropTypes.func.isRequired,
}

export default InvitationFormView
