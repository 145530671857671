import { eventSelector, lengthenItem } from "../../api"
import { optimisticDateRangeUpdate } from "../../api/actions/events"

import * as types from "../actionTypes"

/**
 * Updates the calendar UI to return to it's default state upon
 * completion of an expand drag and drop interaction.
 * @param {Integer} eventUuid The uuid of the affected event.
 * @return {Object} An FSA action.
 */
export const endExpandDrag =
  (eventUuid, calendarId) => (dispatch, getState) => {
    const state = getState()
    const event = eventSelector.find(state)(eventUuid, calendarId)
    dispatch({
      type: types.END_EVENT_EXPAND,
      payload: {},
    })
    const events = eventSelector.forItem(state)(event.itemUuid, calendarId)
    dispatch(optimisticDateRangeUpdate(calendarId))
    return dispatch(
      lengthenItem(event.itemUuid, calendarId, events[0].startsAt, event.length)
    )
  }
