import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarTooltip.module.css"

class ToolBarTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = { tooltipVisible: false }
  }

  showToolTip() {
    this.setState(() => ({ tooltipVisible: true }))
  }

  hideToolTip() {
    this.setState(() => ({ tooltipVisible: false }))
  }

  styleForAlignment(toolTipAlign) {
    switch (toolTipAlign) {
      case "right":
        return styles.alignRight
      case "bottomRight":
        return styles.alignBottomRight
      default:
        return ""
    }
  }

  renderToolTip({ toolTipAlign, toolTipText, toolTipShortCut }) {
    return this.state.tooltipVisible && toolTipText ? (
      <div
        className={`${styles.tooltip} ${this.styleForAlignment(toolTipAlign)}`}
      >
        {toolTipText}
        {toolTipShortCut ? (
          <span className={styles.shortcut}>({toolTipShortCut})</span>
        ) : (
          <span />
        )}
      </div>
    ) : (
      <span />
    )
  }

  render() {
    const { tooltipVisible } = this.state
    return this.props.children({
      tooltipVisible,
      hideToolTip: this.hideToolTip.bind(this),
      showToolTip: this.showToolTip.bind(this),
      renderToolTip: this.renderToolTip.bind(this),
    })
  }
}

ToolBarTooltip.propTypes = {
  children: PropTypes.func,
}

export default ToolBarTooltip
