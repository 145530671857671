var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useEventListener, useSharedRef } from "../hooks";
import { SizeContext } from "./Window";
import { throttle } from "lodash";
import clsx from "clsx";
var INDEX_OFFSET = 2; // Amount of adjacent cells to render offscreen.
var SCROLL_SIZE = 999999;
var SCROLL_SIZE_STYLES = "absolute top-0 left-0 w-full pointer-events-none";
var SCROLL_TIMEOUT = 1500;
var CONTENT_SCROLL_STYLES = "flex flex-grow relative";
var SCROLL_STYLES = "overflow-auto";
var HIDDEN_STYLES = "overflow-hidden";
var ABSOLUTE_CONTAINER_STYLES = "absolute flex";
export var ScrollTileView = React.forwardRef(function (_a, forwardedRef) {
    var _b, _c, _d, _e;
    var _f = _a.anchorValue, anchorValue = _f === void 0 ? "0" : _f, cellSize = _a.cellSize, _g = _a.defaultPosition, defaultPosition = _g === void 0 ? "center" : _g, _h = _a.scrollDirection, scrollDirection = _h === void 0 ? "vertical" : _h, tileCount = _a.tileCount, children = _a.children, style = _a.style, _j = _a.tileStyle, tileStyle = _j === void 0 ? {} : _j, sizeOfCellAtIndex = _a.sizeOfCellAtIndex, positionOfCellAtIndex = _a.positionOfCellAtIndex, indexAtDistance = _a.indexAtDistance, scrollable = _a.scrollable, _k = _a.throttle, throttleTime = _k === void 0 ? 250 : _k, _l = _a.preRenderCount, preRenderCount = _l === void 0 ? INDEX_OFFSET : _l;
    var size = useContext(SizeContext).size;
    var _m = useState(null), storedAnchor = _m[0], setStoredAnchor = _m[1];
    var _o = useState(false), scrolling = _o[0], setScrolling = _o[1];
    var _p = useState(0), index = _p[0], setIndex = _p[1];
    var _q = useState(undefined), endScrollTimeout = _q[0], setEndTimeout = _q[1];
    var _r = useState({
        viewport: { height: 0, width: 0 },
        screen: { height: 0, width: 0 },
        cell: cellSize,
    }), sizing = _r[0], setSizing = _r[1];
    useEffect(function () {
        var _a, _b;
        var cell = {
            width: (_a = cellSize === null || cellSize === void 0 ? void 0 : cellSize.width) !== null && _a !== void 0 ? _a : 0,
            height: (_b = cellSize === null || cellSize === void 0 ? void 0 : cellSize.height) !== null && _b !== void 0 ? _b : 0,
        };
        setSizing(function (prevSizing) { return (__assign(__assign({}, prevSizing), { cell: cell })); });
    }, [cellSize === null || cellSize === void 0 ? void 0 : cellSize.width, cellSize === null || cellSize === void 0 ? void 0 : cellSize.height]);
    var tileSize = scrollDirection === "vertical"
        ? (_c = (_b = sizing.cell) === null || _b === void 0 ? void 0 : _b.height) !== null && _c !== void 0 ? _c : 0
        : (_e = (_d = sizing.cell) === null || _d === void 0 ? void 0 : _d.width) !== null && _e !== void 0 ? _e : 0;
    var scrollSize = tileCount ? tileCount * tileSize : SCROLL_SIZE;
    var origin;
    switch (defaultPosition) {
        case "start":
            origin = 0;
            break;
        case "center":
            origin = Math.ceil(scrollSize / tileSize / 2) * tileSize;
            break;
        case "end":
            origin = SCROLL_SIZE - sizing.viewport.width;
            break;
    }
    var screenSize = scrollDirection === "vertical"
        ? sizing.viewport.height
        : sizing.viewport.width;
    var tilesVisible = Math.ceil(screenSize / tileSize) || 1;
    var useOffset = defaultPosition === "center";
    var visibleTileCount = useOffset
        ? tilesVisible + (useOffset ? preRenderCount : 0) * 2
        : tilesVisible;
    var start = origin + tileSize * index;
    var contentScroll = useSharedRef(null, [
        forwardedRef,
    ]);
    var scrollTo = function (position) {
        if (!contentScroll.current) {
            return;
        }
        if (scrollDirection === "horizontal") {
            contentScroll.current.scroll(position, contentScroll.current.scrollTop);
        }
        else {
            contentScroll.current.scroll(contentScroll.current.scrollLeft, position);
        }
    };
    // Update the visible size dimensions in local state on window resize.
    useEffect(function () {
        if (!contentScroll.current) {
            return;
        }
        setSizing(__assign(__assign({}, sizing), { screen: size, viewport: {
                height: contentScroll.current.offsetHeight,
                width: contentScroll.current.offsetWidth,
            } }));
    }, [size, contentScroll.current]);
    // Scroll to the target index has changed.
    var scrollToIndex = useMemo(function () { return function (newIndex) {
        var newPosition = positionOfCellAtIndex
            ? positionOfCellAtIndex(newIndex, origin)
            : origin + newIndex * tileSize;
        scrollTo(newPosition);
    }; }, [origin, tileSize, scrollTo, positionOfCellAtIndex]);
    useEffect(function () {
        if (anchorValue !== storedAnchor) {
            setStoredAnchor(anchorValue);
            setIndex(0);
            scrollToIndex(0);
        }
    }, [anchorValue, setStoredAnchor, setIndex, scrollToIndex]);
    var handleScroll = useMemo(function () {
        return throttle(function () {
            if (!contentScroll.current) {
                return;
            }
            var dist = origin -
                (scrollDirection === "horizontal"
                    ? contentScroll.current.scrollLeft
                    : contentScroll.current.scrollTop);
            var scrollIndex = indexAtDistance
                ? indexAtDistance(dist)
                : -Math.ceil(dist / tileSize);
            if (index !== scrollIndex) {
                var newIndex = Math.max(tileCount ? 0 : scrollIndex, Math.min(scrollIndex, tileCount !== null && tileCount !== void 0 ? tileCount : scrollIndex));
                setIndex(newIndex);
            }
            if (endScrollTimeout) {
                clearTimeout(endScrollTimeout);
            }
            var timeout = setTimeout(function () { }, SCROLL_TIMEOUT);
            setEndTimeout(timeout);
            if (!scrolling) {
                setScrolling(true);
            }
        }, throttleTime, {
            leading: false,
            trailing: true,
        });
    }, [
        origin,
        tileSize,
        setIndex,
        index,
        scrolling,
        endScrollTimeout,
        setEndTimeout,
        sizeOfCellAtIndex,
    ]);
    useEventListener({
        type: "scroll",
        listener: handleScroll,
        element: contentScroll,
    });
    return (_jsxs("div", __assign({ className: clsx(scrollable ? SCROLL_STYLES : HIDDEN_STYLES, CONTENT_SCROLL_STYLES), ref: contentScroll, style: style }, { children: [new Array(visibleTileCount).fill("").map(function (_, i) {
                var calculatedIndex = index - (useOffset ? preRenderCount : 0) + i;
                var tileIndex = tileCount
                    ? Math.min(Math.max(0, calculatedIndex), tileCount)
                    : calculatedIndex;
                var tilePos = positionOfCellAtIndex
                    ? positionOfCellAtIndex(tileIndex, origin)
                    : start + i * tileSize;
                var actualTileSize = sizeOfCellAtIndex
                    ? sizeOfCellAtIndex(tileIndex)
                    : tileSize;
                var position = isNaN(tilePos) ? 0 : tilePos;
                if (tileCount && calculatedIndex > tileCount - 1) {
                    return null;
                }
                return (_jsx("div", __assign({ className: clsx(ABSOLUTE_CONTAINER_STYLES, scrollDirection === "horizontal" ? "inset-y-0" : "inset-x-0"), style: __assign({ left: scrollDirection === "horizontal" ? position : 0, top: scrollDirection === "vertical" ? position : 0, height: scrollDirection === "vertical" ? actualTileSize : undefined, width: scrollDirection === "horizontal" ? actualTileSize : undefined }, tileStyle) }, { children: children({
                        tileSize: actualTileSize,
                        position: position,
                        index: tileIndex,
                        scrollDirection: scrollDirection,
                    }) }), "tile-" + i));
            }), _jsx("div", { className: SCROLL_SIZE_STYLES, style: {
                    height: scrollDirection === "vertical" ? scrollSize : 1,
                    width: scrollDirection === "horizontal" ? scrollSize : 1,
                } }, void 0)] }), void 0));
});
ScrollTileView.displayName = "ScrollTileView";
