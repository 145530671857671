import { eventSelector } from "../../api"
import * as types from "../actionTypes"

/**
 * Updates the drag UI on the calendar when the user initiates a drag
 * and drop action of an event.
 * @param {Integer} eventUuid The id of the affected event.
 * @return {Object} An FSA action.
 */
export const beginEventDrag =
  (eventUuid, calendarId, dragOffset) => (dispatch, getState) => {
    const { startsAt, position } = eventSelector.find(getState())(
      eventUuid,
      calendarId
    )
    dispatch({
      type: types.BEGIN_DRAG,
      payload: {
        calendarId,
        showDragUI: true,
        eventUuid,
        startsAt,
        position,
        dragOffset,
      },
    })
  }
