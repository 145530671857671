import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import randomColor from "randomcolor"
import { activeField } from "../selectors"
import CalendarDetailView from "../components/CalendarDetailView"
import { FORM_NAME } from "../constants"
import {
  authSelector,
  createCalendar,
  requestCalendar,
  requestCurrentUser,
  requestOrganization,
  requestSnapshot,
  requestInvitation,
  requestHolidaySchedule,
  calendarSelector,
  holidaySelector,
  updateCalendar,
  removeCalendar,
  organizationSelector,
  snapshotSelector,
} from "../../api"

const defaultCalendar = {
  name: "",
  interruptedByHolidays: false,
  interruptedByWeekends: false,
  includeEvents: true,
  weekendColor: "#ebe8ea",
  holidayColor: "#ebe8ea",
  cutoffColor: "#cfc7cd",
  color: randomColor({ luminosity: "bright" }),
  showWeekNumbers: "none",
}

const formValues = formValueSelector(FORM_NAME)

export default connect(
  (state) => ({
    activeField: activeField(state),
    currentUser: authSelector.currentUser(state),
    initialValues: defaultCalendar,
    findCalendar: calendarSelector.find(state),
    findCalendarsForOrg: calendarSelector.forOrganization(state),
    findSnapshotsForCalendar: snapshotSelector.forCalendar(state),
    findOrganization: organizationSelector.find(state),
    templateCalendarId: formValues(state, "templateCalendarId"),
    currentGroupName: formValues(state, "group"),
    startDate: formValues(state, "startDate"),
    endDate: formValues(state, "endDate"),
    holidaySchedules: holidaySelector.allHolidaySchedules(state),
  }),
  {
    postCalendar: createCalendar,
    loadCalendar: requestCalendar,
    requestCurrentUser: requestCurrentUser,
    loadSnapshots: requestSnapshot,
    loadInvitations: requestInvitation,
    loadOrganization: requestOrganization,
    loadHolidaySchedules: requestHolidaySchedule,
    updateCalendar,
    removeCalendar,
  }
)(CalendarDetailView)
