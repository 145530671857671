import { entities } from "./baseSelector"

/**
 * Returns all groups entities in the cache that have not been
 * deleted.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of group entities.
 */
export const all = (state) =>
  Object.keys(entities(state).groups)
    .map((key) => entities(state).groups[key])
    .filter((entity) => !entity.isDeleted)
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

/**
 * Retrieves a specific group object from the redux store by ID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching group if one exists.
 */
export const find = (state) => (groupId) => {
  const entity = entities(state).groups[groupId]
  return entity && !entity.isDeleted && entity
}

/**
 * Sorts group objects by their position attribute
 * @param {Object} a First group to compare
 * @param {Object} b Second group to compare
 * @returns {Number} A value of -1, 0, or 1
 */
const sortByPosition = (a, b) =>
  a.position > b.position ? 1 : a.position < b.position ? -1 : 0

/**
 * Returns a query function that fetches all groups for a single organization.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of groups that match a supplied org ID.
 */
export const forOrganization = (state) => (organization) =>
  all(state)
    .filter((group) => group.organization === organization)
    .sort(sortByPosition)
