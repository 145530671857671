import React from "react"
import PropTypes from "prop-types"
import gridStyles from "../../utils/gridStyles"

const gridAttributes = "col-md-offset-1 col-xs-12 col-sm-5 col-md-4"

const AssistantCol = ({ children }) => (
  <div className={gridStyles(gridAttributes)}>{children}</div>
)

AssistantCol.propTypes = {
  children: PropTypes.node,
}

export default AssistantCol
