import React from "react"
import PropTypes from "prop-types"
import styles from "./Header.module.css"

const Header = ({ children, draggable, ...props }) => (
  <div
    className={`modal-header ${styles.header} ${
      draggable ? styles.draggableHeader : ""
    }`}
    {...props}
  >
    {children}
  </div>
)

Header.propTypes = {
  children: PropTypes.node.isRequired,
  draggable: PropTypes.bool,
}

export default Header
