import { useApiRequest, RequestParams } from "./useApiRequest"

interface Group {
  id?: number
  uuid: string
  name: string
  organization_id: string
  position: number
}

export interface UpdateGroupParentBody {
  group_uuid: string
}

export interface UpdateGroupParentParams extends RequestParams {
  uuid: string
}

export const useUpdateGroupParent = (subdomain: string) => {
  const getUpload = useApiRequest<
    Group,
    UpdateGroupParentBody,
    UpdateGroupParentParams
  >({
    method: "PATCH",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/groups/:uuid/parent`,
  })
  return getUpload
}
