var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "../Avatar";
import clsx from "clsx";
var AVATAR_STYLES = "bg-lochivar-default mr-2";
var DROP_DOWN_ITEM = "flex flex-row p-0";
var DROP_DOWN_ITEM_BTN = function (args) {
    return "flex " + (args.selected ? "bg-gray-300" : "bg-transparent") + " hover:bg-gray-200 font-md px-2 rounded text-sm w-full text-left";
};
var ITEM_LABEL = "font-semibold truncate";
var ITEM_LABEL_INFORMATIONAL = "font-light truncate text-xs";
var ITEM_ICON = "my-auto mr-2 text-lochivar-default flex-shrink";
var ITEM_SECONDARY_LABEL = "text-xs text-gray-600 flex-shrink truncate";
export var DropDownItem = function (_a) {
    var item = _a.item, selected = _a.selected, onMouseOver = _a.onMouseOver, onClick = _a.onClick, avatar = _a.avatar, hideLabels = _a.hideLabels, oversized = _a.oversized;
    return (_jsx("li", __assign({ className: DROP_DOWN_ITEM, onMouseOver: onMouseOver }, { children: _jsxs("button", __assign({ className: clsx(DROP_DOWN_ITEM_BTN({ selected: selected }), oversized ? "py-2" : "py-1"), onClick: onClick }, { children: [avatar ? (_jsx(Avatar, { name: item.label, size: "sm", className: AVATAR_STYLES, color: item.avatarColor }, void 0)) : null, item.icon ? (_jsx(FontAwesomeIcon, { icon: item.icon, className: clsx(ITEM_ICON, oversized ? "text-xl" : "text-md") }, void 0)) : null, !hideLabels ? (_jsxs("h4", __assign({ className: "my-auto flex flex-col" }, { children: [_jsx("span", __assign({ className: item.informational ? ITEM_LABEL_INFORMATIONAL : ITEM_LABEL }, { children: item.label }), void 0), item.secondaryLabel ? (_jsxs("span", __assign({ className: ITEM_SECONDARY_LABEL }, { children: ["(", item.secondaryLabel, ")"] }), void 0)) : null] }), void 0)) : null] }), void 0) }), void 0));
};
