import { createSelector, createSelectorCreator, defaultMemoize } from "reselect"
import values from "lodash/values"
import isEqual from "lodash/isEqual"
import flatten from "lodash/flatten"
import { entities } from "./baseSelector"
import moment from "moment"
import * as itemSelector from "./itemSelector"

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

/**
 * Converts a string into a UTC timestamp.
 *
 * @param {String}    start A string representing the date of the start of an event.
 * @param {String}    end   A string representing the date of the end of an event.
 * @return {Integer}        The length of the event in days.
 */
const makeLength = (start, end) =>
  moment.utc(end).diff(moment.utc(start), "days")

/**
 * Converts a string into a UTC timestamp. This method resets the time info so that
 * only the dates are compared.
 *
 * @param {String}    start A string representing the date of the start of an event.
 * @param {String}    end   A string representing the date of the end of an event.
 * @return {Integer}        The length of the event in days.
 */
const makeLengthFromStart = (start, end) =>
  makeLength(moment.utc(end).startOf("day"), moment.utc(start).startOf("day"))

/**
 * Filters a set of events that occur between a start and end date. This method
 * is inclusive so the event may only begin or end within the specified range.
 *
 * @param {Array}     events  An array of event entities to filter.
 * @param {String}    start   A string representing the date of the start of an event.
 * @param {String}    end     A string representing the date of the end of an event.
 * @return {Integer}          The length of the event in days.
 */
const filterForDateRange = (events, start, end) => {
  const matches = events.filter((event) => {
    return (
      moment.utc(start).isSameOrBefore(event.endsAt) &&
      moment.utc(end).isSameOrAfter(event.startsAt)
    )
  })
  return matches
}

/**
 * Sorts a series of events in the following order:
 * position ASC, startAt ASC, length DESC
 *
 * @param {Array}     events  An array of event entities to filter.
 * @return {Array}            A sorted copy of the original array.
 */
const sortedEvents = (events) =>
  events.sort((a, b) => {
    if (a.position !== b.position) {
      return a.position > b.position ? 1 : -1
    }
    const timeDifference = makeLength(b.startsAt, a.startsAt)
    if (timeDifference !== 0) {
      return timeDifference
    }
    return (
      makeLengthFromStart(a.endsAt, a.startsAt) -
      makeLengthFromStart(b.endsAt, b.startsAt)
    )
  })

/**
 * Determines if a collision occurs between the start and end dates of
 * two different events.
 *
 * @param {Object}    event     The first event to compare.
 * @param {Object}    prevEvent The second event to compare.
 * @return {Boolean}            True if there is a date collision between the supplied events.
 */
export const hasConflict = (event, prevEvent) => {
  const occursBefore =
    makeLengthFromStart(event.startsAt, prevEvent.endsAt) > 0 &&
    makeLengthFromStart(prevEvent.endsAt, event.startsAt) < 0
  const occursAfter =
    makeLengthFromStart(event.endsAt, prevEvent.startsAt) < 0 &&
    makeLengthFromStart(prevEvent.startsAt, event.endsAt) > 0
  return !(occursBefore || occursAfter)
}

/**
 * Takes a multidimensional array of events and filters out any row that
 * already has an event with conflicting dates.
 *
 * @param {Array}    rows           A multidimensional array of event entities.
 * @param {Object}   currentEvent   The event to test for conflicting dates.
 * @return {Array}                  A copy of the rows array excluding any rows that conflicted with the currentEvent.
 */
const getAvailableRows = (rows, currentEvent) =>
  rows.filter(
    (row) =>
      row.filter((event) => hasConflict(event, currentEvent)).length === 0
  )

/**
 * Takes a multidimensional array of events and filters out any row that
 * already has an event with a conflicting position.
 *
 * @param {Array}    rows           A multidimensional array of event entities.
 * @param {Object}   currentEvent   The event to test for conflicting positions.
 * @return {Array}                  A copy of the rows array excluding any rows that conflicted with the currentEvent.
 */
const getConflictingRowsForPosition = (rows, currentEvent) =>
  rows.filter(
    (row) =>
      row.filter(
        (event) =>
          hasConflict(event, currentEvent) &&
          event.position < currentEvent.position
      ).length > 0
  )

/**
 * Compares a set of conflicting and available rows against an original set to find the
 * best available row. This means the lowest possible index the event could be inserted
 * at in a set of rows.
 *
 * @param {Array}    rows             A multidimensional array of event entities.
 * @param {Array}    conflictingRows  A multidimensional array of event entities that conflicted with a tested event.
 * @param {Array}    availableRows    A multidimensional array of event entities that did not conflict with a tested event.
 * @return {Array}                    A multidimensional array of event entities that could be considered for insertion of an event.
 */
const getBestAvailableRowsForPosition = (
  rows,
  conflictingRows,
  availableRows
) => {
  const conflictingIndixes = conflictingRows.map((row) => rows.indexOf(row))
  const lowestPossibleIndex = conflictingIndixes[conflictingIndixes.length - 1]
  return availableRows.filter((row) => rows.indexOf(row) > lowestPossibleIndex)
}

/**
 * Groups a series of events into a multidimensional array of 'rows' of events
 * that do not conflict by date or position. This is used to render 'stacks' of
 * rows in the UI.
 *
 * @param {Array}    events           An array of event entities.
 * @return {Array}                    A multidimensional array of event entities.
 */
const mappedToRows = (events) =>
  events.reduce((rows, currentEvent) => {
    const availableRows = getAvailableRows(rows, currentEvent)
    if (availableRows.length < 1) {
      rows.push([currentEvent])
      return rows
    }
    const conflictingRows = getConflictingRowsForPosition(rows, currentEvent)
    if (conflictingRows.length < 1) {
      availableRows[0].push(currentEvent)
      return rows
    }
    const bestAvailableRows = getBestAvailableRowsForPosition(
      rows,
      conflictingRows,
      availableRows
    )
    if (bestAvailableRows.length < 1) {
      rows.push([currentEvent])
      return rows
    }
    bestAvailableRows[0].push(currentEvent)
    return rows
  }, [])

/**
 * Groups a series of events into a multidimensional array of 'partitions' of events
 * This is used to render three segments of rows in the UI.
 *
 * @param {Array}    events           An array of event entities.
 * @param {Array}    inactiveIds      An array of event Ids to ignore.
 * @return {Array}                    A multidimensional array of event entities segmented by partition.
 */
const mappedToPartitions = (events, inactiveIds) => {
  return events
    .filter((event) => !inactiveIds.includes(event.uuid))
    .reduce(
      (rows, currentEvent) => {
        rows[currentEvent.partition || 0].push(currentEvent)
        return rows
      },
      [[], [], []]
    )
}

const allEvents = (state) => entities(state).events

/**
 * Returns all events entities in the cache that have not been
 * deleted.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of events entities.
 */
export const all = createSelector(allEvents, (events) =>
  Object.keys(events)
    .map((key) => events[key])
    .sort((a, b) => (b.name < a.name ? 1 : b.name > a.name ? -1 : 0))
    .filter((entity) => !entity.isDeleted)
)

/**
 * Retrieves a specific event object from the redux store by UUID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns a matching event if one exists.
 */
export const find = (state) => (eventUuid, calendarId) => {
  const hashId = [eventUuid, calendarId].filter((i) => i).join("#")
  const entity = entities(state).events[hashId]
  return entity && !entity.isDeleted && entity
}

/**
 * Retrieves any specific events from the redux store by their associated item UUID.
 * @param  {Object}       state The current state of the redux store.
 * @return {Function}     A function that returns any matching events if they exists.
 */
export const forItem = (state) => (itemUuid, calendarId) =>
  values(entities(state).events)
    .filter(
      (e) =>
        e.itemUuid === itemUuid &&
        parseInt(e.calendarId, 0) === parseInt(calendarId, 0)
    )
    .filter((e) => !e.isDeleted)
    .sort((a, b) => {
      return b.startsAt < a.startsAt ? 1 : b.startsAt > a.startsAt ? -1 : 0
    })

/**
 * Returns a query function that fetches all events for a single calendar.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of events that match a supplied calendar ID.
 */
export const forCalendar = (state) => (calendarId) =>
  all(state).filter((event) =>
    flatten([calendarId])
      .map((id) => parseInt(id, 0))
      .includes(parseInt(event.calendarId, 0))
  )

/**
 * Returns a query function that fetches all events for a single snapshot.
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that returns an array of events that match a supplied snapshot ID.
 */
export const forSnapshot = (state) => (snapshotId) => {
  const matches = all(state).filter(
    (event) => parseInt(event.snapshotId, 0) === parseInt(snapshotId, 0)
  )
  return matches
}

/**
 * Returns a query function that fetches all events that have position
 * indexes that fall between a supplied range. This is used when updating
 * the sort order of events which is common during drag and drop interactions.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that queries for events overlapping a range of position indexes.
 */
export const positionedBetween =
  (state) => (calendarId, fromPosition, toPosition) => {
    const movingDown = fromPosition < toPosition
    return forCalendar(state)(calendarId).filter((event) => {
      const eventPosition = parseInt(event.position, 0)
      const lowValue = movingDown ? fromPosition : toPosition
      const highValue = movingDown ? toPosition : fromPosition
      return eventPosition >= lowValue && eventPosition <= highValue
    })
  }

/**
 * A convenience method for extracting the 'start' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Object}       The start moment assigned in props.
 */
const getStart = (_, props) => props.start

/**
 * A convenience method for extracting the 'end' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Object}       The end moment assigned in props.
 */
const getEnd = (_, props) => props.end

/**
 * A convenience method for extracting the 'calendarId' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Array}        The calendarIds assigned in props.
 */
const getCalendarIds = (_, props) => props.calendarIds

/**
 * A convenience method for extracting the 'calendarId' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Integer}      The snapshotId assigned in props.
 */
const getSnapshotId = (_, props) => props.snapshotId

/**
 * A convenience method for extracting the 'specialEvents' value from
 * props for reselect memoization.
 * @param  {Object} _     The state passed to the selector.
 * @param  {Object} props The props passes to the selector.
 * @return {Integer}      The snapshotId assigned in props.
 */
const getSpecialEvents = (_, props) => props.specialEvents || []

/**
 * Returns a memoized selector that returns a filtered set of
 * events for a specific calendar.
 * @type {Function}
 */
const getForCalendar = createDeepEqualSelector(
  [all, getCalendarIds, getSnapshotId, getSpecialEvents],
  (events, calendarIds, snapshotId, specialEvents) => {
    let filterBlock = (e) => calendarIds.includes(parseInt(e.calendarId, 0))
    if (snapshotId) {
      filterBlock = (e) => parseInt(e.snapshotId, 0) === parseInt(snapshotId, 0)
    }
    return [...events.filter(filterBlock), ...specialEvents]
  }
)

/* eslint-disable no-confusing-arrow */
export const calendarMaxPosition = createDeepEqualSelector(
  [getForCalendar],
  (events) => {
    const bestPositionedEvent = events.sort((a, b) =>
      a.position < b.position ? 1 : -1
    )[0]
    return (bestPositionedEvent && bestPositionedEvent.position) || 0
  }
)
/* eslint-enable no-confusing-arrow */

/**
 * Returns a memoized selector that returns the earliest visible event
 * for a specific calendar.
 * @type {Function}
 */
/* eslint-disable no-confusing-arrow */
export const findEarliestEventForCalendar = (state) => (calendarId) => {
  const events = forCalendar(state)(calendarId)
  const earliestEvent = events
    .filter((e) => e.calendarId === calendarId)
    .sort((a, b) =>
      moment.utc(a.startsAt).isBefore(b.startsAt)
        ? -1
        : moment.utc(a.startsAt).isAfter(b.startsAt)
        ? 1
        : 0
    )[0]
  return earliestEvent
}
/* eslint-enable no-confusing-arrow */

/**
 * Returns a memoized selector that returns the earliest visible event
 * for a specific calendar.
 * @type {Function}
 */
/* eslint-disable no-confusing-arrow */
export const findLatestEventForCalendar = (state) => (calendarId) => {
  const events = forCalendar(state)(calendarId)
  const latestEvent = events
    .filter((e) => e.calendarId === calendarId)
    .sort((a, b) =>
      moment.utc(a.endsAt).isBefore(b.endsAt)
        ? 1
        : moment.utc(a.endsAt).isAfter(b.endsAt)
        ? -1
        : 0
    )[0]
  return latestEvent
}
/* eslint-enable no-confusing-arrow */

const filterInactiveEventIds = (inactiveItems, events) => {
  return events
    .filter((event) => inactiveItems.includes(event.itemUuid))
    .map((event) => event.uuid)
}

export const inactiveEventIds = createSelector(
  itemSelector.inactiveItemUuids,
  getForCalendar,
  filterInactiveEventIds
)

/**
 * Returns a factory that generates a memoized selector to filter a set
 * of events down to those that fall within a start and end boundary.
 * @return {Function} A memoized selector function.
 */
export const makeForDateRange = () => {
  return createDeepEqualSelector(
    [getStart, getEnd, getForCalendar],
    (start, end, events) => {
      return filterForDateRange(events, start, end)
    }
  )
}

/**
 * Returns a factory that generates a memoized selector to sort events
 * in an appopriate manner for the calendar to render them.
 * @return {function} A memoized selector function.
 */
export const makeSortedEventsInRange = () => {
  const getEventsInRange = makeForDateRange()
  return createDeepEqualSelector([getEventsInRange], (events) => {
    return sortedEvents(events)
  })
}

/**
 * Returns a factory that generates a memoized selector to sort events
 * in an appopriate manner for the calendar to render them.
 * @return {function} A memoized selector function.
 */
export const makeMappedToPartitions = () => {
  const getEventsInRange = makeSortedEventsInRange()
  return createDeepEqualSelector(
    getEventsInRange,
    inactiveEventIds,
    mappedToPartitions
  )
}

/**
 * Maps an array of event objects into non-conflicting rows.
 *
 * @param  {Array}  partitions    All relevant events segmented into individual partitions.
 * @return {Array}                An array of events mapped to rows.
 */
export const mapToRows = (partitions) => {
  return partitions.map((events) =>
    mappedToRows(events).map((row) =>
      row.sort((a, b) => {
        return b.startsAt < a.startsAt ? 1 : b.startsAt > a.startsAt ? -1 : 0
      })
    )
  )
}

/**
 * Returns a query function that fetches all events during a date range
 * and returns the event in non-conflicitng rows that can be rendered by
 * the calendar UI.
 *
 * @param  {Object} state The current state of the redux store.
 * @return {Function}     A function that queries for events overlapping a date range and grouped into non-conflicitng rows.
 */
export const makeGetRowsForCalendarDuringPeriod = () => {
  const partitions = makeMappedToPartitions()
  return createDeepEqualSelector(partitions, mapToRows)
}
