import { useEffect, useRef, useCallback } from "react";
import { getElement } from "utils";
export var useEventListener = function (_a) {
    var type = _a.type, listener = _a.listener, _b = _a.element, element = _b === void 0 ? window : _b, options = _a.options;
    var savedListener = useRef(null);
    useEffect(function () {
        savedListener.current = listener;
    }, [listener]);
    var handleEventListener = useCallback(function (event) {
        var listener = savedListener.current;
        listener === null || listener === void 0 ? void 0 : listener(event);
    }, []);
    useEffect(function () {
        var target = getElement(element);
        target === null || target === void 0 ? void 0 : target.addEventListener(type, handleEventListener);
        return function () { return target === null || target === void 0 ? void 0 : target.removeEventListener(type, handleEventListener); };
    }, [type, element, options, handleEventListener]);
};
