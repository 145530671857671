import { decamelizeKeys } from "humps"
import { useState } from "react"
import { useAccessToken } from "./useAccessToken"
import queryString from "query-string"

export const V1_CONTENT_TYPE = {
  Accept: "application/vnd.film_cal-v1+json",
  "Content-Type": "application/json",
}

export interface ApiRequest<Body, Params, Response> {
  method: "GET" | "DELETE" | "OPTIONS" | "PATCH" | "POST" | "PUT"
  url: string
  version?: "V1" | "V2"
  body?: (variables: Body) => Object
  headers?: Object
  onResponse?: (
    response: Response,
    config?: { params?: Params; body?: Body }
  ) => void
  beforeRequest?: (params?: Params) => void
}

export interface ApiResult<ResponseType> {
  success: boolean
  loading: boolean
  data: ResponseType | null
}

export interface RequestParams {
  [string: string]: string | undefined
}

export interface ApiRequestConfig<
  RequestType,
  ParameterType extends RequestParams
> {
  body?: RequestType
  params?: ParameterType
}

export const useApiRequest = <
  ResponseType,
  RequestType,
  ParameterType extends RequestParams = {}
>({
  url,
  method,
  onResponse,
  beforeRequest,
  version = "V1",
  body,
  headers,
}: ApiRequest<RequestType, ParameterType, ResponseType>): [
  (
    config?: ApiRequestConfig<RequestType, ParameterType>
  ) => Promise<ResponseType | null>,
  ApiResult<ResponseType>
] => {
  const isLegacy = version === "V1"
  const getToken = useAccessToken()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ResponseType | null>(null)

  const request = async (
    config?: ApiRequestConfig<RequestType, ParameterType>
  ) => {
    setLoading(true)
    try {
      beforeRequest?.(config?.params)
      const accessToken = await getToken()
      const transportBody = ["GET", "DELETE"].includes(method)
        ? undefined
        : config?.body ?? body
      const finalUrl = config?.params
        ? Object.keys(config?.params).reduce(
            (p, key) => p.replace(`:${key}`, config?.params?.[key] ?? ""),
            url
          )
        : url
      const query =
        ["GET", "DELETE"].includes(method) && config?.body
          ? `?${queryString.stringify(decamelizeKeys(config?.body ?? {}))}`
          : ""

      const response = await fetch(`${finalUrl}${query}`, {
        method,
        body: transportBody
          ? JSON.stringify(
              isLegacy ? decamelizeKeys(transportBody as Object) : transportBody
            )
          : undefined,
        headers: {
          ...(headers as any),
          ...(isLegacy ? V1_CONTENT_TYPE : {}),
          Authorization: `bearer ${accessToken}`,
        },
      })
      const json = method === "DELETE" ? {} : await response.json()
      onResponse?.(json as ResponseType, {
        params: config?.params,
        body: config?.body,
      })
      setSuccess(json?.success)
      setLoading(false)
      setData(json)
      return json as ResponseType
    } catch (e: any) {
      console.error("Request failed:", e.message)
      setSuccess(false)
      setLoading(false)
      return null
    }
  }

  return [request, { success, loading, data }]
}
