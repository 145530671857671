var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import { useKeyPress } from "../../hooks";
import { DropDownItem } from "./DropdownItem";
import { IconButton } from "../Navigation";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
/**
 * Should be assigned to an optional additional item in order to detect
 * upon selection.
 */
export var DROPDOWN_ADDITIONAL_ITEM_ID = "DropDown/Item/AdditionalItemId";
/**
 * Should be assigned to an optional blank item in order to detect upon
 * selection.
 */
export var DROPDOWN_BLANK_ITEM_ID = "DropDown/Item/BlankItemId";
/**
 * Should be assigned to an optional item indicatign more results in order
 * to detect upon selection.
 */
export var DROPDOWN_MORE_RESULTS_ITEM_ID = "DropDown/Item/MoreResultsItemId";
var DROP_DOWN_LIST = "flex flex-col absolute rounded bg-gray-100 p-0 border w-full top-100 mt-2 transition duration-500 ease-in-out shadow-md hover:shadow-lg hover:mt-1 hover:border-gray-400 z-10";
var DISMISS_STYLES = "absolute border-gray-200 border right-0 top-0 -mt-4 -mr-4 px-4 bg-white hover:bg-gray-100 text-sorbus-default shadow-sm";
export var DropDownList = function (_a) {
    var blankResultItem = _a.blankResultItem, additionalItem = _a.additionalItem, maxResults = _a.maxResults, items = _a.items, onSelectItem = _a.onSelectItem, onDismiss = _a.onDismiss;
    var _b = useState(null), lastKey = _b[0], setLastKey = _b[1];
    var _c = useState(0), currentIndex = _c[0], setCurrentIndex = _c[1];
    var pressedDown = useKeyPress("ArrowDown");
    var pressedUp = useKeyPress("ArrowUp");
    var pressedEnter = useKeyPress("Enter");
    var handleSelectionForItem = useCallback(function (item) { return function (e) {
        e.preventDefault();
        if (!lastKey) {
            onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item);
        }
    }; }, [onSelectItem, lastKey]);
    useEffect(function () {
        if (pressedUp && lastKey !== "ArrowUp") {
            setLastKey("ArrowUp");
            setCurrentIndex(Math.max(0, currentIndex - 1));
        }
        else if (pressedDown && lastKey !== "ArrowDown") {
            setLastKey("ArrowDown");
            setCurrentIndex(Math.min(currentIndex + 1, items.length - 1));
        }
        else if (pressedEnter && lastKey !== "Enter") {
            setLastKey("Enter");
            onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(items[currentIndex]);
        }
        else if (!pressedUp && !pressedDown && !pressedEnter && lastKey) {
            setLastKey(null);
        }
    }, [
        pressedDown,
        pressedUp,
        pressedEnter,
        currentIndex,
        items,
        onSelectItem,
        setLastKey,
        lastKey,
    ]);
    var handleHover = function (index) { return function () {
        setCurrentIndex(index);
    }; };
    var effectiveItems = __spreadArray([], items.filter(function (_, i) { return (maxResults ? i < maxResults : true); }), true);
    var hiddenItemCount = items.length - effectiveItems.length;
    if (additionalItem) {
        effectiveItems.push(additionalItem);
    }
    if (hiddenItemCount > 0) {
        effectiveItems.push({
            label: "At least " + hiddenItemCount + " result(s) not shown narrow your search to see more options.",
            id: DROPDOWN_MORE_RESULTS_ITEM_ID,
            informational: true,
        });
    }
    return (_jsxs("ul", __assign({ className: DROP_DOWN_LIST }, { children: [onDismiss ? (_jsx(IconButton, { icon: faTimes, className: DISMISS_STYLES, onClick: onDismiss }, void 0)) : null, effectiveItems.map(function (item, index) { return (_jsx(DropDownItem, { item: item, onMouseOver: handleHover(index), selected: currentIndex === index, onClick: handleSelectionForItem(item) }, item.id)); }), effectiveItems.length < 1 ? (_jsx(DropDownItem, { item: blankResultItem !== null && blankResultItem !== void 0 ? blankResultItem : {
                    id: DROPDOWN_BLANK_ITEM_ID,
                    label: "No results were found",
                    icon: faBan,
                } }, void 0)) : null] }), void 0));
};
