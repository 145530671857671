import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form } from "../../shared"
import styles from "./reportCalendarListItem.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faPencil } from "@fortawesome/pro-solid-svg-icons"

class ReportCalendarListItem extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  render() {
    const { onRemove, onEdit, calendarId, name, reportCalendar } = this.props
    const { loading } = this.state
    const handleRemove = (e) => {
      e.preventDefault()
      this.setState({ loading: true })
      onRemove(calendarId)
    }
    const handleEdit = (e) => {
      e.preventDefault()
      onEdit(calendarId)
    }
    const customizations = [
      reportCalendar.showTint,
      reportCalendar.showPrefix,
      reportCalendar.showInOut,
    ].filter((c) => c === true)

    return (
      <div
        style={{
          pointerEvents: loading ? "none" : "all",
          opacity: loading ? 0.5 : 1,
        }}
      >
        <Form.Divider />
        <p className="flex">
          <button
            className="text-lochivar-defaulttext-sm underline text-left flex-grow"
            onClick={handleEdit}
          >
            {name}
          </button>
          <button className="flex-shrink-0 text-sm" onClick={handleEdit}>
            <FontAwesomeIcon icon={faPencil} className={styles.inlineIcon} />
          </button>
          <button
            className="flex-shrink-0 text-sm text-danger"
            onClick={handleRemove}
          >
            <FontAwesomeIcon icon={faTimes} className={styles.inlineIcon} />
          </button>
        </p>
        {customizations.length > 0 ? (
          <p className={`${styles.description}`}>
            {reportCalendar.showInOut
              ? "Start date / end date indicators enabled."
              : ""}
            {reportCalendar.showTint ? (
              <>
                {" "}
                <span>Banners are</span>
                <span
                  style={{
                    background: reportCalendar.tintFillColor,
                    color: reportCalendar.tintTextColor,
                    border: `1px solid ${reportCalendar.tintOutlineColor}`,
                    padding: "1px 3px",
                    margin: "auto 0.25rem",
                    borderRadius: "0.25rem",
                    display: "inline-block",
                  }}
                >
                  tinted
                </span>
                .
              </>
            ) : (
              " "
            )}
            {reportCalendar.showPrefix ? (
              <span> Banners are prefixed with '{reportCalendar.prefix}'.</span>
            ) : (
              " "
            )}
          </p>
        ) : (
          ""
        )}
      </div>
    )
  }
}

ReportCalendarListItem.propTypes = {
  /**
   * The ID of the calendar.
   */
  calendarId: PropTypes.string,

  /**
   * The name of the calendar.
   */
  name: PropTypes.string,

  /**
   * The associated report calendar meta data for this calendar on this report.
   */
  reportCalendar: PropTypes.object,

  /**
   * Event handler fired when the user chooses to edit the calendar.
   */
  onRemove: PropTypes.func,

  /**
   * Event handler fired when the user chooses to remove the calendar from the report.
   */
  onEdit: PropTypes.func,
}

export default ReportCalendarListItem
