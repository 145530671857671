var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Group, Rect } from "react-konva";
import { useEffect, useRef, useState } from "react";
var FONT_SIZE = 10;
export var WeekNumberLabel = function (_a) {
    var x = _a.x, y = _a.y, font = _a.font, fillColor = _a.fillColor, labelColor = _a.labelColor, borderColor = _a.borderColor, number = _a.number, limited = _a.limited;
    var _b = useState({ width: 0, height: 0 }), dateSize = _b[0], setDateSize = _b[1];
    var dateText = useRef(null);
    useEffect(function () {
        var _a, _b, _c, _d;
        var width = (_b = (_a = dateText.current) === null || _a === void 0 ? void 0 : _a.textWidth) !== null && _b !== void 0 ? _b : 0;
        var height = (_d = (_c = dateText.current) === null || _c === void 0 ? void 0 : _c.textHeight) !== null && _d !== void 0 ? _d : 0;
        if ((dateSize.width !== width, dateSize.height !== height)) {
            setDateSize({
                width: width,
                height: height,
            });
        }
    }, [dateSize, setDateSize, dateText]);
    var rectWidth = Math.ceil(dateSize.width) + 6;
    var rectX = x - rectWidth;
    var labelX = 3;
    var labelY = y + 2;
    return (_jsxs(Group, __assign({ x: x - rectWidth, y: 0 }, { children: [_jsx(Rect, { width: rectWidth, height: dateSize.height + 4, fill: fillColor !== null && fillColor !== void 0 ? fillColor : "#fff", stroke: borderColor !== null && borderColor !== void 0 ? borderColor : "#000", strokeEnabled: true, strokeWidth: 1 }, void 0), _jsx(Text, { ref: dateText, text: "WEEK " + number, x: labelX, y: labelY, fontSize: FONT_SIZE - (limited ? 4 : 0), fontFamily: font, align: "left", fill: labelColor !== null && labelColor !== void 0 ? labelColor : "#000" }, void 0)] }), void 0));
};
