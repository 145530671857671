var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { faEdit, faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState } from "react";
import { IconButton } from "../Navigation";
export var StyleList = function (_a) {
    var styles = _a.styles, className = _a.className, style = _a.style, onDeleteStyle = _a.onDeleteStyle, onDuplicateStyle = _a.onDuplicateStyle, onEditStyle = _a.onEditStyle, onAddStyle = _a.onAddStyle;
    var _b = useState(null), current = _b[0], setCurrent = _b[1];
    return (_jsx("div", __assign({ className: clsx(className), style: style }, { children: _jsxs("ul", __assign({ className: "flex flex-wrap" }, { children: [styles.map(function (s) {
                    var handleEdit = function () { return onEditStyle(s.uuid); };
                    var handleDuplicate = function () { return onDuplicateStyle === null || onDuplicateStyle === void 0 ? void 0 : onDuplicateStyle(s.uuid); };
                    var handleDelete = function () { return onDeleteStyle === null || onDeleteStyle === void 0 ? void 0 : onDeleteStyle(s.uuid); };
                    var handleClick = function (e) {
                        e.preventDefault();
                        setCurrent(s.uuid === current ? null : s.uuid);
                    };
                    return (_jsxs("li", __assign({ className: clsx("relative flex p-1 border rounded", s.uuid === current
                            ? "border-lochivar-default"
                            : "border-white hover:border-gray-400") }, { children: [_jsx("button", __assign({ style: {
                                    textAlign: s.textAlignment,
                                    border: "1px solid " + s.outlineColor,
                                    background: s.fillColor,
                                    fontFamily: s.font,
                                    fontSize: s.fontSize,
                                    color: s.textColor,
                                    fontStyle: s.fontStyle,
                                    textDecoration: s.textDecoration,
                                }, onClick: handleClick, className: clsx("py-1 px-2 my-auto h-8 flex", s.borderRadius === "round" && "rounded") }, { children: _jsx("span", __assign({ className: "m-auto" }, { children: s.name }), void 0) }), void 0), s.uuid === current ? (_jsxs("ul", __assign({ className: "flex absolute left-0 top-100 bg-white border border-gray-200 z-10 rounded shadow-lg p-1 scale-75" }, { children: [_jsx("li", { children: _jsx(IconButton, { icon: faEdit, className: "ml-auto my-auto", tooltip: "Edit", onClick: handleEdit }, void 0) }, void 0), onDuplicateStyle ? (_jsx("li", { children: _jsx(IconButton, { icon: faCopy, className: "my-auto", tooltip: "Duplicate", onClick: handleDuplicate }, void 0) }, void 0)) : null, onDeleteStyle ? (_jsx("li", { children: _jsx(IconButton, { icon: faTrash, className: "my-auto", tooltip: "Delete", tooltipAlignment: "left", onClick: handleDelete }, void 0) }, void 0)) : null] }), void 0)) : null] }), "style-" + s.uuid));
                }), onAddStyle ? (_jsx("li", __assign({ className: "flex" }, { children: _jsx("button", __assign({ className: "flex shadow rounded-full ml-2 py-2 px-3 border border-gray-200 hover:shadow-lg hover:border-gray-300", onMouseOver: function () {
                            setCurrent(null);
                        }, onClick: onAddStyle }, { children: _jsx(FontAwesomeIcon, { icon: faPlus, className: "m-auto" }, void 0) }), void 0) }), void 0)) : null] }), void 0) }), void 0));
};
