import React, { Component } from "react"
import PropTypes from "prop-types"
import { SketchPicker } from "react-color"

import GridRow from "./GridRow.react"
import Group from "./Group.react"
import FieldCol from "./FieldCol.react"
import AssistantCol from "./AssistantCol.react"
import AssistantText from "./AssistantText.react"
import Indicator from "../Indicator"
import styles from "./Field.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

const getRealType = (type) => {
  switch (type) {
    case "color":
      return "text"
    default:
      return type
  }
}

class Field extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPicker: false,
      color: this.props.color,
      showSuggestions: false,
      blurTimeout: null,
    }
  }

  blurForField(field) {
    const { autocompleteOptions } = this.props
    this.props.input &&
      this.props.input.onBlur &&
      this.props.input.onBlur(field)

    if (autocompleteOptions) {
      const blurTimeout = setTimeout(
        () => this.setState({ showSuggestions: false }),
        250
      )
      this.setState({ blurTimeout })
    }
  }

  focusForField(field) {
    this.props.input &&
      this.props.input.onFocus &&
      this.props.input.onFocus(field)
    if (this.state.blurTimeout) {
      clearTimeout(this.state.blurTimeout)
      this.setState({ blurTimeout: null })
    }
    this.setState({ showSuggestions: true })
  }

  togglePicker(visible) {
    if (visible) {
      this.showPicker(this.props.name)
    } else {
      this.disablePicker(this.props.name)
    }
  }

  showPicker(field) {
    this.props.input.onFocus(field)
    this.setState({ showPicker: true })
  }

  disablePicker(field) {
    this.setState({ showPicker: false })
    this.props.input.onBlur(field)
  }

  handleChangeComplete(color) {
    this.setState({
      color: color.hex,
    })
    this.props.input.onChange(color.hex)
  }

  classNamesForInput() {
    const stylesForCheckBox = `form-check-input ${styles.checkbox}`
    const { type } = this.props
    switch (type) {
      case "color":
        return styles.hidden
      case "checkbox":
        return stylesForCheckBox
      default:
        return "form-control"
    }
  }

  defaultColors() {
    return [
      "#b6d9d5",
      "#789f9e",
      "#4f6467",
      "#eef3dc",
      "#fe615a",
      "#264267",
      "#347996",
      "#3d8d98",
      "#deceaa",
      "#e9aa77",
      "#E27300",
      "#dd7244",
      "#cb4633",
      "#feaf88",
      "#ff687d",
      "#cb6686",
      "#745984",
      "#265d84",
      "#2d4d58",
      "#3b988e",
      "#fedd7c",
      "#fea968",
      "#d9664b",
      "#fff3d9",
      "#ffe6b3",
      "#48aad9",
      "#2f6e8d",
      "#153240",
      "#FFFFFF",
      "#000000",
    ]
  }

  renderColorField(color) {
    const { colorOptions } = this.props.customizations || {}
    return (
      <div
        className={styles.colorField}
        style={{ backgroundColor: color, cursor: "pointer" }}
        onClick={() => {
          this.togglePicker(!this.state.showPicker)
        }}
      >
        {this.state.showPicker}
        {this.state.showPicker && (
          <div>
            <div
              className={styles.pickerContainer}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className={styles.closeButton}
                onClick={(e) => {
                  e.preventDefault()
                  this.togglePicker(false)
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <SketchPicker
                color={this.state.color}
                width="212"
                presetColors={colorOptions || this.defaultColors()}
                onChangeComplete={(c) => this.handleChangeComplete(c)}
              />
            </div>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: "2",
              }}
              onClick={() => this.togglePicker(false)}
            />
          </div>
        )}
      </div>
    )
  }

  renderInput() {
    const { name, input, customizations, type } = this.props
    if (type === "select") {
      return (
        <select
          {...input}
          aria-describedby={`${name}Help`}
          className={this.classNamesForInput()}
          onFocus={(f) => this.focusForField(f)}
          onBlur={(f) => this.blurForField(f)}
        >
          {customizations.defaultOption && (
            <option value="">{customizations.defaultOption}</option>
          )}
          {customizations.options}
        </select>
      )
    }
    return (
      <input
        {...input}
        {...customizations}
        autoComplete="off"
        id={name}
        type={getRealType(type)}
        aria-describedby={`${name}Help`}
        className={this.classNamesForInput()}
        onFocus={(f) => this.focusForField(f)}
        onBlur={(f) => this.blurForField(f)}
      />
    )
  }

  renderCheckboxTextLabel() {
    const { name, checkboxText } = this.props
    return (
      <label htmlFor={name} className="form-check-label">
        {this.renderInput()}
        {checkboxText}
      </label>
    )
  }

  render() {
    const {
      name,
      input,
      label,
      type,
      meta: { asyncValidating, touched, error },
      assistantText,
      active,
      checkboxText,
      previewText,
      autocompleteOptions,
    } = this.props

    const { showSuggestions } = this.state

    const showError = (error && touched) || false

    return (
      <GridRow>
        <FieldCol>
          <Group error={showError}>
            <label htmlFor={name} className={styles.label}>
              {label}
            </label>
            <div className={`input-row ${styles.fieldContainer}`}>
              {checkboxText
                ? this.renderCheckboxTextLabel()
                : this.renderInput()}
              {type === "color" && this.renderColorField(input.value)}
              {asyncValidating && <Indicator.Ring />}
              {showSuggestions &&
                autocompleteOptions &&
                autocompleteOptions.length > 0 && (
                  <ul className={styles.autocompleteList}>
                    {autocompleteOptions.map((o) => (
                      <li key={o.value}>
                        <button
                          type="button"
                          onClick={() => input.onChange(o.value)}
                        >
                          {o.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
            </div>
            {previewText && (
              <p className={styles.example}>{previewText(input.value)}</p>
            )}
          </Group>
        </FieldCol>
        <AssistantCol>
          <AssistantText active={active} name={name} error={showError}>
            {showError ? <span className="error">{error}</span> : assistantText}
          </AssistantText>
        </AssistantCol>
      </GridRow>
    )
  }
}

Field.propTypes = {
  autocompleteOptions: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })
  ),
  name: PropTypes.string,
  color: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  checkboxText: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  customizations: PropTypes.object,
  active: PropTypes.bool.isRequired,
  previewText: PropTypes.func,
  assistantText: PropTypes.string,
}

export default Field
