var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContextItem } from "./ContextItem";
import { faClone, faFileAlt, faCalendarMinus, faCalendarDay, faPaste, faStickyNote, faPlane, faPlaneSlash, faTimesHexagon, faListOl, faCheck, } from "@fortawesome/pro-regular-svg-icons";
import { faBackward, faForward } from "@fortawesome/pro-solid-svg-icons";
/**
 * A set of context options for a selected day.
 */
export var ContextMenuDay = function (_a) {
    var _b = _a.clipboardCount, clipboardCount = _b === void 0 ? 0 : _b, _c = _a.clipboardMode, clipboardMode = _c === void 0 ? "cut" : _c, onSelect = _a.onSelect, hasHolidays = _a.hasHolidays, hasTravel = _a.hasTravel, weekNumberMode = _a.weekNumberMode;
    var handleSelection = function (selection, params) { return function () {
        if (onSelect) {
            onSelect(selection, params);
        }
    }; };
    var label = clipboardCount > 1 ? "Events" : "Event";
    var holidayAction = hasHolidays ? "Edit" : "Add";
    return (_jsxs(_Fragment, { children: [_jsx(ContextItem, { name: "Add Event", icon: faFileAlt, onClick: handleSelection("createItem") }, void 0), _jsx(ContextItem, { name: "Add Note", icon: faStickyNote, onClick: handleSelection("createNote") }, void 0), hasHolidays ? (_jsx(ContextItem, { name: "Clear Holiday/Blackout", icon: faCalendarMinus, onClick: handleSelection("clearHolidays") }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(ContextItem, { name: holidayAction + " Holiday", icon: faCalendarDay, onClick: handleSelection("createHoliday") }, void 0), _jsx(ContextItem, { name: "Blackout Day", icon: faTimesHexagon, onClick: handleSelection("blackoutDay") }, void 0)] }, void 0)), _jsx(ContextItem, { name: "Push", icon: faForward, onClick: handleSelection("push") }, void 0), _jsx(ContextItem, { name: "Pull", icon: faBackward, onClick: handleSelection("pull") }, void 0), _jsx(ContextItem, { name: "Paste " + label, icon: faPaste, onClick: handleSelection("paste"), disabled: clipboardCount < 1 }, void 0), _jsx(ContextItem, { name: "Paste " + label + " w/ Increment", icon: faClone, onClick: handleSelection("paste", {
                    autoIncrement: true,
                }), disabled: clipboardCount !== 1 || clipboardMode !== "copy" }, void 0), hasTravel ? (_jsx(ContextItem, { name: "Clear Travel", icon: faPlaneSlash, onClick: handleSelection("clearTravelDay") }, void 0)) : (_jsx(ContextItem, { name: "Travel Day", icon: faPlane, onClick: handleSelection("travelDay") }, void 0)), _jsxs(ContextItem, __assign({ name: "Toggle Weeknumbers", icon: faListOl, onClick: handleSelection(null) }, { children: [_jsx(ContextItem, { name: "Hide Week Numbers", icon: !weekNumberMode ? faCheck : undefined, onClick: handleSelection("hideWeekNumbers") }, void 0), _jsx(ContextItem, { name: "Week of Project", icon: weekNumberMode === "showWeekOfProject" ? faCheck : undefined, onClick: handleSelection("showWeekOfProject") }, void 0), _jsx(ContextItem, { name: "Week of Year", icon: weekNumberMode === "showWeekOfYear" ? faCheck : undefined, onClick: handleSelection("showWeekOfYear") }, void 0)] }), void 0)] }, void 0));
};
