import { connect } from "react-redux"
import api from "../../api"
import ReportSettingsModalView from "../components/ReportSettingsModalView.react"

const {
  actions: { reports },
  selectors: { calendarSelector, reportSelector },
} = api

export default connect(
  (state) => ({
    findReport: reportSelector.find(state),
    findReportCalendarIds: reportSelector.calendarIdsForReport(state),
    findReportCalendar: reportSelector.findReportCalendar(state),
    findCalendars: calendarSelector.forOrganization(state),
  }),
  {
    addReportCalendar: reports.addReportCalendar,
    deleteReportCalendar: reports.deleteReportCalendar,
    updateReportCalendar: reports.updateReportCalendar,
    requestFullReportCalendars: reports.requestFullReportCalendars,
    requestReport: reports.requestReport,
  }
)(ReportSettingsModalView)
