import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarAction.module.css"
import ToolBarToolTip from "./ToolBarToolTip.react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/* eslint-disable complexity */
const getClassNames = ({
  small,
  disabled,
  required,
  primary,
  promoted,
  selected,
}) =>
  `${styles.button} ${small ? styles.small : ""} ${
    primary ? styles.primary : ""
  } ${promoted ? styles.promoted : ""} ${disabled ? styles.disabled : ""} ${
    selected ? styles.selected : ""
  } ${typeof selected === "boolean" ? styles.selectable : ""} ${
    required ? styles.required : styles.notRequired
  }`
/* eslint-enable complexity */

const ToolBarAction = ({
  primary,
  promoted,
  small,
  onClick,
  icon,
  label,
  disabled,
  required,
  toolTipShortCut,
  toolTipText,
  toolTipAlign,
  selected,
}) => (
  <ToolBarToolTip>
    {({ showToolTip, hideToolTip, renderToolTip }) => (
      <button
        className={getClassNames({
          small,
          disabled,
          required,
          primary,
          promoted,
          selected,
        })}
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e.stopPropagation()
                onClick(e)
              }
            : (e) => e.stopPropagation()
        }
        onMouseOut={() => hideToolTip()}
        onMouseOver={() => showToolTip()}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {label && <span className={styles.label}>{label}</span>}
        {renderToolTip({ toolTipAlign, toolTipText, toolTipShortCut })}
      </button>
    )}
  </ToolBarToolTip>
)

ToolBarAction.propTypes = {
  primary: PropTypes.bool,
  promoted: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  icon: PropTypes.object,
  label: PropTypes.string,
  toolTipText: PropTypes.string,
  toolTipShortCut: PropTypes.string,
  toolTipAlign: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  selected: PropTypes.bool,
}

export default ToolBarAction
