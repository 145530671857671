import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { reports as actions } from "../actions"
import { reportSelector } from "../selectors"
import { useOrganization } from "./useOrganization"

export const useReports = (subdomain: string) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization(subdomain)
  const request = async () => await dispatch(actions.requestReport(subdomain))
  const reports = useSelector(reportSelector.forOrganization)(organization?.id)
  const { loading, refetch } = useRequest(request)
  return { loading, reports, refetch }
}
