import React from "react"
import PropTypes from "prop-types"
import styles from "./Section.module.css"

const Section = ({ children }) => (
  <div className={`${styles.modalSection}`}>{children}</div>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
