import { connect } from "react-redux"
import * as actions from "../actions"
import api from "../../api"
import PrintableCalendarView from "../components/PrintableCalendarView.react"

const {
  calendarSelector,
  eventSelector,
  holidaySelector,
  authSelector,
  reportSelector,
  snapshotSelector,
} = api.selectors

const {
  actions: { cals, reports, snapshots },
} = api

export default connect(
  () => {
    return (state) => ({
      findSnapshot: snapshotSelector.find(state),
      findCalendar: calendarSelector.find(state),
      findIndicatorDates: calendarSelector.findIndicatorDates(state),
      findCalendarEvents: eventSelector.forCalendar(state),
      findSnapshotEvents: eventSelector.forSnapshot(state),
      findReportCalendarIds: reportSelector.calendarIdsForReport(state),
      findReportCalendars: reportSelector.calendarsForReport(state),
      findReport: reportSelector.find(state),
      findHolidays: holidaySelector.forCalendar(state),
      isAdmin: authSelector.admin(state),
      findRenderableCalendarIdsForReport:
        reportSelector.renderableCalendarIdsForReport(state),
    })
  },
  {
    ...actions,
    createCalendarPrintToken: cals.createCalendarPrintToken,
    loadCalendar: cals.requestCalendar,
    loadSnapshot: snapshots.requestSnapshot,
    requestReport: reports.requestReport,
    requestFullReportCalendars: reports.requestFullReportCalendars,
  }
)(PrintableCalendarView)
