import OrganizationFormView from "../components/OrganizationFormView.react"
import { reduxForm } from "redux-form"
import { FORM_NAME } from "../constants"
import { asyncValidate, validate } from "../validators"

export default reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ["name", "subdomain"],
})(OrganizationFormView)
