import { useApiRequest } from "./useApiRequest"

export interface UploadInput {
  key: string
  contentType: string
}

export interface UploadBody {
  uploads: UploadInput[]
}

export interface UploadPayload {
  bucket: string
  downloadUrl: string
  id: string
  url: string
}

export interface UploadOutput {
  success: boolean
  uploads: UploadPayload[]
  errors: Object[]
}

export interface UploadResponse {
  data: UploadOutput
}

export const useCreateUploads = () => {
  const getUpload = useApiRequest<UploadResponse, UploadBody>({
    method: "POST",
    version: "V2",
    url: `//${process.env.REACT_APP_API_V2_BASE_DOMAIN}/uploads`,
  })
  return getUpload
}
