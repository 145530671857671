import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import throttle from "lodash/throttle"
import { DATE_FORMAT, ItemTypes, ContextTypes } from "../constants"
import { DropTarget } from "react-dnd"
import * as ItemExplorer from "../../itemExplorer"
import * as actions from "../actions"
import * as selectable from "../../selectable"
import styles from "./MonthDayView.module.css"
import { readableColor, lighten, darken } from "polished"
import { colors } from "../../utils"
import clsx from "clsx"
import {
  faCog,
  faPlus,
  faPencil,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class MonthDayView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showIndicator: false,
    }
    this.container = null
  }

  componentDidMount() {
    if (this.container) {
      this.container.addEventListener("contextmenu", (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.renderContextMenu({ x: e.clientX, y: e.clientY })
      })
    }
  }

  renderContextMenu({ x, y }) {
    const { holidays, requestContext, date, selectDate, selectItem, selected } =
      this.props
    requestContext({
      entityType: ContextTypes.DAY,
      holidays,
      referenceDate: date.format(),
      x,
      y,
    })
    if (!selected) {
      selectDate(date)
    }
    selectItem(null)
  }

  handleClick(e) {
    const { handleSelection, handleSettingsForDay, date } = this.props
    e.stopPropagation()
    handleSelection(date)
    handleSettingsForDay(null)
  }

  handleMouseEnter() {
    this.setState({ showIndicator: true })
  }

  handleMouseLeave() {
    this.setState({ showIndicator: false })
  }

  shouldShowSettings() {
    const { dateForSettingsMenu, date, readOnly } = this.props
    return (
      !readOnly &&
      dateForSettingsMenu &&
      date.format("MMDDYYYY") === dateForSettingsMenu.format("MMDDYYYY")
    )
  }

  belongsToCurrentMonth(date) {
    return date.month() === this.props.getStartMoment().month()
  }

  isToday(date) {
    return date.format(DATE_FORMAT) === moment().format(DATE_FORMAT)
  }

  isStartOfMonth(date) {
    return date.date() === 1
  }

  isEndOfMonth(date) {
    return date.date() === moment.utc(date).endOf("month").date()
  }

  belongsToPrevMonth(date) {
    return date.month() < this.props.getStartMoment().month()
  }

  belongsToNextMonth(date) {
    return date.month() > this.props.getStartMoment().month()
  }

  belongsToWeekend(date) {
    return [7, 6].includes(date.isoWeekday())
  }

  isHoliday() {
    const { holidays } = this.props
    return holidays.length > 0
  }

  getMonthDetailsClassNames(date) {
    const classNames = []
    if (this.isEndOfMonth(date)) {
      classNames.push(styles.endMonth)
    }
    if (this.isStartOfMonth(date)) {
      classNames.push(styles.startMonth)
    }
    return classNames
  }

  getExternalMonthClassNames(date) {
    const classNames = []
    if (this.belongsToCurrentMonth(date)) {
      classNames.push(styles.currentMonth)
    }
    if (this.belongsToNextMonth(date)) {
      classNames.push(styles.nextMonth)
    }
    if (this.belongsToPrevMonth(date)) {
      classNames.push(styles.prevMonth)
    }
    return classNames
  }

  getDayDetailsClassNames(date) {
    const classNames = []
    if (this.belongsToWeekend(date)) {
      classNames.push(styles.weekend)
    }
    if (this.isHoliday()) {
      classNames.push(styles.holiday)
    }
    if (this.isToday(date)) {
      classNames.push(styles.currentDay)
    }
    return classNames
  }

  getClassNamesForDate(date) {
    return (
      date &&
      clsx(
        styles.day,
        this.getMonthDetailsClassNames(date),
        this.getExternalMonthClassNames(date),
        this.getDayDetailsClassNames(date)
      )
    )
  }

  getBackgroundColorForDate(date) {
    if (!this.belongsToCurrentMonth(date)) {
      return this.props.cutoffColor
    }
    if (this.belongsToWeekend(date)) {
      return this.props.weekendColor
    }
    if (this.isHoliday()) {
      return this.props.holidayColor
    }
    return "auto"
  }

  getReadableColorForDate(date) {
    const color = this.getBackgroundColorForDate(date) || "auto"
    if (color === "auto") {
      return colors.rose
    }
    return readableColor(color, darken(0.2, color), lighten(0.5, color))
  }

  render() {
    const {
      date,
      isOver,
      connectDropTarget,
      simplified,
      selected,
      handleSettingsForDay,
      readOnly,
      ...props
    } = this.props
    const { showIndicator } = this.state
    const classNames = this.getClassNamesForDate(date).join(" ")
    return connectDropTarget(
      <div
        className={classNames}
        onClick={(e) => this.handleClick(e)}
        onMouseEnter={(e) => this.handleMouseEnter(e)}
        onMouseLeave={(e) => this.handleMouseLeave(e)}
        ref={(el) => (this.container = el)}
        style={{
          backgroundColor: this.getBackgroundColorForDate(date),
          color: this.getReadableColorForDate(date),
        }}
      >
        <div
          className={clsx(
            styles.daySelectContainer,
            simplified && styles.simplified
          )}
        >
          <h4
            className={clsx(
              styles.displayDate,
              simplified && styles.simplified,
              this.isToday() && "border-sorbus-default text-white"
            )}
          >
            <span>{date.date()}</span>
          </h4>
          {this.belongsToCurrentMonth(date)
            ? (props.indicatorColors || []).map((color) => (
                <span
                  style={{ background: color }}
                  className={`${styles.indicator} ${
                    simplified && styles.simplified
                  }`}
                  key={color}
                />
              ))
            : null}
          {props.holidays && (
            <h5
              className={`${styles.holidayName} ${
                simplified && styles.simplified
              }`}
            >
              {props.holidays.map((h) => h.name).join(", ")}
            </h5>
          )}
          <span className={styles.daySelectSpacer} />
          {!readOnly && showIndicator && (
            <button
              className={`${styles.settingsButton} ${
                simplified && styles.simplified
              }`}
              onClick={(e) => {
                e.stopPropagation()
                handleSettingsForDay(date)
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </button>
          )}
          {this.shouldShowSettings() && (
            <div
              className={`${styles.dayMenuDropDown} ${
                simplified && styles.simplified
              }`}
            >
              <ul>
                <li className={styles.dropDownTitle}>Settings</li>
                <li className={styles.dropDownItem}>
                  <button
                    className={styles.dropdownButton}
                    onClick={() => props.handleCreateEventOnDate(date)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add Event</span>
                  </button>
                </li>
                <li className={styles.dropDownItem}>
                  <button
                    className={styles.dropdownButton}
                    onClick={() => props.handleCreateNoteOnDate(date)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add Note</span>
                  </button>
                </li>
                {props.holidays.length < 1 && (
                  <li className={styles.dropDownItem}>
                    <button
                      className={styles.dropdownButton}
                      onClick={() => props.handleCreateHolidayOnDate(date)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Add Holiday</span>
                    </button>
                  </li>
                )}
                {props.holidays.map((holiday) => (
                  <li className={styles.dropDownItem} key={holiday.uuid}>
                    <button
                      className={styles.dropdownButton}
                      onClick={() => props.handleEditHoliday(holiday.uuid)}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                      <span>{holiday.name}</span>
                    </button>
                  </li>
                ))}
                {props.holidays.length > 0 && (
                  <li className={styles.dropDownItem}>
                    <button
                      className={styles.dropdownButton}
                      onClick={() => props.handleDeleteHolidays(props.holidays)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <span>
                        Clear Holiday
                        {props.holidays.length > 1 ? "s" : ""}
                      </span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <span className={isOver ? styles.hover : styles.neutral} />
        {selected && <span className={styles.selectable} />}
      </div>
    )
  }
}

MonthDayView.propTypes = {
  /**
   * Indicartes whether or not the calendar day should be clickable.
   */
  selected: PropTypes.bool.isRequired,

  /**
   * The calendar date represented by this view. Typically
   * passed in as a moment JS object.
   */
  date: PropTypes.object.isRequired,

  /**
   * An array of holidays that apply to the current calendar.
   */
  holidays: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Indicates whether or not the view should render in a
   * simplified format due to limited screen real estate.
   */
  simplified: PropTypes.bool,

  /**
   * A function that can generate the exact start moment for
   * the current calendar month. Used to determine how this
   * specific date relates to the overall view. For example,
   * does this date fall within the specified month or is
   * is an auxillary date that trails at the end of the final
   * week of the month?
   */
  getStartMoment: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function creates a calendar event against the API.
   */
  handleItemDrop: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function updates a calendar events date range.
   */
  handleItemExpand: PropTypes.func.isRequired,

  /**
   * A callback that is fired when the day is selected.
   */
  handleSelection: PropTypes.func.isRequired,

  /**
   * A boolean flag indicating whether or not an item that
   * may be dropped onto this day is currently hovering
   * above it.
   */
  isOver: PropTypes.bool.isRequired,

  /**
   * Indicates this day is blocked for example if it is a
   * holiday it may be blocked etc..
   */
  isBlocked: PropTypes.bool,

  /**
   * Allows react dnd to wrap JSX results in a higher level
   * component that behaves as an HTML5 drop target.
   */
  connectDropTarget: PropTypes.func.isRequired,

  /**
   * If the date property matches this date we will display
   * the settings menu for this day.
   */
  dateForSettingsMenu: PropTypes.object,

  /**
   * An event handler called when the settings menu is requested.
   */
  handleSettingsForDay: PropTypes.func.isRequired,

  /**
   * An event handler that is called when the user requests
   * to convert a date on the calendar into a holiday.
   */
  handleCreateHolidayOnDate: PropTypes.func.isRequired,

  /**
   * An event handler that is called when the user requests
   * to add a new event on a specific date.
   */
  handleCreateEventOnDate: PropTypes.func.isRequired,

  /**
   * An event handler that is called when the user requests
   * to add a new note on a specific date.
   */
  handleCreateNoteOnDate: PropTypes.func.isRequired,

  /**
   * An event handler called when the user opts to edit a holiday
   * presented for this day.
   */
  handleEditHoliday: PropTypes.func.isRequired,

  /**
   * An event handler called when the user opts to delete a holiday
   * for the current date.
   */
  handleDeleteHolidays: PropTypes.func.isRequired,

  /**
   * Ensures that editing cannot be done with this view if set to true.
   */
  readOnly: PropTypes.bool.isRequired,

  /**
   * An action creator that requests a context menu for this event / item.
   */
  requestContext: PropTypes.func,

  /**
   * An action creator that selects this event / item.
   */
  selectItem: PropTypes.func,

  /**
   * An action creator that selects a date on the calendar.
   */
  selectDate: PropTypes.func,

  /**
   * The background color of a weekend cell.
   */
  weekendColor: PropTypes.string,

  /**
   * The background color of a holiday cell.
   */
  holidayColor: PropTypes.string,

  /**
   * The background color of an adjacent month's cell.
   */
  cutoffColor: PropTypes.string,

  /**
   * An array of indicator colors to present next to the date.
   */
  indicatorColors: PropTypes.array,
}

/**
 * To mitigate some performance related issues we throttle the mouse event
 * during drag and drop such that the complexities of dispatching an update
 * that reprocesses all of the local events in the redux store can only take
 * place at a maximum of 20 times per second.
 * @type {Function}
 */
const throttlePace = 250
const dispatchHoverEvent = throttle(
  (eventId, date, direction, action) => action(eventId, date, direction),
  throttlePace
)

/**
 * A react DND specification for the DropTarget component representing
 * the ItemViewTarget
 * @type {Object}
 */
const itemViewTarget = {
  canDrop(props) {
    const { isBlocked } = props
    return !isBlocked
  },
  drop(props, monitor) {
    const { handleItemDrop, date, simplified, handleItemExpand } = props
    const item = monitor.getItem()
    switch (monitor.getItemType()) {
      case ItemExplorer.ItemTypes.ITEM_VIEW:
        handleItemDrop(item.uuid, date)
        break
      case ItemTypes.EVENT_EXPAND_VIEW:
        simplified &&
          dispatchHoverEvent(
            item.uuid,
            date.startOf("day"),
            item.start ? -1 : 1,
            handleItemExpand
          )
        break
      default:
        break
    }
  },
  hover(props, monitor) {
    const { handleItemExpand, date, simplified } = props
    const item = monitor.getItem()
    switch (monitor.getItemType()) {
      case ItemTypes.EVENT_EXPAND_VIEW:
        !simplified &&
          dispatchHoverEvent(
            item.uuid,
            date.startOf("day"),
            item.start ? -1 : 1,
            handleItemExpand
          )
        break
      default:
        break
    }
  },
}

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

export default DropTarget(
  [ItemExplorer.ItemTypes.ITEM_VIEW, ItemTypes.EVENT_EXPAND_VIEW],
  itemViewTarget,
  dropCollect
)(
  connect(null, {
    requestContext: actions.requestContext,
    selectItem: selectable.selectItem,
    selectDate: selectable.selectDate,
  })(MonthDayView)
)
