import { useApiRequest, RequestParams } from "./useApiRequest"

export interface CalendarMacroBody {
  itemUuids: string[]
  startIndex?: string
  endIndex?: string
  tags?: string[]
  categoryUuid?: string
}

export interface CalendarMacroParams extends RequestParams {
  calendarId: string
}

export const useCalendarMacro = (subdomain: string) => {
  const CalendarMacro = useApiRequest<
    any,
    CalendarMacroBody,
    CalendarMacroParams
  >({
    method: "PATCH",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/calendars/:calendarId/macro`,
  })
  return CalendarMacro
}
