import { useApiRequest, RequestParams } from "./useApiRequest"

interface Calendar {
  id: number
  group_uuid: string
  name: string
  organization_id: string
  position: number
}

export interface UpdateCalendarGroupBody {
  group_uuid: string
}

export interface UpdateCalendarGroupParams extends RequestParams {
  calendarId: string
}

export const useUpdateCalendarGroup = (subdomain: string) => {
  const getUpload = useApiRequest<
    Calendar,
    UpdateCalendarGroupBody,
    UpdateCalendarGroupParams
  >({
    method: "PATCH",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/calendars/:calendarId/parent`,
  })
  return getUpload
}
