import React, { useState } from "react"
import {
  faUserShield,
  faUserPlus,
  faUser,
} from "@fortawesome/pro-regular-svg-icons"
import { Drawer, DRAWER_ITEM_TYPE, DrawerContent } from "ui"

import { useInvitations } from "../../api"
import { useHistory, useRouteMatch } from "react-router"
import { useHasCreatorPrivilege } from "../../api"

export interface InvitationDrawerProps {
  onDismiss: () => void
}

export const InvitationDrawer: React.FC<InvitationDrawerProps> = ({
  onDismiss: handleDismiss,
}) => {
  const [search, setSearch] = useState("")

  const history = useHistory()
  const match = useRouteMatch<{ subdomain: string }>("/org/:subdomain")
  const subdomain = match?.params.subdomain ?? ""

  const { loading: loadingInvitations, invitations } = useInvitations(subdomain)

  const handleNewClick = () => {
    history.push(`/org/${subdomain}/invite/new`)
    handleDismiss()
  }

  const isCreator = useHasCreatorPrivilege(subdomain)

  const handleSearch = (searchText?: string) => {
    setSearch(searchText ?? "")
  }

  const iconForAccessLevel = (accessLevel: string) => {
    switch (accessLevel) {
      case "admin":
        return faUserShield
      case "creator":
        return faUserPlus
      default:
        return faUser
    }
  }

  const finalItems = invitations
    ?.sort((a: any, b: any) =>
      a.accessLevel < b.accessLevel
        ? -1
        : a.name > b.name
        ? 1
        : a.name < b.name
        ? -1
        : 0
    )
    .map(
      (i: any, index: number): DrawerContent => ({
        id: i.id,
        label: `${i.name} ${!i.accepted ? "(pending)" : ""}`,
        icon: iconForAccessLevel(i.accessLevel),
        type: DRAWER_ITEM_TYPE,
        position: index,
        onSelect: () => {
          history.push(`/org/${subdomain}/invite/${i.id}/settings`)
          handleDismiss()
        },
        onSecondaryClick: () => {
          history.push(`/org/${subdomain}/invite/${i.id}/settings`)
          handleDismiss()
        },
      })
    )
    .filter((i: DrawerContent) =>
      search ? i.label.toLowerCase().indexOf(search.toLowerCase()) > -1 : true
    )
    .filter((i: DrawerContent) => (search ? i.type === DRAWER_ITEM_TYPE : true))
    .map((i: DrawerContent) => (search ? { ...i, groupId: undefined } : i))

  const loading = loadingInvitations

  return (
    <div className="absolute inset-0">
      <Drawer
        title="Users"
        newItemLabel="New User"
        onSearch={handleSearch}
        items={finalItems}
        onDismiss={handleDismiss}
        onNewClick={isCreator ? handleNewClick : undefined}
        offset={48}
        loading={loading}
      />
    </div>
  )
}
