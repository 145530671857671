import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarSearch.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"

const ToolBarSearch = ({ handleChange, placeholder, value }) => (
  <div className={styles.toolBarSearch}>
    <label htmlFor="search" className={styles.searchLabel}>
      <FontAwesomeIcon icon={faSearch} />
    </label>
    <input
      type="text"
      placeholder={placeholder || "search"}
      onChange={(e) => handleChange(e)}
      name="search"
      value={value}
      className={styles.searchInput}
    />
  </div>
)

ToolBarSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

export default ToolBarSearch
