var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
var CONTAINER = "absolute flex m-0 p-0 transition duration-700 ease-in-out transform pointer-events-none z-20";
var BELOW = CONTAINER + " inset-x-0 mt-2 top-100 left-0";
var ABOVE = CONTAINER + " inset-x-0 mb-2 bottom-100 left-0";
var RIGHT = CONTAINER + " inset-y-0 left-100 ml-2";
var LEFT = CONTAINER + " inset-y-0 right-100 mr-2";
var HIDDEN_ABOVE = ABOVE + " opacity-0 -translate-y-1";
var HIDDEN_BELOW = BELOW + " opacity-0 translate-y-1";
var HIDDEN_LEFT = LEFT + " opacity-0 -translate-x-1";
var HIDDEN_RIGHT = RIGHT + " opacity-0 translate-x-1";
var BASE_TOOLTIP = "bg-mist-default px-2 text-xs text-white rounded-full whitespace-nowrap not-italic";
var SHORTCUT = "font-semibold opacity-75";
var containerClass = function (_a) {
    var active = _a.active, direction = _a.direction;
    switch (direction) {
        case "above":
            return active ? ABOVE : HIDDEN_ABOVE;
        case "left":
            return active ? LEFT : HIDDEN_LEFT;
        case "right":
            return active ? RIGHT : HIDDEN_RIGHT;
        default:
            return active ? BELOW : HIDDEN_BELOW;
    }
};
var alignmentClass = function (_a) {
    var align = _a.align;
    switch (align) {
        case "left":
            return "mr-auto";
        case "right":
            return "ml-auto";
        default:
            return "my-auto mx-auto";
    }
};
export var ToolTip = function (_a) {
    var label = _a.label, _b = _a.direction, direction = _b === void 0 ? "below" : _b, shortCut = _a.shortCut, active = _a.active, _c = _a.align, align = _c === void 0 ? "center" : _c;
    var containerEl = useRef(null);
    var tooltipEl = useRef(null);
    var _d = useState(), style = _d[0], setStyle = _d[1];
    useEffect(function () {
        var _a, _b, _c, _d;
        if (["above", "below"].includes(direction)) {
            var diff = ((_b = (_a = containerEl.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0) -
                ((_d = (_c = tooltipEl.current) === null || _c === void 0 ? void 0 : _c.offsetWidth) !== null && _d !== void 0 ? _d : 0);
            if (diff < 0) {
                switch (align) {
                    case "left":
                        setStyle({ right: diff + "px" });
                        break;
                    case "right":
                        setStyle({ left: diff + "px" });
                        break;
                    default:
                        setStyle({
                            left: diff / 2 + "px",
                            right: diff / 2 + "px",
                        });
                }
            }
            else {
                setStyle(undefined);
            }
        }
    }, [containerEl, tooltipEl, align, direction]);
    return (_jsx("cite", __assign({ className: containerClass({ active: active, direction: direction }), style: style, ref: containerEl }, { children: _jsxs("p", __assign({ className: clsx([BASE_TOOLTIP, alignmentClass({ align: align })]), ref: tooltipEl }, { children: [_jsx("span", { children: label }, void 0), shortCut ? _jsxs("span", __assign({ className: SHORTCUT }, { children: ["\u00A0(", shortCut, ")"] }), void 0) : null] }), void 0) }), void 0));
};
