import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"
import moment from "moment"

/**
 * Ensures any supplied time is formatted as UTC time.
 *
 * @param  {Object} timeString  A string representing a time.
 * @return {Object}             A string of the date transformed to UTC.
 */
const applyUTC = (timeString) => moment.utc(timeString).utc().format()

/**
 * Ensures any times in the request body are transformed
 * to UTC time in order to have consistent communication
 * between the API and the client.
 *
 * @param  {Object} icon An object representing the icon data.
 * @return {Object}       A cloned object with the dates transformed to UTC.
 */
const applyUTCForIcon = (icon) => {
  const { startDate, ...rest } = icon
  return {
    startDate: applyUTC(startDate),
    ...rest,
  }
}

//
// GET / Find all OR by :calendar
//

/**
 * Action creator that generates an API call to fetch all icons
 * or a specific calendar.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the icons belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icons belongs to.
 * @return {Object}               An object representing the redux action.
 */
export const getIcons = (subdomain, calendarId) => ({
  type: types.FETCH_ICONS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ICONS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/icons/`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_ICONS_REQUEST,
      types.FETCH_ICONS_SUCCESS,
      types.FETCH_ICONS_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetch all icons
 * or a specific icon by ID. This is a convenience method that wraps
 * the getIcon() creator in a thunk.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the icon belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icon belongs to.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const requestIcons = (subdomain, calendarId) => (dispatch) => {
  return dispatch(getIcons(subdomain, calendarId))
}

//
// POST / New Icon
//

/**
 * Action creator that generates an API call to create a new icon
 * for a specific calendar.
 *
 * @param  {Integer} subdomain    The subdomain of the organization of which the calendar for the icon belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icon belongs to.
 * @param  {Object}  icon      Icon data or body for the API call.
 * @return {Object}               An object representing the redux action.
 */
export const postIcon = (subdomain, calendarId, icon) => ({
  type: types.CREATE_ICON,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "POST",
    schema: Schemas.ICON,
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/icons`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decamelizeKeys(applyUTCForIcon(icon))),
    types: [
      types.CREATE_ICON_REQUEST,
      types.CREATE_ICON_SUCCESS,
      types.CREATE_ICON_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to create a new icon
 * for a specific calendar. This is a convenience method that wraps
 * the postIcon() creator in a thunk.
 *
 * @param  {Integer} subdomain    The id of the calendar the icon belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icon belongs to.
 * @param  {Object}  icon      Icon data or body for the API call.
 * @return {Promise}            A promise representing the dispatched redux action creator.
 */
export const createIcon = (subdomain, calendarId, icon) => (dispatch) => {
  return dispatch(postIcon(subdomain, calendarId, icon))
}

//
// DELETE / Delete existing Icon via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * icon against the API.
 *
 * @param  {Integer} subdomain    The id of the calendar the icon belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icon belongs to.
 * @param  {Integer} iconUuid    The id of the icon to delete.
 * @return {Promise}          An object representing the redux action.
 */
export const deleteIcon = (subdomain, calendarId, iconUuid) => ({
  type: types.DELETE_ICON,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.ICON,
    method: "DELETE",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/icons/${iconUuid}`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.DELETE_ICON_REQUEST,
      types.DELETE_ICON_SUCCESS,
      types.DELETE_ICON_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * icon against the API. This is a convenience method that wraps
 * the deleteIcon() creator in a thunk.
 *
 * @param  {Integer} subdomain    The id of the calendar the icon belongs to.
 * @param  {Integer} calendarId   The id of the calendar the icon belongs to.
 * @param  {String}  iconUuid     The id of the icon to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const removeIcon = (subdomain, calendarId, iconUuid) => (dispatch) => {
  return dispatch(deleteIcon(subdomain, calendarId, iconUuid))
}
