import React from "react"
import { Redirect, Route, Switch } from "react-router"
import { App, ModalContainer } from "../containers"
import { NotFound } from "../shared"
import { PrivateRoute, UnauthenticatedRoute } from "../login"
import { useTracking } from "../utils"
import { ResetPassword } from "../resetPassword"
import { AppNavigator } from "../appNavigator"
import {
  OrganizationDetail,
  OrganizationBlankSlate,
} from "../organizationDetailView"
import { ReportDetailView, ReportBlankSlateView } from "../reportDetailView"
import { Calendar, PrintableCalendar } from "../calendarView"
import {
  CalendarDetail,
  CalendarBlankSlate,
  CalendarImportView,
} from "../calendarDetailView"
import { InvitationDetail, InvitationLoader } from "../invitationDetailView"
import { SuperAdminDashboard } from "../SuperAdminDashboard"
import { LoginModal, ForgotModal, SignupModal } from "../modals"
import { OnboardFlowAccount, OnboardFlowCompany } from "../onboard"

const Routes = () => {
  useTracking()
  return (
    <Route path="/">
      <App>
        <Switch>
          <Route path="/invitation" component={InvitationLoader} />
          <Route path="/reset-password" component={ResetPassword} />
          <PrivateRoute
            path="/onboard/company"
            component={OnboardFlowCompany}
          />
          <Route path="/onboard" component={OnboardFlowAccount} />
          <UnauthenticatedRoute path="/login">
            <ModalContainer>
              <Switch>
                <Route path="/login/forgot" component={ForgotModal} />
                <Route path="/login" exact={true} component={LoginModal} />
                <Route path="/login/register" component={SignupModal} />
              </Switch>
            </ModalContainer>
          </UnauthenticatedRoute>
          <PrivateRoute path="/admin">
            <AppNavigator>
              <Switch>
                <Route path="/admin" exact={true}>
                  <SuperAdminDashboard />
                </Route>
                <Route path="/admin/dashboard/:days">
                  <SuperAdminDashboard />
                </Route>
              </Switch>
            </AppNavigator>
          </PrivateRoute>
          <PrivateRoute path="/org">
            <AppNavigator>
              <Switch>
                <Route
                  path="/org"
                  exact={true}
                  component={OrganizationBlankSlate}
                />
                <Route path="/org/new" component={OrganizationDetail} />
                <Route
                  path="/org/:subdomain/settings"
                  component={OrganizationDetail}
                />
                <Route
                  path="/org/:subdomain/cal/"
                  exact={true}
                  component={CalendarBlankSlate}
                />
                <Route
                  path="/org/:subdomain/cal/new"
                  component={CalendarDetail}
                />
                <Route
                  path="/org/:subdomain/cal/import"
                  component={CalendarImportView}
                />
                <Route
                  path="/org/:subdomain/groups/:groupId"
                  component={CalendarDetail}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/print/start/:startDate/end/:endDate"
                  component={PrintableCalendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/snapshot/:snapshotId/print/start/:startDate/end/:endDate"
                  component={PrintableCalendar}
                />
                <Route
                  path="/org/:subdomain/reports/:reportId/print/start/:startDate/end/:endDate"
                  component={PrintableCalendar}
                />
                <Route
                  path="/org/:subdomain/reports/:reportId/start/:startDate/view/:view/"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/snapshot/:snapshotId/start/:startDate/end/:endDate/:weeks"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/start/:startDate/end/:endDate/"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/start/:startDate/view/:view/"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/snapshot/:snapshotId/start/:startDate/view/:view/"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/snapshot/:snapshotId"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId/settings"
                  component={CalendarDetail}
                />
                <Route
                  path="/org/:subdomain/cal/:calendarId"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/invite/new"
                  component={InvitationDetail}
                />
                <Route
                  path="/org/:subdomain/invite/:invitationId/settings"
                  component={InvitationDetail}
                />
                <Route
                  path="/org/:subdomain/reports/"
                  exact={true}
                  component={ReportBlankSlateView}
                />
                <Route
                  path="/org/:subdomain/reports/new"
                  component={ReportDetailView}
                />
                <Route
                  path="/org/:subdomain/reports/:reportId/start/:startDate/end/:endDate"
                  component={Calendar}
                />
                <Route
                  path="/org/:subdomain/reports/:reportId/settings"
                  component={ReportDetailView}
                />
                <Route
                  path="/org/:subdomain/reports/:reportId"
                  component={Calendar}
                />
              </Switch>
            </AppNavigator>
          </PrivateRoute>
          <Redirect path="/" to="/login" />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </App>
    </Route>
  )
}

export default Routes
