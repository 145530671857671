import { updateDatesForEventsWithIDs } from "./updateDatesForEventsWithIDs"
import moment from "moment"
import {
  adjustEventsWithOffset,
  eventSelector,
  requestCategory,
} from "../../api"
import { clipboardOrigin } from "../selectors"
import { ClipboardMode } from "../constants"
import { updateEvents } from "./updateEvents"
import { calendarSelector } from "../../api/selectors"
import { optimisticDateRangeUpdate } from "../../api/actions/events"

/**
 * Applies the events in the clipboard via the specified mode. If the mode is
 * set to ClipboardMode.COPY the events will be duplicated. If the mode is set to
 * ClipboardMode.CUT the events will be shifted.
 *
 * @param {Array} eventUuids An array of IDs for the affected events.
 * @param {Number} calendarId The id of the calendar the dates belong to.
 * @param {Object} date  A momentJS object representing the new target date.
 * @param {String} mode  A `ClipboardMode` determining whether to duplicate or shift the events.
 * @param {Boolean} increment  If true the prefix/suffix of the event will be incremented if detected. (only on copy and paste)
 * @return {Object} An FSA action.
 */
export const pasteEventsToDate =
  (eventUuids, calendarId, date, mode, increment) => (dispatch, getState) => {
    const state = getState()
    const sourceCalendarId = clipboardOrigin(state)
    const find = eventSelector.find(state)
    const events = eventUuids.map((id) => find(id, sourceCalendarId))
    const originDate = events
      .map((e) => moment.utc(e.startsAt))
      .sort((a, b) => a.diff(b))[0]
    const newOrigin = sourceCalendarId !== calendarId
    if (originDate) {
      const offset = moment
        .utc(moment(date).utc().startOf("day"))
        .diff(moment.utc(originDate.startOf("day")), "days")
      if (mode === ClipboardMode.COPY || newOrigin) {
        return dispatch(
          adjustEventsWithOffset(
            eventUuids,
            calendarId,
            sourceCalendarId,
            offset,
            null,
            true,
            increment
          )
        ).then(() => {
          // If the origin is different refresh the categories...
          if (newOrigin) {
            const newCalendar = calendarSelector.find(state)(calendarId)
            dispatch(requestCategory(newCalendar.organization, calendarId))
          }
          dispatch(optimisticDateRangeUpdate(calendarId))
        })
      } else {
        dispatch(
          updateEvents(
            updateDatesForEventsWithIDs(
              state,
              eventUuids,
              calendarId,
              offset,
              true
            ),
            `cutAndPaste[${eventUuids.join("_")}]`
          )
        ).then(() => dispatch(optimisticDateRangeUpdate(calendarId)))
        return dispatch(
          adjustEventsWithOffset(
            eventUuids,
            calendarId,
            sourceCalendarId,
            offset,
            null,
            false
          )
        )
      }
    }
    return Promise.resolve()
  }
