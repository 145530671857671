import React, { useState } from "react"
import {
  RetainableModal,
  ModalProps,
  useRetainedModalToggle,
  EventForm,
  EventFormValues,
  AsyncSubmitHandler,
} from "ui"
import { DateTime } from "luxon"
import toast from "react-hot-toast"
import {
  createItem,
  updateItem,
  useItem,
  useCategories,
  useStyles,
  createCategory,
  useCalendar,
  useTags,
} from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"
import { v4 } from "uuid"

const MODAL_NAME = "ITEM_MODAL"

export interface ItemModalProps {
  itemUuid?: string
  defaultDate?: string
  onEditCategory?: (uuid?: string) => void
  onEditTheme?: () => void
  onEditStyle?: (uuid?: string) => void
  darkDays?: string[]
}

export const ItemModal: React.FC<ItemModalProps & ModalProps> = ({
  onClose: handleDismiss,
  itemUuid,
  defaultDate,
  onEditCategory: handleEditCategory,
  onEditTheme: handleEditTheme,
  onFocus: handleFocus,
  onEditStyle: handleEditStyle,
  zIndex,
  darkDays = [],
}) => {
  const [loading, setLoading] = useState(false)
  const params = useParams<{ subdomain: string; calendarId: string }>()

  const {
    loading: loadingItem,
    item,
    event,
  } = useItem(params.subdomain, params.calendarId, itemUuid)

  const { tags: availableTags, refetch } = useTags(
    params.subdomain,
    params.calendarId
  )

  const { loading: loadingCategories, categories } = useCategories(
    params.subdomain,
    params.calendarId
  )

  const { loading: loadingCalendar } = useCalendar(
    params.subdomain,
    params.calendarId
  )

  const { styles } = useStyles(params.subdomain, params.calendarId, [
    item?.styleUuid,
    ...(categories?.map((c: any) => c.styleUuid) ?? []),
  ])
  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<EventFormValues> = async (data) => {
    const { startsAt, length, notes, ...itemData } = data
    setLoading(true)
    await wait(1)
    const values = {
      ...itemData,
      interruptedByHolidays: `${itemData.interruptedByHolidays}` === "1",
      interruptedByWeekends: `${itemData.interruptedByWeekends}` === "1",
      event: { startsAt, length, notes },
      occursOnCalendar: true,
      locked: item?.locked,
      active: item?.active,
      tags: data.tags?.split(",").map((t: string) => t.trim()),
    }
    try {
      const response = await dispatch(
        item
          ? updateItem(params.subdomain, params.calendarId, {
              ...values,
              uuid: itemUuid,
            })
          : createItem(params.subdomain, params.calendarId, values)
      )
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(
        `Successfully ${item ? "updated" : "created"} event "${data.name}".`
      )
      if (!keepOpen) {
        refetch()
        handleDismiss?.()
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return
  }

  const { keepOpen, toggleKeepOpen } = useRetainedModalToggle(MODAL_NAME)

  const defaultValues = {
    name: item?.name ?? "",
    length: event?.length ?? 1,
    startsAt: event?.startsAt
      ? DateTime.fromISO(event?.startsAt, { zone: "utc" }).toISODate()
      : defaultDate ?? DateTime.local().toISODate(),
    partition: item?.partition ?? 0,
    categoryUuid: item?.categoryUuid,
    interruptedByWeekends: `${item?.interruptedByWeekends ? "1" : "0"}`,
    interruptedByHolidays: `${item?.interruptedByHolidays ? "1" : "0"}`,
    styleUuid: item?.styleUuid,
    tags: item?.tags?.join(", "),
  }

  const handleCreateCategory = (name: string, styleUuid: string) => {
    const category = { uuid: v4(), name, styleUuid }
    dispatch(
      createCategory(params.subdomain, params.calendarId, {
        ...category,
      })
    )
    return category
  }

  return (
    <RetainableModal
      name="event"
      title={item ? "Edit Event" : "New Event"}
      onClose={handleDismiss}
      toggleKeepOpen={toggleKeepOpen}
      keepOpen={keepOpen}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <EventForm
        loading={loading || loadingItem || loadingCategories || loadingCalendar}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        categories={categories}
        styles={styles}
        onEditCategory={handleEditCategory}
        onEditTheme={handleEditTheme}
        onEditStyle={handleEditStyle}
        onCreateCategory={handleCreateCategory}
        darkDays={darkDays}
        availableTags={availableTags}
      />
    </RetainableModal>
  )
}
