import React, { Component } from "react"
import moment from "moment"
import { DateTime } from "luxon"
import { withRouter, matchPath } from "react-router-dom"
import queryString from "query-string"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import keydown from "react-keydown"
import debounce from "lodash/debounce"
import { CalendarSideBar, EventItemType, Dialog } from "ui"
import { ItemExplorer, ItemTypes as ExplorerTypes } from "../../itemExplorer"
import {
  ItemModal,
  CategoryModal,
  ThemeModal,
  PushPullModal,
  NoteModal,
  HolidayModal,
  SnapshotModal,
  BatchModal,
} from "../../modals"
import { Modal, Placeholder } from "../../shared"
import { isBlank, isNotBlank, isNotDefined } from "../../utils/helpers"
import { getItem, setItem } from "../../utils/tokenStore"
import { ReportSettingsModal } from "../../reportSettingsModalView"
import { ContextTypes, DATE_FORMAT, VIEW_MODE_NAMES } from "../constants"
import { Selectable } from "../../selectable"
import MonthView from "./MonthView"
import { RendererContainerView } from "./RendererContainerView"
import { CalendarContextMenu } from "./CalendarContextMenu"
import { CalendarAccessWidget } from "./CalendarAccessWidget"
import { CalendarSettingsWidget } from "./CalendarSettingsWidget"
import { CalendarHistoryWidget } from "./CalendarHistoryWidget"
import { GanntChartView } from "./GanntChartView"
import { WeekScrollView } from "./WeekScrollView"
import { flatten } from "lodash"
import { FixedDisclaimer } from "ui"
import {
  appendRecentCalendarId,
  setViewMode,
  getRecentViewMode,
} from "./session"
import { ConnectedToolBar } from "./ConnectedToolBar"
import { ConnectedSnapshotToolBar } from "./ConnectedSnapshotToolBar"
import { StyleModal } from "../../modals/StyleModal"
import toast from "react-hot-toast"
import clsx from "clsx"
import { CalendarDateListener } from "./CalendarDateListener"
import { AppManagementWidget } from "./AppManagementWidget"

const LAYOUT_ROOT_STYLES = "flex flex-col flex-grow h-screen lg:pb-6"
const CONTENT_SCROLL_STYLES =
  "flex flex-grow overflow-auto max-h-screen relative"
const OVERFLOW_CONTAINER_STYLES = "flex flex-row flex-grow overflow-hidden"
const SUPPORTED_VIEWS = [
  VIEW_MODE_NAMES.ONE_WEEK,
  VIEW_MODE_NAMES.ONE_MONTH,
  VIEW_MODE_NAMES.FOUR_MONTH,
  VIEW_MODE_NAMES.SIX_MONTH,
  VIEW_MODE_NAMES.ONE_YEAR,
  VIEW_MODE_NAMES.PROJECT,
]

const ITEM_MODAL = "item"
const BATCH_MODAL = "batch"
const THEME_MODAL = "theme"
const CATEGORY_MODAL = "category"
const STYLE_MODAL = "style"
const NOTE_MODAL = "note"
const HOLIDAY_MODAL = "holiday"
const PUSH_MODAL = "push"
const SNAPSHOT_MODAL = "snapshot"

/**
 * This component renders the general calendar UI as well as any specified
 * child renderer. A child renderer could be a MonthView, WeekView, or
 * MultiMonthView. This component is the parent component which provides any
 * related toolbars or other components necessary for navigating or updating
 * the calendar while the calendar itself is generated by any of the
 * aforementioned child components.
 *
 * NOTE: The CalendarView and it's subsequent child components in this module
 * could use a refactor down the road. I am hitting some road blocks in development
 * in which the content views of the calendar have several complex interactions
 * ands thus a LOT of props need to be passed down the chain to the actual
 * child components that need them. While there is no performance issues or
 * even necessarily any maintenance issues. It seems to me this makes the logic
 * of actually rendering the calendar less pure and more difficult to understand
 * and as such I feel a refactor should be considered in the future if time permits.
 */
class CalendarView extends Component {
  constructor(props) {
    super(props)
    // Store the callback in state so that it is bound
    // but referenceable for the event subscription.
    this.state = {
      resetKeyCallback: (e) => this.resetModifierStatus(e.keyCode),
      resetKeyCallbackViaMouse: (e) => this.resetModifierStatusFromMouse(e),
      onScrollFinished: debounce(() => this.snapToNearestWeek(), 500),
      anchorDate: null,
      modifier: "",
      confirmDelete: false,
      requestingDownload: false,
      pollingTimeout: null,
      secondaryAction: null,
      itemToEdit: null,
      categoryToEdit: null,
      pushPullAnchor: null,
      pushPullAction: null,
      pushPullFilters: null,
      createHolidayDate: null,
      holidayToEdit: null,
      reportSettingsVisible: false,
      explorerSection: "EXPLORER",
      useScrolling: false,
      scrollCalendarIndex: 0,
      lastLayoutChange: 0,
      modals: [],
    }
  }

  componentWillMount() {
    const { match } = this.props
    if (match.params.reportId) {
      this.populateReport()
    } else {
      this.populateCalendar()
    }
  }

  componentDidMount() {
    window.addEventListener("keyup", this.state.resetKeyCallback)
    window.addEventListener("mouseout", this.state.resetKeyCallbackViaMouse)
  }

  componentWillReceiveProps(nextProps) {
    const {
      assumeBeneathModal,
      keydown: { event },
      match,
    } = nextProps
    if (!assumeBeneathModal && event && event !== this.props.keydown.event) {
      const keyCode = event.keyCode
      this.actOnModifier(keyCode)
      this.actOnKey(keyCode)
    }
    if (this.props.match.params.calendarId !== match.params.calendarId) {
      this.clearPollingTimeout()
    }
  }

  clearPollingTimeout() {
    this.state.pollingTimeout && clearTimeout(this.state.pollingTimeout)
    this.setState({ pollingTimeout: null })
  }

  shouldComponentUpdate(nextProps) {
    const { assumeBeneathModal } = nextProps
    return !assumeBeneathModal
  }

  getMatchWithDates() {
    const { params } = this.props.match
    if (!isNotDefined(params.startDate) && !isBlank(params.startDate)) {
      return this.props.match
    }
    const match = matchPath(window.location.pathname, {
      path: "/org/:subdomain/cal/:calendarId/snapshot/:snapshotId/start/:startDate/view/:view",
    })
    return match || { params: {} }
  }

  shouldGetDefaultDate() {
    const match = this.getMatchWithDates()
    return (
      isNotDefined(match.params.startDate) || isBlank(match.params.startDate)
    )
  }

  componentDidUpdate(prevProps) {
    if (this.shouldRefreshReport(prevProps)) {
      this.populateReport()
    } else if (this.shouldRefreshCalendar(prevProps)) {
      this.populateCalendar()
    } else if (this.shouldLoadReportCalendars(prevProps)) {
      this.populateReportCalendars()
    } else if (this.shouldGetDefaultDate()) {
      this.sendToDefaultDates()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.state.resetKeyCallback)
    window.removeEventListener("mouseout", this.state.resetKeyCallbackViaMouse)
    this.clearPollingTimeout()
  }

  updateLastRender() {
    this.setState({ lastLayoutChange: new Date().getTime() })
  }

  /**
   * Checks to see if the report has changed from one set of
   * props in comparison to the present.
   *
   * @param {Object} otherProps The props for this component in a prior or future state.
   * @returns {Boolean} True if the report has changed.
   */
  shouldRefreshReport(otherProps) {
    return otherProps.match.params.reportId !== this.props.match.params.reportId
  }

  /**
   * Checks to see if the calendar or snapshot has changed from one set of
   * props in comparison to the present.
   *
   * @param {Object} otherProps The props for this component in a prior or future state.
   * @returns {Boolean} True if the calendar/snapshot has changed.
   */
  shouldRefreshCalendar(otherProps) {
    const { match } = this.props
    const { calendarId, snapshotId } = match.params
    return (
      otherProps.match.params.calendarId !== calendarId ||
      otherProps.match.params.snapshotId !== snapshotId
    )
  }

  /**
   * Checks to see if the calendarIds for a report have changed from a
   * set of prior props in comparison to the present.
   *
   * @param {Object} otherProps The props for this component in a prior or future state.
   * @returns {Boolean} True if the calendar/snapshot has changed.
   */
  shouldLoadReportCalendars(otherProps) {
    const { findReportCalendarIds, match } = this.props
    const { reportId } = match.params
    const currentIds = findReportCalendarIds(reportId).join(",")
    const otherIds = otherProps
      .findReportCalendarIds(otherProps.match.params.reportId)
      .join(",")
    return currentIds !== otherIds
  }

  /**
   * A convenience method to determine if the view should render in read-only
   * mode based on permissions or the current form of data supplied to the
   * calendar.
   *
   * @returns {Boolean} True if the calendar view should render as read-only.
   */
  readOnly() {
    const { match, canWrite } = this.props
    const { snapshotId, subdomain, calendarId, reportId } = match.params
    const { isPending } = this.state
    const isSnapshot = parseInt(snapshotId, 0) > 0
    const isReport = parseInt(reportId, 0) > 0

    const org = this.getOrganization()
    const trialExpired =
      org &&
      org.isTrial &&
      Math.round(
        DateTime.fromISO(org.trialEndsAt).diffNow("days").as("days")
      ) <= 0

    return (
      trialExpired ||
      isPending ||
      isReport ||
      isSnapshot ||
      !canWrite(subdomain, calendarId)
    )
  }

  /**
   * A convenience method to determine if the viewer has readonly access due to
   * their permissions irregardless of the content type being rendered on page.
   *
   * @returns {Boolean} True if the calendar view should render as read-only.
   */
  readOnlyViaPermission() {
    const { canWrite, match } = this.props
    if (match && canWrite) {
      const { subdomain, calendarId } = match.params
      return calendarId && !canWrite(subdomain, calendarId)
    }
    return false
  }

  /**
   * Updates the current state to the supported modifier if it is included in
   * the modifier whitelist.
   *
   * @param {Integer|String} keyCode A keycode extracted from a DOM keyboard event.
   */
  actOnModifier(keyCode) {
    const { setMultiselect } = this.props
    setMultiselect(["Meta", "Control", 91, 71, 17].includes(keyCode))
    if (["Meta", "Control", 91, 71, 17, "Shift", 16].includes(keyCode)) {
      this.setState({ modifier: keyCode })
      setMultiselect(true)
    }
  }

  /* eslint-disable complexity */
  /**
   * Defines the proper next action to take upon receiving a
   * keypress.
   *
   * @param {String} keyCode    The identifier for the key that triggered a keyboard event.
   */
  actOnKey(keyCode) {
    const {
      confirmDelete,
      pushPullAction,
      itemToEdit,
      categoryToEdit,
      holidayToEdit,
    } = this.state
    const { selectedDate } = this.props
    if (pushPullAction || itemToEdit || categoryToEdit || holidayToEdit) {
      // ignore keyboard events if modal is open...
      return
    }
    switch (keyCode) {
      case 68: // d
      case 8: // Backspace
        !this.readOnly() && this.deleteSelectedItems()
        break
      case 13: // Enter
      case 69: // e
        confirmDelete ? this.handleConfirm() : this.editSelectedItem()
        break
      case 67: // c
        this.isHoldingControlKey() && this.copySelectedEvents()
        break
      case 88: // x
        this.isHoldingControlKey() && this.cutSelectedEvents()
        break
      case 73: // i
        this.isHoldingControlKey() &&
          selectedDate &&
          this.pasteEvents(selectedDate, true)
        break
      case 86: // v
        this.isHoldingControlKey() &&
          selectedDate &&
          this.pasteEvents(selectedDate)
        break
      case 27: // esc
        this.clearClipboardAndSelection()
        break
      case 32: //space
        this.handleTodayClick()
        break
      case 37:
        this.handlePrevClick()
        break
      case 38: //up
        this.handleUpClick()
        break
      case 39:
        this.handleNextClick()
        break
      case 40: //down
        this.handleDownClick()
        break
      case 220:
        this.isHoldingShiftKey() && this.toggleItemExplorer()
        break
      default:
        break
    }
  }
  /* eslint-enable complexity */

  /**
   * Clears the modifier status from the state of this component.
   *
   * @param {Integer} keyCode The keycode to check in order to ensure we are resetting the modifier.
   */
  resetModifierStatus(keyCode) {
    const { modifier } = this.state
    if (keyCode === modifier) {
      this.setState({ modifier: "" })
      this.props.setMultiselect(false)
    }
  }

  /**
   * Clears the modifier key if the user has left the window.
   *
   * @param {Event} event   A DOM mouse event.
   */
  resetModifierStatusFromMouse(event) {
    const { modifier } = this.state
    const from = event.relatedTarget || event.toElement
    if (!from || from.nodeName === "HTML") {
      this.resetModifierStatus(modifier)
    }
  }

  /**
   * Determines the current view mode based on the calculated
   * number of applicable months / weeks for the period.
   *
   * @return {String} A value from the VIEW_MODE_NAMES constant.
   */
  getCurrentModeName() {
    const { match } = this.props
    const view = match.params.view
    const validMode = SUPPORTED_VIEWS.includes(view)
    return validMode ? view : VIEW_MODE_NAMES.ONE_MONTH
  }

  incrementScrollIndex(increment) {
    const index = parseInt(this.props.match.params.weeks || "0", 0)
    this.updateStartDateInPath(index + increment)
  }

  resetScrollIndex() {
    this.updateStartDateInPath(0)
  }

  updateStartDateInPath(date, newMode) {
    const { history } = this.props
    const baseUrl = this.getBasePath()
    history.replace(
      `${baseUrl}/start/${date}/view/${newMode || this.getCurrentModeName()}`
    )
  }

  getViewType() {
    if (this.getCurrentModeName() === VIEW_MODE_NAMES.PROJECT) {
      return "GANNT"
    } else {
      return "CALENDAR"
    }
  }

  /**
   * Calculates the amount of weeks to render in any embedded
   * MonthView component based on the view mode.
   *
   * @return {Integer} The number of weeks to render in embedded MonthViews.
   */
  getWeeksToDisplay() {
    switch (this.getCurrentModeName()) {
      case VIEW_MODE_NAMES.ONE_YEAR:
        return 6
      case VIEW_MODE_NAMES.SIX_MONTH:
        return 6
      case VIEW_MODE_NAMES.FOUR_MONTH:
        return 6
      case VIEW_MODE_NAMES.ONE_WEEK:
        return 2
      default:
        return 6
    }
  }

  /**
   * Calculates the best fit display template based on the time difference in
   * the URL.
   *
   * @return {Integer} The number of months to display in the current view.
   */
  getMonthsToDisplay() {
    switch (this.getCurrentModeName()) {
      case VIEW_MODE_NAMES.ONE_YEAR:
        return 12
      case VIEW_MODE_NAMES.SIX_MONTH:
        return 6
      case VIEW_MODE_NAMES.FOUR_MONTH:
        return 4
      case VIEW_MODE_NAMES.TWO_WEEK:
        return 0.5
      case VIEW_MODE_NAMES.ONE_WEEK:
        return 0.25
      default:
        return 1
    }
  }

  /**
   * Calculates the amount of rows to display for the amount
   * of months to be rendered. This is rows of actual MonthView
   * components.
   *
   * @param {Integer} months  The number of months to use for a row calculation.
   * @return {Integer}        The rows of months to render on the calendar.
   */
  /* eslint-disable complexity */
  getRowsToDisplay(months) {
    switch (months) {
      case 1:
        return 1
      case 2:
        return 2
      case 4:
      case 6:
        return 2
      case 12:
        return 3
      default:
        return 1
    }
  }
  /* eslint-enable complexity */

  getColsToDisplay(months) {
    return months / this.getMonthsToDisplay(months)
  }

  /**
   * Retrieves the start date supplied from ReactRouter.
   *
   * @return {Object} A date wrapped in a moment.utc() object.
   */
  getStartMoment() {
    const match = this.getMatchWithDates()
    return moment.utc(match.params.startDate, DATE_FORMAT)
  }

  /**
   * Checks to see if a date range has been specified.
   *
   * @return {Boolean} True if a date has been specified in the route.
   */
  hasStartMoment() {
    const match = this.getMatchWithDates()
    return isNotBlank(match.params.startDate)
  }

  /**
   * Checks to see if the specified calendar is pending or not.
   *
   * @return {Boolean} True if a date has been specified in the route.
   */
  isPending() {
    return this.state.pending
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateReport() {
    const { history, requestReport, loadCalendar, match } = this.props
    const { subdomain, reportId } = match.params
    if (!reportId) {
      return
    }
    requestReport(subdomain, reportId).then((action) => {
      if (action.error) {
        console.error("Could not request report...")
        history.replace("/404")
      }
    })
    loadCalendar(subdomain)
    this.populateReportCalendars(subdomain, reportId)
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateReportCalendars() {
    const { requestFullReportCalendars, match } = this.props
    const { subdomain, reportId } = match.params
    requestFullReportCalendars(subdomain, reportId)
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateCalendar() {
    const {
      requestSnapshot,
      loadCalendar,
      requestCurrentUser,
      isAdmin,
      match,
      history,
    } = this.props
    const { subdomain, calendarId, snapshotId } = match.params
    const loadAction = snapshotId ? requestSnapshot : loadCalendar
    appendRecentCalendarId(calendarId)
    loadAction(subdomain, calendarId, snapshotId).then((action) => {
      if (action.error) {
        history.replace("/404")
      } else {
        setItem(`subdomain`, subdomain)
        setItem(`${subdomain}CalendarId`, calendarId)
        const pending = this.hasPendingEntity()
        this.setState({ pending })
        if (pending) {
          const timeout = setTimeout(() => this.populateCalendar(), 5000)
          this.setState({ pollingTimeout: timeout })
        }
        if (!isAdmin(subdomain)) {
          requestCurrentUser()
        }
        this.sendToDefaultDates()
      }
    })
  }

  /**
   * Determines if the target model (a snapshot or calendar) is
   * currently available and/or pending.
   *
   * @returns {Boolean} True if the current model is not stored in state or is pending.
   */
  hasPendingEntity() {
    const targetEntity = this.props.match.params.snapshotId
      ? this.getSnapshot()
      : this.getCalendar()
    const pending = !targetEntity || targetEntity.pending
    return pending
  }

  /**
   * Uses the current calendar based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current calendar.
   */
  getCalendar() {
    const { findCalendar, match } = this.props
    return findCalendar(match.params.calendarId)
  }

  /**
   * Uses the current organization based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current organization.
   */
  getOrganization() {
    const { findOrganization, match } = this.props
    return findOrganization(match.params.subdomain)
  }

  /**
   * Uses the current snapshot based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current snapshot.
   */
  getSnapshot() {
    const { findSnapshot, match } = this.props
    return findSnapshot(match.params.snapshotId)
  }

  /**
   * Uses the current report based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current report.
   */
  getReport() {
    const { findReport, match } = this.props
    return findReport(match.params.reportId)
  }

  /**
   * If no dates were specified for the calendar, this method redirects
   * the user to a set of default dates that will work.
   */
  sendToDefaultDates() {
    const { history, match } = this.props
    if (isBlank(match.params.startDate)) {
      const mode = getRecentViewMode() ?? "1M"
      const monthStart =
        getItem("startDate") ||
        moment.utc().startOf("month").format(DATE_FORMAT)
      history.replace(
        `${this.getBasePath()}/start/${monthStart}/view/${
          mode.length > 0 ? mode : "1M"
        }`
      )
    }
  }

  /**
   * Returns the beginning of the month for the earliest event on the
   * current calendar.
   * @returns {Object} A moment.js object representing the start of the earliest event's month.
   */
  getStartDate() {
    const events = this.findEventsForView()
    if (!events || events.length < 1) {
      return moment().startOf("month")
    }
    const earliestDate = events
      .map((e) => moment.utc(e.startsAt))
      .sort((a, b) => (a.isBefore(b) ? -1 : 1))[0]
    return earliestDate.startOf("month")
  }

  /**
   * Returns the end of the month for the latest event on the
   * current calendar.
   * @returns {Object} A moment.js object representing the end of the latest event's month.
   */
  getEndDate() {
    const events = this.findEventsForView()
    if (!events || events.length < 1) {
      return moment().endOf("month")
    }
    const latestDate = events
      .map((e) => moment.utc(e.endsAt))
      .sort((a, b) => (a.isAfter(b) ? -1 : 1))[0]
    return latestDate.endOf("month")
  }

  /**
   * Determines if a supported modiier key is currently being pressed
   * by the user to support the selection of multiple items.
   *
   * @return {Boolean} True if multi-selection is engaged.
   */
  isHoldingControlKey() {
    const { multiselectEnabled } = this.props
    return multiselectEnabled
  }

  /**
   * Determines if a supported modiier key is currently being pressed
   * by the user to support the selection of multiple items.
   *
   * @return {Boolean} True if multi-selection is engaged.
   */
  isHoldingShiftKey() {
    const { modifier } = this.state
    return ["Shift", 16].includes(modifier)
  }

  /**
   * Passes a selection action up to the redux store - performs any
   * edge case logic per calendar behavior prior if necessary.
   * @param {String} selectableType   A string representing the type of item being selected.
   * @param {Integer} id              The id of the selected item.
   */
  handleSelectedItem(selectableType, id, calendarId) {
    const { selectItem } = this.props
    const append = this.isHoldingControlKey()
    selectItem(selectableType, id, calendarId, append)
    this.props.selectDate(null)
  }

  /**
   * Deletes an item that was selected by the user.
   * @param {String} selectableType The type of entity that will be deleted.
   * @param {String} id The id of the entity to be deleted.
   */
  handleDeleteItem(selectableType, id) {
    const { match } = this.props
    const itemNames = {
      [Selectable.EVENT]: ["event", "events"],
      [Selectable.CATEGORY]: ["category", "categories"],
      [Selectable.ITEM]: ["event", "events"],
    }
    const isPlural = typeof id !== "string" && id.length > 1
    const itemName = itemNames[selectableType][isPlural ? 1 : 0]
    const removeAction = this.removeActionForItemType(selectableType)
    this.setState({
      confirmDelete: true,
      confirmMessage: `Are you sure you want to delete ${
        isPlural ? id.length : "this"
      } ${itemName}?`,
      confirmAction: () => {
        removeAction(
          match.params.subdomain,
          match.params.calendarId,
          [id].flat()
        )
        this.confirmCancel()
      },
    })
  }

  /**
   * Pushes the browser navigation to a route for the specified snapshot.
   * @param {String} snapshotId The id of the snapshot that will be navigated to.
   */
  handleSelectedSnapshot(snapshotId) {
    const { history, match } = this.props
    const { subdomain, calendarId, startDate, endDate } = match.params
    history.push(
      `/org/${subdomain}/cal/${calendarId}${
        snapshotId ? `/snapshot/${snapshotId}` : ""
      }/start/${startDate}/end/${endDate}`
    )
  }

  /**
   * Resets any selections made by the user.
   */
  handleEmptyAreaClick() {
    const { hasSelection, resetSelection } = this.props
    if (this.getViewType() !== "GANNT") {
      hasSelection && resetSelection()
    }
  }

  /**
   * Resets any selected entities on the calendar if an entity was
   * that was not currently selected had just been clicked on and
   * a multi-select modifier was not in effect (i.e. holding CTRL-key).
   *
   * @param {Bool} selected The selected state of the current entity.
   */
  handlePossibleSelectionClearance(selected) {
    const { hasSelection, resetSelection } = this.props
    if (!this.isHoldingControlKey() && !selected) {
      hasSelection && resetSelection()
    }
  }

  /**
   * Obtains the id of the item most recently selected
   * by the user. This is typically the default item used
   * to take action based on keyboard output.
   *
   * @return {Integer} The id of the most recently selected item or null.
   */
  mostRecentitemUuid() {
    const { lastSelectedType, selectedItems } = this.props
    const itemUuids = selectedItems[lastSelectedType]
    if (itemUuids && itemUuids.length > 0) {
      return itemUuids[itemUuids.length - 1]
    }
    return null
  }

  /**
   * Presents the report settings modal.
   */
  showReportSettings() {
    this.setState({ reportSettingsVisible: true })
  }

  /**
   * Presents a modal prompt to edit the most recently selected
   * item if one exists.
   */
  editSelectedItem() {
    const { lastSelectedType } = this.props
    const mostRecentId = this.mostRecentitemUuid()
    if (mostRecentId) {
      switch (lastSelectedType) {
        case Selectable.ITEM: {
          this.displayModal(ITEM_MODAL, { itemToEdit: mostRecentId })
          break
        }
        case Selectable.EVENT: {
          const item = this.itemForEvent(mostRecentId)
          this.displayModal(ITEM_MODAL, { itemToEdit: item.uuid })
          break
        }
        case Selectable.CATEGORY: {
          this.handleEditCategory(mostRecentId)
          break
        }
        default:
          break
      }
    }
  }

  /**
   * Presents a modal view to destroy any selected items of
   * the most recent type.
   */
  deleteSelectedItems() {
    const { lastSelectedType, selectedItems, match } = this.props
    const { subdomain, calendarId } = match.params
    const itemUuids = selectedItems[lastSelectedType]
    if (!itemUuids || itemUuids.length < 1) {
      return
    }
    const itemNames = {
      [Selectable.EVENT]: ["event", "events"],
      [Selectable.CATEGORY]: ["category", "categories"],
      [Selectable.ITEM]: ["event", "events"],
    }

    const itemName = itemNames[lastSelectedType][itemUuids.length > 1 ? 1 : 0]
    const removeAction = this.removeActionForItemType(lastSelectedType)

    const isEvent = lastSelectedType === Selectable.EVENT
    const secondaryAction = isEvent
      ? {
          name: "Keep in Sidebar",
          action: () => {
            removeAction(subdomain, calendarId, itemUuids, false)
            this.confirmCancel()
          },
        }
      : null

    this.setState({
      confirmDelete: true,
      confirmMessage: `Are you sure you want to delete ${itemUuids.length} ${itemName}?`,
      secondaryAction,
      confirmAction: () => {
        removeAction(subdomain, calendarId, itemUuids, true)
        this.confirmCancel()
      },
    })
  }

  /**
   * Accepts a selectable type and returns the appropriate
   * remove action to delete that type of item from the API.
   *
   * @param {String} selectableType   This should be a key from the Selectable constant.
   * @return {Function}               A redux action creator for removing a group of entities.
   */
  removeActionForItemType(selectableType) {
    const { removeCategories, removeEvents, removeItems } = this.props
    switch (selectableType) {
      case Selectable.EVENT:
        return removeEvents
      case Selectable.CATEGORY:
        return removeCategories
      case Selectable.ITEM:
        return removeItems
      default:
        return null
    }
  }

  /**
   * A convenience method for calling the current confirm
   * action. Primarily if user is using a keyboard only
   * navigation technique.
   */
  handleConfirm() {
    const { confirmAction } = this.state
    confirmAction && confirmAction()
  }

  /**
   * Requests a secure download token to access a CSV dump of the calendar.
   */
  downloadCalendarCSV() {
    const { downloadCalendar, match } = this.props
    const { subdomain, calendarId } = match.params
    this.setState({ requestingDownload: true })
    downloadCalendar(subdomain, calendarId).then((action) => {
      const url = action && action.payload && action.payload.downloadUrl
      this.setState({ requestingDownload: false })
      if (url) {
        window.location = url
      }
    })
  }

  /**
   * Sends the user to a printable layout for the calendar view.
   */
  gotoPrintView() {
    const { history, match } = this.props
    const { subdomain, calendarId, reportId, snapshotId } = match.params
    const calendar = this.getCalendar()
    const viewStyle = this.getMonthsToDisplay() > 6 ? "annual" : "monthly"
    const datePathParams = `start/${this.getStartDate().format(
      DATE_FORMAT
    )}/end/${this.getEndDate().format(DATE_FORMAT)}`
    reportId
      ? history.push(
          `/org/${subdomain}/reports/${reportId}/print/${datePathParams}?viewStyle=${viewStyle}`
        )
      : snapshotId
      ? history.push(
          `/org/${subdomain}/cal/${calendarId}/snapshot/${snapshotId}/print/${datePathParams}?${queryString.stringify(
            { ...calendar.printSettings, viewStyle }
          )}`
        )
      : history.push(
          `/org/${subdomain}/cal/${calendarId}/print/${datePathParams}?${queryString.stringify(
            { ...calendar.printSettings, viewStyle }
          )}`
        )
  }

  /**
   * Clears the selected date if it is now out of range.
   *
   * @param {String} start The start date to validate against.
   */
  clearSelectedDateIfOutOfRange(start) {
    const { selectedDate, selectDate } = this.props
    const end = moment(start).add(this.getMonthsToDisplay(), "months")
    selectedDate &&
      (moment.utc(selectedDate).isBefore(start) ||
        moment.utc(selectedDate).isAfter(end)) &&
      selectDate(null)
  }

  /**
   * Pushes the user to the appropriate date range for an
   * alternative view mode.
   *
   * @param {Integer} quantity The amount of time to display in the view.
   * @param {String} newMode The name of the view mode to use.
   */
  handleViewSelection(newMode, startMoment) {
    setViewMode(newMode)
    let referenceMoment = moment.utc(startMoment || this.getStartMoment())
    if (!referenceMoment.isValid()) {
      referenceMoment = moment().utc()
    }
    let start = moment.utc(referenceMoment).format(DATE_FORMAT)
    this.updateStartDateInPath(start, newMode)
  }

  /**
   * Pushes the user to the appropriate push / pull route based on the
   * supplied axction and the current selected date.
   *
   * @param {String} pushPullAction The amount of time to display in the view.
   */
  handlePushPullRequest(pushPullAction) {
    const { selectedItems, findEvent, findItem, match } = this.props
    const { calendarId } = match.params
    const selectedEvents = selectedItems[Selectable.EVENT]
    const events = selectedEvents
      .map((uuid) => findEvent(uuid, calendarId))
      .filter((e) => e)
    const items = events.map((event) => findItem(event.itemUuid, calendarId))
    const pushPullFilters = items.map((i) => `item#${i.uuid}`).join(" ")

    this.displayModal(PUSH_MODAL, {
      pushPullAction,
      pushPullFilters,
    })
  }

  /**
   * Pushes the user to a route that will display the new snapshot
   * modal.
   */
  handleCreateSnapshotRequest(id) {
    this.displayModal(SNAPSHOT_MODAL, {
      createSnapshot: true,
      specificSnapshotId: id,
    })
  }

  /**
   * Moves the currently selected item up towards the top
   * of the day in the priority field.
   */
  handleUpClick() {
    const calendar = this.getCalendar()
    const { selectedItems, incrementEventPosition } = this.props
    const selectedEvents = selectedItems[Selectable.EVENT]
    if (!this.readOnly() && selectedEvents[0] && selectedEvents[0].length > 0) {
      incrementEventPosition(selectedEvents[0], calendar.id, false)
    }
  }

  /**
   * Moves the currently selected item up towards the bottom
   * of the day in the priority field.
   */
  handleDownClick() {
    const calendar = this.getCalendar()
    const { selectedItems, incrementEventPosition } = this.props
    const selectedEvents = selectedItems[Selectable.EVENT]
    if (!this.readOnly() && selectedEvents[0] && selectedEvents[0].length > 0) {
      incrementEventPosition(selectedEvents[0], calendar.id, true)
    }
  }

  /**
   * Redirects the user back to set of dates where the current day will
   * be in range.
   */
  handleTodayClick() {
    const weeks = this.getWeeksToDisplay()
    const unit = weeks <= 4 ? "week" : "month"
    const start = DateTime.utc().startOf(unit).startOf("week")
    this.pushToDateRange(start.toFormat("yyyy-MM-dd"))
    if (this.state.useScrolling) {
      this.setState({
        anchorDate: start.toISODate(),
      })
    }
  }

  /**
   * Redirects the user to a new set of dates by incrementing the current
   * period.
   */
  handleNextClick() {
    const weeks = this.getWeeksToDisplay()
    const unit = weeks < 4 ? "w" : "M"
    const start = this.getStartMoment().add(1, unit).startOf(unit)
    this.pushToDateRange(start.format(DATE_FORMAT))
    if (this.state.useScrolling) {
      this.setState({
        anchorDate: start.format(),
      })
    }
  }

  /**
   * Redirects the user to a new set of dates by decrementing the current
   * period.
   */
  handlePrevClick() {
    const weeks = this.getWeeksToDisplay()
    const unit = weeks < 4 ? "w" : "M"
    const start = this.getStartMoment().subtract(1, unit).startOf(unit)
    this.pushToDateRange(start.format(DATE_FORMAT))
    if (this.state.useScrolling) {
      this.setState({
        anchorDate: start.format(),
      })
    }
  }

  /**
   * Sends the application to a new location based on supplied date ranges.
   * @param {String} start The start date to be used in the route.
   */
  pushToDateRange(start) {
    const { history, match } = this.props
    const view = match.params.view
    history.push(
      `${this.getBasePath()}/start/${start}/view/${
        view || VIEW_MODE_NAMES.ONE_MONTH
      }`
    )
    setItem("startDate", start)
    setItem("view", view)
    this.clearSelectedDateIfOutOfRange(start, view)
  }

  getBasePath() {
    const { match } = this.props
    const { subdomain, calendarId, snapshotId, reportId } = match.params
    const url = reportId
      ? `/org/${subdomain}/reports/${reportId}`
      : `/org/${subdomain}/cal/${calendarId}`
    return `${url}${snapshotId ? `/snapshot/${snapshotId}` : ""}`
  }

  /**
   * Presents an edit modal for a specified event entity.
   *
   * @param {String} uuid  the uuid of the event to edit.
   */
  handleEditEvent(uuid, calendarId) {
    if (!this.readOnly()) {
      const item = this.itemForEvent(uuid, calendarId)
      this.handleEditItem(item.uuid, item.calendarId)
    }
  }

  handleChangeAll(uuids) {
    if (!this.readOnly()) {
      const modal = BATCH_MODAL
      this.displayModal(modal, { itemUuids: uuids })
    }
  }

  /**
   * Looks up the parent item for a given event.
   *
   * @param {String} eventId  the id of the event.
   * @returns {Object} the parent item for the given event.
   */
  itemForEvent(eventId) {
    const event = this.findEventsForView().filter((e) => e.uuid === eventId)[0]
    const item = this.findItemsForView().filter(
      (i) => i.uuid === event.itemUuid
    )[0]
    return item
  }

  /**
   * Determines if an item with a supplied uuid is a note.
   *
   * @param {String} itemUuid  the uuid of the item.
   * @returns {Boolean} true if the event is actually part of the notes collection.
   */
  itemIsNote(itemUuid) {
    const item = this.findItemsForView().filter((i) => itemUuid === i.uuid)[0]
    const category = this.findCategoriesForView().filter(
      (c) => c.name === "Notes"
    )[0]
    return item && category && item.categoryUuid === category.uuid
  }

  /**
   * Presents an edit modal for a specified item entity.
   *
   * @param {Integer} uuid    the uuid of the item to edit.
   */
  handleEditItem(uuid) {
    if (!this.readOnly()) {
      const itemResourceType = this.itemIsNote(uuid) ? "note" : "event"
      const modal = itemResourceType === "note" ? NOTE_MODAL : ITEM_MODAL
      this.displayModal(modal, { itemToEdit: uuid, itemResourceType })
    }
  }

  /**
   * Sends the user to the create item view.
   */
  handleCreateItem() {
    if (!this.readOnly()) {
      this.displayModal(ITEM_MODAL, {
        itemToEdit: "NEW",
        itemResourceType: "event",
        defaultDate: this.props.selectedDate,
      })
    }
  }

  /**
   * Sends the user to the note creation form.
   *
   * @param {Object} date The date to create a new event.
   */
  handleCreateNoteOnDate(date) {
    if (!this.readOnly()) {
      if (!moment(this.props.selectedDate).isSame(date, "day")) {
        this.handleDaySelection(date)
      }
      this.displayModal(NOTE_MODAL, {
        itemToEdit: "NEW",
        itemResourceType: "note",
        defaultDate: moment.utc(date).format(),
      })
    }
  }

  /**
   * Sends the user to the note creation form.
   *
   * @param {Object} date The date to create a new event.
   */
  handleCreateEventOnDate(date) {
    if (!this.readOnly()) {
      if (!moment(this.props.selectedDate).isSame(date, "day")) {
        this.handleDaySelection(date)
      }
      this.displayModal(ITEM_MODAL, {
        itemToEdit: "NEW",
        itemResourceType: "event",
        defaultDate: moment.utc(date).format(),
      })
    }
  }

  /**
   * Sends the user to the create category view.
   */
  handleCreateCategory() {
    if (!this.readOnly()) {
      this.displayModal(CATEGORY_MODAL, { categoryToEdit: "NEW" })
    }
  }

  /**
   * Presents an edit modal for a specified category entity.
   *
   * @param {String}  uuid  the uuid of the category to edit.
   */
  handleEditCategory(uuid) {
    if (!this.readOnly()) {
      this.displayModal(CATEGORY_MODAL, { categoryToEdit: uuid })
    }
  }

  /**
   * This method is a callback passed down to child components and
   * called when an item has been dragged from the item explorer onto
   * the calendar view.
   *
   * @param {String} type   The item type tracked by react-dnd.
   * @param {String} uuid   The uuid of the item which was dropped.
   * @param {Number} calendarId The calendar id for the item item which was dropped.
   * @param {String} date   The date the item was dropped on.
   */
  handleItemDrop(type, uuid, calendarId, date) {
    const { createEvent, expandEventPreview } = this.props
    if (this.readOnly()) {
      return
    }
    switch (type) {
      case ExplorerTypes.ITEM_VIEW:
        createEvent(this.getCalendar(), {
          itemUuid: uuid,
          startsAt: moment.utc(date).startOf("day"),
          endsAt: moment.utc(date).startOf("day"),
          length: 1,
          useCustomStyles: false,
        })
        break
      case EventItemType.START:
      case EventItemType.END:
        const direction = type === EventItemType.START ? -1 : 1
        const item = this.itemForEvent(uuid)

        if (
          item.interruptedByHolidays &&
          Object.keys(this.getDarkDays()).includes(
            moment.utc(date).format(DATE_FORMAT)
          )
        ) {
          toast.error("This event cannot occur on a holiday.")
          return
        } else if (
          item.interruptedByWeekends &&
          [0, 6].includes(moment.utc(date).weekday())
        ) {
          toast.error("This event cannot occur on a weekend.")
          return
        }
        expandEventPreview(
          uuid,
          calendarId,
          moment.utc(date).startOf("day"),
          direction
        )
        break
      default:
        break
    }
  }

  /**
   * Updates the redux store so that the selected date is marked
   * to display a settings menu.
   *
   * @param {Object} date The new date to display settings for.
   */
  handleSettingsForDay(date) {
    const { showSettingsForDate } = this.props
    showSettingsForDate(moment.utc(date))
  }

  /**
   * Sends the user to the holiday creation form.
   *
   * @param {Object} date The date to create a holiday.
   */
  handleCreateHolidayOnDate(date) {
    if (!this.readOnly()) {
      this.displayModal(HOLIDAY_MODAL, {
        holidayToEdit: "NEW",
        defaultDate: moment.utc(date).format(),
      })
    }
  }

  /**
   * Sends the user to the holiday edit form.
   *
   * @param {String} holidayUuid The ID of the holiday to edit.
   */
  handleEditHoliday(holidayUuid) {
    if (!this.readOnly()) {
      this.displayModal(HOLIDAY_MODAL, { holidayToEdit: holidayUuid })
    }
  }

  handleDeleteHolidays(holidays) {
    const { removeHolidays, match, loadEvents } = this.props
    const { subdomain, calendarId } = match.params
    const names = holidays.map((h) => `'${h.name}'`).join(", ")
    this.setState({
      confirmDelete: true,
      confirmMessage: `You will remove the following holidays: ${names}.`,
      confirmAction: () => {
        removeHolidays(subdomain, calendarId, holidays[0].uuid).then(() =>
          loadEvents(subdomain, calendarId)
        )
        this.confirmCancel()
      },
    })
  }

  /**
   * Resets the state triggering a delete confirmation.
   */
  confirmCancel() {
    this.setState({ confirmDelete: false })
  }

  displayModal(name, state) {
    this.setState({
      modals: [...this.state.modals.filter((m) => m !== name), name],
      ...state,
    })
  }

  dismissModal(name, state) {
    this.setState({
      modals: this.state.modals.filter((m) => m !== name),
      ...state,
    })
  }

  getModalIndex(name) {
    const indicies = ["z-40", "z-50", "z-60", "z-70", "z-80", "z-90"]
    const index = Math.max(
      0,
      Math.min(this.state.modals.indexOf(name), indicies.length)
    )
    return indicies[index]
  }

  renderBatchModal() {
    const { match } = this.props
    const { subdomain, calendarId } = match.params
    const handleDismiss = () =>
      this.dismissModal(BATCH_MODAL, { itemUuids: null })
    return (
      <BatchModal
        itemUuids={this.state.itemUuids}
        subdomain={subdomain}
        calendarId={calendarId}
        onClose={handleDismiss}
      />
    )
  }

  /**
   * Renders a modal to edit the currently selected item.
   * @return {ReactClass} A component representing an item edit dialog.
   */
  renderItemModal() {
    const { selectedDate, match } = this.props
    const { subdomain, calendarId } = match.params
    const handleEditCategory = (uuid) => {
      this.displayModal(CATEGORY_MODAL, { categoryToEdit: uuid || "NEW" })
    }
    const handleEditStyle = (uuid) => {
      this.displayModal(STYLE_MODAL, { styleToEdit: uuid })
    }
    const handleDismiss = () =>
      this.dismissModal(ITEM_MODAL, { itemToEdit: null })
    const handleEditTheme = () => this.displayModal(THEME_MODAL)
    const handleFocus = () => {
      this.displayModal(ITEM_MODAL)
    }
    return (
      <ItemModal
        itemUuid={this.state.itemToEdit}
        subdomain={subdomain}
        calendarId={calendarId}
        resourceType={this.state.itemResourceType}
        defaultDate={
          selectedDate
            ? DateTime.fromISO(selectedDate, { zone: "utc" }).toISODate()
            : DateTime.utc().toISODate()
        }
        onClose={handleDismiss}
        onEditStyle={handleEditStyle}
        onEditCategory={handleEditCategory}
        onEditTheme={handleEditTheme}
        onFocus={handleFocus}
        zIndex={this.getModalIndex(ITEM_MODAL)}
        darkDays={Object.keys(this.getDarkDays() ?? {})}
      />
    )
  }

  /**
   * Renders a modal to edit the currently selected item.
   * @return {ReactClass} A component representing an item edit dialog.
   */
  renderStyleModal() {
    const { match } = this.props
    const { subdomain, calendarId } = match.params
    const handleDismiss = () => {
      this.dismissModal(STYLE_MODAL, { styleToEdit: null })
    }
    const handleFocus = () => {
      this.displayModal(STYLE_MODAL)
    }
    return (
      <StyleModal
        styleUuid={this.state.styleToEdit}
        subdomain={subdomain}
        calendarId={calendarId}
        onClose={handleDismiss}
        onFocus={handleFocus}
        zIndex={this.getModalIndex(STYLE_MODAL)}
      />
    )
  }

  /**
   * Renders a modal to edit the theme for the current calendar.
   * @return {ReactClass} A component representing an theme edit dialog.
   */
  renderThemeModal() {
    const { selectedDate, match } = this.props
    const { subdomain, calendarId } = match.params

    const handleDismiss = () => this.dismissModal(THEME_MODAL)
    const handleDuplicateStyle = (uuid) =>
      this.displayModal(STYLE_MODAL, {
        styleToEdit: uuid,
        editingTheme: false,
        newStyle: true,
      })
    const handleCreateStyle = () =>
      this.displayModal(STYLE_MODAL, {
        styleToEdit: null,
        editingTheme: false,
        newStyle: true,
      })
    const handleEditStyle = (uuid) =>
      this.displayModal(STYLE_MODAL, {
        styleToEdit: uuid,
        editingTheme: false,
        newStyle: false,
      })
    const handleFocus = () => {
      this.displayModal(THEME_MODAL)
    }
    return (
      <ThemeModal
        subdomain={subdomain}
        calendarId={calendarId}
        resourceType={this.state.itemResourceType}
        defaultDate={
          selectedDate
            ? DateTime.fromISO(selectedDate, { zone: "utc" }).toISODate()
            : DateTime.utc().toISODate()
        }
        onClose={handleDismiss}
        onFocus={handleFocus}
        onCreateStyle={handleCreateStyle}
        onDuplicateStyle={handleDuplicateStyle}
        onEditStyle={handleEditStyle}
        zIndex={this.getModalIndex(THEME_MODAL)}
      />
    )
  }

  /**
   * Renders a modal to handle a push / pull action.
   * @return {ReactClass} A component representing a push/pull dialog.
   */
  renderPushPullModal() {
    const { selectedDate } = this.props
    const { pushPullAction, pushPullFilters } = this.state
    const handleDismiss = () => this.dismissModal(PUSH_MODAL)
    return (
      <PushPullModal
        action={pushPullAction}
        onClose={handleDismiss}
        anchorDate={selectedDate}
        itemsToMove={pushPullFilters}
      />
    )
  }

  /**
   * Renders a modal to handle snapshot creation.
   * @return {ReactClass} A component representing a snapshot form dialog.
   */
  renderSnapshotModal() {
    const handleDismiss = () =>
      this.dismissModal(SNAPSHOT_MODAL, {
        createSnapshot: false,
        specificSnapshotId: null,
      })
    return (
      <SnapshotModal
        onDismiss={handleDismiss}
        specificSnapshotId={this.state.specificSnapshotId}
      />
    )
  }

  /**
   * Renders a modal to manage calendars associated to a report.
   * @return {ReactClass} A component representing an item edit dialog.
   */
  renderReportSettingsModal() {
    const { match } = this.props
    const { subdomain, reportId } = match.params
    return (
      <Modal.Modal
        title={`Manage Report`}
        draggable={true}
        returnTo={() => this.setState({ reportSettingsVisible: false })}
      >
        <ReportSettingsModal
          subdomain={subdomain}
          reportId={reportId}
          handleDismiss={() => this.setState({ reportSettingsVisible: false })}
        />
      </Modal.Modal>
    )
  }

  renderNoteModal() {
    const itemUuid = this.state.itemToEdit
    const isNew = itemUuid === "NEW"
    const handleFocus = () => {
      this.displayModal(NOTE_MODAL)
    }
    const handleDismiss = () =>
      this.dismissModal(NOTE_MODAL, { itemToEdit: null })

    return (
      <NoteModal
        itemUuid={isNew ? null : itemUuid}
        onClose={handleDismiss}
        zIndex={this.getModalIndex(NOTE_MODAL)}
        onFocus={handleFocus}
        defaultDate={this.state.defaultDate}
      />
    )
  }

  /**
   * Renders a modal to edit the currently selected category.
   * @return {ReactClass} A component representing an category edit dialog.
   */
  renderCategoryModal() {
    const categoryUuid = this.state.categoryToEdit
    const isNew = categoryUuid === "NEW"
    const handleFocus = () => {
      this.displayModal(CATEGORY_MODAL)
    }
    const handleDismiss = () =>
      this.dismissModal(CATEGORY_MODAL, { categoryToEdit: null })
    const handleEditTheme = () => this.displayModal(THEME_MODAL)
    const handleEditStyle = (uuid) => {
      this.displayModal(STYLE_MODAL, { styleToEdit: uuid })
    }
    return (
      <CategoryModal
        categoryUuid={isNew ? null : categoryUuid}
        onClose={handleDismiss}
        zIndex={this.getModalIndex(CATEGORY_MODAL)}
        onFocus={handleFocus}
        onEditTheme={handleEditTheme}
        onEditStyle={handleEditStyle}
      />
    )
  }

  /**
   * Renders a modal to edit / create a holiday.
   * @return {ReactClass} A component representing an item edit dialog.
   */
  renderHolidayModal() {
    const holidayUuid = this.state.holidayToEdit
    const isNew = holidayUuid === "NEW"
    const handleFocus = () => {
      this.displayModal(HOLIDAY_MODAL)
    }
    const handleDismiss = () =>
      this.dismissModal(HOLIDAY_MODAL, { holidayToEdit: null })

    return (
      <HolidayModal
        holidayUuid={isNew ? null : holidayUuid}
        onClose={handleDismiss}
        zIndex={this.getModalIndex(HOLIDAY_MODAL)}
        onFocus={handleFocus}
        defaultDate={this.state.defaultDate}
      />
    )
  }

  /**
   * Provided the proper requirements have been set in the state. This
   * method renders a confirmation dialog
   *
   * @return {ReactClass} A component representing a confirmation dialog.
   */
  renderConfirmation() {
    const { confirmMessage, confirmAction } = this.state
    const handleDismiss = () => this.confirmCancel()
    return (
      <Dialog
        onClose={handleDismiss}
        title="Are You Sure?"
        message={confirmMessage}
        onConfirm={confirmAction}
        confirmLabel="Confirm"
        onCancel={handleDismiss}
        open
      />
    )
  }

  nameForToolbar() {
    const calendar = this.getCalendar()
    const snapshot = this.getSnapshot()
    const report = this.getReport()
    return (
      (report && report.name) ||
      (snapshot && snapshot.name) ||
      (calendar && calendar.name) ||
      "..."
    )
  }

  customColorsForCalendar() {
    const calendar = this.getCalendar() || {}
    const { weekendColor, cutoffColor, holidayColor } = calendar
    return {
      weekendColor: weekendColor || "#ebe8ea",
      cutoffColor: cutoffColor || "#cfc7cd",
      holidayColor: holidayColor || "#ebe8ea",
    }
  }

  organizationNameForToolbar() {
    const organization = this.getOrganization()
    const snapshot = this.getSnapshot()
    return (
      (snapshot && moment.utc(snapshot.createdAt).format("MMM D, YYYY")) ||
      (organization && organization.name) ||
      "..."
    )
  }

  // eslint-disable-line no-use-before-define
  /**
   * Renders the toolbar that appears above the calendar when no items are selected.
   *
   * @returns {ReactClass} A react component representing the default CalendarToolbar.
   */
  renderCalendarToolBar() {
    const {
      selectedItems,
      cutEvents,
      copyEvents,
      clipboardEvents,
      selectedDate,
      match,
    } = this.props
    const { calendarId, snapshotId, subdomain } = match.params
    const selectedEvents = selectedItems[Selectable.EVENT]

    const handleItemSelect = (item) => {
      gtag("event", item, {
        event_category: "calendar toolbar",
        event_label: `toolbar => ${item}`,
        value: 1,
      })

      switch (item) {
        case "cut":
          cutEvents(selectedEvents, calendarId)
          break
        case "copy":
          copyEvents(selectedEvents, calendarId)
          break
        case "edit":
          this.editSelectedItem()
          break
        case "delete":
          this.deleteSelectedItems()
          break
        case "paste":
          this.pasteEvents(selectedDate)
          break
        case "push":
          this.handlePushPullRequest("Push")
          break
        case "pull":
          this.handlePushPullRequest("Pull")
          break
        case "pdf":
          this.gotoPrintView()
          break
        case "next":
          this.handleNextClick()
          break
        case "previous":
          this.handlePrevClick()
          break
        case "today":
          this.handleTodayClick()
          break
        case "save":
          this.handleCreateSnapshotRequest()
          break
        case "category":
          this.handleCreateCategory()
          break
        case "item":
          this.handleCreateItem()
          break
        case "holiday":
          this.handleCreateHolidayOnDate(moment(selectedDate))
          break
        case "note":
          this.handleCreateNoteOnDate(moment(selectedDate))
          break
        default:
          break
      }
    }

    const disabledItems = []
    if (this.readOnly()) {
      disabledItems.push([
        "cut",
        "copy",
        "paste",
        "edit",
        "delete",
        "push",
        "pull",
      ])
    }
    if (selectedEvents.length < 1) {
      disabledItems.push(["cut", "copy", "edit", "delete"])
    }
    if (clipboardEvents.length < 1) {
      disabledItems.push(["paste"])
    }

    const handleViewSelection = (mode) => {
      switch (mode) {
        case "1 Week":
          this.handleViewSelection(VIEW_MODE_NAMES.ONE_WEEK)
          break
        case "4 Month":
          this.handleViewSelection(VIEW_MODE_NAMES.FOUR_MONTH)
          break
        case "6 Month":
          this.handleViewSelection(VIEW_MODE_NAMES.SIX_MONTH)
          break
        case "1 Year":
          this.handleViewSelection(VIEW_MODE_NAMES.ONE_YEAR)
          break
        case "Timeline":
          this.handleViewSelection(VIEW_MODE_NAMES.PROJECT)
          break
        default:
          this.handleViewSelection(VIEW_MODE_NAMES.ONE_MONTH)
      }
    }

    let modeName = ""
    switch (this.getCurrentModeName()) {
      case VIEW_MODE_NAMES.ONE_WEEK:
        modeName = "1 Week"
        break
      case VIEW_MODE_NAMES.FOUR_MONTH:
        modeName = "4 Months"
        break
      case VIEW_MODE_NAMES.SIX_MONTH:
        modeName = "6 Months"
        break
      case VIEW_MODE_NAMES.ONE_YEAR:
        modeName = "1 Year"
        break
      case VIEW_MODE_NAMES.PROJECT:
        modeName = "Timeline"
        break
      default:
        modeName = "1 Month"
    }

    if (snapshotId) {
      const handleSnapshotItemSelection = (item) => {
        switch (item) {
          case "pdf":
            this.gotoPrintView()
            break
          case "save":
            this.handleCreateSnapshotRequest()
            break
          case "back":
            this.props.history.push(
              `/org/${subdomain}/cal/${calendarId}/start/${match.params.startDate}/view/${match.params.view}`
            )
            break
          default:
            break
        }
      }

      return (
        <ConnectedSnapshotToolBar
          onViewModeSelect={handleViewSelection}
          onItemSelect={handleSnapshotItemSelection}
          viewMode={modeName}
          subdomain={subdomain}
          currentCalendarId={calendarId}
          snapshotId={snapshotId}
          viewId={match.params.view}
          startDate={match.params.startDate}
        />
      )
    }

    return (
      <ConnectedToolBar
        onItemSelect={handleItemSelect}
        calendarName={this.nameForToolbar()}
        calendarDescription={this.organizationNameForToolbar()}
        onViewModeSelect={handleViewSelection}
        disabledItems={disabledItems.flat()}
        viewMode={modeName}
        subdomain={subdomain}
        currentCalendarId={calendarId}
        viewId={match.params.view}
        startDate={match.params.startDate}
        disabled={this.readOnly()}
      />
    )
  }

  clearClipboardAndSelection() {
    const { clearClipboard, resetSelection } = this.props
    clearClipboard()
    resetSelection()
  }

  handleDaySelection(date) {
    const { selectDate, resetSelection, selectedDate } = this.props
    const result =
      selectedDate && moment(selectedDate).isSame(date, "day") ? null : date
    selectDate(result)
    if (result && !moment(result).isSame(this.state.defaultDate, "day")) {
      this.setState({ defaultDate: result })
    }
    resetSelection()
  }

  copySelectedEvents() {
    const { selectedItems, copyEvents, match } = this.props
    const { calendarId } = match.params
    const selectedEvents = selectedItems[Selectable.EVENT]
    selectedEvents.length > 0 && copyEvents(selectedEvents, calendarId)
  }

  cutSelectedEvents() {
    const { selectedItems, cutEvents, match } = this.props
    const { calendarId } = match.params
    const selectedEvents = selectedItems[Selectable.EVENT]
    selectedEvents.length > 0 && cutEvents(selectedEvents, calendarId)
  }

  pasteEvents(date, increment) {
    const { clipboardEvents, pasteEventsToDate, match, clipboardMode } =
      this.props
    const { calendarId } = match.params
    pasteEventsToDate(
      clipboardEvents,
      calendarId,
      moment.utc(date),
      clipboardMode,
      increment
    )
  }

  renderMonths() {
    const type = this.getViewType()
    if (type === "GANNT") {
      return this.renderGannt()
    }

    const weeks = this.getWeeksToDisplay()
    const months = this.getMonthsToDisplay()
    const rows = this.getRowsToDisplay(months)
    const cols = months / rows

    return months <= 1
      ? this.renderScollingMonths(weeks)
      : this.renderMonthGrid(months, rows, cols)
  }

  renderMonthGrid(months, rows, cols) {
    if (this.state.useScrolling) {
      this.setState({ useScrolling: false, scrollCalendarIndex: 0 })
    }
    return (
      <div
        className={clsx(
          "grid grid-flow-row gap-x-1 gap-y-px flex-grow",
          rows === 2 ? "grid-rows-2" : "grid-rows-3",
          cols === 2
            ? "grid-cols-2"
            : cols === 3
            ? "grid-cols-3"
            : "grid-cols-4"
        )}
      >
        {Array(rows * cols)
          .fill()
          .map((_, i) => this.renderMonth(i, months < 3))}
      </div>
    )
  }

  getDarkDays() {
    const { snapshotId, calendarId } = this.props.match.params
    const holidays = snapshotId
      ? this.props.findHolidaysForSnapshot(snapshotId)
      : this.props.findHolidays(calendarId)
    const format = (d) => moment.utc(d).format(DATE_FORMAT)
    const dates = (c) =>
      Array((moment(c.endsAt).diff(moment(c.startsAt), "days") || 0) + 1)
        .fill("")
        .map((_, i) => moment.utc(c.startsAt).add(i, "day"))
        .map(format)
    const darkDays = holidays.reduce(
      (p, c) => ({
        ...p,
        ...dates(c).reduce(
          (pd, cd) => ({
            ...pd,
            [cd]: [...(p[cd] || []), ...(pd[cd] || []), c.name],
          }),
          {}
        ),
      }),
      {}
    )
    return darkDays
  }

  handleSelectEvent(id, calendarId, ignoreIfSelected) {
    if (
      ignoreIfSelected &&
      this.props.selectedItems[Selectable.EVENT].includes(id)
    ) {
      return
    }
    this.handleSelectedItem(Selectable.EVENT, id, calendarId)
    if (this.state.itemToEdit) {
      this.handleEditEvent(id, calendarId)
    }
  }

  renderGannt() {
    const { findReportCalendars, findCalendar } = this.props
    const match = this.getMatchWithDates()
    const { calendarId, reportId } = match.params
    const calendar = this.getCalendar()
    const { findStyle, findItem } = this.props
    const calendars = findReportCalendars(reportId).map(
      (rc) => findCalendar(rc.calendarId) || {}
    )

    const categories = this.findCategoriesForView()
    const calendarEvents = this.findEventsForView()
      .filter((e) => !e.isDeleted)
      .map((e) => {
        const style = findStyle(e.applicableStyleId, e.calendarId)
        const item = findItem(e.itemUuid, e.calendarId)
        return {
          id: e.uuid,
          name: e.name,
          background: style.fillColor,
          foreground: style.textColor,
          outline: style.outlineColor,
          length: e.length,
          startsAt: e.startsAt.substr(0, 10),
          category: (item || {}).categoryUuid,
          calendarId: e.calendarId,
          interruptedByHolidays: e.interruptedByHolidays,
          interruptedByWeekends: e.interruptedByWeekends,
        }
      })

    const sortByDate = (a, b) =>
      a.startsAt > b.startsAt ? 1 : a.startsAt < b.startsAt ? -1 : 0

    const buildGroups = (parentId) => {
      const uncategorized = calendarEvents
        .filter((e) => !parentId || (e.calendarId || e.calendar) === parentId)
        .filter((e) => !e.category)
      const groups = categories
        .filter((c) => !parentId || (c.calendarId || c.calendar) === parentId)
        .map((c) => {
          const style = findStyle(c.applicableStyleId, c.calendarId)
          return {
            id: c.uuid,
            name: c.name,
            outline: "#000000",
            background: style.fillColor,
            contents: calendarEvents
              .filter(
                (c) => !parentId || (c.calendarId || c.calendar) === parentId
              )
              .filter((e) => e.category === c.uuid)
              .map((e) => ({
                ...e,
                outline: "#000000",
              })),
          }
        })
      if (uncategorized.length > 0) {
        groups.push({
          id: `${parentId}_uncategorized`,
          contents: uncategorized,
          name: "Uncategorized",
        })
      }
      const ungrouped = groups
        .map((g) => g.contents)
        .flat()
        .sort(sortByDate)
      return ungrouped
    }

    let calendarGroups = buildGroups()

    let startDate =
      (calendarGroups && calendarGroups[0] && calendarGroups[0].startsAt) ||
      DateTime.utc().toISODate()
    if (calendars && calendars.filter((c) => c.id).length > 0) {
      calendarGroups = flatten(
        calendars.map((c) => ({
          id: c.id,
          name: c.name,
          contents: buildGroups(c.id),
        }))
      )
      const earliestEvent =
        (calendarGroups || [])
          .map((c) => c.contents || [])
          .flat()
          .sort(sortByDate)[0] || {}
      startDate = earliestEvent.startsAt || startDate
    }
    const onSelectDate = (d) => this.handleDaySelection(d)
    const onSelectEvent = (id, calendarId) =>
      this.handleSelectEvent(id, calendarId)
    const groups = calendarGroups.length > 0 ? calendarGroups : [{}]
    const calendarIds = reportId
      ? this.props.findRenderableCalendarIdsForReport(reportId)
      : [parseInt(calendarId, 0)]

    return (
      <GanntChartView
        originDate={DateTime.fromISO(startDate).startOf("month").toISODate()}
        dayCellSize={20}
        groups={groups}
        onSelectDate={onSelectDate}
        onSelectEvent={onSelectEvent}
        selectedDate={this.props.selectedDate}
        selectedEventIds={this.props.selectedItems[Selectable.EVENT]}
        darkDays={this.getDarkDays()}
        reportId={reportId}
        calendarIds={calendarIds}
        weekendCellBackgroundColor={calendar ? calendar.weekendColor : null}
        inactiveCellBackgroundColor={calendar ? calendar.holidayColor : null}
      />
    )
  }

  renderScollingMonths(weeks) {
    const match = this.getMatchWithDates()
    const { calendarId, reportId, snapshotId } = match.params
    if (!this.state.useScrolling) {
      this.setState({
        useScrolling: true,
        anchorDate: this.getStartMoment().format(),
      })
    }
    const handleDateUpdate = (index, newDate) => {
      this.updateStartDateInPath(newDate)
    }

    const calendarIds = reportId
      ? this.props.findRenderableCalendarIdsForReport(reportId)
      : [parseInt(calendarId, 0)]

    const colors = this.customColorsForCalendar()

    return (
      <WeekScrollView
        weeksVisible={weeks}
        anchorDate={this.state.anchorDate}
        onDateUpdate={handleDateUpdate}
        selectedDate={this.props.selectedDate}
        onSelectDate={(d) => this.props.selectDate(d)}
        onItemDrop={(type, uuid, calId, date) => {
          this.handleItemDrop(type, uuid, calId, date)
        }}
        onItemDropHover={() => {}}
        snapshotId={snapshotId}
        calendarIds={calendarIds}
        reportId={reportId}
        readOnly={this.readOnly()}
        onEventMouseDown={(selected) => {
          this.handlePossibleSelectionClearance(selected)
        }}
        onSelectedEvent={(id, calendarId, ignoreIfSelected) => {
          this.handleSelectEvent(id, calendarId, ignoreIfSelected)
        }}
        onEditEvent={(id, calendarId) => this.handleEditEvent(id, calendarId)}
        darkDays={this.getDarkDays()}
        lastLayoutChange={this.state.lastLayoutChange}
        weekendCellBackgroundColor={colors.weekendColor}
        inactiveCellBackgroundColor={colors.holidayColor}
        outOfRangeCellBackgroundColor={colors.cutoffColor}
        currentIndex={this.state.index || 0}
      />
    )
  }

  renderMonth(offset, enforceStrictHeight) {
    const { clipboardEvents, match, ...props } = this.props
    const { calendarId, snapshotId, reportId } = match.params
    const calendarIds = reportId
      ? props.findRenderableCalendarIdsForReport(reportId)
      : [parseInt(calendarId, 0)]
    const colors = this.customColorsForCalendar()
    return (
      <MonthView
        key={`MONTH${offset}_${this.getStartMoment()
          .add(offset, "months")
          .format(DATE_FORMAT)}`}
        getStartMoment={() => {
          const start = this.getStartMoment().add(offset, "months")
          return start
        }}
        enforceStrictHeight={enforceStrictHeight}
        calendarIds={calendarIds}
        snapshotId={snapshotId}
        handleSelectMonth={(month) =>
          this.handleViewSelection(VIEW_MODE_NAMES.ONE_MONTH, month)
        }
        handleSelectWeek={(week) =>
          this.handleViewSelection(VIEW_MODE_NAMES.ONE_WEEK, week)
        }
        showDragUI={props.showDragUI}
        incrementEventPosition={props.incrementEventPosition}
        handleEditEvent={(id) => this.handleEditEvent(id, calendarId)}
        onItemDrop={(type, uuid, calendarId, date) => {
          this.handleItemDrop(type, uuid, calendarId, date)
        }}
        onItemDropHover={() => {}}
        findItem={props.findItem}
        findStyle={props.findStyle}
        findLockedItem={props.findLockedItem}
        handleSelectedEvent={(id, calendarId) => {
          this.handleSelectedItem(Selectable.EVENT, id, calendarId)
          if (this.state.itemToEdit) {
            this.handleEditEvent(id, calendarId)
          }
        }}
        selectedEvents={props.selectedItems[Selectable.EVENT]}
        handleMouseDown={(selected) => {
          this.handlePossibleSelectionClearance(selected)
        }}
        weeks={this.getWeeksToDisplay()}
        simplified={this.getMonthsToDisplay() > 2}
        limited={this.getMonthsToDisplay() > 6}
        clipboardEvents={clipboardEvents}
        useDaySelectMode={false}
        selectedDate={props.selectedDate}
        handleDaySelection={(date) => this.handleDaySelection(date)}
        expandEventPreview={props.expandEventPreview}
        readOnly={this.readOnly()}
        forPrint={false}
        handleContextMenu={(params) => this.handleContextMenu(params)}
        darkDays={this.getDarkDays()}
        holidayColor={colors.holidayColor}
        weekendColor={colors.weekendColor}
        cutoffColor={colors.cutoffColor}
        reportId={reportId}
        indicatorDates={this.findIndicatorDatesForView()}
      />
    )
  }

  handleContextMenu({ x, y, date }) {
    const { requestContext, findHolidays, match } = this.props
    const { calendarId, reportId } = match.params
    const calendarIds = reportId
      ? this.props.findRenderableCalendarIdsForReport(reportId)
      : [parseInt(calendarId, 0)]
    const holidaysForDate = findHolidays(calendarIds).filter((d) => {
      const anchor = DateTime.fromISO(date, { zone: "utc" })
      return (
        DateTime.fromISO(d.startsAt, { zone: "utc" }).diff(anchor, "days")
          .days <= 0 &&
        DateTime.fromISO(d.endsAt, { zone: "utc" }).diff(anchor, "days").days >=
          0
      )
    })

    requestContext({
      entityType: ContextTypes.DAY,
      holidays: holidaysForDate,
      referenceDate: date,
      x,
      y,
    })
    this.handleDaySelection(date)
  }

  getPageTitle() {
    const calendar = this.getCalendar()
    if (this.hasStartMoment() && calendar) {
      return `${calendar.name} | ${this.getStartMoment().format("MMM Do")}`
    } else if (calendar) {
      return calendar.name
    } else {
      return "Loading Calendar"
    }
  }

  handleAddCalendar(calendarId) {
    const { addReportCalendar, match } = this.props
    const { reportId, subdomain } = match.params
    addReportCalendar(subdomain, reportId, calendarId)
  }

  handleRemoveCalendar(calendarId) {
    const { removeReportCalendar, match } = this.props
    const { reportId, subdomain } = match.params
    removeReportCalendar(subdomain, reportId, calendarId)
  }

  findCategoriesForView() {
    const { match, ...props } = this.props
    const { snapshotId, reportId, calendarId } = match.params
    if (reportId) {
      return props.findCalendarCategories(props.findReportCalendarIds(reportId))
    }

    const categories = snapshotId
      ? props.findSnapshotCategories(snapshotId)
      : props.findCalendarCategories(calendarId)
    return categories
  }

  findItemsForView() {
    const { match, ...props } = this.props
    const { snapshotId, reportId, calendarId } = match.params
    if (reportId) {
      return props.findCalendarItems(props.findReportCalendarIds(reportId))
    }
    return snapshotId
      ? props.findSnapshotItems(snapshotId)
      : props.findCalendarItems(calendarId)
  }

  findNotesForView() {
    const { match, ...props } = this.props
    const { snapshotId, reportId, calendarId } = match.params
    if (reportId) {
      return props.findCalendarOrSnapshotNotes(
        props.findReportCalendarIds(reportId)
      )
    }
    const entityType = snapshotId && snapshotId > 0 ? "snapshot" : "calendar"
    const parentEntityId = entityType === "snapshot" ? snapshotId : calendarId
    return props.findCalendarOrSnapshotNotes(parentEntityId, entityType)
  }

  findNotesCategoryForView() {
    const { match, ...props } = this.props
    const { snapshotId, reportId, calendarId } = match.params
    if (reportId) {
      return []
    }
    const entityType = snapshotId && snapshotId > 0 ? "snapshot" : "calendar"
    const parentEntityId = entityType === "snapshot" ? snapshotId : calendarId
    return props.findCalendarOrSnapshotCategoryForNotes(
      parentEntityId,
      entityType
    )
  }

  findEventsForView() {
    const { match, ...props } = this.props
    const { snapshotId, reportId, calendarId } = match.params
    if (reportId) {
      return props.findCalendarEvents(props.findReportCalendarIds(reportId))
    }
    return snapshotId
      ? props.findSnapshotEvents(snapshotId)
      : props.findCalendarEvents(calendarId)
  }

  findIndicatorDatesForView() {
    const { reportId } = this.props.match.params
    return reportId
      ? this.findIndicatorDatesForReport()
      : this.findIndicatorDatesForCalendar()
  }

  findIndicatorDatesForCalendar() {
    const { findIndicatorDates, match } = this.props
    const { calendarId } = match.params
    const result = findIndicatorDates(calendarId)
    if (!result) {
      return {}
    }
    return {
      [result.startDate]: [result.color],
      [result.endDate]: [result.color],
    }
  }

  findIndicatorDatesForReport() {
    const { findReportCalendars, findIndicatorDates, match } = this.props
    const { reportId } = match.params
    const inOutCalendarIds = findReportCalendars(reportId).filter(
      (r) => r.showInOut
    )
    const startStopDates = inOutCalendarIds
      .map(({ calendarId }) => {
        const result = findIndicatorDates(calendarId)
        if (!result) {
          return []
        }
        return [
          { date: result.startDate, color: result.color },
          { date: result.endDate, color: result.color },
        ]
      })
      .reduce((a, c) => [...a, ...c], [])
      .reduce(
        (a, c) => ({ ...a, [c.date]: [...(a[c.date] || []), c.color] }),
        {}
      )
    return startStopDates
  }

  sectionsForSidebar() {
    const { canManage } = this.props
    const { subdomain, calendarId, reportId, snapshotId } =
      this.props.match.params
    if (reportId || snapshotId || !canManage(subdomain, calendarId)) {
      return ["explorer", "history"]
    }
    return ["explorer", "collaborators", "history", "apps", "settings"]
  }

  render() {
    const { contextMenu, match, ...props } = this.props
    const { subdomain, calendarId, snapshotId, reportId, view } = match.params

    const handleEditTheme = () => this.displayModal(THEME_MODAL)
    const handleDuplicateSnapshot = (id) => this.handleCreateSnapshotRequest(id)
    return (
      <div className={LAYOUT_ROOT_STYLES}>
        <Helmet title={this.getPageTitle()} />
        <CalendarDateListener
          subdomain={subdomain}
          currentCalendarId={calendarId}
          currentReportId={reportId}
          currentSnapshotId={snapshotId}
          viewId={view}
          initialDate={match.params.startDate}
          useTimeout={this.state.useScrolling}
        />
        {this.renderCalendarToolBar()}
        <div className={OVERFLOW_CONTAINER_STYLES}>
          {this.state.confirmDelete && this.renderConfirmation()}
          <div className={CONTENT_SCROLL_STYLES} ref={this.contentScroll}>
            <div className="h-full w-full flex">
              <RendererContainerView
                updateItemPreviewType={props.updateItemPreviewType}
                itemPreviewType={props.itemPreviewType}
                onClick={() => this.handleEmptyAreaClick()}
              >
                {!this.isPending() && this.hasStartMoment() ? (
                  this.renderMonths()
                ) : (
                  <Placeholder.Loading />
                )}
              </RendererContainerView>
            </div>
          </div>
          <CalendarSideBar
            onChangeSection={() => this.updateLastRender()}
            availableSections={this.sectionsForSidebar()}
            render={({ section }) => {
              switch (section) {
                case "explorer":
                  return (
                    <ItemExplorer
                      calendarId={snapshotId || calendarId}
                      categories={this.findCategoriesForView()}
                      notes={this.findNotesForView()}
                      notesCategory={this.findNotesCategoryForView()}
                      items={this.findItemsForView()}
                      events={this.findEventsForView()}
                      multiSelectMode={this.isHoldingControlKey()}
                      itemPreviewType={props.itemPreviewType}
                      handleManageReportClick={() => this.showReportSettings()}
                      handleChangeAll={(itemUuids) =>
                        this.handleChangeAll(itemUuids)
                      }
                      handleEditEvent={(id, calendarId) =>
                        this.handleEditEvent(id, calendarId)
                      }
                      handleEditItem={(id, calendarId) =>
                        this.handleEditItem(id, calendarId)
                      }
                      handleEditCategory={(id, calendarId) => {
                        this.handleEditCategory(id, calendarId)
                      }}
                      handleDeleteCategory={(id, calendarId) =>
                        this.handleDeleteItem(
                          Selectable.CATEGORY,
                          id,
                          calendarId
                        )
                      }
                      handleBatchAction={(itemUuids) => {
                        this.handleBatchAction()
                      }}
                      handleDeleteItem={(id, calendarId) =>
                        this.handleDeleteItem(Selectable.ITEM, id, calendarId)
                      }
                      updateItemDraggingStatus={props.updateItemDraggingStatus}
                      readOnly={this.readOnly()}
                      snapshotId={snapshotId}
                      addReportCalendar={(id) => this.handleAddCalendar(id)}
                      removeReportCalendar={(id) =>
                        this.handleRemoveCalendar(id)
                      }
                      availableCalendars={
                        reportId && props.orgCalendars(subdomain)
                      }
                      reportCalendarIds={props.findReportCalendarIds(reportId)}
                      subdomain={subdomain}
                    />
                  )
                case "apps":
                  return (
                    <AppManagementWidget
                      calendarId={calendarId}
                      subdomain={subdomain}
                    />
                  )
                case "collaborators":
                  return (
                    <CalendarAccessWidget
                      calendarId={calendarId}
                      subdomain={subdomain}
                    />
                  )
                case "settings":
                  return (
                    <CalendarSettingsWidget
                      calendarId={calendarId}
                      subdomain={subdomain}
                      onEditTheme={handleEditTheme}
                    />
                  )
                case "history":
                  return (
                    <CalendarHistoryWidget
                      calendarId={calendarId}
                      subdomain={subdomain}
                      snapshotId={snapshotId}
                      onDuplicateSnapshot={handleDuplicateSnapshot}
                    />
                  )
                default:
                  return <span />
              }
            }}
          />
        </div>
        {this.state.modals.includes(THEME_MODAL)
          ? this.renderThemeModal()
          : null}
        {this.state.modals.includes(ITEM_MODAL) ? this.renderItemModal() : null}
        {this.state.modals.includes(BATCH_MODAL)
          ? this.renderBatchModal()
          : null}
        {this.state.modals.includes(CATEGORY_MODAL)
          ? this.renderCategoryModal()
          : null}
        {this.state.modals.includes(PUSH_MODAL)
          ? this.renderPushPullModal()
          : null}
        {this.state.modals.includes(SNAPSHOT_MODAL)
          ? this.renderSnapshotModal()
          : null}
        {this.state.reportSettingsVisible
          ? this.renderReportSettingsModal()
          : null}
        {this.state.modals.includes(HOLIDAY_MODAL)
          ? this.renderHolidayModal()
          : null}
        {this.state.modals.includes(STYLE_MODAL)
          ? this.renderStyleModal()
          : null}
        {this.state.modals.includes(NOTE_MODAL) ? this.renderNoteModal() : null}
        {contextMenu ? (
          <CalendarContextMenu
            calendarId={calendarId}
            subdomain={subdomain}
            onSelect={(selection, params) => {
              gtag("event", selection, {
                event_category: "calendar context menu",
                event_label: `context menu => ${selection}`,
                value: 1,
              })
              switch (selection) {
                case "createHoliday":
                  return this.handleCreateHolidayOnDate(params.referenceDate)
                case "createItem":
                  return this.handleCreateItem()
                case "createNote":
                  return this.handleCreateNoteOnDate(params.referenceDate)
                case "editHoliday":
                  return this.handleEditHoliday(contextMenu.holidays[0].uuid)
                case "clearHolidays":
                  return this.handleDeleteHolidays(contextMenu.holidays)
                case "push":
                  return this.handlePushPullRequest("Push")
                case "pull":
                  return this.handlePushPullRequest("Pull")
                case "editEvent":
                  return this.handleEditEvent(params.targetUuid)
                case "deleteEvent":
                  return this.deleteSelectedItems()
                case "manageTheme":
                  return this.displayModal(THEME_MODAL)
                default:
                  break
              }
            }}
          />
        ) : null}
        {snapshotId ? (
          <div className="border-t px-2 py-1 border-sorbus bg-sorbus-default fixed z-50 w-full bottom-0">
            <p className="text-white text-center w-full">
              You are in read only mode.{" "}
              <button
                href="#"
                className="underling font-bold text-white"
                onClick={(e) => {
                  e.preventDefault()
                  this.handleSelectedSnapshot(null)
                }}
              >
                Click here to edit the live calendar
              </button>
              .
            </p>
          </div>
        ) : null}
        {this.readOnlyViaPermission() ? (
          <FixedDisclaimer message="You have read-only access and cannot make changes to this calendar." />
        ) : null}
      </div>
    )
  }
}

CalendarView.propTypes = {
  /**
   * An object passed down from the keydown library allowing
   * us to access any keydown event and key.
   */
  keydown: PropTypes.object,

  /**
   * Determines if the component should consider itself
   * to be in the background and prevent rendering.
   */
  assumeBeneathModal: PropTypes.bool,

  /**
   * A redux selector to fetch an organization by subdomain.
   */
  findOrganization: PropTypes.func.isRequired,

  /**
   * A redux selector to fetch an snapshot by id.
   */
  findSnapshot: PropTypes.func.isRequired,

  /**
   * A redux selector to fetch an report by id.
   */
  findReport: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given calendar by ID and
   * organization subdomain.
   */
  findCalendar: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given set of calendar ids
   * for a reportId.
   */
  findReportCalendarIds: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given set of report calendars
   * for a reportId.
   */
  findReportCalendars: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given set of calendars
   * that will be rendered for a reportId. (ignores calendars using in/out only)
   */
  findRenderableCalendarIdsForReport: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given style by ID and
   * organization subdomain.
   */
  findStyle: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of categories by calendarID.
   */
  findCalendarCategories: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of categories by snapshotID.
   */
  findSnapshotCategories: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of items by calendarID.
   */
  findCalendarItems: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of items that belong to the protected 'Notes' category.
   */
  findCalendarOrSnapshotNotes: PropTypes.func.isRequired,

  /**
   * A redux selector to query the special notes category.
   */
  findCalendarOrSnapshotCategoryForNotes: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of items by snapshotID.
   */
  findSnapshotItems: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of events by calendarID.
   */
  findCalendarEvents: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of events by snapshotID.
   */
  findSnapshotEvents: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of snapshots by calendarID.
   */
  findCalendarSnapshots: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any calendar
   * information available for a supplied ID and organization subdomain.
   * This action is a thunk and returns a promise when the network request
   * with the API has finished.
   */
  loadCalendar: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will request a
   * CSV download for a specified calendar.
   */
  downloadCalendar: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any events
   * available for a supplied ID and organization subdomain. This action
   * is a thunk and returns a promise when the network request with the
   * API has finished.
   */
  loadEvents: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any items
   * available for a supplied ID and organization subdomain. This action
   * is a thunk and returns a promise when the network request with the
   * API has finished.
   */
  loadItems: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any
   * categories available for a supplied ID and organization subdomain.
   * This action is a thunk and returns a promise when the network
   * request with the API has finished.
   */
  loadCategories: PropTypes.func.isRequired,

  /**
   * A selector function that retrieves a specific item.
   */
  findItem: PropTypes.func.isRequired,

  /**
   * A selector function that retrieves a specific event.
   */
  findEvent: PropTypes.func.isRequired,

  /**
   * A selector function that retrieves a locked item.
   */
  findLockedItem: PropTypes.func.isRequired,

  /**
   * A boolean flag indicating whether or not any child calendar renderers
   * should render any drag and drop related feedback to the user.
   */
  showDragUI: PropTypes.bool.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function creates a calendar event against the API.
   */
  createEvent: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function removes a calendar event(s) against the API.
   */
  removeEvents: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function removes a calendar item(s) against the API.
   */
  removeItems: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function removes a calendar category or categories against the
   * API.
   */
  removeCategories: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function removes a calendar holiday or holidays against the
   * API.
   */
  removeHolidays: PropTypes.func.isRequired,

  /**
   * An action creator that dispatches an optimistic UI update indicating
   * an update in the order of an event's position.
   */
  incrementEventPosition: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This function
   * shows (adds) the item explorer from the view heirarchy.
   */
  showItems: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This function
   * hides (removes) the item explorer from the view heirarchy.
   */
  hideItems: PropTypes.func.isRequired,

  /**
   * If true this view will display the items explorer.
   */
  itemExplorerVisible: PropTypes.bool.isRequired,

  /**
   * The location injected by react router.
   */
  location: PropTypes.object,

  /**
   * A redux action creator already mapped to dispatch
   * which will select a calendar item.
   */
  selectItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will clear a selection.
   */
  resetSelection: PropTypes.func.isRequired,

  /**
   * A redux action creator which has been mapped to dispatch. This
   * function updates a calendar event against the API.
   */
  updateEvent: PropTypes.func.isRequired,

  /**
   * An array of item IDs that have been marked as selected
   * by the user.
   */
  selectedItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    events: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar item.
   */
  lockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar item.
   */
  unlockItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will lock a calendar category.
   */
  lockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will unlock a calendar category.
   */
  unlockCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar item.
   */
  showItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideItem: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will show a calendar category.
   */
  showCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will hide a calendar category.
   */
  hideCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will open or close a calendar category.
   */
  openCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar item's parent category.
   */
  updateItemCategory: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update a calendar categorys's parent
   * category.
   */
  updateCategoryCategory: PropTypes.func.isRequired,

  /**
   * Allows us to update the type of preview cell to
   * render as the user drags an item across the UI.
   */
  updateItemPreviewType: PropTypes.func.isRequired,

  /**
   * Indicates the type of preview to render when a
   * cell from the item explorer view is being dragged
   * accross the UI.
   */
  itemPreviewType: PropTypes.string.isRequired,

  /**
   * Flag that determines if the view is currently tracking
   * tracking any selected items, events, or categories.
   */
  hasSelection: PropTypes.bool.isRequired,

  /**
   * The last item type selected by the user. Used to determine
   * how to handle any destructive or next actions.
   */
  lastSelectedType: PropTypes.string.isRequired,

  /**
   * An action creator that takes an array of event IDs to
   * store in the clipboard for cut and paste.
   */
  cutEvents: PropTypes.func.isRequired,

  /**
   * An action creator that takes an array of event IDs and
   * a date offset to cut and paste to a new date.
   */
  pasteEventsToDate: PropTypes.func.isRequired,

  /**
   * An action creator that takes an array of event IDs to
   * store in the clipboard for copying.
   */
  copyEvents: PropTypes.func.isRequired,

  /**
   * An action creator that clears any eventIds from the
   * current clipboard.
   */
  clearClipboard: PropTypes.func.isRequired,

  /**
   * A string describing the intended clipboard action.
   */
  clipboardMode: PropTypes.string.isRequired,

  /**
   * An array of IDs that are currently stored in the clipboard.
   */
  clipboardEvents: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * The date currently selected on the calendar.
   */
  selectedDate: PropTypes.string,

  /**
   * An action creator which dispatches an update to the redux
   * store defining the currently selected date on the calendar.
   */
  selectDate: PropTypes.func.isRequired,

  /**
   * An action creator which dispatches an update to the redux
   * store defining whether or not to utilize multi-select mode.
   */
  setMultiselect: PropTypes.func.isRequired,

  /**
   * A boolean flag indicating whether or not multi-select mode is enabled.
   */
  multiselectEnabled: PropTypes.bool.isRequired,

  /**
   * An array of holidays that apply to the current calendar.
   */
  findHolidays: PropTypes.func.isRequired,

  /**
   * An array of holidays that apply to the current snapshot.
   */
  findHolidaysForSnapshot: PropTypes.func.isRequired,

  /**
   * A redux action creator already mapped to dispatch
   * which will update the status of whether or not a
   * calendar item (not an existing event) is being dragged.
   */
  updateItemDraggingStatus: PropTypes.func.isRequired,

  /**
   * An action creator that handles updates to an event expand
   * drag and drop event.
   */
  expandEventPreview: PropTypes.func.isRequired,

  /**
   * Ensures the current user can manage this calendar.
   */
  canManage: PropTypes.func.isRequired,

  /**
   * The start date of the most recently selected event.
   */
  lastSelectedEventDate: PropTypes.string,

  /**
   * An async redux axction creator which will request any
   * available snapshots for the current calendar.
   */
  requestSnapshot: PropTypes.func.isRequired,

  /**
   * The current date which a settings menu should be displayed.
   */
  dateForSettingsMenu: PropTypes.object,

  /**
   * Determines whether or not the current user is an admin
   * or not.
   */
  isAdmin: PropTypes.func.isRequired,

  /**
   * Determines whether or not the current user is a super admin
   * or not.
   */
  isSuperAdmin: PropTypes.bool.isRequired,

  /**
   * Retrieves details about the current user's account.
   */
  requestCurrentUser: PropTypes.func.isRequired,

  /**
   * Ensures the current user can make write changes to a specific
   * calendar being presented.
   */
  canWrite: PropTypes.func.isRequired,

  /**
   * Loads a report based on subdomain and ID.
   */
  requestReport: PropTypes.func.isRequired,

  /**
   * Requests the context menu.
   */
  requestContext: PropTypes.func.isRequired,

  /**
   * Load full calendars based on the report.
   */
  requestFullReportCalendars: PropTypes.func.isRequired,

  /**
   * Adds a calendar to a specified report.
   */
  addReportCalendar: PropTypes.func.isRequired,

  /**
   * Removes a calendar from a specified report.
   */
  removeReportCalendar: PropTypes.func.isRequired,

  /**
   * The final date to be rendered by the calendar.
   */
  contextMenu: PropTypes.object,

  /**
   * A redux selector that queries the start / end dates a calendar.
   */
  findIndicatorDates: PropTypes.func.isRequired,

  /**
   * A redux selector that queries the earliest possible date for visible events on a calendar.
   */
  findEarliestEventForCalendar: PropTypes.func.isRequired,

  /**
   * A redux selector that queries the latest possible date for visible events on a calendar.
   */
  findLatestEventForCalendar: PropTypes.func.isRequired,

  /**
   * The standard params expected to be passed to the calendar via React
   * Router.
   */
  match: PropTypes.object,

  /**
   * The standard history expected to be passed to the calendar via React
   * Router.
   */
  history: PropTypes.object,
}

export default withRouter(keydown(CalendarView))
