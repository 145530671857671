import React, { Component } from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import moment from "moment"
import { View, Placeholder } from "../../shared"
import { DATE_FORMAT } from "../constants"
import MonthView from "./MonthView"
import PrintSettingsForm from "./PrintSettingsForm.react"
import styles from "./CalendarView.module.css"
import Page from "./Page.react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons"
import { PrintPollingModal } from "../../modals"
import toast from "react-hot-toast"
import clsx from "clsx"

const { ToolBar, ToolBarSection, ToolBarHeading, ToolBarAction } = View

/**
 * This component renders the general calendar UI as well as any specified
 * child renderer. A child renderer could be a MonthView, WeekView, or
 * MultiMonthView. This component is the parent component which provides any
 * related toolbars or other components necessary for navigating or updating
 * the calendar while the calendar itself is generated by any of the
 * aforementioned child components.
 *
 * NOTE: The CalendarView and it's subsequent child components in this module
 * could use a refactor down the road. I am hitting some road blocks in development
 * in which the content views of the calendar have several complex interactions
 * ands thus a LOT of props need to be passed down the chain to the actual
 * child components that need them. While there is no performance issues or
 * even necessarily any maintenance issues. It seems to me this makes the logic
 * of actually rendering the calendar less pure and more difficult to understand
 * and as such I feel a refactor should be considered in the future if time permits.
 */
class PrintableCalendarView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      scaleFactor: 1,
      loading: true,
    }
  }

  componentWillMount() {
    if (this.props.match.params.reportId) {
      this.populateReport()
    } else {
      this.populateCalendar()
    }
  }

  /**
   * Calculates the amount of weeks to render in any embedded
   * MonthView component based on the view mode.
   *
   * @param {Object} startMoment A momentJS object representing the start of a month.
   * @return {Integer} The number of weeks to render in embedded MonthViews.
   */
  getWeeksToDisplay(startMoment) {
    const ref = moment(startMoment) || moment()
    const trueStart = moment(ref).startOf("month").startOf("week")
    const trueEnd = moment(ref).endOf("month").endOf("week")
    const weeks = Math.abs(moment(trueStart).diff(trueEnd, "weeks")) + 1
    return weeks
  }

  /**
   * Calculates the best fit display template based on the time difference in
   * the URL.
   * @param {String} unit The type of unit to return the length / duration in.
   * @return {Integer} The number of months to display in the current view.
   */
  getCalendarDuration(unit) {
    const { startDate, endDate } = this.props.match.params
    const start = moment(startDate).startOf(unit)
    const end = moment(endDate).endOf(unit)
    if (!start.isValid() || !end.isValid()) {
      return 0
    }
    return end.diff(start, unit) + 1
  }

  /**
   * Updates the start and end date range used for printing.
   * @param {String} startsAt The new start date for the printable range.
   * @param {String} endsAt The new end date for the printable range.
   * @returns {Promise} A promise that resolves with the updated start/end date range.
   */
  handleUpdatedDateRange(startsAt, endsAt) {
    const { history } = this.props
    return new Promise((resolve) => {
      const startDate = moment(startsAt).startOf("month")
      const endDate =
        moment(endsAt).diff(startsAt) > 0
          ? moment(endsAt).endOf("month")
          : moment(startDate).endOf("month")
      history.replace(this.updateUrl({ startDate, endDate }))
      resolve({
        startsAt: startDate.format("YYYY-MM-DD"),
        endsAt: endDate.format("YYYY-MM-DD"),
      })
    })
  }

  /**
   * Generates a new url with the proper route params and query string based
   * on the current props/state of the component.
   * @param {Object} urlParams An object containing the date range and custom fields.
   * @returns {String} A new url with embedded params and query string variables.
   */
  updateUrl({ startDate, endDate, customFields }) {
    const { subdomain, calendarId, reportId, snapshotId } =
      this.props.match.params
    const start = (startDate || this.getStartDate()).format("YYYYMMDD")
    const end = (endDate || this.getEndDate()).format("YYYYMMDD")
    const fields = customFields || this.getCustomFields()
    const query = queryString.stringify({
      topLeft: fields.topLeft,
      topCenter: fields.topCenter,
      topRight: fields.topRight,
      bottomLeft: fields.bottomLeft,
      bottomCenter: fields.bottomCenter,
      bottomRight: fields.bottomRight,
      nameFontSize: fields.nameFontSize,
      monthFontSize: fields.monthFontSize,
      headerFontSize: fields.headerFontSize,
      footerFontSize: fields.footerFontSize,
      printCalendarName: fields.printCalendarName,
      viewStyle: fields.viewStyle,
      page: fields.page,
      showPageNumbers: fields.showPageNumbers,
      highContrast: fields.highContrast,
    })

    return reportId
      ? `/org/${subdomain}/reports/${reportId}/print/start/${start}/end/${end}?${query}`
      : snapshotId
      ? `/org/${subdomain}/cal/${calendarId}/snapshot/${snapshotId}/print/start/${start}/end/${end}?${query}`
      : `/org/${subdomain}/cal/${calendarId}/print/start/${start}/end/${end}?${query}`
  }

  /**
   * Retrieves the current custom fields from the current query string.
   * @returns {Object} An object with all of the custom fields parsed from the current query string.
   */
  getCustomFields() {
    const {
      topLeft,
      topRight,
      topCenter,
      bottomLeft,
      bottomCenter,
      bottomRight,
      nameFontSize,
      monthFontSize,
      headerFontSize,
      footerFontSize,
      printCalendarName,
      viewStyle,
      page,
      showPageNumbers,
      highContrast,
    } = this.getQuery()
    return {
      topLeft,
      topRight,
      topCenter,
      bottomLeft,
      bottomCenter,
      bottomRight,
      nameFontSize: nameFontSize || "0.75rem",
      monthFontSize: monthFontSize || "0.75rem",
      headerFontSize: headerFontSize,
      footerFontSize: footerFontSize || "0.75rem",
      printCalendarName: printCalendarName === "false" ? false : true,
      showPageNumbers: showPageNumbers === "false" ? false : true,
      highContrast: highContrast === "true" ? true : false,
      viewStyle: viewStyle || "monthly",
      page: page || 1,
    }
  }

  /**
   * Applies an updated view style mode to the query string url.
   * @param {string} viewStyle The new viewStyle to apply to the query.
   */
  updateViewStyle(viewStyle) {
    this.updateCustomFields({ viewStyle })
  }

  /**
   * Updates the query string for any custom fields that have
   * been updated by the user.
   * @param {Object} newFields An object containining any updated user defined fields.
   */
  updateCustomFields(newFields) {
    const { history } = this.props
    const currentFields = this.getCustomFields()
    const customFields = { ...currentFields, ...newFields }
    history.replace(this.updateUrl({ customFields }))
  }

  /**
   * Returns an acceptable start date provided it does not collide with the
   * current end date.
   * @param {Object} newStartDate A momentJS object representing a potential new start date of the print range.
   * @returns {Object} A new momentJS object representing the current start date of the print range.
   */
  getValidStartDate(newStartDate) {
    if (newStartDate.diff(this.getStartDate()) < 0) {
      return this.getStartDate()
    }
    return newStartDate.diff(this.getEndDate()) > 0
      ? this.getEndDate()
      : newStartDate
  }

  /**
   * Returns an acceptable end date provided it does not collide with the
   * current start date.
   * @param {Object} newEndDate A momentJS object representing a potential new end date of the print range.
   * @returns {Object} A new momentJS object representing the current end date of the print range.
   */
  getValidEndDate(newEndDate) {
    if (newEndDate.diff(this.getEndDate()) > 0) {
      return this.getEndDate()
    }
    return newEndDate.diff(this.getStartDate()) < 0
      ? this.getStartDate()
      : newEndDate
  }

  /**
   * Fetches the start date used to define the range for printing
   * from the route params.
   * @returns {Object} A new momentJS object representing the current start date of the print range.
   */
  getStartDate() {
    return moment(this.props.match.params.startDate).startOf("month")
  }

  /**
   * Fetches the end date used to define the range for printing
   * from the route params.
   * @returns {Object} A new momentJS object representing the current end date of the print range.
   */
  getEndDate() {
    return moment(this.props.match.params.endDate).endOf("month")
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateCalendar() {
    const { loadCalendar, loadSnapshot } = this.props
    const { subdomain, calendarId, snapshotId } = this.props.match.params
    this.setState(() => ({ loading: true }))
    Promise.all([
      snapshotId
        ? loadSnapshot(subdomain, calendarId, snapshotId)
        : Promise.resolve(),
      loadCalendar(subdomain, calendarId),
    ]).then((actions) => {
      actions
        .filter((result) => result && result.error)
        .forEach((action) => {
          toast.error(action.errorMessage)
        })
      this.setState(() => ({ loading: false }))
    })
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateReport() {
    const { requestReport, isAdmin, history } = this.props
    const { subdomain, reportId } = this.props.match.params
    if (!isAdmin(subdomain)) {
      history.replace("/404")
    } else {
      requestReport(subdomain, reportId).then((action) => {
        if (action.error) {
          history.replace("/404")
        }
      })
      this.populateReportCalendars(subdomain, reportId)
    }
  }

  /**
   * Loads any data necessary to render this calendar asynchronously.
   * TODO: Replace this method with a top level solution such as
   * redux-async-connect.
   */
  populateReportCalendars() {
    const { requestFullReportCalendars } = this.props
    const { subdomain, reportId } = this.props.match.params
    requestFullReportCalendars(subdomain, reportId).then((r) => {
      this.setState(() => ({ loading: false }))
    })
  }

  findEventsForView() {
    const { ...props } = this.props
    const { snapshotId, reportId, calendarId } = this.props.match.params
    if (this.isReport()) {
      return props.findCalendarEvents(props.findReportCalendarIds(reportId))
    }
    return snapshotId
      ? props.findSnapshotEvents(snapshotId)
      : props.findCalendarEvents(calendarId)
  }

  customColorsForCalendar() {
    const calendar = this.getCalendar() || {}
    const { weekendColor, cutoffColor, holidayColor } = calendar
    return { weekendColor, cutoffColor, holidayColor }
  }

  /**
   * A convenience method to determine whether or not a report is intended
   * to be rendered as opposed to a calendar.
   *
   * @return {Boolean} Will be true if the view is rendering a report.
   */
  isReport() {
    const { match } = this.props
    const { reportId } = match.params
    return reportId && parseInt(reportId, 0) > 0
  }

  /**
   * A convenience method to obtain the query string as a parsed object.
   *
   * @return {Object} Any query string variables.
   */
  getQuery() {
    return queryString.parse(this.props.location.search) || {}
  }

  /**
   * A convenience method to obtain the name of the current report or calendar
   * presented by the printable view.
   *
   * @return {String} The name of the resource presented by this view.
   */
  getResourceName() {
    const resource = this.isReport() ? this.getReport() : this.getCalendar()
    return resource ? resource.name : "..."
  }

  /**
   * Fetches the appropriate calendar Ids to render for a report or
   * individual calendar.
   *
   * @return {Array<Integer>} An array of matching calendarIds.
   */
  findCalendarIds() {
    const { match, findReportCalendarIds } = this.props
    const { reportId, calendarId } = match.params
    return this.isReport()
      ? findReportCalendarIds(reportId)
      : [parseInt(calendarId, 0)]
  }

  /**
   * Uses the current calendar based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current calendar.
   */
  getCalendar() {
    const { findCalendar, match } = this.props
    const { calendarId } = match.params
    return findCalendar(calendarId)
  }

  /**
   * Uses the current calendar based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current calendar.
   */
  getSnapshot() {
    const { findSnapshot, match } = this.props
    const { snapshotId } = match.params
    return findSnapshot(snapshotId)
  }

  /**
   * Uses the current report based on route params and retrieves
   * the actual model object from the redux store.
   *
   * @returns {Object} The JSON representation of the current report.
   */
  getReport() {
    return this.props.findReport(this.props.match.params.reportId)
  }

  /**
   * Generates the HTML title for the view based on the current resource being
   * presented by the view.
   *
   * @returns {String} A string representing the HTML title for the view.
   * @memberof PrintableCalendarView
   */
  getPageTitle() {
    const calendar = this.getCalendar()
    if (calendar) {
      return `${calendar.name} | Print View`
    } else {
      return "Loading Calendar"
    }
  }

  /**
   * Fetches the current calendar categories for the view.
   *
   * @returns {Array<Object>} An array of category entities from the redux store.
   * @memberof PrintableCalendarView
   */
  findCategoriesForView() {
    return this.props.findCalendarCategories(this.props.match.params.calendarId)
  }

  /**
   * Fetches the current item entities that will be
   * necessary to render the view.
   *
   * @returns {Array<Object>} An array of item entities fro mthe redux store.
   * @memberof PrintableCalendarView
   */
  findItemsForView() {
    return this.props.findCalendarItems(this.props.match.params.calendarId)
  }

  /**
   * A convenience method to return the user to the calendar or report
   * view represented by this printable version.
   *
   * @memberof PrintableCalendarView
   */
  returnToPresentingResource() {
    const { match, history } = this.props
    const { subdomain, calendarId, reportId, snapshotId } = match.params
    this.isReport()
      ? history.replace(`/org/${subdomain}/reports/${reportId}/`)
      : snapshotId
      ? history.replace(
          `/org/${subdomain}/cal/${calendarId}/snapshot/${snapshotId}`
        )
      : history.replace(`/org/${subdomain}/cal/${calendarId}/`)
  }

  getCurrentPage(totalPages) {
    const page = this.getQuery().page || 1
    return Math.min(totalPages - 1, Math.max(page - 1, 0))
  }

  renderYears() {
    const { isServer } = this.getQuery()
    const years = this.getCalendarDuration("years")
    return (
      <div className={styles.rows}>
        {Array(years)
          .fill()
          .map((_, offset) => {
            if (!isServer && offset !== this.getCurrentPage(years)) {
              return <span key={`row${offset}`} />
            }
            const PAGE_KEY = `YEAR${offset}_${moment(
              this.props.match.params.startDate
            )
              .startOf("year")
              .add(offset, "years")
              .format(DATE_FORMAT)}`
            return (
              <div
                className={clsx(styles.row, styles.print_preview_full_page)}
                key={`row${offset}`}
              >
                {this.renderYear(offset, PAGE_KEY, years)}
              </div>
            )
          })}
      </div>
    )
  }

  renderYear(yearOffset, keyBase, totalPages) {
    const { highContrast } = this.getQuery()
    const months = this.getCalendarDuration("months")
    const rows = 3
    const cols = 4
    if (months < 1) {
      return ""
    }
    return (
      <Page
        resourceName={this.getResourceName()}
        fields={this.getCustomFields()}
        onChangeFields={(fields) => this.updateCustomFields(fields)}
        page={yearOffset + 1}
        totalPages={totalPages}
      >
        <div className={styles.annual_print_preview}>
          {Array(rows)
            .fill()
            .map((_, row) => (
              <div
                className={[styles.row, styles.print_preview_row].join(" ")}
                key={`row${row}`}
              >
                {Array(cols)
                  .fill()
                  .map((__, col) => {
                    const monthOffset = col + row * cols
                    const { startDate } = this.props.match.params
                    const startsAt = moment(startDate)
                      .startOf("year")
                      .add(yearOffset, "years")
                      .add(monthOffset, "months")
                      .startOf("month")
                    return (
                      <div
                        style={{
                          width: `${100 / cols}%`,
                          display: "flex",
                          marginRight: col < cols - 1 ? 2 : 0,
                          marginTop: row > 0 && row < rows - 1 ? 2 : 0,
                          marginBottom: row > 0 && row < rows - 1 ? 2 : 0,
                        }}
                        key={`${keyBase}_${monthOffset}`}
                        className={clsx(
                          "border",
                          highContrast === "true"
                            ? "border-black"
                            : "border-gray-400"
                        )}
                      >
                        {this.renderMonth(startsAt, true, true)}
                      </div>
                    )
                  })}
              </div>
            ))}
        </div>
      </Page>
    )
  }

  /**
   * Renders all necessary months for the span of events that occur during
   * the calendar or calendars in a report.
   *
   * @returns {Object} A JSX snippet of month views grouped in a containing div as opposed to an array fragment.
   * @memberof PrintableCalendarView
   */
  renderMonths() {
    const { isServer, highContrast } = this.getQuery()
    const months = this.getCalendarDuration("months")
    const rows = months
    const cols = 1
    if (months < 1) {
      return ""
    }

    return (
      <div
        className={styles.rows}
        style={{
          overflowY: isServer ? "scroll" : undefined,
        }}
      >
        {Array(rows)
          .fill()
          .map((_, row) => {
            if (!isServer && row !== this.getCurrentPage(months)) {
              return <span key={`row${row}`} />
            }
            return (
              <div
                className={[styles.row, styles.print_preview_full_page].join(
                  " "
                )}
                key={`row${row}`}
              >
                {Array(cols)
                  .fill()
                  .map((__, col) => {
                    const monthOffset = col + row * cols
                    const { startDate } = this.props.match.params
                    const startsAt = moment(startDate)
                      .add(monthOffset, "months")
                      .startOf("month")
                    return (
                      <Page
                        key={`MONTH${monthOffset}_${moment(startDate)
                          .add(monthOffset, "months")
                          .format(DATE_FORMAT)}`}
                        resourceName={this.getResourceName()}
                        fields={this.getCustomFields()}
                        onChangeFields={(fields) =>
                          this.updateCustomFields(fields)
                        }
                        page={row + 1}
                        totalPages={rows}
                        highContrast={highContrast === "true"}
                      >
                        <div
                          className={clsx(
                            "flex flex-grow print:border",
                            highContrast === "true"
                              ? "border-black"
                              : "border-gray-400"
                          )}
                        >
                          {this.renderMonth(startsAt, false, false)}
                        </div>
                      </Page>
                    )
                  })}
              </div>
            )
          })}
      </div>
    )
  }

  getDarkDays() {
    const holidays = this.props.findHolidays(this.props.match.params.calendarId)
    const format = (d) => moment.utc(d).format(DATE_FORMAT)
    const dates = (c) =>
      Array((moment(c.endsAt).diff(moment(c.startsAt), "days") || 0) + 1)
        .fill("")
        .map((_, i) => moment.utc(c.startsAt).add(i, "day"))
        .map(format)
    const darkDays = holidays.reduce(
      (p, c) => ({
        ...p,
        ...dates(c).reduce(
          (pd, cd) => ({
            ...pd,
            [cd]: [...(p[cd] || []), ...(pd[cd] || []), c.name],
          }),
          {}
        ),
      }),
      {}
    )
    return darkDays
  }

  renderMonth(startsAt, minified, hideLabels = false) {
    const { highContrast } = this.getQuery()
    const props = this.props
    const { calendarId, reportId, snapshotId } = this.props.match.params
    const fields = this.getCustomFields()
    const calendarIds = reportId
      ? props.findRenderableCalendarIdsForReport(reportId)
      : [parseInt(calendarId, 0)]
    const colors = this.customColorsForCalendar()
    return (
      <MonthView
        getStartMoment={() => {
          return moment(startsAt)
        }}
        calendarIds={calendarIds}
        handleSelectMonth={(month) => this.handleViewSelection(1, "M", month)}
        handleSelectWeek={(week) => this.handleViewSelection(1, "w", week)}
        showDragUI={props.showDragUI}
        shiftEventPreview={props.shiftEventPreview}
        handleEditEvent={(id) => this.handleEditEvent(id)}
        handleItemDrop={(id, date) => this.handleItemDrop(id, date)}
        findItem={props.findItem}
        findStyle={props.findStyle}
        findLockedItem={() => false}
        handleSelectedEvent={(id) => id}
        selectedEvents={[]}
        clipboardEvents={[]}
        handleMouseDown={(selected) => selected}
        weeks={minified ? 6 : this.getWeeksToDisplay(startsAt)}
        simplified={minified}
        limited={minified}
        forPrint={true}
        monthNameFontSizeOverride={fields.monthFontSize}
        useDaySelectMode={false}
        selectedDate={props.selectedDate}
        handleDaySelection={(date) => date}
        expandEventPreview={() => false}
        readOnly={true}
        handleSettingsForDay={(date) => date}
        dateForSettingsMenu={props.dateForSettingsMenu}
        handleCreateHolidayOnDate={(date) => date}
        handleCreateNoteOnDate={(date) => date}
        handleCreateEventOnDate={(date) => date}
        handleEditHoliday={(id) => id}
        handleDeleteHolidays={(holidays) => holidays}
        darkDays={this.getDarkDays()}
        holidayColor={colors.holidayColor}
        weekendColor={colors.weekendColor}
        cutoffColor={colors.cutoffColor}
        reportId={reportId}
        snapshotId={snapshotId}
        indicatorDates={this.findIndicatorDatesForView()}
        highContrast={highContrast === "true"}
        hideLabels={hideLabels}
        weighted
      />
    )
  }

  /**
   * Renders a modal to displaying the print job that is currently polling.
   * @return {ReactClass} A component representing a snapshot form dialog.
   */
  renderPollingModal() {
    const { calendarId, reportId, subdomain } = this.props.match.params
    const handleDismiss = () =>
      this.setState({
        printingJobId: null,
        sentTo: null,
        downloadUrl: null,
      })
    return (
      <PrintPollingModal
        onClose={handleDismiss}
        sentTo={this.state.sentTo}
        jobId={this.state.printingJobId}
        calendarId={calendarId}
        reportId={reportId}
        subdomain={subdomain}
        downloadUrl={this.state.downloadUrl}
        onComplete={(downloadUrl) => this.setState({ downloadUrl })}
      />
    )
  }

  findIndicatorDatesForView() {
    const { findReportCalendars, findIndicatorDates, match } = this.props
    const { reportId } = match.params
    const inOutCalendarIds = findReportCalendars(reportId).filter(
      (r) => r.showInOut
    )
    const startStopDates = inOutCalendarIds
      .map(({ calendarId }) => {
        const result = findIndicatorDates(calendarId)
        return [
          { date: result.startDate, color: result.color },
          { date: result.endDate, color: result.color },
        ]
      })
      .reduce((a, c) => [...a, ...c], [])
      .reduce(
        (a, c) => ({ ...a, [c.date]: [...(a[c.date] || []), c.color] }),
        {}
      )
    return startStopDates
  }

  render() {
    const { loading } = this.state
    const { match, createCalendarPrintToken } = this.props
    const { endDate, startDate, calendarId, subdomain } = match.params
    const { isServer } = this.getQuery()

    if (loading) {
      return <Placeholder.Loading />
    }
    const fields = this.getCustomFields()
    const pages = this.getCalendarDuration(
      fields.viewStyle === "annual" ? "years" : "months"
    )
    const currentPage = this.getCurrentPage(pages) + 1
    return isServer ? (
      <div id="PrintableCalendar">
        {fields.viewStyle === "annual"
          ? this.renderYears()
          : this.renderMonths()}
      </div>
    ) : (
      <div className={styles.printControlsContainer} id="PrintableCalendar">
        <div className={styles.calendarPrintRendererContainer}>
          <Helmet title={this.getPageTitle()} />
          <ToolBar>
            <ToolBarSection ignoresGrid={true} required={true}>
              <ToolBarAction
                icon={faTimes}
                label=""
                primary={false}
                required={true}
                disabled={false}
                onClick={() => this.returnToPresentingResource()}
                toolTipText="Close"
                toolTipShortCut=""
              />
            </ToolBarSection>
            <ToolBarSection ignoresGrid={true} required={true} grow={true}>
              <ToolBarHeading
                primaryText={this.getResourceName()}
                secondaryText={"Print Preview"}
              />
            </ToolBarSection>
          </ToolBar>
          {fields.viewStyle === "annual"
            ? this.renderYears()
            : this.renderMonths()}
          <div className={styles.pageControl}>
            <div className={styles.pageControlContents}>
              {currentPage > 1 ? (
                <button
                  className={`${styles.pageControlButton} mx-2`}
                  onClick={() =>
                    this.updateCustomFields({ page: currentPage - 1 })
                  }
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
              ) : null}
              {currentPage} / {pages}
              {currentPage < pages ? (
                <button
                  className={`${styles.pageControlButton} mx-2`}
                  onClick={() =>
                    this.updateCustomFields({ page: currentPage + 1 })
                  }
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <PrintSettingsForm
          viewStyle={fields.viewStyle}
          onChangeViewStyle={(viewStyle) => {
            this.updateViewStyle(viewStyle)
          }}
          startsAt={moment(startDate).format("YYYY-MM-DD")}
          endsAt={moment(endDate).format("YYYY-MM-DD")}
          onDatesUpdated={(startsAt, endsAt) =>
            this.handleUpdatedDateRange(startsAt, endsAt)
          }
          printCalendarName={fields.printCalendarName}
          onChangePrintCalendarName={(newVal) =>
            this.updateCustomFields({
              printCalendarName: newVal,
            })
          }
          highContrast={fields.highContrast}
          onChangeHighContrastMode={(newVal) => {
            this.updateCustomFields({
              highContrast: newVal,
            })
          }}
          showPageNumbers={fields.showPageNumbers}
          onChangeShowPageNumbers={(newVal) =>
            this.updateCustomFields({
              showPageNumbers: newVal,
            })
          }
          monthFontSize={fields.monthFontSize}
          nameFontSize={fields.nameFontSize}
          headerFontSize={fields.headerFontSize}
          footerFontSize={fields.footerFontSize}
          onFontSizeUpdated={(fontSettings) => {
            this.updateCustomFields({
              ...fontSettings,
            })
          }}
          submitting={this.state.submitting}
          onPrint={() => {
            this.setState({ submitting: true })
            createCalendarPrintToken(
              subdomain,
              calendarId,
              window.location.href
            ).then(({ jobId, sentTo }) => {
              this.setState({ submitting: false, printingJobId: jobId, sentTo })
            })
          }}
        />
        {this.state.printingJobId ? this.renderPollingModal() : null}
      </div>
    )
  }
}

PrintableCalendarView.propTypes = {
  /**
   * A redux selector to query a given calendar by ID and
   * organization subdomain.
   */
  findCalendar: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given snapshot by ID and
   * organization subdomain.
   */
  findSnapshot: PropTypes.func.isRequired,

  /**
   * An array of holidays that apply to the current calendar.
   */
  findHolidays: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given report by ID and
   * organization subdomain.
   */
  findReport: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of events by calendarID.
   */
  findCalendarEvents: PropTypes.func.isRequired,

  /**
   * A redux selector to query a segment of events by calendarID.
   */
  findSnapshotEvents: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given set of calendars
   * for a reportId.
   */
  findReportCalendarIds: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any calendar
   * information available for a supplied ID and organization subdomain.
   * This action is a thunk and returns a promise when the network request
   * with the API has finished.
   */
  loadCalendar: PropTypes.func.isRequired,

  /**
   * A redux action creator mapped to dispatch which will load any snapshot
   * information available for a supplied ID and organization subdomain.
   * This action is a thunk and returns a promise when the network request
   * with the API has finished.
   */
  loadSnapshot: PropTypes.func.isRequired,

  /**
   * A redux router action creator to replace the current URL without
   * affecting the replace state in HTML5 history.
   */
  history: PropTypes.object.isRequired,

  /**
   * Loads a report based on subdomain and ID.
   */
  requestReport: PropTypes.func.isRequired,

  /**
   * Load full calendars based on the report.
   */
  requestFullReportCalendars: PropTypes.func.isRequired,

  /**
   * Generates a temporary access token to send to our external print service..
   */
  createCalendarPrintToken: PropTypes.func.isRequired,

  /**
   * Determines whether or not the current user is an admin
   * or not.
   */
  isAdmin: PropTypes.func.isRequired,

  /**
   * A react router location object containing the current query string.
   */
  location: PropTypes.object,

  /**
   * The standard params expected to be passed to the calendar via React
   * Router.
   */
  match: PropTypes.object,

  /**
   * A redux selector to query a given set of calendars
   * that will be rendered for a reportId. (ignores calendars using in/out only)
   */
  findRenderableCalendarIdsForReport: PropTypes.func.isRequired,

  /**
   * A redux selector to query a given set of report calendars
   * for a reportId.
   */
  findReportCalendars: PropTypes.func.isRequired,

  /**
   * A redux selector that queries the start / end dates a calendar.
   */
  findIndicatorDates: PropTypes.func.isRequired,
}

export default withRouter(PrintableCalendarView)
