import moment from "moment"
import { eventSelector, itemSelector } from "../../api"

/**
 * Normalize a date to UTC time.
 * @param {*} d A date or string.
 */
export const makeDate = (d) => moment.utc(d)

/**
 * Normalize an array of dates to determine if a supplied date is
 * included in a given set.
 * @param {*} date The date to test.
 * @param {*} dates The dates to test against.
 */
const dateIncludedInSet = (date, dates) =>
  dates
    .map((d) => makeDate(d).format("YYYY-MM-DD"))
    .includes(date.format("YYYY-MM-DD"))

/**
 * Generate a helper to test if a date belongs to a set within a given date range.
 * @param {*} dates An array of dates.
 * @param {*} start The start of the date range.
 * @param {*} end The end of the date range.
 */
const dateIncludedInRange = (dates, start, end) => (d) =>
  dateIncludedInSet(d, dates) && d.isBetween(start, end, "days", "[]")

/**
 * Determine the true end date of an event.
 * @param {*} options A configuration object to configure the calculation.
 */
const calculateFinalDate = ({
  maxIterations,
  potentialOccurrences,
  blockedDates,
  startDate,
  daysApart,
}) => {
  let finalDate = null
  let finalDateIsBlocked = true
  let iterations = 0
  while (finalDateIsBlocked && iterations <= maxIterations) {
    const endDate = finalDate || startDate
    const cannotOccurOn = blockedDates
      .map(makeDate)
      .filter(dateIncludedInRange(potentialOccurrences, startDate, endDate))
    finalDate = makeDate(startDate).add(
      cannotOccurOn.length * daysApart,
      "days"
    )
    finalDateIsBlocked = dateIncludedInSet(finalDate, potentialOccurrences)
    potentialOccurrences.push(finalDate)
    iterations += 1
  }
  return finalDate
}

/**
 * Determines the true start/end dates for an item with recurring events.
 * @param {*} options The item/start date to perform calculations for.
 */
const calculateDatesForRecurringItem = ({ item, startDate }) => {
  const dates = []
  const potentialCount =
    item.numberOfIterationsRecurring + (item.cannotOccurOn || []).length
  const potentialOccurrences = Array(potentialCount)
    .fill()
    .map((_, i) => makeDate(startDate).add(item.recurringDaysApart * i, "days"))
  let nextAvailableDate = startDate
  while (dates.length <= item.numberOfIterationsRecurring) {
    const date = calculateFinalDate({
      maxIterations: (item.cannotOccurOn || []).length,
      potentialOccurrences,
      startDate: nextAvailableDate,
      blockedDates: item.cannotOccurOn || [],
      daysApart: item.recurringDaysApart,
    })
    dates.push(date)
    nextAvailableDate = makeDate(date).add(item.recurringDaysApart, "days")
  }
  return dates
}

/**
 * Applies an offset to the start date of an event. This method will perform a more
 * complex operation if the event belongs to a recurring item.
 * @param  {Object}   state           The current state of the redux store.
 * @param  {Array}    event           The event to recalculate an effective start date.
 * @param  {Integer}  offset          The amount of offset in days to apply.
 * @return {Object}                   An instance of momentJS for the newly calculated date.
 */
export const offsetDateForEvent = (state, event, offset) => {
  const events = eventSelector.forItem(state)(event.itemUuid, event.calendarId)
  // Simple item, just offset the event
  if (events.length === 1) {
    return makeDate(event.startsAt).add(offset, "days")
  }
  // Recurring item requires recalculation...
  const item = itemSelector.find(state)(event.itemUuid, event.calendarId)
  const offsetItemStart = makeDate(events[0].startsAt).add(offset, "days")
  const index = events.map((e) => e.uuid).indexOf(event.uuid)

  const dates = calculateDatesForRecurringItem({
    item,
    startDate: offsetItemStart,
  })

  return dates[index]
}
