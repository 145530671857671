import ShortListItem from "./ShortListItem.react"
import ShortList from "./ShortList.react"
import ShortListIcon from "./ShortListIcon.react"
import ShortListLabel from "./ShortListLabel.react"
import ShortListGroup from "./ShortListGroup.react"
import ShortListSearchField from "./ShortListSearchField.react"
import ShortListSecondaryAction from "./ShortListSecondaryAction.react"

const List = ShortList
const Item = ShortListItem
const Label = ShortListLabel
const Icon = ShortListIcon
const Group = ShortListGroup
const Search = ShortListSearchField
const SecondaryAction = ShortListSecondaryAction

/* eslint-disable complexity */
const truncate = (str, length, ending, tail) => {
  if (length === null) {
    return str
  }
  let separator = "..."
  if (ending !== null) {
    separator = ending
  }
  let tailContent = ""
  if (tail) {
    tailContent = tail ? str.substring(str.length - tail, str.length) : ""
  }
  if (str.length > length) {
    return (
      str.substring(0, length - separator.length - tailContent.length) +
      separator +
      tailContent
    )
  } else {
    return str
  }
}
/* eslint-enable complexity */

const content = {
  List,
  Item,
  Label,
  Icon,
  Group,
  SecondaryAction,
  Search,
  truncate,
}
export default content
