import * as components from "./components"
import * as containers from "./containers"
import * as constants from "./constants"
import * as selectors from "./selectors"
import reducer from "./reducer"

const content = { components, containers, constants, reducer, selectors }
export default content
export const { CalendarBlankSlate, CalendarDetail } = containers
export const { CalendarImportView } = components
