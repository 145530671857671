var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown, faFontCase, faRepeat, } from "@fortawesome/pro-regular-svg-icons";
import { IconButton } from "./Navigation";
var INPUT_CLASS = "flex flex-grow rounded-full border border-gray-300 px-4 py-1 bg-white";
var FORM_CLASS = "flex flex-shrink-0 flex-col my-2 px-2";
export var SearchForm = function (_a) {
    var onSearchChange = _a.onSearchChange, onReplaceChange = _a.onReplaceChange, onToggleCase = _a.onToggleCase, _b = _a.searchValue, searchValue = _b === void 0 ? "" : _b, _c = _a.replaceValue, replaceValue = _c === void 0 ? "" : _c, caseSensitive = _a.caseSensitive, onReplaceAll = _a.onReplaceAll, replaceAllowed = _a.replaceAllowed, disabled = _a.disabled;
    var _d = useState(false), showReplace = _d[0], setShowReplace = _d[1];
    var handleChange = function (type) { return function (e) {
        var handler = type === "search" ? onSearchChange : onReplaceChange;
        handler(e.currentTarget.value);
    }; };
    var handleToggleCase = function () {
        if (onToggleCase) {
            onToggleCase(!caseSensitive);
        }
    };
    var handleToggleClick = function (e) {
        e.preventDefault();
        setShowReplace(!showReplace);
    };
    return (_jsx("form", __assign({ className: FORM_CLASS }, { children: _jsxs("div", __assign({ className: "flex flex-grow" }, { children: [replaceAllowed ? (_jsx("button", __assign({ className: "p-2 w-8 text-center text-sm text-body", onClick: handleToggleClick }, { children: _jsx(FontAwesomeIcon, { icon: showReplace ? faChevronDown : faChevronRight }, void 0) }), void 0)) : null, _jsxs("ul", __assign({ className: "flex flex-grow flex-col" }, { children: [_jsxs("li", __assign({ className: "flex flex-grow" }, { children: [_jsx("input", { type: "text", placeholder: "search calendar...", onChange: handleChange("search"), name: "search", value: searchValue, className: INPUT_CLASS, autoComplete: "off", disabled: disabled }, void 0), _jsx(IconButton, { icon: faFontCase, onClick: handleToggleCase, tooltipAlignment: "right", tooltip: "Case Sensitive", inactive: !caseSensitive, className: "ml-2" }, void 0)] }), void 0), showReplace && replaceAllowed ? (_jsxs("li", __assign({ className: "flex flex-grow mt-2" }, { children: [_jsx("input", { type: "text", placeholder: "replace text", onChange: handleChange("replace"), name: "search", value: replaceValue, className: INPUT_CLASS, autoComplete: "off" }, void 0), _jsx(IconButton, { icon: faRepeat, onClick: onReplaceAll, tooltipAlignment: "right", tooltip: "Replace All", disabled: (replaceValue !== null && replaceValue !== void 0 ? replaceValue : "").length < 1, className: "ml-2" }, void 0)] }), void 0)) : null] }), void 0)] }), void 0) }), void 0));
};
