import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const ModalContainer: React.FC = ({ children }) => {
  return (
    <div className="bg-gradient-to-br from-revolution-purple via-revolution-blue to-revolution-green fixed inset-0">
      <Helmet titleTemplate="%s | ProCal" defaultTitle="Welcome" />
      {children}
    </div>
  )
}

ModalContainer.propTypes = {
  children: PropTypes.node,
}

export default ModalContainer
