import { updateEvent } from "./updateEvent"
import { offsetDateForEvent } from "./offsetDateForEvent"
import { eventSelector } from "../../api"

/**
 * Passes a batch of events to the update algorithm. Used when the user is dragging
 * items around with the multi-select mode enabled.
 * @param  {Object}   state           The current state of the redux store.
 * @param  {Array}    selectedEvents  An array of strings representing the uuids of selected events.
 * @param  {Integer}  calendarId      The calendar id the events are scoped to.
 * @param  {Integer}  offset          The amount of offset to apply to the events being adjusted.
 * @return {Object}                   An object containing the updated events in a normalized object.
 */
export const updateDatesForEventsWithIDs = (
  state,
  selectedEvents,
  calendarId,
  offset
) =>
  selectedEvents
    .map((id) => eventSelector.find(state)(id, calendarId))
    .map((e) => {
      if (!e) {
        console.error(
          `Could not update event '${e}' for '${selectedEvents.join(
            ","
          )}' on calendar '${calendarId}'`
        )
        return {}
      }
      return updateEvent(
        e,
        offsetDateForEvent(state, e, offset),
        true,
        e.position,
        e.partition,
        state
      )
    })
    .reduce((acc, e) => Object.assign({}, acc, e), {})
