import React, { useEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import {
  AsyncSubmitHandler,
  ForgotPasswordForm,
  ForgotPasswordValues,
  Modal,
} from "ui"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { wait } from "utils"
import { createForgotPassword } from "../api"

export interface ForgotModalProps {}

export const ForgotModal: React.FC<ForgotModalProps> = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const onSubmit: AsyncSubmitHandler<ForgotPasswordValues> = async (data) => {
    const { email } = data
    setLoading(true)
    await wait(1)
    try {
      const { response } = (await dispatch(
        createForgotPassword(email.toLowerCase())
      )) as any
      if ([200, 201].includes(response.status)) {
        toast.success(
          "If your account exists, a reset password email has been sent."
        )
        setSuccess(true)
      } else {
        toast.error("There was a problem connecting to the server.")
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  const field = useRef<HTMLInputElement>(null)
  useEffect(() => {
    field.current?.focus()
  })

  return (
    <Modal title="Forgot Your Password" open>
      <Helmet title="Forgot Your Password" />
      {!success ? (
        <ForgotPasswordForm
          onSubmit={onSubmit}
          defaultValues={{
            email: "",
          }}
          loading={loading}
        />
      ) : (
        <p className="text-md font-bold text-center p-4 border rounded my-3">
          Please check your inbox for further instructions.
        </p>
      )}
      <p className="text-center mx-auto pb-3">
        Existing user?{" "}
        <Link to="/login" className="text-lochivar-default font-bold">
          Click here to login.
        </Link>
      </p>
      <p className="border-t border-gray-400 my-3 pt-3 text-center">
        <strong>Have questions or suggestions?</strong>
        <br />
        Please email <a href="mailto:support@revolutiones.com">support</a> or
        call 818.303.3306
      </p>
      <p className="text-xs text-center">
        Powered by{" "}
        <a href="https://www.revolutiones.com" className="text-sorbus-default">
          Revolution Entertainment Services
        </a>
      </p>
      <p className="text-xs text-center">
        <a
          href="http://www.revolutiones.com/#/termsofuse"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Terms of Service
        </a>{" "}
        |{" "}
        <a
          href="http://www.revolutiones.com/#/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sorbus-default"
        >
          Privacy Policy
        </a>
      </p>
    </Modal>
  )
}
