import { useState } from "react"
import {
  OnBoardLayout,
  AsyncSubmitHandler,
  CompanyForm,
  CompanyFormValues,
  DEFAULT_COMPANY_COLOR,
} from "ui"
import { wait } from "utils"
import { createOrganization } from "../api"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import calendarScreenshot from "./calendar-screen.png"
import { useHistory } from "react-router"
import logoPath from "../images/ProCal-logo-and-wordmark.svg"

const quote = {
  author: "Jeffrey L. Weaver",
  title: "Show Runner / Executive Producer",
  text: `ProCal has changed everything about my production schedule workflow - for the better. Making and, even more importantly, revising and shifting schedules is easier than it’s ever been. I run unscripted television series and our schedules are always changing - especially now. ProCal’s functionality is ideal for this. There is simply no product like it or even close to it - nor has there ever been. I recommend it to every EIC & VP I work with and they all thank me.`,
}

export const OnboardFlowCompany = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleCompany: AsyncSubmitHandler<CompanyFormValues> = async ({
    companyName,
    color,
    subdomain,
  }) => {
    setLoading(true)
    await wait(1)
    try {
      const { response } = (await dispatch(
        createOrganization({
          name: companyName,
          color,
          subdomain,
        })
      )) as any
      setLoading(false)
      if ([200, 201].includes(response.status)) {
        toast.success("Your organization has been created!")
        history.push(`/org/${subdomain}/cal`)
      } else {
        toast.error(
          response?.responseJson?.error ??
            "There was a problem creating your account."
        )
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return undefined
  }

  return (
    <OnBoardLayout
      quoteAuthor={quote.author}
      quoteAuthorTitle={quote.title}
      quote={quote.text}
      headline="Setup Your Company"
      subheadline="Your Company Will get a Free Trial"
      screenshotPath={calendarScreenshot}
      logoPath={logoPath}
    >
      <CompanyForm
        onSubmit={handleCompany}
        loading={loading}
        defaultValues={{
          companyName: "",
          color: DEFAULT_COMPANY_COLOR(),
          subdomain: "",
        }}
      />
    </OnBoardLayout>
  )
}
