var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect, useRef, useState, useContext, useCallback, useMemo, } from "react";
import { DateTime } from "luxon";
import clsx from "clsx";
import Color from "color";
import { SizeContext } from "../Window";
import { useAnimationFrame, useEventListener, useSharedRef } from "../../hooks";
import { clear, rect, line, measure, roundedRect } from "../../canvas";
import { renderMonth } from "./renderMonth";
import { renderEvent } from "./renderEvent";
import { getSegments } from "./ganntHelpers";
var SCALE = window.devicePixelRatio;
var HEADER_SIZE = 60;
export var GanntTimeline = React.forwardRef(function (_a, forwardedRef) {
    var _b;
    var _c = _a.borderColor, borderColor = _c === void 0 ? "#CECECE" : _c, _d = _a.cellBackgroundColor, cellBackgroundColor = _d === void 0 ? "#fff" : _d, _e = _a.inactiveCellBackgroundColor, inactiveCellBackgroundColor = _e === void 0 ? "#E9EAED" : _e, _f = _a.weekendCellBackgroundColor, weekendCellBackgroundColor = _f === void 0 ? "#E9EAED" : _f, labelColor = _a.labelColor, secondaryLabelColor = _a.secondaryLabelColor, _g = _a.todayFillColor, todayFillColor = _g === void 0 ? "#dc6939" : _g, _h = _a.hoverColor, hoverColor = _h === void 0 ? "#76b4f719" : _h, _j = _a.selectedColor, selectedColor = _j === void 0 ? "#4e83bc" : _j, startDate = _a.startDate, _k = _a.showToday, showToday = _k === void 0 ? false : _k, _l = _a.expanded, expanded = _l === void 0 ? {} : _l, _m = _a.disabled, disabled = _m === void 0 ? {} : _m, selectedDate = _a.selectedDate, handleSelectDate = _a.onSelectDate, handleSelectEvent = _a.onSelectEvent, handleContextMenu = _a.onContextMenu, externalWidth = _a.width, externalHeight = _a.height, _o = _a.darkDays, externalDarkDays = _o === void 0 ? {} : _o, _p = _a.externalLayoutChange, externalLayoutChange = _p === void 0 ? 0 : _p, _q = _a.scale, scale = _q === void 0 ? SCALE : _q, _r = _a.cellWidth, cellWidth = _r === void 0 ? 40 : _r, contents = _a.contents, _s = _a.rowHeight, rowHeight = _s === void 0 ? 20 : _s, _t = _a.rowPadding, rowPadding = _t === void 0 ? 2 : _t, divideRows = _a.divideRows, _u = _a.selectedEventIds, selectedEventIds = _u === void 0 ? [] : _u, handleEventAdjustment = _a.onAdjustEvent, _v = _a.renderingMode, renderingMode = _v === void 0 ? "days" : _v, _w = _a.showHandles, showHandles = _w === void 0 ? false : _w;
    var containerRef = useSharedRef(null, [
        forwardedRef,
    ]);
    var _x = useState({ state: "up" }), mouse = _x[0], setMouse = _x[1];
    var darkDays = useRef(externalDarkDays);
    darkDays.current = externalDarkDays;
    var _y = useState({ x: -2200, y: 0 }), _z = _y[0], scrollX = _z.x, scrollY = _z.y, setPos = _y[1];
    var windowSize = useContext(SizeContext).size;
    var _0 = useState({ eventId: null, calendarId: null }), currentEvent = _0[0], setCurrentEvent = _0[1];
    var _1 = useState(9999), scrollWidth = _1[0], setScrollWidth = _1[1];
    var _2 = useState(externalLayoutChange), lastLayout = _2[0], setLastLayout = _2[1];
    var _3 = useState({
        width: externalWidth !== null && externalWidth !== void 0 ? externalWidth : 600,
        height: externalHeight !== null && externalHeight !== void 0 ? externalHeight : 100,
    }), size = _3[0], setSize = _3[1];
    var _4 = useState({
        width: externalWidth !== null && externalWidth !== void 0 ? externalWidth : 600,
        height: externalHeight !== null && externalHeight !== void 0 ? externalHeight : 100,
    }), canvasSize = _4[0], setCanvasSize = _4[1];
    var canvasRef = useRef(null);
    var width = externalWidth !== null && externalWidth !== void 0 ? externalWidth : size.width;
    var height = externalHeight !== null && externalHeight !== void 0 ? externalHeight : size.height;
    var startDateRef = useMemo(function () {
        return DateTime.fromISO(startDate, { zone: "utc" })
            .startOf("month")
            .startOf("day");
    }, [startDate]);
    var callback = useMemo(function () { return function (_) {
        setPos(function () {
            var _a, _b, _c, _d;
            return ({
                x: -((_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== null && _b !== void 0 ? _b : 0),
                y: -((_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.scrollTop) !== null && _d !== void 0 ? _d : 0),
            });
        });
    }; }, [containerRef]);
    useAnimationFrame(callback);
    useEffect(function () {
        var scrollListener = function () {
            if (containerRef.current && canvasRef.current) {
                canvasRef.current.style.transform = "translate(" + containerRef.current.scrollLeft + "px, " + containerRef.current.scrollTop + "px)";
            }
        };
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", scrollListener);
        }
        var container = containerRef.current;
        return function () {
            container === null || container === void 0 ? void 0 : container.removeEventListener("scroll", scrollListener);
        };
    }, [containerRef, canvasRef]);
    // Handle any layout changes due to window resize.
    var updateSize = useCallback(function () {
        var container = containerRef.current;
        if (!container) {
            return;
        }
        if ((size === null || size === void 0 ? void 0 : size.width) !== container.offsetWidth ||
            (size === null || size === void 0 ? void 0 : size.height) !== container.offsetHeight) {
            setSize({
                width: container.offsetWidth,
                height: container.offsetHeight,
            });
        }
    }, [size, containerRef]);
    // Handle any layout changes due to window resize.
    var updateComponentSizes = useCallback(function () {
        var canvas = canvasRef.current;
        var ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
        if (!ctx || !canvas || !windowSize) {
            return;
        }
        if ((canvasSize === null || canvasSize === void 0 ? void 0 : canvasSize.width) !== canvas.offsetWidth ||
            (canvasSize === null || canvasSize === void 0 ? void 0 : canvasSize.height) !== canvas.offsetHeight) {
            setCanvasSize({
                width: canvas.offsetWidth,
                height: height,
            });
        }
    }, [canvasSize, canvasRef, height, windowSize]);
    useLayoutEffect(function () {
        updateSize();
        updateComponentSizes();
    }, [canvasSize, updateSize, updateComponentSizes]);
    // Trigger any forced layout changes via an arbitrary notification from an external ui component.
    if (lastLayout !== externalLayoutChange) {
        setTimeout(function () {
            setLastLayout(externalLayoutChange);
            setTimeout(function () { return updateSize(); }, 50);
        }, 250);
    }
    // Update hover styling via mouse over/out...
    var getPosForMouse = useCallback(function (mouseX, mouseY) {
        if (!canvasRef.current) {
            return {};
        }
        var bounds = canvasRef.current.getBoundingClientRect();
        var translatedMouseX = mouseX - bounds.x;
        var translatedMouseY = mouseY - bounds.y;
        var dayIndex = Math.floor((translatedMouseX - scrollX) / cellWidth);
        return {
            coord: { x: translatedMouseX, y: translatedMouseY },
            dayIndex: dayIndex,
        };
    }, [cellWidth, scrollX]);
    var handleMouseMove = useCallback(function (e) {
        var _a = getPosForMouse(e.pageX, e.pageY), coord = _a.coord, dayIndex = _a.dayIndex;
        setMouse(function (prevMouse) { return (__assign(__assign({}, prevMouse), { state: prevMouse.state === "down" ? "dragging" : prevMouse.state, coord: coord, dayIndex: dayIndex })); });
    }, [getPosForMouse]);
    var handleMouseOut = useCallback(function (e) {
        setMouse({ state: "up" });
    }, [setMouse]);
    // Handle any date selections.
    var getCurrentDateFromMouse = useCallback(function (dayIndex) {
        return DateTime.fromISO(startDate, { zone: "utc" })
            .plus({ days: dayIndex !== null && dayIndex !== void 0 ? dayIndex : 0 })
            .toISO();
    }, [startDate]);
    var lastDown = useRef(null);
    var handleMouseDown = useCallback(function (e) {
        e.preventDefault();
        var _a = getPosForMouse(e.pageX, e.pageY), coord = _a.coord, dayIndex = _a.dayIndex;
        setMouse({
            state: "down",
            coord: coord,
            dayIndex: dayIndex,
            originDayIndex: dayIndex,
            origin: coord,
            originId: currentEvent.eventId,
            originCalendarId: currentEvent.calendarId,
        });
    }, [setMouse, currentEvent, getPosForMouse]);
    var handleMouseUp = useCallback(function (e) {
        var _a, _b;
        e.preventDefault();
        var originId = mouse.originId, originCalendarId = mouse.originCalendarId, _c = mouse.originDayIndex, originDayIndex = _c === void 0 ? 0 : _c, _d = mouse.dayIndex, dayIndex = _d === void 0 ? 0 : _d, state = mouse.state;
        var eventId = (_a = currentEvent.eventId) !== null && _a !== void 0 ? _a : originId;
        var calendarId = (_b = currentEvent.calendarId) !== null && _b !== void 0 ? _b : originCalendarId;
        var date = getCurrentDateFromMouse(dayIndex);
        if (!eventId && handleSelectDate) {
            handleSelectDate(date);
        }
        else if (eventId && calendarId && handleSelectEvent) {
            handleSelectEvent(eventId, calendarId);
        }
        if (state === "dragging" && handleEventAdjustment) {
            var event_1 = contents === null || contents === void 0 ? void 0 : contents.find(function (_a) {
                var id = _a.id;
                return id === originId;
            });
            if (event_1) {
                var startsAt = DateTime.fromISO(event_1.startsAt, {
                    zone: "utc",
                }).startOf("day");
                var offset = dayIndex - originDayIndex;
                handleEventAdjustment({
                    eventId: originId !== null && originId !== void 0 ? originId : "",
                    startsAt: startsAt.plus({ days: offset }).startOf("day").toISO(),
                    length: event_1.length,
                    offset: offset,
                });
            }
        }
        setMouse({ state: "up" });
    }, [
        mouse,
        setMouse,
        getCurrentDateFromMouse,
        handleSelectDate,
        handleSelectEvent,
        currentEvent,
        handleEventAdjustment,
        contents,
    ]);
    // Forward any context navigation requests.
    var handleRightClick = useCallback(function (e) {
        var _a;
        if (handleContextMenu) {
            e.preventDefault();
            e.stopPropagation();
            var date = DateTime.fromISO(startDate, { zone: "utc" })
                .plus({ days: (_a = mouse.dayIndex) !== null && _a !== void 0 ? _a : 0 })
                .toISO();
            handleContextMenu({
                x: e.clientX,
                y: e.clientY,
                date: date,
                eventId: currentEvent.eventId,
            });
        }
    }, [handleContextMenu, mouse, startDate, currentEvent.eventId]);
    useEventListener({
        type: "contextmenu",
        element: canvasRef,
        listener: handleRightClick,
    });
    var contentsJSON = JSON.stringify(contents);
    //
    // Primary Render Effect
    //
    useEffect(function () {
        var _a, _b;
        var canvas = canvasRef.current;
        var ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
        if (!ctx || !canvas) {
            return;
        }
        // Reset drawing context.
        clear(ctx, { x: 0, y: 0, w: width, h: height });
        // Calculate start date base on scroll position
        var currentDateIndex = -Math.floor(scrollX / cellWidth);
        var earliestDay = startDateRef
            .plus({ days: currentDateIndex })
            .startOf("day");
        // Calculate max visible date
        var latestMonth = earliestDay
            .plus({ days: Math.ceil(width / cellWidth) })
            .startOf("month")
            .startOf("day");
        // Render each month
        var months = Math.max(1, Math.ceil(latestMonth.diff(earliestDay, "months").months) + 1);
        new Array(months).fill(null).forEach(function (_, idx, array) {
            // Allows to reverse the rendering order of the months
            var startFromTheEnd = array.length - 1 - idx;
            var monthStart = earliestDay
                .startOf("month")
                .plus({ months: startFromTheEnd })
                .startOf("day");
            var monthX = monthStart.diff(startDateRef, "days").days * cellWidth + scrollX;
            // Calculate days for current month
            var end = DateTime.fromISO(monthStart.toISO(), { zone: "utc" })
                .endOf("month")
                .startOf("day");
            var days = end.diff(monthStart, "days").days + 1;
            // Hover index
            var hoverIndex = !(currentEvent === null || currentEvent === void 0 ? void 0 : currentEvent.eventId) && mouse.dayIndex
                ? mouse.dayIndex - monthStart.diff(startDateRef, "days").days
                : null;
            renderMonth(ctx, {
                days: days,
                cellWidth: cellWidth,
                darkDays: darkDays.current,
                pos: { x: monthX, y: scrollY },
                cellBackgroundColor: cellBackgroundColor,
                borderColor: borderColor,
                labelColor: labelColor,
                selectedDate: selectedDate,
                hoverColor: hoverColor,
                inactiveCellBackgroundColor: inactiveCellBackgroundColor,
                weekendCellBackgroundColor: weekendCellBackgroundColor,
                selectedColor: selectedColor,
                secondaryLabelColor: secondaryLabelColor,
                height: height,
                hoverIndex: hoverIndex,
                dragging: mouse.state === "dragging",
                start: monthStart,
                renderingMode: renderingMode,
                todayFillColor: todayFillColor,
                showToday: showToday,
            });
        });
        if (contents) {
            var labelSize_1 = 11;
            var minIndex_1 = Math.abs(Math.floor(scrollY / rowHeight)) - 2;
            var maxIndex_1 = minIndex_1 + Math.ceil(canvasSize.height / rowHeight) + 2;
            var _c = (_a = mouse.coord) !== null && _a !== void 0 ? _a : {}, _d = _c.x, hoverX_1 = _d === void 0 ? 0 : _d, _e = _c.y, hoverY_1 = _e === void 0 ? 0 : _e;
            var interactiveEvent_1 = null;
            var interactiveCalendar_1 = null;
            ctx.save(); // save context prior to clipping
            rect(ctx, {
                x: 0,
                y: HEADER_SIZE,
                w: canvasSize.width,
                h: canvasSize.height - HEADER_SIZE,
            });
            ctx.clip();
            contents === null || contents === void 0 ? void 0 : contents.forEach(function (result, i) {
                var _a, _b, _c, _d, _e;
                if (i < minIndex_1 || i > maxIndex_1) {
                    return;
                }
                var y = HEADER_SIZE + i * rowHeight + scrollY + rowPadding;
                if ("startsAt" in result) {
                    var event_2 = result;
                    if (!event_2.disabled) {
                        var segments_1 = getSegments(event_2.startsAt, event_2.length, (_a = event_2.interruptedByHolidays) !== null && _a !== void 0 ? _a : false, (_b = event_2.interruptedByWeekends) !== null && _b !== void 0 ? _b : false, Object.keys(darkDays.current));
                        segments_1.forEach(function (segment, i) {
                            var x = Math.floor(DateTime.fromISO(segment.startDate, { zone: "utc" })
                                .startOf("day")
                                .diff(startDateRef, "days").days) *
                                cellWidth +
                                scrollX;
                            var w = segment.duration * cellWidth;
                            var h = rowHeight - rowPadding * 2;
                            var mouseOver = renderEvent(ctx, {
                                x: x,
                                y: y,
                                w: w,
                                h: h,
                                name: i === segments_1.length - 1 ? event_2.name : "",
                                hover: { x: hoverX_1, y: hoverY_1 },
                                labelSize: labelSize_1,
                                radius: h / 2,
                                selected: selectedEventIds.includes(event_2.id),
                                dragging: mouse.originId === event_2.id,
                                strokeStyle: event_2.outline,
                                fillStyle: event_2.background,
                                selectedColor: selectedColor,
                                showHandles: showHandles,
                            }).mouseOver;
                            if (mouseOver) {
                                interactiveEvent_1 = event_2.id;
                                interactiveCalendar_1 = event_2.calendarId;
                            }
                        });
                    }
                }
                if ("contents" in result && !disabled[result.id]) {
                    var contentSegments = result.contents
                        .map(function (ev) {
                        var _a, _b;
                        var e = ev;
                        return getSegments(e.startsAt, e.length, (_a = e.interruptedByWeekends) !== null && _a !== void 0 ? _a : false, (_b = e.interruptedByHolidays) !== null && _b !== void 0 ? _b : false, Object.keys(darkDays.current));
                    })
                        .flat();
                    var earliestDate = contentSegments
                        .map(function (s) { return s.startDate; })
                        .sort()[0];
                    var latestDate = contentSegments
                        .map(function (s) {
                        return DateTime.fromISO(s.startDate, { zone: "utc" })
                            .plus({ days: s.duration })
                            .toISO();
                    })
                        .sort()
                        .reverse()[0];
                    var length_1 = DateTime.fromISO(latestDate, { zone: "utc" })
                        .startOf("day")
                        .diff(DateTime.fromISO(earliestDate, { zone: "utc" }).startOf("day"), "days").days;
                    var x = Math.floor(DateTime.fromISO(earliestDate, { zone: "utc" })
                        .startOf("day")
                        .diff(startDateRef, "days").days) *
                        cellWidth +
                        scrollX;
                    var w = length_1 * cellWidth;
                    var h = rowHeight - rowPadding * 6;
                    var fillStyle = Color((_c = result.background) !== null && _c !== void 0 ? _c : "#000")
                        .alpha(0.35)
                        .rgb()
                        .string();
                    var strokeStyle = Color((_d = result.outline) !== null && _d !== void 0 ? _d : "#000")
                        .alpha(0.5)
                        .rgb()
                        .string();
                    roundedRect(ctx, {
                        x: x,
                        y: y + rowPadding * 1.5,
                        w: w,
                        h: h,
                        fillStyle: fillStyle,
                        strokeStyle: strokeStyle,
                        lineWidth: 1,
                        radius: 3,
                    });
                }
                if ("inlineContents" in result && !expanded[result.id]) {
                    (_e = result.inlineContents) === null || _e === void 0 ? void 0 : _e.forEach(function (event) {
                        var _a, _b;
                        if (event.disabled) {
                            return;
                        }
                        var segments = getSegments(event.startsAt, event.length, (_a = event.interruptedByHolidays) !== null && _a !== void 0 ? _a : false, (_b = event.interruptedByWeekends) !== null && _b !== void 0 ? _b : false, Object.keys(darkDays.current));
                        segments.forEach(function (segment, i) {
                            var x = Math.floor(DateTime.fromISO(segment.startDate, { zone: "utc" })
                                .startOf("day")
                                .diff(startDateRef, "days").days) *
                                cellWidth +
                                scrollX;
                            var w = segment.duration * cellWidth;
                            var h = rowHeight - rowPadding * 2;
                            renderEvent(ctx, {
                                x: x,
                                y: y,
                                w: w,
                                h: h,
                                name: "",
                                hover: { x: hoverX_1, y: hoverY_1 },
                                labelSize: labelSize_1,
                                radius: h / 2,
                                selected: selectedEventIds.includes(event.id),
                                dragging: mouse.originId === event.id,
                                strokeStyle: event.outline,
                                fillStyle: event.background,
                                selectedColor: selectedColor,
                            });
                        });
                    });
                }
                if (divideRows) {
                    line(ctx, {
                        from: { x: 0, y: y - 2 },
                        to: { x: canvasSize.width, y: y - 2 },
                        strokeStyle: borderColor,
                        lineWidth: 1,
                    });
                }
            });
            ctx.restore(); // undo clipping mask
            // Calculate maximum scrollable X value
            var lastEvent = (_b = (contents !== null && contents !== void 0 ? contents : [])[(contents !== null && contents !== void 0 ? contents : []).length - 1]) !== null && _b !== void 0 ? _b : {};
            if ("startsAt" in lastEvent) {
                var maxX = Math.floor(DateTime.fromISO(lastEvent.startsAt, { zone: "utc" })
                    .startOf("day")
                    .plus({ days: 30 })
                    .diff(startDateRef, "days").days) *
                    cellWidth +
                    lastEvent.length * cellWidth +
                    measure(ctx, { scale: scale, labelSize: labelSize_1, text: lastEvent.name }).width;
                if (scrollWidth !== maxX) {
                    setScrollWidth(maxX);
                }
            }
            if (interactiveEvent_1 !== currentEvent.eventId) {
                setCurrentEvent({
                    eventId: interactiveEvent_1,
                    calendarId: interactiveCalendar_1,
                });
            }
        }
    }, [
        rowHeight,
        borderColor,
        cellBackgroundColor,
        cellWidth,
        darkDays,
        height,
        mouse,
        hoverColor,
        inactiveCellBackgroundColor,
        weekendCellBackgroundColor,
        todayFillColor,
        selectedColor,
        selectedDate,
        width,
        scrollX,
        scrollY,
        contents,
        scrollWidth,
        setScrollWidth,
        selectedEventIds,
        currentEvent,
        setCurrentEvent,
        expanded,
        contents === null || contents === void 0 ? void 0 : contents.length,
        renderingMode,
        labelColor,
        secondaryLabelColor,
        canvasSize.height,
        canvasSize.width,
        rowPadding,
        divideRows,
        scale,
        showToday,
        disabled,
        showHandles,
        startDateRef,
        contentsJSON,
    ]);
    return (_jsxs("div", __assign({ className: "flex relative h-full overflow-scroll", style: { height: externalHeight }, ref: containerRef }, { children: [_jsx("div", { style: {
                    height: ((_b = contents === null || contents === void 0 ? void 0 : contents.length) !== null && _b !== void 0 ? _b : 0) * rowHeight + 60,
                    width: scrollWidth,
                }, className: "absolute top-0 left-0 pointer-events-none" }, void 0), _jsx("canvas", { ref: canvasRef, className: clsx("w-full h-full top-0 left-0", currentEvent && "cursor-pointer"), style: {
                    height: canvasSize.height,
                }, height: canvasSize.height * scale, width: width * scale, onMouseMove: handleMouseMove, onMouseOut: handleMouseOut, onMouseDown: handleMouseDown, onMouseUp: handleMouseUp }, void 0)] }), void 0));
});
