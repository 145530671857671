import React, { Component } from "react"
import PropTypes from "prop-types"
import { isNotBlank } from "../../utils/helpers"
import Group from "./Group.react"
import Input from "./Input.react"
import Label from "./Label.react"
import { SketchPicker } from "react-color"
import styles from "./Field.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { colors } from "../../utils"

class ModalColorInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPicker: false,
    }
  }

  togglePickerVisible(showPicker) {
    if (showPicker) {
      this.showPicker()
    } else {
      this.disablePicker()
    }
  }

  showPicker() {
    this.setState({ showPicker: true })
  }

  disablePicker() {
    this.setState({ showPicker: false })
  }

  defaultColors() {
    return [
      "#b6d9d5",
      "#789f9e",
      "#4f6467",
      "#eef3dc",
      "#fe615a",
      "#264267",
      "#347996",
      "#3d8d98",
      "#deceaa",
      "#e9aa77",
      "#E27300",
      "#dd7244",
      "#cb4633",
      "#feaf88",
      "#ff687d",
      "#cb6686",
      "#745984",
      "#265d84",
      "#2d4d58",
      "#3b988e",
      "#fedd7c",
      "#fea968",
      "#d9664b",
      "#fff3d9",
      "#ffe6b3",
      "#48aad9",
      "#2f6e8d",
      "#153240",
      ...Object.values(colors),
    ]
  }

  handleChangeComplete({ rgb }) {
    const { r, g, b, a } = rgb
    this.props.input.onChange(`rgba(${r},${g},${b},${a})`)
  }

  render() {
    const {
      meta,
      input: { name, value, onChange, ...input },
      disabled,
      placeholder,
      label,
      type,
    } = this.props
    const showError = isNotBlank(meta.error) && meta.touched
    return (
      <Group error={showError}>
        <Label
          htmlFor={`${name}`}
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {label}
        </Label>
        {this.state.showPicker && (
          <div
            className={styles.pickerDismiss}
            onClick={(e) => {
              e.preventDefault()
              this.togglePickerVisible(false)
            }}
          />
        )}
        <div
          className={styles.colorField}
          style={{
            backgroundColor: value,
            opacity: disabled ? 0.5 : 1,
            pointerEvents: disabled ? "none" : "all",
          }}
          onClick={() => this.togglePickerVisible(true)}
        >
          <Input
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            className={styles.hidden}
            name={name}
            onChange={() => onChange(value)}
            value={value}
            {...input}
          />
          {this.state.showPicker && (
            <div
              className={styles.pickerContainer}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <SketchPicker
                color={value}
                width="200"
                presetColors={this.defaultColors()}
                onChangeComplete={(c) => this.handleChangeComplete(c)}
              />
              <button
                className={styles.closeButton}
                onClick={(e) => {
                  e.preventDefault()
                  this.togglePickerVisible(false)
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </div>
        {showError && <span className="error">{meta.error}</span>}
      </Group>
    )
  }
}

ModalColorInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default ModalColorInput
