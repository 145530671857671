import React from "react"
import PropTypes from "prop-types"
import styles from "./Dialog.module.css"

const handleClick = (event) => {
  event.stopPropagation()
}

const Dialog = ({ children, fadeIn, extended, style, innerRef }) => (
  <div className={styles.modalLayer} style={style}>
    <div
      onClick={handleClick}
      onMouseDown={handleClick}
      className={`modal-dialog ${styles.modalContainer} ${
        fadeIn && styles.fadeIn
      } ${extended && styles.extended}`}
      role="document"
      ref={(r) => (innerRef ? innerRef(r) : null)}
    >
      <div className={`modal-content ${styles.modal}`}>{children}</div>
    </div>
  </div>
)

Dialog.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  extended: PropTypes.bool,
  fadeIn: PropTypes.bool,
  innerRef: PropTypes.func,
}

export default Dialog
