var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Layer, Rect, Group, Text } from "react-konva";
import Color from "color";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import GanntListItemCheckbox from "./GanntListItemCheckbox";
import GanntListItemToggling from "./GanntListItemIndentation";
var STROKE_COLOR = Color("#cbdbe7");
var STROKE_COLOR_HEX = STROKE_COLOR.hex();
var BORDER_COLOR_HEX = STROKE_COLOR.darken(0.25).hex();
var BLACK = "#000000";
var MAX_CHECKBOX_DIMENSIONS = 25;
var AXIS_GAP = 20;
var MINI_GAP = 6;
var FONT_SIZE = 12;
var BORDER_HEIGHT = 0.5;
var SPACING_FACTOR = 1.5;
var getHalf = function (x) { return x / 2; };
export var GanntListItem = function (_a) {
    var width = _a.width, height = _a.height, values = _a.values, x = _a.x, y = _a.y, _b = _a.active, active = _b === void 0 ? false : _b, _c = _a.expanded, expanded = _c === void 0 ? false : _c, _d = _a.indent, indent = _d === void 0 ? 0 : _d, onToggle = _a.onToggle, onExpand = _a.onExpand, onCollapse = _a.onCollapse;
    var isFirstMount = useRef(true);
    var _e = useState(active), checked = _e[0], setChecked = _e[1];
    var _f = useState(expanded), expand = _f[0], setExpand = _f[1];
    useEffect(function () {
        setExpand(expanded);
    }, [expanded]);
    useEffect(function () {
        setChecked(active);
    }, [active]);
    useEffect(function () {
        if (!isFirstMount.current) {
            onToggle === null || onToggle === void 0 ? void 0 : onToggle(checked);
        }
    }, [checked]);
    useEffect(function () {
        if (!isFirstMount.current) {
            expand ? onExpand === null || onExpand === void 0 ? void 0 : onExpand(expand) : onCollapse === null || onCollapse === void 0 ? void 0 : onCollapse();
        }
        isFirstMount.current = false;
    }, [expand]);
    if (values.length === 0) {
        return null;
    }
    var handleOnToggleChecked = function () { return setChecked(function (s) { return !s; }); };
    var handleOnToggleExpand = function () { return setExpand(function (s) { return !s; }); };
    var handleOnMouseOver = function () {
        document.body.style.cursor = "pointer";
    };
    var handleOnMouseOut = function () {
        document.body.style.cursor = "default";
    };
    var isNested = indent > 0;
    var CHECKBOX_DIMENSION = Math.min(height - MINI_GAP, MAX_CHECKBOX_DIMENSIONS); // width & height of check box
    var Y_AXIS_POSITION = height > AXIS_GAP
        ? getHalf(height) - getHalf(CHECKBOX_DIMENSION)
        : getHalf(MINI_GAP);
    var INDENTATION_WIDTH = CHECKBOX_DIMENSION * indent;
    var INDENTATION_SPACER_X_POSITION = CHECKBOX_DIMENSION * SPACING_FACTOR;
    return (_jsx(Layer, __assign({ x: x, y: y, height: height, width: width }, { children: _jsxs(Group, __assign({ onMouseOver: handleOnMouseOver, onMouseOut: handleOnMouseOut }, { children: [_jsx(Rect, { width: width, height: height, fill: "white" }, void 0), _jsx(GanntListItemCheckbox, { x: 10, y: Y_AXIS_POSITION, width: CHECKBOX_DIMENSION, height: CHECKBOX_DIMENSION, checked: checked, onToggle: handleOnToggleChecked }, void 0), isNested ? (_jsx(Rect, { x: INDENTATION_SPACER_X_POSITION, width: INDENTATION_WIDTH, height: height, fill: STROKE_COLOR_HEX }, void 0)) : null, _jsx(GanntListItemToggling, { width: CHECKBOX_DIMENSION, height: CHECKBOX_DIMENSION, y: Y_AXIS_POSITION, x: CHECKBOX_DIMENSION + INDENTATION_WIDTH + 10, onClick: handleOnToggleExpand, icon: expand ? faMinus : faPlus, color: checked ? BLACK : STROKE_COLOR_HEX }, void 0), _jsx(GanntListItemValues, { values: values, startXPosition: INDENTATION_WIDTH + INDENTATION_SPACER_X_POSITION + 25, height: height, checked: checked }, void 0), _jsx(Rect, { x: x, y: height - BORDER_HEIGHT, width: width, height: BORDER_HEIGHT, fill: BORDER_COLOR_HEX }, void 0)] }), void 0) }), void 0));
};
var GanntListItemValues = function (_a) {
    var values = _a.values, startXPosition = _a.startXPosition, height = _a.height, checked = _a.checked;
    var accumulatedXPosition = useRef(0);
    return (_jsx(_Fragment, { children: values.map(function (_a, idx) {
            var width = _a.width, _b = _a.label, label = _b === void 0 ? "" : _b;
            var first = idx === 0;
            var x = first ? startXPosition : accumulatedXPosition.current;
            var leftBorderX = x - 5;
            accumulatedXPosition.current = x + width - (first ? startXPosition : 0);
            return (_jsxs(_Fragment, { children: [!!label && !first ? (_jsx(Rect, { x: leftBorderX, width: 1, height: height, fill: STROKE_COLOR_HEX }, void 0)) : null, _jsx(Text, { text: label, fontSize: FONT_SIZE, fontStyle: "bold", width: width, height: height, x: x, y: getHalf(height) - getHalf(FONT_SIZE), fill: checked ? BLACK : STROKE_COLOR_HEX }, void 0)] }, void 0));
        }) }, void 0));
};
