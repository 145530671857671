import React, { Component } from "react"
import PropTypes from "prop-types"
import { decamelizeKeys } from "humps"
import { Field } from "redux-form"
import { withRouter } from "react-router"
import { Form, Indicator, Flex } from "../../shared"
import { Button } from "ui"
import { trimmedSubDomain, asSubDomain, isDefined } from "../../utils/helpers"
import { submitValidate } from "../validators"

import { faLock, faUnlockAlt } from "@fortawesome/pro-regular-svg-icons"
import toast from "react-hot-toast"

class OrganizationFormView extends Component {
  onChangeName(e) {
    this.props.change("name", e.target.value)
    if (this.newOrg()) {
      this.props.change("subdomain", trimmedSubDomain(e.target.value))
    }
  }

  submitForm(values) {
    const { createOrganization, updateOrganization } = this.props
    const defaultValues = { usesGranularPermissions: false }
    const valuesToSubmit = decamelizeKeys(
      Object.assign({}, defaultValues, values)
    )

    const dispatchAction = this.newOrg()
      ? createOrganization
      : updateOrganization

    return dispatchAction(valuesToSubmit)
      .then(submitValidate)
      .then((action) => {
        this.props.history.push(`/org/${action.payload.subdomain}/cal/`)
        const message = this.newOrg()
          ? `${values.name} Successfully Created!`
          : `${values.name} Successfully Updated!`
        toast.success(message)
      })
  }

  newOrg() {
    return this.props.initialValues && !isDefined(this.props.initialValues.id)
  }

  render() {
    const { submitting, activeField, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit((values) => this.submitForm(values))}>
        <Form.Grid>
          <Field
            component={Form.Field}
            name="name"
            label="Company Name"
            type="text"
            assistantText="The name of this company."
            customizations={{
              disabled: submitting,
              placeholder: "Company Name",
              tabIndex: "1",
              onChange: (e) => this.onChangeName(e),
            }}
            active={activeField === "name"}
          />
          <Field
            component={Form.Field}
            name="subdomain"
            label="Subdomain"
            type="text"
            assistantText="A unique URL users will use to access this organization."
            customizations={{
              disabled: submitting || !this.newOrg(),
              placeholder: "Subdomain",
              tabIndex: "2",
            }}
            parse={(value) => asSubDomain(value)}
            previewText={(value) => `${value || "example"}.NPFAcal.com`}
            active={activeField === "subdomain"}
          />
          <Field
            component={Form.Field}
            name="color"
            label="Color"
            type="color"
            assistantText="This is a color used to help identify the organization in a list."
            customizations={{
              disabled: submitting,
              tabIndex: "3",
            }}
            active={activeField === "color"}
          />

          <Form.Heading name="Permissions" />
          <Form.GridRow>
            <Form.FieldCol>
              <Form.RadioGroup>
                <Field
                  name="usesGranularPermissions"
                  component={Form.RadioField}
                  value={false}
                  type="radio"
                  label="Simple Protection"
                  icon={faUnlockAlt}
                  disabled={submitting}
                  description="Only members of your company can access your calendars."
                />
                <Field
                  name="usesGranularPermissions"
                  component={Form.RadioField}
                  label="Advanced Protection"
                  type="radio"
                  value={true}
                  icon={faLock}
                  disabled={submitting}
                  description="In addition to simple protection, you can specify which calendars an individual can read or make changes to."
                />
              </Form.RadioGroup>
            </Form.FieldCol>
          </Form.GridRow>

          <Form.GridRow>
            <Form.FieldCol>
              <br />
              <Button type="submit" align="stretch" disabled={submitting}>
                <Flex.Container>
                  {submitting && <Indicator.Ring theme={"button"} />}
                  {this.newOrg() ? "Create" : "Update"} Company
                </Flex.Container>
              </Button>
            </Form.FieldCol>
          </Form.GridRow>
        </Form.Grid>
      </form>
    )
  }
}

OrganizationFormView.propTypes = {
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired,
  activeField: PropTypes.string,
  createOrganization: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
}

export default withRouter(OrganizationFormView)
