import { useApiRequest, RequestParams } from "./useApiRequest"

export interface TouchOrgParams extends RequestParams {
  uuid: string
}

export const useTouchOrg = (subdomain: string) => {
  const touchRequest = useApiRequest<{}, {}, TouchOrgParams>({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/organizations/${subdomain}/touch`,
  })

  return touchRequest
}
