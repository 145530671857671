import React, { useCallback, useState, useEffect } from "react"
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import { faCog } from "@fortawesome/pro-solid-svg-icons"
import { Context } from "immutability-helper"

import {
  Drawer,
  SortCompleteHandler,
  DRAWER_GROUP_TYPE,
  DRAWER_ITEM_TYPE,
  DrawerContent,
  DrawerContextEvent,
  ContextMenu,
  MenuSelectionHandler,
  Dialog,
  DrawerListGroup,
  DrawerArtifactType,
} from "ui"

import {
  useCalendars,
  useGroups,
  useSortCalendars,
  useSortGroups,
  useUpdateCalendarGroup,
  useUpdateGroupParent,
  useDeleteGroup,
  useHasCreatorPrivilege,
} from "../../api"
import { useHistory, useRouteMatch } from "react-router"
import { GroupModal, SendToGroupModal } from "../../modals"
import {
  useCalendarListItems,
  positionSort,
  useRemoveCalendarListItem,
  useToggleListItem,
} from "../atoms"

interface PromptState {
  type:
    | null
    | "group"
    | "calendar"
    | "deleteGroup"
    | "warnGroup"
    | "sendToGroup"
  params: { [key: string]: number | string | undefined }
}

const { update } = new Context()

export interface CalendarDrawerProps {
  onDismiss: () => void
}

export const CalendarDrawer: React.FC<CalendarDrawerProps> = ({
  onDismiss: handleDismiss,
}) => {
  const match = useRouteMatch<{ subdomain: string }>("/org/:subdomain")
  const subdomain = match?.params.subdomain ?? ""

  const [search, setSearch] = useState("")
  const [prompt, setPrompt] = useState<PromptState | null>(null)
  const [contextMenu, setContextMenu] = useState<DrawerContextEvent>()
  const { listItems, setListItems } = useCalendarListItems(subdomain)

  const history = useHistory()

  const [sortGroups] = useSortGroups(subdomain)
  const [sortCalendars] = useSortCalendars(subdomain)
  const [updateCalendarGroup] = useUpdateCalendarGroup(subdomain)
  const [updateGroupParent] = useUpdateGroupParent(subdomain)

  const { loading: loadingCalendars, calendars } = useCalendars(subdomain)
  const { loading: loadingGroups, groups } = useGroups(subdomain)

  const handleNewClick = () => {
    history.push(`/org/${subdomain}/cal/new`)
    handleDismiss()
  }

  const isCreator = useHasCreatorPrivilege(subdomain)

  const [lastLoadString, setLastLoadString] = useState("") // Prevent infinite loop on effect.
  const loadString =
    calendars
      .map((c: any) => `${c.id}[${c.position}]->${c.groupUuid}`)
      .join(" ") +
    groups
      .map((g) => `${g.uuid}[${g.name}][${g.position}]->${g.groupUuid}`)
      .join(" ")
  useEffect(() => {
    if (loadString !== lastLoadString) {
      const calendarContent: DrawerContent[] = calendars.map(
        (cal: any, index: number) => ({
          icon: faCalendarAlt,
          label: cal.name,
          secondaryIcon: faCog,
          onSelect: () => {
            history.push(`/org/${subdomain}/cal/${cal.id}`)
            handleDismiss()
          },
          onSecondaryClick: isCreator
            ? () => {
                history.push(`/org/${subdomain}/cal/${cal.id}/settings`)
                handleDismiss()
              }
            : undefined,
          id: cal.id,
          type: DRAWER_ITEM_TYPE,
          groupId: cal.groupUuid,
          position: cal.position ?? index,
        })
      )
      const groupContent: DrawerListGroup[] = groups.map(
        (group, index: number) => ({
          label: group.name,
          secondaryIcon: faCog,
          onSecondaryClick: isCreator
            ? () => {
                setPrompt({ type: "group", params: { groupUuid: group.uuid } })
              }
            : undefined,
          onSelect: () => {},
          id: group.uuid,
          type: DRAWER_GROUP_TYPE,
          groupId: group.groupUuid,
          position: group.position ?? index,
        })
      )
      setLastLoadString(loadString)
      setListItems([...groupContent, ...calendarContent].sort(positionSort))
    }
  }, [
    calendars,
    groups,
    setListItems,
    lastLoadString,
    setLastLoadString,
    loadString,
    handleDismiss,
    history,
    isCreator,
    subdomain,
  ])

  const handleSearch = (searchText?: string) => {
    setSearch(searchText ?? "")
  }

  const handleSort: SortCompleteHandler = useCallback(
    ({ dragId, dropId, behavior, parentId }) => {
      if (!dragId) {
        return
      }
      const dragItem = listItems.find((i: DrawerContent) => i.id === dragId)
      const dragIndex = dragItem ? listItems.indexOf(dragItem) : -1

      const dropItem = listItems.find((i: DrawerContent) => i.id === dropId)
      const dropIndex = dropItem ? listItems.indexOf(dropItem) : -1

      const updatedItem = {
        ...dragItem,
        groupId: behavior === "insert" ? dropId : parentId,
      } as DrawerContent

      if (dragItem?.groupId !== updatedItem.groupId) {
        if (updatedItem.type === DRAWER_ITEM_TYPE) {
          updateCalendarGroup({
            body: { group_uuid: updatedItem.groupId ?? "" },
            params: { calendarId: updatedItem.id },
          })
        } else if (updatedItem.type === DRAWER_GROUP_TYPE) {
          updateGroupParent({
            body: { group_uuid: updatedItem.groupId ?? "" },
            params: { uuid: updatedItem.id },
          })
        }
      }

      const updatedItems = update(listItems, {
        $splice: [
          [dragIndex, 1],
          [dropIndex, 0, updatedItem as DrawerContent],
        ],
      })
        .map((i: DrawerContent, index: number) => ({ ...i, position: index }))
        .sort(positionSort)

      setListItems(updatedItems)

      const groups = updatedItems.filter((i) => i.type === DRAWER_GROUP_TYPE)
      sortGroups({
        body: {
          uuids: groups.map((g) => g.id),
          positions: groups.map((g) => g.position),
        },
      })
      const calendars = updatedItems.filter((i) => i.type === DRAWER_ITEM_TYPE)
      sortCalendars({
        body: {
          ids: calendars.map((g) => g.id),
          positions: calendars.map((g) => g.position),
        },
      })
    },
    [
      listItems,
      setListItems,
      sortGroups,
      sortCalendars,
      updateCalendarGroup,
      updateGroupParent,
    ]
  )

  const handleToggleGroup = useToggleListItem()

  const finalItems = listItems
    .filter((i: DrawerContent) =>
      search ? i.label.toLowerCase().indexOf(search.toLowerCase()) > -1 : true
    )
    .filter((i: DrawerContent) => (search ? i.type === DRAWER_ITEM_TYPE : true))
    .map((i: DrawerContent) => (search ? { ...i, groupId: undefined } : i))

  const dismissContext = () => {
    setContextMenu(undefined)
  }

  const handleNewGroupClick = () => {
    setPrompt({
      type: "group",
      params: {
        position: contextMenu?.position,
        parentGroupUuid: contextMenu?.groupId,
        name: contextMenu?.label,
      },
    })
  }

  const handleSendToGroupClick = (id?: string, type?: DrawerArtifactType) => {
    const targetName =
      type === DRAWER_GROUP_TYPE
        ? groups.find((g) => g.uuid === id)?.name
        : calendars.find((c: { id: string }) => c.id === id).name
    setPrompt({
      type: "sendToGroup",
      params: {
        targetId: id,
        targetType: type,
        targetName,
      },
    })
  }

  const handleContextSelection: MenuSelectionHandler = (selection) => {
    switch (selection) {
      case "createCalendar":
        break
      case "editCalendar":
        history.push(`/org/${subdomain}/cal/${contextMenu?.id}/settings`)
        break
      case "sendToGroup":
        handleSendToGroupClick(contextMenu?.id, contextMenu?.type)
        return
      case "createGroup":
        handleNewGroupClick()
        return
      case "editGroup":
        setPrompt({
          type: "group",
          params: {
            groupUuid: contextMenu?.id,
          },
        })
        return
      case "deleteGroup":
        const count = listItems.filter(
          (i) => i.groupId === contextMenu?.id
        ).length

        setPrompt({
          type: count < 1 ? "deleteGroup" : "warnGroup",
          params: {
            groupUuid: contextMenu?.id,
            name: contextMenu?.label,
            count,
          },
        })
        return
      default:
        break
    }
    handleDismiss()
  }

  const closeModal = () => setPrompt(null)

  const removeListItem = useRemoveCalendarListItem()
  const [deleteGroup] = useDeleteGroup(subdomain)
  const handleConfirmDeleteGroup = () => {
    deleteGroup({ params: { uuid: prompt?.params.groupUuid as string } })
    removeListItem(prompt?.params.groupUuid as string)
    closeModal()
  }

  const loading = loadingGroups || loadingCalendars

  return (
    <div className="absolute inset-0">
      <Drawer
        title="Calendars"
        newItemLabel="New Calendar"
        onSearch={handleSearch}
        onSort={isCreator ? handleSort : undefined}
        items={finalItems}
        onDismiss={handleDismiss}
        onToggleGroup={handleToggleGroup}
        onNewClick={isCreator ? handleNewClick : undefined}
        onNewGroupClick={isCreator ? handleNewGroupClick : undefined}
        onContextMenu={(e: any) => {
          if (isCreator) {
            setContextMenu(e)
          }
        }}
        offset={48}
        loading={loading}
      />
      {contextMenu ? (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onSelect={handleContextSelection}
          onDismiss={dismissContext}
          entityType={
            contextMenu.type === DRAWER_GROUP_TYPE ? "group" : "calendar"
          }
        />
      ) : null}
      {prompt?.type === "group" ? (
        <GroupModal
          title=""
          open
          draggable={false}
          subdomain={subdomain}
          onClose={closeModal}
          groupUuid={prompt?.params?.groupUuid as string}
          initialValues={{
            position: prompt?.params?.position as number,
            parentGroupUuid: prompt?.params?.parentGroupUuid as string,
          }}
        />
      ) : null}
      {prompt?.type === "sendToGroup" ? (
        <SendToGroupModal
          title=""
          open
          draggable={false}
          subdomain={subdomain}
          onClose={closeModal}
          targetName={prompt?.params?.targetName as string}
          targetId={prompt?.params?.targetId as string}
          targetType={
            prompt?.params?.targetType === "DRAWER_GROUP" ? "group" : "calendar"
          }
        />
      ) : null}
      {prompt?.type === "deleteGroup" ? (
        <Dialog
          title="Are You Sure?"
          open
          onClose={closeModal}
          message={`Are you sure you want to delete "${prompt.params?.name}"?`}
          onCancel={closeModal}
          onConfirm={handleConfirmDeleteGroup}
        />
      ) : null}
      {prompt?.type === "warnGroup" ? (
        <Dialog
          title="Cannot Delete Group"
          open
          onClose={closeModal}
          message={`Only empty groups may be delete. "${prompt.params?.name}" contains ${prompt.params.count} items. Use the sort function to move the contents to another group and try again.`}
          onConfirm={closeModal}
          confirmLabel="I Understand"
        />
      ) : null}
    </div>
  )
}
