import React, { useState, useEffect } from "react"
import Week from "../containers/Week.react"
import { useDispatch, useSelector } from "react-redux"
import {
  WeekCanvas,
  EventItemType,
  EventContextMenuHandler,
  WeekCanvasProps,
  useWeekCanvasState,
} from "ui"
import { ItemTypes as ExplorerItemTypes } from "../../itemExplorer"
import { DateTime } from "luxon"
import { holidaySelector, iconSelector } from "../../api"
import { ContextTypes } from "../constants"
import { requestContext } from "../actions"
import { WeekHeading } from "./WeekHeading"
import { calendarSelector } from "../../api/selectors"

const HEADING_STYLES = "text-sm text-lochivar-default pl-4 pt-3"
const CONTENT_SCROLL_STYLES =
  "flex flex-grow overflow-hidden max-h-screen relative scrollbar-hidden relative"

const withinRange = (target: DateTime, start: DateTime, end: DateTime) =>
  Math.floor(target.diff(start, "weeks").weeks) >= 0 &&
  Math.floor(end.diff(target, "weeks").weeks) >= 0

export interface WeekScrollViewProps extends WeekCanvasProps {
  currentIndex?: number
  onSelectDate?: (date: string) => void
  onItemDrop?: (
    id: string,
    calendarId: string,
    date: string,
    type: string
  ) => void
  onItemDropHover?: (
    itemType: string,
    id: string,
    calendarId: string,
    start: boolean,
    date: string
  ) => void
  snapshotId: number
  calendarIds: number[]
  reportId?: number
  onEventMouseDown: (selected: boolean) => void
  onSelectedEvent: (
    id: string,
    calendarId: string,
    ignoreIfSelected?: boolean
  ) => void
  onEditEvent: (id: string, calendarId: string) => void
  readOnly?: boolean
}

export const WeekScrollView: React.FC<WeekScrollViewProps> = ({
  onSelectDate: handleSelectDate,
  selectedDate,
  onItemDrop,
  onItemDropHover,
  calendarIds,
  snapshotId,
  reportId,
  onSelectedEvent: handleSelectedEvent,
  onEditEvent: handleEditEvent,
  onEventMouseDown: handleEventMouseDown,
  readOnly,
  weeksVisible = 6,
  darkDays,
  inactiveCellBackgroundColor,
  outOfRangeCellBackgroundColor,
  weekendCellBackgroundColor,
}) => {
  const { anchorDate, scrollCursorDate } = useWeekCanvasState()
  const dispatch = useDispatch()
  const calendar = useSelector(calendarSelector.find)(calendarIds[0])
  const findHolidaysForCalendars = useSelector(holidaySelector.forCalendar)
  const findHolidaysForSnapshot = useSelector(holidaySelector.forSnapshot)
  const findIconsForCalendars = useSelector(iconSelector.forCalendar)
  const findIconsForSnapshots = useSelector(iconSelector.forSnapshot)
  const [startDate, setStartDate] = useState(anchorDate)
  const [displayDate, setDisplayDate] = useState(anchorDate)

  useEffect(() => {
    if (startDate !== anchorDate) {
      setStartDate(anchorDate)
    }
  }, [anchorDate, startDate, setStartDate])

  useEffect(() => {
    if (displayDate?.toISODate() !== scrollCursorDate.toISODate()) {
      setDisplayDate(scrollCursorDate)
    }
  }, [scrollCursorDate, setDisplayDate, displayDate])

  const travelDates = (
    snapshotId
      ? findIconsForSnapshots(snapshotId)
      : findIconsForCalendars(calendarIds)
  ).map((i: any) => DateTime.fromISO(i.startDate, { zone: "utc" }).toISO())
  const utcStart = DateTime.fromISO(calendar?.startDate, { zone: "utc" })
  const calendarStart = utcStart.minus({
    days: utcStart.weekday < 7 ? utcStart.weekday : 0,
  })
  const utcEnd = DateTime.fromISO(calendar?.endDate, { zone: "utc" })
  const calendarEnd = utcEnd.plus({
    days: utcEnd.weekday <= 6 ? 6 - utcEnd.weekday : 6,
  })

  // Respond to requests for contextual navigation.
  const handleDateContextMenu = ({
    x,
    y,
    date,
  }: {
    x: number
    y: number
    date: string
  }) => {
    const iconsForDate = findIconsForCalendars(calendarIds).filter(
      ({ startDate }: any) => {
        const anchor = DateTime.fromISO(date, { zone: "utc" })
        return (
          Math.floor(
            DateTime.fromISO(startDate, { zone: "utc" }).diff(anchor, "days")
              .days
          ) <= 0
        )
      }
    )
    const holidaysForDate = (
      snapshotId
        ? findHolidaysForSnapshot(snapshotId)
        : findHolidaysForCalendars(calendarIds)
    ).filter((d: any) => {
      const anchor = DateTime.fromISO(date, { zone: "utc" })
      return (
        DateTime.fromISO(d.startsAt, { zone: "utc" }).diff(anchor, "days")
          .days <= 0 &&
        DateTime.fromISO(d.endsAt, { zone: "utc" }).diff(anchor, "days").days >=
          0
      )
    })
    dispatch(
      requestContext({
        entityType: ContextTypes.DAY,
        holidays: holidaysForDate,
        icons: iconsForDate,
        referenceDate: date,
        x,
        y,
      })
    )
    if (handleSelectDate) {
      handleSelectDate(date)
    }
  }

  const handleEventContextMenu: EventContextMenuHandler = ({
    x,
    y,
    uuid,
    calendarId,
  }) => {
    handleSelectedEvent(uuid, calendarId, true)
    dispatch(
      requestContext({
        entityType: ContextTypes.EVENT,
        uuids: [uuid],
        targetUuid: uuid,
        x,
        y,
      })
    )
  }

  const handleItemDrop = (
    itemType: string,
    { uuid, calendarId }: any,
    date: string
  ) => {
    if (onItemDrop) {
      onItemDrop(itemType, uuid, calendarId, date)
    }
  }

  const handleItemDropHover = (
    itemType: string,
    { uuid, start, calendarId }: any,
    date: string
  ) => {
    if (onItemDropHover) {
      onItemDropHover(itemType, uuid, calendarId, start, date)
    }
  }

  const weekNumberForWeek = (date: DateTime) => {
    switch (calendar?.showWeekNumbers) {
      case "year":
        return Math.ceil(
          date.diff(date.startOf("year").startOf("week"), "weeks").weeks
        )
      case "calendar_start":
        return withinRange(date, calendarStart, calendarEnd)
          ? Math.ceil(date.diff(calendarStart.startOf("week"), "weeks").weeks)
          : 0
    }
    return undefined
  }

  const currentMonth = (displayDate ?? anchorDate ?? DateTime.utc()).plus({
    weeks: 2,
  })

  return (
    <>
      <h1 className={HEADING_STYLES}>
        <span className="font-bold">
          {currentMonth?.endOf("week").monthLong}
        </span>{" "}
        {currentMonth?.endOf("week").year}
      </h1>
      <WeekHeading style={{ paddingTop: "0" }} />
      <div className={CONTENT_SCROLL_STYLES}>
        <WeekCanvas
          onContextMenu={handleDateContextMenu}
          selectedDate={selectedDate}
          onSelect={handleSelectDate}
          accept={[
            ExplorerItemTypes.ITEM_VIEW,
            EventItemType.START,
            EventItemType.END,
          ]}
          onDropHover={handleItemDropHover}
          onDrop={handleItemDrop}
          darkDays={darkDays}
          travel={travelDates}
          labelColor="#000"
          inactiveCellBackgroundColor={inactiveCellBackgroundColor}
          outOfRangeCellBackgroundColor={outOfRangeCellBackgroundColor}
          weekendCellBackgroundColor={weekendCellBackgroundColor}
          weeksVisible={weeksVisible}
          weekNumberForWeek={weekNumberForWeek}
          showMonthHeadings
          withMonthName
          showToday
        >
          {({ isoDate, date, height }) => {
            const endDate = date.plus({ days: 1 }).endOf("week")
            return (
              <Week
                start={isoDate}
                end={endDate.toISODate()}
                calendarIds={calendarIds}
                handleMouseDown={handleEventMouseDown}
                handleMouseClick={handleSelectDate}
                handleSelectedEvent={handleSelectedEvent}
                handleEditEvent={handleEditEvent}
                readOnly={readOnly}
                reportId={reportId}
                snapshotId={snapshotId}
                onContextMenu={handleEventContextMenu}
                maxRowsBeforeScale={weeksVisible === 6 ? 5 : 18}
                height={height - 28}
              />
            )
          }}
        </WeekCanvas>
      </div>
    </>
  )
}
