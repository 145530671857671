import { CALL_API, Schemas } from "../middlewares"
import { PROTECTED } from "redux-jwt-protected-middleware"
import * as types from "../actionTypes"

//
// GET / Find total user count
//

export const getTotalUserCount = () => ({
  type: types.FETCH_METRIC_TOTAL_USER_COUNT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRIC,
    method: "GET",
    endpoint: `/api/analytics/total_user_count`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_TOTAL_USER_COUNT_REQUEST,
      types.FETCH_METRIC_TOTAL_USER_COUNT_SUCCESS,
      types.FETCH_METRIC_TOTAL_USER_COUNT_FAILURE,
    ],
  },
})

export const requestTotalUserCount = () => (dispatch) => {
  return dispatch(getTotalUserCount())
}

//
// GET / Find active user count
//

export const getActiveUserCount = (days) => ({
  type: types.FETCH_METRIC_ACTIVE_USER_COUNT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRIC,
    method: "GET",
    endpoint: `/api/analytics/${days}/active_user_count`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_ACTIVE_USER_COUNT_REQUEST,
      types.FETCH_METRIC_ACTIVE_USER_COUNT_SUCCESS,
      types.FETCH_METRIC_ACTIVE_USER_COUNT_FAILURE,
    ],
  },
})

export const requestActiveUserCount = (days) => (dispatch) => {
  return dispatch(getActiveUserCount(days))
}

//
// GET / Find active users
//

export const getActiveUsers = (days) => ({
  type: types.FETCH_METRIC_ACTIVE_USERS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRICS_ARRAY,
    method: "GET",
    endpoint: `/api/analytics/${days}/active_users`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_ACTIVE_USERS_REQUEST,
      types.FETCH_METRIC_ACTIVE_USERS_SUCCESS,
      types.FETCH_METRIC_ACTIVE_USERS_FAILURE,
    ],
  },
})

export const requestActiveUsers = (days) => (dispatch) => {
  return dispatch(getActiveUsers(days))
}

//
// GET / Find active calendars
//

export const getActiveCalendars = (days) => ({
  type: types.FETCH_METRIC_ACTIVE_CALENDARS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRICS_ARRAY,
    method: "GET",
    endpoint: `/api/analytics/${days}/active_calendars`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_ACTIVE_CALENDARS_REQUEST,
      types.FETCH_METRIC_ACTIVE_CALENDARS_SUCCESS,
      types.FETCH_METRIC_ACTIVE_CALENDARS_FAILURE,
    ],
  },
})

export const requestActiveCalendars = (days) => (dispatch) => {
  return dispatch(getActiveCalendars(days))
}

//
// GET / Find active calendar count
//

export const getActiveCalendarCount = (days) => ({
  type: types.FETCH_METRIC_ACTIVE_CALENDARS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRIC,
    method: "GET",
    endpoint: `/api/analytics/${days}/active_user_count`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_ACTIVE_CALENDARS_REQUEST,
      types.FETCH_METRIC_ACTIVE_CALENDARS_SUCCESS,
      types.FETCH_METRIC_ACTIVE_CALENDARS_FAILURE,
    ],
  },
})

export const requestActiveCalendarCount = (days) => (dispatch) => {
  return dispatch(getActiveCalendarCount(days))
}

//
// GET / Find invitations
//

export const getInvitations = (days) => ({
  type: types.FETCH_METRIC_INVITATIONS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.METRICS_ARRAY,
    method: "GET",
    endpoint: `/api/analytics/${days}/invitations`,
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.FETCH_METRIC_INVITATIONS_REQUEST,
      types.FETCH_METRIC_INVITATIONS_SUCCESS,
      types.FETCH_METRIC_INVITATIONS_FAILURE,
    ],
  },
})

export const requestInvitations = (days) => (dispatch) => {
  return dispatch(getInvitations(days))
}
