var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { flatten } from "lodash";
import { DateTime } from "luxon";
export var chronologicalSort = function (a, b) {
    return a.startsAt > b.startsAt ? 1 : a.startsAt < b.startsAt ? -1 : 0;
};
/**
 * Derives an array of event/group contents with indent and collapsed state.
 * @param group The group to process.
 * @param expanded A global map of group IDs indicating expanded state.
 * @param parentCompressed If true we treat this group as the child of a compressed group.
 */
export var processGroup = function (group, expanded, disabled, indent, parentCompressed, parentDisabled) {
    var _a, _b;
    if (expanded === void 0) { expanded = {}; }
    if (disabled === void 0) { disabled = {}; }
    if (indent === void 0) { indent = 0; }
    var groupDisabled = disabled[group.id] || parentDisabled;
    var events = (_a = group.contents) === null || _a === void 0 ? void 0 : _a.filter(function (result) { return "startsAt" in result; }).map(function (e) { return (__assign(__assign({}, e), { disabled: disabled[e.id], indent: indent })); });
    var groups = (_b = group.contents) === null || _b === void 0 ? void 0 : _b.filter(function (result) { return "contents" in result; }).map(function (g) { return (__assign(__assign({}, g), { indent: indent, disabled: disabled[g.id] })); });
    if (parentCompressed) {
        var contents = __spreadArray(__spreadArray([], (groups !== null && groups !== void 0 ? groups : []).map(function (g) {
            return processGroup(g, expanded, disabled, indent + 1, parentCompressed, groupDisabled === true);
        }), true), (events !== null && events !== void 0 ? events : []), true);
        return flatten(contents);
    }
    if (expanded[group.id]) {
        var contents = __spreadArray(__spreadArray([], groups.map(function (g) {
            return processGroup(g, expanded, disabled, indent + 1, false, groupDisabled === true);
        }), true), events
            .map(function (e) { return (__assign(__assign({}, e), { indent: indent + 1, disabled: disabled[e.id] })); })
            .sort(chronologicalSort), true);
        var results = flatten(contents);
        return __spreadArray([__assign(__assign({}, group), { indent: indent })], results, true);
    }
    var inlineContents = processGroup(group, expanded, disabled, indent + 1, true, groupDisabled === true);
    return [__assign(__assign({}, __assign(__assign({}, group), { indent: indent })), { inlineContents: inlineContents })];
};
var isHoliday = function (date, holidays) {
    return holidays.some(function (h) { return DateTime.fromISO(h).toUTC().hasSame(date, "day"); });
};
export var getSegments = function (startDate, length, interruptedByWeekends, interruptedByHolidays, holidays) {
    var start = DateTime.fromISO(startDate).toUTC();
    var segments = [];
    var currentStartDate = start;
    var currentDuration = 0;
    var totalDuration = 0;
    while (totalDuration < length) {
        var isWeekend = currentStartDate.weekday > 5;
        var isHolidayInstance = isHoliday(currentStartDate, holidays);
        if ((isWeekend && interruptedByWeekends) ||
            (isHolidayInstance && interruptedByHolidays)) {
            if (currentDuration > 0) {
                segments.push({
                    startDate: currentStartDate
                        .minus({ days: currentDuration })
                        .toISODate(),
                    duration: currentDuration,
                });
                totalDuration += currentDuration;
                currentDuration = 0;
            }
        }
        else {
            currentDuration++;
            if (totalDuration + currentDuration === length) {
                segments.push({
                    startDate: currentStartDate
                        .minus({ days: currentDuration - 1 })
                        .toISODate(),
                    duration: currentDuration,
                });
                totalDuration += currentDuration;
                currentDuration = 0;
            }
        }
        currentStartDate = currentStartDate.plus({ days: 1 });
    }
    return segments;
};
