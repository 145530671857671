import React, { useState } from "react"
import {
  Modal,
  ModalProps,
  HolidayForm,
  HolidayFormValues,
  AsyncSubmitHandler,
} from "ui"
import { DateTime } from "luxon"
import toast from "react-hot-toast"
import { createHoliday, updateHoliday, useHoliday } from "../api"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { wait } from "utils"

export interface HolidayModalProps {
  holidayUuid?: string
  defaultDate?: string
  onEditCategory?: (uuid?: string) => void
  onEditTheme?: () => void
}

export const HolidayModal: React.FC<HolidayModalProps & ModalProps> = ({
  onClose: handleDismiss,
  holidayUuid,
  defaultDate,
  onFocus: handleFocus,
  zIndex,
}) => {
  const [loading, setLoading] = useState(false)
  const params = useParams<{ subdomain: string; calendarId: string }>()

  const { loading: loadingHoliday, holiday } = useHoliday(
    params.subdomain,
    params.calendarId,
    holidayUuid
  )

  const dispatch = useDispatch()
  const onSubmit: AsyncSubmitHandler<HolidayFormValues> = async (data) => {
    setLoading(true)
    await wait(1)
    const values = {
      ...data,
    }
    try {
      const response = await dispatch(
        holiday
          ? updateHoliday(params.subdomain, params.calendarId, {
              ...values,
              uuid: holidayUuid,
            })
          : createHoliday(params.subdomain, params.calendarId, values)
      )
      if (response.error) {
        setLoading(false)
        return {
          error: response.error,
          errors: { network: response.errorMessage },
        }
      }
      toast.success(
        `Successfully ${holiday ? "updated" : "created"} holiday "${
          data.name
        }".`
      )
      setLoading(false)
      handleDismiss?.()
    } catch (e) {
      console.error(e)
      setLoading(false)
      return
    }
    return
  }

  const startsAt = holiday?.startsAt ?? defaultDate

  const defaultValues = {
    name: holiday?.name ?? "",
    length: holiday?.length ?? 1,
    startsAt: startsAt
      ? DateTime.fromISO(startsAt, { zone: "utc" }).toISODate()
      : DateTime.local().toISODate(),
  }

  return (
    <Modal
      title={holiday ? "Edit Holiday" : "New Holiday"}
      onClose={handleDismiss}
      zIndex={zIndex}
      onFocus={handleFocus}
      open
      draggable
    >
      <HolidayForm
        loading={loading || loadingHoliday}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </Modal>
  )
}
