import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { camelizeKeys } from "humps"

interface SetKeeperProject {
  id: number
  name: string
}

export const useSetKeeperProjects = () => {
  const updateEntity = useUpdateEntity()

  const requestProjects = useApiRequest<
    SetKeeperProject[],
    Object,
    RequestParams
  >({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/connections/setkeeper-projects`,
    onResponse: (response) => {
      response.forEach((project) => {
        if (project.id) {
          updateEntity("setKeeperProjects", project.id, {
            ...camelizeKeys(project),
          })
        }
      })
    },
  })

  return requestProjects
}
