import React from "react"
import PropTypes from "prop-types"
import styles from "./ToolBarButtonGroup.module.css"

const ToolBarButtonGroup = ({ children }) => (
  <ul className={styles.toolBarButtonGroup}>
    {React.Children.map(children, (child) => (
      <li>{child}</li>
    ))}
  </ul>
)

ToolBarButtonGroup.propTypes = {
  children: PropTypes.node,
}

export default ToolBarButtonGroup
