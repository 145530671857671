import React, { useState, useEffect } from "react"
import { useSnapshots, updateSnapshot, restoreCalendar } from "../../api"
import { DateTime } from "luxon"
import { useHistory } from "react-router"
import { CalendarHistoryItem, MenuItem } from "./CalendarHistoryItem"
import { useDispatch } from "react-redux"
import { Indicators } from "ui"

interface CalendarHistoryWidgetTypes {
  /**
   * The identifier for the org
   */
  subdomain: string
  /**
   * The id of the calendar to present permissions for.
   */
  calendarId: string
  /**
   * The id of the current snapshot that has been selected.
   */
  snapshotId?: string
  /**
   * The current start date in the calendar path if available.
   */
  startDate: string
  /**
   * The current view type in the calendar path if available.
   */
  viewId?: string
  /**
   * A callback to handle the duplication of a specific snapshot.
   */
  onDuplicateSnapshot?: (snapshotId: number) => void
}

const styles = {
  container: "h-full",
  list: "flex flex-col",
  item: "border-b border-gray-300 flex flex-col",
  button: "cursor-pointer rounded py-2 px-1 flex flex-col flex-grow",
  itemName:
    "font-semibold text-sm bg-opacity-0 bg-transparent focus:border hover:border border-gray-300 p-1 -mt-1",
  itemDescription: "text-xs px-1",
  selected: "bg-lochivar bg-opacity-25",
  notSelected: "hover:bg-lochivar-lighter",
  menuIcon: "my-1 py-2 px-4 mr-1",
}

export const CalendarHistoryWidget: React.FC<CalendarHistoryWidgetTypes> = ({
  subdomain,
  calendarId,
  snapshotId,
  startDate,
  viewId,
  onDuplicateSnapshot: handleDuplicateSnapshot,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const handleVersionSelect =
    (id: string): React.MouseEventHandler =>
    (e) => {
      e.preventDefault()
      if (`${id}` !== `${snapshotId}`) {
        history.push(
          `/org/${subdomain}/cal/${calendarId}/snapshot/${id}/start/${startDate}/view/${
            viewId ?? "1M"
          }`
        )
      }
    }

  const gotoLiveCalendar = (forceRefresh?: boolean) => {
    const url = `/org/${subdomain}/cal/${calendarId}/start/${startDate}/view/${
      viewId ?? "1M"
    }`
    if (forceRefresh) {
      window.location.href = url
    } else {
      history.push(url)
    }
  }

  const handleSelectLiveCalendar: React.MouseEventHandler = (e) => {
    e.preventDefault()
    gotoLiveCalendar()
  }

  const { snapshots, refetch } = useSnapshots(subdomain, calendarId)
  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 60 * 5 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [refetch])

  const snapshotsForList = snapshots.length > 0 ? snapshots : []

  const handleAction = (id: number) => async (action: MenuItem) => {
    switch (action) {
      case MenuItem.Duplicate:
        handleDuplicateSnapshot?.(id)
        break
      case MenuItem.Restore:
        setLoading(true)
        await dispatch(restoreCalendar(subdomain, calendarId, id))
        setLoading(false)
        gotoLiveCalendar(true)
        break
      default:
        console.error("Did not handle the action.")
    }
  }

  const handleRenamedItem = (id: number) => async (name: string) => {
    const snapshot = snapshots.find((s: any) => s.id === id)
    const update = { id: id, notes: snapshot.notes, name }
    dispatch(updateSnapshot(subdomain, calendarId, update))
  }

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <CalendarHistoryItem
          selected={!snapshotId}
          onSelect={handleSelectLiveCalendar}
          description="The latest revision of the calendar."
          name="Live Calendar"
        />
        {snapshots.length === 0 ? (
          <p className="font-body text-body text-xs text-left rounded border border-lochivar-lighter p-2 my-2">
            <strong className="font-bold block">No recent versions</strong>{" "}
            Versions are automatically saved as you make changes every 15
            minutes.
          </p>
        ) : (
          snapshotsForList.map((s: any) => {
            return (
              <CalendarHistoryItem
                key={s.id}
                selected={s.id === parseInt(snapshotId ?? "0", 0)}
                onSelect={handleVersionSelect(s.id)}
                description={
                  s.createdAt
                    ? DateTime.fromISO(s.createdAt, { zone: "utc" }).toFormat(
                        "MMM d, yyyy @ h:mm a"
                      )
                    : s.notes
                }
                name={s.name}
                onAction={handleAction(s.id)}
                onRename={handleRenamedItem(s.id)}
              />
            )
          })
        )}
      </ul>
      {loading ? <Indicators.AbsoluteOverlay /> : null}
    </div>
  )
}

CalendarHistoryWidget.displayName = "CalendarHistoryWidget"
