import * as types from "../actionTypes"

/**
 * Hides the item explorer.
 * @return {Object} An FSA action.
 */
export const hideItems = () => ({
  type: types.HIDE_ITEMS,
  payload: {
    itemExplorerVisible: false,
  },
})
