import React from "react"
import { useDispatch } from "react-redux"
import { Button } from "ui"
import { Modal } from "../../shared"
import { logoutUser } from "../../api"

export const ExpressSignInModal: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <Modal.Dialog fadeIn={true} extended={true}>
      <Modal.Header draggable={true}>
        <Modal.Title title={"Your Session has Expired"} />
        <Modal.Logo />
      </Modal.Header>
      <Modal.Body>
        <p>
          Your session token is no longer valid. Please sign in again to
          continue working in ProCal.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          onClick={() => {
            dispatch(logoutUser())
          }}
        >
          Return to Sign In
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  )
}
