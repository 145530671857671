import { CALL_API } from "../middlewares"
import * as types from "../actionTypes"
import * as auth from "./auth"

//
// POST / create user
//

export const fetchUserRegistration = (creds) => ({
  type: types.CREATE_USER,
  [CALL_API]: {
    method: "POST",
    endpoint: "/api/users/register.json",
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: creds.name,
      email: creds.username,
      password: creds.password,
      password_confirmation: creds.confirm,
      token: creds.token,
    }),
    types: [
      types.CREATE_USER_REQUEST,
      types.CREATE_USER_SUCCESS,
      types.CREATE_USER_FAILURE,
    ],
  },
})

export const registerUser = (creds) => (dispatch) => {
  return dispatch(fetchUserRegistration(creds))
}

export const registerAndLoginUser = (creds) => (dispatch) => {
  return dispatch(fetchUserRegistration(creds)).then((action) => {
    if (action.type === types.CREATE_USER_SUCCESS) {
      return dispatch(auth.loadAuthToken(creds))
    }
    return action
  })
}

//
// POST / create forgot password email
//

export const postForgotPassword = (email) => ({
  type: types.CREATE_FORGOT_PASSWORD,
  [CALL_API]: {
    method: "POST",
    endpoint: `/api/users/forgot_password`,
    body: JSON.stringify({ email }),
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.CREATE_FORGOT_PASSWORD_REQUEST,
      types.CREATE_FORGOT_PASSWORD_SUCCESS,
      types.CREATE_FORGOT_PASSWORD_FAILURE,
    ],
  },
})

export const createForgotPassword = (email) => (dispatch) => {
  return dispatch(postForgotPassword(email))
}

//
// PATCH / reset password
//

export const patchResetPassword = (password, token) => ({
  type: types.PATCH_RESET_PASSWORD,
  [CALL_API]: {
    method: "PATCH",
    endpoint: `/api/users/reset_password`,
    body: JSON.stringify({ password, token }),
    headers: {
      Accept: "application/vnd.film_cal-v1+json",
      "Content-Type": "application/json",
    },
    types: [
      types.PATCH_RESET_PASSWORD_REQUEST,
      types.PATCH_RESET_PASSWORD_SUCCESS,
      types.PATCH_RESET_PASSWORD_FAILURE,
    ],
  },
})

export const updateForgottenPassword = (email, token) => (dispatch) => {
  return dispatch(patchResetPassword(email, token))
}
