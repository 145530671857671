var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToolTip } from "./ToolTip";
import clsx from "clsx";
var BUTTON_STYLES = "rounded-full w-10 flex-shrink-0";
var PRIMARY_STYLES = "bg-sorbus-default text-white";
var HOVER_STYLES = "hover:bg-gray-400 hover:bg-opacity-25";
var PRIMARY_HOVER_STYLES = "hover:bg-sorbus-dark hover:text-white";
var INACTIVE_STYLES = "opacity-50";
var DISABLED_STYLES = "opacity-25 pointer-events-none";
export var IconButton = function (_a) {
    var disabled = _a.disabled, icon = _a.icon, clickHandlerFromProps = _a.onClick, className = _a.className, style = _a.style, tooltip = _a.tooltip, _b = _a.tooltipAlignment, tooltipAlignment = _b === void 0 ? "center" : _b, _c = _a.toolTipDirection, toolTipDirection = _c === void 0 ? "below" : _c, inactive = _a.inactive, noPadding = _a.noPadding, primary = _a.primary;
    var _d = useState(), hoverTimeout = _d[0], setHoverTimeout = _d[1];
    var _e = useState(false), hover = _e[0], setHover = _e[1];
    var handleMouseOver = function (e) {
        if (tooltip) {
            setHoverTimeout(setTimeout(function () { return setHover(true); }, 500));
        }
    };
    var handleMouseOut = function (e) {
        if (tooltip) {
            clearTimeout(hoverTimeout);
            setHover(false);
        }
    };
    var handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (clickHandlerFromProps) {
            clickHandlerFromProps(e.currentTarget);
        }
    };
    return (_jsxs("button", __assign({ className: clsx([
            BUTTON_STYLES,
            (className !== null && className !== void 0 ? className : "").indexOf("absolute") < 0 && "relative",
            (className !== null && className !== void 0 ? className : "").indexOf("hover:bg") < 0 && primary
                ? PRIMARY_HOVER_STYLES
                : HOVER_STYLES,
            primary && PRIMARY_STYLES,
            !noPadding && "p-2",
            className,
            disabled ? DISABLED_STYLES : inactive ? INACTIVE_STYLES : null,
        ]), onClick: handleClick, style: style, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseOut }, { children: [_jsx(FontAwesomeIcon, { icon: icon }, void 0), tooltip ? (_jsx(ToolTip, { label: tooltip, active: hover, direction: toolTipDirection, align: tooltipAlignment }, void 0)) : null] }), void 0));
};
