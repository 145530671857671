import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { items } from "../actions"
import { itemSelector, eventSelector } from "../selectors"

export const useItem = (
  subdomain: string,
  calendarId: string,
  itemUuid?: string
) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(items.requestItem(subdomain, calendarId, itemUuid))
  const item = useSelector(itemSelector.find)(itemUuid, calendarId)
  const events = useSelector(eventSelector.forItem)(itemUuid, calendarId)
  const { loading, refetch } = useRequest(request)
  return { loading, item, event: events?.[0], refetch }
}
