import React from "react"
import PropTypes from "prop-types"
import styles from "./ShortListGroup.module.css"

const ShortListGroup = ({ children }) => (
  <div className={styles.shortListGroup}>{children}</div>
)

ShortListGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ShortListGroup
