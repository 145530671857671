import { useWindowSize } from './useWindowSize';
var breakpoints = {
    xs: 375,
    sm: 544,
    md: 768,
    lg: 992,
    xl: 1200,
};
export var useBreakpoint = function () {
    var width = useWindowSize().width;
    var viewPorts = Object.keys(breakpoints).filter(function (key) { return breakpoints[key] <= width; });
    return viewPorts;
};
