import { useRequest } from "./useRequest"
import { useSelector, useDispatch } from "react-redux"
import { reports as actions } from "../actions"
import { reportSelector } from "../selectors"
import { useReportCalendars } from "./useReportCalendars"

export const useReport = (subdomain: string, id: number) => {
  const dispatch = useDispatch()
  const request = async () =>
    await dispatch(actions.requestReport(subdomain, id))
  const report = useSelector(reportSelector.find)(id)
  const { loading, refetch: repRefetch } = useRequest(request)
  const {
    loading: calendarLoading,
    calendars,
    refetch: calRefetch,
  } = useReportCalendars(subdomain, id)
  const refetch = () => {
    repRefetch()
    calRefetch()
  }
  return {
    loading: calendarLoading || loading,
    report,
    reportCalendars: calendars,
    refetch,
  }
}
