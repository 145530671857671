import React from "react"
import PropTypes from "prop-types"
import styles from "./ViewContent.module.css"

const ViewContent = ({ children }) => (
  <div className={styles.viewContent}>{children}</div>
)

ViewContent.propTypes = {
  children: PropTypes.node,
}

export default ViewContent
