import React from "react"

import Container from "./PlaceholderContainer.react"
import Indicator from "../Indicator"

const Loading = () => (
  <Container>
    <Indicator.Ring theme="placeholder" />
  </Container>
)

export default Loading
