var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, } from "@fortawesome/pro-regular-svg-icons";
import { ContextMenuContainer } from "./ContextMenuContainer";
import clsx from "clsx";
var CHILD_STYLES = "flex flex-col shadow absolute bg-white rounded py-2 w-48";
var ITEM_STYLES = "flex relative hover:bg-gray-200 hover:bg-opacity-50";
var BUTTON_STYLES = "flex flex-row py-1 px-3 text-xs font-bold flex-grow";
var ICON_STYLES = "my-auto text-lochivar-default";
var LABEL_STYLES = "pl-2 text-sorbus-default flex-shrink-0";
/**
 * A context menu with overlay to dismiss itself when a user clicks outside of
 * the menu. Similar to a modal view but purely for menu navigsation.
 */
export var ContextItem = function (_a) {
    var children = _a.children, icon = _a.icon, onClick = _a.onClick, disabled = _a.disabled, name = _a.name;
    var childMenu = useRef(null);
    var timeout = useRef();
    var _b = useState({ bottom: false, right: false }), alignment = _b[0], setAlignment = _b[1];
    var _c = useState(false), hover = _c[0], setHover = _c[1];
    var handleClick = function (e) {
        e.preventDefault();
        if (onClick) {
            onClick();
        }
    };
    var handleHover = function (active) { return function (e) {
        e.preventDefault();
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(function () { return setHover(active); }, 350);
    }; };
    useEffect(function () {
        var _a;
        if (hover) {
            var r = (_a = childMenu.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            var bottom = r ? window.innerHeight < r.y + r.height : false;
            var right = r ? window.innerWidth < r.x + r.width : false;
            setAlignment({ bottom: bottom, right: right });
        }
    }, [setAlignment, hover]);
    return (_jsxs("li", __assign({ className: ITEM_STYLES, onMouseOver: handleHover(true), onMouseOut: handleHover(false) }, { children: [_jsxs("button", __assign({ onClick: handleClick, className: BUTTON_STYLES, style: {
                    pointerEvents: disabled ? "none" : "all",
                    opacity: disabled ? 0.5 : 1,
                } }, { children: [icon ? (_jsx("span", __assign({ className: ICON_STYLES }, { children: _jsx(FontAwesomeIcon, { icon: icon, style: {
                                width: "20px",
                            } }, void 0) }), void 0)) : (_jsx("i", { style: { width: "20px" } }, void 0)), _jsx("span", __assign({ className: LABEL_STYLES }, { children: name }), void 0), children ? (_jsx(FontAwesomeIcon, { icon: faChevronRight, style: {
                            width: "20px",
                        }, className: "my-auto ml-auto text-rose-darkest" }, void 0)) : null] }), void 0), hover && children ? (_jsx(ContextMenuContainer, __assign({ className: clsx(CHILD_STYLES, alignment.bottom ? "bottom-0" : "top-0", alignment.right ? "right-100 -mr-1" : "left-100 -ml-1"), onClick: handleClick, onMouseOver: handleHover(true), onMouseOut: handleHover(false), ref: childMenu }, { children: children }), void 0)) : null] }), void 0));
};
