import { connect } from "react-redux"
import * as actions from "../actions"
import * as sel from "../selectors"
import api from "../../api"
import CalendarView from "../components/CalendarView.react"
import * as selectable from "../../selectable"

const {
  itemSelector,
  calendarSelector,
  styleSelector,
  eventSelector,
  reportSelector,
  categorySelector,
  organizationSelector,
  snapshotSelector,
  holidaySelector,
  authSelector,
} = api.selectors

const {
  actions: {
    events,
    items,
    cals,
    categories,
    snapshots,
    holidays,
    auth,
    reports,
  },
} = api

export default connect(
  () => {
    return (state, props) => ({
      findCalendar: calendarSelector.find(state),
      orgCalendars: calendarSelector.forOrganization(state),
      findIndicatorDates: calendarSelector.findIndicatorDates(state),
      findSnapshot: snapshotSelector.find(state),
      downloadToken: calendarSelector.downloadToken(state),
      findCalendarCategories: categorySelector.forCalendar(state),
      findSnapshotCategories: categorySelector.forSnapshot(state),
      findCalendarItems: itemSelector.forCalendar(state),
      findSnapshotItems: itemSelector.forSnapshot(state),
      findCalendarOrSnapshotCategoryForNotes:
        categorySelector.notesCategoryForCalendarEntity(state),
      findCalendarOrSnapshotNotes: itemSelector.notesForCalendar(state),
      findCalendarEvents: eventSelector.forCalendar(state),
      findSnapshotEvents: eventSelector.forSnapshot(state),
      findOrganization: organizationSelector.find(state),
      findCalendarSnapshots: snapshotSelector.forCalendar(state),
      findReport: reportSelector.find(state),
      findReportCalendarIds: reportSelector.calendarIdsForReport(state),
      findReportCalendars: reportSelector.calendarsForReport(state),
      findRenderableCalendarIdsForReport:
        reportSelector.renderableCalendarIdsForReport(state),
      findStyle: styleSelector.find(state),
      findItem: itemSelector.find(state),
      findEvent: eventSelector.find(state),
      findLockedItem: itemSelector.findLocked(state),
      showDragUI: sel.showDragUI(state),
      itemExplorerVisible: sel.itemExplorerVisible(state),
      itemPreviewType: sel.itemPreviewType(state),
      ignoreEvents: sel.ignoreEvents(state),
      selectedItems: selectable.getSelectedItems(state),
      hasSelection: selectable.hasSelectedItems(state),
      lastSelectedType: selectable.getLastSelectedType(state),
      selectedDate: selectable.selectedDate(state),
      clipboardMode: sel.clipboardMode(state),
      clipboardEvents: sel.clipboardEvents(state),
      lastSelectedEventDate: selectable.lastSelectedEventDate(state),
      dateForSettingsMenu: sel.dateForSettingsMenu(state),
      findHolidays: holidaySelector.forCalendar(state),
      findHolidaysForSnapshot: holidaySelector.forSnapshot(state),
      isAdmin: authSelector.admin(state),
      isSuperAdmin: authSelector.superAdmin(state),
      canWrite: authSelector.canWrite(state),
      canManage: authSelector.canManage(state),
      contextMenu: sel.contextMenu(state),
      multiselectEnabled: selectable.getMultiselect(state),
      findLatestEventForCalendar: eventSelector.findLatestEventForCalendar(
        state,
        props
      ),
      findEarliestEventForCalendar: eventSelector.findEarliestEventForCalendar(
        state,
        props
      ),
    })
  },
  {
    ...actions,
    loadCalendar: cals.requestCalendar,
    downloadCalendar: cals.downloadCalendar,
    loadEvents: events.requestEvent,
    createEvent: events.createEvent,
    updateEvent: events.updateEvent,
    removeEvents: events.removeEvents,
    loadItems: items.requestItem,
    removeItems: items.removeItems,
    lockItem: items.lockItem,
    unlockItem: items.unlockItem,
    showItem: items.showItem,
    hideItem: items.hideItem,
    updateItemCategory: items.updateItemCategory,
    lockCategory: categories.lockCategory,
    unlockCategory: categories.unlockCategory,
    loadCategories: categories.requestCategory,
    removeCategories: categories.removeCategories,
    showCategory: categories.showCategory,
    hideCategory: categories.hideCategory,
    openCategory: categories.openCategory,
    updateCategoryCategory: categories.updateCategoryCategory,
    requestSnapshot: snapshots.requestSnapshot,
    requestCurrentUser: auth.requestCurrentUser,
    removeHolidays: holidays.removeHoliday,
    requestReport: reports.requestReport,
    requestFullReportCalendars: reports.requestFullReportCalendars,
    addReportCalendar: reports.addReportCalendar,
    removeReportCalendar: reports.removeReportCalendar,
    selectItem: selectable.selectItem,
    selectDate: selectable.selectDate,
    resetSelection: selectable.resetSelection,
    setMultiselect: selectable.setMultiselect,
    updateItemDraggingStatus: selectable.updateItemDraggingStatus,
  }
)(CalendarView)
