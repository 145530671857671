var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export var SimpleSelect = React.forwardRef(function (_a, ref) {
    var name = _a.name, id = _a.id, value = _a.value, _b = _a.unit, unit = _b === void 0 ? "" : _b, onChange = _a.onChange, disabled = _a.disabled, placeholder = _a.placeholder, _c = _a.options, options = _c === void 0 ? [] : _c;
    return (_jsx("select", __assign({ ref: ref, name: name, value: value, id: id !== null && id !== void 0 ? id : name, disabled: disabled, onChange: onChange, placeholder: placeholder, className: "block bg-gray-100 p-2 rounded w-full outline-none shadow" }, { children: options === null || options === void 0 ? void 0 : options.map(function (o) { return (typeof o === "object" ? o : { value: o, label: o }); }).map(function (o) { return (_jsx("option", __assign({ value: o.value }, { children: "" + o.label + unit }), "name" + o.value)); }) }), void 0));
});
