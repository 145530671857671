import React, { useState, useEffect } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { useInactiveOrgsAnalytics } from "../api"
import { Button } from "ui"
import { Panel } from "./Panel"
import { Title } from "./Title"
import { Results } from "./Resullts"
import { StylableProps } from "./Styleable"

interface InactiveOrgsPanelProps {
  maxResults?: number
}
export const InactiveOrgsPanel: React.FC<
  StylableProps & InactiveOrgsPanelProps
> = ({ maxResults = 10, ...props }) => {
  const [request, { loading, data }] = useInactiveOrgsAnalytics()
  const [showAll, setShowAll] = useState(false)
  const handleShowAll: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setShowAll(!showAll)
  }

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!loaded) {
      request()
      setLoaded(true)
    }
  }, [request, loaded, setLoaded])

  const count = data?.length ?? 0
  return (
    <Panel {...props} loading={loading}>
      <Title className="text-red-600">
        {(data ?? []).length} Inactive Orgs (All Time)
      </Title>
      <Results>
        <>
          <p className="px-2 w-1/2 text-sm font-bold">Name</p>
          <p className="px-2 w-1/4 text-sm font-bold">Last Active</p>
        </>
        {(data ?? [])
          .filter((_: any, i: any) =>
            showAll ? true : maxResults ? i <= maxResults : true
          )
          .sort((a: any, b: any) => {
            return moment(b.lastActive).diff(moment(a.lastActive))
          })
          .map((metric: any, i: number) => (
            <React.Fragment key={metric.id}>
              <p className="px-2 w-1/2 text-sm flex-grow-0 truncate">
                <Link to={`/org/${metric.meta_2}/`}>{metric.value}</Link>
              </p>
              <p className="px-2 w-1/2 text-sm flex-grow-0 truncate">
                {moment(metric.meta_1).format("MMM DD, yyyy")}
              </p>
            </React.Fragment>
          ))}
        {count <= maxResults ? (
          <p className="px-2 w-full text-sm flex-grow-0 truncate font-italic text-gray-500">
            {count ? `Displaying all ${count} results.` : "No results found."}
          </p>
        ) : (
          <Button onClick={handleShowAll} appearance="secondary">
            {showAll ? "Show Less" : "Show All"}
          </Button>
        )}
      </Results>
    </Panel>
  )
}
