import { useApiRequest } from "./useApiRequest"
import { AnalyticResult } from "./useActiveOrgsAnalytics"

export const useActiveUsersOverTimeAnalytics = (days: number) => {
  const getActiveOrgs = useApiRequest<AnalyticResult[], undefined>({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/analytics/${days}/active_users_over_time`,
  })
  return getActiveOrgs
}
