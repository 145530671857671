import React from "react"
import PropTypes from "prop-types"
import styles from "./Section.module.css"

const classNamesForProps = ({ active }) =>
  [styles.section, !active ? styles.inactive : ""].join(" ")

const Section = ({ children, active }) => (
  <div className={classNamesForProps({ active })}>{children}</div>
)

Section.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
}

export default Section
