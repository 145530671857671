import React from "react"
import { render } from "react-dom"
import LogRocket from "logrocket"
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer"

import configureStore from "./store/configureStore"
import Root from "./Root"

const privateFieldNames = ["password", "authorization"]

const { requestSanitizer, responseSanitizer } =
  LogrocketFuzzySanitizer.setup(privateFieldNames)

LogRocket.init("nwsbas/procal", {
  release: process.env.REACT_APP_ENVIRONMENT || "local",
  network: {
    requestSanitizer: requestSanitizer,
    responseSanitizer: responseSanitizer,
  },
})

const store = configureStore()

render(<Root store={store} />, document.getElementById("root"))
