import { useUpdateEntity } from "../entities"
import { useApiRequest, RequestParams } from "../useApiRequest"
import { camelizeKeys } from "humps"

interface SetKeeperCalendar {
  id: number
  name: string
  color: string
  departmentId: string
  projectId: string
}

export interface SetKeeperCalendarsBody extends RequestParams {
  projectId: string
}

export const useSetKeeperCalendars = () => {
  const updateEntity = useUpdateEntity()

  const requestCalendars = useApiRequest<
    SetKeeperCalendar[],
    SetKeeperCalendarsBody,
    RequestParams
  >({
    method: "GET",
    url: `//${process.env.REACT_APP_API_BASE_DOMAIN}/api/connections/setkeeper-calendars`,
    onResponse: (response) => {
      response.forEach((calendar) => {
        if (calendar.id) {
          updateEntity("setKeeperCalendars", calendar.id, {
            ...camelizeKeys(calendar),
          })
        }
      })
    },
  })

  return requestCalendars
}
