import { FileUploadStatus } from "./FileDropProps";
export var FileUpload = function (uuid, file, url, onUpdate) {
    var reader = new FileReader();
    var xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", function (e) {
        if (e.lengthComputable) {
            var percentage = Math.round((e.loaded * 100) / e.total);
            onUpdate(uuid, FileUploadStatus.Pending, percentage);
        }
    }, false);
    xhr.upload.addEventListener("load", function (_) {
        onUpdate(uuid, FileUploadStatus.Available, 100);
    }, false);
    xhr.open("PUT", url);
    reader.onload = function (_) { return xhr.send(reader.result); };
    try {
        reader.readAsArrayBuffer(file);
    }
    catch (e) {
        console.error(e);
    }
    return {
        cancel: function () {
            xhr.abort();
            onUpdate(uuid, FileUploadStatus.Deleted, 0);
        },
    };
};
