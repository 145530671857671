var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { sortedWithinRange, priorWeekendsBetweenDates } from "./dateHelpers";
/**
 * A hook to calculate interruptions affecting a specific event. The algorithm will return
 * an object containing an array of event segments that can be iterated and rendered via
 * the `EventView` or the `GanntChart`.
 * @param config Props to configure the segment calculation.
 * @returns An array of segments and an initial offset calculation.
 */
export var useRenderableSegmentsForWeek = function (_a) {
    var weekStartsAt = _a.weekStartsAt, startsAt = _a.startsAt, breakOnDarkDays = _a.breakOnDarkDays, breakOnWeekends = _a.breakOnWeekends, length = _a.length, darkDays = _a.darkDays;
    // Determine start and end dates for this week.
    var eventInProgress = weekStartsAt.diff(startsAt, "days").days > 0;
    var effectiveStart = (eventInProgress ? weekStartsAt : startsAt).startOf("day");
    var weekEndsAt = weekStartsAt.plus({ days: 6 });
    var priorDarkDays = breakOnDarkDays
        ? sortedWithinRange(darkDays, startsAt.toISO({ includeOffset: false }), weekEndsAt.toISO({ includeOffset: false }))
        : [];
    var priorWeekends = breakOnWeekends
        ? priorWeekendsBetweenDates(startsAt.toISO({ includeOffset: false }), weekEndsAt.toISO({ includeOffset: false }))
        : [];
    var interruptions = __spreadArray(__spreadArray([], priorDarkDays, true), priorWeekends, true);
    // We'll increment this if any interrupitions push this event beyond it's intended start.
    var initialOffset = eventInProgress
        ? 0
        : Math.floor(startsAt.diff(weekStartsAt, "days").days);
    var predictedEndDate = startsAt.plus({
        days: interruptions.length + length,
    });
    // We'll reference this to determine if a segment is the end of the event.
    var lengthForView = Math.min(weekEndsAt.diff(effectiveStart, "day").days + 1, // We want this range to be inclusive of the final day
    eventInProgress
        ? length -
            weekStartsAt.diff(startsAt, "days").days +
            sortedWithinRange(interruptions, startsAt.toISO({ includeOffset: false }), weekStartsAt.minus({ days: 1 }).toISO({ includeOffset: false })).length
        : length);
    // We'll use this to iterate over the remaining days we we calculate segment lengths.
    var remainingLength = lengthForView;
    if (predictedEndDate.diff(weekStartsAt, "days").days <= 0 ||
        remainingLength < 0) {
        return {
            segments: [],
            initialOffset: initialOffset,
        };
    }
    // Generate all renderable segments of this event for this week..
    var segmentCount = 7 - initialOffset;
    var segments = segmentCount < 1
        ? []
        : new Array(segmentCount).fill("").reduce(function (p, _, i) {
            // Bail early if we've reached the max length of the event
            if (remainingLength < 1) {
                return __spreadArray([], p, true);
            }
            var lastSegment = p[p.length - 1];
            var priorSegments = p.length > 1 ? p.slice(0, p.length - 1) : [];
            var currentDay = effectiveStart.plus({ days: i });
            var currentDate = currentDay.toISO({ includeOffset: false });
            if (interruptions.includes(currentDate)) {
                if (!lastSegment) {
                    initialOffset += 1;
                    return __spreadArray([], p, true);
                }
                return __spreadArray(__spreadArray([], priorSegments, true), [
                    __assign(__assign({}, lastSegment), { brokenEnd: true }),
                ], false);
            }
            remainingLength = remainingLength - 1;
            var blankDates = initialOffset + interruptions.length;
            predictedEndDate = startsAt.plus({
                days: blankDates > 0
                    ? interruptions.length + length - initialOffset
                    : length - 1,
            });
            predictedEndDate = startsAt.plus({
                days: length - 1 + interruptions.length,
            });
            var brokenStart = currentDate !== startsAt.toISO({ includeOffset: false });
            var brokenEnd = currentDay.diff(weekEndsAt, "days").days === 0 &&
                predictedEndDate.diff(weekEndsAt, "days").days > 0;
            // Append length to existing segment
            if (lastSegment && !(lastSegment === null || lastSegment === void 0 ? void 0 : lastSegment.brokenEnd)) {
                return __spreadArray(__spreadArray([], priorSegments, true), [
                    __assign(__assign({}, lastSegment), { length: lastSegment.length + 1, brokenEnd: brokenEnd }),
                ], false);
            }
            var segmentDiffFromEventStart = currentDay.diff(startsAt, "days")
                .days;
            // start a new segment
            return __spreadArray(__spreadArray([], p, true), [
                {
                    starts: currentDay,
                    brokenStart: brokenStart,
                    brokenEnd: brokenEnd,
                    length: 1,
                    daysFromStart: segmentDiffFromEventStart,
                },
            ], false);
        }, []);
    return { segments: segments, initialOffset: initialOffset };
};
