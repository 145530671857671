import React from "react"
import PropTypes from "prop-types"
import styles from "./ShortListIcon.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ShortListIcon = ({ icon }) => (
  <FontAwesomeIcon icon={icon} className={`${styles.shortListIcon}`} />
)

ShortListIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default ShortListIcon
