var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { InvitationSelect } from "../Form/InvitationSelect";
import isEmail from "is-email";
import { faTrash, faPlus, faCog, faPencil, faEye, } from "@fortawesome/pro-regular-svg-icons";
import { IconButton } from "../Navigation";
import { Button } from "../Form";
import { swallow } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var styles = {
    headline: "text-sm font-bold text-gray-700",
    body: "text-sm text-gray-600",
};
export var CollaboratorWidget = function (_a) {
    var className = _a.className, style = _a.style, collaborators = _a.collaborators, invitations = _a.invitations, handleCreate = _a.onCreateInvitation, handleSelect = _a.onSelectExistingInvitation, handleEditCollaborator = _a.onEditCollaborator, handleDeleteCollaborator = _a.onDeleteCollaborator;
    var handleSelectInvitation = function (invitationId) {
        handleSelect(invitationId ? parseInt(invitationId) : null);
    };
    var handleCreateInvitation = function (value) {
        handleCreate({
            name: isEmail(value)
                ? value.split("@")[0].replace(/[\W_]+/g, " ")
                : value,
            email: isEmail(value) ? value : undefined,
        });
    };
    var handleCreateCollaboratorClick = swallow(function () {
        handleCreateInvitation("");
    });
    var handleCreateViaIconCLick = function () {
        handleCreateInvitation("");
    };
    var handleEditCollaboratorWithId = function (permissionId) { return function (e) {
        e.preventDefault();
        handleEditCollaborator(permissionId);
    }; };
    var handleDeleteCollaboratorWithId = function (permissionId) { return function () {
        return handleDeleteCollaborator(permissionId);
    }; };
    return (_jsxs("section", __assign({ className: className, style: style }, { children: [_jsx("label", __assign({ className: styles.headline }, { children: "New Collaborator" }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx(InvitationSelect, { name: "invitationId", onSelect: handleSelectInvitation, onCreate: handleCreateInvitation, options: invitations.map(function (invitation) { return ({
                            name: invitation.name,
                            email: invitation.email,
                            value: invitation.id.toString(),
                        }); }), placeholder: "Search team members", creatable: true, clearable: true }, void 0), _jsx(IconButton, { icon: faPlus, tooltip: "New Collaborator", tooltipAlignment: "right", className: "ml-1", onClick: handleCreateViaIconCLick }, void 0)] }), void 0), _jsx("h3", __assign({ className: clsx(styles.headline, "mt-4") }, { children: "Current Collaborators" }), void 0), collaborators.length > 0 && (_jsx("ul", { children: collaborators.map(function (_a) {
                    var name = _a.name, manage = _a.manage, read = _a.read, write = _a.write, permissionId = _a.permissionId;
                    return (_jsxs("li", __assign({ className: "border rounded border-gray-300/75 mb-2 flex hover:bg-lochivar-lighter/20 " }, { children: [_jsxs("button", __assign({ className: "p-2 flex-grow flex", onClick: handleEditCollaboratorWithId(permissionId) }, { children: [_jsx(FontAwesomeIcon, { icon: (manage && faCog) || (write && faPencil) || faEye, className: "mr-2 my-auto text-lochivar-default", size: "lg" }, void 0), _jsxs("ul", __assign({ className: "flex flex-col text-left" }, { children: [_jsx("li", { children: _jsx("h3", __assign({ className: styles.headline }, { children: name }), void 0) }, void 0), _jsx("li", { children: _jsxs("p", __assign({ className: styles.body }, { children: [manage && "Can read/write and manage users/settings.", !manage && write && read && "Has read and write access.", !manage && !write && read && "Has read-only access."] }), void 0) }, void 0)] }), void 0)] }), void 0), _jsx(IconButton, { icon: faTrash, tooltip: "delete", className: "my-auto mr-2 opacity-50 hover:opacity-100", onClick: handleDeleteCollaboratorWithId(permissionId) }, void 0)] }), "collaborator" + permissionId));
                }) }, void 0)), _jsx(Button, __assign({ className: "mt-2", onClick: handleCreateCollaboratorClick, align: "stretch" }, { children: "New Collaborator" }), void 0)] }), void 0));
};
CollaboratorWidget.displayName = "Widgets.CollaboratorWidget";
