var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { stroke } from "./stroke";
import { makePx } from "./px";
/**
 * Render a rect path on an HTML5 2d canvas.
 * @param ctx The current canvas context to draw on.
 * @param props The properties defining the appearance of the rectangle.
 */
export var rect = function (ctx, _a) {
    var x = _a.x, y = _a.y, h = _a.h, w = _a.w, fillStyle = _a.fillStyle, strokeProps = __rest(_a, ["x", "y", "h", "w", "fillStyle"]);
    var px = makePx(strokeProps.scale);
    ctx.beginPath();
    ctx.rect(px(x), px(y), px(w), px(h));
    if (fillStyle) {
        ctx.fillStyle = fillStyle;
        ctx.fillRect(px(x), px(y), px(w), px(h));
    }
    stroke(ctx, strokeProps);
};
