import React from "react"
import PropTypes from "prop-types"
import styles from "./Prompt.module.css"

const ModalPrompt = ({ children }) => (
  <p className={styles.modalPrompt}>{children}</p>
)

ModalPrompt.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalPrompt
