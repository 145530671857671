import * as types from "../actionTypes"

/**
 * Sets the preview type to use in the custom drag layer. Essentially,
 * this action is central to changing an item from the item explorer into
 * an actual event bubble when the user drags it over the calendar.
 * @param {String} itemPreviewType The new item preview type to use.
 * @return {Object} An FSA action.
 */
export const updateItemPreviewType = (itemPreviewType) => ({
  type: types.UPDATE_ITEM_PREVIEW,
  payload: {
    itemPreviewType,
  },
})
