var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToolBar, BarSpacer, BarItem, BarHeader } from "../Navigation";
export var DetailView = function (_a) {
    var children = _a.children, icon = _a.icon, title = _a.title, subtitle = _a.subtitle;
    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs(ToolBar, { children: [icon ? _jsx(BarItem, { icon: icon, mode: "secondary", disabled: true }, void 0) : null, _jsx(BarHeader, { primary: title, secondary: subtitle }, void 0), _jsx(BarSpacer, {}, void 0)] }, void 0), _jsx("div", __assign({ className: "flex-grow p-4" }, { children: children }), void 0)] }), void 0));
};
