import { PROTECTED } from "redux-jwt-protected-middleware"
import { CALL_API, Schemas } from "../middlewares"
import * as types from "../actionTypes"
import { decamelizeKeys } from "humps"
import moment from "moment"
import { eventSelector, calendarSelector, itemSelector } from "../selectors"
import { optimisticEntityDelete, optimisticEntityUpdate } from "./entities"
import { ENTITY_TYPES } from "../constants"
import { applyUTC } from "../helpers"
import { removeItems, updateItem } from "./items"
import toast from "react-hot-toast"

/**
 * Ensures any times in the request body are transformed
 * to UTC time in order to have consistent communication
 * between the API and the client.
 *
 * @param  {Object} event An object representing the event data.
 * @return {Object}       A cloned object with the dates transformed to UTC.
 */
const applyUTCForEvent = (event) => {
  const { startsAt, ...rest } = event
  return {
    startsAt: applyUTC(startsAt),
    ...rest,
  }
}

//
// GET / Find all OR by :calendar
//

/**
 * Action creator that generates an API call to fetcb all events
 * or a specific event by ID.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the event belongs to.
 * @param  {Integer} calendarId   The id of the calendar the event belongs to.
 * @param  {Integer} id           The (optional) id of the event to fetch.
 * @return {Object}               An object representing the redux action.
 */
export const getEvent = (subdomain, calendarId, id) => ({
  type: types.FETCH_EVENTS,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: id ? Schemas.EVENT : Schemas.EVENTS_ARRAY,
    method: "GET",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/events/${
      id || ""
    }`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.FETCH_EVENTS_REQUEST,
      types.FETCH_EVENTS_SUCCESS,
      types.FETCH_EVENTS_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to fetcb all events
 * or a specific event by ID. This is a convenience method that wraps
 * the getEvent() creator in a thunk.
 *
 * @param  {String} subdomain     The id of the organization which the calendar of the event belongs to.
 * @param  {Integer} calendarId   The id of the calendar the event belongs to.
 * @param  {Integer} id           The (optional) id of the event to fetch.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const requestEvent = (subdomain, calendarId, id) => (dispatch) => {
  return dispatch(getEvent(subdomain, calendarId, id))
}

/**
 * Action creator that generates an API call to fetcb all events
 * or a specific event by ID. This is a convenience method that wraps
 * the getEvent() creator in a thunk.
 *
 * @param  {Integer} calendar The calendar the event belongs to.
 * @param  {Integer} id       The (optional) id of the event to fetch.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const requestEventForCalendar = (calendar, id) => (dispatch) => {
  return dispatch(getEvent(calendar.organization, calendar.id, id))
}

//
// POST / New calendar
//

/**
 * Action creator that generates an API call to create a new event
 * for a specific calendar.
 *
 * @param  {Integer} calendar The id of the calendar the event belongs to.
 * @param  {Object}  event    Event data or body for the API call.
 * @return {Object}           An object representing the redux action.
 */
export const postEvent = (calendar, event) => {
  return {
    type: types.CREATE_EVENT,
    [PROTECTED]: true,
    [CALL_API]: {
      method: "POST",
      schema: event.numberOfIterationsRecurring
        ? Schemas.EVENTS_ARRAY
        : Schemas.EVENT,
      endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/events`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.film_cal-v1+json",
      },
      body: JSON.stringify(decamelizeKeys(applyUTCForEvent(event))),
      types: [
        types.CREATE_EVENT_REQUEST,
        types.CREATE_EVENT_SUCCESS,
        types.CREATE_EVENT_FAILURE,
      ],
    },
  }
}

/**
 * Action creator that generates an API call to create a new event
 * for a specific calendar. This is a convenience method that wraps
 * the postEvent() creator in a thunk.
 *
 * @param  {Integer} calendar The id of the calendar the event belongs to.
 * @param  {Object}  event    Event data or body for the API call.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const createEvent = (calendar, event) => (dispatch) => {
  return dispatch(postEvent(calendar, event))
}

//
// PUT / Update Event
//

/**
 * Action creator that generates an API call to update an existing event
 * against the API.
 *
 * @param  {Integer} calendar The id of the calendar the event belongs to.
 * @param  {Object}  event    Event data or body for the API call.
 * @return {Object}           An object representing the redux action.
 */
export const putEvent = (calendar, event) => ({
  type: types.UPDATE_EVENT,
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PUT",
    schema: event.numberOfIterationsRecurring
      ? Schemas.EVENTS_ARRAY
      : Schemas.EVENT,
    endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/events/${event.uuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys(applyUTCForEvent(event))),
    types: [
      types.UPDATE_EVENT_REQUEST,
      types.UPDATE_EVENT_SUCCESS,
      types.UPDATE_EVENT_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to update an existing
 * event against the API. This is a convenience method that wraps
 * the putEvent() creator in a thunk.
 *
 * @param  {Integer} calendar The id of the calendar the event belongs to.
 * @param  {Object}  event    Event data or body for the API call.
 * @return {Promise}          A promise representing the dispatched redux action creator.
 */
export const updateEvent = (calendar, event) => (dispatch) => {
  return dispatch(putEvent(calendar, event))
}

/**
 * Updates the date and position attributes for an event on a given
 * calendar. This method will also cause all positions in the list of
 * events to be resorted depending on the new position.
 *
 * @param  {Object}   calendar    The calendar which the event belongs to.
 * @param  {String}   eventUuid   The uuid of the event to be updated.
 * @param  {String}   startsAt    A UTC time denoting the new start time for the event.
 * @param  {String}   length      The length of the event in days.
 * @param  {Integer}  position    The arbitrary index representing the position of the event.
 * @param  {Integer}  partition   Indicates the partition to assign the events to events.
 * @param  {String}   debounceKey An optional key indicating whether or not to debounce the action.
 * @return {Object}               A redux action representing the API request.
 */
export const patchShiftEvent = (
  calendar,
  eventUuid,
  startsAt,
  length,
  position,
  partition,
  debounceKey
) => ({
  type: types.PATCH_SHIFT_EVENT,
  meta: {
    debounce: {
      time: debounceKey ? 1250 : 0,
      key: debounceKey,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    method: "PATCH",
    schema: Schemas.EVENT,
    endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/events/${eventUuid}/shift`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        startsAt: applyUTC(startsAt),
        length,
        position,
        partition,
      })
    ),
    types: [
      types.PATCH_SHIFT_EVENT_REQUEST,
      types.PATCH_SHIFT_EVENT_SUCCESS,
      types.PATCH_SHIFT_EVENT_FAILURE,
    ],
  },
})

/**
 * Updates the date and position attributes for an event on a given
 * calendar. This method will also cause all positions in the list of
 * events to be resorted depending on the new position. This is a
 * convenience method that wraps the putEvent() creator in a thunk.
 *
 * @param  {String}   eventUuid The uuid of the event to be updated.
 * @param  {String}   startsAt  A utc time denoting the new start time for the event.
 * @param  {Integer}  position  The arbitrary index representing the position of the event.
 * @param  {Integer}  partition Indicates the partition to assign the events to events.
 * @return {Promise}            A promise representing the dispatched redux action creator.
 */
export const shiftEvent =
  (eventUuid, startsAt, position, partition) => (dispatch, getState) => {
    const state = getState()
    const event = eventSelector.find(state)(eventUuid)
    const calendar = calendarSelector.find(getState())(event.calendarId)
    const newStartsAt = moment.utc(startsAt).format()
    return dispatch(
      patchShiftEvent(
        calendar,
        eventUuid,
        newStartsAt,
        event.length,
        position,
        partition
      )
    ).then(() => dispatch(optimisticDateRangeUpdate(event.calendarId)))
  }

/**
 * Looks up an event from the current state and calls the shift action to
 * persist its position via the API.
 *
 * @param  {String}   eventUuid    The id of the event to be updated.
 * @param  {String}   debounceKey  The key used to debounce the action
 * @return {Promise}               A promise representing the dispatched redux action creator.
 */
export const saveEventPosition =
  (eventUuid, calendarId, debounceKey) => (dispatch, getState) => {
    const state = getState()
    const event = eventSelector.find(state)(eventUuid, calendarId)
    const calendar = calendarSelector.find(getState())(calendarId)
    const action = patchShiftEvent(
      calendar,
      eventUuid,
      event.startsAt,
      event.length,
      event.position,
      event.partition,
      debounceKey
    )
    return dispatch(action).then(() =>
      dispatch(optimisticDateRangeUpdate(calendarId))
    )
  }

/**
 * Utilizes an optimistic entity update to update the date range of the specified calendar
 * base on the current events.
 * @param {string} calendarId The id of the calendar to update
 * @returns
 */
export const optimisticDateRangeUpdate =
  (calendarId) => (dispatch, getState) => {
    const state = getState()
    const calendar = calendarSelector.find(state)(calendarId)
    const events = eventSelector.forCalendar(state)(calendarId)
    const startDate = events.map((e) => e.startsAt).sort()[0]
    const endDate = events
      .map((e) => e.endsAt)
      .sort()
      .reverse()[0]
    return dispatch(
      optimisticEntityUpdate(ENTITY_TYPES.CALENDARS, calendarId, {
        ...calendar,
        startDate,
        endDate,
      })
    )
  }

/**
 * Action creator that generates an API call to offset an existing
 * event(s) against the API.
 *
 * @param  {String}   subdomain   The id of the organization the event belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the event belongs to.
 * @param  {Integer}  sourceCalendarId  The id of the original calendar the event belongs to.
 * @param  {Array}    uuids       An array of event uuids to update.
 * @param  {Integer}  offset      The number of days to offset the events by.
 * @param  {Integer}  partition   Indicates the partition to assign the events to events.
 * @param  {Boolean}  duplicate   Indicates whether or not to make copies of the events.
 * @param  {Boolean}  increment   Indicates whether or not to increment the prefix/suffix on a copy action.
 * @return {Promise}              An object representing the redux action.
 */
export const offsetEvents = (
  subdomain,
  calendarId,
  sourceCalendarId,
  uuids,
  offset,
  partition,
  duplicate,
  increment
) => ({
  type: types.OFFSET_EVENT,
  meta: {
    debounce: {
      time: duplicate ? 0 : 1250,
      key: `offsetEvents[${uuids.join("_")}]`,
    },
  },
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.EVENTS_ARRAY,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/events/offset`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(
      decamelizeKeys({
        uuids,
        offset,
        partition,
        duplicate,
        increment,
        sourceCalendarId,
      })
    ),
    types: [
      types.OFFSET_EVENT_REQUEST,
      types.OFFSET_EVENT_SUCCESS,
      types.OFFSET_EVENT_FAILURE,
    ],
  },
})

/**
 * Looks up an event from the current state and calls the offset action to
 * persist new event dates via the API.
 *
 * @param  {Array}    uuids       An array of event uuids to update.
 * @param  {Integer}  calendarId  The id of the calendar the event belongs to.
 * @param  {Integer}  sourceCalendarId  The id of the original calendar the event belongs to.
 * @param  {Integer}  offset      The number of days to offset the events by.
 * @param  {Integer}  partition   Indicates the partition to assign the events to events.
 * @param  {Boolean}  duplicate   Indicates whether or not to make copies of the events.
 * @param  {Boolean}  increment   Indicates whether or not to increment the prefix/suffix on a copy action.
 * @return {Promise}              A promise representing the dispatched redux action creator.
 */
export const adjustEventsWithOffset =
  (
    uuids,
    calendarId,
    sourceCalendarId,
    offset,
    partition,
    duplicate,
    increment
  ) =>
  (dispatch, getState) => {
    const calendar = calendarSelector.find(getState())(calendarId)
    return dispatch(
      offsetEvents(
        calendar.organization,
        calendarId,
        sourceCalendarId,
        uuids,
        offset,
        partition,
        duplicate,
        increment
      )
    ).then(dispatch(optimisticDateRangeUpdate(calendarId)))
  }

//
// DELETE / Delete existing Event via ID.
//

/**
 * Action creator that generates an API call to delete an existing
 * event against the API.
 *
 * @param  {Integer} calendar The calendar the event belongs to.
 * @param  {Integer} uuid     The uuid of the event that will be deleted.
 * @return {Promise}          An object representing the redux action.
 */
export const deleteEvent = (calendar, uuid) => ({
  type: types.DELETE_EVENT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.EVENT,
    method: "DELETE",
    endpoint: `/api/organizations/${calendar.organization}/calendars/${calendar.id}/events/${uuid}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    types: [
      types.DELETE_EVENT_REQUEST,
      types.DELETE_EVENT_SUCCESS,
      types.DELETE_EVENT_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * event against the API. This is a convenience method that wraps
 * the deleteEvent() creator in a thunk.
 *
 * @param  {Integer} calendar The id of the calendar the event belongs to.
 * @param  {Integer} id       The id of the event that will be deleted.
 * @return {Promise}           An object representing the redux action.
 */
export const removeEvent = (calendar, id) => (dispatch) => {
  return dispatch(deleteEvent(calendar, id))
}

/**
 * Action creator that generates an API call to delete an existing
 * event against the API.
 *
 * @param  {String}   subdomain   The id of the organization the event belongs to.
 * @param  {Integer}  calendarId  The id of the calendar the event belongs to.
 * @param  {Array}    uuids       An array of event uuids to delete.
 * @return {Promise}              An object representing the redux action.
 */
export const deleteEvents = (subdomain, calendarId, uuids) => ({
  type: types.DELETE_EVENT,
  [PROTECTED]: true,
  [CALL_API]: {
    schema: Schemas.EVENTS_ARRAY,
    method: "PATCH",
    endpoint: `/api/organizations/${subdomain}/calendars/${calendarId}/events`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.film_cal-v1+json",
    },
    body: JSON.stringify(decamelizeKeys({ uuids })),
    types: [
      types.DELETE_EVENT_REQUEST,
      types.DELETE_EVENT_SUCCESS,
      types.DELETE_EVENT_FAILURE,
    ],
  },
})

/**
 * Action creator that generates an API call to delete an existing
 * event against the API. This is a convenience method that wraps
 * the deleteEvent() creator in a thunk.
 *
 * @param  {String}   subdomain           The id of the organization the event belongs to.
 * @param  {Integer}  calendarId          The id of the calendar the event belongs to.
 * @param  {Array}    uuids               An array of event uuids to delete.
 * @param  {Boolean}  removeParentItems   If true the parent item of the event(s) will also be removed.
 * @return {Promise}                      An object representing the redux action.
 */
export const removeEvents =
  (subdomain, calendarId, uuids, removeParentItems) => (dispatch, getStore) => {
    if (removeParentItems) {
      const items = uuids.map(
        (uuid) => eventSelector.find(getStore())(uuid, calendarId).itemUuid
      )
      const promise = dispatch(removeItems(subdomain, calendarId, items))
      toast.promise(promise, {
        loading: `Removing ${uuids.length} Events...`,
        success: `Removed ${uuids.length} Events.`,
        error: `Failed to remove ${uuids.length} Events.`,
      })
      return promise
    }
    uuids.forEach((uuid) => {
      dispatch(
        optimisticEntityDelete(
          ENTITY_TYPES.EVENTS,
          uuid,
          eventSelector.find(uuid)
        )
      )
    })
    return dispatch(deleteEvents(subdomain, calendarId, uuids))
  }

/**
 * Assigns a new category to an event.
 * @param {string} subdomain The subdomain of the organization
 * @param {number} calendarId The id of the calendar to the event belongs to
 * @param {string} eventUuid The uuid of the event to update
 * @param {string} categoryUuid The uuid of the category to assign the event to
 * @returns A promise representing the dispatched redux action creator
 */
export const updateEventCategory =
  (subdomain, calendarId, eventUuid, categoryUuid) => (dispatch, getState) => {
    const state = getState()
    const { itemUuid, startsAt, length, notes } = eventSelector.find(state)(
      eventUuid,
      calendarId
    )
    const item = itemSelector.find(state)(itemUuid, calendarId)
    return dispatch(
      updateItem(subdomain, calendarId, {
        ...item,
        categoryUuid,
        occursOnCalendar: true,
        event: {
          startsAt,
          length,
          notes,
        },
      })
    )
  }

/**
 * Assigns a new category to an event.
 * @param {string} subdomain The subdomain of the organization
 * @param {number} calendarId The id of the calendar to the event belongs to
 * @param {string} eventUuid The uuid of the event to update
 * @param {string} styleUuid The uuid of the style to assign the event to
 * @returns A promise representing the dispatched redux action creator
 */
export const updateEventStyle =
  (subdomain, calendarId, eventUuid, styleUuid) => (dispatch, getState) => {
    const state = getState()
    const { itemUuid, startsAt, length, notes } = eventSelector.find(state)(
      eventUuid,
      calendarId
    )
    const item = itemSelector.find(state)(itemUuid, calendarId)
    return dispatch(
      updateItem(subdomain, calendarId, {
        ...item,
        styleUuid,
        occursOnCalendar: true,
        event: {
          startsAt,
          length,
          notes,
        },
      })
    )
  }
