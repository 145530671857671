import { connect } from "react-redux"
import { loadOrgForSubdomain } from "../actions"
import { isFetching } from "../selectors"
import {
  invitationSelector,
  requestAcceptInvitation,
  clearedPendingInvitation,
} from "../../api"
import OrganizationBlankSlateView from "../components/OrganizationBlankSlateView.react"

export default connect(
  () => (state) => ({
    fetching: isFetching(state),
    pendingInviteOrg: invitationSelector.pendingInvitationOrganization(state),
    pendingInviteToken: invitationSelector.pendingInvitationToken(state),
  }),
  {
    clearInvitation: clearedPendingInvitation,
    acceptInvitation: requestAcceptInvitation,
    loadOrg: loadOrgForSubdomain,
  }
)(OrganizationBlankSlateView)
